import React from "react";

function Required({ok, children}) {
  return ok === true ? (
    children
  ) : (
    <div>
      <span
        style={{
            color: "darkred",
            borderRadius: "1rem",
            opacity: "0.8",
            fontSize: "0.8rem",
            border: "solid",
            borderColor: "darkred",
            borderWidth: "thin",
            padding: ".2rem"
        }}
      >
        required
      </span>
      {children}
    </div>
  );
}

export default Required;
