import React from 'react';
import Search from './Search';
import Controls from './Controls';
import Tree from './tree';
import './styles/index';
import useSearchTree from './useSearchTree';
import SelectionItem from './selection-item';

type Props = {
  selection?: string[];
  save: (public_ids: string[]) => void;
  clear?: () => void;
  cancel?: () => void;
  index: { byId: any; allNodes: any; ids: any; getNode: any };
  mirrorParent: boolean;
  mirrorChild?: boolean;
  showSelection: boolean;
  saveText?: string;
  cancelText?: string;
  showCancel?: boolean;
  showSave?: boolean;
  showRemoveAll?: boolean;
  onSelectionUpdated?: (value: any) => void;
  nothingSelectedText?: string;
};

function SearchTree(props: Props) {
  const {
    getNode,
    ancestorMap,
    setQuery,
    query,
    update,
    cache,
    pendingMap,
    pendingArray,
    currentTree,
    clear,
    cancel,
    save,
    remove,
  } = useSearchTree(props);

  React.useEffect(() => {
    if (props.onSelectionUpdated) {
      const selected = pendingArray.map((categoryPublicId) => {
        return getNode(categoryPublicId);
      });
      props.onSelectionUpdated(selected);
    }
  }, [pendingArray]);

  const showCancel = props.showCancel === undefined || props.showCancel;
  const showRemoveAll = props.showRemoveAll === undefined || props.showRemoveAll;
  const showSave = props.showSave === undefined || props.showSave;

  return (
    <>
      <Search query={query} getQuery={(n) => setQuery(n)} />
      <div data-cy={'selection-tree'} className="editor__search-tree__tree">
        {cache[currentTree] && cache[currentTree].length > 0 ? (
          cache[currentTree].map((root) => (
            <Tree
              {...root}
              key={root.public_id}
              query={query}
              update={update}
              mirrorChild={props.mirrorChild}
              mirrorParent={props.mirrorParent}
              ancestorMap={ancestorMap}
              selection={pendingMap}
            />
          ))
        ) : (
          <div className="text-muted" onClick={() => setQuery('@')}>
            {'no results'}
          </div>
        )}
      </div>
      <div className="editor__search-tree__selection">
        {props.showSelection && props.nothingSelectedText && pendingArray.length == 0 && (
          <span className="editor__search-tree__empty-selection">{props.nothingSelectedText}</span>
        )}
        {props.showSelection &&
          pendingArray.map((public_id) => {
            const t = ancestorMap[public_id];
            const ancestors = (t ? t.slice(0, -1) : []).map((a) => getNode(a));
            const node = getNode(public_id);
            return (
              <SelectionItem
                key={public_id + '__selected'}
                public_id={public_id}
                name={node.name}
                ancestors={ancestors}
                remove={() =>{
                  remove(public_id);
                }}
                pending={false}
              />
            );
          })}
      </div>
      <Controls
        showRemoveAll={pendingArray.length > 0 && showRemoveAll}
        save={save}
        cancel={cancel}
        clear={clear}
        showCancel={showCancel}
        showSave={showSave}
        cancelText={props.cancelText}
        saveText={props.saveText || 'Apply'}
      />
    </>
  );
}

export default SearchTree;
