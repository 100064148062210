import * as React from "react";
import {
  CancelButton,
  RoundEditButton,
  SaveButton,
  RoundSuggestionButton,
  SecondaryButton,
} from "../../library/Buttons";
import useApi from "../../hooks/useApi";
import IconX from "../../icons/IconX";
import ErrorBoundary from "@library/ErrorBoundary";
import {useStoreContext} from "@/state";
import * as hooks from "@library/launch-darkly/hooks";
import cn from "classnames";
import "./styles/index.scss";

type ViewDataProps = {
  show: boolean;
  onClick: () => void;
  className?: string;
  children?: any;
  editButtonId?: string;
  suggestionsButtonId?: string;
  userEditingStrategy: "update" | "suggest" | "none";
  cy?: string;
  sectionTitle: string;
  analytics?: any;
};

export const ViewData = ({
  userEditingStrategy,
  show,
  ...props
}: ViewDataProps) => {
  const onClick = () => {
    if (props.analytics) {
      props.analytics.trackListing("Listing Section", "opened to edit", {
        section: props.sectionTitle,
      });
    }
    props.onClick();
  };
  return show && userEditingStrategy ? (
    <div
      data-cy={props.cy || ""}
      className={"position-relative" + " " + props.className || "p-4"}
    >
      {userEditingStrategy === "update" && (
        <RoundEditButton onClick={props.onClick} id={props.editButtonId} />
      )}
      {userEditingStrategy === "suggest" && (
        <RoundSuggestionButton
          onClick={onClick}
          id={props.suggestionsButtonId}
        />
      )}
      <div className="editor__data-section editor__data-section--view">
        {props.children}
      </div>
    </div>
  ) : null;
};

type EditProps = {
  id?: string;
  show: boolean;
  cancel: () => void;
  className?: string;
  children?: any;
  saveButtonId?: string;
  suggestionsButtonId?: string;
  userEditingStrategy: "update" | "suggest" | "none";
  cy?: string;
  error?: boolean;
  errorMessage?: string;
  dismissError?: () => void;
  persistChanges?: (e: any) => void;
  saving?: boolean;
  disable?: boolean;
  floatControls?: boolean;
};

export const Edit = (props: EditProps) => (
  <>
    {props.error && (
      <div className="alert alert-danger d-flex flex-flow align-items-center justify-content-between p-1 mt-1 mb-1">
        <div className="ml-3"> {props.errorMessage}</div>
        <div className="mt-3" onClick={props.dismissError}>
          <IconX />
        </div>
      </div>
    )}
    {props.show ? (
      <div
        data-cy={props.cy || ""}
        id={props.id}
        className={
          "editor__data-section editor__data-section--edit" +
          (props.className ? " " + props.className : "")
        }
      >
        {props.children}
        { !props.floatControls && (
          <div className="editor__data-section__controls">
            <CancelButton cy="cancel-button" onCancel={props.cancel} />
            { props.userEditingStrategy === "update" ? (
              <SaveButton
                cy="save-button"
                disabled={props.saving || props.disable}
                onSave={(e) => props.persistChanges(e)}
                id={props.saveButtonId}
              />
            ) : (
              <SecondaryButton
                cy="suggest-button"
                disabled={props.saving || props.disable}
                onClick={(e) => props.persistChanges(e)}
                id={props.suggestionsButtonId}
              >
                {"Suggest updates"}
              </SecondaryButton>
            )}
          </div>
        )}
      </div>
    ) : null}
  </>
);

type EditDataProps = EditProps & {
  params?: any;
  onSuccess?: any;
  onFailure?: any;
  preSaveCheck?: any;
  method?: any;
  url?: any;
  cancel: () => void;
  sectionTitle: string;
  analytics?: any;
};

export const EditData = (props: EditDataProps) => {
  const {saving, error, dismissError, response, ...api} = useApi();

  const persistChanges = (e) => {
    if (e) e.preventDefault();
    api[props.method || "patch"](
      props.params,
      props.onSuccess,
      props.onFailure,
      props.preSaveCheck,
      props.url
    );
    if (props.analytics) {
      props.analytics.trackListing("Listing Section", "saved", {
        section: props.sectionTitle,
      });
    }
  };

  return (
    <Edit
      {...props}
      error={error}
      errorMessage={response}
      saving={saving}
      dismissError={dismissError}
      persistChanges={(e) => persistChanges(e)}
    >
      {props.children}
    </Edit>
  );
};

type Props = {
  sectionTitle: string;
  viewComponent: React.ReactNodeArray | React.ReactNode;
  editComponent: React.ReactNodeArray | React.ReactNode;
  params: any;
  reset: () => void;
  updateParams?: () => any;
  editDataId?: string;
  onEdit?: () => void;
  editSuggestionsButtonId?: string;
  viewSuggestionsButtonId?: string;
  editButtonId?: string;
  saveButtonId?: string;
  userEditingStrategy: UserEditingStrategy;
  cyView?: string;
  cyEdit?: string;
  cySection?: string;
  diff?: {
    applyValue: (field: string, value: any) => void;
  };
  onSuccess?: any;
  onFailure?: any;
  method?: string;
  editing?: boolean;
  featureFlag?: string;
  className?: string;
  floatControls?: boolean;
};

export function DataSection({
  viewComponent,
  featureFlag,
  editComponent,
  params,
  reset,
  diff,
  sectionTitle,
  ...props
}: Props) {
  const {analytics} = useStoreContext();
  const [editing, setEditing] = React.useState(props.editing || false);
  const [enabled] = featureFlag ? hooks.useFlag(featureFlag, false) : [true];

  return (
    <ErrorBoundary>
      {enabled ? (
        <div
          data-cy={props.cySection || "data-section"}
          className={cn(["editor__data", props.className])}
        >
          {sectionTitle ? (
            <h4 className="mt-5" id={sectionTitle}>
              {sectionTitle}
            </h4>
          ) : null}
          <ViewData
            cy={props.cyView}
            sectionTitle={sectionTitle}
            analytics={analytics}
            userEditingStrategy={props.userEditingStrategy}
            editButtonId={props.editButtonId}
            suggestionsButtonId={props.viewSuggestionsButtonId}
            show={!editing}
            onClick={() => {
              if (props.onEdit) props.onEdit();
              setEditing(true);
            }}
          >
            {viewComponent}
          </ViewData>
          <EditData
            cy={props.cyEdit}
            sectionTitle={sectionTitle}
            analytics={analytics}
            params={params}
            method={props.method}
            userEditingStrategy={props.userEditingStrategy}
            id={props.editDataId}
            saveButtonId={props.saveButtonId}
            suggestionsButtonId={props.editSuggestionsButtonId}
            show={editing}
            floatControls={props.floatControls}
            cancel={() => {
              if (reset) reset();
              setEditing(false);
            }}
            onSuccess={() => {
              if (props.onSuccess) props.onSuccess();
              setEditing(false);
            }}
            onFailure={() => {
              if (props.onFailure) props.onFailure();
              setEditing(false);
            }}
          >
            {editComponent}
          </EditData>
        </div>
      ) : null}
    </ErrorBoundary>
  );
}

export default DataSection;
