export default function parseQueryString(): { params: { [key: string]: string}, hash: string} {
  let params = {};

  if (document.location.search) {
    document.location.search
      .substring(1)
      .split('&')
      .forEach(function (kvp) {
        let tmp = kvp.split('=');
        // Convert "+" to "%20" in query strings before calling decodeURIComponent.
        // Otherwise the "+" characters that represent spaces can be accidentally
        // re-encoded as "%2B" instead of "+" or "%20".
        params[tmp[0]] = decodeURIComponent(tmp[1].replace(/\+/g, '%20'));
      });
  }

  if (document.location.hash) {
    return { params, hash: document.location.hash };
  }

  return { params, hash: '' };
}

export function getQueryStringValue(key: string, defaultValue: any): string {
  return parseQueryString()?.params[key] || defaultValue;
}
