import type { State } from '../types';

/**     ___________
 *     [__   Types |
 *    __/_| [] []  |
 *   [_,________,__|
 *     "        "
 **/

// If the correction type is 'correction' then the payload
// that we send the the backend is formatted differently
type Payload = State;

function notUndefinedOrNull(state, field) {
  return state[field] !== undefined && state[field] !== null;
}
export default (props: {
  canonical_place_id: number;
  uuid: string;
  name: string;
}): {
  atlas?: {
    url: string;
    method: string;
    payloadFormatter: (State) => Payload;
  };
  initialState: State;
  recaptcha: {
    action: string;
  };
  required: {
    [field: string]: {
      checkValue: (state: State) => boolean;
      dependents?: string[];
    };
  };
} => ({
  initialState: {
    canonical_place_id: props.canonical_place_id,
    uuid: props.uuid,
    name: props.name,
    comment: '',
    parking_allowed: null,
    policy_enforced: null,
    restricted_by: null,
    user_stay: null,
    user_stay_date: null,
    policy_confirmed: false,
    policy_confirmation_method: null,
    policy_confirmation_date: null,
    policy_confirmed_by: null,
    place_correct: null,
    errors: null,
  },
  required: {
    parking_allowed: {
      checkValue: (state) => state['parking_allowed'] !== undefined,
    },
    policy_enforced: {
      checkValue: (state) => state['policy_enforced'] !== undefined,
    },
    restricted_by: {
      checkValue: (state) =>
        (state['parking_allowed'] === false && notUndefinedOrNull(state, 'restricted_by')) ||
        state['parking_allowed'] === true ||
        state['parking_allowed'] === null,
    },
    user_stay: {
      checkValue: (state) => notUndefinedOrNull(state, 'user_stay'),
    },
    user_stay_date: {
      checkValue: (state) =>
        (state['user_stay'] === true && notUndefinedOrNull(state, 'user_stay_date')) || !state['user_stay'],
    },
    policy_confirmed: {
      checkValue: (state) => notUndefinedOrNull(state, 'policy_confirmed'),
    },
    policy_confirmation_method: {
      checkValue: (state) =>
        (state['policy_confirmed'] && notUndefinedOrNull(state, 'policy_confirmation_method')) ||
        state['policy_confirmed'] === false,
    },
    policy_confirmation_date: {
      checkValue: (state) =>
        (state['policy_confirmed'] && notUndefinedOrNull(state, 'policy_confirmation_date')) ||
        state['policy_confirmed'] === false,
    },
    policy_confirmed_by: {
      checkValue: (state) =>
        (state['policy_confirmed'] && notUndefinedOrNull(state, 'policy_confirmed_by')) ||
        state['policy_confirmed'] === false,
    },
    place_correct: {
      checkValue: (state) => notUndefinedOrNull(state, 'place_correct'),
    },
  },
  recaptcha: {
    action: 'orvp',
  },
  atlas: {
    method: '_post',
    url: '/orvp_reports',
    payloadFormatter: (st: State): Payload => st,
  },
});
