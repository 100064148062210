import * as util from '@library/attributes-section/util';

// Maps return value schema to the UI component that will
// be rendered after sorting
export function getComponentType(props) {

  const hasOptions = props.options && props.options.length > 0;
  const isLink = util.linkFormat(props.schema);
  const lcl = props.label.toLowerCase();

  switch (props.schema.type) {

    case 'object':
      return 'TextArea';

    case 'integer':
      if (props.schema.format === 'price-rating') return 'Price'
      if (props.depends_on && lcl.includes('count')) return 'CountDependent'
      if (lcl.includes('count')) return 'Count';
      return 'Integer';

    case 'float': return 'Float';

    case 'string':
      if (lcl.includes('length')) return 'Integer'
      if (hasOptions) return 'Select'
      if (props.schema.format === 'long') return 'TextArea'
      if (isLink) return 'Link';
      return 'Input'

    case 'boolean': return 'Boolean'
    case 'date': return 'Date';

    case 'array':
      if (isLink) return 'MultiLink';
      if (hasOptions) return 'MultiSelect';
      return 'MultiTextInput';

    default:
      console.debug(props.id + ' was not matched to component type');
      return null;
  }
}

export function setComponentType(props) {
  const component = getComponentType(props);

  if (props['dependents']) {
    const t = props.dependents.map(dep => {
      const d = getComponentType(dep)
      return ({ component: d, ...dep })
    })
    return ({ component, ...props, dependents: t });
  }
  return ({ component, ...props });
}

// Create collections of components to display together, the default is to
// group them my component type and if they have dependents
export default function createTypeMap(definitions) {
  const index = definitions.map(definition => setComponentType(definition)).reduce((prev, curr) => {
    const type = curr.component.toLowerCase();

    if (!prev.definitions[type]) prev.definitions[type] = [];

    if (type === 'boolean' && curr.dependents) {
      prev.definitions['conditional'].push(curr);
    } else {
      if (prev.types.indexOf(type) === -1) prev.types.push(type)
      prev.definitions[type].push(curr);
    }
    return prev;
  }, {
    types: ['conditional'],
    definitions: { conditional: [] }
  });

  return [index.types, index.definitions]
}
