import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconLeft extends React.Component {
  render() {
    var props = $.extend({}, this.props, {
      title: 'Previous',
      className: 'previous',
      path: "M21 17.3424313C21 18.2706698 20.2721959 19 19.3458998 19c-.4631481 0-.8932142-.1989082-1.1909522-.4972706l-5.1607927-5.4036739-5.12771076 5.3705225c-.62855809.6630275-1.68718224.6961789-2.34882234.0331514-.66164009-.6298761-.69472209-1.69072-.066164-2.3537475l6.3517449-6.63027471c.6285581-.66302747 1.6871822-.69617884 2.3488223-.06630275.033082 0 .033082.03315138.066164.06630275l6.3517449 6.63027471C20.83459 16.513647 21 16.9114635 21 17.3424313z",
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}
