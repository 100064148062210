import Modal from './Modal';
import React from 'react';

class MessageBox extends React.Component {
  state = {};

  componentDidMount() {
    window.showMessageBox = (message) => {
      this.setState({ message: message }, () => {
        $('#message-box').modal('show');
      });
    };
  }

  render() {
    return (
      <Modal id="message-box" modalClass="modal-dialog-centered">
        <p className="message-text">{this.state.message}</p>
        <div className="actions">
          <button type="button" className="btn btn-link" role="button" data-dismiss="modal">
            Ok
          </button>
        </div>
      </Modal>
    );
  }
}

export default MessageBox;
