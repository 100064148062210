import React from 'react';

const TabContent = ({
  expected,
  actual,
  children
}: {
  expected: string;
  actual: string;
  children?: any;
}) => {
  return actual === expected ? (
    <div className="tab-content">
      <div id={expected + '-tab'} />
      <div>{children}</div>
    </div>
  ) : null;
};

export default TabContent;
