/* Determines "equality" for scalar and array values */
function isEqual(first, second) {
  const firstIsArray = Array.isArray(first),
    secondIsArray = Array.isArray(second);

  if (firstIsArray && secondIsArray) {
    return first.length == second.length && first.every((val, idx) => val === second[idx]);
  } else if (firstIsArray || secondIsArray) {
    // Only one argument is an array. These are clearly not the same value.
    return false;
  } else {
    return first === second;
  }
}

/* Main `filterChanges` function */
export default function(oldParams, newParams, fields) {
  const changedParams = {};

  fields.forEach(field => {
    let oldValue = null;
    if (oldParams) {
      oldValue = oldParams[field];
    }
    const newValue = newParams[field];

    if (!isEqual(oldValue, newValue)) {
      changedParams[field] = newValue;
    }
  });

  return changedParams;
}
