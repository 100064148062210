import * as React from 'react';
import Hover from '../Hover';
import { IconZoom, IconZoomHover } from '../../icons/IconZoom';

export default function View() {
  return (
    <div className="overlay-icon">
      <Hover hoverContents={<IconZoomHover viewBoxX="-6" viewBoxY="-5" />}>
        <IconZoom viewBoxX="-6" viewBoxY="-5" />
      </Hover>
    </div>
  );
}
