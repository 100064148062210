import React from "react";
import * as ajax from "@/helpers/ajax";
import Section from "@library/section";
import useLink from "@library/link/useLink";
import {DateTime} from "luxon";
import "./styles";
import Diff from '@library/diff';


type Props = {
  setActive: (text: string) => void;
  canonicalPlace: {
    id: string;
  };
  changesJson?: {
    old_value: any;
    new_value: any;
    field: string;
    changed_at: string;
    author: string;
  }[];
};

function Changes(props: Props) {
  const [changes, setChanges] = React.useState(props.changesJson || []);

  const onClick = () => {
    const text = "changes";
    props.setActive(text);
    window.location.hash = text;
  };

  const changesLink = useLink({
    onClick,
    internalLink: "",
  });

  async function loadChanges() {
    const [promise] = ajax._get(
      "/listings/changes/" + props.canonicalPlace.id + ".json/?limit=5"
    );
    const json = await promise;

    if (json) {
      setChanges(json.changes);
    }
  }

  React.useEffect(() => {
    if (!props.changesJson) {
      loadChanges();
    }
  }, []);



  return (
    <div data-cy="dashboard-changes" className="editor__dashboard__changes">
      <Section
        header={
          <div className="editor__dashboard__changes__header">
            <h4 {...changesLink}>Changes</h4>
          </div>
        }
      >
        <div
          data-cy="changes-content"
          className="editor__dashboard__changes__content"
        >
          {changes.length === 0 ? (
            <i>none</i>
          ) : (
            changes.map((change, idx) => {
              return (
                <Diff {...change} key={idx + change.changed_at} hideTimestamp />
              );
            })
          )}
        </div>
      </Section>
    </div>
  );
}

export default Changes;
