import React, { PureComponent } from 'react';
import { SinglePathIcon } from './SinglePathIcon';

export default class IconEdit extends PureComponent {
  render = () => {
    const { width, height, ...props } = this.props;
    return (
      <SinglePathIcon
        {...props}
        width={width || '28'}
        height={height || '28'}
        path="M22 8.9a2.9 2.9 0 0 0-4.94-2.05l-8.98 8.97a.67.67 0 0 0-.15.24L6.04 21.1a.67.67 0 0 0 .86.86l5.04-1.9a.67.67 0 0 0 .24-.15l8.97-8.97c.55-.54.85-1.28.85-2.05zM7.81 20.18l1-2.67 1.67 1.67-2.67 1zm3.88-1.68l-2.2-2.2 7.08-7.09 2.2 2.2-7.08 7.09zm8.52-8.52l-.49.5-2.2-2.21.49-.49a1.56 1.56 0 0 1 2.2 2.2z"
      />
    );
  };
}

export { IconEdit };
