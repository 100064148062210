import React, { PureComponent } from 'react';
import { SinglePathIcon } from './SinglePathIcon';

export default class IconMatch extends PureComponent {
  render = () => {
    const { width, height, ...props } = this.props;
    return (
      <SinglePathIcon
        {...props}
        width={width || '28'}
        height={height || '28'}
        path="M16.25 5c-3.04695 0-5.61915 2.018475-6.4593 4.7907C7.0187 10.630175 5 13.20305 5 16.25 5 19.9787 8.021975 23 11.75 23c3.04695 0 5.61915-2.0187 6.4593-4.7907C20.9813 17.37005 23 14.79695 23 11.75 23 8.0213 19.9787 5 16.25 5zm-4.5 16.499925c-2.8944 0-5.249925-2.355525-5.249925-5.249925 0-2.0961 1.237725-3.90465 3.01815-4.74525C9.5153 11.587325 9.5 11.666525 9.5 11.75c0 3.7287 3.021975 6.75 6.75 6.75.083475 0 .162675-.0153.245475-.018225-.8415 1.780425-2.649375 3.01815-4.745475 3.01815zm6.731775-5.00445c.002925-.0828.018225-.162.018225-.245475 0-3.7287-3.021975-6.75-6.75-6.75-.083475 0-.162675.0153-.244575.01845.8406-1.78065 2.648475-3.018375 4.744575-3.018375 2.8944 0 5.249925 2.35485 5.249925 5.249925 0 2.0961-1.237725 3.903975-3.01815 4.745475z"
      />
    );
  };
}

export { IconMatch };
