import React, { Component } from 'react';
import PropTypes from 'prop-types';

const notNullAppendComma = str => (str === null || str === '' ? null : str + ',');

export class ListingDetailsBase extends Component {
  render() {
    const { name, locations } = this.props;

    return (
      <React.Fragment>
        <div className="d-flex">
          <span className="boone-listing__details__name">
            <b>{name}</b>
          </span>
        </div>
        {locations &&
          locations.map((location, idx) => {
            // construct address
            const { address1, address2, city, state, postal_code, country } = location;
            const inUSA = country === 'USA';
            const address = [
              notNullAppendComma(address1),
              notNullAppendComma(address2),
              notNullAppendComma(city),
              inUSA ? state : notNullAppendComma(state),
              inUSA ? postal_code : notNullAppendComma(postal_code),
              inUSA ? null : country
            ]
              .filter(item => item && item)
              .join(' ');

            return (
              <span className="boone-listing__details__location" key={'listing-name-location-' + idx}>
                <small data-cy="location-address">{address}</small>
              </span>
            );
          })}
      </React.Fragment>
    );
  }
}

ListingDetailsBase.propTypes = {
  name: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  detailsViewPath: PropTypes.string,
  className: PropTypes.string
};

const ListingDetails = props => (
  <div className="boone-listing__details">
    <ListingDetailsBase {...props} />
  </div>
);

export default ListingDetails;
