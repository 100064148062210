import * as React from "react";
import * as ajax from "@/helpers/ajax";
import clone from "@/helpers/DeepClone";
import policy from "@/media/data/policy";
import normalizeData from "@/media/data/normalizeData";
import filterMedia from "@/media/data/filterMedia";
import openImagePicker from "@/helpers/imageUpload";
import Media from "./Media";
import MediaModalView from "@/media/MediaModal";
import Upload from "@/media/actions/Upload";
import {useStoreContext} from "@/state";
import "./styles";
import cn from "classnames";

type Props = {
  media: any;
  targetKey: string;
  ownerId: string;
  ownerKey: string;
  isEditable: boolean;
  apiUrl: string;
  mediaUrl: string;
  imageUrl: string;
  id: string;
  orgUser: boolean;
  superAdminUser: boolean;
  internalUser: boolean;
  userEditingStrategy: "update" | "suggest" | "none";
  filestackStoragePath: string;
  filestackApiKey: string;
};

const filters = {
  all: "fromAll",
  owner: "fromOwner",
  org: "fromOrg",
  others: "fromOthers",
};

const labels = {
  fromAll: "All photos",
  fromOwner: "Photos by business owner",
  fromOrg: "Photos from your org",
  fromOthers: "Photos by others",
};

function Filter(props) {
  return (
    <button
      onClick={(e) => props.filterPhotos(e, props.target)}
      className={cn({
        editor__button: true,
        "editor__button--white": props.filter !== props.target,
        "editor__button--primary": props.filter === props.target,
      })}
    >
      {labels[props.target]}
    </button>
  );
}

function ImageGallery(props: Props) {
  const t = normalizeData(props.media);
  const [filter, setFilter] = React.useState(filters.all);
  const [selectedMedia, setSelectedMedia] = React.useState({});
  const [ids, setIds] = React.useState(t.ids);
  const [byId, setById] = React.useState(t.byId);
  const {analytics} = useStoreContext();

  const permissions = policy(
    props.orgUser,
    props.internalUser,
    props.superAdminUser
  );

  React.useEffect(() => {
    if (selectedMedia) {
      analytics.trackListing("Photo Details", "opened");
    }
  }, [selectedMedia]);

  const filteredMedia = React.useMemo(
    () => filterMedia(ids.map((id) => byId[id])),
    [ids, byId]
  );

  const updateById = (v) => setById({...byId, [v.id]: {...byId[v.id], ...v}});

  const analyticsTrackPhoto = (action, photo_id) => {
    analytics.trackListing("Photo", action, {photo_id});
  };

  const addMedia = (v) => {
    setIds((prevState) => [...prevState, v.id]);
    setById((prevState) => ({...prevState, [v.id]: {...v}}));
    analyticsTrackPhoto("added", v.id);
  };

  const imageThumbnailUrl = (image) => {
    if (
      image["sizes"] &&
      image["sizes"]["square"] &&
      image["sizes"]["square"]["url"]
    ) {
      return image["sizes"]["square"]["url"];
    } else if (
      image["sizes"] &&
      image["sizes"]["thumbnail"] &&
      image["sizes"]["thumbnail"]["url"]
    ) {
      return image["sizes"]["thumbnail"]["url"];
    } else {
      return image.image_url;
    }
  };

  const handleFilestack = (response) => {
    response.filesUploaded.forEach(async (uploadedFile) => {
      const response = await ajax.post(props.apiUrl, {
        [props.targetKey]: {
          url: uploadedFile["url"],
          [props.ownerKey]: props.ownerId,
        },
      });
      const json = await response.json();

      if (!$.isEmptyObject(json.errors)) {
        console.debug(json.errors);
      } else {
        json["from_your_org"] = true; // Your org just uploaded it.
        addMedia({...json, image_url: json.url});
      }
    });
  };

  const handleUpload = () => {
    openImagePicker(
      props.filestackApiKey,
      (res) => handleFilestack(res),
      props.filestackStoragePath,
      10
    );
  };

  const disabledMedia = (id) => {
    const t = clone(byId[id]);
    t.flags = [
      {
        flagged_item_id: id,
        id: -1,
        reason: "disabled",
        canonical_place_id: -1,
        flagged_item_type: "PlaceImage",
      },
    ];
    updateById(t);
    analyticsTrackPhoto("deleted", id);
  };

  const flaggedMedia = (id) => {
    const t = clone(byId[id]);
    t.flags = [
      {
        flagged_item_id: id,
        id: -1,
        reason: "flagged",
        canonical_place_id: -1,
        flagged_item_type: "PlaceImage",
      },
    ];
    updateById(t);
    analyticsTrackPhoto("flagged", id);
  };

  const unflaggedMedia = (id) => {
    const t = clone(byId[id]);
    t.flags = [];
    updateById(t);
    analyticsTrackPhoto("unflagged", id);
  };

  const disableViaModal = (id) => {
    $("#deleteConfirmation").modal("show");

    let confirmDeleteButton = $("#confirm-delete-button");
    confirmDeleteButton.off("click");

    confirmDeleteButton.on("click", () => {
      $("#deleteConfirmation").modal("hide");

      ajax.post(props.apiUrl + id + "/disable", {
        [props.ownerKey]: props.ownerId,
      });

      disabledMedia(id);
    });
  };

  const updateMedia = (id, updatedAttributes) => {
    const t = {...clone(byId[id]), ...updatedAttributes};
    updateById(t);
    analytics.trackListing("Photo Details", "saved");
  };

  const featuredMedia = (id, featured) => {
    const t = {...clone(byId[id]), featured};

    updateById(t);
    featured
      ? analyticsTrackPhoto("featured", id)
      : analyticsTrackPhoto("unfeatured", id);
  };

  const handleZoom = (id) => {
    setSelectedMedia(clone(byId[id]));
    $("#media-modal").modal("show");
  };

  const filterPhotos = (e, filter) => {
    e.preventDefault();
    setFilter(filter);
    analytics.track("Photos", "filtered", {filter});
  };

  const renderMedia = (mediaFilter) => {
    return filter === filters.all || filter === mediaFilter
      ? filteredMedia[mediaFilter]
          .map((id) => byId[id])
          .map((media) => (
            <Media
              key={media.id}
              url={props.apiUrl + media.id}
              imageUrl={imageThumbnailUrl(media)}
              featured={media.featured}
              flagged={media.flags && media.flags.length > 0}
              mediaId={media.id}
              width={media.width}
              height={media.height}
              sourceName={media.source_name}
              sourceId={media.source_id}
              attributionName={media.attribution_name}
              attributionUrl={media.attribution_url}
              caption={media.caption}
              mediaDisabled={() => disabledMedia(media.id)}
              mediaFlagged={() => flaggedMedia(media.id)}
              mediaUnflagged={() => unflaggedMedia(media.id)}
              featuredMediaChanged={(featured) =>
                featuredMedia(media.id, featured)
              }
              onClick={() => handleZoom(media.id)}
              canFlagMedia={permissions.canFlagMedia[mediaFilter]}
              canFeatureMedia={permissions.canFeatureMedia[mediaFilter]}
              canDisableMedia={permissions.canDisableMedia[mediaFilter]}
              canViewMedia={permissions.canViewMedia[mediaFilter]}
              targetKey={props.targetKey}
              ownerId={props.ownerId}
              ownerKey={props.ownerKey}
              userEditingStrategy={props.userEditingStrategy}
            />
          ))
      : null;
  };

  const mediaSelected = Boolean(selectedMedia && selectedMedia["id"]);
  return (
    <div className="editor__gallery">
      {props.userEditingStrategy !== "none" && mediaSelected && (
        <MediaModalView
          media={selectedMedia}
          targetKey={props.targetKey}
          apiUrl={props.apiUrl}
          onDelete={() => disableViaModal(mediaSelected)}
          onUnflag={() => unflaggedMedia(mediaSelected)}
          onSave={updateMedia}
          isEditable={props.isEditable}
        />
      )}

      <div className="editor__gallery__controls">
        <Filter
          filter={filter}
          filterPhotos={filterPhotos}
          target={filters.all}
        />
        <Filter
          filter={filter}
          filterPhotos={filterPhotos}
          target={filters.owner}
        />
        <Filter
          filter={filter}
          filterPhotos={filterPhotos}
          target={filters.org}
        />
        <Filter
          filter={filter}
          filterPhotos={filterPhotos}
          target={filters.others}
        />
      </div>

      <div id={props.id} className="editor__gallery__items">
        {renderMedia(filters.owner)}
        {renderMedia(filters.org)}
        {renderMedia(filters.others)}
        {props.userEditingStrategy !== "none" &&
        (filter === filters.org || filter === filters.all) ? (
          <div className="editor__media__upload">
            <Upload
              onUpload={handleUpload}
              className={"editor__media__upload__control"}
            />
          </div>
        ) : (
          <div>no media</div>
        )}
      </div>
    </div>
  );
}

ImageGallery.defaultProps = {
  resize: true,
};

export default ImageGallery;
