import React from 'react';
import Separator from '@/library/Separator';
import DatePicker from './closure-date-picker';
import Reason from './Reason';

const START_DATE_ID = 'start_date';
const END_DATE_ID = 'end_date';

type Props = {
  startDate: string | null;
  setStartDate: (date: string) => void;
  endDate: string | null;
  setEndDate: (date: string) => void;
  reason: string;
  setReason: (reason: string) => void;
};

const TemporaryClosure = (props: Props) => {
  const [focused, setFocused] = React.useState(null);

  const setStartDate = (v) => {
    props.setStartDate(v);
    setFocused(END_DATE_ID);
  };

  const setEndDate = (v) => {
    props.setEndDate(v);
    setFocused(null);
  };

  return (
    <div className="listing-status__temporary-closures__single-closure listing-status__temporary-closures__single-closure__container">
      <div className="listing-status__temporary-closures__single-closure__dates">
        <div>
          <Separator> from </Separator>
        </div>
        <DatePicker
          id={START_DATE_ID}
          key={'closure-' + START_DATE_ID}
          date={props.startDate}
          focused={focused === START_DATE_ID}
          handleChange={(p, v) => setStartDate(v)}
          onFocusChange={({ focused }) =>
            setFocused(focused ? START_DATE_ID : null)}
        />
        <Separator> through </Separator>
        <DatePicker
          id={END_DATE_ID}
          key={'closure-' + END_DATE_ID}
          indefinitelyPreset
          minimum={props.startDate}
          date={props.endDate}
          focused={focused === END_DATE_ID}
          handleChange={(p, v) => setEndDate(v)}
          onFocusChange={({ focused }) =>
            setFocused(focused ? END_DATE_ID : null)}
        />
        <Separator> for </Separator>
        <Reason
          reason={props.reason}
          handleChange={(p, v) => props.setReason(v)}
        />
      </div>
    </div>
  );
};

export default TemporaryClosure;
