import React from 'react';
import './styles.scss';
import Search from '@/library/tree-select/search-tree/Search';
import useSearchTree from '@/library/tree-select/search-tree/useSearchTree';
import useIndex from "@/hooks/useIndex";
import Card from './card';


export default function Discoverability(props) {

    const [error, setError] = React.useState(false);
    const [selection, setSelection] = React.useState(
        props.selected.map((obj) => obj.public_id)
    );

    const {
        setQuery,
        query,
        cache,
        currentTree
    } = useSearchTree({
        ...props,
        save: async function (categories) {
            try {
                setSelection(categories);
                if (error) setError(false);
            } catch (e) {
                console.error(e);
                setError(true);
            } 
        },
        index: useIndex(props.categories, "public_id", {
            clearChildren: false,
        }),
        selection,
    });


    return (
        <div className="discoverability-view">
            <Search query={query} getQuery={(n) => setQuery(n)} />
            <ul className="__root-list">
                {cache[currentTree].length > 0 ? (
                    cache[currentTree].map((root) => (
                        <Card {...root} key={root.public_id}></Card>
                    ))
                ) : (
                    <div className="text-muted" onClick={() => setQuery("@")}>
                        {"none"}
                    </div>
                )}
            </ul>
        </div>
    )
}