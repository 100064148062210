import * as React from "react";
import { useState, useEffect } from 'react';
import * as ajax from '../../helpers/ajax';
import Relative from "./relative";
import './styles.scss';
import Options from "./options";
import Suggestions from "./suggestions";

export default function Relationships({ ...props }) {
  const [parent, setParent] = useState(null);
  const [children, setChildren] = useState([]);
  const [toggleAddParent, setToggleAddParent] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCanonicalPlace = (canonicalPlaceId: string, setRelative: Function) => {

    setLoading(true);
    const [promise, abort] = ajax._fetch(`/listings/${canonicalPlaceId}.json`);
    return promise
      .then(response => {
        setRelative(response);
        setLoading(false);
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          console.error(error);
          setLoading(false);
        }
      });
  }

  const setCanonicalPlaceParent = async (canonicalPlaceId: string, newParentId: any) => {
    setLoading(true);
    const payload = {
      listing: {
        parent: newParentId
      }
    }

    const [promise, abort] = ajax._put(`/listings/${canonicalPlaceId}`, payload);
    return promise
      .then(response => getCanonicalPlace(response.parent.id, setParent))
      .catch(error => {
        if (error.name === 'AbortError') {
          console.error(error);
        }
      });
  }

  const getCanonicalPlaceRelationships = () => {

    props.parent && getCanonicalPlace(props.parent.id, setParent);
    props.children && props.children.map(child => getCanonicalPlace(child.id, data => setChildren([...children, data])));
  }

  const handleSetParentToggle = () => setToggleAddParent(!toggleAddParent);

  const handlePopulateRelationshipOptions = () => {
      return [{
        name: "parent",
        label: "Set Parent",
        onClick: handleSetParentToggle
      }];
  }

  const handleSetParent = async (newParent) => {
    await setCanonicalPlaceParent(props.canonicalPlaceId, newParent.id);
  }

  const handleRemoveParent = async () => {
    await setCanonicalPlaceParent(props.canonicalPlaceId, null);
    setParent(null);
    setLoading(false);
  }

  useEffect(() => {
    getCanonicalPlaceRelationships();
  }, []);

  return (
    <div className="relationship-view">
      {loading && (
        <div className="relationship-view__loading">
          <h3>Loading...</h3>
        </div>)}
      <div className="relationship-view__header">
        <span><span className="relationship-view__header__label">Relationship View </span>
          <Options options={handlePopulateRelationshipOptions()} />
        </span>
      </div>

      <div className="relationship-view__section">
        <div className="relationship-view__section__header">
          <p className="relationship-view__section__header__label">Parent</p>
        </div>
      </div>

      {!parent ? (
        <div className="relationship-view__section">
          <p className="relationship-view__no-data">No Data Available.</p>
        </div>
      ) :
        parent && (
          <div className="relationship-view__section">
            <Relative removeAction={handleRemoveParent} {...parent}></Relative>
          </div>
        )}

      {toggleAddParent && (
        <div className="relationship-view__section">
          <div className="relationship-view__section__header">
            <p className="relationship-view__section__header__label">Set Parent</p>
            <button className="btn" onClick={handleSetParentToggle}> Close </button>
          </div>
          <Suggestions setRelative={handleSetParent} />
        </div>
      )}

      <div className="relationship-view__section">
        <div className="relationship-view__section__header">
          <p className="relationship-view__section__header__label">Children</p>
        </div>
      </div>

      {children.length <= 0 ? (
        <div className="relationship-view__section">
          <p className="relationship-view__no-data">No Data Available.</p>
        </div>
      ) :
        children.map(child => (
          <div key={child.id} className="relationship-view__section">
            <Relative {...child}></Relative>
          </div>
        ))
      }
    </div>
  );
}