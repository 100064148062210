import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import AuditAssignmentDropdown from './AuditAssignmentDropdown';
import * as ajax from '@/helpers/ajax';
import * as pt from '../helpers/propTypes';

// The data for state.auditsTruncResults is set in session storage by the
// ListingsSearchResults component after each search. It is then read from
// session storage and set to state when the user interacts with the dropdown.
// Session storage is used in this component because it is mounted independently,
// its props are not updated for each new search, and it requires data from
// the most current search results

class BulkListingAuditLink extends Component {
  state = {
    auditsTruncResults: JSON.parse(sessionStorage.getItem('boone_audits'))
  };

  saveSessionData = () => {
    this.setState({
      auditsTruncResults: JSON.parse(sessionStorage.getItem('boone_audits'))
    });
  };

  render() {
    const { users, className } = this.props;
    return (
      <div onMouseEnter={() => this.saveSessionData()} className={cn(['audit__dropdown', className])}>
        <AuditAssignmentDropdown
          right={false}
          hasAudit={false}
          isNestedDropdown={true}
          closeParentDropdown={this.props.closeParentDropdown}
          forceShowRemoveOption={true}
          showOnHover={true}
          id={undefined}
          assignedUserId={null}
          onUserClick={id => this.assign(id)}
          onAnyoneClick={() => this.assign()}
          onRemoveClick={() => this.remove()}
          users={users}
          toggleIcon="label-no-icon"
          menuClassName="boone-dropdown boone-dropdown--nested boone-dropdown__menu boone-dropdown__menu--left"
          Label={() => <div className={cn({ 'audit-assignment': true, 'link-super-admin': true })}>Assign audit</div>}
        />
      </div>
    );
  }

  // The following methods kick off a batch operation and then
  // individually update the audits for each listing on the current
  // page so the user has visual confirmation that the batch process
  // has started

  assign = async userId => {
    // Kick off batch operation
    const searchParams = parseQueryString();
    ajax.post('/listings/assign_audits/', Object.assign(searchParams, { assign_to_user_id: userId }));

    // Individual updates
    const { auditsTruncResults } = this.state;
    if (auditsTruncResults.length > 0) {
      let counter = 0;
      auditsTruncResults.forEach(async ({ id, audit }) => {
        if (audit) {
          const response = await ajax.put('/listing_audits/' + audit.id, {
            user_id: userId
          });
        } else {
          const response = await ajax.post('/listing_audits/', {
            canonical_place_id: id,
            user_id: userId
          });
        }
        counter++;
        if (counter === auditsTruncResults.length) {
          runSearch();
        }
      });
    }
  };

  remove = () => {
    // Kick off batch operation
    const searchParams = parseQueryString();
    ajax.destroy('/listings/destroy_audits/', searchParams);

    // Individual updates
    // Not all listing results will have audits. Filter out the ones that do not.
    const auditsTruncResults = this.state.auditsTruncResults.filter(tr => tr.audit);
    if (auditsTruncResults.length > 0) {
      let counter = 0;
      auditsTruncResults.forEach(async ({ audit }) => {
        await ajax.destroy('/listing_audits/' + audit.id);
        counter++;
        if (counter === auditsTruncResults.length) {
          runSearch();
        }
      });
    }
  };
}

BulkListingAuditLink.defaultProps = {
  isNestedDropdown: true
};

BulkListingAuditLink.propTypes = {
  className: PropTypes.string,
  closeParentDropdown: PropTypes.func,
  isNestedDropdown: PropTypes.bool,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      avatar_url: PropTypes.string,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BulkListingAuditLink;
