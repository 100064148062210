import * as turf from '@turf/turf';

// to create an inset, pass in a negative number, offset a positive number
export const visibleInMap = (map, point, t = 0, b = 0, l = 0, r = 0) => {
  if (point.length !== 2 || typeof point[0] !== 'number' || typeof point[1] !== 'number') {
    return false;
  }
  const pt = turf.point(point);
  const canvas = map.getCanvas();
  const w = canvas.width;
  const h = canvas.height;

  const unproject = pt => map.unproject(pt).toArray();

  // [width coord + offset/inset, height coord + offset/inset]
  const topLeft = unproject([l, t]); // default [0,0]
  const topRight = unproject([w + r, t]); // default [width, 0]
  const bottomRight = unproject([w + r, h + b]); // default [width, height]
  const bottomLeft = unproject([l, h + b]); // default [0, height]

  const coordinates = [topLeft, topRight, bottomRight, bottomLeft, topLeft];
  const polygon = turf.polygon([coordinates]);

  return turf.booleanPointInPolygon(pt, polygon);
};
