import React from "react";

export type clearRequiredFields = {
  [field: string]: boolean;
};

export type RequiredSpec<S> = {
  [field: string]: {
    checkValue: (state: S) => boolean;
    dependents?: string[];
  };
};

export type UseRequired<S> = {
  requiredFields: clearRequiredFields;
  checkFields: (state: S) => boolean;
  clearRequiredFields: () => void;
};

function useRequired<S>(required: RequiredSpec<S>): UseRequired<S> {
  const [requiredFields, setRequiredFields] = React.useState<
    clearRequiredFields | {}
  >({});

  function checkRequired(state, field) {
    const req = required[field];

    const results = req.checkValue(state);
    setRequiredFields((r) => ({ ...r, [field]: results }));
    console.debug(field, results)
    return results;
  }

  function checkFields(state: S) {
    const res = Object.keys(required).map((field) =>
      checkRequired(state, field)
    );
    const ok = res.reduce((p, c) => {
      p = p && c;
      return p;
    }, true);
    return ok;
  }

  return {
    checkFields,
    requiredFields,
    clearRequiredFields: () => setRequiredFields({}),
  };
}

export default useRequired;
