import React, {PureComponent} from "react";
import Dropdown from "@library/dropdown";
import type {Props as DropdownProps} from "@library/dropdown";
import AssignUserOption from "@library/dropdown/options/AssignUserOption";
import RemoveOption from "@library/dropdown/options/RemoveOption";

export type Props = {
  id?: number;
  forceShowRemoveOption?: boolean;
  hasAudit?: boolean;
  showAnyoneOption?: boolean;
  showRemoveOption?: boolean;
  onRemoveClick?: () => void;
  onAnyoneClick?: (user_id?: number) => void;
  onUserClick?: (user_id: number) => void;
  assignedUserId?: number;
  hideLastName?: boolean;
  users?: {
    id: number;
    name: string;
    avatar_url?: string;
  }[];
} & DropdownProps;

export class AuditAssignmentDropdown extends PureComponent<Props> {
  static defaultProps = {
    isNestedDropdown: true,
    showOnHover: true,
    showOnClick: false,
    toggleIcon: "arrow-right",
    direction: "right",
    className: "boone-dropdown boone-dropdown--nested",
    menuClassName:
      "boone-dropdown boone-dropdown--nested boone-dropdown__menu boone-dropdown__menu--right",
    showRemoveOption: true,
    forceShowRemoveOption: false,
    showAnyoneOption: true,
    hideLastName: true,
  };

  render() {
    return (
      <Dropdown
        closeParentDropdown={this.props.closeParentDropdown}
        isNestedDropdown={this.props.isNestedDropdown}
        showOnHover={this.props.showOnHover}
        toggleIcon={this.props.toggleIcon}
        direction={this.props.direction}
        className={this.props.className}
        menuClassName={this.props.menuClassName}
        divider={this.props.divider}
        right={this.props.right}
        Label={this.props.Label}
      >
        {[
          /** Remove option only visible in dropdown when an audit exists */
          (this.props.forceShowRemoveOption ||
            (this.props.hasAudit && this.props.showRemoveOption)) && (
            <RemoveOption
              key="audit-assignment-remove"
              onClick={this.props.onRemoveClick}
            />
          ),
          /** When assign to Anyone is clicked, we call createAudit or updateAudit without an id */
          this.props.showAnyoneOption && (
            <AssignUserOption
              key="audit-assignment-anyone"
              booneLogo={true}
              onClick={() => this.props.onAnyoneClick()}
              assigned={this.props.hasAudit && !this.props.assignedUserId}
            />
          ),
          /** Users comprise the remainder of the dropdown options */
          ...this.props.users.map((user) => (
            <AssignUserOption
              key={"audit-assignment-user-" + user.id}
              onClick={() => {
                this.props.onUserClick(user.id);
              }}
              hideLastName={this.props.hideLastName}
              name={user.name}
              url={user.avatar_url}
              assigned={user.id === this.props.assignedUserId}
            />
          )),
        ]}
      </Dropdown>
    );
  }
}

export default AuditAssignmentDropdown;
