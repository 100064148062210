import React from "react";
import LaunchDarklyProvider from "@library/launch-darkly/provider";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {Provider as ContextProvider} from "./useContext";
import useParams from "./useParams";

function Providers({children, data, ...props}) {
  const params = useParams();



  const LDProvider = ({children}) =>
    props.launchDarkly ? (
      <LaunchDarklyProvider
        user={props.launchDarkly["user"]}
        clientID={props.launchDarkly["client"]}
      >
        {children}
      </LaunchDarklyProvider>
    ) : (
      <>{children}</>
    );

  const ReCaptchaProvider = ({children}) =>
    props.reCaptchaKey ? (
      <GoogleReCaptchaProvider reCaptchaKey={props.reCaptchaKey}>
        {children}
      </GoogleReCaptchaProvider>
    ) : (
      <>{children}</>
    );

  return (
    <LDProvider>
      <ReCaptchaProvider>
        <ContextProvider {...data} {...params}>{children}</ContextProvider>
      </ReCaptchaProvider>
    </LDProvider>
  );
}

export default Providers;
