import React, { ReactNode, useRef, useEffect } from 'react';

interface props {
  children?: ReactNode;
  onOutsideClick: Function;
}

export const OutsideClickHandler = ({children, ...props }: props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: any) => {
    wrapperRef.current &&
    !wrapperRef.current.contains(event.target) &&
    props.onOutsideClick();
  }

useEffect(() => {
  document.addEventListener('mousedown', handleOutsideClick);
  return () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  }
}, [children]);
  return (
    <div ref={wrapperRef as React.RefObject<HTMLDivElement>}>
      {children}
    </div>
  )
}
