import React, { PureComponent } from 'react';
import { SinglePathIcon } from './SinglePathIcon';

export class IconCheck extends PureComponent {
  render() {
    return (
      <SinglePathIcon
        {...this.props}
        path="M20.93 8a.99.99 0 0 0-.71.35l-8.09 8.95-4.43-4.16a.96.96 0 0 0-1.01-.21c-.35.14-.6.47-.67.87-.07.4.06.81.34 1.08l5.16 4.85a.96.96 0 0 0 1.4-.07l8.77-9.7c.3-.33.4-.83.23-1.27a1.03 1.03 0 0 0-1-.69z"
      />
    );
  }
}

export default IconCheck;
