import React from "react";
import {useWizardContext} from "../useWizardContext";
import Button from "@library/_button";
import "./styles/index";

function Footer() {
  const wz = useWizardContext();

  const onClick = async () => {
    if (wz.step.save) {
      const ok = await wz.step.save();
      if (ok) {
        wz.nextStep();
      }
    } else {
      wz.nextStep();
    }
  };

  return (
    <div className="editor__wizard__footer">
      {!wz.step.hideBack && (
        <Button
          className="editor__button--back"
          disabled={wz.start}
          onClick={() => {
            wz.previousStep();
          }}
        >
          {wz.step.backButtonText || "back"}
        </Button>
      )}
      {!wz.step.hideNext && (
        <Button
          className="editor__button--next"
          disabled={wz.end}
          onClick={onClick}
        >
          {wz.step.nextButtonText || "next"}
        </Button>
      )}
    </div>
  );
}

export default Footer;
