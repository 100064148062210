import React from "react";

function CorrectionDiff(props) {
  const diffField = function(field) {
    let change = props.correction[field];
    let current = props.current[field];

    if (Array.isArray(change)) {
      change = change.join(', ');
    }

    if (Array.isArray(current)) {
      current = current.join(', ');
    }

    if (change && change != current) {
      return {
        fieldName: props.diffFields[field],
        changed: true,
        current: current,
        correction: change
      };
    }
    return {
      fieldName: props.diffFields[field],
      changed: false,
      current: current,
      correction: current
    }
  };

  const rowData = Object.keys(props.diffFields).map(diffField);

  return (
    <table className="editor__correction__diff--table">
      <tr>
        <th>Field</th>
        <th>Current</th>
        <th>Correction</th>
        <th>Changed</th>
      </tr>
      { rowData.map((row) => {
        return (
          <tr className={row.changed ? 'editor__correction__diff--change' : ''}>
            <td>{row.fieldName}</td>
            <td>{row.current}</td>
            <td>{row.correction}</td>
            <td>{row.changed ? 'Yes' : 'No'}</td>
          </tr>
        );
      })}
    </table>
  )
}

export default CorrectionDiff;
