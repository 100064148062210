import React, { useEffect } from 'react';
import { Map } from '@commodityvectors/react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export interface Props {
  options?: any;
  map: any;
  disableRotate?: boolean;
  bounds: Array<Array<number>>;
  passMap: Function;
};

// controls how much of the map you see, expects bounds to be formatted as
// [ [south,west], [north,east] ]
const BoundsControl = ({ ...props }: Props) => {
  useEffect(() => {
    const { map, bounds, disableRotate = true, ...options } = props;
    if (bounds) {
      map.fitBounds(bounds, {
        linear: true,
        maxZoom: 10,
        padding: { top: 15, bottom: 15, left: 30, right: 40 },
        // any other fit bounds options
        ...options
      });
    }
    if (disableRotate) {
      map.dragRotate.disable();
      map.touchZoomRotate.disableRotation();
    }
  }, [props.bounds]);

  return null;
};

export default Map.component(BoundsControl);
