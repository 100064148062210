import * as React from 'react';
import * as Buttons from '../../library/Buttons';
//import './styles/index.scss'

type EditorProps = {
  showAdd: boolean;
  showRemove: boolean;
  children: any;
  onRemove: any;
  onAdd: any;
  rmContentDiv?: boolean;
  cy?: string;
};

export const Editor = ({
  children,
  showRemove = true,
  showAdd,
  onRemove,
  onAdd,
  rmContentDiv = false,
  cy
}: EditorProps) => {
  return (
    <div className="editor__group-editor__item" data-cy={cy || 'group-editor-item'}>
      {rmContentDiv ? children : <div
        data-cy='group-editor-item-content' className="editor__group-editor__item__content">{children}</div>}
      <div data-cy='group-editor-item-controls' className="editor__group-editor__item__controls">
        {showRemove && <Buttons.RoundRemoveButton cy="group-editor-item-remove-button" small onClick={onRemove} />}
        {showAdd && <Buttons.RoundAddButton cy="group-editor-item-add-button" small onClick={onAdd} />}
      </div>
    </div>
  );
};

type Props = {
  getChanges: (values: any[]) => void;
  values: any;
  // placeholder value
  emptyValue: any;
  children: any;
  rmContentDiv?: boolean;
  allowNone: boolean;
  cy?: string;
};

/**
 * description:  pass in editor component as child & apply value / setValue
 *
 * usage:       <GroupEditor emptyValue={''} getChanges={n => setChanges(n)} values={values}>
 *                {({ value, setValue }) => (
 *                     <InputWithLabel label="Articles" value={value} onChange={e => setValue(e.target.value)} />
 *                 )}
 *              </GroupEditor>
 * */
export const GroupEditor = (props: Props) => {
  const [values, setValues] = React.useState(props.values);

  React.useEffect(() => {
    if (!props.allowNone && values.length === 0) {
      addValue();
    }
    if (props.getChanges) props.getChanges(values);
  }, [values]);

  const removeValue = i => {
    let t = [...values];
    t.splice(i, 1);
    setValues(t);
  };

  const addValue = () => {
    setValues([...values, props.emptyValue]);
  };

  const updateValues = (i, value) => {
    let t = [...values];
    t[i] = value;
    setValues(t);
  };

  return (
    <div data-cy="group-editor" className="editor__group-editor">
      {values.map((val, i) => (
        <Editor
          cy={props.cy}
          showAdd={i === values.length - 1}
          showRemove={i > 0}
          key={'o' + i}
          onRemove={() => removeValue(i)}
          onAdd={() => addValue()}
          rmContentDiv={props.rmContentDiv}
        >
          {props.children({ value: val, setValue: v => updateValues(i, v), i })}
        </Editor>
      ))}
    </div>
  );
};

GroupEditor.defaultProps = {
  allowNone: false
};

export default GroupEditor;
