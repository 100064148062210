/* eslint-disable no-restricted-globals */
import React from 'react';
import _ from 'lodash';
import { assign, map } from 'lodash/fp';
import * as ajax from '@/helpers/ajax';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import geoViewport from '@mapbox/geo-viewport';
import { LngLatBounds } from 'mapbox-gl';
import ReactMapboxGl, { Layer, Source, ScaleControl } from 'react-mapbox-gl';

import styled from 'styled-components';

// Components
import Canonical from './Canonical';
import MatchActions from './MatchActions';

const MAPSTYLES = ['satellite-streets', 'streets', 'satellite'];

// -----------------------------------------------
// STYLED-COMPONENTS -----------------------------
// -----------------------------------------------
const StyledMapContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100% * 1 / 3);
`;

const StyledMapStyleChooser = styled.div`
   line-height: 12px;
   font-size: 8pt;
   margin-top: 1px;
   margin-right: 2px;
   position:absolute;
   top:0;
   right:0;
`;

const StyledMatchArea = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(100% * 2 / 3);
  padding: 16px;
  overflow-y: scroll;
  background: #fefefe;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
`;

const StyledMatchTitle = styled.h1`
  float: left;
`;

const StyledAlertBox = styled.div`
  background-color: #f2f4f5;
  border: 1px solid #708e9a;
  border-radius: 3px;
  margin-bottom: 2em;
  padding: 1.25em;
`;

// ===============================================
// COMPONENT->MATCH ==============================
// ===============================================
export default class Match extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props) {
    super(props);

    const { canonicalId } = this.props;
    const { target, candidates, map } = this.props;
    const viewport = this.initialViewport();

    this.state = {
      target,
      candidates,
      id: target.id,
      lng: viewport.center[0].toFixed(4),
      lat: viewport.center[1].toFixed(4),
      zoom: viewport.zoom.toFixed(2),
      mapStyleIndex: 0
    };
  }

  getMapStyle(mapStyleIndex) {
    return MAPSTYLES[mapStyleIndex % MAPSTYLES.length];
  }

  pathMatchType = () => {
    const { matchType } = this.props;
    return matchType === 'canonical' ? 'canonical_place' : 'match_set';
  };

  makeAPIUrl = suffix => {
    return `/match/${this.pathMatchType()}${suffix}`;
  };

  sendMatchAction = (type, additional = {}, callback = response => console.debug(response)) => {
    const data = Object.assign(
      {
        type,
        target_place_ids: _.map(this.state.target.places, _.property('id')),
        target_canonical_id: _.toInteger(this.props.canonicalId)
      },
      additional
    );

    ajax
      .post(this.makeAPIUrl('/confirm/'), data)
      .then(response => response.json())
      .then(callback)
      .catch(error => {
        console.error(error);
      });
  };

  confirmAllMatches = e => {
    if (!window.confirm('This will confirm all matches and non-matches. Are you sure?')) {
      return;
    }

    // Collect all places for the ajax call
    let allPlaces = _.map(this.state.candidates, candidate => ({
      canonical_id: candidate.id,
      place_ids: _.map(candidate.places, place => place.id)
    }));

    if (_.isObject(this.state.newCanonical)) {
      allPlaces = _.concat(allPlaces, { place_ids: _.map(this.state.newCanonical.places, place => place.id) });
    }

    // Perform the ajax call for confirm_all
    this.sendMatchAction(
      'confirm_all',
      {
        all_places: allPlaces
      },
      response => {
        this.setState(prevState =>
          update(prevState, {
            target: {
              isCollapsed: { $set: true },
              isConfirmed: { $set: true }
            },
            newCanonical: {
              $set: undefined
            },
            candidates: {
              $unshift: [
                {
                  id: response.new_canonical_place_id,
                  places: prevState.newCanonical && prevState.newCanonical.places ? prevState.newCanonical.places : []
                }
              ],
              $apply: map(assign({ isCollapsed: true, isConfirmed: true }))
            }
          })
        );
      }
    );
  };

  // ---------------------------------------------
  // HANDLERS->CONFIRMATIONS ---------------------
  // ---------------------------------------------
  handleDoesNotMatch = (e, id) => {
    this.sendMatchAction('non_match', {
      non_match_canonical_id: id,
      non_match_place_ids: _.map(_.find(this.state.candidates, { id }).places, _.property('id'))
    });

    this.setState(prevState =>
      update(prevState, {
        candidates: {
          $apply: candidates =>
            candidates.map(candidate => {
              if (candidate.id === id) {
                candidate.isCollapsed = true;
                candidate.isConfirmed = true;
              }
              return candidate;
            })
        }
      })
    );
  };

  handleConfirmMatch = (e, id) => {
    // This should be the Target only
    this.sendMatchAction('match', {}, response =>
      this.setState(prevState =>
        update(prevState, {
          target: {
            isConfirmed: { $set: true }
          }
        })
      )
    );
  };

  handleConfirmNewMatch = (e, id) => {
    this.sendMatchAction(
      'new',
      {
        new_canonical_place_ids: _.map(this.state.newCanonical.places, _.property('id'))
      },
      response =>
        // Move this group of places from `newCanonical` into `candidates`.
        // The API response includes the ID of the new group.
        // Set `newCanonical` to undefined so we can break off a place into
        // another New Canonical.
        this.setState(prevState =>
          update(prevState, {
            candidates: {
              $unshift: [
                {
                  id: response.new_canonical_place_id,
                  places: prevState.newCanonical.places,
                  isCollapsed: true,
                  isConfirmed: true
                }
              ]
            },
            newCanonical: {
              $set: undefined
            }
          })
        )
    );
  };

  handleCanonicalButtonClick = (e, id, type) => {
    switch (type) {
      case 'candidate':
        return this.handleDoesNotMatch(e, id);
      case 'target':
        return this.handleConfirmMatch(e, id);
      case 'new':
        return this.handleConfirmNewMatch(e, id);
      default:
        throw new Error(`Unhandled type '${type}' on click.`);
    }
  };

  handleRemoveFromQueueButtonClick = (e, id) => {
    ajax
      .destroy(this.makeAPIUrl('/queue/'), {
        target_id: id
      })
      .then(response => {
        this.setState(prevState =>
          update(prevState, {
            candidates: {
              $apply: candidates =>
                candidates.map(candidate => {
                  if (candidate.id === id) {
                    candidate.isQueued = false;
                  }
                  return candidate;
                })
            }
          })
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleAddToQueueButtonClick = (e, id) => {
    ajax
      .post(this.makeAPIUrl('/queue/'), {
        target_id: id
      })
      .then(response => {
        this.setState(prevState =>
          update(prevState, {
            candidates: {
              $apply: candidates =>
                candidates.map(candidate => {
                  if (candidate.id === id) {
                    candidate.isQueued = true;
                  }
                  return candidate;
                })
            }
          })
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  // ---------------------------------------------
  // MAP -----------------------------------------
  // ---------------------------------------------

  flyTo = options => {
    this.map.state.map.flyTo(options);
  };

  initialViewport = () => {
    const targetLocations = _.compact(
      this.props.target.places.map(place => place.locations.find(l => l.latitude && l.longitude))
    );

    const wsen =
      targetLocations.length === 0
        ? [0, 0, 0, 0]
        : [
            _.min(targetLocations.map(l => l.longitude)), // West
            _.min(targetLocations.map(l => l.latitude)), // South
            _.max(targetLocations.map(l => l.longitude)), // East
            _.max(targetLocations.map(l => l.latitude)) // North
          ];

    return geoViewport.viewport(wsen, [480, 640]);
  };

  zoomToFitCanonical = (e, id) => {
    const bounds = new LngLatBounds();

    const locations = _.map(this.findCanonical(id).places, place => place.locations[0]);

    _.each(locations, location => {
      if (location && _.isNumber(location.latitude) && _.isNumber(location.longitude)) {
        bounds.extend([location.longitude, location.latitude]);
      }
    });

    this.map.state.map.fitBounds(bounds, {
      padding: { top: 100, bottom: 100, left: 100, right: 100 }
    });
  };

  // ---------------------------------------------
  // DRAG-AND-DROP -------------------------------
  // ---------------------------------------------
  allCanonicals = () => {
    const canonicals = _.concat(this.state.candidates, this.state.target);

    if (_.isObject(this.state.newCanonical)) {
      return _.concat(canonicals, this.state.newCanonical);
    }

    return canonicals;
  };

  findCanonical = id => {
    return _.find(this.allCanonicals(), canonical => canonical.id.toString() === id.toString());
  };

  movePlaceToCanonical(droppedPlace, moveToCanonicalId) {
    // SEE: placeSource in Place.jsx gives some of these

    // FIX: All of this below is ugly. Use immutability-helper
    // *******************************************
    const newTarget = _.cloneDeep(this.state.target);
    const newCandidates = _.cloneDeep(this.state.candidates);
    const newNewCanonical = _.isObject(this.state.newCanonical) ? _.cloneDeep(this.state.newCanonical) : undefined;

    // Remove the dropped place from everywhere (wherever it exists).
    // We'll add it later
    // -------------------------------------------
    _.remove(newTarget.places, { id: droppedPlace.id });

    _.each(newCandidates, candidate => {
      _.remove(candidate.places, { id: droppedPlace.id });
    });

    if (_.isObject(newNewCanonical)) {
      _.remove(newNewCanonical.places, { id: droppedPlace.id });
    }

    // Add the place to the correct Canonical
    // -------------------------------------------
    if (moveToCanonicalId === newTarget.id) {
      newTarget.places.push(droppedPlace);
    }
    // If it's -1 we're moving to the "New Canonical"
    else if (moveToCanonicalId === -1) {
      newNewCanonical.places.push(droppedPlace);
      // Otherwise we're moving it into a "Candidate Canonical"
    } else {
      _.find(newCandidates, { id: moveToCanonicalId }).places.push(droppedPlace);
    }

    // Set state
    // -------------------------------------------
    this.setState({
      target: newTarget,
      candidates: newCandidates,
      newCanonical: newNewCanonical // Either an object or undefined depending on whether or not we have a 'New Canonical'
    });
  }

  breakPlaceIntoNewCanonical = (e, place) => {
    e.stopPropagation();

    const updatedTarget = _.cloneDeep(this.state.target);
    _.remove(updatedTarget.places, { id: place.id });

    // Assign this on the frontend to -1 so we can drag
    // and drop later. We look at this value.
    place.canonical_place_id = -1;

    this.setState({
      target: updatedTarget,
      newCanonical: {
        id: -1,
        places: [place]
      }
    });
  };

  // Expands or collapses the group identified by the `path` argument list.
  // For example, toggleCollapse('candidates', 2) will toggle the second "similar canonical" group.
  toggleCollapse(...path) {
    // Convert a path like ['candidates', 2] into { candidates: { 2: $toggle: ['isCollapsed'] } }
    const changes = path.reverse().reduce((acc, cur) => ({ [cur]: acc }), { $toggle: ['isCollapsed'] });
    this.setState(prevState => update(prevState, changes));
  }

  // =============================================
  // RENDER ======================================
  // =============================================
  render() {
    const { matchType } = this.props;

    const Map = ReactMapboxGl({
      accessToken: window._env_.MAPBOX_KEY
    });

    let candidatesCanonicals = [];

    const commonCanonicalProps = {
      matchType,
      flyTo: this.flyTo,
      handleCanonicalButtonClick: this.handleCanonicalButtonClick.bind(this),
      handleAddToQueueButtonClick: this.handleAddToQueueButtonClick.bind(this),
      handleRemoveFromQueueButtonClick: this.handleRemoveFromQueueButtonClick.bind(this),
      movePlaceToCanonical: this.movePlaceToCanonical.bind(this),
      breakPlaceIntoNewCanonical: this.breakPlaceIntoNewCanonical.bind(this),
      zoomToFitCanonical: this.zoomToFitCanonical.bind(this)
    };

    // Build Candidate Canonicals
    // -------------------------------------------
    if (!_.isUndefined(this.state.candidates)) {
      candidatesCanonicals = _.map(_.values(this.state.candidates), (candidate, i) => {
        const queryString = parseQueryString();
        let openAsTargetUrl = `/match/${this.pathMatchType()}/${candidate.id}`;

        if (queryString.compare_to) {
          let compareToIds = queryString.compare_to.split(',');
          compareToIds = _.without(_.union([this.state.target.id], compareToIds), candidate.id);
          openAsTargetUrl = openAsTargetUrl + '?compare_to=' + compareToIds.join(',');
        }

        return (
          <Canonical
            type="candidate"
            target={false}
            new={false}
            canonical={candidate}
            places={candidate.places}
            id={candidate.id}
            key={candidate.id}
            openAsTargetUrl={openAsTargetUrl}
            onToggleCollapse={e => this.toggleCollapse('candidates', i)}
            {...commonCanonicalProps}
          />
        );
      });
    }

    // Labels for "early" source features hide labels for "later" features, but the reverse
    // holds true for circles. Later circles are drawn over earlier circles. To ensure that the right
    // labels appear over the right circles, we need two sources with opposite feature orders.
    const labelData = this.props.map.data;
    const circleData = { type: 'FeatureCollection', features: labelData.features.slice(0).reverse() };

    return (
      <DndProvider backend={HTML5Backend}>
        <div>
          <StyledMapContainer>
            <Map
              containerStyle={{position: 'fixed', top: 0, right: 0, bottom: 0, width: 'calc(100% * 1 / 3)'}}
              center={[this.state.lng, this.state.lat]}
              ref={map => (this.map = map)}
              style={`mapbox://styles/mapbox/${this.getMapStyle(this.state.mapStyleIndex)}-v9`}
              zoom={[this.state.zoom]}
            >
              <Source id="map-data-places-labels" geoJsonSource={{type: 'geojson', data: labelData}}/>
              <Source id="map-data-places-circles" geoJsonSource={{type: 'geojson', data: circleData}}/>
              <Layer
                sourceId="map-data-places-circles"
                type="circle"
                paint={
                  {'circle-radius': 14,
                    'circle-color': {type: 'identity', property: 'circle-color'},
                    'circle-stroke-color': {type: 'identity', property: 'circle-stroke-color'},
                    'circle-stroke-width': 2
                  }
                }
              />
              <Layer
                sourceId="map-data-places-labels"
                type="symbol"
                layout={{'text-field': '{title}', 'text-font': ['Arial Unicode MS Bold'], 'text-size': 15}}
                paint={{'text-color': '#ffffff'}}
              />
              <ScaleControl measurement={'mi'}/>
            </Map>
            <StyledMapStyleChooser>
              <input type={'button'} value={`View ${this.getMapStyle(this.state.mapStyleIndex + 1)}`}
                     onClick={() => {
                       this.setState(prevState =>
                         (
                           {
                             mapStyleIndex: prevState.mapStyleIndex + 1
                           }
                         )
                       );
                     }}/>
            </StyledMapStyleChooser>
          </StyledMapContainer>
          <StyledMatchArea>
            <div>
              <StyledMatchTitle>Match</StyledMatchTitle>
              <MatchActions confirmAllMatches={() => this.confirmAllMatches()} />

              <StyledAlertBox>
                <strong>Directionality matters.</strong> Drag lower-value places <strong>into</strong> higher-value
                place.
              </StyledAlertBox>
            </div>

            {/* Target Canonical */}
            {this.state.target && this.state.target?.places && this.state.target?.places.length > 0 && (
              <Canonical
                {...commonCanonicalProps}
                canonical={this.state.target}
                newCanonical={this.state.newCanonical}
                type="target"
                target={true}
                places={this.state.target?.places}
                id={this.state.id}
                onToggleCollapse={e => this.toggleCollapse('target')}
                nearMatchItems={this.state.target?.near_match_items}
              />
            )}
            {/* New Canonical */}
            {this.state.newCanonical && this.state.newCanonical.id && this.state.newCanonical.places && (
              <Canonical
                {...commonCanonicalProps}
                type="new"
                target={false}
                canonical={this.state.newCanonical}
                places={this.state.newCanonical.places}
                id={this.state.newCanonical.id}
                onToggleCollapse={e => this.toggleCollapse('newCanonical')}
              />
            )}
            {/* Candidate Canonicals */}
            <div>{candidatesCanonicals}</div>
          </StyledMatchArea>
        </div>
      </DndProvider>
    );
  }
}
