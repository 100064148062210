const openImagePicker = (apiKey, uploadCallback, storagePath, maxFiles = 1) => {
  const fsClient = filestack.init(apiKey);
  let options = {
    maxFiles: maxFiles,
    fromSources: ['local_file_system', 'dropbox'],
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
    onFileUploadFailed: function onFileUploadFailed(file, error) {
      console.error(error);
    },
    storeTo: {
      location: 's3',
      path: storagePath
    }
  };
  fsClient.pick(options).then(response => {
    uploadCallback(response);
  });
};

export default openImagePicker;
