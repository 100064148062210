/***
 *       _____                                             _
 *      / ____|                                           | |
 *     | |     ___  _ __ ___  _ __   ___  _ __   ___ _ __ | |_
 *     | |    / _ \| '_ ` _ \| '_ \ / _ \| '_ \ / _ \ '_ \| __|
 *     | |___| (_) | | | | | | |_) | (_) | | | |  __/ | | | |_
 *      \_____\___/|_| |_|_|_| .__/ \___/|_| |_|\___|_| |_|\__|
 *     |  ____|          | | | |
 *     | |__  __  ___ __ | | |_|  _ __ ___ _ __
 *     |  __| \ \/ / '_ \| |/ _ \| '__/ _ \ '__|
 *     | |____ >  <| |_) | | (_) | | |  __/ |
 *     |______/_/\_\ .__/|_|\___/|_|  \___|_|
 *                 | |
 *                 |_|
 *
 *    The Component Explorer is a development and QA tool that allows us to mock state in order to show components
 *    in scenarios that may be hard to trigger through the regular app flow. The Component Explorer is accessible
 *    at `/components`. See `/library/loaders` for examples.
 */

import React from "react";
import "./styles";
import Provider from "../library/launch-darkly/provider";
import * as flags from "../library/launch-darkly/flags";
import SideNav from "../layout/side-nav/index";
import Nav from "../layout/side-nav/nav";
import cn from "classnames";
// export const ComponentContext = React.createContext('');
import ex from "./exports";

function ComponentExplorer(props) {
  const keys = Object.keys(ex);
  const getQs = () => sessionStorage.getItem("exp");

  const [active, setActive] = React.useState(getQs() || keys[0]);

  const Component = ex[active];

  const onClick = (k) => {
    setActive(k);
    sessionStorage.setItem("exp", k);
  };

  return (
    <div className="editor__component-explorer">
      <SideNav
        enabled={true}
        listings={{show: false}}
        organization={{show: false}}
        listing={{show: false}}
      >
        {keys.map((k) => {
          // if parent category return parent str
          if (ex[k] === null) {
            return <b key={k}>{k}</b>;
          }

          // else return child (showing k minus parent str)
          let r = /([A-Z].*?\w(?=[A-Z]))/;
          const match = k.match(r);
          let str;
          if (match) {
            str = k.slice(match[0].length, k.length);
          }
          return (
            <nav
              className={cn({
                "editor__nav-item": true,
                "editor__nav-item--active": k === active,
              })}
              key={k}
              onClick={() => onClick(k)}
            >
              <div className="editor__nav-text">{str}</div>
            </nav>
          );
        })}
      </SideNav>
      <div className="editor__component">
        {Component && <Component {...props} />}
      </div>
    </div>
  );
}

type Props = {
  launchDarkly: LaunchDarkly;
  stripeApiKey: string;
};

export default function ComponentExplorerWithFlags({
  launchDarkly,
  stripeApiKey,
}: Props) {
  return (
    <Provider user={launchDarkly.user} clientID={launchDarkly.client}>
      <flags.COMPONENT_EXPLORER>
        <ComponentExplorer
          stripeApiKey={stripeApiKey || null}
          launchDarkly={launchDarkly}
        />
      </flags.COMPONENT_EXPLORER>
    </Provider>
  );
}
