import * as pt from '../propTypes';

describe('prop type check helpers', () => {
  it('requiredIf produces a function that returns nothing when required prop is defined', () => {
    const props = { type: true, subtype: true };
    const fn = pt.requiredIf('type');
    const result = fn(props, 'subtype', 'TestComponent');
    expect(result).toBe(undefined);
  });

  it('requiredIf produces a function that returns an error object when required prop is not defined', () => {
    const props = { type: true };
    const fn = pt.requiredIf('type');
    const result = fn(props, 'subtype', 'TestComponent');
    expect(typeof result).toBe('object');
  });

  it('onlyIfNot produces a function that returns nothing when only one of the two props is true', () => {
    const props = { a: true, b: false };
    const fn = pt.onlyIfNot('a');
    const result = fn(props, 'b', 'TestComponent');
    expect(result).toBe(undefined);
  });

  it('onlyIfNot produces a function that returns an error object when both props are defined', () => {
    const props = { a: 'a', b: 'b' };
    const fn = pt.onlyIfNot('a');
    const result = fn(props, 'b', 'TestComponent');
    expect(typeof result).toBe('object');
  });

  it('typeOrUndefined produces a function that returns nothing if the prop type matches the type arg', () => {
    const props = { b: true };
    const fn = pt.typeOrUndefined('boolean');
    const result = fn(props, 'b', 'TestComponent');
    expect(result).toBe(undefined);
  });

  it('typeOrUndefined produces a function that returns nothing if the prop is undefined', () => {
    const props = { a: true };
    const fn = pt.typeOrUndefined('boolean');
    const result = fn(props, 'b', 'TestComponent');
    expect(result).toBe(undefined);
  });

  it('typeOrUndefined produces a function that returns an error object if the prop type is incorrect', () => {
    const props = { b: 'str' };
    const fn = pt.typeOrUndefined('boolean');
    const result = fn(props, 'b', 'TestComponent');
    expect(typeof result).toBe('object');
  });
});
