import React from 'react';
import CopyToClipboard from '@library/CopyToClipboard';
import ReactTooltip from 'react-tooltip';
import Subsection from '@/library/wizard/components/subsection';
import './styles';

type Props = {
  save: () => void;
};

function Summary(props: Props) {
  React.useEffect(() => {
    props.save();
  }, []);

  return (
    <div className="editor__data-outreach__rv-service__summary">
      <Subsection>
        <Subsection>
          <span>
            {`Thanks for updating your business information! We're excited to
              connect you with our users and look forward to growing our
              relationship. We'll check in annually to ensure your information
              stays up-to-date.`}
          </span>
        </Subsection>
        <br />
        <br />
        <Subsection>
          <span>
            {`Please feel free to reach out and if any details
              change. You can contact us at`}
            <span className="editor__link" data-tip="Copied!" data-for="copied-support-email" data-event="click">
              {`service@togogroup.io`}
            </span>

            {`.`}
          </span>
        </Subsection>
        <CopyToClipboard text="service@togogroup.io" />
        <ReactTooltip globalEventOff="click" id={'copied-support-email'} delayShow={300} delayHide={500} />
      </Subsection>
    </div>
  );
}

export default Summary;
