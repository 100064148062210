import React from 'react';
import { SinglePathIcon } from './SinglePathIcon';

export class IconAdd extends React.Component {
  render() {
    var props = $.extend(
      {
        width: '16',
        height: '16',
        viewBoxHeight: '24',
        viewBoxWidth: '24',
        viewBoxX: '0',
        viewBoxY: '0',
        className: 'icon-add',
        path: 'M15 13V7a1 1 0 0 0-2 0v6H7a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2h-6z'
      },
      this.props
    );
    return <SinglePathIcon {...props} />;
  }
}

export default IconAdd;
