import React from 'react';
import PropTypes from 'prop-types';
import { GalleryImage, GalleryImageUpload } from './Media';
import Gallery from './Gallery';
import MediaModalView from './MediaModal';
import deepClone from '../helpers/DeepClone';
import MediaAjax from './MediaAjax';
import openImagePicker from '../helpers/imageUpload';
import update from 'immutability-helper';

export default class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = deepClone(props);
  }

  imageThumbnailUrl = image => {
    if (image['sizes'] && image['sizes']['square'] && image['sizes']['square']['url']) {
      return image['sizes']['square']['url'];
    } else if (image['sizes'] && image['sizes']['thumbnail'] && image['sizes']['thumbnail']['url']) {
      return image['sizes']['thumbnail']['url'];
    } else {
      return image.image_url;
    }
  };

  render() {
    return (
      <div>
        {this.state.selectedMedia && (
          <MediaModalView
            media={this.state.selectedMedia}
            targetKey={this.props.targetKey}
            apiUrl={this.props.apiUrl}
            onDelete={this.deleteImage}
            onUnflag={this.unflagImage}
            onSave={this.updateMedia}
            isEditable={this.props.isEditable}
          />
        )}
        <Gallery
          {...this.state}
          renderMedia={this.renderImage}
          renderMediaUpload={this.renderUpload}
          onFeaturedChanged={this.onFeaturedChanged}
          onDeleted={this.onDeleted}
          onUnflagged={this.onUnflagged}
        />
      </div>
    );
  }

  renderImage = (image, handleDelete, handleUnflag, handleToggleFeatured) => {
    return (
      <GalleryImage
        mediaUrl={image.image_url}
        imageUrl={this.imageThumbnailUrl(image)}
        featured={image.featured}
        flagged={image.flags && image.flags.length > 0}
        mediaId={image.id}
        onDelete={handleDelete}
        onUnflag={handleUnflag}
        onToggleFeatured={handleToggleFeatured}
        onClick={this.handleZoom}
        canFeatureMedia={this.props.canFeatureMedia}
        isEditable={this.props.isEditable}
      />
    );
  };

  renderUpload = () => {
    return <GalleryImageUpload onUpload={this.handleUpload} />;
  };

  handleUpload = () => {
    openImagePicker(this.props.filestackApiKey, this.handleFilestack, this.props.filestackStoragePath, 10);
  };

  handleZoom = id => {
    this.setState(
      (prevState, props) => {
        let selectedMedia = null;
        for (var i = 0; i < prevState.media.length; ++i) {
          let media = prevState.media[i];
          if (media.id === parseInt(id)) {
            selectedMedia = media;
            break;
          }
        }
        return { selectedMedia: selectedMedia };
      },
      () => {
        $('#media-modal').modal('show');
      }
    );
  };

  handleFilestack = response => {
    var that = this;
    response.filesUploaded.forEach(function(uploadedFile) {
      let url = uploadedFile['url'];
      let dataObject = { [that.props.targetKey]: { url: url, [that.props.ownerKey]: that.props.ownerId } };
      $.ajax({
        url: that.props.apiUrl,
        credentials: 'same-origin',
        method: 'POST',
        data: JSON.stringify(dataObject),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      }).then(data => {
        data['image_url'] = data['url'];
        that.setState((prevState, _props) => {
          prevState.media.push(data);
          return { media: deepClone(prevState.media) };
        });
      });
    });
  };

  onDeleted = id => {
    this.setState((prevState, _props) => {
      if (this.props.canUnflagMedia) {
        const newState = update(prevState, {
          media: {
            $apply: function(media) {
              for (let i = 0; i < media.length; ++i) {
                let image = media[i];
                if (image.id === id) {
                  // Add placeholder flag
                  image.flags = [
                    {
                      flagged_item_id: id,
                      id: -1,
                      reason: 'flagged',
                      canonical_place_id: -1,
                      flagged_item_type: 'PlaceImage'
                    }
                  ];
                }
              }
              return media;
            }
          }
        });
        return { newState };
      } else {
        var newMedia = deepClone(prevState.media).filter(media => media.id !== parseInt(id));
        return { media: newMedia };
      }
    });
  };

  onUnflagged = id => {
    this.setState((prevState, _props) => {
      const newState = update(prevState, {
        media: {
          $apply: function(media) {
            for (let i = 0; i < media.length; ++i) {
              let image = media[i];
              if (image.id === id) {
                // Remove flags
                image.flags = [];
              }
            }
            return media;
          }
        }
      });
      return { newState };
    });
  };

  deleteImage = id => {
    $('#deleteConfirmation').modal('show');

    var confirmDeleteButton = $('#confirm-delete-button');
    confirmDeleteButton.off('click');

    let that = this;
    confirmDeleteButton.on('click', function() {
      $('#deleteConfirmation').modal('hide');

      MediaAjax.deleteMedia(that.props.apiUrl, id, that.props.ownerKey, that.props.ownerId);

      that.onDeleted(id);
    });
  };

  updateMedia = (id, updatedAttributes) => {
    this.setState((prevState, props) => {
      let newMedia = deepClone(prevState.media);
      for (var i = 0; i < newMedia.length; ++i) {
        let media = newMedia[i];
        if (media.id === id) {
          Object.keys(updatedAttributes).forEach(attr => (media[attr] = updatedAttributes[attr]));
          break;
        }
      }
      return { media: newMedia };
    });
  };

  onFeaturedChanged = (id, featured) => {
    this.setState((prevState, props) => {
      for (var i = 0; i < prevState.media.length; ++i) {
        let media = prevState.media[i];
        if (media.id === parseInt(id)) {
          media.featured = featured;
          break;
        }
      }
      return { media: deepClone(prevState.media) };
    });
  };
}

const IMAGE_SIZES = PropTypes.shape({
  height: PropTypes.number,
  width: PropTypes.number,
  url: PropTypes.string
});

ImageGallery.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  canFeatureMedia: PropTypes.bool.isRequired,
  canUnflagMedia: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  media: PropTypes.arrayOf([
    PropTypes.shape({
      enabled: PropTypes.bool,
      featured: PropTypes.bool,
      fingerprint: PropTypes.string,
      from_verified_owner: PropTypes.bool,
      from_your_org: PropTypes.bool,
      id: PropTypes.number,
      image_url: PropTypes.string,
      source_id: PropTypes.number,
      source_name: PropTypes.string,
      sizes: PropTypes.shape({
        large: IMAGE_SIZES,
        medium: IMAGE_SIZES,
        small: IMAGE_SIZES,
        square: IMAGE_SIZES,
        thumbnail: IMAGE_SIZES
      })
    })
  ]),
  ownerId: PropTypes.number,
  ownerKey: PropTypes.string,
  targetKey: PropTypes.string,
  filestackStoragePath: PropTypes.string.isRequired,
  filestackApiKey: PropTypes.string.isRequired
};
