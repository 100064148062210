import * as React from 'react';
import { SinglePathIcon } from './SinglePathIcon';

export default class IconHide extends React.PureComponent {
  render = () => {
    return (
      <SinglePathIcon
        {...this.props}
        path="M14.96 12.93l.88-.92a6.99 6.99 0 0 0-1.86-.29c-3.14 0-5.9 2.6-6.99 3.77.91 1 1.96 1.85 3.1 2.54l1.44-1.5a2.82 2.82 0 0 1-.17-1.77c.22-.98.95-1.75 1.89-2a2.48 2.48 0 0 1 1.71.17zm5.35-.42l1.8-1.72a.5.5 0 0 1 .52-.16c.19.06.33.22.36.42.04.2-.04.4-.2.53l-1.69 1.6c.6.55 1.15 1.13 1.66 1.76a.9.9 0 0 1 0 1.12c-.16.2-3.95 4.96-8.78 4.96-.95-.01-1.9-.2-2.8-.53l1.36-1.42c.47.12.95.18 1.44.18 3.13 0 5.9-2.6 6.98-3.76a15.1 15.1 0 0 0-2.75-2.34l1.23-1.29c.3.2.59.42.87.65zm-3.62-2.08l.49.18 4.54-4.76a.33.33 0 0 1 .48 0l.48.5c.13.14.13.36 0 .5L7.59 22.64a.33.33 0 0 1-.24.11.33.33 0 0 1-.24-.1l-.48-.5a.37.37 0 0 1 0-.5l2.22-2.32c-1.37-.9-2.6-2-3.66-3.3a.92.92 0 0 1 0-1.12c.06-.07.69-.85 1.71-1.77l-1.42-1.59a.55.55 0 0 1 .02-.74.5.5 0 0 1 .72.02l1.47 1.64c.43-.34.9-.68 1.4-.99l-1-2.19a.54.54 0 0 1 .25-.7.5.5 0 0 1 .68.24l.97 2.15c.41-.2.84-.4 1.28-.55l-.5-2.32a.53.53 0 0 1 .38-.64.5.5 0 0 1 .61.4l.5 2.28c.4-.1.8-.15 1.21-.18V7.65c0-.3.23-.54.5-.54.29 0 .52.24.52.54v2.32c.4.04.81.1 1.21.18l.5-2.27a.52.52 0 0 1 .34-.4.5.5 0 0 1 .5.13c.13.12.19.32.15.5l-.5 2.32zm-3.25 7.71l3.06-3.2a2.75 2.75 0 0 1-.9 2.66c-.6.51-1.4.71-2.16.54z"
      />
    );
  };
}

export { IconHide };
