import React from 'react';
import ContactSection from '@/data-outreach/_core/forms/components/contact';
import type { UpdateField, State } from '../../../typings';

type Props = {
  show: boolean;
  state: State;
  updateField: UpdateField;
  fields?: string[][];
};

function StorageContact({ show, state, updateField, ...props }: Props) {
  return show ? (
    <ContactSection
      label="RV Rental"
      state={state}
      updateField={updateField}
      fields={
        props.fields || [
          ['Phone', 'rental_phone', 'tel', 'phone'],
          ['Email', 'rental_email', 'email', 'phone']
        ]
      }
      title="RV Rental"
    />
  ) : null;
}

export default StorageContact;
