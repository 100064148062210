import React from 'react';
import ReactDOM from 'react-dom';

function Drawer(props){
  return ReactDOM.createPortal(
    props.children,
    document.getElementById('drawer-anchor')
  );
}

export default Drawer;
