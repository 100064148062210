import React from 'react';
import Link from '@/library/link/view';
import cn from 'classnames';
import { useSpring, animated } from 'react-spring';

type Props = {
  key?: string;
  name: string;
  ancestors: {
    children?: string[];
    name: string;
  }[];
  pending: boolean;
  public_id: string;
  remove: (public_id: string) => void;
};

function SelectionItem({ ancestors, public_id, remove, pending, name }: Props){
  const props = useSpring({
    border: Boolean(pending) ? '2px solid #32cd32' : '1ps solid black'
  });

  return (
    <animated.div
      style={props}
      key={'selection-' + public_id}
      onClick={() => remove(public_id)}
      className={cn({
        'editor__search-tree__selection-item': true,
        'editor__search-tree__selection-item--pending': pending
      })}
    >
      <div className="editor__search-tree__selection-item__description">
        {ancestors &&
        ancestors.length >= 1 && (
          <div className="editor__search-tree__selection-item__ancestors">
            <small>
              {ancestors.map(
                (node, idx) =>
                  node.name + (idx === ancestors.length - 1 ? '' : ' | ')
              )}
            </small>
          </div>
        )}
        <div className="editor__search-tree__selection-item__name">{name}</div>
      </div>
      <Link>Remove</Link>
    </animated.div>
  );
}

export default SelectionItem;
