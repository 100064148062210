import React from "react";
import * as constants from "../../constants";
import Select from "@/library/select";
import TextArea from "@/library/text-area";
import TriSwitch from "@/library/tri-switch";
import type {State} from "../../../types";
import {FieldsInputsMapper} from "@/data-outreach/_core/forms/components/field-mappers";
import {useContext} from "@/data-outreach/_core/forms/useContext";
import Required from "@/data-outreach/_core/forms/components/required";

export function GeneralInfo() {
  // @ts-ignore
  const ctx: {
    state?: State;
    setField: (value: string | boolean, field: string) => void;
    updateField: (event, field: string) => void;
    requiredFields: {[field: string]: boolean};
  } = useContext();

  React.useEffect(() => {
    ctx.setField(null, "restricted_by");
    ctx.setField(null, "policy_enforced")
  },[ctx.state.parking_allowed]);

  React.useEffect(() => {
    ctx.setField(null, "user_stay_date")
  },[ctx.state.user_stay]);

  React.useEffect(() => {
    ctx.setField(null, "policy_confirmation_method");
    ctx.setField(null, "policy_confirmed_by");
    ctx.setField(null, "policy_confirmation_date")
  },[ctx.state.policy_confirmed]);

  return (
    <div className="editor__data-outreach__orvp__form">
      <h3>Report Overnight RV Parking at {ctx.state.name}</h3>
      <br />

      { ctx.state.errors && (
        <div className='editor__form-errors'>Could Not Submit Report: {ctx.state.errors}</div>
      )}

      <Required ok={ctx.requiredFields["parking_allowed"]}>
        <TriSwitch
          label="Is overnight RV parking allowed?"
          defaultValue={ctx.state["parking_allowed"]}
          getValue={(value) => ctx.setField(value, "parking_allowed")}
          first="yes"
          second="no"
          third="other*"
        />
      </Required>

      {ctx.state.parking_allowed === null && (
        <p>* Please explain in comment section below</p>
      )}

      {ctx.state.parking_allowed === false && (
        <Required ok={ctx.requiredFields["restricted_by"]}>
          <Select
            onChange={(value) => ctx.setField(value, "restricted_by")}
            value={ctx.state.restricted_by}
            options={constants.RESTRICTIONS_OPTIONS}
            label="Why is overnight RV parking not allowed?"
          />
        </Required>
      )}

      <Required ok={ctx.requiredFields["user_stay"]}>
        <TriSwitch
          defaultValue={ctx.state["user_stay"]}
          label="Did you stay overnight at this location?"
          getValue={(value) => ctx.setField(value, "user_stay")}
          first="yes"
          second="no"
        />
      </Required>

      {ctx.state.user_stay && (
        <FieldsInputsMapper
          state={ctx.state}
          updateField={ctx.updateField}
          fields={[
            [
              "When did you stay?",
              "user_stay_date",
              "",
              "date",
              ctx.requiredFields["user_stay_date"],
            ],
          ]}
        />
      )}

      <Required ok={ctx.requiredFields["policy_confirmed"]}>
        <TriSwitch
          label="Did you confirm the overnight RV parking policy?"
          defaultValue={ctx.state["policy_confirmed"]}
          getValue={(value) => ctx.setField(value, "policy_confirmed")}
          first="yes"
          second="no"
        />
      </Required>

      {ctx.state.policy_confirmed ? (
        <>
          <Required ok={ctx.requiredFields["policy_confirmation_method"]}>
            <Select
              value={ctx.state.policy_confirmation_method}
              options={constants.CONF_METHODS_OPTIONS}
              label="How did you confirm the policy?"
              onChange={(value) => ctx.setField(value, "policy_confirmation_method")}

            />
          </Required>
          <Required ok={ctx.requiredFields["policy_confirmed_by"]}>
            <Select
              value={ctx.state.policy_confirmed_by}
              options={constants.CONF_SOURCES_OPTIONS}
              label="Who confirmed the policy?"
              onChange={(value) => ctx.setField(value, "policy_confirmed_by")}

            />
          </Required>
          <FieldsInputsMapper
            state={ctx.state}
            updateField={ctx.updateField}
            fields={[
              [
                "When did you confirm the policy?",
                "policy_confirmation_date",
                "",
                "date",
                ctx.requiredFields["policy_confirmation_date"],
              ],
            ]}
          />
        </>
      ) : null}

      {ctx.state.parking_allowed !== true && <Required ok={ctx.requiredFields["policy_enforced"]}>
        <TriSwitch
          label="Is the overnight parking policy enforced?"
          defaultValue={ctx.state["policy_enforced"]}
          getValue={(value) => ctx.setField(value, "policy_enforced")}
          first="yes"
          second="no"
          third="other*"
        />
      </Required>
      }
      {ctx.state.parking_allowed !== true && ctx.state.policy_enforced === null && (
        <p>* Please explain in comment section below</p>
      )}
      <Required ok={ctx.requiredFields["place_correct"]}>
        <TriSwitch
          label="Is the information about this place correct?"
          defaultValue={ctx.state["place_correct"]}
          getValue={(value) => ctx.setField(value, "place_correct")}
          first="yes"
          second="no"
        />
      </Required>

      <TextArea
        className="editor__comments"
        label={"Comments"}
        value={ctx.state.comment}
        onChange={(e) => {
          ctx.updateField(e, "comment");
        }}
      />
    </div>
  );
}

export default GeneralInfo;
