import React from 'react';
import styled from 'styled-components';

// Bootstrap
import { Table } from 'reactstrap';

// Material UI
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import { DropTarget } from 'react-dnd';

import ItemTypes from './ItemTypes';

// Components
import Place from './Place';
import CanonicalActions from './CanonicalActions';
import Link from "@library/link/view";

const StyledCanonicalHeaderColors = {
  background: {
    target: '#985d54',
    new: '#707c3b',
    candidate: '#fff'
  }
};

const HeaderPrefixOfType = {
  target: 'Target',
  new: 'New',
  candidate: 'Similar'
};

const StyledCanonical = styled.div`
  ${props => (props.isOver && props.canDrop ? 'box-shadow: 0 16px 24px rgba(23, 22, 21, 0.2) !important;' : '')};

  ${props => (props.isOver && !props.canDrop ? 'opacity: 0.25;' : '')};

  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background: #fff;

  margin-bottom: 20px;

  tbody {
  }

  thead {
    background-color: #fdfcfb;
    font-weight: bold;
    border-top: 1px solid #efe9dd;
  }

  td {
    font-size: 11px;
    padding: 8px;
    vertical-align: middle;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #efe9dd;
  }
`;

const StyledCanonicalHeader = styled.div`
  font-size: 14px;
  height: auto !important;
  font-weight: 400 !important;
  background: ${props => StyledCanonicalHeaderColors.background[props.type]};
  ${props => (props.target ? 'color: #fff;' : '')} font-weight: bold;
  border-radius: 6px 6px 0px 0px;
  white-space: nowrap;

  span.canonical_id {
    font-size: 13px;
    color: ${props => (props.target ? '#fff' : '#77746e')};
    margin-left: 8px;
  }
  span.canonical_target_id {
    
    font-size: 13px;
    color: ${props => (props.target? '#fff' : '#77746e')};
    margin-left: 8px;
  }

  &.collapsed {
    border-radius: 6px;
  }

  * {
    display: inline-block;
  }
`;

const canonicalTarget = {
  canDrop(props, monitor) {
    // If the user is trying to drag TO a candidate
    // this is not allowed.
    //
    // The user can only drag TO the target
    // OR back to the original canonical for match_type = canonical_place.
    return props.type !== 'candidate' || monitor.getItem().canonical_place_id === props.canonical.id;
  },

  drop(canonicalProps, monitor) {
    const droppedPlace = monitor.getItem();
    canonicalProps.movePlaceToCanonical(droppedPlace, canonicalProps.id);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

// ===============================================
// COMPONENT->CANONICAL ==========================
// ===============================================
class Canonical extends React.Component {
  instance = React.createRef();

  render() {
    const {
      matchType,
      target,
      canonical,
      id,
      places,
      connectDropTarget,
      isOver,
      canDrop,
      handleCanonicalButtonClick,
      handleAddToQueueButtonClick,
      breakPlaceIntoNewCanonical,
      zoomToFitCanonical,
      type,
      flyTo,
      newCanonical,
      openAsTargetUrl,
      onToggleCollapse,
      nearMatchItems
    } = this.props;

    const commonProps = {
      target,
      matchType
    };
    const nearMatchIds = this.props.nearMatchItems ? nearMatchItems.map(i => i['place_id']) : [];
    const nearMatchApprovedIds = nearMatchIds?.length > 0 ? nearMatchItems.filter(i => i['near_match_approved']).map(i => i['place_id']) : [];

    return connectDropTarget(
      <div>
        <StyledCanonical target={canonical} isOver={isOver} canDrop={canDrop}>
          <CanonicalActions
            id={id}
            target={canonical}
            type={type}
            handleCanonicalButtonClick={handleCanonicalButtonClick}
            handleAddToQueueButtonClick={handleAddToQueueButtonClick}
            openAsTargetUrl={openAsTargetUrl}
            onToggleCollapse={onToggleCollapse}
          />
          <StyledCanonicalHeader target={target} type={type} className={canonical.isConfirmed ? 'collapsed' : null}>
            <IconButton onClick={e => zoomToFitCanonical(e, canonical.id)}>
              <Icon style={type === 'target' ? { color: 'white' } : {}}>my_location</Icon>
            </IconButton>
            {`${HeaderPrefixOfType[type]} ${this.props.matchType}`}
            <Link internalLink={type !== 'new' && ('/listings/' + id)}>
              <span className='canonical_id'>{type !== 'new' && id}</span>
            </Link>
          </StyledCanonicalHeader>

          {canonical.isCollapsed !== true && (
            <Table style={{ tableLayout: 'fixed' }}>
              <thead id={id}>
                <tr>
                  <td style={{ width: '32px' }} />
                  <td style={{ width: '70px' }}>Place ID</td>
                  <td style={{ width: '120px' }}>Source</td>
                  <td style={{ width: '200px' }}>Place Name</td>
                  <td style={{ width: '200px' }}>Address</td>
                  <td style={{ width: '100%' }}>Categories</td>
                  <td style={{ width: '40px' }}/>
                  <td style={{ width: '40px' }} className="place-buttons" />
                </tr>
              </thead>
              <tbody>
                {places && places.length > 0
                  ? places.map((place) => (
                      <Place
                        {...commonProps}
                        flyTo={flyTo}
                        key={place.id}
                        place={place}
                        canonical={canonical}
                        newCanonical={newCanonical}
                        breakPlaceIntoNewCanonical={breakPlaceIntoNewCanonical}
                        isNearMatch={nearMatchIds.indexOf(place.id) !== -1}
                        nearMatchApproved={nearMatchIds.indexOf(place.id) !== -1 ? nearMatchApprovedIds.indexOf(place.id) !== -1 : null}
                      />
                    ))
                  : null}
              </tbody>
            </Table>
          )}
        </StyledCanonical>
      </div>
    );
  }
}

export default DropTarget(ItemTypes.PLACE, canonicalTarget, collect)(Canonical);
