import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

type Index = {
  ids: [],
  byId: {}
}

type Props = {
  items: any[];
  key?: string;
  options?: {
    clearChildren?: boolean;
    callback?: (index: Index, item: any) => Index
  }
}

function createIndex({ items, key = 'public_id', options }: Props) {
  const [index] = React.useState(items.reduce(
    (tmp, item) => {
      const id = item[key];
      tmp.ids.push(id);

      if (options.clearChildren) {
        tmp.byId[id] = { ...item, children: [] };
      } else {
        tmp.byId[id] = { ...tmp.byId[id], ...item };
      }
      if (options.callback) {
        tmp = options.callback(tmp, item);
      }
      return tmp;
    },
    {
      key,
      byId: {},
      ids: []
    }
  ));

  const getNode = (id) => {
    // We want to make sure we are not editing the original node
    return cloneDeep({ ...index.byId[id], children: [] });
  };

  const allNodes = () => index.ids.map(id => getNode(id))

  const getIds = () => {
    return [...index.ids];
  }

  return { allNodes, getNode, getIds, ...index }
}

export default function useIndex(items, key, options) {
  return createIndex({ items, key, options })
};

