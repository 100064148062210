import React from 'react';
import FilterForm from './FilterForm';

class SelectFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { appliedValue: this.props.data, value: this.props.data };

    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleApply() {
    this.setState(prevState => ({ appliedValue: prevState.value }), function() {
      this.props.applyFilter({[this.props.name]: this.state.value});
    });
  }

  handleCancel() {
    this.setState(prevState => ({ value: prevState.appliedValue }));
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    return(
      <FilterForm active={!!this.state.appliedValue} btnClass={this.props.btnClass} label={this.renderButtonLabel()} name={this.props.name} onApply={this.handleApply} onCancel={this.handleCancel}>
        { this.renderFilter() }
      </FilterForm>
    );
  }

  renderButtonLabel() {
    if (this.state.appliedValue) {
      const selectedOption = this.props.options.find(o => o.value == this.state.appliedValue);
      return selectedOption.shortLabel || selectedOption.label;
    } else {
      return this.props.label;
    }
  }

  renderFilter() {
    const options = this.props.options.map(o => {
      return <option key={o.value} value={o.value}>{o.label}</option>;
    });

    return (
      <select name={this.props.name} onChange={this.handleChange} value={this.state.value || ''}>
        {options}
      </select>
    );
  }
};

export default SelectFilter;
