import React from "react";
import * as ajax from "@/helpers/ajax";
import Section from "@library/section";
import useLink from "@library/link/useLink";
import {DateTime} from "luxon";
import "./styles";
import {stringifyBools, stringifyNoteAndAuthor} from "@/helpers/audit";
import ViewField from "@library/view-field";


type Props = {
  setActive: (text: string) => void;
  canonicalPlace: {
    id: string;
  };
  auditsJson?: {
    contact_update: boolean;
    general_update: boolean;
    resolved_duplicates: boolean;
    validated: boolean;
    created_at: string;
    author: string;
    note: string;
  }[];
};

function Audits(props: Props) {
  const [audits, setAudits] = React.useState(props.auditsJson || []);

  const onClick = () => {
    const text = "audits";
    props.setActive(text);
    window.location.hash = text;
  };

  const auditsLink = useLink({
    onClick,
    internalLink: "",
  });

  async function loadAudits() {
    const [promise] = ajax._get(
      "/listings/audits/" + props.canonicalPlace.id + ".json/?limit=5&include_note_only_audits=1"
    );
    const json = await promise;

    if (json) {
      setAudits(json.data);
    }
  }
  React.useEffect(() => {
    if (!props.auditsJson) {
      loadAudits();
    }
  }, []);

  return (
    <div data-cy="dashboard-audits" className="editor__dashboard__audits">
      <Section
        header={
          <div className="editor__dashboard__audits__header">
            <h4>Audits</h4>
          </div>
        }
      >
        <div
          data-cy="audits-content"
          className="editor__dashboard__audits__content"
        >
          {audits.length === 0 ? (
            <i>none</i>
          ) : (
            audits.map((audit, idx) => {
              return (
                <div key={idx} className="editor__dashboard__audits__audit">
                  <ViewField
                    label={DateTime.fromISO(audit.created_at).toLocaleString() + ' ' + stringifyBools(audit, true)}
                    value={stringifyNoteAndAuthor(audit)}
                  />
                </div>
              );
            })
          )}
        </div>
      </Section>
    </div>
  );
}

export default Audits;
