import React from 'react';
import * as Components from './components';
import * as util from '@library/attributes-section/util';
import camelCase from 'lodash/camelCase';

function Attribute({
  component,
  dependents,
  description,
  id,
  onChange,
  options,
  show,
  value,
  active,
  ...props
}: Attributes.Definition): any{
  const label = util.formatLabel(props.label, props.depends_on);
  const disabled = !(active ?? true);
  const hideLabel = !!props.hideLabel;

  const topLevel = {
    editing: props.editing,
    featureFlag: props.featureFlag,
  };

  const view = {
    dependents,
    depends_on: props.depends_on,
    description,
    id,
    label,
    labelId: id,
    value,
    hideNull: props.hideNull,
  };

  const edit = { ...view, onChange, disabled, hideLabel };

  const attrProps = { ...topLevel, view, edit };

  if (!component) {
    console.debug(id + ' was not rendered');
    return null;
  }

  const Component = Components[component];
  if (Component) Component.displayName = camelCase(id) + Component.displayName;

  switch (component) {
    case 'CountDependent':
      const label = 'Count';
      return (
        <Components.Count
          {...attrProps}
          view={{ ...view, label }}
          edit={{ ...edit, label }}
        />
      );

    case 'Link':
      return (
        <Component
          {...attrProps}
          view={{
            ...view,
            link: true,
            externalLink: value
          }}
        />
      );

    case 'Select':
      return (
        <Component
          {...attrProps}
          view={{
            ...view,
            value: util.getValue('name', value, options)
          }}
          edit={{ ...edit, options }}
        />
      );

    case 'MultiSelect':
      const opts = util.getOptions(options);
      return (
        <Component
          {...attrProps}
          view={{
            ...view,
            link: false,
            options: opts,
            values: util.getValues('name', value, opts)
          }}
          edit={{
            ...edit,
            persistChanges: onChange,
            options: opts,
            selected: util.getValues('id', value, opts)
          }}
        />
      );

      case 'MultiTextInput':
        return (
          <Component
            {...attrProps}
            view={{
              ...view,
              link: util.linkFormat(props.schema.items.format),
              values: value
            }}
            edit={{ ...edit, values: value || [] }}
          />
        );

    case 'MultiInput':
      return (
        <Component
          {...attrProps}
          view={{
            ...view,
            link: util.linkFormat(props.schema.items.format),
            values: value
          }}
          edit={{ ...edit, values: value || [] }}
        />
      );

    case 'MultiLink':
      return (
        <Components.MultiInput
          {...attrProps}
          view={{
            ...view,
            link: true,
            values: value
          }}
          edit={{
            ...edit,
            values: value || []
          }}
        />
      );
    default:
      return (
        <Component
          {...attrProps}
          view={{
            ...view,
            className:
              (typeof value === 'string' && value.length <= 5) ||
              typeof value === 'number'
                ? 'editor__single-line ' + view['className']
                : view['className'] || null
          }}
        />
      );
  }
}

export default Attribute;
