import React from "react";
import Dropdown from "@/library/dropdown";
import CopyToClipboard from "@library/CopyToClipboard";
import {ListingAuditLink} from "@/audit/ListingAuditLink";
import {useStoreContext} from "@/state";
import Link from "@library/link/view";
import type {Props as AuditLinkProps} from "@/audit/ListingAuditLink";

export type Props = {
  canonicalPlaceId: number;
  viewInMatchText: string;
  viewInMatchPath: string;
  viewJSONText: string;
  viewJSONPath: string;
  owner_verified?: boolean;
  internalOrg: boolean;
  canEditAudit: boolean;
  canViewInMatch: boolean;
  googleSearchUrl?: string;
  brandedAttributionUrl?:string;
  detailsViewPath: string;
  cy?: string;
  removeAudit: (user_id: number) => void;
  createAudit: (user_id: number) => void;
  updateAudit: (user_id: number) => void;
} & AuditLinkProps;

export const ListingActionsDropdown = ({
  canonicalPlaceId,
  detailsViewPath,
  owner_verified,
  internalOrg,
  viewJSONPath,
  viewJSONText,
  googleSearchUrl,
  viewInMatchPath,
  viewInMatchText,
  audit,
  users,
  canViewInMatch,
  canEditAudit,
  ...props
}: Props) => {
  const detailsViewUrl = window
    ? window.location.protocol + window.location.host + detailsViewPath
    : "";
  const {analytics} = useStoreContext();

  return (
    <Dropdown cy={"listing-dropdown"}>
      <Link internalLink={detailsViewPath}>View details</Link>

      <CopyToClipboard text={detailsViewUrl}>
        <Link
          className="clipboard-link text-nowrap"
          onClick={() => {
            analytics.trackListing("Listing", "copied to clipboard", {
              search_query: document.location.href,
              link: detailsViewUrl,
            });
          }}
        >
          Copy link to clipboard
        </Link>
      </CopyToClipboard>

      {internalOrg && (
        <Link
          cy="view-json-link"
          className="link-super-admin text-nowrap"
          internalLink={viewJSONPath}
          onClick={() => {
            analytics.trackListing("Listing", "viewed JSON", {
              search_query: document.location.href,
            });
          }}
        >
          {viewJSONText}
        </Link>
      )}

      {googleSearchUrl && internalOrg && (
        <Link
          cy="google-listing-link"
          className="link-super-admin text-nowrap"
          externalLink={googleSearchUrl}
          onClick={() => {
            analytics.trackListing("Listing", "googled", {
              google_search_url: googleSearchUrl,
              search_query: document.location.href,
            });
          }}
        >
          Google this listing
        </Link>
      )}

      {props.brandedAttributionUrl && (
        <Link
          cy="yelp-listing-link"
          className="link-super-admin text-nowrap"
          externalLink={props.brandedAttributionUrl}
          onClick={() => {
            analytics.trackListing("Listing", "viewed on yelp", {
              yelp_search_url: props.brandedAttributionUrl,
              search_query: document.location.href,
            });
          }}
        >
          View on Yelp
        </Link>
      )}

      {canViewInMatch && (
        <Link
          cy="compare-in-match-link"
          className="link-super-admin text-nowrap"
          internalLink={viewInMatchPath}
          onClick={() => {
            analytics.trackListing("Listing", "compared in match", {
              search_query: document.location.href,
              comparison_set_ids: viewInMatchPath,
            });
          }}
        >
          {viewInMatchText}
        </Link>
      )}

      {canEditAudit && (
        <ListingAuditLink
          isNestedDropdown={true}
          showOnHover={true}
          toggleIcon={props.toggleIcon}
          Label={props.Label}
          audit={audit}
          users={users}
          updateAudit={props.updateAudit}
          createAudit={props.createAudit}
          removeAudit={props.removeAudit}
          admin={true}
          hideLastName={props.hideLastName}
        />
      )}
    </Dropdown>
  );
};

export default ListingActionsDropdown;
