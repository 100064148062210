import AttributesSection from '@/library/attributes-section';
import React, { HTMLAttributes, ReactNode, useState, useEffect } from 'react';

export interface tab {
  sectionTitle: string;
  sectionId?: string;
  content: ReactNode;
}

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  sections?: Array<tab>;
  selected?: string;
  setSelected?: Function;
  handleFieldOnChange?: Function;
  userEditingStrategy: any;
}

export const Sections = ({ ...props }: Props) => {
  const [selected, setSelected] = useState(props.selected);

  const handleOnClick = (event: any, name: string) => {
    event &&
      event.preventDefault();

    setSelected(name);

  }

  useEffect(() => {
    !props.selected &&
      setSelected(props.sections[0].sectionTitle.split(' ').join('-').toLowerCase());
  }, []);

  return (
    <div className="attribute-sections">
      <div className="__header">
        <div className="__header_tabs">
          {props.sections &&
            props.sections.map((tab) => {
              tab.sectionId = tab.sectionTitle.split(' ').join('-').toLowerCase();

              return (
                <div key={tab.sectionId} className={`__header_tab ${selected && selected == tab.sectionId ? '--selected' : ''}`}>
                  <a href="#" data-name={tab.sectionId} onClick={(e) => handleOnClick(e, tab.sectionId)}>
                    {tab.sectionTitle}
                  </a>
                </div>
              )
            }
            )
          }
        </div>
      </div>
      <div className="__content">
        {props.sections &&
          props.sections.map((section) => {
            section.sectionId = section.sectionTitle.split(' ').join('-').toLowerCase();

            return (
              selected && selected == section.sectionId &&

              <AttributesSection
                {...section}
                onChange={props.handleFieldOnChange}
                key={section.sectionTitle}
                userEditingStrategy={props.userEditingStrategy}
              />
            );
          }
          )
        }
      </div>
    </div>
  )
}
