import React, {Component} from "react";
import Toggle from "./toggle";
import {Dropdown, DropdownMenu} from "reactstrap";
import type {Props as ToggleProps} from "./toggle";

export type Props = {
  className?: string;
  closeParentDropdown?: () => void;
  cy?: string;
  direction?: string;
  divider?: boolean;
  hideOnMouseLeave?: boolean;
  isNestedDropdown?: boolean;
  menuClassName?: string;
  right?: boolean;
  showOnHover?: boolean;
} & ToggleProps;

type State = {
  isOpen: boolean;
};

// If any child component evaluates to null, the divider will still be visible,
// in the dropdown. Make sure that child evaluates to false if it conditionally
// renders.

// Expected behavior
// - children should be an array when using dividers
// - close on menu click, except when event originates from nested dropdown
// - open on hover when prop is set
class DropDown extends Component<Props, State> {
  state = {
    isOpen: false,
  };

  static defaultProps = {
    toggleIcon: "dots",
    className: "boone-dropdown",
    menuClassName: "boone-dropdown__menu",
    direction: "down",
    divider: true,
    showOnHover: false,
    right: true,
  };

  toggle(isOpen?: boolean) {
    if (isOpen !== undefined) {
      if (!isOpen && this.props.isNestedDropdown) {
        this.props.closeParentDropdown();
      } else {
        this.setState({isOpen});
      }
    } else {
      this.setState((prevState) => ({isOpen: !prevState.isOpen}));
    }
  }

  closeDropdown() {
    this.toggle(false);
  }

  render() {
    return (
      <div
        data-cy={this.props.cy || "dropdown"}
        onMouseLeave={
          this.props.hideOnMouseLeave ? () => this.toggle(false) : null
        }
        onClick={
          // We still want the dropdown to open on click for smaller screens
          this.props.showOnHover
            ? (e) => {
                e.preventDefault();
                this.toggle(true);
              }
            : null
        }
      >
        <Dropdown
          onMouseEnter={this.props.showOnHover ? () => this.toggle(true) : null}
          isOpen={this.state.isOpen}
          className={this.props.className}
          direction={this.props.direction}
          toggle={() => this.toggle()}
        >
          <Toggle toggleIcon={this.props.toggleIcon} Label={this.props.Label} />
          <DropdownMenu
            right={this.props.right}
            className={this.props.menuClassName}
          >
            {React.Children.toArray(this.props.children).map((child, idx) => {
              if (!React.isValidElement(child)) return;

              // If the child is a nested dropdown, we pass in a the closeParentDropdown callback and we do
              // not close the dropdown when that child is clicked
              return (
                child && (
                  <div
                    data-cy="dropdown-menu-item"
                    className="boone-dropdown__menu__item"
                    onClick={
                      child.props.isNestedDropdown
                        ? null
                        : () => this.toggle(false)
                    }
                    key={"dropdown-item-" + idx}
                  >
                    {child.props.isNestedDropdown
                      ? React.cloneElement(child, {
                          closeParentDropdown: () => this.closeDropdown(),
                        })
                      : child}
                    {this.props.divider &&
                      idx < React.Children.count(this.props.children) - 1 && (
                        <hr className="boone-dropdown__menu__item__hr" />
                      )}
                  </div>
                )
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

/** If this dropdown has a dropdown as a child, set this flag to true. Open / close state
 * will be dictated by the dropdown child instead of the default behavior which is closing
 * on menu item click. The child will receive a closeParentDropdown callback to evoke when
 * both menus should be closed. */

export default DropDown;
