/**
 * @deprecated 12/31/2019 use library/link/view
 */

import React from 'react';

const PROTOCOLMAP = {
  'tel': {protocol: 'tel:', openInNewTab: false},
  'email': {protocol: 'mailto:', openInNewTab: false},
};

export default class ExternalLink extends React.Component {

  render() {
    const {type, ...props} = this.props;

    let protocol = null;
    let openInNewTab = true;
    if (type && PROTOCOLMAP[type]) {
      protocol = PROTOCOLMAP[type].protocol;
      openInNewTab = PROTOCOLMAP[type].openInNewTab;
    }

    let {href} = this.props;
    if (href && !href.toLowerCase().startsWith(protocol || 'http')) {
      href = (protocol || 'https://') + href;
    }
    const target = openInNewTab ? "_blank" : null;
    return (
      <a {...props} href={href} target={target}>{this.props.children || this.props.href}</a>
    );
  }
}
