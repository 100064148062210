import * as React from "react";
import LoadableVisibility from "react-loadable-visibility/react-loadable";
import Bar from "@library/loaders/bar";

const LoadableComponent = LoadableVisibility({
  loader: () => import("./index"),
  loading: Bar
});

function Loadable(props) {
  return <LoadableComponent {...props} />;
}

export default Loadable;
