
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import Enable from './index';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Enable {...{
      "enabled": false,
      "canEnable": true,
      "enablePath": "/test/listing/enable"
    }} mdxType="Enable" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;