import React from 'react';

function Collapse(props){
  return (
    <span
      data-cy="tree-collapse"
      className="tree-expanded-button"
      key="expanded"
      onClick={props.onClick}
    >
      -
    </span>
  );
}

function Expand(props){
  return (
    <span
      data-cy="tree-expand"
      className="tree-collapsed-button"
      key="collapsed"
      onClick={props.onClick}
    >
      +
    </span>
  );
}

export default function Control(props){
  return props.show ? props.isExpanded ? (
    <Collapse onClick={props.onCollapse} />
  ) : (
    <Expand onClick={props.onExpand} />
  ) : null;
}
