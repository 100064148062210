import React from 'react';
import { LaunchDarklyContext } from './context';

export function useFlag(flagName: string, defaultValue?: any) {
  // We might use this soon
  // const user = React.useContext(UserContext);
  const client = React.useContext(LaunchDarklyContext);
  const [shouldUpdate, setShouldUpdate] = React.useState(true);
  const [flagDetails, setFlagDetails] = React.useState(() => client.variationDetail(flagName, defaultValue));

  const update = () => {
    if (shouldUpdate) {
      const details = client.variationDetail(flagName, defaultValue);
      setFlagDetails(details);
    }
  };

  React.useEffect(() => {

    const details = client.variationDetail(flagName, defaultValue);
    if (details !== flagDetails) {
      setFlagDetails(details);
    }
  },
    [flagName, defaultValue]);

  React.useEffect(() => {
    client.on(`change:${flagName}`, update);
    return () => {
      setShouldUpdate(false);
      client.off(`change:${flagName}`, update);
    };
  }, [flagName, defaultValue]);

  return [flagDetails.value];
}
