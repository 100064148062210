import React from 'react';
import Footer from './components/footer';
import ProgressBar from './components/progress-bar';
import StepHeader from './components/step-header';
import Step from './components/step';
import { WizardProvider } from './useWizardContext';
import './styles';

function Wizard(props){
  return (
    <WizardProvider steps={props.steps} index={props.index}>
      <div className="editor__wizard">
        <div className="editor__wizard__header">
          {props.headerContent}
          {!props.hideProgress && <ProgressBar />}
        </div>
        <StepHeader />
        <Step>{props.children}</Step>
        <Footer />
      </div>
    </WizardProvider>
  );
}

export default Wizard;
