import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import IconMatch from '../../icons/IconMatch';
import ViewField from '../../library/view-field';
import { AuditViewField } from '../../audit/AssignmentPortal';

class InternalInfo extends PureComponent {
  render() {
    return (
      <div className="editor__internal position-relative">
        {this.props.canViewInMatch && (
          <a
            href={this.props.matchPath}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-white btn-circle edit-icon"
          >
            <IconMatch fillColor="#985d54" />
          </a>
        )}
        <div className="container-fluid well">
          <ViewField label="Canonical ID" value={this.props.canonicalPlaceId} />
          <ViewField label="Fidelity" value={this.props.fidelity} />
          <ViewField label="Source Places" value={this.props.placesCount} />
          <ViewField label="Match Status" value={this.props.matchStatus || ''} />
          <div id="audit-assignment-portal-anchor">
            {this.props.audit && this.props.audit.id && (
              <AuditViewField name={this.props.audit.user_name || 'Anyone'} url={this.props.audit.avatar_url || null} />
            )}
          </div>
          <ViewField label="Previous IDs" value={this.props.previousIds ? this.props.previousIds.join(', ') : 'none'} />
          <ViewField
            label="Engagement"
            value={
              this.props.engagement
                ? [
                    this.props.engagement.avgRating === null ? '' : 'Avg Rating:' + this.props.engagement.avgRating,
                    this.props.engagement.ratingCount === null
                      ? ''
                      : 'Rating Count:' + this.props.engagement.ratingCount.toLocaleString(),
                    this.props.engagement.visits === null
                      ? ''
                      : 'Visits:' + this.props.engagement.visits.toLocaleString()
                  ].join(' ')
                : 'none'
            }
          />
        </div>
      </div>
    );
  }
}

InternalInfo.propTypes = {
  matchPath: PropTypes.string.isRequired,
  canonicalPlaceId: PropTypes.number.isRequired,
  fidelity: PropTypes.number,
  placesCount: PropTypes.number.isRequired,
  audit: PropTypes.shape({
    id: PropTypes.number,
    canonical_place_id: PropTypes.number,
    user_name: PropTypes.string,
    avatar_url: PropTypes.string
  }),
  engagement: PropTypes.shape({
    avgRating: PropTypes.number,
    ratingCount: PropTypes.number,
    visits: PropTypes.number
  }),
  canViewInMatch: PropTypes.bool.isRequired,
  matchStatus: PropTypes.string,
  previousIds: PropTypes.array
};

export default InternalInfo;
