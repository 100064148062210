import React from 'react';

function EditField(props) {
  const kids = React.Children.toArray(props.children);
  return (
    <div data-cy={props.cy} className={`${props.className + ' ' || ''}editor__field editor__field--edit`}>
      <div className="editor__label">{kids[0]}</div>
      {kids[1]}
    </div>
  );
}

export default EditField;
