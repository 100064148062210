import React from "react";
import * as ajax from "@/helpers/ajax";
import useApi from "./useApi";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import useRequired from "./useRequired";
import type {RequiredSpec} from "./useRequired";

export type Props<State> = {
  initialState: State;
  required?: RequiredSpec<State>;
  gsheet_hash?: string;
  atlas?: {
    method?: string;
    url?: string;
    payloadFormatter: (
      state: State
    ) => {
      listing?: string;
      correction?: string;
    };
  };
  gsheet?: {
    payloadFormatter: (state: State) => string;
  };
  recaptcha?: {
    action?: string;
  };
  intervalSave?: number;
  autoSave?: boolean;
  dismountSave?: boolean;
};

export type Returns<State> = {
  setState: React.Dispatch<React.SetStateAction<State>>;
  setField: (value: any, field: string) => void;
  state: State;
  onChange: (
    e: React.ChangeEvent,
    fmt: (s: State, value: string) => void
  ) => void;
  updateField: (e: React.ChangeEvent, field: string) => void;
  save: () => Promise<boolean>;
  getRequiredFields?: () => {[field: string]: boolean};
  requiredFields: {[field: string]: boolean};
  getState: any;
};

function useState<State>(props: Props<State>): Returns<State> {
  const [state, setState] = React.useState<State>(props.initialState);
  const recaptcha = props.recaptcha ? useGoogleReCaptcha() : null;

  const required = props.required && useRequired<State>(props.required);

  const atlasApi = props.atlas
    ? useApi({
        xhrFn: async (url, params) => {
          const fn = ajax[props.atlas.method];
          return await fn(url, params);
        },
      })
    : null;

  const googleSheetApi = props.gsheet
    ? useApi({
        onSuccess: function (json) {
          console.debug(json);
        },
        xhrFn: async (url, params) => {
          return await ajax._fetch(url, params);
        },
      })
    : null;

  const updateGoogleSheet = async () => {
    // avoid stale state value in callback
    let st;
    setState((prev) => {
      st = prev;
      return prev;
    });
    if (googleSheetApi) {
      return await googleSheetApi.persistChanges(
        `https://script.google.com/macros/s/${
          props.gsheet_hash
        }/exec?${props.gsheet.payloadFormatter(st)}`,
        {
          method: "GET",
        }
      );
    }
  };

  const updateAtlas = async () => {
    if (atlasApi) {
      // avoid stale state value in callback

      // @ts-ignore
      const freshState = await getState();

      // @ts-ignore
      const payload = props.atlas.payloadFormatter(freshState);

      return await atlasApi.persistChanges(props.atlas.url, payload);
    }
  };

  async function getState() {
    return new Promise(async (resolve, reject) => {
      let t;

      setState((s) => {
        t = s;
        return s;
      });

      const start = new Date().getTime();

      while (true) {
        if (t) {
          break; // or return
        }
        if (new Date().getTime() > start + 3000) {
          break; // or throw
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      if (t) {
        return resolve(t);
      } else {
        return reject("error");
      }
    });
  }

  const save = async () => {
    const freshState = await getState();

    if (required) {
       // @ts-ignore
      const ok = required.checkFields(freshState);
      if (!ok) {
        if('errors' in (freshState as any)) {
          setState((s) => ({...s, errors: "Missing Required Fields"}))
        }
        return false;
      }
    }

    if (recaptcha) {
      try {
        const token = await recaptcha.executeRecaptcha(props.recaptcha.action);
        const [promise] = await ajax._post("/verify_recaptcha", {
          recaptcha_action: props.recaptcha.action,
          token,
        });
        const json = await promise;
        if (json.success) {
          if (googleSheetApi) await updateGoogleSheet();
          if (atlasApi) await updateAtlas();
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    } else {
      if (googleSheetApi) await updateGoogleSheet();
      if (atlasApi) await updateAtlas();
      return true;
    }
  };

  return {
    getState,
    setState,
    setField: (value, field) => setState((s) => ({...s, [field]: value})),
    state,
    onChange: (e, fmt) => {
      const element = e.currentTarget as HTMLInputElement;
      const value = element && element.value;

      // @ts-ignore
      setState((s) => fmt(s, value));
    },
    updateField: (e, field) => {
      // Store event value in variable to force it to
      // persist, see react event pooling for more info
      const element = e.currentTarget as HTMLInputElement;
      const value = element?.value;
      setState((s) => ({...s, [field]: value}));
    },
    save,
    requiredFields: required?.requiredFields,
    getRequiredFields: () => required?.requiredFields,
  };
}

export default useState;
