import React from "react";
import {DateTime} from "luxon";
import Section from "@library/section";
import bem from "@/helpers/bem";
import DynClr from "@library/dyn-clr";
import "./styles";

export type Props = {
  changed_at: string;
  author: string;
  field: string;
  hideTimestamp?: boolean;
  old_value: boolean | string | null | number | SVGElement | string[];
  new_value: boolean | string | null | number | SVGElement | string[];
};

function parse(value) {
  if (value === null) return "null";
  if (value === undefined) return "undefined";
  if (value === "") return "none";
  if (Array.isArray(value) && value.length === 0) return "[ ]";
  if (value === false) return "false";
  if (value === true) return "true";
  if (Array.isArray(value) && value.length > 0 && typeof value[0] !== "object")
    return value.join(", ");
  if (typeof value === "object") {
    return <pre>{JSON.stringify(value, null, 2)}</pre>;
  }
  if (typeof value === "string" && value.startsWith("<svg ")) {
    const src = `data:image/svg+xml;utf8,${encodeURIComponent(value)}`;
    return <img src={src} title={value} />;
  }
  return value;
}

function Diff(props: Props) {
  const b = bem("editor__diff");
  const iso = DateTime.fromISO(props.changed_at);
  const days = iso.diffNow("days").days;
  const daysAgo = days === 0 ? 0 : days * -1;
  return (
    <div className={b()}>
      <Section
        minHeight={55}
        collapsed={true}
        header={
          <div className={b("meta")}>
            <div className={b("meta__field__wrapper")}>
              <DynClr className={b("meta__field")} str={props.field}>
                {props.field}
              </DynClr>
            </div>
            <div className={b("meta__author")}>{props.author}</div>
            <div className={b("meta__datetime")}>
              {daysAgo < 1 && "today"}
              {daysAgo < 2 && daysAgo > 1 && "yesterday"}
              {daysAgo > 2 && `${parseInt(daysAgo + "")} days ago`}
            </div>

            {!props.hideTimestamp && (
              <div className={b("meta__timestamp")}>
                {DateTime.fromISO(props.changed_at).toFormat("D, hh:mm a ZZZZ")}
              </div>
            )}
          </div>
        }
      >
        <div className={b("data")}>
          <div className={b("data__change")}>
            <div className={b("data__change__value", {old: true})}>
              {parse(props.old_value)}
            </div>
            <div className={b("data__change__value", {new: true})}>
              {parse(props.new_value)}
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

export default Diff;
