import React, { ReactElement } from 'react';
import BooleanRow from '@library/boolean-row/edit';
import Subsection from '@/library/wizard/components/subsection';
import './styles';

type Props<State> = {
  label?: string;
  field: string;
  value: boolean;
  setState: (s: State | ((prevState: State) => State)) => State;
  state: State;
};

const Type = (props: Props<{ [field: string]: boolean }>) => {
  return (
    <BooleanRow
      binary
      label={props.label}
      value={props.state[props.field]}
      onChange={(v) => {
        props.setState((s) => ({ ...s, [props.field]: v }));
      }}
    />
  );
};

function Component(props: any): ReactElement {
  return (
    <div className="editor__data-outreach__rv-service__service-areas">
      <Subsection>
        <Type {...props} label="RV Service Center" field="service_center" />
        <Type {...props} label="RV Mobile Service" field="mobile_service" />
        <Type {...props} label="RV Sales" field="sales" />
        <Type {...props} label="RV Parts & Accessories Sales" field="parts_sales" />
        <Type {...props} label="RV Storage" field="storage" />
        <Type {...props} label="RV Rental" field="rental" />
      </Subsection>
    </div>
  );
}

export default Component;
