export const assignment = (audit, users) => {
  const DEFAULT_USER = { name: 'Anyone', avatar_url: null };

  const hasAudit = Boolean(audit.id);
  if (!hasAudit) {
    return { hasAudit: null };
  }

  const hasUsers = Boolean(users) && users.length > 0;
  if (!hasUsers) {
    return { hasAudit: true, assignedUser: DEFAULT_USER };
  }

  const assignedUser = { ...DEFAULT_USER };

  const found = users.find(user => user.id === audit.user_id);

  if (found) {
    if (found.name) {
      assignedUser.name = found.name;
    }
    if (found.avatar_url) {
      assignedUser.avatar_url = found.avatar_url;
    }
  }
  return { hasAudit, assignedUser };
};
