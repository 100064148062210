import React from 'react';
import { IconCheck } from '../../../../icons/IconCheck';
import { IconX } from '../../../../icons/IconX';
import { IconWarning } from '../../../../icons/IconWarning';
import { isToday } from '../../../../helpers/TimeFormat';
import classNames from 'classnames';
import moment from 'moment';
import * as c from '../../constants';
import ViewField from '../../../../library/view-field';
const Status = ({ status }) => {
  const formatDate = date => {
    if (!date) {
      return '';
    }
    return isToday(date) ? 'today' : moment(date).format(c.VIEW_DATE_FORMAT);
  };
  if (status) {
    let icon = <IconCheck fillColor="#707c3b" />;
    let statusText = '';
    let hasKnownWarnings = false;
    if (status && status.operating_normally) {
      if (status.warnings && status.warnings.indexOf('hazardous_conditions_nearby') !== -1) {
        statusText = 'Hazardous conditions nearby. Check availability and take caution.';
        icon = <IconWarning fillColor="#8f3025" />;
        hasKnownWarnings = true;
      } else {
        statusText = 'Operating normally';
      }
    } else if (status.reason === 'permanently_closed') {
      statusText = 'Permanently closed';
      icon = <IconX fillColor="#8f3025" />;
    } else {
      statusText = 'Temporarily closed';
      icon = <IconX fillColor="#8f3025" />;
      if (status.changes_at) {
        if (isToday(status.changes_at)) {
          statusText += ' today';
        } else {
          statusText += ' through ' + formatDate(status.changes_at);
        }
      }
      if (status.reason !== 'other') {
        statusText += ' for ' + status.reason;
      }
    }

    return (
      <ViewField
        className="editor__hours__status--view"
        label={icon}
        value={
          <div
            data-cy="status"
            className={classNames({
              'font-weight-bold': true,
              'color-success': status.operating_normally && !hasKnownWarnings,
              'color-warning': !status.operating_normally || hasKnownWarnings
            })}
          >
            {statusText}
          </div>
        }
      />
    );
  } else {
    return null;
  }
};

export default Status;
