import React, { Component } from 'react';
import Heading from './Heading';
import PropTypes from 'prop-types';

export const BoonePageHeader = ({ children }) => (
  <header className="boone-page__header">
    <div className="boone-page__header__hero" />
    <div className="boone-page__header__items">{children}</div>
  </header>
);
BoonePageHeader.propTypes = {
  children: PropTypes.any
};

export const BoonePageLogo = ({ children }) => (
  <Heading level={2} primary className="boone-page__header__item boone-page__title">
    {children}
  </Heading>
);
BoonePageLogo.propTypes = {
  children: PropTypes.any
};

export const BoonePageHeadline = ({ children }) => (
  <Heading level={1} primary className="boone-page__header__item boone-page__headline">
    {children}
  </Heading>
);
BoonePageHeadline.propTypes = {
  children: PropTypes.any
};

export const BoonePageSubheadline = ({ children }) => (
  <Heading level={2} secondary className="boone-page__header__item boone-page__subheadline">
    {children}
  </Heading>
);
BoonePageSubheadline.propTypes = {
  children: PropTypes.any
};

export const BoonePageFooter = ({ children }) => <footer className="boone-page__footer">{children}</footer>;
BoonePageFooter.propTypes = {
  children: PropTypes.any
};

export const BoonePageMain = ({ children }) => <main className="boone-page__main">{children}</main>;
BoonePageMain.propTypes = {
  children: PropTypes.any
};

// To ensure that BoonePage* style_guide are wrapped by a div with the correct classes on mount, add this
// component to the root of your react component tree and set the following options via `react-rails`.
//
// Suggested options:
//  `{ class: '<your class> boone-page' }`
//
// Example usage:
//
// In your react class...
//  class YourComponent extends Component { ... render() { return <BoonePage>...</BoonePage>; } }
//
// In your *.html.erb template ...
//  <%= react_component 'YourComponent', { ...props }, { class: '<your class> boone-page' } %>
//
// Rendered html:
//  <body>
//    <div class="<your class> boone-page">
//      <header class="boone-page__header"> ... </header>
//      <main class="boone-page__main"> ... </main>
//      <footer class="boone-page__footer"> .. </footer>
//    </div>
//  </body>
class BoonePage extends Component {
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

BoonePage.propTypes = {
  children: PropTypes.any
};

export default BoonePage;
