// Accounts for first names that may have spaces or two parts
// by grabbing all of the text before the last space
// ex: Mary Ann Smith -> Mary Ann
export const getFirstName = name => {
  const nameArr = name.split(' ');
  if (nameArr.length > 1) {
    nameArr.pop();
  }
  return nameArr.join(' ');
};
