
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import FormV1 from './connector';
import useSteps from './useSteps';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`TRV Service Outreach`}</h3>

    <FormV1 name="form" contact_info={{
      email: "hello@gmail.com"
    }} location={{
      address1: '124 main'
    }} rv_services={{
      data: {},
      attributes: [{
        id: "available_services",
        options: [{
          id: '0',
          name: "service"
        }, {
          id: '1',
          name: "service1"
        }, {
          id: '2',
          name: "service2"
        }]
      }]
    }} mdxType="FormV1" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;