import React from 'react';
import ContactSection from '@/data-outreach/_core/forms/components/contact';

function MobileServiceContact({ show, state, updateField, ...props }){
  return show ? (
    <ContactSection
      state={state}
      updateField={updateField}
      label="RV Mobile Service"
      fields={
        props.fields || [
          ['Phone', 'mobile_phone', 'tel', 'phone'],
          ['Email', 'mobile_email', 'email', 'email']
        ]
      }
      title="RV Mobile Service"
    />
  ) : null;
}

export default MobileServiceContact;
