import React from 'react';
import type {ApplyFilter, RemoveFilter} from "../types";

export type TopLevelProps = {
  applyFilter: ApplyFilter;
  float: boolean;
  label: string;
  maxValue: string;
  minValue: string;
  name: string;
  removeFilter: RemoveFilter;
  step: string;
};

export type SingleValueProps = {
  label?: string;
  onChange: (number) => void;
  setShow: (boolean) => void;
  show: boolean;
  value: number;
  cy?: string;
};

export type RangeProps = {
  disabled: boolean;
  max: number;
  min: number;
  onChange: (values: [number, number]) => void;
  values: [number, number];
  cy?: string;
};

export type FormFns = {
  fmtLabel: () => any;
  handleApply: () => void;
  handleCancel: () => void;
  handleClear: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type FormState = {
  active: boolean;
}
