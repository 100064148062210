import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconEdit from './icons/IconEdit';

export default class EditButton extends PureComponent {
  render = () => {
    const { onClick, ...props } = this.props;
    return (
      <span className="btn btn-white btn-circle edit-icon" onClick={onClick}>
        <IconEdit {...props} />
      </span>
    );
  };
}

EditButton.propTypes = {
  onClick: PropTypes.func
};

export { EditButton };
