import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, PopUp } from '@commodityvectors/react-mapbox-gl';
import { BOTTOM_INSET, RIGHT_INSET } from './constants';
import * as util from './util';

export class Popup extends Component {
  render = () => {
    const pt = [this.props.longitude, this.props.latitude];
    const show = util.visibleInMap(this.props.map, pt, 0, BOTTOM_INSET, 0, RIGHT_INSET);
    return show ? (
      <PopUp
        id={this.props.id}
        className={this.props.className}
        map={this.props.map}
        options={this.props.options}
        position={pt}
      >
        {this.props.children}
      </PopUp>
    ) : null;
  };
}

Popup.defaultProps = {
  id: 'popup',
  className: 'popup',
  flyToOnClick: false,
  options: { offset: [0, -14], closeButton: false, anchor: 'bottom' }
};

Popup.propTypes = {
  id: PropTypes.string,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  map: PropTypes.any.isRequired,
  className: PropTypes.string,
  options: PropTypes.shape({
    offset: PropTypes.array,
    closeButton: PropTypes.bool,
    anchor: PropTypes.string
  }),
  children: PropTypes.any,
  onClick: PropTypes.func
};

export default Map.component(Popup);
