import React from 'react';
import parseQueryString from '@/helpers/parseQueryString';

function useParams(){
  const [params, setParams] = React.useState({});

  React.useEffect(() => {
    const url = parseQueryString();
    if (Object.keys(url.params).length > 0) {
      setParams(url.params);
    }
  }, []);
  return params;
}

export default useParams;
