import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconSearch extends React.Component {
  render() {
    var props = $.extend({
        width: '16',
        height: '16',
        viewBoxHeight: '24',
        viewBoxWidth: '24',
        viewBoxX: '0',
        viewBoxY: '0',
        title: 'Search',
        className: 'icon-search',
        path: "M17.33 16.15a6.3 6.3 0 1 0-1.18 1.18l4.42 4.42c.17.17.38.25.59.25.17 0 .3-.04.42-.13h.04l.13-.12a.81.81 0 0 0 0-1.18l-4.42-4.42zm-9.6-3.83a4.6 4.6 0 0 1 9.18 0 4.6 4.6 0 0 1-9.18 0z"
      },
      this.props
    );
    return (
      <SinglePathIcon {...props}/>
    );
  }
}
