import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutsideClickHandler } from '../OutsideClickHandler';
import applyFilter, {removeFilter} from "@/helpers/applyFilter";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface Props {
  sort: string;
}

interface Option {
  name: string;
  icon?: IconProp;
  value: string;
}

export const Sort = ({ sort, ...props }: Props) => {
  const searchOptions: Array<Option> = [
    { name: 'Default (TravelRank)', icon: 'sort-numeric-up', value: null },
    { name: 'Reverse TravelRank', icon: 'sort-numeric-down', value: '+ranking.score' },
    { name: 'Alphabetically', icon: 'sort-alpha-down', value: 'name' },
    { name: 'Reverse Alphabetically', icon: 'sort-alpha-up', value: '-name' },
    { name: 'Most Recently Updated', icon: 'sort-amount-down', value: '-last_updated' },
    { name: 'Least Recently Updated', icon: 'sort-amount-up', value: 'last_updated' }
  ];

  const getOptionFromValue = (value: string) => {
    let matchingOption = searchOptions.find((opt) => opt.value == value);

    if (!matchingOption) {
      // Handle no matching option
      matchingOption = {name: 'Custom', icon: 'sort', value: value};
    }

    return matchingOption;
  };

  const [showSortOptions, setShowSortOptions] = useState<boolean>(false);
  const [sortOption, setSortOption] = useState<Option>(getOptionFromValue(sort));

  const setSelectOption = (sortValue: string) => {
    if (sortValue) {
      applyFilter({sort: sortValue});
    } else {
      removeFilter(['sort']);
    }
  };

  const SortOption = ({ ...option }: Option) => (
    option &&
    <div className="sort-option"
      onClick={() => {
        setSortOption(option);
        setSelectOption(option.value);
        setShowSortOptions(false);
      }}>
      <div className="option-row">
        <div className="icon">{
          option.icon &&
          <FontAwesomeIcon
            icon={option?.icon}
          />
        }</div>
        <div className="name">
          {option.name}
        </div>
      </div>
    </div>
  );

  return (
      <OutsideClickHandler
        onOutsideClick={() => setShowSortOptions(false)}
      >
        <div className="btn btn-light"
          title="Sort by"
          onClick={() => {
            setShowSortOptions(!showSortOptions)
          }}>
          <span>
            {`Sort: ${sortOption.name} `}
            <FontAwesomeIcon
              icon={'sort'}
            />
          </span>
        </div>
        {showSortOptions &&
          <div className="sort-menu">
            {searchOptions.map(
              (option: Option) =>
                <SortOption
                  key={option.name}
                  {...option}
                />)
            }
          </div>}
      </OutsideClickHandler>
  )
};
