import React from 'react';

function useApi(props){
  const [request, setRequest] = React.useState(null);
  const [status, setStatus] = React.useState({});

  const onSuccess = (json) => {
    setStatus((p) => ({ ...p, inFlight: false, error: false }));
    if (props.onSuccess) props.onSuccess(json);
  };

  const onError = (e) => {
    setStatus((p) => ({ ...p, inFlight: false, error: true, errorMsg: e }));
    if (props.onError) props.onError();
  };

  const persistChanges = async (url, params) => {
    // We only want the latest request to go through
    if (request && request.abort) {
      request.abort();
    }

    const [promise, abort] = await props.xhrFn(url, params);

    setRequest({ abort });
    setStatus({ inFlight: true });

    promise
      .then((json) => {
        if (json.result === 'error') {
          onError(json);
          console.debug(json);
        } else {
          onSuccess(json);
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          onError(e);
          console.debug(e);
        }
      });
  };

  return { ...status, persistChanges, request };
}

export default useApi;
