import React from "react";
import * as ajax from "@/helpers/ajax";
import Button from "@library/_button";
import "./styles";

function Enable(props) {
  const [banner, setBanner] = React.useState(true);
  async function enable() {
    if (props.onClick) props.onClick();
    if (props.canEnable) {
      const [promise] = ajax._post(props.enablePath);
      promise
        .then((json) => {
          console.debug(json);
        })
        .catch((e) => {
          console.debug(e);
        });
    }
  }

  return !props.enabled && props.canEnable ? (
    <>
      {banner && (
        <>
          <div className="editor_overlay"></div>
          <div className="editor__enable__banner">
            <div>
              This listing is <span className="editor__admin">disabled</span>,
              please enable it to make changes.
            </div>
            <br />
            <div className="editor__footer">
              <Button onClick={() => setBanner(false)} className="editor__cancel">
                close
              </Button>
              <div className="editor__enable">
                <Button onClick={enable} className="editor__button__primary">
                  enable
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="editor__enable">
        <Button onClick={enable} className="editor__button__primary">
          enable
        </Button>
      </div>
    </>
  ) : null;
}

export default Enable;
