import React from 'react';
import DataSection from '../../library/data-section';
import ViewField from '../../library/view-field';
import InputWithLabel from '../../library/input-with-label';
import TextArea from '@/library/text-area';
import Link from '@/library/link/view';

type Props = {
  editorial?: string;
  contributor?: string;
  userEditingStrategy: 'suggest' | 'update' | 'none';
};

const Editorial = function(props: Props) {
  const [editorial, setEditorial] = React.useState(props.editorial);
  const [savedEditorial, setSavedEditorial] = React.useState(props.editorial);
  const [contributor, setContributor] = React.useState(props.contributor);
  const [savedContributor, setSavedContributor] = React.useState(props.contributor);
  return (
    <div className="boone-rt__editorial" data-cy="rt-editorial">
      <DataSection
        sectionTitle="Editorial"
        cyEdit="rt-editorial-edit"
        cyView="rt-editorial-view"
        viewComponent={
          <>
            <ViewField value={savedEditorial || ''} label="Our Take" />
            <br/>
            <Link
              externalLink={savedContributor}
              value={savedContributor || ''} label="Contributor"
            />
          </>
        }
        editComponent={
          <>
            <div className="editor__textarea">
              <label className="editor__label">Our Take</label>
              <TextArea
                data-cy="rt-editorial-edit-text-area"
                value={editorial || ''}
                onChange={e => setEditorial(e.target.value)}
              />
            </div>
            <InputWithLabel
              cy="rt-editorial-contributor-edit-input"
              label="Contributor"
              value={contributor}
              onChange={e => setContributor(e.target.value)}
            />
          </>
        }
        params={{
          listing: { road_trip_details: { editorial, contributor } }
        }}
        onSuccess={() => {
          setSavedEditorial(editorial);
          setSavedContributor(contributor);
        }}
        reset={() => {
          setEditorial(savedEditorial);
          setContributor(savedContributor);
        }}
        userEditingStrategy={props.userEditingStrategy}
      />
    </div>
  );
};
export default Editorial;
