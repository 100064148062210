import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as ajax from './helpers/ajax';
import { CancelButton, RoundEditButton, SaveButton } from './library/Buttons';

export default class BasicInfoView extends Component {
  state = {
    editing: false,
    saving: false,
  };

  cancel = () => {
    this.props.reset();
    this.setState({
      editing: false,
      saving: false,
    });
  };

  save = () => {
    this.setState({ saving: true });
    ajax.patch(this.props.updateUrl, this.props.updateParams()).then((response) => {
      let saved = true;
      if (this.props.onSave) {
        let result = this.props.onSave(response);
        if (result && result.cancel) {
          saved = false;
        }
      }
      if (saved) {
        this.setState({ editing: false, saving: false });
      } else {
        this.setState({ saving: false });
      }
    });
  };

  renderViewForm() {
    return (
      <div className="position-relative">
        {this.props.isEditable && <RoundEditButton onClick={() => this.setState({ editing: true })} />}
        <div className="container-fluid well">{this.props.renderViewForm()}</div>
      </div>
    );
  }

  renderEditForm() {
    return (
      <div className="container-fluid well-no-clip">
        {this.props.renderEditForm()}
        <div className="row my-4 pr-3">
          <div className="col text-right">
            <CancelButton onCancel={this.cancel} />
            <SaveButton disabled={this.state.saving} onSave={this.save} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.editing ? this.renderEditForm() : this.renderViewForm()}
      </div>
    );
  }
}

BasicInfoView.defaultProp = {
  editing: false,
};

BasicInfoView.propTypes = {
  editing: PropTypes.bool,
  renderEditForm: PropTypes.func,
  renderViewForm: PropTypes.func,
  reset: PropTypes.func,
  updateUrl: PropTypes.string,
  onSave: PropTypes.func,
  isEditable: PropTypes.bool,
  diff: PropTypes.any,
  updateParams: PropTypes.any,
};
