import React from 'react';
import PropTypes from 'prop-types';
import MediaAjax from './MediaAjax';

class Gallery extends React.Component {
  render() {
    return (
      <div className="boone-media__gallery">
        <div className="container-fluid well px-4">
          <div id={this.props.id} className="row my-3">
            {this.props.media.map(media => (
              <div className="col-sm-4 my-3" key={media.id}>
                {this.props.renderMedia(
                  media,
                  () => this.handleDelete(media.id),
                  () => this.handleUnflag(media.id),
                  this.handleToggleFeatured,
                  this.props.isEditable
                )}
              </div>
            ))}
            {this.props.isEditable && <div className="col-sm-4 my-3">{this.props.renderMediaUpload()}</div>}
          </div>
        </div>
      </div>
    );
  }

  handleDelete = id => {
    let confirmationId = '#' + (this.props.deleteConfirmation ? this.props.deleteConfirmation : 'deleteConfirmation');
    $(confirmationId).modal('show');

    let deleteConfirmationButtonId =
      '#' + (this.props.deleteConfirmationButton ? this.props.deleteConfirmationButton : 'confirm-delete-button');
    var confirmDeleteButton = $(deleteConfirmationButtonId);
    confirmDeleteButton.off('click');

    let that = this;
    confirmDeleteButton.on('click', function() {
      $(confirmationId).modal('hide');
      that.deleteMedia(id);
    });
  };

  handleUnflag = id => {
    MediaAjax.unflagMedia(this.props.apiUrl, id);
    this.props.onUnflagged(id);
  };

  handleToggleFeatured = (id, previousValue) => {
    // Switch it
    let featured = !previousValue;
    MediaAjax.setMediaFeatured(this.props.apiUrl, id, featured, this.props.targetKey);
    this.props.onFeaturedChanged(id, featured);
  };

  deleteMedia = id => {
    MediaAjax.deleteMedia(this.props.apiUrl, id, this.props.ownerKey, this.props.ownerId);
    this.props.onDeleted(id);
  };
}

Gallery.propTypes = {
  id: PropTypes.string.isRequired,
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  apiUrl: PropTypes.string.isRequired,
  targetKey: PropTypes.string.isRequired,
  renderMedia: PropTypes.func.isRequired,
  renderMediaUpload: PropTypes.func.isRequired,
  deleteConfirmation: PropTypes.string,
  deleteConfirmationButton: PropTypes.string,
  ownerKey: PropTypes.string.isRequired,
  ownerId: PropTypes.number.isRequired,
  onFeaturedChanged: PropTypes.func,
  onDeleted: PropTypes.func.isRequired,
  onUnflagged: PropTypes.func,
  isEditable: PropTypes.bool.isRequired
};

export default Gallery;
