import React from 'react';
import RoadpassLogo from '@/data-outreach/_core/forms/components/roadpass-logo';
import Wizard from '@library/wizard';
import './styles';

function Form(props){
  return (
    <div className="editor__data-outreach editor__data-outreach__rv-service">
      <Wizard
        index={props.index}
        steps={props.steps}
        headerContent={
          <div className="editor__logo">
            <RoadpassLogo />
          </div>
        }
      >
        {props.children}
      </Wizard>
    </div>
  );
}

export default Form;
