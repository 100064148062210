import * as React from 'react';
import Avatar from '../../library/Avatar';
import RoleSelect from './RoleSelect';
import Button from '@library/_button';
import '@library/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as ajax from '@/helpers/ajax';

type Props = {
  id: number;
  currentUserId: number;
  name: string;
  email?: string;
  invitation_pending: boolean;
  avatar_url?: string;
  role_id: number;
  membership_id: number;
  selectRole: (value: number) => void;
  onRemove: (userId: number) => void;
  onRemoveMembership: (id: number, userId: number) => void;
  onResendInvite: (email: string) => void;
  roles: { id: number; name: string }[];
  isSuperAdmin: boolean;
  userEditingStrategy: UserEditingStrategy;
};

export default function User({
  id,
  currentUserId,
  name,
  email,
  invitation_pending,
  avatar_url,
  role_id,
  membership_id,
  selectRole,
  onRemove,
  onRemoveMembership,
  onResendInvite,
  roles,
  userEditingStrategy,
  isSuperAdmin,
}: Props) {
  return (
    <tr className="boone-organization__user" data-cy={email}>
      <td className="boone-organization__user__identity">
        <div className="boone-organization__user__identity__avatar">
          <Avatar
            url={avatar_url}
            className="boone-avatar boone-avatar--round boone-avatar--lg"
            placeholderClassName="boone-avatar"
          />
        </div>
        <div className="boone-organization__user__identity__name-email">
          <div className="user-name">{name}</div>
          <div className="user-email small">{email}</div>
          {invitation_pending && <div className="small font-italic text-muted">Invitation Pending </div>}
        </div>
      </td>
      <td className="boone-organization__user__role-select">
        {id !== currentUserId && userEditingStrategy == 'update' ? (
          <RoleSelect showAlert={false} selected={role_id} selectRole={selectRole} options={roles} />
        ) : role_id === 1 ? (
          'Admin'
        ) : (
          <div data-cy="role">{roles.filter((role) => role.id === role_id)[0].name}</div>
        )}
      </td>
      <td className="boone-organization__user__actions">
        <div>
          <Button
            disabled={userEditingStrategy == 'none' || currentUserId == id}
            onClick={() => onRemoveMembership(membership_id, id)}
            className="editor__button--warning"
          >
            Remove
          </Button>
          {isSuperAdmin && (
            <Button
              disabled={userEditingStrategy == 'none' || currentUserId == id}
              onClick={() => onRemove(id)}
              className="editor__button--warning editor__button--delete-user"
            >
              <FontAwesomeIcon icon={'bolt'} /> Delete User
            </Button>
          )}
          {isSuperAdmin && (
            <Button
              disabled={userEditingStrategy == 'none' || currentUserId == id}
              onClick={() => onResendInvite(email)}
              className="editor__button--warning"
            >
              <FontAwesomeIcon icon={'bolt'} /> Resend Invitation
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
}
