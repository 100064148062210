import React from 'react';
import Form from '../_form';
import InternalContact from '../_form/components/contacts/internal';
import Listing from '../_form/components/listing';
import ServiceAreas from '../_form/components/service-areas';
import Services from '../_form/components/service-options';
import PublicContacts from '../_form/components/contacts/public';
import Summary from '../_form/components/summary';
import Notes from '../_form/components/notes';

function FormV1({ data, steps }){
  return (
    <Form steps={steps} index={0}>
      <InternalContact {...data} />
      <ServiceAreas {...data} />
      <Listing {...data} />
      <PublicContacts {...data} />
      <Services {...data} />
      <Notes {...data} />
      <Summary {...data} />
    </Form>
  );
}

export default FormV1;
