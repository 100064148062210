import React from "react";
import FormV1 from "./form";
import * as hooks from "@/library/launch-darkly/hooks";

function ConnectorV1() {
  const [enabled] = hooks.useFlag("roadtrippers-corrections", false);
  return (
    <React.Fragment>
      {enabled ? (
          <FormV1 />
      ) : (
        <p className="editor__well">:/ Page not found</p>
      )}
    </React.Fragment>
  );
}

export default ConnectorV1;
