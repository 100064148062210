// @ts-ignore
var global = window;

  interface JQuery {
    dataTable: any;
    datepicker: any;
    datetimepicker: any;
    moment: any;
    select2: any;
    DataTable: any;
    iCheck: any;
    animateCss: any;
    actual: any;
    fullscreen: any;
    noUiSlider: any;
    modal: any;
    tab: any;
    multiselect: any;
    spectrum: any;
  }


