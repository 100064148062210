import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  [x: string]: any;
  children?: any;
  ariaLabel?: any;
  className?: any;
  cy?: any;
};

function Button({ children, ariaLabel, className, cy, ...props }: Props){
  return (
    <button
      {...props}
      aria-label={ariaLabel}
      data-cy={cy}
      className={cn(['editor__button', className])}
    >
      {children}
    </button>
  );
}

export default Button;
