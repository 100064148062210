import * as React from 'react';
import LoadableVisibility from 'react-loadable-visibility/react-loadable';
import Loading from '../../library/loaders/nested';

const LoadableComponent = LoadableVisibility({
  loader: () => import('./index'),
  loading: Loading
});

export default class Container extends React.Component {
  render() {
    return <LoadableComponent {...this.props} />;
  }
}
