import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map, NavigationControl } from '@commodityvectors/react-mapbox-gl';

export class BaseMap extends PureComponent {
  render() {
    return (
      <Map
        {...this.props.viewport}
        mapStyle={'mapbox://styles/mapbox/streets-v11'}
        accessToken={window._env_.MAPBOX_KEY}
        onViewPortChange={(viewport) => this.props.onViewPortChange(viewport)}
      >
        {this.props.children}
        <NavigationControl position={'top-left'} />
      </Map>
    );
  }
}

BaseMap.propTypes = {
  viewport: PropTypes.shape({
    zoom: PropTypes.number,
    bearing: PropTypes.number,
    pitch: PropTypes.number,
    center: PropTypes.any,
  }),
  mapStyle: PropTypes.string,
  children: PropTypes.any,
  bounds: PropTypes.array,
  onViewPortChange: PropTypes.func,
};

export default BaseMap;
