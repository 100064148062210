import * as ajax from "@/helpers/ajax";
import { AuditParams } from "../common/types";


// ENDPOINTS FOR AUDIT RELATED CALLS GO HERE
const auditsApi = {
  postAuditsApi: (params: AuditParams) =>
    ajax._post(`/listing_audits`, params),

  deleteAuditsApi: (id: number) =>
    ajax._delete(`/listing_audits/${id}`),

  putAuditsApi: (params: AuditParams) =>
    ajax._put(`/listing_audits/${params.id}`, params),

  postBulkAuditsApi:(params: AuditParams) =>
  ajax._post('/listings/assign_audits/', params),
};

export default auditsApi;
