import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconAcceptSuggestion extends React.Component {
  render() {
    var props = $.extend({}, this.props, {
      fillColor: '#000',
      width: '16',
      height: '16',
      viewBoxHeight: '24',
      viewBoxWidth: '24',
      viewBoxX: '0',
      viewBoxY: '0',
      className: 'accept-suggestion',
      path: "M20.93 8a.99.99 0 0 0-.71.35l-8.09 8.95-4.43-4.16a.96.96 0 0 0-1.01-.21c-.35.14-.6.47-.67.87-.07.4.06.81.34 1.08l5.16 4.85a.96.96 0 0 0 1.4-.07l8.77-9.7c.3-.33.4-.83.23-1.27a1.03 1.03 0 0 0-1-.69z"
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}

export default IconAcceptSuggestion;
