import SearchTree from '@/library/tree-select/search-tree';
import React, { useState, useEffect } from 'react';
import './styles';


interface Filter {
  name?: string;
  adminOnly: boolean;
  expanded?: boolean;
  options?: string;
}

export const SearchFilters = () => {

  const filters: Array<Filter> = [
    {
      name: "Sources",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Source Count",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Place Count",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Regions",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Categories",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Image Count",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Review Count",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Rating",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Fidelity",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Audit Queue",
      adminOnly: false,
      expanded: false
    },
    {
      name: "Audits",
      adminOnly: false,
      expanded: false
    }
  ];

  const [expanded, setExpanded] = useState<Object>();

  const setupToggle = (filters) => {
    filters.forEach((filter) => {
      setExpanded((prev) => ({ ...prev, [filter.name]: false }));
    });
  };

  const resetToggle = (expanded, excluded?) => {
    let newExpanded = { ...expanded };

    Object.keys(newExpanded).forEach(key => {
      if (key !== excluded) {
        newExpanded[key] = false;
      }
    })
    setExpanded(newExpanded);
  }

  const handleContextToggle = (filter) => {
    resetToggle(expanded, filter.name);
    setExpanded((prev: any) => ({ ...prev, [filter.name]: !prev[filter.name] }));

  }

  useEffect(() => { setupToggle(filters); }, [])

  return (
    <div className="input-group search_filters">
      {filters.map((filter: Filter, index: any) => {

        return (
          <div className="filter_option" key={filter.name}>
            <button className="btn btn-light filter_button" onClick={(event) => {
              event.preventDefault();
              handleContextToggle(filter);
            }}>{filter.name}</button>
            {
              expanded && expanded[filter.name] &&
              <div className="filter-context-menu">
                <SearchTree
                  selection={[]}
                  index={index}
                  cancel={()=>{}}
                  clear={()=>{}}
                  save={()=>{}}
                  mirrorParent={false}
                  mirrorChild={true}
                  showSelection={true}
                />
              </div>
            }
          </div>
        )
      })}

    </div>
  )
}
