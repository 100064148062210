/* eslint-disable no-restricted-globals */
import React from 'react';
import _ from 'lodash';
import { assign, map } from 'lodash/fp';
import * as ajax from '@/helpers/ajax';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Errors } from './Types';
import Modal from '@/library/Modal.js';


import geoViewport from '@mapbox/geo-viewport';
import { LngLatBounds } from 'mapbox-gl';
import ReactMapboxGl, { GeoJSONLayer, Layer, Source } from 'react-mapbox-gl';

import styled from 'styled-components';

// Components
import Canonical from './Canonical';
import { MatchActions, ContinueActions } from './MatchActions';

import { Loading } from '../v2Editor/src/components/loading';

// -----------------------------------------------
// STYLED-COMPONENTS -----------------------------
// -----------------------------------------------
const StyledMapContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100% * 1 / 4);
`;

const StyledMatchArea = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(100% * 3 / 4);
  padding: 16px;
  overflow-y: scroll;
  background: #fefefe;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
`;

const StyledMatchFooter = styled.div`
  text-align: right;

  span.pending-results {
    font-size: 14px;
    padding:10px;
  }
`;

const StyledMatchTitle = styled.h1`
  float: left;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAlertBox = styled.div`
  background-color: #f2f4f5;
  border: 1px solid #708e9a;
  border-radius: 3px;
  margin-bottom: 2em;
  padding: 1.25em;
`;

// ===============================================
// COMPONENT->MATCH ==============================
// ===============================================
export default class Match extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props) {
    super(props);
    const { canonicalId } = this.props;
    const { target, map } = this.props;
    window.history.replaceState(null, null, `review?match_set_id=${this.props.target?.id || ''}`);

    if (!props.message?.error) {
      const viewport = this.initialViewport();

      this.state = {
        target,
        childMatchSets: target.child_match_sets,
        id: target.id,
        lng: viewport.center[0].toFixed(4),
        lat: viewport.center[1].toFixed(4),
        zoom: viewport.zoom.toFixed(2),
        isLoading: false

      };
    }
  }

  pathMatchType = () => {
    const { matchType } = this.props;
    return matchType === 'canonical' ? 'canonical_place' : 'match_set';
  };

  makeAPIUrl = suffix => {
    return `/match/${this.pathMatchType()}${suffix}`;
  };

  sendMatchAction = (type, additional = {}, callback = response => console.debug(response)) => {
    const data = Object.assign(
      {
        type,
        target_place_ids: _.map(this.state.target.places, _.property('id')),
        target_canonical_id: _.toInteger(this.props.canonicalId)
      },
      additional
    );

    ajax
      .post(this.makeAPIUrl('/confirm/'), data)
      .then(response => response.json())
      .then(callback)
      .catch(error => {
        console.error(error);
      });
  };

  confirmAllMatches = e => {

    // Collect all places for the ajax call
    let allPlaces = _.map(this.state.childMatchSets, child => ({
      canonical_id: child.id,
      place_ids: _.map(child.places, place => place.id)
    }));

    if (_.isObject(this.state.newCanonical)) {
      allPlaces = _.concat(allPlaces, { place_ids: _.map(this.state.newCanonical.places, place => place.id) });
    }

    // Perform the ajax call for confirm_all
    this.sendMatchAction(
      'confirm_all',
      {
        all_places: allPlaces
      },
      response => {
        this.setState(prevState =>
          update(prevState, {
            target: {
              isCollapsed: { $set: true },
              isConfirmed: { $set: true }
            },
            newCanonical: {
              $set: undefined
            },
            childMatchSets: {
              $unshift: [
                {
                  id: response.new_canonical_place_id,
                  places: prevState.newCanonical && prevState.newCanonical.places ? prevState.newCanonical.places : []
                }
              ],
              $apply: map(assign({ isCollapsed: true, isConfirmed: true }))
            }
          })
        );
      }
    );
    this.proceedOrReturn(this.state.target);
  };

  // ---------------------------------------------
  // HANDLERS->CONFIRMATIONS ---------------------
  // ---------------------------------------------
  handleDoesNotMatch = (e, id) => {
    this.sendMatchAction('non_match', {
      non_match_canonical_id: id,
      non_match_place_ids: _.map(_.find(this.state.childMatchSets, { id }).places, _.property('id'))
    });

    this.setState(prevState =>
      update(prevState, {
        childMatchSets: {
          $apply: childMatchSets =>
            childMatchSets.map(child => {
              if (child.id === id) {
                child.isCollapsed = true;
                childMatchSets.isConfirmed = true;
              }
              return child;
            })
        }
      })
    );
  };

  handleConfirmMatch = (e, id) => {
    // This should be the Target only
    this.sendMatchAction('match', {}, response =>
      this.setState(prevState =>
        update(prevState, {
          target: {
            isConfirmed: { $set: true }
          }
        })
      )
    );
    this.proceedOrReturn(this.state.target);
  };

  handleConfirmNewMatch = (e, id) => {
    this.sendMatchAction(
      'new',
      {
        new_canonical_place_ids: _.map(this.state.newCanonical.places, _.property('id'))
      },
      response =>
        // Move this group of places from `newCanonical` into `child`.
        // The API response includes the ID of the new group.
        // Set `newCanonical` to undefined so we can break off a place into
        // another New Canonical.
        this.setState(prevState =>
          update(prevState, {
            childMatchSets: {
              $unshift: [
                {
                  id: response.new_canonical_place_id,
                  places: prevState.newCanonical.places,
                  isCollapsed: true,
                  isConfirmed: true
                }
              ]
            },
            newCanonical: {
              $set: undefined
            }
          })
        )
    );
    this.proceedOrReturn(this.state.target);
  };

  handleCanonicalButtonClick = (e, id, type) => {
    switch (type) {
      case 'child':
        return this.handleDoesNotMatch(e, id);
      case 'target':
        return this.handleConfirmMatch(e, id);
      case 'new':
        return this.handleConfirmNewMatch(e, id);
      default:
        throw new Error(`Unhandled type '${type}' on click.`);
    }
  };

  handleRemoveFromQueueButtonClick = (e, id) => {
    ajax
      .destroy(this.makeAPIUrl('/queue/'), {
        target_id: id
      })
      .then(response => {
        this.setState(prevState =>
          update(prevState, {
            childMatchSets: {
              $apply: childMatchSets =>
                childMatchSets.map(child => {
                  if (child.id === id) {
                    child.isQueued = false;
                  }
                  return child;
                })
            }
          })
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleAddToQueueButtonClick = (e, id) => {
    ajax
      .post(this.makeAPIUrl('/queue/'), {
        target_id: id
      })
      .then(response => {
        this.setState(prevState =>
          update(prevState, {
            childMatchSets: {
              $apply: childMatchSets =>
                childMatchSets.map(child => {
                  if (child.id === id) {
                    child.isQueued = true;
                  }
                  return child;
                })
            }
          })
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleOnSkip = (matchRunId, matchSetId) => {
    this.setState(prevState =>
      update(prevState, {
        isLoading: { $set: true }
      })
    )
    window.location.href = `/match_runs/${matchRunId}/review?match_set_id=${matchSetId}`;
  }

  handleOnReturn = (matchRunId) => {
    window.location.href = `/match_runs/${matchRunId}`;
  }

  proceedOrReturn = (target) => {
    return (!target.next_reviewing_match_set_id || target.next_reviewing_match_set_id === '' ?
      this.handleOnReturn(target.match_run_id)
      :
      this.handleOnSkip(target.match_run_id, target.next_reviewing_match_set_id))
  }

  // ---------------------------------------------
  // MAP -----------------------------------------
  // ---------------------------------------------

  flyTo = options => {
    this.map.state.map.flyTo(options);
  };

  initialViewport = () => {
    const targetLocations = _.compact(
      this.props.target.places.map(place => place.locations.find(l => l.latitude && l.longitude))
    );

    const wsen =
      targetLocations.length === 0
        ? [0, 0, 0, 0]
        : [
          _.min(targetLocations.map(l => l.longitude)), // West
          _.min(targetLocations.map(l => l.latitude)), // South
          _.max(targetLocations.map(l => l.longitude)), // East
          _.max(targetLocations.map(l => l.latitude)) // North
        ];

    return geoViewport.viewport(wsen, [480, 640]);
  };

  zoomToFitCanonical = (e, id) => {
    const bounds = new LngLatBounds();

    const locations = _.map(this.findCanonical(id).places, place => place.locations[0]);

    _.each(locations, location => {
      if (location && _.isNumber(location.latitude) && _.isNumber(location.longitude)) {
        bounds.extend([location.longitude, location.latitude]);
      }
    });

    this.map.state.map.fitBounds(bounds, {
      padding: { top: 100, bottom: 100, left: 100, right: 100 }
    });
  };

  // ---------------------------------------------
  // DRAG-AND-DROP -------------------------------
  // ---------------------------------------------
  allCanonicals = () => {
    const canonicals = _.concat(this.state.childMatchSets, this.state.target);

    if (_.isObject(this.state.newCanonical)) {
      return _.concat(canonicals, this.state.newCanonical);
    }

    return canonicals;
  };

  findCanonical = id => {
    return _.find(this.allCanonicals(), canonical => canonical.id.toString() === id.toString());
  };

  movePlaceToCanonical(droppedPlace, moveToCanonicalId) {
    // SEE: placeSource in Place.jsx gives some of these

    // FIX: All of this below is ugly. Use immutability-helper
    // *******************************************
    const newTarget = _.cloneDeep(this.state.target);
    const newChildMatchSets = _.cloneDeep(this.state.childMatchSets);
    const newNewCanonical = _.isObject(this.state.newCanonical) ? _.cloneDeep(this.state.newCanonical) : undefined;

    // Remove the dropped place from everywhere (wherever it exists).
    // We'll add it later
    // -------------------------------------------
    _.remove(newTarget.places, { id: droppedPlace.id });

    _.each(newChildMatchSets, child => {
      _.remove(child.places, { id: droppedPlace.id });
    });

    if (_.isObject(newNewCanonical)) {
      _.remove(newNewCanonical.places, { id: droppedPlace.id });
    }

    // Add the place to the correct Canonical
    // -------------------------------------------
    if (moveToCanonicalId === newTarget.id) {
      newTarget.places.push(droppedPlace);
    }
    // If it's -1 we're moving to the "New Canonical"
    else if (moveToCanonicalId === -1) {
      newNewCanonical.places.push(droppedPlace);
      // Otherwise we're moving it into a "Child Canonical"
    } else {
      _.find(newChildMatchSets, { id: moveToCanonicalId }).places.push(droppedPlace);
    }

    // Set state
    // -------------------------------------------
    this.setState({
      target: newTarget,
      childMatchSets: newChildMatchSets,
      newCanonical: newNewCanonical // Either an object or undefined depending on whether or not we have a 'New Canonical'
    });
  }

  breakPlaceIntoNewCanonical = (e, place) => {
    e.stopPropagation();

    const updatedTarget = _.cloneDeep(this.state.target);
    _.remove(updatedTarget.places, { id: place.id });

    // Assign this on the frontend to -1 so we can drag
    // and drop later. We look at this value.
    place.canonical_place_id = -1;

    this.setState({
      target: updatedTarget,
      newCanonical: {
        id: -1,
        places: [place]
      }
    });
  };

  // Expands or collapses the group identified by the `path` argument list.
  // For example, toggleCollapse('children', 2) will toggle the second "similar canonical" group.
  toggleCollapse(...path) {
    console.log(path);
    // Convert a path like ['children', 2] into { children: { 2: $toggle: ['isCollapsed'] } }
    const changes = path.reverse().reduce((acc, cur) => ({ [cur]: acc }), { $toggle: ['isCollapsed'] });
    this.setState(prevState => update(prevState, changes));
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.removeEventListener('keydown', this.handleKeyDown);
      this.confirmAllMatches();
    }
  }

  // =============================================
  // RENDER ======================================
  // =============================================
  render() {
    this.props.message?.error &&
      console.error(
        `[Match Set Error] -`,
        `[Match Set id]: ${this.props.id || null} `,
        `[Error Details]: ${this.props.message.details}`
      );

    const { matchType } = this.props;

    const Map = ReactMapboxGl({
      accessToken: window._env_.MAPBOX_KEY
    });

    if (this.props) {
      if (this.props.message?.error) {
        return (
          <div className="matchruns-container">
            <Map
              containerStyle={{ position: 'fixed', top: 0, right: 0, bottom: 0, width: 'calc(100% * 1 / 4)' }}
              center={[0, 0]}
              style="mapbox://styles/mapbox/streets-v9"
            ></Map>
            <StyledMatchArea>
              <TitleContainer>
                <StyledMatchTitle>Match Review</StyledMatchTitle>
                <br />
                <br />
                <StyledAlertBox>
                  <strong>{this.props.message.error && Errors.matchReviewUnavailable}</strong>
                </StyledAlertBox>
              </TitleContainer>
            </StyledMatchArea>
          </div>
        )
      } else {

        let childMatchSetsCanonicals = [];

        const commonCanonicalProps = {
          matchType,
          flyTo: this.flyTo,
          handleCanonicalButtonClick: this.handleCanonicalButtonClick.bind(this),
          handleAddToQueueButtonClick: this.handleAddToQueueButtonClick.bind(this),
          handleRemoveFromQueueButtonClick: this.handleRemoveFromQueueButtonClick.bind(this),
          movePlaceToCanonical: this.movePlaceToCanonical.bind(this),
          breakPlaceIntoNewCanonical: this.breakPlaceIntoNewCanonical.bind(this),
          zoomToFitCanonical: this.zoomToFitCanonical.bind(this)
        };

        // Build child sets Canonicals
        // -------------------------------------------
        if (!_.isUndefined(this.state.childMatchSets)) {
          childMatchSetsCanonicals = _.map(_.values(this.state.childMatchSets), (child, i) => {
            const queryString = parseQueryString();
            let openAsTargetUrl = `/matchruns/${this.pathMatchType()}/${child.id}`;

            if (queryString.compare_to) {
              let compareToIds = queryString.compare_to.split(',');
              compareToIds = _.without(_.union([this.state.target.id], compareToIds), child.id);
              openAsTargetUrl = openAsTargetUrl + '?compare_to=' + compareToIds.join(',');
            }

            return (
              <Canonical
                type="child"
                target={false}
                new={false}
                canonical={child}
                places={child.places}
                id={child.id}
                key={child.id}
                openAsTargetUrl={openAsTargetUrl}
                onToggleCollapse={e => this.toggleCollapse('childMatchSets', i)}
                {...commonCanonicalProps}
              />
            );
          });
        }

        // Labels for "early" source features hide labels for "later" features, but the reverse
        // holds true for circles. Later circles are drawn over earlier circles. To ensure that the right
        // labels appear over the right circles, we need two sources with opposite feature orders.
        const labelData = this.props.map.data;
        const target = this.props.target;
        const circleData = { type: 'FeatureCollection', features: labelData.features.slice(0).reverse() };

        return (
          <DndProvider backend={HTML5Backend}>
            <div>
              <Map
                containerStyle={{ position: 'fixed', top: 0, right: 0, bottom: 0, width: 'calc(100% * 1 / 4)' }}
                center={[this.state.lng, this.state.lat]}
                ref={map => (this.map = map)}
                style="mapbox://styles/mapbox/streets-v9"
                zoom={[this.state.zoom]}
              >
                <Source id="map-data-places-labels" geoJsonSource={{ type: 'geojson', data: labelData }} />
                <Source id="map-data-places-circles" geoJsonSource={{ type: 'geojson', data: circleData }} />
                <Layer
                  sourceId="map-data-places-circles"
                  type="circle"
                  paint={{ 'circle-radius': 14, 'circle-color': { type: 'identity', property: 'circle-color' } }}
                />
                <Layer
                  sourceId="map-data-places-labels"
                  type="symbol"
                  layout={{ 'text-field': '{title}', 'text-font': ['Arial Unicode MS Bold'], 'text-size': 15 }}
                  paint={{ 'text-color': '#ffffff' }}
                />
              </Map>
              <StyledMatchArea>
                {document.addEventListener('keydown', this.handleKeyDown)}
                <TitleContainer>
                  <StyledMatchTitle>Match Review</StyledMatchTitle>

                  <StyledAlertBox>
                    <strong>Directionality matters.</strong> Drag lower-value places <strong>into</strong> higher-value
                    place.
                  </StyledAlertBox>
                </TitleContainer>
                <MatchActions confirmAllMatches={() => this.confirmAllMatches()} />
                {/* Target Canonical */}
                {this.state.isLoading && <Loading />}
                {this.state.target && this.state.target?.places && this.state.target?.places.length > 0 && (
                  <Canonical
                    {...commonCanonicalProps}
                    canonical={this.state.target}
                    newCanonical={this.state.newCanonical}
                    type="target"
                    target={true}
                    places={this.state.target?.places}
                    id={this.state.id}
                    onToggleCollapse={e => this.toggleCollapse('target')}
                    nearMatchItems={this.state.target?.near_match_items}
                    status={this.state.target?.confirmed ? 'confirmed' : 'pending'}
                    matchSetCount={this.state.target?.reviewing_match_set_count}
                    itemType={this.state.matchType}
                  />
                )}
                {/* New Canonical */}
                {this.state.newCanonical && this.state.newCanonical.id && this.state.newCanonical.places && (
                  <Canonical
                    {...commonCanonicalProps}
                    type="new"
                    target={false}
                    canonical={this.state.newCanonical}
                    places={this.state.newCanonical.places}
                    id={this.state.newCanonical.id}
                    onToggleCollapse={e => this.toggleCollapse('newCanonical')}
                    itemType={this.state.matchType}
                  />
                )}
                {/* Child match sets */}
                <div>{childMatchSetsCanonicals}</div>
                {/* Match footer*/}
                <StyledMatchFooter>
                  <ContinueActions
                    skipOrReturn={() => this.proceedOrReturn(target)}
                    next_reviewing_match_set_id={target.next_reviewing_match_set_id}
                  />
                  <br />
                  <small
                    className="pending-results">{
                      target.next_reviewing_match_set_id ?
                        `Next set: ${target.next_reviewing_match_set_id}`
                        :
                        `No results pending`}
                  </small>
                </StyledMatchFooter>
              </StyledMatchArea>
            </div>
          </DndProvider>
        );
      }
    }
  }
}
