import React from "react";
import * as hooks from "./hooks";

type Props = {
  children?: any;
};

export function EXTRAORDINARY_PLACES({children}: Props) {
  const [enabled] = hooks.useFlag("extraordinary-places", false);
  return <>{enabled ? children : null}</>;
}

export function CULTURAL_DETAILS({children}: Props) {
  const [enabled] = hooks.useFlag("cultural-details", false);
  return <>{enabled ? children : null}</>;
}

export function COMPONENT_EXPLORER({children}: Props) {
  const [enabled] = hooks.useFlag("component-explorer", false);
  // @ts-ignore
  return (
    <>
      {enabled ? (
        children
      ) : (
        <div className="card p-5">Hmm... something went wrong</div>
      )}
    </>
  );
}

export function CHAINS({children}: Props) {
  const [enabled] = hooks.useFlag("chains", false);
  return <>{enabled ? children : null}</>;
}

export function CATEGORIES({children}: Props) {
  const [enabled] = hooks.useFlag("categories", false);
  return <>{enabled ? children : null}</>;
}

export function DETAILS_TAB({children}: Props) {
  const [enabled] = hooks.useFlag("details-tab", false);
  return <>{enabled ? children : null}</>;
}

export function RESTROOM_DETAILS({children}: Props) {
  const [enabled] = hooks.useFlag("restroom-details", false);
  return <>{enabled ? children : null}</>;
}

export function BOOKING_URL({children}: Props) {
  const [enabled] = hooks.useFlag("booking-url", false);
  return <>{enabled ? children : null}</>;
}

export function CATEGORIES_FILTER({children}: Props) {
  const [enabled] = hooks.useFlag("categories-filter", false);
  return <>{enabled ? children : null}</>;
}

// show enable / disable button in listing/:id side nav
export function ENABLE_PLACE({children}: Props) {
  const [enabled] = hooks.useFlag("enable-place", false);
  return <>{enabled ? children : null}</>;
}
