import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

class YouTubeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { videoUrl: '', errorMessage: '' };
    this.handleChange = this.handleChange.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.parseQueryString = this.parseQueryString.bind(this);
    this.validateVideoUrl = this.validateVideoUrl.bind(this);
    this.setFocus = this.setFocus.bind(this);
  }

  handleChange(e) {
    this.setState({ videoUrl: e.target.value, errorMessage: '' });
  }

  render() {
    return (
      <div>
        <Modal
          id="add-youtube-video-modal"
          onShow={this.setFocus}
          onHide={this.resetFields}
          modalClass="modal-dialog-centered"
        >
          <span className="header-text">Add a YouTube Video</span>

          {this.state.errorMessage && <div className="mt-2 alert alert-danger">{this.state.errorMessage}</div>}

          <label className={this.state.errorMessage ? 'mt-2' : 'mt-4'} htmlFor="youtube-url">
            The video&apos;s full URL
          </label>
          <input
            name="youtube-url"
            type="url"
            className="form-control youtube-url"
            placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            value={this.state.videoUrl}
            onChange={this.handleChange}
            required={true}
            ref={el => (this.inputField = el)}
          />

          <div className="modal-footer">
            <button
              className="editor__button editor__button__primary mr-2 diff-diff-label"
              id="diff-diff-data-button"
              type="button"
              onClick={this.validateVideoUrl}
            >
              Add Video
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  parseQueryString() {
    var params = {};
    if (this.state.videoUrl && this.state.videoUrl.toLowerCase().match(/(http(s)?:\/\/)?(www.)?youtube.com/)) {
      this.state.videoUrl
        .split('?')[1]
        .split('&')
        .forEach(function(kvp) {
          let tmp = kvp.split('=');
          params[tmp[0]] = decodeURIComponent(tmp[1]);
        });
    }
    return params;
  }

  validateVideoUrl() {
    let that = this;
    const videoId = this.parseQueryString()['v'];

    if (videoId) {
      let url =
        'https://www.googleapis.com/youtube/v3/videos?id=' +
        videoId +
        '&key=' +
        that.props.youtubeApiKey +
        '&part=snippet';
      $.ajax({
        url: url,
        success: function(data) {
          if (data.items && data.items.length > 0) {
            that.addVideo(that.state.videoUrl, data.items[0].snippet.thumbnails.default.url);
          } else {
            that.setState({ errorMessage: "We can't find a YouTube video at that URL" });
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          console.error(errorThrown);
          console.error(jqXHR);
        }
      });
    } else {
      this.setState({ errorMessage: "We can't find a YouTube video at that URL" });
    }
  }

  addVideo(videoUrl, thumbnailUrl) {
    var that = this;
    let dataObject = {
      [that.props.targetKey]: {
        url: videoUrl,
        frame_image_url: thumbnailUrl,
        [that.props.ownerKey]: that.props.ownerId
      }
    };
    $.ajax({
      url: that.props.apiUrl,
      credentials: 'same-origin',
      method: 'POST',
      data: JSON.stringify(dataObject),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      success: function(data) {
        data['video_url'] = data['url'];
        that.props.addVideo(data);
        $('#add-youtube-video-modal').modal('hide');
      },
      error: function(jqXHR, _textStatus, _errorThrown) {
        let data = JSON.parse(jqXHR.responseText);
        if (data.errors && data.errors.url && data.errors.url[0].indexOf('index_place_videos_on_place_id_and_url') !== -1) {
          that.setState({errorMessage: 'This video has already been added for this place.'});
        }
      }
    });
  }

  resetFields() {
    this.setState({ videoUrl: '', errorMessage: '' });
  }

  setFocus() {
    this.inputField && this.inputField.focus();
  }
}

YouTubeModal.propTypes = {
  addVideo: PropTypes.func.isRequired
};

export default YouTubeModal;
