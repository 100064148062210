import React from "react";
import YelpLogo from "@/listing/header/components/yelp-logo";
import { RoadtrippersLogo } from "@/listing/header/components/rt-logo";


const brandedAttributionThumbnail = (props, thumbnail) => {
  return props.branded_attribution == 'yelp' && (
    <>
      {!props.brandedAttributionUrl ? (
        <div className="editor__place-summary__branded-attribution__logo red">
          <YelpLogo fill="white" width={45} height={15} />
        </div>
      ) : (
        <a href={props.brandedAttributionUrl}>
          <div className="editor__place-summary__branded-attribution__logo red">
            <YelpLogo fill="white" width={45} height={15} />
          </div>
        </a>
      )}
      <div className="editor__place-summary__branded-attribution__frame red">
        {thumbnail}
      </div>
    </>
  )
}

const extraordinaryPlaceThumbnail = (thumbnail) => {
  return (
    <>
      <div className="editor__place-summary__branded-attribution__logo green">
        <RoadtrippersLogo fill="white" width={25} height={15} />
      </div>
      <div className="editor__place-summary__branded-attribution__frame green">
        {thumbnail}
      </div>
    </>)
}

const processedThumbnail = (props, thumbnail) => {
  if (props.branded_attribution) {
    return brandedAttributionThumbnail(props, thumbnail);
  } else {
    if (props.road_trip_details) {
        return props.road_trip_details.map_illustration && extraordinaryPlaceThumbnail(thumbnail);
    }
  }
  return (
    <div className="editor__place-summary__branded-attribution__frame">
      {thumbnail}
    </div>
  )
}

export const Thumbnail = (props) => {

  const thumbnail = props.children;

  return (
    <div className="editor__place-summary__item editor__place-summary__thumbnail">
      {processedThumbnail(props, thumbnail)}
    </div>
  )
}