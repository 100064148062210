import * as React from 'react';
import LoadableVisibility from 'react-loadable-visibility/react-loadable';

const LoadableComponent = LoadableVisibility({
  loader: () => import('./component'),
  loading: () => <div data-cy="user-avatar" className="avatar clickable" data-toggle="dropdown" />,
  delay: 2000
});

export default class Container extends React.Component {
  render() {
    return <LoadableComponent {...this.props} />;
  }
}
