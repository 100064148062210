import React from "react";
import {AuditQueueFilter, AuditsFilter} from "@library/filters/Audits";
import Checkbox from "@library/filters/CheckboxFilter";
import TextFieldFilter from "@library/filters/textfield";
import Range from "@/library/filters/range";
import Regions from "@library/filters/region";
import Select from "@library/filters/SelectFilter";
import TreeSelect from "@library/filters/tree-select";
import ErrorBoundary from "@library/ErrorBoundary";
import type {ApplyFilter, RemoveFilter} from "@library/filters/types";

export type FilterTypes =
  | "select"
  | "tree-select"
  | "audit-queue"
  | "audits"
  | "checkbox"
  | "textfield"
  | "regions"
  | "range";

export type Props = {
  applyFilter: ApplyFilter;
  removeFilter: RemoveFilter,
  type: FilterTypes;
  name?: string;
  cy?: string;
  label: string;
  showSelection?: boolean;
  featureFlag?: string;
  filter?: string;
  data?: string[];
  props: any;
};

function FilterFactory({
  applyFilter,
  removeFilter,
  type,
  name,
  label,
  cy,
  showSelection,
  featureFlag,
  filter,
  data,
  ...props
}: Props): React.ReactElement | null {

  const filters = {
    select: Select,
    "tree-select": TreeSelect,
    "audit-queue": AuditQueueFilter,
    audits: AuditsFilter,
    checkbox: Checkbox,
    textfield: TextFieldFilter,
    regions: Regions,
    range: Range,
  };

  if (!filters[type]) {
    console.error("ERROR: Unknown filter type: " + type);
    return null;
  }

  const Component = filters[type];

  return (
    <ErrorBoundary>
      <Component
        {...props}
        cy={cy}
        filter={filter}
        featureFlag={featureFlag}
        label={label}
        showSelection={showSelection}
        applyFilter={applyFilter}
        removeFilter={removeFilter}
        data={data}
        name={name}
      />
    </ErrorBoundary>
  );
}

export default FilterFactory;
