import React, { useState, useEffect, ReactNode} from 'react';
import Modal from '@/library/Modal.js';
import { AssignAuditData, CanonicalPlace, FullResultsInfo, User } from '../../common/types';
import { AuditListing } from './AuditListing';
import './styles';
import { UsersList } from './UsersList';

interface Props {
  auditUser?: any;
  onSave: Function;
  assignAuditData: AssignAuditData;
  toggle: Function;
  show: boolean;
  onUserClick?: Function;
  selectedUser?: User;
  selectedListings?: Array<CanonicalPlace>;
  FullResultsInfo?: FullResultsInfo;
}

export const AssignAudit = ({ assignAuditData, ...props }: Props) => {
  const maxAssignments = 1000;
  const [isBulkAssignAudits, setIsBulkAssignAudits] = useState(false);
  const [resultsExceeded, setResultsExceeded] = useState(false);

  const handleUserOnClick = (user: User) => {
    props.onUserClick(user);
  };

  const handleOnSave = () => {
    props.onSave();
    isBulkAssignAudits && props.toggle(false);
  };

  useEffect(
    () => {
      { !assignAuditData.listings && setIsBulkAssignAudits(true) }
    }, []
  );

  useEffect(
    () => {
      props.FullResultsInfo &&
      setResultsExceeded(props.FullResultsInfo.resultsTotal > maxAssignments)
    }, [props.FullResultsInfo]
  );

  return (
    <>
      <Modal
        show={props.show}
        toggle={props.toggle}
      >
        <div className="modal-body">
          <div className="assign-audit">
            <div className="assign-audit-header">
              {!assignAuditData.listings ? 'Bulk Assign Audits' : 'Assign Audit'}
            </div>
            <div className="assign-audit-body">
              {assignAuditData ?
                <>
                  <div className="audit-list">
                    <div className="audit-list-header">{assignAuditData.listings && 'Listing'}</div>
                    <div className="audit-list-body">
                      <div className="listings-content">
                        {!assignAuditData.listings ?
                          props.FullResultsInfo &&
                          <div className="__bulk-assign-view">
                            <div className="__header">Bulk assign audit to all search results?</div>
                            <div className="__body">
                              <div className="__field">
                                <div className="label"><small>Results Total</small></div><div className={`__result--big${props.FullResultsInfo.resultsTotal > maxAssignments ? ' --red' : ' --green'}`}>
                                {props.FullResultsInfo.resultsTotal.toLocaleString() + '/' + maxAssignments.toLocaleString()}</div>
                                {props.FullResultsInfo.resultsTotal > maxAssignments &&
                                  <small>Note: Your results exceed the bulk assign allotment. Please refine your search.</small>
                                }
                              </div>
                            </div>
                          </div>
                          :
                          <AuditListing
                            listing={assignAuditData.listings[0]}
                          />}
                      </div>
                    </div>
                  </div>
                  <div className="audit-list users">
                    <div className="audit-list-header">Users</div>
                    <div className="audit-list-body">
                      <UsersList
                        users={assignAuditData.users}
                        selectedUser={props.selectedUser}
                        handleUserOnClick={handleUserOnClick}
                      />
                    </div>
                  </div>
                </> : <>
                  No audit data available.
                </>}
            </div>

          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={handleOnSave} disabled={props.selectedUser === undefined || resultsExceeded}>
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}
