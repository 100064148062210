import React from 'react';
import { useState, useEffect } from 'react';
import PlaceAutocomplete from '@/PlaceAutocomplete';
import Suggestion from "./suggestion";


export default function Suggestions(props) {
  const [searchedName, setSearchedName] = useState('');

  const handleSetRelative = (suggestedPlace: any) => {
    props.setRelative(suggestedPlace);
  }

  return (
    <>
      <input
        data-cy="create-listing-search-input"
        type="text"
        className="editor__input"
        id="relativeName"
        placeholder="Search for Relative Place"
        value={searchedName}
        autoComplete="false"
        onChange={e => setSearchedName(e.target.value)}
      />

      <PlaceAutocomplete
        query={searchedName}
        onResults={() => null}

        SuggestionRow={(suggestedPlace) => {
          return (<Suggestion
            onClick={() => handleSetRelative(suggestedPlace)}
            {...suggestedPlace}
          />);
        }}
      />
    </>
  );
}