import React from "react";
import Label from "@library/label";
import True from "./symbols/true";
import Checkbox from "./checkbox";
import "@library/boolean-row/styles/index";

function Binary(props: {
  disabled?: boolean;
  value: boolean | null;
  label: any;
  description?: string;
  id?: string;
  onChange?: (value: boolean | null) => void;
}) {
  const falsy = props.value === false;
  const truthy = props.value === true;
  const neither = !falsy && !truthy;

  return (
    <div
      onClick={() => {
        if (props.disabled) { return; }
        if (truthy) {
          props.onChange(false);
        } else {
          props.onChange(true);
        }
      }}
      data-cy="boolean-row-binary"
      className="editor__boolean-row editor__boolean-row--edit"
    >
      <Label
        cy="boolean-row-label"
        description={props.description}
        id={props.id}
        text={props.label}
      />
      <div
        className="editor__boolean-row__controls"
        data-cy="boolean-row-controls"
      >
        {(falsy || neither) && (
          // don't show red x unless using ternary
          <Checkbox state="null" disabled={props.disabled} />
        )}

        {truthy && (
          <Checkbox state="true" disabled={props.disabled}>
            <True />
          </Checkbox>
        )}
      </div>
    </div>
  );
}

export default Binary;
