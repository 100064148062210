import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export default class Switch extends PureComponent {
  onClick(e, value) {
    e.preventDefault();
    this.props.onChange(value);
  }
  render() {
    const { value, switchLabel, leftValue, leftLabel, rightValue, rightLabel } = this.props;

    // Switch is only shown if value is undefined, "", or null
    return !value ? (
      <div className="boone-switch">
        <div className="boone-switch__label">{switchLabel}</div>
        <div className="boone-switch__controls">
          <SwitchControl
            cy={this.props.leftCy || 'left-control'}
            side="left"
            label={leftLabel}
            controlValue={leftValue}
            currentValue={value}
            onClick={e => this.onClick(e, leftValue)}
          />
          <SwitchControl
            cy={this.props.rightCy || 'right-control'}
            side="right"
            label={rightLabel}
            controlValue={rightValue}
            currentValue={value}
            onClick={e => this.onClick(e, rightValue)}
          />
        </div>
      </div>
    ) : null;
  }
}

Switch.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  switchLabel: PropTypes.string,
  leftValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftLabel: PropTypes.string,
  rightValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightLabel: PropTypes.string,
  leftCy: PropTypes.string,
  rightCy: PropTypes.string
};

const SwitchControl = ({ side, label, currentValue, controlValue, onClick, cy }) => {
  const selected = currentValue === controlValue;
  return (
    <button
      data-cy={cy}
      aria-selected={selected}
      aria-valuetext={label}
      // Human readable className example - `boone-switch__controls__right--selected` or `boone-switch__controls__right`
      className={cn({
        ['boone-switch__controls__' + side]: !selected,
        ['boone-switch__controls__' + side + '--selected']: selected
      })}
      onClick={e => onClick(e, controlValue)}
    >
      {label}
    </button>
  );
};

SwitchControl.propTypes = {
  side: PropTypes.oneOf(['right', 'left']),
  label: PropTypes.string,
  currentValue: PropTypes.string,
  controlValue: PropTypes.string,
  onClick: PropTypes.func,
  cy: PropTypes.string
};
