import React from 'react';
import { CanonicalPlace } from '../../common/types';

export const ListingDetails = ({ ...props }: CanonicalPlace) => {

  const formattedDate = (stringDate) => {
    const date = new Date(stringDate);
    return date.toLocaleDateString();
  };

  return (
    <div className="details">
      <div className="details-row">

      </div>
      <div className="details-row">
          <ul>
            <li>
              <span className="label">ID </span><span className="value">{props?.id}</span>
            </li>
            <li>
              <span className="label">Fidelity </span><span className="value">{props?.fidelity_level}</span>
            </li>
            <li>
              <span className="label">Places </span><span className="value">{props.attribution.length}</span>
            </li>
            <li>
              <span className="label">Travel Rank </span><span className="value">{props?.ranking_score}</span>
            </li>
            <li>
              <span className="label">Avg Rating </span><span className="value">{props?.engagement?.avg_rating}</span>
            </li>
            <li>
              <span className="label">Ratings </span><span className="value">{props?.engagement?.rating_count.toLocaleString()}</span>
            </li>
            <li>
              <span className="label">Visits </span><span className="value">{props?.engagement?.visits?.toLocaleString()}</span>
            </li>
            <li>
              <span className="label">Updated </span><span className="value">{formattedDate(props.last_updated)}</span>
            </li>
          </ul>
      </div>
    </div>
  )
}
