import React from 'react';
import useIndex from '../../hooks/useIndex';

type Props = {
  tags: { public_id: string; name: string }[];
  userEditingStrategy: UserEditingStrategy;
  data: any;
};

function TagAttributes(props: Props) {
  const [saved, setSaved] = React.useState(props.tags || []);
  const [pending, setPending] = React.useState(
    saved ? saved.map((node) => node.public_id) : []
  );
  const index = useIndex(props.data, 'public_id', { clearChildren: true });

  const sortTags = (tags) =>
    tags
      ? tags.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      : [];
  const sortedPendingTags = () =>
    sortTags(pending.map((id) => index.getNode(id)));

  return (
    <>
      <h4>Tags</h4>
      <i>Tags are derived from categories</i>
      <div className="editor__well tags-list mt-2">

        {saved && saved.length > 0 ? (
          sortTags(saved).map((tag) => (
            <div key={tag.public_id} className="pb-3">
              <div className="col-sm-12">{tag.name}</div>
            </div>
          ))
        ) : (
            <p className="text-muted m-0">No tags set for this listing</p>
          )}
      </div>
    </>
  );
}

export default TagAttributes;
