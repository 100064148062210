import React from 'react';
import * as ajax from '../helpers/ajax';

type Props = {
  form?: string;
  base64Auth?: string;
}

function useWufooApi(props: Props) {

  props.form = 'xsx3rgj0jk1shb';
  props.base64Auth = "O2IMHRU0g78GhqGxnadZlvAy0m2G0A7MwQzFX8BMahU=";

  const [form] = React.useState(props.form);

  function setHeaders(){
    return {
      Authorization: "Basic " + props.base64Auth
    }
  }

  function authenticate() {
    return ajax._fetch(
      `https://${form}.wufoo.com/api/v3/forms.json`,
      {
        method: "GET",
        headers: setHeaders()
    });
  }

  function post(body) {
    return ajax._fetch(
      `https://${form}.wufoo.com/api/v3/forms.json`,
      {
        method: 'POST',
        headers: setHeaders(),
        body: JSON.stringify([body])
    });
  }


  return {
    authenticate,
    post
  }
}

export default useWufooApi;
