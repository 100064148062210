import * as React from "react";
import Hover from "../Hover";
import {
  IconUploadMedia,
  IconUploadMediaHover,
} from "../../icons/IconUploadMedia";

type Props = {
  onUpload: () => void;
  className: string;
};

export default class Upload extends React.PureComponent<Props> {
  render() {
    return (
      <div
        id="upload-image"
        onClick={() => this.props.onUpload()}
        className={this.props.className + " bg-light rounded"}
      >
        <div className="">
          <div className="overlay-icon">
            <Hover
              hoverContents={
                <IconUploadMediaHover viewBoxX="-6" viewBoxY="-5" />
              }
            >
              <IconUploadMedia viewBoxX="-6" viewBoxY="-5" />
            </Hover>
          </div>
        </div>
      </div>
    );
  }
}
