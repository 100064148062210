import dataExists from '../dataExists.js';

describe('(are all) object properties set helper', () => {
  it('returns true when all properties are strings with length > 0', () => {
    const object = { a: 'a', b: 'b' };
    expect(dataExists(object)).toBe(true);
  });

  it('returns true when all properties are set to empty strings', () => {
    const object = { a: '', b: '' };
    expect(dataExists(object)).toBe(true);
  });

  it('returns false when all properties are set to null', () => {
    const object = { a: null, b: null };
    expect(dataExists(object)).toBe(false);
  });

  it('returns false when all properties are undefined', () => {
    const object = { a: undefined, b: undefined };
    expect(dataExists(object)).toBe(false);
  });

  it('returns true when one property is a string and the other is null', () => {
    const object = { a: 'a', b: null };
    expect(dataExists(object)).toBe(true);
  });

  it('returns true when one property is an empty string and the other is null', () => {
    const object = { a: '', b: null };
    expect(dataExists(object)).toBe(true);
  });

  it('returns true when one property is an empty string and the other is undefined', () => {
    const object = { a: '', b: undefined };
    expect(dataExists(object)).toBe(true);
  });

  // Considers empty arrays and objects to be data set for a property
  it('returns true when one property is a string and the other is an empty array', () => {
    const object = { a: 'str', b: [] };
    expect(dataExists(object)).toBe(true);
  });

  it('returns true when one property is a string and the other is an empty object', () => {
    const object = { a: 'str', b: {} };
    expect(dataExists(object)).toBe(true);
  });
});
