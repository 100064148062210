import React from 'react';
import InputWithLabel from '../../library/input-with-label';
import ViewField from '../../library/view-field';
import DataSection from '../../library/data-section';
import InputGroupEditor from '../../library/group-editor/InputGroupEditor';
import MultiSelect from '../../library/multi-select';
import * as flags from '../../library/launch-darkly/flags';

type Props = {
  name: string;
  altNames?: string[];
  chains?: Chain[];
  chainSuggestions: Chain[];
  formerNames?: string[];
  userEditingStrategy: UserEditingStrategy;
};

const IdentityInfo = function (props: Props) {
  const suggestions = props.chainSuggestions.reduce((p, c) => {
    p[c.id] = c;
    return p;
  }, {});

  const [altNames, setAltNames] = React.useState(props.altNames);
  const [formerNames, setFormerNames] = React.useState(props.formerNames);
  const [name, setName] = React.useState(props.name);
  const [chains, setChains] = React.useState(props.chains);
  const [savedState, setSavedState] = React.useState({ altNames, name, chains, formerNames });

  return (
    <DataSection
      sectionTitle='Identity'
      cyView="listing-identity-view"
      cyEdit="listing-identity-edit"
      userEditingStrategy={props.userEditingStrategy}
      reset={() => {
        setAltNames(savedState.altNames);
        setName(savedState.name);
        setChains(savedState.chains);
        setFormerNames(savedState.formerNames);
      }}
      params={{
        listing: {
          name,
          alt_names: altNames.filter(a => a),
          former_names: formerNames.filter(f => f),
          // expects array of public ids
          chains: chains ? chains.map(c => c.public_id) : []
        }
      }}
      onSuccess={() => setSavedState({ name, altNames, formerNames, chains })}
      viewComponent={
        <>
          <ViewField cy='view-place-name' label="Name" value={savedState.name} />
          {savedState.altNames && (
            <ViewField label="Alternate names" value={savedState.altNames.filter(a => a).join(', ')} />
          )}
          {savedState.formerNames && (
            <ViewField label="Former names" value={savedState.formerNames.filter(f => f).join(', ')} />
          )}
          <flags.CHAINS>
            {savedState.chains && (
              <ViewField
                label="Chain"
                value={savedState.chains
                  .filter(c => c)
                  .map(c => c.name)
                  .join(', ')}
              />
            )}
          </flags.CHAINS>
        </>
      }
      editComponent={
        <>
          <InputWithLabel
            value={name}
            onChange={e => setName(e.target.value)}
            label="Name"
            cy='edit-place-name'
            labelClassName="editor__label"
          />
          <span data-cy="alt-name">
            <InputGroupEditor
              allowNone={false}
              values={altNames}
              labelClassName="editor__label"
              getChanges={v => setAltNames(v)}
              label={'Alternate name'}
            />
          </span>
          <span data-cy="former-names">
            <InputGroupEditor
              allowNone={false}
              values={formerNames}
              getChanges={v => setFormerNames(v)}
              label={'Former name'}
            />
          </span>

          <flags.CHAINS>
            <MultiSelect
              cy="edit-chain"
              placeholder=""
              label="Chain"
              labelId="chain"
              selected={chains.map(c => c.id) || []}
              options={props.chainSuggestions}
              allowNewValues={false}
              persistChanges={n => setChains(n && n.map(id => suggestions[id]))}
            />
          </flags.CHAINS>
        </>
      }
    />
  );
};

IdentityInfo.defaultProps = {
  formerNames: [],
  altNames: [],
  name: ''
};

export default IdentityInfo;
