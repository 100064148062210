import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
//import Tooltip from '@material-ui/core/Tooltip';

import styled from 'styled-components';

const StyledMatchActions = styled.div`
  margin-top: 8px;
  padding: 8px 0px 8px 0px;

  a {
    text-decoration: none;
  }
`;

const MatchActions = props => {
  return (
    <StyledMatchActions style={{ height: '64px' }}>
      {/*<a href="/match">*/}
      {/*<Tooltip title="Back to List">*/}
      {/*<IconButton>*/}
      {/*<Icon>arrow_back</Icon>*/}
      {/*</IconButton>*/}
      {/*</Tooltip>*/}
      {/*</a>*/}
      <Button
        onClick={props.confirmAllMatches}
        color="primary"
        style={{
          float: 'right'
        }}
      >
        <Icon>done_all</Icon>
        Confirm All
      </Button>
    </StyledMatchActions>
  );
};

MatchActions.propTypes = {
  confirmAllMatches: PropTypes.func
};

export default MatchActions;
