import React from "react";
import Form from "../_form";
import PostSubmit from "@/data-outreach/orvp/forms/_form/components/post-submit";
import FormStep from "@/data-outreach/orvp/forms/_form/components/form";
import useSteps from "./useSteps";

function FormV1() {
  const steps = useSteps();
  return (
    <Form steps={steps} index={0}>
      <FormStep />
      <PostSubmit />
    </Form>
  );
}

export default FormV1;
