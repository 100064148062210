import * as React from "react";
import * as ajax from "@/helpers/ajax";

type Props = {
  featured: boolean;
  url: string;
  targetKey: string;
  featuredMediaChanged: (featured: boolean) => void;
  className?: string;
  flagged?: boolean;
};

function Feature(props: Props) {
  const [feature, setFeatured] = React.useState(props.featured);

  React.useEffect(() => {
    if (feature !== props.featured) {
      props.featuredMediaChanged(feature);
    }
  }, [feature]);

  React.useEffect(() => {
    if (feature !== props.featured) {
      ajax.patch(props.url, {[props.targetKey]: {featured: feature}});
    }
  }, [feature]);

  return (
    <div
      className={props.className || "image-badge feature-image-badge"}
      onClick={(e) => {
        if (!props.flagged || props.flagged === undefined) {
          e.stopPropagation();
          setFeatured(!feature);
        }
      }}
      data-featured={feature}
    >
      {feature ? (
        <span data-cy="unfeature">Unfeature</span>
      ) : (
        <span data-cy="feature">Feature</span>
      )}
    </div>
  );
}

export default Feature;
