import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

const IconCollapse = (props) => (
  <SinglePathIcon
    width="28"
    height="28"
    fillColor={props.fillColor}
    path="M13.25,4.19565217 C13.25,3.82463768 13.575,3.5 14,3.5 C14.4,3.5 14.75,3.80144928 14.75,4.19565217 L14.75,9.1115942 L16.7,7.30289855 C17,7.02463768 17.475,7.02463768 17.775,7.30289855 C17.925,7.41884058 18,7.60434783 18,7.78985507 C18,7.95217391 17.925,8.13768116 17.775,8.27681159 L14.525,11.2913043 C14.4,11.4304348 14.2,11.5 14,11.5 C13.8,11.5 13.625,11.4304348 13.475,11.2913043 L10.225,8.27681159 C9.925,7.99855072 9.925,7.55797101 10.225,7.27971014 C10.525,7.00144928 11,7.00144928 11.3,7.27971014 L13.25,9.0884058 L13.25,4.19565217 Z M9,13.75 C9,13.325 9.39215686,13 9.86834734,13 L18.1596639,13 C18.6078431,13 19,13.325 19,13.75 C19,14.15 18.6358543,14.5 18.1596639,14.5 L9.84033613,14.5 C9.39215686,14.5 9,14.175 9,13.75 Z M10.225,19.2093023 L13.475,16.2093023 C13.6,16.0697674 13.8,16 14,16 C14.2,16 14.375,16.0697674 14.525,16.2093023 L17.775,19.2325581 C17.925,19.3488372 18,19.5348837 18,19.7209302 C18,19.8837209 17.925,20.0697674 17.775,20.2093023 C17.475,20.4883721 17,20.4883721 16.7,20.2093023 L14.75,18.3953488 L14.75,23.3023256 C14.75,23.6744186 14.425,24 14,24 C13.6,24 13.25,23.6976744 13.25,23.3023256 L13.25,18.3953488 L11.3,20.2093023 C11,20.4883721 10.525,20.4883721 10.225,20.2093023 C9.925,19.9302326 9.925,19.4883721 10.225,19.2093023 Z"
  />
);

export default IconCollapse;
