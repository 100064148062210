import React from 'react';
import PropTypes from 'prop-types';
import deepClone from '../helpers/DeepClone';
import * as ajax from '@/helpers/ajax';
import { IconClose } from '../icons/IconClose';

class MediaModalView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      editMedia: deepClone(nextProps.media)
    });
  }

  getInitialState = () => {
    var state = {
      editMedia: deepClone(this.props.media)
    };
    return state;
  };

  handleChange = e => {
    const editMedia = deepClone(this.state.editMedia);
    editMedia[e.target.id] = e.target.value;
    this.setState({ editMedia });
  };

  handleSave = () => {
    this.setState({ saving: true });
    let updatedAttributes = {
      caption: this.state.editMedia.caption,
      attribution_name: this.state.editMedia.attribution_name,
      attribution_url: this.state.editMedia.attribution_url
    };
    ajax
      .patch(this.props.apiUrl + this.props.media.id, {
        [this.props.targetKey]: updatedAttributes
      })
      .then(() => {
        if (this.props.onSave) {
          this.props.onSave(this.props.media.id, updatedAttributes);
        }
        $(this.node).modal('hide');
      });
  };

  renderImageForm() {
    const showDelete = this.props.isEditable && !(this.state.editMedia.flags && this.state.editMedia.flags.length > 0);

    return (
      <div className="container">
        {this.renderEditField('Caption', 'caption', 'Short tag describing the image')}
        {this.renderEditField('Attribution name', 'attribution_name', 'Name of the image source')}
        {this.renderEditField('Attribution URL', 'attribution_url', 'URL where the image was found')}
        <div className="row mt-4 small text-muted">
          <b>Reminder from Larry the lawyer:</b>
          <ul>
            <li>Only use images from which you have permission</li>
            <li>Only cover and profile photos from Facebook</li>
            <li>Be a good citizen of the interwebz</li>
          </ul>
        </div>
        <div className="row mt-3 mb-5">
          <div className="col-sm-6">
            {showDelete && (
              <button className="btn text-danger" onClick={this.handleDelete}>
                Delete
              </button>
            )}
          </div>
          <div className="col-sm-6 text-right">
            <button
              className="editor__button editor__button__primary"
              disabled={!this.props.isEditable}
              onClick={this.handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderEditField(label, field, placeholder) {
    let value = this.state.editMedia[field] || '';
    return (
      <div>
        <div className="row pr-3 mt-3">
          <label htmlFor={field} className="text-sm-right col-form-label">
            {label}
          </label>
        </div>
        <div className="row py-0 my-0">
          <input
            type={'text'}
            className="form-control"
            id={field}
            value={value}
            onChange={this.handleChange}
            readOnly={!this.props.isEditable}
            placeholder={placeholder}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div ref={node => (this.node = node)} className="modal fade" id="media-modal" role="dialog">
        <div className="modal-dialog-centered modal-dialog modal-lg" role="document">
          <div className="modal-content" style={{ overflow: 'hidden' }}>
            <div className="modal-body p-0">
              <div className="container">
                <div className="row">
                  <div className="col-sm-8 p-0">
                    <img className="zoomed-image" src={this.props.media.image_url} />
                  </div>
                  <div className="col-sm-4">{this.renderImageForm()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="btn close-modal-button" role="button" data-dismiss="modal">
          <IconClose />
        </span>
      </div>
    );
  }

  handleDelete = () => {
    $(this.node).modal('hide');
    this.props.onDelete(this.props.media.id);
  };
}

MediaModalView.propTypes = {
  media: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image_url: PropTypes.string.isRequired
  }).isRequired,
  apiUrl: PropTypes.string.isRequired,
  targetKey: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired
};

export default MediaModalView;
