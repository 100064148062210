const policy = (orgUser: boolean, internalUser: boolean, superAdminUser: boolean) => {
  return {
    canViewMedia: {
      fromOwner: true,
      fromOrg: true,
      fromOthers: true
    },
    canFlagMedia: {
      fromOwner: internalUser || superAdminUser,
      fromOrg: internalUser || superAdminUser,
      fromOthers: internalUser || superAdminUser
    },
    canDisableMedia: {
      fromOwner: internalUser || superAdminUser,
      fromOrg: true,
      fromOthers: internalUser || superAdminUser
    },
    canFeatureMedia: {
      fromOwner: internalUser || superAdminUser,
      fromOrg: orgUser || internalUser || superAdminUser,
      fromOthers: orgUser || internalUser || superAdminUser
    }
  };
};

export default policy;
