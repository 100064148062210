import * as React from 'react';
import DataSection from '../../library/data-section';
import InputGroupEditor from '../../library/group-editor/InputGroupEditor';
import ViewList from '../../library/view-list';

type Props = {
  magazineArticles?: string[];
  tripGuides?: string[];
  userEditingStrategy: 'update' | 'suggest' | 'none';
};

const InternalLinks = function(props: Props) {
  const ma = props.magazineArticles || [];
  const tg = props.tripGuides || [];

  const [magazineArticles, setMagazineArticles] = React.useState(ma);
  const [tripGuides, setTripGuides] = React.useState(tg);
  const [savedState, setSavedState] = React.useState({ magazineArticles: ma, tripGuides: tg });

  return (
    <DataSection
      sectionTitle="Internal Links"
      cyView="rt-internal-links-view"
      cyEdit="rt-internal-links-edit"
      viewComponent={
        <div className="d-flex flex-column">
          <ViewList label={'Articles'} values={magazineArticles} />
          <ViewList label={'Trip Guides'} values={tripGuides} />
        </div>
      }
      editComponent={
        <>
          <InputGroupEditor
            allowNone={false}
            cy="rt-magazine-articles-editor"
            values={magazineArticles}
            getChanges={v => setMagazineArticles(v)}
            label="Article"
          />
          <InputGroupEditor
            allowNone={false}
            cy="rt-trip-guides-editor"
            values={tripGuides}
            getChanges={v => setTripGuides(v)}
            label="Trip Guide"
          />
        </>
      }
      params={{
        listing: {
          road_trip_details: {
            magazine_articles: magazineArticles ? magazineArticles.filter(ma => ma) : [],
            trip_guides: tripGuides ? tripGuides.filter(tg => tg) : []
          }
        }
      }}
      onSuccess={() => setSavedState({ magazineArticles, tripGuides })}
      reset={() => {
        setMagazineArticles(savedState.magazineArticles);
        setTripGuides(savedState.tripGuides);
      }}
      userEditingStrategy={props.userEditingStrategy}
    />
  );
};

export default InternalLinks;
