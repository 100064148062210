import React from 'react';
import constate from 'constate';
import useWizardState from './useWizardState';

const [WizardProvider, useWizardContext] = constate(function({
  index,
  steps
}: {
  index?: number;
  steps: Steps;
}){
  return useWizardState({
    index,
    steps
  });
});

export { WizardProvider, useWizardContext };
