import 'whatwg-fetch';

function defaultHeaders() {
  const headers = { 'Content-Type': 'application/json' };

  const token = document.querySelector('meta[name="csrf-token"]');
  if (token) {
    headers['X-CSRF-Token'] = token.getAttribute('content');
  }

  return headers;
}

const params = (method) => ({
  credentials: 'same-origin',
  method,
  headers: defaultHeaders(),
});

const withPayload = (method, body) => ({
  ...params(method),
  body: JSON.stringify(body),
});

/**
 * @name _fetch
 * const [promise, abort] = ajax._fetch(url, params);
 *
 * promise
 *  .then(json => ...)
 *  .catch(error => {
 *     if (error.name === 'AbortError') {
 *       < deal with error >
 *     }
 *   });
 *
 * Call abort at anytime to cancel request
 * abort();
 */

export function _fetch(url: string, params?: any): [promise: Promise<any>, abort: any] {

  const controller = new AbortController();
  const fetchParams = {
    ...params,
    signal: controller.signal,
  };
  const promise = fetch(url, fetchParams).then((data) => data.json());
  return [promise, controller.abort.bind(controller)];
};


export function _patch(url: string, body?: any) {
  const params = withPayload('PATCH', body);
  return _fetch(url, params);
}

export function _post (url: string, body?: any)  {
  const params = withPayload('POST', body);
  return _fetch(url, params);
}

export function _put(url: string, body?: any) {
  const params = withPayload('PUT', body);
  return _fetch(url, params);
}

export function _get(url: string, body?: any)  {
  const params = withPayload('GET', body);
  return _fetch(url, params);
}

export function _delete(url: string, body?: any) {
  const params = withPayload('DELETE', body);
  return _fetch(url, params);
}

/**
 * @deprecated
 */
export function patch(url: string, body?: any)  {
  return fetch(url, {
    credentials: 'same-origin',
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: defaultHeaders(),
  });
}

export function put(url: string, body?: any)  {
  return fetch(url, {
    credentials: 'same-origin',
    method: 'PUT',
    body: JSON.stringify(body),
    headers: defaultHeaders(),
  });
}

export function post(url: string, body?: any)  {
  return fetch(url, {
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify(body),
    headers: defaultHeaders(),
  });
}

export function get(url: string) {
  return fetch(url, {
    credentials: 'same-origin',
    method: 'GET',
    headers: defaultHeaders(),
  });
}

export function destroy(url: string, body?: any) {
  return fetch(url, {
    credentials: 'same-origin',
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: defaultHeaders(),
  });
}
