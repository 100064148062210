import parseQueryString from "@/helpers/parseQueryString";

/**
 * Provide an array of keys and get an object with matching query params and
 * values or null if none are found
 */
function selectQueryParams(keys: string[]): { [param: string]: string } | null {
  // If params or the selected params are empty we return null
  const { params } = parseQueryString();

  if (params && Object.keys(params).length > 0) {
    let selectParams = keys.reduce((p, c) => {
      if (params[c]) {
        p[c] = params[c];
      }
      return p;
    }, {});

    if (Object.keys(selectParams).length > 0) {
      return selectParams;
    }
  }
  return null
}

export default selectQueryParams;
