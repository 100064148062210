import React from 'react';
import BooleanRow from '../../../../library/boolean-row/edit';
import PermanentlyClosed from './PermanentlyClosed';
import TemporaryClosures from './temporary-closures';
import * as c from '../../constants';

type Props = {
  permanentlyClosed: boolean;
  setPermanentlyClosed: (v: boolean) => void;
  hoursMayVary: boolean;
  setHoursMayVary: (v: boolean) => void;
  mayRequireAppointment: boolean;
  setMayRequireAppointment: (v: boolean) => void;
  temporaryClosures: any[];
  setTemporaryClosures: (v: { start_date: string; end_date: string; reason: string }[]) => void;
};

const Edit = function(props: Props) {
  return (
    <>
      <PermanentlyClosed
        permanentlyClosed={props.permanentlyClosed}
        setPermanentlyClosed={v => props.setPermanentlyClosed(v)}
        onConfirm={() => props.setPermanentlyClosed(true)}
        onCancel={() => props.setPermanentlyClosed(false)}
      />
      <BooleanRow
        label={c.HOURS_VARY_LABEL}
        value={props.hoursMayVary}
        onChange={v => props.setHoursMayVary(v)}
      />
      <BooleanRow
        label={c.APPOINTMENT_LABEL}
        value={props.mayRequireAppointment}
        onChange={v => props.setMayRequireAppointment(v)}
      />
      <TemporaryClosures
        setTemporaryClosures={v => props.setTemporaryClosures(v)}
        temporaryClosures={props.temporaryClosures}
      />
    </>
  );
};

export default Edit;
