import React from 'react';
import { initialize } from 'launchdarkly-js-client-sdk';
import { LaunchDarklyContext, UserContext } from './context';

type Props = {
  children?: any;
  user: { key: string; anonymous?: boolean };
  clientID: string;
};

export function Provider({ children, user, clientID }: Props) {
  const [ready, setReady] = React.useState(false)
  const [client] = React.useState(
    initialize(clientID, user)
  );

  React.useEffect(() => {
    client.on('ready', () => {
      setReady(true);
    })
  }, [])

  React.useEffect(() => {
    if (user && ready) {
      client.identify(user);
    }
  }, [user]);

  return ready ? <UserContext.Provider value={user} >
    <LaunchDarklyContext.Provider value={client}>{children}</LaunchDarklyContext.Provider>
  </UserContext.Provider > : null

}

export default Provider;
