import * as c from '../constants';
import { getCurrentSort, getNextParams, constructUrl } from '../TableHead';

describe('TableHead', () => {
  describe('get current sort', () => {
    it('returns SORT_DEFAULT when sort does not exist in path', () => {
      const params = '';
      const urlParams = new URLSearchParams(params);
      const expected = c.SORT_DEFAULT;
      const result = getCurrentSort(urlParams, 'last_updated');
      expect(result).toEqual(expected);
    });

    it('returns SORT_DEFAULT when sort exists in path, but value !== sortKey', () => {
      const params = 'sort=name';
      const urlParams = new URLSearchParams(params);
      const expected = c.SORT_DEFAULT;
      const result = getCurrentSort(urlParams, 'last_updated');
      expect(result).toEqual(expected);
    });

    it('returns SORT_ASC when sort=sortKey exists in path', () => {
      const params = 'sort=last_updated';
      const urlParams = new URLSearchParams(params);
      const expected = c.SORT_ASC;
      const result = getCurrentSort(urlParams, 'last_updated');
      expect(result).toEqual(expected);
    });

    it('returns SORT_DESC when sort=-sortKey exists in path', () => {
      const params = 'sort=-last_updated';
      const urlParams = new URLSearchParams(params);
      const expected = c.SORT_DESC;
      const result = getCurrentSort(urlParams, 'last_updated');
      expect(result).toEqual(expected);
    });
  });

  describe('get next params', () => {
    it(`returns path containing sort=sortKey when:
        - the current sort is SORT_DEFAULT`, () => {
      const params = '';
      const urlParams = new URLSearchParams(params);
      const expected = 'sort=last_updated';
      const result = getNextParams(urlParams, c.SORT_DEFAULT, 'last_updated');
      expect(result).toEqual(expected);
    });

    it(`returns path containing sort=sortKey + any pre-existing search params when:
        - the current sort is SORT_DEFAULT
        - search path has other params`, () => {
      const params = 'tag=park&tag=other';
      const urlParams = new URLSearchParams(params);
      const expected = 'tag=park&tag=other&sort=last_updated';
      const result = getNextParams(urlParams, c.SORT_DEFAULT, 'last_updated');
      expect(result).toEqual(expected);
    });

    it(`returns path containing sort=sortKey + any pre-existing search params when:
        - the current sort is SORT_DEFAULT
        - sort exists for a DIFFERENT sortKey in search path
        - search path has other params`, () => {
      const params = 'sort=name&tag=park&tag=other';
      const urlParams = new URLSearchParams(params);
      const expected = 'sort=last_updated&tag=park&tag=other';
      const result = getNextParams(urlParams, c.SORT_DEFAULT, 'last_updated');
      expect(result).toEqual(expected);
    });

    it(`returns sort=-sortKey when:
        - the current sort is SORT_ASC`, () => {
      const params = 'sort=last_updated';
      const urlParams = new URLSearchParams(params);
      const expected = 'sort=-last_updated';
      const result = getNextParams(urlParams, c.SORT_ASC, 'last_updated');
      expect(result).toEqual(expected);
    });

    it(`returns an empty string when:
        - the current sort is SORT_DESC`, () => {
      const params = 'sort=-last_updated';
      const urlParams = new URLSearchParams(params);
      const expected = '';
      const result = getNextParams(urlParams, c.SORT_DESC, 'last_updated');
      expect(result).toEqual(expected);
    });

    it(`returns all pre-existing search params when:
        - the current sort is SORT_DESC`, () => {
      const params = 'sort=-last_updated&tag=shop&region=ca';
      const urlParams = new URLSearchParams(params);
      const expected = 'tag=shop&region=ca';
      const result = getNextParams(urlParams, c.SORT_DESC, 'last_updated');
      expect(result).toEqual(expected);
    });
  });

  describe('construct url', () => {
    it('constructs url correctly when search path does not exist', () => {
      const expected = 'https://boone.com/listings';
      const result = constructUrl('https://boone.com/listings', '');
      expect(result).toEqual(expected);
    });
    it('constructs url correctly when search path exists', () => {
      const expected = 'https://boone.com/listings?sort=-last_updated&tag=shop&region=ca';
      const result = constructUrl('https://boone.com/listings', 'sort=-last_updated&tag=shop&region=ca');
      expect(result).toEqual(expected);
    });
  });
});
