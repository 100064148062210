import React from 'react'
import { CanonicalPlace } from '../../common/types'
import { AuditListing } from './AuditListing';

interface Props {
  listings: Array<CanonicalPlace>;
  selectedListings: Array<CanonicalPlace>;
}

export const ListingsAuditList = ({ ...props }: Props) => {

  const isSelected = (listing: CanonicalPlace) => {
    if (props.selectedListings && props.selectedListings.length > 0) {
      const isSelected = props.selectedListings.some(lst => listing.id === lst.id);
      return isSelected;
    }
  }

  return (
    <div className="listings-content">
      {props.listings.map((listing: CanonicalPlace, index: number) =>
        <AuditListing
          key={`${listing.name}-${index}`}
          listing={listing}
        />
      )}
    </div>
  )
}
