import React, { ReactNode, useState, useEffect } from 'react';

import ListingResultsMap from '../../../../listings/search/map';

import './styles';
import { CanonicalPlace } from '../../common/types';
import Pagination from "@/helpers/pagination";

export interface Props {
  children?: ReactNode
  inFocus: number;
  mapOpen: boolean;
  classes?: {};
  pagination: Pagination;
  results: Array<CanonicalPlace>,
  auditsTruncResults?: Array<any>,
  handleFocusRef: Function;
  scrollTo: any;
}


export const MapView = ({ ...props }: Props) => {

  const handleFocus = (id) => {
    props.handleFocusRef(id);
    props.scrollTo && props.scrollTo.current && props.scrollTo.current.length > 0 && props.scrollTo.current[id] &&
    props.scrollTo.current[id].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="editor__listings">
      <div className="editor__search-results">
        <ListingResultsMap
          {...props}
          offset={props.pagination.start}
          mapOpen={props.mapOpen}
          inFocus={props.inFocus}
          
          handleFocus={handleFocus}
        />

      </div>
    </div>
  )
}
