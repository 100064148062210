import React, {PureComponent} from "react";
import Label from "../label";

type Props = {
  label?: any;
  className?: string;
  value?: any;
  labelClass?: string;
  cy?: string;
  id?: string;
  description?: string;
  hideNull?: boolean;
};

class ViewField extends PureComponent<Props> {
  render() {
    const {label, value, cy} = this.props;
    const className =
      this.props.className || "editor__field editor__field--view";
    const labelClass = this.props.labelClass || "editor__label";

    return this.props.hideNull && !value && value !== "" ? null : (
      <div data-cy={cy || "view-field"} className={className}>
        {label && (
          <Label
            missingData={!value && value !== "" && value !== 0}
            id={this.props.id}
            className={labelClass}
            description={this.props.description}
            text={label}
          />
        )}
        {value === "" && (
          <span className="editor__field__value editor__text-muted">none</span>
        )}
        {(value || value === 0) && (
          <div className="editor__field__value">{value}</div>
        )}
      </div>
    );
  }
}

export default ViewField;
