import React from 'react';
import EditingDataSection from '../editing-data-section';
import Attributes from './attributes';
import cloneDeep from 'lodash/cloneDeep';
import useDefinitionsIndex from './hooks/definitions-index';
import useStateMapper from './hooks/state-mapper';
import * as util from './util';
import './styles/index';

function AttributesSection(props){
  const index = useDefinitionsIndex(props.attributes);
  const [saved, setSaved, savedAttributes] = useStateMapper(
    index,
    cloneDeep(props.data)
  );
  const [pending, setPending, pendingAttributes] = useStateMapper(
    index,
    cloneDeep(saved)
  );

  React.useEffect(
    () => {
      if (props.onChange) {
        props.onChange(getParams())
      }
    },
    [pending]
  );

  const cySection = props.sectionTitle.toLowerCase().split(' ').join('-');

  const getParams = () => {
    // Check if attribute is root or nested, activities has no child
    // properties, for example
    const root = props.rootAttributeKey;
    const keys = util.getObjectDiff(pending, saved);

    // Only return changes
    if (keys.length === 1 && keys[0] === root) {
      return { [root]: pending[root] };
    } else if (keys.length > 0) {
      return {
        [root]:
          keys.reduce((p, c) => {
            p[c] = pending[c];
            return p;
          }, {})
      };
    }
  };

  return (
    <EditingDataSection
      className="editor__attributes__data-section"
      sectionTitle={props.sectionTitle}
      cyEdit={cySection + '-edit'}
      userEditingStrategy={props.userEditingStrategy}
      onSuccess={() => setSaved(cloneDeep(pending))}
      onEdit={() => {}}
      params={{ listing: getParams() }}
      floatControls={true}
      editComponent={
        <Attributes definitions={pendingAttributes} editing={true} />
      }
    />
  );
}

export default AttributesSection;
