import React from 'react';
import PropTypes from 'prop-types';
import ReactMapboxGl, { Layer, Feature, ZoomControl } from 'react-mapbox-gl';
import { GeolocateControl } from 'mapbox-gl';
import './styles';

const Map = ReactMapboxGl({
  accessToken: window._env_.MAPBOX_KEY
});

export default class LocationMapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      zoom: this.props.zoom || [11],
      latitude: this.props.latitude,
      longitude: this.props.longitude
    };
  }

  getPoint() {
    let long = this.props.longitude;
    if (isNaN(long) || long < -180 || long > 180) {
      long = 0;
    }

    let lat = this.props.latitude;
    if (isNaN(lat) || lat < -90 || lat > 90) {
      lat = 0;
    }

    return [long, lat];
  }

  onDragEnd = pos => {
    if (this.props.editing) {
      let point = {
        longitude: pos.lngLat.lng,
        latitude: pos.lngLat.lat
      };
      this.props.onMapLocationChange(this, point);
      this.props.setField('moved_map_marker', 'location_set_via')
    }
  };

  layerPaintProps() {
    return {
      'circle-stroke-width': 4,
      'circle-radius': 10,
      'circle-blur': 0.15,
      'circle-color': '#3770C6',
      'circle-stroke-color': 'white'
    };
  }

  onMapLoad = (map) => {
    const geolocate = new GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true,
    })
    map.addControl(geolocate, 'top-right');
    geolocate.on('geolocate', (pos) => {
      this.currentLocationData(pos);
    });
  };

  currentLocationData = (pos) => {
    this.props.setPositionLocation(pos.coords.latitude, pos.coords.longitude);
    this.props.setField(pos.coords.latitude, 'latitude')
    this.props.setField(pos.coords.longitude, 'longitude')
    this.props.setField('my_location', 'location_set_via')
  }

  render() {
    return (
      <Map
        onStyleLoad={this.onMapLoad.bind(this)}
        style="mapbox://styles/mapbox/streets-v9"
        center={this.getPoint()}
        containerStyle={{ height: '500px', width: '1000px' }}
        zoom={this.state.zoom}
      >
        <ZoomControl position="bottom-left" className='zoom_in' />
        <Layer type="circle" id="loc_circle_marker" paint={this.layerPaintProps()}>
          <Feature coordinates={this.getPoint()} draggable={this.props.editing} onDragEnd={this.onDragEnd} />
        </Layer>
      </Map>
    );
  }
}

LocationMapView.propTypes = {
  editing: PropTypes.bool,
  latitude: PropTypes.any.isRequired,
  longitude: PropTypes.any.isRequired,
  zoom: PropTypes.arrayOf(PropTypes.number),
  onMapLocationChange: PropTypes.func
};
