import React from 'react';
import Button from '@library/_button';
import './styles/index.scss';

type Props = {
  saveText: string;
  showSave?: boolean;
  cancelText?: string;
  showCancel?: boolean;
  saving?: boolean;
  cancel: () => void;
  clear: () => void;
  save: () => void;
  showRemoveAll: boolean; // Hide Remove All button if there isn't anything to remove
};

function Controls(props: Props) {
  const showCancel = props.showCancel === undefined || props.showCancel;
  const showSave = props.showSave === undefined || props.showSave;

  return (
    <div className="editor__search-tree__controls">
      {showCancel && (
        <Button data-cy="options-cancel" className="editor__button--white" onClick={props.cancel}>
          {props.cancelText || 'Cancel'}
        </Button>
      )}
      {props.showRemoveAll && (
        <Button className="editor__button--white" data-cy="options-none" onClick={() => props.clear()}>
          Remove All
        </Button>
      )}
      {showSave && (
        <Button disabled={props.saving} className="editor__button--primary" data-cy="options-apply" onClick={props.save}>
          {props.saveText || 'Save'}
        </Button>
      )}
    </div>
  );
}

export default Controls;
