import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import AvatarName from '../library/AvatarName';
import ViewField from '../library/view-field';

export const AuditViewField = ({ className, name, url, booneLogo }) => (
  <ViewField
    label="Audit"
    value={
      <AvatarName
        className="listing-detail listing-detail__audit-assignment"
        name={name}
        url={url}
        booneLogo={booneLogo}
      />
    }
  />
);

AuditViewField.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  booneLogo: PropTypes.bool
};

export class AssignmentPortal extends PureComponent {
  render = () => {
    return this.props.anchor && this.props.show
      ? ReactDOM.createPortal(<AuditViewField {...this.props} />, this.props.anchor)
      : null;
  };
}

AssignmentPortal.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  anchor: PropTypes.any,
  booneLogo: PropTypes.bool
};

export default AssignmentPortal;
