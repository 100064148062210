import React from 'react';
import * as ajax from '@/helpers/ajax';

export default function useApi() {
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [response, setResponse] = React.useState('');

  const handleResponse = async (response, onSuccess, onFailure) => {
    const json = await response.json();

    setResponse(json);

    if (response.status === 400) {
      setError(true);
      setResponse(json);
      setSaving(false);
      return;
    } else if (response.status === 500) {
      setError(true);
      setResponse('Something went really wrong.');
      setSaving(false);
      console.debug('500: Something went really wrong.');
      return;
    } else {
      setError(false);

      let saved = true;
      if (onSuccess) {
        // do we need this still?
        let result = onSuccess(response);
        if (result && result.cancel) {
          saved = false;
        }
      }

      if (saved) {
        setSaving(false);
      } else {
        setSaving(false);
      }
    }
    setSaving(false);
  };

  const createReq = method => async (
    params,
    onSuccess = null,
    onFailure = null,
    preSaveCheck = null,
    url = document.location.pathname
  ) => {
    // preSaveCheck is used to handle any checks you need to make before
    if (preSaveCheck) {
      const { ok, reason, message } = preSaveCheck();
      if (!ok) {
        if (reason === 'error') {
          setError(true);
          setResponse(message);
        } else {
          console.debug(reason, message);
        }
        setSaving(false);
        return;
      }
    }

    setSaving(true);
    setError(false);

    const response = await ajax[method](url, params);

    return await handleResponse(response, onSuccess, onFailure);
  };

  return {
    patch: createReq('patch'),
    post: createReq('post'),
    get: createReq('get'),
    delete: createReq('delete'),
    saving,
    error,
    dismissError: () => setError(false),
    response
  };
}
