import React from 'react';
import Attribute from './attribute';
import SlideIn from '../../animations/SlideIn';
import cn from 'classnames';

function Composer({ attributes, editing }){
  const stylePrefix = (suffix) => `editor__attribute__${suffix}`;

  return (
    <div className="editor__attribute-set">
      {
        attributes.map((attribute) => {
          const hasDeps = Boolean(attribute.dependents);
          const hasMultiple = hasDeps && attribute.dependents.length > 1;
          return (
            <div
              data-cy="attribute"
              key={attribute.id}
              className={cn(
                stylePrefix(attribute.component),
                {
                  editor__attribute: true,
                  // multiple children are displayed in a column
                  [stylePrefix('pair')]: hasDeps && !hasMultiple,
                  [stylePrefix('multiple')]: hasDeps && hasMultiple
                }
              )}
            >
              <Attribute editing={editing} {...attribute} />

              {hasDeps && (
                <div
                  data-cy="attribute-dependents"
                  className={cn({
                    [stylePrefix('dependents')] : true,
                    [stylePrefix('dependents-disabled')] : !attribute.value
                  })}
                >
                    {
                      attribute.dependents.map((attr) => {
                        return (<Attribute
                          key={attr.id + '-dep'}
                          {...attr}
                          hideLabel={true}
                          active={!!attribute.value}
                          editing={editing}
                        />)
                      })
                    }
                </div>
              )}
            </div>
          );
        })
      }
    </div>
  );

}

export default Composer;
