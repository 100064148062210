import formatAddressObject from './formatAddressObject';

// Make an event or deal listing look like an autocomplete result.
export default function(location) {
  let newLocation = {};

  newLocation.id = location.id
  newLocation.canonical_place_id = location.canonical_place_id;
  newLocation.name = location.name;
  newLocation.address = location.address;
  newLocation.thumbnail = location.thumbnail;

  newLocation.address = newLocation.address || formatAddressObject(location);

  if (!newLocation.thumbnail && location.image && location.image.sizes) {
    newLocation.thumbnail = location.image.sizes.thumbnail;
  }

  return newLocation;
};
