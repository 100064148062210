import React from 'react';
import * as dt from '@library/attributes-section/attributes/type-map';
import Attribute from '@library/attributes-section/attributes/attribute';

function Attributes(props){
  return (
    <>
      {props
        .attributes

        .filter(attr => props.blackList.indexOf(attr.id) === -1)
        .map(
          s => dt.setComponentType({ ...s, label: s.name })
        )
        .map(
          k => (
            <Attribute
              editing
              {...k}
              key={k.id}
              value={props.state[k.id]}
              onChange={(n) => props.setState(s => ({ ...s, [k.id]: n }))}
            />
          )
        )}
      </>
  );
}


export default Attributes;
