import React from 'react';
import PropTypes from 'prop-types';
import AvatarName from '../../AvatarName';
import IconCheck from '../../../icons/IconCheck';
import * as helpers from '../../../helpers';

export const AssignUserOption = ({ name = 'Anyone', assigned = false, ...props }) => (
  <AvatarName
    className={props.className}
    usernameClassName={props.usernameClassName}
    avatarClassName={props.avatarClassName}
    onClick={props.onClick}
    url={props.url}
    name={props.hideLastName ? helpers.getFirstName(name) : name}
    booneLogo={props.booneLogo}
  >
    {assigned ? <IconCheck className={props.checkClassName || 'boone-icon boone-icon__assigned-check'} /> : null}
  </AvatarName>
);

AssignUserOption.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  assigned: PropTypes.bool,
  className: PropTypes.string,
  usernameClassName: PropTypes.string,
  avatarClassName: PropTypes.string,
  checkClassName: PropTypes.string,
  booneLogo: PropTypes.bool,
  hideLastName: PropTypes.bool
};

export default AssignUserOption;
