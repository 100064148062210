import React from "react";
import {InputWithLabel as Input} from "@/library/input-with-label";
import Subsection from "@/library/wizard/components/subsection";
import type { UpdateField } from './typings';

type Props = {
  validate?: (field, value) => void;
  title?: string;
  fields: string[][];
  state: {[key: string]: string};
  updateField: UpdateField;
  errorMap?: {[key: string]: boolean};
};

function Location({
  title,
  fields,
  state,
  updateField,
  validate,
  errorMap,
}: Props) {
  return (
    <Subsection>
      {title && <h4>{title}</h4>}
      {fields.map(([label, field, cn, props]) => (
        <div key={field} className={"editor__form-field editor__" + cn}>
          <Input
            {...props}
            error={Boolean(errorMap && errorMap[field])}
            label={label}
            value={state[field]}
            onChange={(event) => {
              validate && validate(event.target.value,field);
              updateField(event, field);
            }
            }
          />
        </div>
      ))}
    </Subsection>
  );
}

export default Location;
