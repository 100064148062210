import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFlask,
  faMapMarkerAlt,
  faNewspaper,
  faImage,
  faCalendar,
  faHiking,
  faEdit,
  faCommentDots,
  faLandmark,
  faBell,
  faPalette,
  faGlobeAmericas,
  faRestroom,
  faCar,
  faDollarSign,
  faCampground,
  faUtensils,
  faHotel,
  faBook,
  faCaravan,
  faDog,
  faBolt,
  faMap,
  faBars,
  faTimes,
  faUsers,
  faSearch,
  faKey,
  faList,
  faLayerGroup,
  faShapes,
  faHome,
  faChevronUp,
  faChevronDown,
  faShieldAlt,
  faHistory,
  faArrowDown,
  faArrowUp,
  faCheck,
  faCheckDouble,
  faCheckSquare,
  faFilter,
  faSort,
  faSortUp,
  faSortDown,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faPlug,
  faThumbsUp,
  faThumbsDown,
  faHourglass,
  faPercent,
  faNetworkWired,
  faUserCircle,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faCircle,
  faFileImport,
  faHeadset,
  faCaretDown,
  faProjectDiagram,
  faSquare,
  faPlus,
  faFileContract,
  faGripHorizontal,
  faLink,
  faExternalLinkAlt,

} from '@fortawesome/free-solid-svg-icons';

library.add(
  faEdit,
  faArrowDown,
  faArrowUp,
  faFlask,
  faMapMarkerAlt,
  faNewspaper,
  faImage,
  faCalendar,
  faHiking,
  faEdit,
  faCommentDots,
  faLandmark,
  faBell,
  faPalette,
  faGlobeAmericas,
  faRestroom,
  faCar,
  faDollarSign,
  faCampground,
  faUtensils,
  faHotel,
  faBook,
  faCaravan,
  faDog,
  faMap,
  faBolt,
  faBars,
  faTimes,
  faUsers,
  faSearch,
  faBolt,
  faKey,
  faMap,
  faList,
  faLayerGroup,
  faShapes,
  faHome,
  faChevronUp,
  faChevronDown,
  faShieldAlt,
  faHistory,
  faCheck,
  faCheckDouble,
  faCheckSquare,
  faFilter,
  faSort,
  faSortUp,
  faSortDown,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faPlug,
  faThumbsUp,
  faThumbsDown,
  faHourglass,
  faPercent,
  faNetworkWired,
  faUserCircle,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faCircle,
  faFileImport,
  faHeadset,
  faCaretDown,
  faProjectDiagram,
  faSquare,
  faPlus,
  faFileContract,
  faGripHorizontal,
  faLink,
  faExternalLinkAlt,
);
