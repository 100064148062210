import React from "react";
import selectQueryParams from "@/helpers/selectQueryParams";
import {enforceNum, truthy} from "./util";
import {
  SingleValueProps,
  RangeProps,
  FormFns,
  FormState,
  TopLevelProps,
} from "./types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faGreaterThanEqual,
  faLessThanEqual,
} from "@fortawesome/free-solid-svg-icons";

// min or max state scroll down for range hook
export type RangeValue = {
  bound: number;
  only: boolean;
  param: string;
  readFromQueryParam: () => {value: number; truthy: boolean};
  saved: number;
  savedTruthy: boolean;
  setOnly: React.Dispatch<React.SetStateAction<boolean>>;
  setSaved: React.Dispatch<React.SetStateAction<number>>;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
  valueTruthy: boolean;
};

function useRangeValueState(
  bound: string,
  float: boolean,
  param: string
): RangeValue {
  const numBound = enforceNum(bound, float);

  const [value, setValue] = React.useState<number>(numBound);
  const [saved, setSaved] = React.useState<number>(numBound);
  const [only, setOnly] = React.useState<boolean>(false);

  const [valueTruthy, setValueTruthy] = React.useState<boolean>(true);
  const [savedTruthy, setSavedTruthy] = React.useState<boolean>(true);

  React.useEffect(() => {
    setValueTruthy(truthy(value));
    setSavedTruthy(truthy(saved));
  }, [value, saved]);

  React.useEffect(() => {
    if (!only) {
      setValue(numBound);
    }
  }, [only]);

  function readFromQueryParam(): {value: number | null; truthy: boolean} {
    const selectParams = selectQueryParams([param]);
    if (selectParams) {
      const paramValue = enforceNum(selectParams[param], float);
      if (truthy(paramValue)) {
        const searchValueTruthy = truthy(paramValue);
        return {
          value: paramValue,
          truthy: searchValueTruthy,
        };
      }
    }
    return {truthy: false, value: null};
  }

  return {
    value,
    setValue,
    valueTruthy,
    saved,
    setSaved,
    savedTruthy,
    only,
    setOnly,
    bound: numBound,
    param,
    readFromQueryParam,
  };
}

function useRangeState(
  props: TopLevelProps
): {
  min: SingleValueProps;
  max: SingleValueProps;
  range: RangeProps;
} & FormFns &
  FormState {
  const [isOpen, setIsOpen] = React.useState(false);
  const [active, setActive] = React.useState(false);

  const min = useRangeValueState(
    props.minValue,
    props.float,
    "min_" + props.name
  );

  const max = useRangeValueState(
    props.maxValue,
    props.float,
    "max_" + props.name
  );

  React.useEffect(() => {
    readFromUrl();
  }, []);

  React.useEffect(() => {
    if (min.only || max.only) {
      setActive(true);
    }
  }, [max.only, min.only]);

  React.useEffect(() => {
    if (min.only) {
      max.setOnly(false);
      max.setValue(max.bound);
    }
  }, [min.only]);

  React.useEffect(() => {
    if (max.only) {
      min.setOnly(false);
      min.setValue(min.bound);
    }
  }, [max.only]);

  const setBothValues = (i, x) => {
    if (i <= x) {
      min.setValue(i);
      max.setValue(x);
    } else {
      min.setValue(x);
      max.setValue(i);
    }
  };

  const setBothSaved = (i, x) => {
    min.setSaved(i);
    max.setSaved(x);
  };

  const setBothOnly = (n, m) => {
    max.setOnly(n);
    min.setOnly(m);
  };

  function readFromUrl() {
    const minQueryParam = min.readFromQueryParam();
    const maxQueryParam = max.readFromQueryParam();

    if (minQueryParam.truthy || maxQueryParam.truthy) {
      setActive(true);
    }

    if (
      minQueryParam.value !== min.value ||
      maxQueryParam.value !== max.value
    ) {
      if (minQueryParam.truthy && maxQueryParam.truthy) {
        setBothOnly(false, false);
        setBothSaved(minQueryParam.value, maxQueryParam.value);
        setBothValues(minQueryParam.value, maxQueryParam.value);
      } else if (minQueryParam.truthy && !maxQueryParam.truthy) {
        min.setOnly(true);
        min.setValue(minQueryParam.value);
        min.setSaved(minQueryParam.value);
      } else if (!minQueryParam.truthy && maxQueryParam.truthy) {
        max.setOnly(true);
        max.setValue(maxQueryParam.value);
        max.setSaved(maxQueryParam.value);
      }
    }
  }

  function handleApply() {
    if (min.valueTruthy && min.only) {
      setActive(true);
      min.setSaved(min.value);
      props.removeFilter([max.param]);
      props.applyFilter({[min.param]: min.value + ""});
    } else if (max.valueTruthy && max.only) {
      max.setSaved(max.value);
      setActive(true);
      props.removeFilter([min.param]);
      props.applyFilter({[max.param]: max.value + ""});
    } else if (min.valueTruthy && max.valueTruthy && !min.only && !max.only) {
      setBothSaved(min.value, max.value);
      setActive(true);
      props.applyFilter({
        [min.param]: min.value + "",
        [max.param]: max.value + "",
      });
    }
  }

  function handleCancel() {
    readFromUrl();
  }

  function fmtLabel() {
    if (isOpen) {
      return props.label;
    }
    const svgStyles = {
      marginLeft: "0.25em",
      marginRight: "0.25em",
    };

    if (active && (min.savedTruthy || max.savedTruthy)) {
      if ((max.savedTruthy && max.only) || min.value === min.bound) {
        return (
          <div>
            {props.label}
            <FontAwesomeIcon
              size="xs"
              style={svgStyles}
              icon={faLessThanEqual}
            />
            {max.saved}
          </div>
        );
      } else if (min.savedTruthy && min.only) {
        return (
          <div>
            {props.label}
            <FontAwesomeIcon
              size="xs"
              style={svgStyles}
              icon={faGreaterThanEqual}
            />
            {min.saved}
          </div>
        );
      } else if (min.saved === max.saved && max.savedTruthy) {
        return props.label + " = " + max.saved;
      } else if (max.savedTruthy && min.savedTruthy) {
        return props.label + " " + min.saved + " - " + max.saved;
      }
    }

    return props.label;
  }

  function handleClear() {
    setBothOnly(false, false);
    setBothValues(min.bound, max.bound);
    props.removeFilter([min.param, max.param]);
    setActive(false);
  }

  function setIfInBounds(v, set) {
    if (v >= min.bound && v <= max.bound) {
      set(v);
    }
  }

  function onChange(values) {
    setBothOnly(false, false);
    setBothValues(values[0], values[1]);
  }

  return {
    min: {
      onChange: (v) => setIfInBounds(v, min.setValue),
      setShow: min.setOnly,
      show: min.only,
      value: min.value,
    },
    max: {
      onChange: (v) => setIfInBounds(v, max.setValue),
      setShow: max.setOnly,
      show: max.only,
      value: max.value,
    },
    range: {
      values: [min.value, max.value],
      onChange,
      min: min.bound,
      max: max.bound,
      disabled: min.only || max.only,
    },
    active,
    handleApply,
    handleCancel,
    handleClear,
    setIsOpen: (open) => {
      if (open) readFromUrl();
      setIsOpen(open);
    },
    fmtLabel,
  };
}

export default useRangeState;
