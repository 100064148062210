import { CategoriesToSections } from "./order";

function groupAllCategories(categories: any) {
  let allCategories = [];
    if (categories) {
      if(categories.length > 0){
        categories
        .forEach((category: any) =>
          allCategories
            .push(...category.public_id_breadcrumbs.reverse()));
      }
    }
    return allCategories;
}

function groupAllSectionIds(allCategories: string[]) {
  let sectionIds = [];

  allCategories
    .forEach((category: string) => CategoriesToSections[category] &&
      sectionIds
        .push(...CategoriesToSections[category]));

  sectionIds.push(...CategoriesToSections['default']);
  return sectionIds;
}

export function SortedAttributeSections(props) {
  const allCategories = groupAllCategories(props.categories);
  let allSectionIds = groupAllSectionIds(allCategories);

  let duplicateFilteredIds = Array.from(new Set(allSectionIds));
  let theSections = [...props.sections];
  let sortedSections = [];

  duplicateFilteredIds.forEach(sectionId => {
    let matchedSection = theSections.filter(section => section.sectionId === sectionId);
    if (matchedSection) {
      if (matchedSection.length > 0) {
        sortedSections.push(...matchedSection);
        theSections = theSections.filter(section => section.sectionId !== sectionId)
      }
    }
  });
  sortedSections.push(...theSections);

  return sortedSections;
}