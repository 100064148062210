import React from "react";
import Changes from "./components/changes";
import Places from "./components/places";
import Canonical from "./components/canonical";
import Details from "./components/details";
import Audits from "./components/audits";
import "./styles";

function Dashboard(props) {
  return (
    <div data-cy={"listing-dashboard"} className="editor__dashboard">
      <Canonical {...props} />
      <Details {...props} />
      <Audits {...props} />
      <Changes {...props} />
      <Places places={props.places} />

    </div>
  );
}

export default Dashboard;
