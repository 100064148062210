import React from 'react';

function RoadpassLogo(){
  return (
    <svg width="82" height="84" viewBox="0 0 82 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1939 33.4902C17.6723 30.2602 21.3189 27.2001 25.19 24.4801C27.4341 22.8935 29.7343 21.4201 32.1467 20.1168C32.4272 18.5868 32.8199 17.0568 33.3809 15.5834C35.7933 9.06672 40.3376 3.96669 46.004 0.170001C46.0601 0.113334 46.1723 0.056667 46.2284 0H4.43209L0 22.3834H16.3819L14.1939 33.4902Z" fill="#24201D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M82 24.7683C82 12.8771 74.7422 3.79043 63.2085 1.09808C62.0832 1.43463 61.0143 1.71508 59.889 2.10772C56.5133 3.22953 53.2501 4.74397 50.2682 6.65106C47.9052 8.1655 45.7672 9.9604 43.9669 12.0918C43.2917 12.9332 42.6728 13.7746 42.1102 14.7281C41.7726 15.2329 41.4913 15.7377 41.2663 16.2986C45.5985 15.0646 50.2119 14.6159 54.6004 15.906C59.4389 17.3644 63.096 20.7298 64.9526 25.3853C66.5842 29.4799 66.0778 34.2476 64.1649 38.174C62.1395 42.3247 57.8073 45.7462 53.2501 46.6997C50.8871 47.2046 48.4116 47.2046 46.0486 46.5315C43.6856 45.8584 41.6601 44.6244 39.7472 43.0539C36.0902 40.0811 33.6709 35.6499 32.4894 31.2187C32.2644 30.4335 32.1518 29.6482 31.983 28.8629C27.3696 31.1626 17.1299 39.9689 11.3349 46.6437L3.85203 84H26.1318L31.3642 57.8057H41.4351L50.3807 84H74.067L63.2647 53.8233C74.4609 48.3825 82 37.8374 82 24.7683Z" fill="#24201D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M45.2754 22.3377C43.466 22.6921 41.7131 23.2829 40.0168 23.9918C39.7906 24.1099 39.5079 24.169 39.2817 24.2872C39.2252 24.2872 39.1686 24.3462 39.1121 24.3462C38.999 24.4053 39.1686 25.9412 39.2252 26.1185C39.7341 29.7811 40.9215 33.1483 43.2398 35.9839C44.3707 37.3426 45.7277 38.465 47.3675 39.0558C49.1203 39.7056 51.1559 39.7056 52.9088 38.9376C54.0396 38.465 55.0574 37.6971 55.9056 36.7519C56.7537 35.7476 57.5454 34.5661 57.9412 33.2665C58.5631 31.258 58.4501 28.9541 57.6019 27.0637C56.8103 25.2324 55.2836 23.4601 53.4742 22.7512C50.7601 21.747 47.9895 21.806 45.2754 22.3377Z" fill="#24201D"/>
    </svg>

  );
}

export default RoadpassLogo;
