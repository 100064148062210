import React, { HTMLAttributes, ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavOption, Props as iOption } from './NavOption';
import './styles.scss';
import '@library/fontawesome';
import { RecentPlace } from '../../common/types';




export interface Props extends HTMLAttributes<HTMLButtonElement> {
  options?: Array<iOption>;
  title?: string;
  titleB?: string;
  logo?: string;
  collapsed?: boolean;
  setCollapsed?: Function;
  onCollapsedState?: Function;
  userRole?: number;
  recentPlaces?: Array<RecentPlace>;
  setActive?: (tab: string) => void;
  children?: ReactNode;
}

export const Sidebar = ({ children, onCollapsedState, options, collapsed = false, ...props }: Props) => {

  const [drawerCollapsed, setDrawerCollapsed] = useState(collapsed);
  const [recentCollapsed, setRecentCollapsed] = useState(true);


  const roles = {
    1: 'Super Admin',
    2: 'Admin',
    3: 'Editor',
    4: 'Auditor',
  };

  const isAdmin = (role: number) => {
    if(roles[role] == 'Admin' || roles[role] == 'Super Admin'){
      return true;
    }
    return false;
  }

  const toggleSetCollapsed = () => {
    setDrawerCollapsed &&
      setDrawerCollapsed(!drawerCollapsed);
    onCollapsedState &&
      onCollapsedState(drawerCollapsed);
  };

  return (
    <div className={`sidebar ${drawerCollapsed ? '--collapsed' : ''}`}>
      <div className="__header">
        {
          !drawerCollapsed &&
          <div className="_logo">{
            drawerCollapsed ?
              props.logo ?
                <img src={''} /> : '' :
              <>
                <div className="_title">{props.title}</div>
                <div className="_title --bold">{props.titleB}</div>
              </>
          }
          </div>
        }
        <button className="_toggle-button" id="button" onClick={() => toggleSetCollapsed()}><FontAwesomeIcon icon={drawerCollapsed ? ['fas', 'angle-right'] : ['fas', 'angle-left']} /></button>
      </div>
      <div className="__body">
        <div className="pre-nav">
          {!drawerCollapsed && props.recentPlaces && props.recentPlaces.length > 0 &&
            <>
              <div className="__recent-header" onClick={() => setRecentCollapsed(!recentCollapsed)}>
                <div className='__tree-chevron'>
                  <FontAwesomeIcon icon={recentCollapsed ? ['fas', 'angle-right'] : ['fas', 'angle-down']} />
                </div>
                <span>Recent Places</span>
              </div>
              {
                !recentCollapsed &&
                props.recentPlaces.map((place: RecentPlace) =>
                  <div key={place.id} className="__recent-place" title={place.name}>
                    <a href={place.href}>{place.name}</a>
                  </div>
                )
              }
            </>
          }
        </div>
        {
          options && options.length > 0 ?
            <div className="__options">
              {
                options.map((option: iOption) =>
                  !option.adminOnly ?
                    <NavOption
                      {...option}
                      key={option.name}
                      parentCollapsed={drawerCollapsed}
                      setActive={props.setActive}
                    />
                    :
                    option.adminOnly && isAdmin(props.userRole) &&
                    <NavOption
                      {...option}
                      key={option.name}
                      parentCollapsed={drawerCollapsed}
                      setActive={props.setActive}
                    />
                )
              }
            </div>
            : ''
        }
      </div>
      <br />
      {!drawerCollapsed && children}
      <div className="__footer">
        {!drawerCollapsed &&
          <div className="__app-info">
            <small>© RoadpassDigital - Editor v2.1.0</small>
          </div>
        }
      </div>
    </div>
  )
}
