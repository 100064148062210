import React from 'react';

type Props = {
  children?: any;
  id: string;
  idStamp: string;
  name?: string;
  url: string;
};

function WufooForm(props: Props){
  return (
    <form
      id={props.id}
      name={props.name}
      acceptCharset="UTF-8"
      autoComplete="off"
      encType="multipart/form-data"
      method="post"
      noValidate
      action={props.url}
    >
      {props.children}
      <input type="hidden" id="idstamp" name="idstamp" value={props.idStamp} />
    </form>
  );
}

export default WufooForm;
