import React from "react";
import FilterForm from "@/library/filter-form";
import Range from "@library/range";
import SingleParam from "./single";
import {enforceNum} from "./util";
import type {
  RangeProps,
  FormFns,
  FormState,
  SingleValueProps,
  TopLevelProps,
} from "./types";
import "./styles";

type Props = {
  min: SingleValueProps;
  max: SingleValueProps;
  range: RangeProps;
} & FormFns &
  FormState &
  Partial<TopLevelProps>;

function Form({
  min,
  max,
  range,
  active,
  setIsOpen,
  handleClear,
  handleApply,
  handleCancel,
  fmtLabel,
  name,
  step,
  float,
}: Props) {
  const cyName = name.split("_").join("-");
  return (
    <FilterForm
      name={name}
      active={active}
      label={fmtLabel()}
      onApply={handleApply}
      onCancel={handleCancel}
      onClear={handleClear}
      setIsOpen={setIsOpen}
      hideButtons={false}
      cy={cyName + "-filter"}
    >
      <div className="editor__filters__range">
        <SingleParam cy={cyName + "-min"} {...min} label={"min only"} />
        <SingleParam cy={cyName + "-max"} {...max} label={"max only"} />
        <Range
          {...range}
          cy={cyName + "-range"}
          step={enforceNum(step, float)}
          float={float}
        />
      </div>
    </FilterForm>
  );
}

export default Form;
