import React from 'react';
import Clipboard from 'react-clipboard.js';
import PropTypes from 'prop-types';

const CopyToClipboard = ({ text, children }) => (
  <Clipboard className="display-none" data-clipboard-text={text}>
    {children}
  </Clipboard>
);

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default CopyToClipboard;
