import React from 'react';
import ReactTooltip from 'react-tooltip';
import './styles';

type Props = {
  message?: string;
  id?: string;
};

function Info({ message, id }: Props){
  if (!id) {
    console.debug(`prop 'id' is required for tooltip with message: ${message}`);
  }
  return message && id ? (
    <div className="editor__info" data-tip data-for={'message-' + id}>
      <svg
        className="editor__info__icon"
        xmlns="http://www.w3.org/2000/svg"
        height="12"
        viewBox="0 0 24 24"
        width="12"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
      </svg>
      <ReactTooltip
        place="right"
        className="editor__info__tooltip"
        type="light"
        multiline={true}
        id={'message-' + id}
        effect="solid"
        getContent={() => <p className=""> {message} </p>}
      />
    </div>
  ) : null;
}

export default Info;
