import * as React from "react";
import {IconDeleteMedia, IconDeleteMediaHover} from "../../icons/IconDelete";
import Hover from "../Hover";
import * as ajax from "@/helpers/ajax";

type Props = {
  id: number;
  ownerKey: string;
  ownerId: string;
  url: string;
  mediaDisabled: () => void;
  className?: string;
};

export default function Disable(props: Props) {
  const [disable, setDisable] = React.useState(false);

  React.useEffect(() => {
    if (disable) {
      let confirmationId = "#deleteConfirmation";
      $(confirmationId).modal("show");

      let deleteConfirmationButtonId = "#confirm-delete-button";
      const confirmDeleteButton = $(deleteConfirmationButtonId);
      confirmDeleteButton.off("click");

      const disableMedia = () => {
        ajax.post(props.url + "/disable", {[props.ownerKey]: props.ownerId});
        props.mediaDisabled();
      };

      confirmDeleteButton.on("click", () => {
        $(confirmationId).modal("hide");
        disableMedia();
      });
      setDisable(false);
    }
  });

  return (
    <div
      data-cy="disable"
      className={props.className || "image-badge delete-image-badge"}
      onClick={(e) => {
        e.stopPropagation();
        setDisable(!disable);
      }}
    >
      <span>Delete</span>
    </div>
  );
}
