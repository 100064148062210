import React from "react";
import Form from "./form";
import type {TopLevelProps} from "./types";
import useRangeState from "./hooks";

function RangeFilter(props: TopLevelProps) {
  const state = useRangeState(props);
  return <Form {...state} {...props} />;
}

export default RangeFilter;
