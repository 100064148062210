// toggle icon options
const labelNoIcon = 'label-no-icon';
const labelArrowRight = 'label-arrow-right';
const labelArrowDown = 'label-arrow-down';
const dots = 'dots';
const arrowRight = 'arrow-right';
const arrowDown = 'arrow-down';
const actionButton = 'action-button';

export const toggleIcons = {
  labelArrowRight,
  labelArrowDown,
  arrowRight,
  arrowDown,
  actionButton,
  dots,
  labelNoIcon,
  all: [labelNoIcon, labelArrowRight, labelArrowDown, arrowRight, arrowDown, actionButton, dots]
};
