import React, { PureComponent } from 'react';
import { Icon } from './Icon';

export class IconDash extends PureComponent {
  render() {
    const { fillColor, ...props } = this.props;
    return (
      <Icon {...props}>
        <rect fill={fillColor} x="0" y="10" width="15" height="1.5" />
      </Icon>
    );
  }
}

export default IconDash;
