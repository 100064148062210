import PropTypes from 'prop-types';
import React from 'react';
import FilterForm from './FilterForm';

class CheckboxFilter extends React.Component {
  constructor(props) {
    super(props);

    let value = this.props.data;
    this.state = { appliedValue: value, value: value };

    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleApply() {
    this.setState(prevState => ({ appliedValue: prevState.value }), function() {
      this.props.applyFilter({[this.props.name]: this.state.value ? '1' : null});
    });
  }

  handleCancel() {
    this.setState(prevState => ({ value: prevState.appliedValue }));
  }

  handleChange(event) {
    this.setState({value: event.target.checked});
  }

  render() {
    return(
      <FilterForm
        btnClass={this.props.btnClass}
        name={this.props.name}
        active={this.state.appliedValue}
        label={this.renderButtonLabel()}
        onApply={this.handleApply}
        onCancel={this.handleCancel}
      >
        { this.renderFilter() }
      </FilterForm>
    );
  }

  renderButtonLabel() {
    return this.state.appliedValue ? this.props.activeLabel : this.props.label;
  }

  renderFilter() {
    return (
      <label>
        <input type="checkbox" checked={this.state.value} onChange={this.handleChange}/> {this.props.checkboxLabel}
      </label>
    );
  }
};

CheckboxFilter.propTypes = {
  label: PropTypes.string.isRequired,
  activeLabel: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  applyFilter: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default CheckboxFilter;
