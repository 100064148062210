import React, { PureComponent } from 'react';
import { Icon } from './Icon';

export class IconRemove extends PureComponent {
  render() {
    return (
      <Icon title="Remove" className="icon-remove" {...this.props}>
        <rect fill={this.props.fillColor} x="6" y="11" width="10" height="1.5" rx="1" />
      </Icon>
    );
  }
}

export default IconRemove;
