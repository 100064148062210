import React from 'react';
import PaginatedTable from '@library/paginated-table';
import { TableFilters, useFiltering } from '@library/paginated-table/filtering';
import { useSorting } from '@library/paginated-table/sorting';
import OrvpReport from './orvp_report';
import parseQueryString from '@/helpers/parseQueryString';
import './styles';

function OrvpAdmin() {
  const { activeFilters, getActiveFilterValue, setActiveFilter, filterOptions, setFilterOptions } = useFiltering(
    [
      {
        name: 'Place',
        key: 'place_id',
        default: null,
        options: [{ name: 'All', id: null }],
      },
    ],
    parseQueryString()?.params || {}
  );


  const onTableUpdated = (data) => {
    const updatedFilters = [...filterOptions];

    const aggCplaces = {};
    const cplaces = data.map((datum) => {
      return {
        name: datum.place.name,
        id: datum.place.id,
      };
    }).filter((cplace) => aggCplaces[cplace.id] ? false : aggCplaces[cplace.id] = true );

    updatedFilters[2].options = [{ name: 'All', id: null }];
    updatedFilters[2].options.push(...cplaces);

    setFilterOptions(updatedFilters);
  };

  const onRowAction = (action: string, options: { [key: string]: string }) => {
    if (action == 'filter') {
      setActiveFilter(options.key, options.option_id);
    }
  };

  return (
    <div className="editor__orvp_report_manager">
      <TableFilters
        filterOptions={filterOptions}
        getActiveFilterValue={getActiveFilterValue}
        setActiveFilter={setActiveFilter}
      />
      <PaginatedTable
        onDataChanged={onTableUpdated}
        onRowAction={onRowAction}
        dataFilters={activeFilters}
        dataSort={null}
        Head={() => (
          <tr className="editor__orvp_report">
            <th>ID</th>
            <th>CPID</th>
            <th>Name</th>
            <th>City</th>
            <th>Parking Allowed</th>
            <th>User Stay Date</th>
            <th>Policy Enforced</th>
            <th>Policy Confirmed</th>
            <th>Report Date</th>
          </tr>
        )}
        Row={OrvpReport}
        url={'/orvp_reports.json'}
        limit={20}
      />
    </div>
  );
}

export default OrvpAdmin;
