import * as React from 'react';
import Sandbox from '../../component-explorer/Sandbox';
import Scenario from '../../component-explorer/Scenario';
import MultiSelect from '.';

export default function Examples(): any {
  return (
    <Sandbox name="MultiSelect">
      <Scenario description="More than one in view" code="">
        <MultiSelect
          labelId="animals"
          allowNewValues={false}
          placeholder=""
          persistChanges={() => { }}
          label="Animals"
          selected={[]}
          options={['cats', 'dogs', 'horses']}
        />
        <MultiSelect
          labelId="planets"
          allowNewValues={false}
          placeholder=""
          persistChanges={() => { }}
          label="Planets"
          selected={[]}
          options={['earth', 'mars', 'venus', 'saturn']}
        />
      </Scenario>
      <Scenario description="Allow new values" code="">
        <MultiSelect
          labelId="animals"
          allowNewValues={true}
          placeholder=""
          persistChanges={() => { }}
          label="Animals"
          selected={[]}
          options={['cats', 'dogs', 'horses']}
        />
      </Scenario>
      <Scenario description="with subtitles" code="">
        <MultiSelect
          labelId="places"
          allowNewValues={false}
          placeholder=""
          persistChanges={() => { }}
          label="Places"
          selected={[1]}
          options={[
            { id: 1, name: 'A Cool Place', subtitle: 'duh' },
            { id: 2, name: 'Dive', subtitle: `it's a bar` },
            { id: 3, name: 'Has Food', subtitle: 'ok food' },
            { id: 4, name: 'Has Food' }
          ]}
        />
      </Scenario>
    </Sandbox>
  );
}
