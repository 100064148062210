import React from 'react';

type Props = {
  externalLink?: string;
  internalLink?: string;
  useTab?: boolean;
  onClick?: (e?: React.SyntheticEvent) => void;
};

function useLink(props: Props){
  const href = props.externalLink || props.internalLink || '';

  const onClick = (e) => {
    if (props.onClick) props.onClick(e);
    if (props.externalLink) {
      window.open(props.externalLink, '_blank');
    } else if (props.internalLink) {
      if (e.metaKey || e.ctrlKey || props.useTab) {
        window.open(props.internalLink, '_blank');
      }
      // @ts-ignore
      else if (window.Turbolinks) {
        // @ts-ignore
        window.Turbolinks.visit(props.internalLink);
      } else {
        window.open(props.internalLink);
      }
    }
  };

  return {
    onClick,
    'data-href': href,
    'data-cy': "link",
    role: "link"
  }
}

export default useLink;
