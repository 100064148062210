import React from "react";
import Control from "./Control";
import cn from "classnames";
import BooleanRow from "@library/boolean-row/edit";
import ReactTooltip from "react-tooltip";
import useMirrorParent from "./hooks/useMirrorParent";
import useChildState from "./hooks/useChildState";
import useMirrorChild from "./hooks/useMirrorChild";
import useExpandedState from "./hooks/useExpandedState";
import "./styles.scss";

type Props = {
  key?: string;
  cy?: string;
  useAssinableDisabled: boolean;
  mirrorChild: boolean;
  mirrorParent: boolean;
  query?: string;
  ancestorMap: AncestorMap;
  selection: AssignmentMap;
  update: UpdateAssignmentMap;
} & DataNode;

function Tree(props: Props) {
  const [childAssigned, hasChildren] = useChildState(props);

  const [expanded, setExpanded] = useExpandedState({
    ...props,
    hasChildren,
    childAssigned,
  });

  const pm = useMirrorParent(props);

  // only runs an effect must be last
  const cm = useMirrorChild({
    ...props,
    hasChildren,
    childAssigned,
  });

  const disableBooleanRow = pm.disabled || cm.disabled || (props.useAssinableDisabled && !props.assignable)

  return (
    <div
      data-cy={`tree-${props.public_id}`}
      className="editor editor__search-tree__subtree tree"
    >
      <div
        className={cn({
          "tree-node": true,
          "editor__node--not-assignable":
            props.mirrorChild &&
            !props.assignable &&
            !cm.fauxAssign &&
            !props.selection[props.public_id],
          "editor__node--assigned": props.selection[props.public_id],
          "editor__node--faux-assign": pm.fauxAssign || cm.fauxAssign,
        })}
        key="tree-node"
      >
        <Control
          show={hasChildren}
          isExpanded={expanded}
          onCollapse={() => setExpanded(false)}
          onExpand={() => setExpanded(true)}
        />
        <span
          data-cy="node-label"
          className="tree-node-label"
          data-tip
          data-for={`cant-edit-${props.public_id}`}
          data-event="click"
        >
          <label className="selection-tree-node">
            <BooleanRow
              binary
              cy={`boolean-row-${props.public_id}`}
              label={props.name}
              disabled={disableBooleanRow}
              value={
                props.selection[props.public_id] ||
                pm.fauxAssign ||
                cm.fauxAssign
              }
              onChange={(value) => {
                props.update(props.public_id, value);
              }}
            />
          </label>
        </span>
      </div>

      {cm.showTooltip && (
        <ReactTooltip
          globalEventOff="click"
          id={`cant-edit-${props.public_id}`}
          multiline={true}
          delayShow={300}
          delayHide={500}
          effect="solid"
          type="error"
          place="right"
        >
          <p>cannot be assigned</p>
        </ReactTooltip>
      )}

      {hasChildren ? (
        <div
          data-cy={`children-${props.public_id}`}
          key="tree-children"
          className="tree-children"
        >
          {expanded
            ? props.children.map((tree) => (
                <Tree key={tree.public_id} {...props} {...tree} />
              ))
            : null}
        </div>
      ) : null}
    </div>
  );
}

const MemoTree = React.memo(Tree);

MemoTree.displayName = "Tree";
export default MemoTree;
