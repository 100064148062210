import React from "react";
import Input from "@library/input-with-label";
import Required from "./required";

export function FieldsInputsMapper({fields, state, updateField}) {
  return fields.map(([label, field, cn, type, required]) => (
    <Required ok={required}>
      <div key={field} className={"editor__form-field editor__" + cn}>
        <Input
          label={label}
          value={state[field]}
          type={type}
          onChange={(event) => updateField(event, field)}
        />
      </div>
    </Required>
  ));
}
