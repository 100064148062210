import React from "react";
import Section from "@library/section";
import useLink from "@library/link/useLink";
import Attributes from "@library/attributes-section/attributes";
import useStateMapper from "@library/attributes-section/hooks/state-mapper";
import useDefinitionsIndex from "@library/attributes-section/hooks/definitions-index";
import cloneDeep from "lodash/cloneDeep";
import "./styles";

function Details(props) {
  const link = useLink({
    internalLink: document.location.pathname + "#attributes",
  });

  const section = props.attributes.sections.find(
    (section) => section.mostRelevant
  );

  const index = useDefinitionsIndex(section.attributes);
  const [_saved, _setSaved, savedAttributes] = useStateMapper(
    index,
    cloneDeep(section.data)
  );

  return (
    <div data-cy="dashboard-details" className="editor__dashboard__details">
      <Section
        header={
          <h4 {...link}>
            <span>{section.sectionTitle}</span>
          </h4>
        }
      >
        <Attributes
          definitions={savedAttributes.map((s) => ({
            ...s,
            hideNull: true,
            description: null
          }))}
          editing={false}
        />
      </Section>
    </div>
  );
}

export default Details;
