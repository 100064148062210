import React from 'react';
import ContactSection from '@/data-outreach/_core/forms/components/contact';

function ServiceCenterContact({ state, updateField, serviceCenter }){
  return (
    <ContactSection
      state={state}
      updateField={updateField}
      fields={[
        ['Phone', 'service_phone', 'tel', 'phone'],
        ['Email', 'service_email', 'email', 'email'],
        ['Website', 'service_website', 'url', 'website']
      ]}
      title={serviceCenter ? 'RV Service Center' : 'Contact'}
    />
  );
}

export default ServiceCenterContact;
