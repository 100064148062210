import React from 'react';


export default function Indicator(props){
    const spectrum = {
        red: '#ff0000',
        yellow: '#ffd700',
        green: '#01ff00',
        blue: '#00e5ff'
    };

    const getColor = (value) =>{
        if(value <= 25){
            return spectrum.red;
        }
        if(value > 26 && value < 51){
            return spectrum.yellow;
        }
        if(value > 50 && value < 76){
            return spectrum.green;
        }
        if(value > 75 && value <= 100){
            return spectrum.blue;
        }
    };

    return(
        <div className="__indicator">
            <div className="__header">Score</div>
            <div className="__value" style={{color:getColor(props.discoverability)}}>{props.discoverability}</div>
        </div>
    )
}