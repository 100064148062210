import React from 'react';
import copy from '../_form/copy';
import { useContext } from '@/data-outreach/_core/forms/useContext';

type Context = {
  state?: {
    name?: boolean;
    mobile_service?: boolean;
    service_center?: boolean;
  };
  save?: (state: any) => Promise<boolean>;
};

function useSteps() {
  const ctx: Context = useContext();

  return [
    {
      title: '',
      message: copy.introduction,
      showStep: true,
      hideBack: true,
      hideNext: false
    },
    {
      title: 'Service Areas',
      message: copy.service_areas,
      showStep: true,
      hideBack: false,
      hideNext: false
    },
    {
      title: 'Listing Information',
      message: copy.listing_information,
      showStep: true,
      hideBack: false,
      hideNext: false
    },
    {
      title: 'Customer Contact',
      message: copy.customer_contact,
      showStep: true,
      hideBack: false,
      hideNext: false
    },
    {
      title: 'Service Options',
      message: copy.publicDataDisclaimer,
      hideBack: false,
      hideNext: false,
      showStep: ctx?.state?.mobile_service || ctx?.state?.service_center
    },
    {
      title: 'Notes',
      message: copy.notes,
      showStep: true,
      nextButtonText: 'Submit',
      hideBack: false,
      hideNext: false
    },
    {
      showStep: true,
      hideBack: false,
      hideNext: true,
      save: ctx.save
    }
  ];
}

export default useSteps;
