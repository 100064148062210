import React from 'react';
import SalesContact from './rv-sales';
import StorageContact from './rv-storage';
import MobileServiceContact from './mobile-service';
import ServiceCenterContact from './service-center';
import RentalContact from './rv-rental';
import type { UpdateField, State } from '../../../typings';
import '../styles';

type Props = {
  state: State;
  updateField: UpdateField;
};

function PublicContacts(props: Props) {
  return (
    <div className="editor__data-outreach__rv-service__contacts editor__data-outreach__rv-service__contacts--public">
      <ServiceCenterContact
        updateField={props.updateField}
        state={props.state}
        serviceCenter={props.state.service_center}
      />
      <>
        <br />
        {props.state.sales || props.state.storage || props.state.mobile_service || props.state.rental ? (
          <div className="editor__info">{`Only fill out the following if it differs from your ${
            props.state.service_center ? 'service center contact' : 'primary contact'
          }.`}</div>
        ) : null}
      </>
      <MobileServiceContact updateField={props.updateField} state={props.state} show={props.state.mobile_service} />
      <SalesContact updateField={props.updateField} state={props.state} show={props.state.sales} />
      <StorageContact updateField={props.updateField} state={props.state} show={props.state.storage} />
      <RentalContact updateField={props.updateField} state={props.state} show={props.state.rental} />
    </div>
  );
}

export default PublicContacts;
