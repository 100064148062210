import React from 'react';
import { useWizardContext } from '../useWizardContext';
import { useTransition, animated } from 'react-spring';
import './styles/index';

const Option = ({ style, children }) => (
  <animated.div
    className="editor__wizard__step__child"
    style={{
      ...style
    }}
  >
    {children}
  </animated.div>
);

function Wizard({ children }){
  const wz = useWizardContext();
  const transitions = useTransition(wz.index || 0, (p) => p, {
    unique: true,
    from: { opacity: 0 },
    enter:
      {
        opacity: 1,
        width: '100%'
      },
    leave:
      {
        display: 'none'
      }
  });

  return (
    <div className="editor__wizard__step">
      {transitions.map(({ item, props, key }) => {
        return wz.step.showStep ? (
          <Option key={key} style={props}>
            {children[item]}
          </Option>
        ) : null;
      })}
    </div>
  );
}

export default Wizard;
