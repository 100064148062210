import React, { PureComponent } from 'react';
import BooneCard, { BooneCardBody } from './Card';
import PropTypes from 'prop-types';

export default class Content extends PureComponent {
  render() {
    return (
      <div id="content" className={`${this.props.className + ' '} row justify-content-center`}>
        <div id="content-container" className="col-12 col-md-9 col-lg-8 mb-5">
          <BooneCard>
            <BooneCardBody>{this.props.children}</BooneCardBody>
          </BooneCard>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};
