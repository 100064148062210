import React from 'react';

export default class SocialNetworkSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { network: this.props.network };
  }

  onValueChanged = newValue => {
    this.setState({ network: newValue });
    this.props.onNetworkChanged(newValue);
  };

  render() {
    return (
      <select
        id={'network-' + this.props.social_handle_id}
        value={this.state.network}
        className="editor__select__component"
        onChange={e => this.onValueChanged(e.target.value)}
      >
        <option value="Facebook">Facebook</option>
        <option value="Instagram">Instagram</option>
        <option value="TripAdvisor">TripAdvisor</option>
        <option value="YouTube">YouTube</option>
        <option value="Zomato">Zomato</option>
        <option value="Yelp">Yelp</option>
        <option value="OpenTable">OpenTable</option>
        <option value="Twitter">Twitter</option>
        <option value="KOA">KOA</option>
      </select>
    );
  }
}
