
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import BooleanRow from './edit';
import ErrorBoundary from '@library/ErrorBoundary'
export const Example = props => {
  const [v, setV] = React.useState(props.initialValue);
  return (//@ts-expect-error
    <div className='editor__component-explorer__example'>
      <ErrorBoundary mdxType="ErrorBoundary">
        <BooleanRow {...props} label={v === true && 'True' || v === false && 'False' || v === null && 'Null' || v === undefined && 'Undefined'} value={v} onChange={setV} mdxType="BooleanRow" />
      </ErrorBoundary>
    </div>
  );
};

const layoutProps = {
  Example
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Boolean Row`}</h2>
    <h4>{`Component`}</h4>

    <h3>{`All States`}</h3>
    <Example binary={false} initialValue={true} mdxType="Example" />
    <Example binary={false} initialValue={false} mdxType="Example" />
    <Example binary={false} initialValue={null} mdxType="Example" />
    <Example binary={false} mdxType="Example" />
    <h4>{`Tri State`}</h4>
    <Example binary={false} initialValue={null} mdxType="Example" />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<BooleanRow
  binary={false}
  label={label}
  value={value}
  onChange={onChange}
/>
`}</code></pre>
    <hr></hr>
    <h4>{`Binary State: T/F`}</h4>
    <Example binary initialValue={false} mdxType="Example" />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<BooleanRow
  binary
  {...props}
/>
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;