import URL from 'url-parse';
const NETWORKS = {
  Facebook: {
    url: 'https://facebook.com/{handle}'
    // Could be used in the future imageUrl: 'https://graph.facebook.com/{handle}/picture',
  },
  TripAdvisor: {
    url: 'https://tripadvisor.com/{handle}'
  }
};

const generateUrl = function(templateKey, network, handle) {
  let url = '';
  if (NETWORKS[network]) {
    url = (NETWORKS[network][templateKey] || '').replace('{handle}', handle);
  }
  return url;
};

export class SocialHandlePresenter {
  constructor(socialHandle /*or socialHandleSuggestion*/) {
    this.socialHandle = socialHandle;
  }

  get network() {
    return this.socialHandle.network;
  }

  get handle() {
    let handle = this.socialHandle.handle;
    if (!handle && this.socialHandle.url) {
      // TODO: Parse the url for the handle...
    }
    return handle;
  }

  get url() {
    let url = this.socialHandle.url;
    if ((url === null || url === undefined) && this.socialHandle.handle) {
      url = generateUrl('url', this.network, this.socialHandle.handle);
    }
    return url;
  }

  get displayUrl() {
    if (!this.url) {
      return this.url;
    }
    let url = new URL(this.url);
    let hostname = url.hostname;
    if (hostname.startsWith('www.')) {
      hostname = hostname.slice(4);
    }
    let displayUrl = hostname + url.pathname;
    // This library adds our host name if none was specified in the original url
    if (this.url.indexOf(displayUrl) === -1) {
      displayUrl = this.url;
    }
    return displayUrl;
  }

  urlMatchesNetwork(network) {
    let doesMatch = false;
    if (this.url) {
      doesMatch = this.url.toLowerCase().includes(network.toLowerCase());
    }
    return doesMatch;
  }
}
