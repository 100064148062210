import React from 'react';
import FilterForm from "@/library/filter-form";
import "./styles";

const TextField = (props) => {
  const [value, setValue] = React.useState(props.data.value);
  const [applied, setApplied] = React.useState(props.data.value != null);

  const active = (value != null);

  const label = () => {
    var lbl = props.data.label;

    if (applied) {
      lbl += `: ${value}`
    }
    return lbl;
  }

  const handleApply = () => {
    setApplied(value != null)
    props.applyFilter({
      [props.data.name]: value
    });
  }

  const handleChange = (value) => {
    setValue(value)
  }

  const handleClear = () => {
    setApplied(false)
    setValue(null);
    props.applyFilter({
      [props.data.name]: null
    });
  }

  const renderFilter = () => {
    return (
      <div className="editor__filters__textfield">
        <div className="editor__filters__textfield--row">
          <input type="text" onChange={(e) => handleChange(e.target.value)} value={value}/>
        </div>
      </div>
    );
  }

  return(
    <FilterForm
      name={`TextField-${props.data.name}`}
      cy={props.data.name}
      onCancel={()=>{}}
      active={active}
      label={label()}
      onApply={() => handleApply()}
      onClear={() => handleClear()}
      hideButtons={false}
    >
      { renderFilter() }
    </FilterForm>
  );
};

export default TextField;
