
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import Corrections from './index';
import Correction from './correction'
import './styles';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h3>{`Correction`}</h3>
    <table className='editor__corrections__manager'>
  <tbody>
    <Correction id='20' issue_type='correction' canonical_place={{
          id: 28998383,
          name: "Roy's Diner"
        }} updated_at="123333334" created_at="123333333" state="pending" ip_address='127.0.0.1' mdxType="Correction" />
  </tbody>
    </table>
    <h3>{`Corrections Manager`}</h3>
    <Corrections data={[{
      id: 20,
      issue_type: 'correction',
      ip_address: '127.0.0.1',
      updated_at: '123333334',
      created_at: '123333333',
      state: 'pending',
      canonical_place: {
        id: 28998383,
        name: "Roy's Diner"
      },
      args: 'test test test'
    }, {
      id: 21,
      issue_type: 'correction',
      ip_address: '127.0.0.1',
      updated_at: '123333334',
      created_at: '123333333',
      state: 'pending',
      canonical_place: {
        id: 28998384,
        name: "Snow Park"
      },
      args: 'test test test'
    }]} url={'/corrections.json'} limit={20} mdxType="Corrections" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;