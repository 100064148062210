import React, {PureComponent} from "react";
import withAuditApi from "../hoc/withAuditApi";
import AuditAssignmentDropdown from "./AuditAssignmentDropdown";
import type {Props as AuditDropdownProps} from "./AuditAssignmentDropdown";

import Assignment from "./Assignment";
import AssignmentPortal from "./AssignmentPortal";
import * as util from "./util";
import * as helpers from "../helpers/index";
import cn from "classnames";

export type Props = {
  mountAssignmentPortal: boolean;
  audit?: {
    id: number;
    canonical_place_id: number;
    user_id: number;
  };
  updateAudit: (user_id: number) => void;
  createAudit: (user_id: number) => void;
  admin: boolean;
  showUsersDropDown?: boolean;
  removeAudit: () => void;
} & Exclude<
  AuditDropdownProps,
  | "onRemoveClick"
  | "onUserClick"
  | "onAnyoneClick"
  | "Label"
  | "assignedUserId"
  | "id"
  | "hasAudit"
  | "onClick"
>;

export class ListingAuditLink extends PureComponent<
  Props,
  {anchor: HTMLElement | null}
> {
  state = {
    anchor: null,
  };

  static defaultProps = {
    showUsersDropDown: true,
    mountAssignmentPortal: false,
    hideLastName: true,
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.props.mountAssignmentPortal) {
      if (!nextState.anchor) {
        const anchor = document.getElementById(
          "audit-assignment-portal-anchor"
        );
        if (anchor) {
          if (anchor.hasChildNodes()) {
            anchor.innerHTML = "";
          }
          this.setState({anchor});
        }
      }
    }
  }

  render() {
    const {
      audit,
      users,
      className,
      updateAudit,
      createAudit,
      hideLastName,
      ...props
    } = this.props;
    const update = (user_id) => updateAudit(user_id);
    const create = (user_id) => createAudit(user_id);

    const {hasAudit, assignedUser} = util.assignment(audit, users);
    const onClick = hasAudit ? update : create;

    const assignedUserName = assignedUser
      ? hideLastName
        ? helpers.getFirstName(assignedUser.name)
        : assignedUser.name
      : "Anyone";

    const Label = hasAudit
      ? () => (
          <Assignment
            booneLogo={!audit.user_id}
            name={assignedUserName}
            url={assignedUser.avatar_url}
          />
        )
      : () => (
          <div
            className={cn({
              "audit-assignment": true,
              "link-super-admin": this.props.admin,
            })}
          >
            Assign audit
          </div>
        );

    return (
      <React.Fragment>
        <div
          data-cy="listing-audit-link"
          className={cn(["audit__dropdown", className])}
        >
          {this.props.showUsersDropDown && (
            <AuditAssignmentDropdown
              closeParentDropdown={this.props.closeParentDropdown}
              isNestedDropdown={this.props.isNestedDropdown}
              showOnHover={this.props.showOnHover}
              hasAudit={hasAudit}
              id={hasAudit && audit.id}
              assignedUserId={hasAudit && audit.user_id ? audit.user_id : null}
              onUserClick={onClick}
              onAnyoneClick={onClick}
              onRemoveClick={props.removeAudit}
              users={users}
              toggleIcon={props.toggleIcon}
              Label={Label}
              hideLastName={hideLastName}
            />
          )}
        </div>
        {this.props.mountAssignmentPortal && hasAudit && (
          <AssignmentPortal
            anchor={this.state.anchor}
            show={hasAudit}
            booneLogo={!audit.user_id}
            name={assignedUserName}
            url={assignedUser && assignedUser.avatar_url}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withAuditApi(ListingAuditLink);
