import React from "react";
import cn from "classnames";

function Checkbox(props: {
  state: "false" | "null" | "true";
  children?: any;
  disabled?: boolean;
}) {
  return (
    <span
      className={cn({
        "editor__boolean-row__checkbox": true,
        [`editor__boolean-row__checkbox--${props.state}`]: true,
        [`editor__boolean-row__checkbox--disabled`]: props.disabled,
      })}
      data-cy={"boolean-row-" + props.state}
    >
      {props.children}
    </span>
  );
}

export default Checkbox;
