import React from 'react';

function AlertIcon(){
  return (
    <span className="editor__data__missing">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="16"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill="#8f3025"
          d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
        />
      </svg>
    </span>
  );
}

export default AlertIcon;
