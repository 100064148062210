
export const createTreeList = (nodes) => {

  const root = [];   // root(s)
  const map = {};   // Cache cache parent index in nodes array

  nodes.forEach((node) => {
    // No parent so add to root array
    if (!node.parent_public_id) return root.push(node);

    let parentIndex = map[node.parent_public_id];
    if (typeof parentIndex !== "number") {
      parentIndex = nodes.findIndex(t => t.public_id === node.parent_public_id);
      map[node.parent_public_id] = parentIndex;
    }

    if (!nodes[parentIndex].children) {
      return nodes[parentIndex].children = [node];
    }

    nodes[parentIndex].children.push(node);
  });

  return root;
};

