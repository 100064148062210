import React from 'react';

import { TableFilters, useFiltering } from '@library/paginated-table/filtering';
import parseQueryString from '@/helpers/parseQueryString';
import constructQueryString from '@/helpers/constructQueryString';


function IndexFilters(props) {
  var options = [{ name: 'All', id: null }];

   if (props.sources) {
     options = options.concat(props.sources.map( (s) => {
       return {name: s.name, id: s.id}
     } ));
   }

  const { activeFilters, getActiveFilterValue, setActiveFilter, filterOptions, setFilterOptions } = useFiltering(
    [
      {
        name: 'Source',
        key: 'source_id',
        default: null,
        options: options
      },
    ],
    parseQueryString()?.params || {}
  );

  const setFilter = (k,v) => {
     setActiveFilter(k,v)
     const params = parseQueryString()?.params || {}
     Object.assign(params, {[k]: v})
     document.location.search = constructQueryString(params)
  }

  return (
    <div className="editor__corrections__manager">
      <TableFilters
        filterOptions={filterOptions}
        getActiveFilterValue={getActiveFilterValue}
        setActiveFilter={setFilter}
      />
    </div>
  );
}

export default IndexFilters;
