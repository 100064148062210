import * as React from "react";
import * as ajax from "@/helpers/ajax";
import {ModalBody, Modal} from "reactstrap";
import EditField from "./EditField";
import {PrimaryButton} from "../library/Buttons";
import Label from "../library/label";

type Props = {
  organizationId: number;
  name: string;
  description: string;
};

type State = {
  name: string;
  description: string;
  showSecret: boolean;
  clientId: string;
  clientSecret: string;
};

export default class CreateAppKeyModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.handleSubmitAppKey = this.handleSubmitAppKey.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGotIt = this.handleGotIt.bind(this);
  }

  state = {
    name: this.props.name,
    description: this.props.description,
    showSecret: false,
    clientId: "",
    clientSecret: "",
  };

  handleChange(e) {
    const key = e.target.id;
    const val = e.target.value;
    // @ts-ignore @TODO: figure out why ts is interpreting id as type number resulting a failure to compile
    this.setState({[key]: val});
  }

  handleGotIt() {
    // @ts-ignore
    window.location.pathname =
      "/api_organizations/" + this.props.organizationId;
  }

  async handleSubmitAppKey(e) {
    e.preventDefault();

    const params = {
      app_key: {
        name: this.state.name,
        description: this.state.description,
        organization_id: this.props.organizationId,
      },
    };

    const response = await ajax.post("/app_keys", params);
    if (response.ok) {
      const appKey = await response.json();

      this.setState({
        clientId: appKey.client_id,
        clientSecret: appKey.client_secret,
        showSecret: true,
      });
    }

    $("#create-app-key-modal").modal("hide");
  }

  toggleSecretModal() {
    this.setState((prevState) => ({
      showSecret: !prevState.showSecret,
    }));
  }

  renderSecretModal() {
    return (
      <Modal
        toggle={() => this.toggleSecretModal()}
        isOpen={this.state.showSecret}
      >
        <div className="modal-header">
          <h3 className="modal-title">App Key was successfully created</h3>
        </div>
        <ModalBody>
          <div className="container">
            <EditField
              label="Client ID"
              field="clientId"
              labelClass="col-sm-3 col-form-label"
              value={this.state.clientId || ""}
              required={true}
              readOnly={true}
            />
            <EditField
              label="Client Secret"
              field="clientSecret"
              labelClass="col-sm-3 col-form-label"
              value={this.state.clientSecret || ""}
              required={true}
              readOnly={true}
            />
            <Label
              className="color-warning font-weight-bold"
              text="You will only see this secret once!"
            />
            <div className="justify-content-end text-right">
              <PrimaryButton type="submit" onClick={this.handleGotIt}>
                Ok got it
              </PrimaryButton>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    return (
      <>
        <div
          className="modal fade"
          id="create-app-key-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="invite-user"
          aria-hidden="true"
        >
          <div
            className="modal-dialog-centered modal-dialog modal-lg card"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Create a new app key</h3>
              </div>
              <ModalBody>
                <form onSubmit={this.handleSubmitAppKey}>
                  <div className="container">
                    <EditField
                      label="Name"
                      field="name"
                      placeholder="Visit Gotham"
                      labelClass="col-sm-3 col-form-label"
                      onChange={this.handleChange}
                      value={this.state.name || ""}
                      required={true}
                    />
                    <EditField
                      label="Description"
                      field="description"
                      placeholder="App key for Visit Gotham"
                      labelClass="col-sm-3 col-form-label"
                      onChange={this.handleChange}
                      value={this.state.description || ""}
                      required={true}
                    />
                    <div className="justify-content-end text-right">
                      <PrimaryButton type="submit">
                        Create app key
                      </PrimaryButton>
                    </div>
                  </div>
                </form>
              </ModalBody>
            </div>
          </div>
        </div>
        {this.renderSecretModal()}
      </>
    );
  }
}
