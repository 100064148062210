import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterForm from "@/library/filter-form";
import AuditAssignmentDropdown from './AuditAssignmentDropdown';
import AssignUserOption from '@library/dropdown/options/AssignUserOption';
import CheckboxWithLabel from '@library/CheckboxWithLabel';
import Select from '@library/select';

const AuditsFilter = (props) => {
  const [errors, setErrors] = React.useState({date: null})

  const [audited, setAudited] = React.useState(props.data.audited)
  const [notAudited, setNotAudited] = React.useState(props.data.notAudited)

  const [startDateOffset, setStartDateOffset] = React.useState(props.data.startDateOffset || false)
  const [endDateOffset, setEndDateOffset] = React.useState(props.data.endDateOffset || false)

  const [validated, setValidated] = React.useState(props.data.auditTypes.includes('validated'))
  const [duplicates, setDuplicates] = React.useState(props.data.auditTypes.includes('resolved_duplicates'))
  const [generalUpdates, setGeneralUpdates] = React.useState(props.data.auditTypes.includes('general_update'))
  const [contactUpdates, setContactUpdates] = React.useState(props.data.auditTypes.includes('contact_update'))
  const [noteAdded, setNoteAdded] = React.useState(props.data.noteAdded)

  const active = audited || notAudited

  const offsetToDateString = (offset) => {
    const now = new Date()
    const date = new Date(now)
    date.setDate(date.getDate() - offset)
    return date.toISOString()
  }

  const handleApply = () => {
    /**
     * Filter states:
     *  - audited ~ `/listings?audited=1`
     *  - not audited ~ `/listings?audited=0`
     *  - date range ~ `/listings?audit_date_range_start=&audit_date_range_end`
     **/

    if (!(audited || notAudited)) {
      props.applyFilter({audited: null})
    } else if (audited) {
      props.applyFilter({audited: "1"})
    } else if (notAudited) {
      props.applyFilter({audited: "0"})
    }

    const auditTypes = {
      validated: validated,
      resolved_duplicates: duplicates,
      general_update: generalUpdates,
      contact_update: contactUpdates,
    }

    const typeFilters = Object.keys(auditTypes).filter((type) => auditTypes[type])

    if (typeFilters.length > 0) {
      props.applyFilter({audit_types: typeFilters.join(',')})
    } else {
      props.applyFilter({audit_types: null})
    }

    if (noteAdded) {
      props.applyFilter({include_note_only_audits: "1"})
    } else {
      props.applyFilter({include_note_only_audits: null})
    }

    if (startDateOffset) {
      props.applyFilter({audit_date_offset_start: startDateOffset})
      props.applyFilter({audit_date_range_start: offsetToDateString(startDateOffset)})
    } else {
      props.applyFilter({audit_date_offset_start: null})
      props.applyFilter({audit_date_range_start: null})
    }
    if (endDateOffset) {
      props.applyFilter({audit_date_offset_end: endDateOffset})
      props.applyFilter({audit_date_range_end: offsetToDateString(endDateOffset)})
    } else {
      props.applyFilter({audit_date_offset_end: null})
      props.applyFilter({audit_date_range_end: null})
    }
  }

  const toggleAudited = () => {
    setAudited(!audited)
    if (!audited) {
      setNotAudited(false)
    }
  }

  const toggleNotAudited = () => {
    setNotAudited(!notAudited)
    if (!notAudited) {
      setAudited(false)
    }
  }

  const dateRangeOptions = (ranges, labels) => {
    const now = new Date()

    const options = ranges.map((option, idx) => [labels[idx], option])

    options.unshift(['Any', false])

    return options
  }

  React.useEffect(() => {
    if ((startDateOffset || endDateOffset) && startDateOffset <= endDateOffset) {
      setErrors({...errors, date: "Bad Date Range"})
    } else {
      setErrors(null)
    }
  }, [startDateOffset, endDateOffset])

  let filterLabel = props.label;

  if (audited) {
    filterLabel = "Having Audits"
  }

  if (notAudited) {
    filterLabel = "No Audits"
  }

  const startDateOptions = dateRangeOptions(
    [7,30,60,90,180,270,356],
    ["1 week ago", "30 days ago", "60 days ago", "90 days ago", "180 days ago", "1 year ago"]
  )

  const endDateOptions = dateRangeOptions(
    [7,30,60,90,180,270,356,712],
    ["1 week ago", "30 days ago", "60 days ago", "90 days ago", "180 days ago", "1 year ago", "2 years ago"]
  )

  return (
    <FilterForm
      btnClass={props.btnClass}
      name={props.type}
      active={active}
      label={filterLabel}
      onApply={() => handleApply()}
    >
      <div className="audit-filter audit-filter__container">
        <div className="audit-filter__col">
          <div className="audit-filter__input">
            <CheckboxWithLabel
              labelClassName="audit-filter audit-filter__input-label"
              labelId="audit-filter-checkbox"
              label="Listings having audit"
              onChange={toggleAudited}
              checked={Boolean(audited)}
            />
          </div>
          <div className="audit-filter__input">
            <CheckboxWithLabel
              labelClassName="audit-filter audit-filter__input-label"
              labelId="audit-filter-checkbox"
              label="Listings without audits"
              onChange={toggleNotAudited}
              checked={Boolean(notAudited)}
            />
          </div>
        </div>
        <div className="audit-filter__col">
          <div className="audit-filter__input-label">
            <strong>Filter by date created</strong>
          </div>
          { errors?.date && (
            <div className="audit-filter__input-label">
              <span>{errors.date}</span>
            </div>
          )}
          <div className="audit-filter__row">
            <div className="audit-filter__input">
              <Select
                value={startDateOffset}
                label="After"
                onChange={(v) => setStartDateOffset(v)}
                options={startDateOptions.map((option) => ({
                  name: option[0],
                  id: option[1]
                }))}
              />
            </div>
            <div className="audit-filter__input">
              <Select
                value={endDateOffset}
                label="Before"
                onChange={(v) => setEndDateOffset(v)}
                options={endDateOptions.map((option) => ({
                  name: option[0],
                  id: option[1]
                }))}
              />
            </div>
          </div>
        </div>
        <div className="audit-filter__row">
          <div className="audit-filter__col">
            <div className="audit-filter__input-label">
              <strong>Filter by Audit Types</strong>
            </div>
            <div className="audit-filter__input">
              <CheckboxWithLabel
                labelClassName="audit-filter audit-filter__input-label"
                labelId="audit-filter-checkbox"
                label="Validated"
                onChange={() => setValidated(!validated)}
                checked={Boolean(validated)}
              />
            </div>
            <div className="audit-filter__input">
              <CheckboxWithLabel
                labelClassName="audit-filter audit-filter__input-label"
                labelId="audit-filter-checkbox"
                label="Duplicates"
                onChange={() => setDuplicates(!duplicates)}
                checked={Boolean(duplicates)}
              />
            </div>
            <div className="audit-filter__input">
              <CheckboxWithLabel
                labelClassName="audit-filter audit-filter__input-label"
                labelId="audit-filter-checkbox"
                label="General Updates"
                onChange={() => setGeneralUpdates(!generalUpdates)}
                checked={Boolean(generalUpdates)}
              />
            </div>
            <div className="audit-filter__input">
              <CheckboxWithLabel
                labelClassName="audit-filter audit-filter__input-label"
                labelId="audit-filter-checkbox"
                label="Contact Updates"
                onChange={() => setContactUpdates(!contactUpdates)}
                checked={Boolean(contactUpdates)}
              />
            </div>
            <div className="audit-filter__input">
              <CheckboxWithLabel
                labelClassName="audit-filter audit-filter__input-label"
                labelId="audit-filter-checkbox"
                label="Note Added"
                onChange={() => setNoteAdded(!noteAdded)}
                checked={Boolean(noteAdded)}
              />
            </div>
          </div>
        </div>
      </div>
    </FilterForm>
  );
}

export default AuditsFilter;
