import React from 'react'
import { Defaults, Props } from './Types'

export const Dashboard = ({...props}: Props) => {
  return (
    <div className="dashboard">
    <div className="dashboard__row dashboard__row--header">
      <span className="dashboard__column dashboard__column--label">Status</span>
      <span className="dashboard__column dashboard__column--label">Old Matches</span>
      <span className="dashboard__column dashboard__column--label">New Matches</span>
      <span className="dashboard__column dashboard__column--label">New Canonical Places</span>
      <span className="dashboard__column dashboard__column--label">Canonical Place Merges</span>
    </div>
    <div className="dashboard__row dashboard__row--body">
      <span className="dashboard__column dashboard__column--content">
        <span className="dashboard__cel dashboard__cel--md">
          {(props.status && props.status.charAt(0).toUpperCase() + props.status.slice(1)) ?? Defaults.statusUnavailable}
        </span>
      </span>
      <span className="dashboard__column dashboard__column--content">
        <span className="dashboard__cel dashboard__cel--lg">
          {props.old_match_count ?? Defaults.countUnavailable}
        </span>
      </span>
      <span className="dashboard__column dashboard__column--content">
        <span className="dashboard__cel  dashboard__cel--lg">
          {props.new_match_count ?? Defaults.countUnavailable}
        </span>
      </span>
      <span className="dashboard__column dashboard__column--content">
        <span className="dashboard__cel  dashboard__cel--lg">
          {props.new_canonical_place_count ?? Defaults.countUnavailable}
        </span>
      </span>
      <span className="dashboard__column dashboard__column--content">
        <span className="dashboard__cel  dashboard__cel--lg">
          {props.canonical_place_merge_count ?? Defaults.countUnavailable}
        </span>
      </span>
    </div>
  </div>
  )
}
