import React from 'react';
import * as ajax from '@/helpers/ajax';
import uniqWith from 'lodash/uniqWith';
import DataSection from '../../../library/data-section';
import Edit from './edit';
import View from './view';
import './styles/index';

type Props = {
  statusPath: string;
  hoursMayVary?: boolean;
  temporaryClosures?: {
    start_date: string;
    end_date: string;
    reason: string;
  }[];
  mayRequireAppointment?: boolean;
  permanentlyClosed?: boolean;
  userEditingStrategy: 'update' | 'suggest' | 'none';
  status?: {
    warnings?: string[];
    reason?: string;
    operating_normally?: boolean;
    changes_at?: string;
  };
};

const Closures = function(props: Props){
  // User cannot update status directly
  const [status, setStatus] = React.useState(props.status);

  const [temporaryClosures, setTemporaryClosures] = React.useState(
    props.temporaryClosures
  );
  const [hoursMayVary, setHoursMayVary] = React.useState(props.hoursMayVary);
  const [mayRequireAppointment, setMayRequireAppointment] = React.useState(
    props.mayRequireAppointment
  );
  const [permanentlyClosed, setPermanentlyClosed] = React.useState(
    props.permanentlyClosed
  );
  const [requestingStatus, setRequestingStatus] = React.useState(false);
  const [savedState, setSavedState] = React.useState({
    temporaryClosures,
    hoursMayVary,
    mayRequireAppointment,
    permanentlyClosed
  });

  const compare = (a, b) =>
    a.start_date === b.start_date &&
    a.end_date === b.end_date &&
    a.reason === b.reason;

  const updateTemporaryClosures = (v) => {
    setTemporaryClosures(uniqWith(v, compare));
  };

  return (
    <DataSection
      sectionTitle="Status"
      cyEdit="closures-edit"
      cyView="closures-view"
      viewComponent={<View {...savedState} status={status} />}
      editComponent={
        <Edit
          temporaryClosures={temporaryClosures}
          setTemporaryClosures={(v) => updateTemporaryClosures(v)}
          permanentlyClosed={permanentlyClosed}
          setPermanentlyClosed={(v) => setPermanentlyClosed(v)}
          hoursMayVary={hoursMayVary}
          setHoursMayVary={(v) => setHoursMayVary(v)}
          mayRequireAppointment={mayRequireAppointment}
          setMayRequireAppointment={(v) => setMayRequireAppointment(v)}
        />
      }
      params={{
        listing:
          {
            temporary_closures: temporaryClosures,
            details:
              {
                hours_may_vary: hoursMayVary,
                may_require_appointment: mayRequireAppointment,
                permanently_closed: permanentlyClosed
              }
          }
      }}
      reset={() => {
        setTemporaryClosures(savedState.temporaryClosures);
        setHoursMayVary(savedState.hoursMayVary);
        setMayRequireAppointment(savedState.mayRequireAppointment);
        setPermanentlyClosed(savedState.permanentlyClosed);
      }}
      userEditingStrategy={props.userEditingStrategy}
      onSuccess={() => {
        setSavedState({
          ...savedState,
          hoursMayVary,
          mayRequireAppointment,
          temporaryClosures,
          permanentlyClosed
        });

        if (!requestingStatus) {
          setRequestingStatus(true);
          ajax.get(props.statusPath).then((response) => {
            if (response.ok) {
              response.json().then((val) => {
                setStatus(val);
              });
            } else {
              console.error('Could not get ' + props.statusPath);
            }
          });
          setRequestingStatus(false);
        }
      }}
    />
  );
};

export default Closures;
