import React from 'react';
import PropTypes from 'prop-types';
import {
  IconFeaturedMedia,
  IconFeaturedMediaHover,
  IconUnfeaturedMedia,
  IconUnfeaturedMediaHover,
  IconFeaturedImageBadge
} from '../icons/IconFeaturedMedia';
import { IconDeleteMedia, IconDeleteMediaHover } from '../icons/IconDelete';
import { IconUploadMedia, IconUploadMediaHover } from '../icons/IconUploadMedia';
import { IconZoom, IconZoomHover } from '../icons/IconZoom';
import { IconPlay, IconPlayHover } from '../icons/IconPlay';
import cn from 'classnames';
import { IconShowMedia, IconShowMediaHover } from '../icons/IconShowMedia';

const Hover = ({ hoverContents, children }) => (
  <div className="hover">
    <div className="hover__no-hover">{children}</div>
    <div className="hover__hover">{hoverContents}</div>
  </div>
);

Hover.propTypes = {
  hoverContents: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired
};

class Media extends React.Component {
  render() {
    return (
      <div>
        <div className={this.props.className} onClick={this.handleClick}>
          <img src={this.props.imageUrl} className="rounded" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          {this.props.canFeatureMedia ? this.renderFeaturedOverlay() : this.props.renderOverlay()}
        </div>
        {this.props.featured && this.props.canFeatureMedia ? <IconFeaturedImageBadge /> : null}
      </div>
    );
  }

  renderFeaturedOverlay() {
    return (
      <div className="overlay-2-buttons">
        <div
          className="overlay-icon featured-media-toggle float-left ml-2"
          onClick={this.handleToggleFeatured}
          data-featured={this.props.featured}
        >
          {this.props.featured ? (
            <Hover hoverContents={<IconFeaturedMediaHover viewBoxX="-6" viewBoxY="-5" />}>
              <IconFeaturedMedia viewBoxX="-6" viewBoxY="-5" />
            </Hover>
          ) : (
            <Hover hoverContents={<IconUnfeaturedMediaHover viewBoxX="-6" viewBoxY="-5" />}>
              <IconUnfeaturedMedia viewBoxX="-6" viewBoxY="-5" />
            </Hover>
          )}
        </div>

        {this.props.flagged ? (
          <div className="overlay-icon delete-media-button float-right mr-2" onClick={this.handleUnflag}>
            <Hover hoverContents={<IconShowMediaHover viewBoxX="-6" viewBoxY="-5" />}>
              <IconShowMedia viewBoxX="-6" viewBoxY="-5" />
            </Hover>
          </div>
        ) : (
          <div className="overlay-icon delete-media-button float-right mr-2" onClick={this.handleDelete}>
            <Hover hoverContents={<IconDeleteMediaHover viewBoxX="-6" viewBoxY="-5" />}>
              <IconDeleteMedia viewBoxX="-6" viewBoxY="-5" />
            </Hover>
          </div>
        )}
      </div>
    );
  }

  handleToggleFeatured = e => {
    e.stopPropagation();
    this.props.onToggleFeatured(this.props.mediaId, this.props.featured);
  };

  handleDelete = e => {
    e.stopPropagation();
    this.props.onDelete(this.props.mediaId);
  };

  handleUnflag = e => {
    e.stopPropagation();
    this.props.onUnflag(this.props.mediaId);
  };

  handleClick = () => {
    this.props.onClick(this.props.mediaId);
  };
}

Media.propTypes = {
  mediaId: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  featured: PropTypes.bool,
  flagged: PropTypes.bool,
  canFeatureMedia: PropTypes.bool.isRequired,
  renderOverlay: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onToggleFeatured: PropTypes.func,
  onUnflag: PropTypes.func,
  isEditable: PropTypes.bool.isRequired
};

export class GalleryImage extends React.Component {
  render() {
    return (
      <Media
        {...this.props}
        renderOverlay={this.renderOverlay}
        className={cn({
          'gallery-image': true,
          'gallery-image--flagged': this.props.flagged
        })}
      />
    );
  }

  renderOverlay = () => {
    return (
      <div className="overlay-1-button">
        <div className="overlay-icon">
          <Hover hoverContents={<IconZoomHover viewBoxX="-6" viewBoxY="-5" />}>
            <IconZoom viewBoxX="-6" viewBoxY="-5" />
          </Hover>
        </div>
      </div>
    );
  };
}

export class GalleryVideo extends React.Component {
  render() {
    return (
      <Media
        {...this.props}
        renderOverlay={this.renderOverlay}
        className={cn({
          'gallery-video': true,
          'gallery-video--flagged': this.props.flagged
        })}
      />
    );
  }

  renderReadOnlyOverlay = () => {
    return (
      <div className="overlay-1-button">
        <div className="overlay-icon play-media-button">
          <Hover hoverContents={<IconPlayHover viewBoxX="-6" viewBoxY="-5" />}>
            <IconPlay viewBoxX="-6" viewBoxY="-5" />
          </Hover>
        </div>
      </div>
    );
  };

  renderEditableOverlay = () => {
    return (
      <div className="overlay-2-buttons">
        <div className="overlay-icon play-media-button float-left ml-2">
          <Hover hoverContents={<IconPlayHover viewBoxX="-6" viewBoxY="-5" />}>
            <IconPlay viewBoxX="-6" viewBoxY="-5" />
          </Hover>
        </div>

        <div
          className="overlay-icon delete-media-button float-right mr-2"
          onClick={e => {
            e.stopPropagation();
            this.props.onDelete(this.props.mediaId);
          }}
        >
          <Hover hoverContents={<IconDeleteMediaHover viewBoxX="-6" viewBoxY="-5" />}>
            <IconDeleteMedia viewBoxX="-6" viewBoxY="-5" />
          </Hover>
        </div>
      </div>
    );
  };

  renderOverlay = () => {
    return this.props.isEditable ? this.renderEditableOverlay() : this.renderReadOnlyOverlay();
  };
}

class GalleryMediaUpload extends React.Component {
  render() {
    return (
      <div id="upload-image" onClick={this.handleUpload} className={this.props.className + ' bg-light rounded'}>
        <div className="overlay-1-button opaque">
          <div className="overlay-icon">
            <Hover hoverContents={<IconUploadMediaHover viewBoxX="-6" viewBoxY="-5" />}>
              <IconUploadMedia viewBoxX="-6" viewBoxY="-5" />
            </Hover>
          </div>
        </div>
      </div>
    );
  }

  handleUpload = () => {
    this.props.onUpload();
  };
}

GalleryMediaUpload.propTypes = {
  className: PropTypes.string,
  onUpload: PropTypes.func.isRequired
};

export class GalleryImageUpload extends React.Component {
  render() {
    return <GalleryMediaUpload {...this.props} className="gallery-image" />;
  }
}

export class GalleryVideoUpload extends React.Component {
  render() {
    return <GalleryMediaUpload {...this.props} className="gallery-video" />;
  }
}
