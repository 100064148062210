
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import NavMenu from './index';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className='editor__well'>
  <NavMenu nav={{
        canViewRoadtripDetails: false,
        listings: {
          show: false
        },
        organization: {
          show: false
        },
        listing: {
          show: true
        }
      }} setActive={n => {}} internalUser={false} enabled={false} canEnable={true} enablePath={"/test/listing/enable"} mdxType="NavMenu" />
    </div>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;