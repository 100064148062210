import React from 'react';
import constate from 'constate';
import analytics from '../helpers/analytics';
import omit from 'lodash/omit';

type Props = {
  user?: { id: number },
  org?: { id: number },
  canonicalPlace?: { id: number }
};

const [StoreProvider, useStoreContext] = constate((props: Props): any => {
  const [user] = React.useState(props.user);
  const [org] = React.useState(props.org);
  const [canonicalPlace] = React.useState(props.canonicalPlace);

  // Adds user_id and org_id to analytics properties by default
  const track = (object, action, properties) => {
    analytics._track(object, action, {
      user_id: '0000' + user.id,
      org_id: org.id,
      ...properties
    });
  }

  // Adds canonical_place_id, user_id, and org_id to analytics properties by default
  const trackListing = (object, action, properties) => {
    track(object, action, {
      ...properties,
      canonical_place_id: canonicalPlace && canonicalPlace.id ? canonicalPlace.id : null
    })
  }

  return { user, org, canonicalPlace, analytics: { track, trackListing } };
});

export { StoreProvider, useStoreContext };
