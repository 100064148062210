import React from 'react';
import PaginatedTable from '@library/paginated-table';
import { TableFilters, useFiltering } from '@library/paginated-table/filtering';
import { useSorting } from '@library/paginated-table/sorting';
import Correction from './correction';
import BooneDropdown from '@/library/dropdown';
import parseQueryString from '@/helpers/parseQueryString';
import './styles';

function Corrections(props) {
  const { activeFilters, getActiveFilterValue, setActiveFilter, filterOptions, setFilterOptions } = useFiltering(
    [
      {
        name: 'State',
        key: 'state',
        default: 'pending',
        options: [
          { name: 'All', id: null },
          { name: 'Pending', id: 'pending' },
          { name: 'Accepted', id: 'accepted' },
          { name: 'Rejected', id: 'rejected' },
        ],
      },
      {
        name: 'Issue Type',
        key: 'issue_type',
        default: null,
        options: [
          { name: 'All', id: null },
          { name: 'Correction', id: 'correction' },
          { name: 'Closed Permanently', id: 'closed_permanently' },
          { name: 'Closed Temporarily', id: 'closed_temporarily' },
          { name: 'Does Not Exist', id: 'does_not_exist' },
          { name: 'Duplicate', id: 'duplicate' },
          { name: 'Category Correction', id: 'category_correction' },
        ],
      },
      {
        name: 'Canonical Place',
        key: 'canonical_place_id',
        default: null,
        options: [{ name: 'All', id: null }],
      },
    ],
    parseQueryString()?.params || {}
  );

  const { activeSort, setActiveSort, getSortLabel } = useSorting(
    [
      {
        name: 'Issue Type',
        key: 'issue_type',
      },
      {
        name: 'Canonical Place',
        key: 'canonical_place_id',
      },
      {
        name: 'State',
        key: 'state',
      },
      {
        name: 'IP Address',
        key: 'ip_address',
      },
      {
        name: 'Created At',
        key: 'created_at',
      },
    ],
    '-created_at'
  );

  const onTableUpdated = (data) => {
    const updatedFilters = [...filterOptions];

    const aggCplaces = {};
    const cplaces = data.map((datum) => {
      return {
        name: datum.canonical_place.name,
        id: datum.canonical_place.id,
      };
    }).filter((cplace) => aggCplaces[cplace.id] ? false : aggCplaces[cplace.id] = true );

    updatedFilters[2].options = [{ name: 'All', id: null }];
    updatedFilters[2].options.push(...cplaces);

    setFilterOptions(updatedFilters);
  };

  const onRowAction = (action: string, options: { [key: string]: string }) => {
    if (action == 'filter') {
      setActiveFilter(options.key, options.option_id);
    }
  };

  return (
    <div className="editor__corrections__manager">
      <TableFilters
        filterOptions={filterOptions}
        getActiveFilterValue={getActiveFilterValue}
        setActiveFilter={setActiveFilter}
      />
      <PaginatedTable
        onDataChanged={onTableUpdated}
        onRowAction={onRowAction}
        dataFilters={activeFilters}
        dataSort={activeSort}
        Head={() => (
          <tr className="editor__correction">
            <th>Id</th>
            <th>{getSortLabel('issue_type')}</th>
            <th>{getSortLabel('canonical_place_id')}</th>
            <th>{getSortLabel('state')}</th>
            <th>{getSortLabel('ip_address')}</th>
            <th>{getSortLabel('created_at')}</th>
            <th>Place Updated At</th>
            <th>Action</th>
          </tr>
        )}
        Row={Correction}
        url={'/corrections.json'}
        limit={10}
      />
    </div>
  );
}

export default Corrections;
