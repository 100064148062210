
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import Wizard from './index';
import ProgressBar from './components/progress-bar';
import StepHeader from './components/step-header';
import Step from './components/step';
import Footer from './components/footer';
import Input from '@/library/input-with-label';
import Summary from './steps/summary';
import { WizardProvider } from './useWizardContext';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Wizard`}</h2>
    <p>{`Provide the wizard component with an array of step titles and children.`}</p>
    <div className="editor__component-explorer__example">
  <Wizard steps={[{
        title: 'Intro',
        showStep: true
      }, {
        title: 'Step One',
        showStep: true
      }, {
        title: 'Step Two',
        showStep: false
      }, {
        title: 'Step Three',
        showStep: true
      }, {
        title: 'Summary',
        showStep: true
      }]} mdxType="Wizard">
    <div className="editor__component-explorer__example">🧡 </div>
    <div className="editor__component-explorer__example">🧡🧡 </div>
    <div className="editor__component-explorer__example">💜💜 </div>
    <div className="editor__component-explorer__example">💚💚💚</div>
    <div> This is a summary </div>
  </Wizard>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
  <Wizard
    steps={[
      { title: 'Intro', showStep:true },
      { title: 'Step One', showStep: true },
      { title: 'Step Two', showStep: true },
      { title: 'Step Three', showStep: true },
      { title: 'Summary', showStep: true }
    ]}
  >
    <div className="editor__component-explorer__example">🧡 </div>
    <div className="editor__component-explorer__example">🧡🧡 </div>
    <div className="editor__component-explorer__example">💜💜 </div>
    <div className="editor__component-explorer__example">💚💚💚</div>
    <div> This is a summary </div>
  </Wizard>
`}</code></pre>
    {
      /*
      
      ---
      ### Wizard Components
      All Components (and steps) require access to the Wizard Context.
      <br/>
      #### Progress Bar
      <div className='editor__component-explorer__example'>
       <WizardProvider total={5} step={0}>
         <ProgressBar/>
       </WizardProvider>
      </div>
      #### Step Header
      <div className='editor__component-explorer__example'>
       <WizardProvider total={2} step={0} stepTitles={['ID', 'Location']}>
         <StepHeader/>
       </WizardProvider>
      </div>
      
      #### Step
      <div className='editor__component-explorer__example'>
       <WizardProvider total={2} step={0} stepTitles={['Step', 'Step 2']}>
         <Step>
          <div> 🧡 </div>
          <div> 💜💜 </div>
         </Step>
       </WizardProvider>
      </div>
      
      #### Footer
      <div className='editor__component-explorer__example'>
       <WizardProvider total={2} step={0} stepTitles={['Step', 'Step 2']}>
         <Footer/>
       </WizardProvider>
      </div>
      ---
      ### Steps
      <p>
      Steps are generic step components that can be used in any wizard. Steps will probably require access to the Wizard Context.
      </p>
      <br/>
      #### Summary
      <div className='editor__component-explorer__example'>
       <WizardProvider total={2} step={0} stepTitles={['Step', 'Step 2', 'Summary']}>
         <Summary msg={'Thank you!'}/>
       </WizardProvider>
      </div>
      */
    }
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;