import React from 'react';
import './styles/index';
import Link from '@/library/link/view';

function Redirect(props: { children: any; link?: string }){
  React.useEffect(() => {
    setTimeout(function(){
      window.location.href = props.link;
    }, 5000);
  }, []);

  return (
    <div className="editor__wizard__summary">
      <div className="editor__wizard__summary-message">
        {props.children}
        If you aren't automatically redirected,
        <Link className="editor__link" externalLink={props.link}>
          click here
        </Link>.
      </div>
    </div>
  );
}

export default Redirect;
