import React from "react";
import "./styles/index";
import * as ajax from "@/helpers/ajax";
import Controls from "@/library/tree-select/search-tree/Controls";
import Search from "@/library/tree-select/search-tree/Search";
import Tree from "@library/tree-select/search-tree/tree";
import useIndex from "@/hooks/useIndex";
import useSearchTree from "@library/tree-select/search-tree/useSearchTree";
import Assigned from "./assigned";
import { useSpring, animated } from "react-spring";
import useMeasure from "@/hooks/useMeasure";
import isEqual from "lodash/isEqual";
import useMounted from "@/hooks/useMounted";
import TagAttributes from "../attributes/TagAttributes";

function Categories(props) {
  const [request, setRequest] = React.useState({});
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(false);
  const mounted = useMounted();
  const [selection, setSelection] = React.useState(
    props.selected.map((obj) => obj.public_id)
  );

  const [bind, d] = useMeasure();

  const sp = useSpring({
    height: `${d.height}px`,
    transition: "height 0.02s ease",
  });

  const {
    getNode,
    update,
    setQuery,
    query,
    cache,
    pendingMap,
    pendingArray,
    currentTree,
    clear,
    cancel,
    save,
    ancestorMap,
    remove,
  } = useSearchTree({
    ...props,
    save: async function (categories) {
      try {
        // @ts-ignore
        if (request && request.abort) request.abort();
        const [promise, abort] = ajax._patch(window.location.pathname, {
          listing: { categories },
        });
        setRequest({ abort });
        await promise;
        setSelection(categories);
        if (error) setError(false);
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setSaving(false);
      }
    },
    index: useIndex(props.categories, "public_id", {
      clearChildren: false,
    }),
    selection,
  });

  const t = [...pendingArray].sort();
  const n = [...selection].sort();

  return (
    <animated.div {...bind} className="editor__category__assignment">
      <div className="editor__categories">
        <div className="editor__categories__header">
          <h3>Categories</h3>
          <div className="editor__categories__actions">
            <div className="editor__save-indicator">
              {!error && mounted && !isEqual(t, n) && <i>unsaved changes</i>}
              {error && mounted && (
                <b>Something went wrong, your changes may not have saved</b>
              )}
            </div>
            <Controls
              showRemoveAll
              showCancel={false}
              save={() => {
                setSaving(true);
                save();
              }}
              clear={clear}
              cancel={cancel}
              saving={saving}
              saveText={saving ? "saving" : "save"}
            />
          </div>
        </div>
        <animated.div
          style={{
            maxHeight: d.height * 0.8 + "px",
          }}
          className="editor__categories__main"
          onClick={() => setError(false)}
        >
          <div className="editor__categories__main__section">
            <Search query={query} getQuery={(n) => setQuery(n)} />
            <animated.div
              style={sp}
              data-cy={"selection-tree"}
              className="editor__categories__tree"
            >
              {cache[currentTree].length > 0 ? (
                cache[currentTree].map((root) => (
                  <Tree
                    {...root}
                    query={query}
                    update={update}
                    ancestorMap={ancestorMap}
                    key={root.public_id}
                    useAssinableDisabled={true}
                    mirrorChild={true}
                    mirrorParent={false}
                    selection={pendingMap}
                  />
                ))
              ) : (
                <div className="text-muted" onClick={() => setQuery("@")}>
                  {"none"}
                </div>
              )}
            </animated.div>
          </div>

          <div className="editor__categories__main__section">
              <Assigned
                saved={selection}
                pending={pendingArray}
                getNode={getNode}
                ancestorMap={ancestorMap}
                remove={remove}
              />
              <TagAttributes
                {...props.tags}
                userEditingStrategy={props.userEditingStrategy}
              />
          </div>
        </animated.div>
      </div>
    </animated.div>
  );
}

export default Categories;
