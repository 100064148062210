import React from 'react';
import Select from '../select';

function Edit(props) {
  return (
    <Select
      {...props}
      dropdown={{right: false}}
      options={[
        { name: 'Free',id: 0 },
        { name: '$',id: 1 },
        { name: '$$',id: 2 },
        { name: '$$$',id: 3 },
        { name: '$$$$',id: 4 }
      ]} />
  )
}

export default Edit

