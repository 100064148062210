import React from "react";
import useMeasure from "@/hooks/useMeasure";
import {useSpring, animated} from "react-spring";
import "@library/fontawesome";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./styles";

type Props = {
  header?: any;
  children?: any;
  collapsed?: boolean;
  maxWidth?: number;
  minHeight?: number;
};

function Section({header, children, collapsed, maxWidth, minHeight}: Props) {
  const [bind, d] = useMeasure();

  const sp = useSpring({
    maxHeight: `${d.height}px`,
    minHeight: `${d.height + (minHeight || 60)}px`,
    transition: "max-height 50ms linear",
    maxWidth: `${maxWidth}`,
  });

  const [expand, setExpand] = React.useState(!collapsed);

  return (
    <animated.div style={sp} className="editor__expandable-section">
      <div
        className="editor__expandable-section__header"
        onClick={() => setExpand((s) => !s)}
      >
        {header}
        <div
          data-cy="section-control"
          className="editor__expandable-section__control"
        >
          <FontAwesomeIcon icon={expand ? "chevron-up" : "chevron-down"} />
        </div>
      </div>
      <animated.div {...bind} className="editor__expandable-section__content">
        {expand ? children : null}
      </animated.div>
    </animated.div>
  );
}

export default Section;
