import React from 'react';
import styled from 'styled-components';

// Bootstrap
import { Table } from 'reactstrap';

// Material UI
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import { DropTarget } from 'react-dnd';

import ItemTypes from './ItemTypes';

// Components
import Place from './Place';
import CanonicalActions from './CanonicalActions';
import Link from "@library/link/view";

const StyledCanonicalHeaderColors = {
  background: {
    target: '#985d54',
    new: '#707c3b',
    child: '#fff'
  }
};

const StyledCanonicalStatusColors = {
  background: {
    pending: '#fffc71',
    confirmed: '#1cffa4',
    base: '#985d54'
  }
};

const HeaderPrefixOfType = {
  target: 'Target',
  new: 'New',
  child: 'Child'
};

const StatusPrefixOfType = {
  pending: 'Pending',
  confirmed: 'Confirmed'
};

const StyledCanonical = styled.div`
  ${props => (props.isOver && props.canDrop ? 'box-shadow: 0 16px 24px rgba(23, 22, 21, 0.2) !important;' : '')};

  ${props => (props.isOver && !props.canDrop ? 'opacity: 0.25;' : '')};

  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background: #fff;

  margin-bottom: 20px;

  tbody {
  }

  thead {
    background-color: #fdfcfb;
    font-weight: bold;
    border-top: 1px solid #efe9dd;
  }

  td {
    font-size: 11px;
    padding: 8px;
    vertical-align: middle;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #efe9dd;
  }
`;

const StyledCanonicalHeader = styled.div`
  font-size: 14px;
  height: auto !important;
  font-weight: 400 !important;
  background: ${props => StyledCanonicalHeaderColors.background[props.type]};
  ${props => (props.isTarget ? 'color: #fff;' : '')} font-weight: bold;
  border-radius: 0px 6px 0px 0px;
  white-space: nowrap;

  span.canonical_id {
    font-size: 13px;
    color: ${props => (props.isTarget ? '#fff' : '#77746e')};
    margin-left: 8px;
  }
  span.canonical_target_id {
    
    font-size: 13px;
    color: ${props => (props.isTarget ? '#fff' : '#77746e')};
    margin-left: 8px;
    
    
    
  }

  &.collapsed {
    border-radius: 6px;
  }

  * {
    display: inline-block;
  }
`;

const StyledStatusPip = styled.div`
font-size: 14px;
height: auto !important;
font-weight: 400 !important;
padding: 8px;
margin-top:2px;
background: ${props => StyledCanonicalStatusColors.background[props.status]};
${props => (props.status ? 'color: #fff;' : '')} font-weight: bold;
border-radius: 20px;
white-space: nowrap;

`;

const StyledPendingIndicator = styled.div`
font-size: 14px;
height: auto !important;
font-weight: 400 !important;
margin-top:2px;
background: ${props => StyledCanonicalStatusColors.background[props.status]};
${props => (props.status ? 'color: #fff;' : '')} font-weight: bold;
border-radius: 4px;
white-space: nowrap;
display: inline-block;
text-align: center;
padding: 0px 8px;
margin-bottom: 10px;
border: 1px solid #00000020;
`;

const StyledStatusIndicator = styled.div`
display: inline-flex;
font-size: 14px;
height: auto !important;
font-weight: 400 !important;
padding: 6px;
padding-right: 12px;
background: ${StyledCanonicalStatusColors.background.base};
${props => (props.status ? 'color: #fff;' : '')} font-weight: bold;
border-radius: 6px 6px 0px 0px;
white-space: nowrap;
align-items: center;

span.text {
  font-size: 14px;
  color: ${props => (props.status ? '#fff' : '#77746e')};
  margin-left: 14px;
}

span.pip {
  margin-left: 10px;
}
  
}

&.collapsed {
  border-radius: 6px;
}
`;

const canonicalTarget = {
  canDrop(props, monitor) {
    // If the user is trying to drag TO a child
    // this is not allowed.
    //
    // The user can only drag TO the target
    // OR back to the original canonical for match_type = canonical_place.
    return props.type !== 'child' || monitor.getItem().canonical_place_id === props.canonical.id;
  },

  drop(canonicalProps, monitor) {
    const droppedPlace = monitor.getItem();
    canonicalProps.movePlaceToCanonical(droppedPlace, canonicalProps.id);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

// ===============================================
// COMPONENT->CANONICAL ==========================
// ===============================================
class Canonical extends React.Component {
  instance = React.createRef();

  render() {
    const {
      matchType,
      target,
      canonical,
      id,
      places,
      connectDropTarget,
      isOver,
      canDrop,
      handleCanonicalButtonClick,
      handleAddToQueueButtonClick,
      breakPlaceIntoNewCanonical,
      zoomToFitCanonical,
      type,
      status,
      flyTo,
      newCanonical,
      openAsTargetUrl,
      onToggleCollapse,
      nearMatchItems
    } = this.props;

    const commonProps = {
      target,
      matchType
    };
    const canonicalTargetId = this.props.canonical.target_canonical_place_id
    const nearMatchIds = this.props.nearMatchItems ? nearMatchItems.map(i => i['place_id']) : []
    const nearMatchApprovedIds = nearMatchIds?.length > 0 ? nearMatchItems.filter(i => i['near_match_approved']).map(i => i['place_id']) : []

    return connectDropTarget(
      <div>
        {type === 'target' &&
          <>
            <StyledPendingIndicator>
              <small>Pending</small>
              <h3>{this.props.matchSetCount}</h3>
            </StyledPendingIndicator>
            <br />
            <StyledStatusIndicator status={status}>
              <span className='pip'>
                <StyledStatusPip status={status} />
              </span>
              <span className='text'>
                {StatusPrefixOfType[status]}
              </span>
            </StyledStatusIndicator>
          </>}
        <StyledCanonical target={canonical} isOver={isOver} canDrop={canDrop}>
          <CanonicalActions
            id={id}
            target={canonical}
            type={type}
            handleCanonicalButtonClick={handleCanonicalButtonClick}
            handleAddToQueueButtonClick={handleAddToQueueButtonClick}
            openAsTargetUrl={openAsTargetUrl}
            onToggleCollapse={onToggleCollapse}
            status={status}
          />
          <StyledCanonicalHeader isTarget={target} type={type} className={canonical.isConfirmed ? 'collapsed' : null}>
            <IconButton onClick={e => zoomToFitCanonical(e, canonical.id)}>
              <Icon style={type === 'target' ? { color: 'white' } : {}}>my_location</Icon>
            </IconButton>
            {`${HeaderPrefixOfType[type]} ${this.props.matchType}`}

            <span className="canonical_id">{type !== 'new' && id}</span> | 
            <span className="canonical_target_id"> Canonical Target {matchType == 'group' && <Link internalLink={type !== 'new' && ('/listings/' + canonicalTargetId)}><span className='canonical_id'>{type !== 'new' && canonicalTargetId}</span></Link>}</span>
            
          </StyledCanonicalHeader>

          {canonical.isCollapsed !== true && (
            <Table style={{ tableLayout: 'fixed' }}>
              <thead id={id}>
                <tr>
                  <td style={{ width: '36px' }} />
                  <td style={{ width: '72px' }}>Item Type</td>
                  <td style={{ width: '72px' }}>Place ID</td>
                  {matchType === 'group' && (<>
                    <td style={{ width: '72px' }}>Canonical ID</td>
                    <td style={{ width: '100px' }}></td>
                    <td style={{ width: '100px' }}></td>
                  </>)}
                  <td style={{ width: '120px' }}>Source</td>
                  <td style={{ width: '240px' }}>Place Name</td>
                  <td style={{ width: '160px' }}>Address</td>
                  <td>Categories</td>
                  <td style={{ width: '40px' }} className="place-buttons" />
                </tr>
              </thead>
              <tbody>
                {places && places.length > 0
                  ? places.map((place) => (
                    <Place
                      {...commonProps}
                      flyTo={flyTo}
                      key={place.id}
                      place={place}
                      canonical={canonical}
                      newCanonical={newCanonical}
                      breakPlaceIntoNewCanonical={breakPlaceIntoNewCanonical}
                      isNearMatch={nearMatchIds.indexOf(place.id) !== -1}
                      nearMatchApproved={nearMatchIds.indexOf(place.id) !== -1 ? nearMatchApprovedIds.indexOf(place.id) !== -1 : null}
                      itemType={place.item_type}
                      remappable={place.remappable}
                    />
                  ))
                  : null}
              </tbody>
            </Table>
          )}
        </StyledCanonical>
      </div>
    );
  }
}

export default DropTarget(ItemTypes.PLACE, canonicalTarget, collect)(Canonical);
