export default {
  publicDataDisclaimer: "Your business information is publicly displayed to our shared users through Togo RV and Roadtrippers. It's how we connect our users to your business. Please update the information to reflect how you would like your listing to appear to users.",
  loremIpsum: "*Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",

  introduction: "Thanks for choosing to verify your listing! Before we start, we’d like to make sure we have the correct contact for your company. This contact information is STRICTLY for internal use and won't be public. It’s how we’ll contact you regarding future account updates. You’ll update public contact information that is displayed for our customers in the upcoming steps.",

  service_areas: "Let us know what services your location handles. Please check all that apply.",

  listing_information: "Your business information is publicly displayed to our shared users through Togo RV and Roadtrippers. It's how we connect our users to your business. Please update the information to reflect how you would like your listing to appear to users. This information is public.",

  customer_contact: "Your business information is publicly displayed to our shared users through Togo RV and Roadtrippers. It's how we connect our users to your business. Please update the information to reflect how you would like your listing to appear to users. This information is public.",
  notes: "Is there anything that we’ve left out that would be valuable for customers to know? This is publicly displayed information.",
  summary: "Thanks for updating your business information! We’re excited to connect you with our users and look forward to growing our relationship. We'll check in annually to ensure your information stays up-to-date."

}
