import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BooneDropdown from '../../library/dropdown';

class RoleSelect extends PureComponent {
  state = {
    alertClassName: ' boone-hidden',
  };

  showAlert() {
    this.setState(
      {
        alertClassName: ' boone-visible',
      },
      () => {
        this.alertTimeout = setTimeout(() => this.setState({ alertClassName: ' boone-hidden' }), 4000);
      }
    );
  }

  componentWillUnmount() {
    clearTimeout(this.alertTimeout);
  }

  render() {
    return (
      <React.Fragment>
        <div className="boone-organization__user__role-select__alert">
          {this.props.showAlert && (
            <div
              id={`alert-membership-${this.props.selected}`}
              className={'mr-2 alert alert-info alert-autocloseable-info' + this.state.alertClassName}
            >
              <i className="mdi mdi-check" /> Account type updated
            </div>
          )}
        </div>
        <div className="boone-dropdown__label-button">
          <BooneDropdown
            direction="down"
            right={false}
            menuClassName="boone-dropdown boone-dropdown--nested boone-dropdown__menu boone-dropdown__menu--below"
            toggleIcon="label-arrow-down"
            showOnHover={false}
            Label={() => (
              <div className="boone-organization__user__role-select__dropdown__label">
                {this.props.selected === 1
                  ? 'Admin'
                  : this.props.options.filter((o) => o.id === this.props.selected)[0].name || 'Role'}
              </div>
            )}
          >
            {this.props.options.map(({ id, name }) => (
              <div
                data-cy={name.toLowerCase() + '_role'}
                key={name.toLowerCase() + '-' + id}
                onClick={() => {
                  this.showAlert();
                  this.props.selectRole(id);
                }}
              >
                {name}
              </div>
            ))}
          </BooneDropdown>
        </div>
      </React.Fragment>
    );
  }
}

RoleSelect.propTypes = {
  // Alert pops up when option is selected and fades out
  showAlert: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  className: PropTypes.string,
  selectRole: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
};

export default RoleSelect;
