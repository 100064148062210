import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UsersTable from './UsersTable';
import Modal from '@/library/Modal.js';
import Content from '@/library/Content';
import Pagination from '@/library/Pagination';
import InviteUserModalForm from './InviteUserModalForm';
import { WhiteButton } from '@/library/Buttons';
import * as ajax from '@/helpers/ajax';
import * as pt from '../../helpers/propTypes';
import IconAdd from '../../icons/IconAdd';
import { useStoreContext } from '../../state';
import { Sidebar } from "@/v2Editor/src/layout/sidebar";


class Users extends Component {
  // Store original onpopstate
  onpopstate = window.onpopstate;

  anchor = document.getElementById('modal-anchor');
  setUsers = () => {
    if (this.props.users) {
      return this.props.users.reduce(
        (prev, curr) => {
          prev.byId[curr.id] = curr;
          prev.ids.push(curr.id);
          prev.users.push(curr);
          return prev;
        },
        { byId: {}, ids: [], users: [] }
      );
    }
  };

  formDefaults = {
    name: '',
    email: '',
    role_id: this.props.roles[0].id,
    organization_id: this.props.orgId,
  };

  state = {
    ...this.setUsers(),
    modalOpen: false,
    showInviteForm: true,
    invitationStatusMessage: 'Invitation sent.',
    offset: 0,
    // Form used to add a user via invite
    form: { ...this.formDefaults },
    collapsedState: false,
  };

  componentDidMount() {
    this.props.analytics.track('Org', 'viewed');

    // Set our own event handler for onpopstate so that
    // the table of users is updated when the browser's
    // back and forward buttons are used.
    window.onpopstate = () => this.updateOffset();

    // Read page query param from url and update offset
    if (!this.updateOffset()) {
      // If we didn't update the offset, we can assume that either the
      // page was not set in the url, or it was not set correctly. Regardless
      // of either scenario, we set the url param to page=1 by default.
      window.history.pushState('', '', document.location.pathname + '?page=1');
    }
  }

  componentWillUnmount() {
    // Reset before dismount
    window.onpopstate = this.onpopstate;
  }

  updateOffset = () => {
    const queryParams = parseQueryString();
    const page = parseInt(queryParams['page']);
    const maxNumPages = Math.ceil(this.props.users.length / this.props.pageSize);
    if (!isNaN(page) && page >= 1 && page <= maxNumPages) {
      this.setState({ offset: this.props.pageSize * (page - 1) });
    } else {
      return false;
    }
  };

  updateUserRole = (id, role_id) => {
    this.props.analytics.track('User Role', 'updated', { new_role: role_id });
    this.setState((prevState) => ({
      byId: {
        ...prevState.byId,
        [id]: {
          ...prevState.byId[id],
          role_id,
        },
      },
    }));
  };

  onUserDisabled = (id) => {
    this.props.analytics.track('User', 'removed', { user_id: id });
    const indexToRemove = this.props.users.findIndex((user) => user.id == id);
    this.props.users.splice(indexToRemove, 1);
    this.setState({
      ...this.setUsers(),
    });
  };

  getUsers = (
    ids = this.state.ids,
    byId = this.state.byId,
    offset = this.state.offset,
    pageSize = this.props.pageSize
  ) => (ids ? ids.slice(offset, offset + pageSize).map((id) => byId[id]) : []);

  resendInvite = async (email) => {
    const { role_id, organization_id, name } = this.state.form;
    this.props.analytics.track('User', 'reinvited', { invitee_role: role_id });
    const res = await ajax.post('/users/resend_invite', { role_id, organization_id, name, email });
    const json = await res.json();

    this.setState({ invitationStatusMessage: json['message'] });

    const { user } = json;
    this.onInvite({ ...this.state.form, ...user });
  };

  sendInvite = async () => {
    const { role_id, organization_id, name, email } = this.state.form;
    this.props.analytics.track('User', 'invited', { invitee_role: role_id });
    const res = await ajax.post('/users/invite', { role_id, organization_id, name, email });
    const json = await res.json();

    this.setState({ invitationStatusMessage: json['message'] });

    const { user } = json;
    this.onInvite({ ...this.state.form, ...user });
  };

  onSubmit = async () => {
    this.setState({
      showInviteForm: false,
    });
    await this.sendInvite();
  };

  updateFormData(key, value) {
    this.setState((prevState) => ({ form: { ...prevState.form, [key]: value } }));
  }

  onInvite = ({ id, name, email, role_id, invitation_pending, avatar_url }) => {
    if (!this.state.ids.includes(id)) {
      this.setState((prevState) => {
        // Add user to state
        return {
          ids: [...prevState.ids, id],
          byId: {
            ...prevState.byId,
            [id]: {
              id,
              name,
              email,
              role_id,
              avatar_url: avatar_url,
              invitation_pending: invitation_pending,
            },
          },
          form: { ...this.formDefaults },
        };
      });
    }
  };

  updateUrlParamPage = () => {
    const page = this.state.offset / this.props.pageSize + 1;
    window.history.pushState('', '', document.location.pathname + '?page=' + page);
  };

  onPreviousClick = (e) => {
    e.preventDefault();
    const { pageSize } = this.props;
    this.setState(
      (prevState) => ({
        offset: prevState.offset - pageSize,
      }),
      () => this.updateUrlParamPage()
    );
  };

  onNextClick = (e) => {
    e.preventDefault();
    const { pageSize } = this.props;
    this.setState(
      (prevState) => ({
        offset: prevState.offset + pageSize,
      }),
      () => this.updateUrlParamPage()
    );
  };

  navOptions = [
    { name: 'Search', icon: 'search', url: '/search' }
  ];

  render() {
    const users = this.getUsers();
    const end = this.state.offset + users.length;
    const start = this.state.offset + 1;

    return (

      <div className="editor-container__content">
          <div className={
            `__left-panel${this.state.collapsedState ? '--collapsed' : ''}`
          }>
            <Sidebar
              title="Roadpass"
              titleB="Places"
              options={this.navOptions}
              isAdmin={true}
              onCollapsedState={() => this.setState((prevState) => ({ collapsedState: !prevState.collapsedState }))}
            />
          </div>
          <div className={`__right-panel`} id="rightPanel">
          <Content>
        <div className="d-flex justify-content-between">
          <h3 className="users-header">{this.props.orgName} Organization Users</h3>
          {this.props.userEditingStrategy === 'update' && (
            <WhiteButton
              cy="new-user"
              ariaLabel="new user"
              className="m-2 mr-4"
              onClick={() => {
                this.props.analytics.track('Invite User Modal', 'opened');
                this.setState({ modalOpen: true });
              }}
            >
              <IconAdd /> New user
            </WhiteButton>
          )}
        </div>
        {this.props.users.length > 0 ? (
          <React.Fragment>
            <UsersTable
              roles={this.props.roles}
              currentUserId={this.props.currentUserId}
              users={users}
              updateUserRole={(id, role_id) => this.updateUserRole(id, role_id)}
              isSuperAdmin={this.props.isSuperAdmin}
              resendInvite={(email) => this.resendInvite(email)}
              onUserDisabled={(id) => this.onUserDisabled(id)}
              userEditingStrategy={this.props.userEditingStrategy}
            />
            <Pagination
              hasPaginatedResults={true}
              className="boone-pagination"
              pathToPreviousPage="#"
              pathToNextPage="#"
              hasNextPage={this.state.offset + users.length < this.state.ids.length}
              hasPreviousPage={this.state.offset > 0}
              start={start}
              end={end}
              total={this.state.ids.length}
              onPreviousClick={(e) => this.onPreviousClick(e)}
              onNextClick={(e) => this.onNextClick(e)}
            />
          </React.Fragment>
        ) : (
          <div className="users no-users">
            <p>{`This organization doesn't have any users`}</p>
          </div>
        )}
        <Modal
          show={this.state.modalOpen}
          toggle={() => this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }))}
        >
          {this.state.showInviteForm && (
            <InviteUserModalForm
              orgName={this.props.orgName}
              roles={this.props.roles}
              name={this.state.form.name}
              email={this.state.form.email}
              role_id={this.state.form.role_id}
              onSubmit={(e) => this.onSubmit(e)}
              updateFormData={(key, value) => this.updateFormData(key, value)}
            />
          )}
          {!this.state.showInviteForm && (
            <div role="document" id="invite-confirmation-modal" className="modal-content">
              <div className="modal-body">
                <h2>{this.state.invitationStatusMessage}</h2>
              </div>
              <div className="modal-footer">
                <WhiteButton onClick={() => this.setState({ modalOpen: false, showInviteForm: true })}>Ok</WhiteButton>
              </div>
            </div>
          )}
        </Modal>
      </Content>
          </div>
        </div>
    );
  }
}

Users.propTypes = {
  users: PropTypes.array,
  roles: PropTypes.array,
  role_id: PropTypes.number,
  membership_id: PropTypes.number,
  currentUserId: PropTypes.number,
  orgName: PropTypes.string,
  orgId: PropTypes.number,
  pageSize: PropTypes.number,
  title: PropTypes.bool,
  modal: PropTypes.bool,
  modalProps: pt.requiredIf('modal'),
  titleProps: pt.requiredIf('title'),
  userEditingStrategy: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  analytics: PropTypes.any,
};

export default function OrgUsers(props) {
  const { analytics } = useStoreContext();
  return <Users {...props} analytics={analytics} />;
}
