import React from 'react';
import isEqual from 'lodash/isEqual';

function useSmartState(defState) {
  const [state, setState] = React.useState(defState);

  function smartSetState(newState, callback) {
    if (!isEqual(state, newState)) {
      setState(newState);
      if(callback) callback(newState)
    }
  }

  return [state, smartSetState];
}

export default useSmartState;
