import React from 'react';
import BooleanRow from '@library/boolean-row/edit';
import * as c from '../../constants';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { CancelButton, DangerButton } from '@library/Buttons';

type Props = {
  permanentlyClosed: boolean;
  setPermanentlyClosed: (v: boolean) => void;
  onConfirm: () => void;
  onCancel: () => void;
};

export const PermanentlyClosed = function(props: Props) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const cancel = () => {
    props.onCancel();
    setModalOpen(false);
  };

  const confirm = () => {
    props.onConfirm();
    setModalOpen(false);
  };

  return (
    <>
      <BooleanRow
        binary
        label="Permanently Closed"
        value={props.permanentlyClosed}
        onChange={val => {
          if (!val) {
            props.setPermanentlyClosed(val);
          } else {
            setModalOpen(true);
          }
        }}
      />
      <Modal className="boone-listing__disable-modal" isOpen={modalOpen} toggle={cancel}>
        <ModalBody className="m-4 mr-3">{c.PERMANENTLY_CLOSED_MODAL_BODY}</ModalBody>
        <ModalFooter>
          <CancelButton onCancel={cancel} />
          <DangerButton onClick={confirm}>{'Set as permanently closed'}</DangerButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PermanentlyClosed;
