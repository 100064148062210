import React, { useState, useEffect, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './styles';
import SearchFilters from '@/listings/search/filters';
import { ListingsList } from '../listingsList';
import { MapView } from '../mapView';
import { AssignAuditData, BulkAssignAuditData, CanonicalPlace, FilterData, FullResultsInfo, LaunchDarkly, User } from '../../common/types';
import applyFilter, { applyPage } from '@/helpers/applyFilter';
import parseQueryString, { getQueryStringValue } from "@/helpers/parseQueryString";
import Pagination from '@/helpers/pagination';
import { SearchInput } from '../searchInput';
import { AssignAudit } from '../AssignAudit';
import { CreateListing } from '../createListing';
import useApi from '../../hooks/useApi';
import auditsApi from '../../api/audits';


export interface Meta {
  discoverable_min?: number;
  total: number;
}

export interface Results {
  data?: Array<CanonicalPlace>;
  meta?: Meta;
  error: any;
}

export interface Props {
  results: Results;
  replacedIds?: Array<any>;
  isSuperAdmin?: boolean;
  data: FilterData;
  launchDarkly?: LaunchDarkly;
  onLoading: Function;
  pagination: Pagination;
  sort: string;
  bulkAssignAuditShow: boolean;
  bulkAssignAuditToggle: Function;
  createListingShow: boolean;
  createListingToggle: Function;
}

export const ListingsView = ({ ...props }: Props) => {
  const renderFilters = () => {
    return (<SearchFilters
      launchDarkly={props.launchDarkly}
      replacedIds={props.replacedIds}
      data={props.data}
      isSuperAdmin={props.isSuperAdmin}
    />);
  };

  const [filters, setFilters] = useState(renderFilters());
  const [query, setQuery] = useState(getQueryStringValue('name', ''));
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [selectedAuditUser, setSelectedAuditUser] = useState<User>();
  const [selectedAuditListings, setSelectedAuditListings] = useState<Array<CanonicalPlace>>([]);
  const [focusRef, setFocusRef] = useState(null);
  const [hoverId, setHoverId] = useState(0);
  const [results, setResults] = useState<Results>();

  // Handles scrolling the results list automatically to the same listing being hovered in the map
  let scrollTo = useRef([]);

  // AUDIT API INTERACTION

  // Handles getting the create bulk audits api
  const createBulkAudits = useApi(auditsApi.postBulkAuditsApi);

  // Pushes the bulk assign audits payload to the api
  const handleSaveBulkAudits = (bulkAssignAuditData: BulkAssignAuditData) => {
    if (bulkAssignAuditData.user) {
      const searchParams = parseQueryString();
      const params = Object.assign(searchParams.params, {assign_to_user_id: bulkAssignAuditData.user.id});
      createBulkAudits.request(params).then((data) => {
        (window as any).updateSearch();
      });
    }
  };

  // Utility Functions

  // Builds full results info data
  const getFullResultsData = (): FullResultsInfo => {
    const params = new URLSearchParams(window.location.search);
    return {
      query: params.toString(),
      resultsTotal: results.meta.total
    }as FullResultsInfo
  }

  // Adds the "Anyone" user to the users list
  const anyoneUserAppendedAuditQueue = () => (
    props.data && props.data.audit_queue &&
    {
      ...props.data.audit_queue,
      anyone: {
        created_at: null,
        email: null,
        enabled: true,
        id: null,
        name: "Anyone",
        updated_at: null
      }
    }
  );

  // Change / Click handle events

  const handlePageChange = (page: number) => {
    applyPage(page);
  };

  const stepPage = (direction: 'reverse' | 'forward') => {
    switch (direction) {
      case 'reverse':
        handlePageChange(props.pagination.page - 1);
        break;
      case 'forward':
        handlePageChange(props.pagination.page + 1);
        break;
    }
  };

  const handleOnChange = (event: any) => {
    setQuery(event.target.value);
  };

  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    setQuery(query);
    applyFilter({ name: query });
  };

  useEffect(() => {
    props.results &&
      setResults(props.results);
    scrollTo.current[0]?.scrollIntoView({ behavior: 'smooth' });

  }, [props.results]);


  return (
    <div className="listings-view">
      <div className="__header">
        <div className="__banner"></div>
        <SearchInput
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
          value={query}
        />
        <div className="__filters">
          {filtersVisible ?
            <>
              <a href="#" onClick={(event) => {
                event.preventDefault();
                setFiltersVisible(false)
              }}
              >
                <FontAwesomeIcon icon={['fas', 'filter']} />
                <FontAwesomeIcon
                  icon={['fas', 'angle-down']}
                />
              </a>
              {filters}
            </>
            :
            <>
              <a href="#" onClick={(event) => {
                event.preventDefault();
                setFiltersVisible(true)
              }}
              >
                <FontAwesomeIcon icon={['fas', 'filter']} />
                <FontAwesomeIcon icon={['fas', 'angle-right']} />
              </a>
            </>}
        </div>
      </div>
      <div className="__body">
        {results && !results.error && scrollTo && props.results.data?.length > 0 ?
          <>
            <ListingsList
              results={results}
              onUpdateResults={setResults}
              handlePageChange={handlePageChange}
              handleStepPage={stepPage}
              focusRef={focusRef}
              scrollTo={scrollTo}
              onHover={setHoverId}
              onLoading={props.onLoading}
              pagination={props.pagination}
              sort={props.sort}
              auditQueue={anyoneUserAppendedAuditQueue()}
              selectedAuditUser={selectedAuditUser}
              onSelectAuditUser={setSelectedAuditUser}
            />
            {props.results.data &&
              <MapView
                results={props?.results.data}
                mapOpen={true}
                handleFocusRef={setFocusRef}
                scrollTo={scrollTo}
                inFocus={hoverId}
                pagination={props.pagination}
              />}
            <AssignAudit
              toggle={() => {
                setSelectedAuditUser(null);
                setSelectedAuditListings([]);
                props.bulkAssignAuditToggle(!props.bulkAssignAuditShow)
              }}
              show={props.bulkAssignAuditShow}
              selectedUser={selectedAuditUser}
              onUserClick={(user: User) => setSelectedAuditUser(user)}
              FullResultsInfo={getFullResultsData()}
              onSave={() => {
                handleSaveBulkAudits({
                  user: selectedAuditUser,
                  query: getFullResultsData().query
                } as BulkAssignAuditData)
              }}
              assignAuditData={{
                users: [anyoneUserAppendedAuditQueue().anyone, ...anyoneUserAppendedAuditQueue().users]
              } as AssignAuditData}
            />
            <CreateListing
              onLoading={props.onLoading}
              toggle={() => props.createListingToggle(!props.createListingShow)}
              show={props.createListingShow}
            />
          </>
          :
          <div className="__nothing-found">
            We were unable to return any results
            <br></br>
            <FontAwesomeIcon icon={'search'} />
          </div>
        }
      </div>
      <div className="__footer"></div>
    </div>
  )
}
