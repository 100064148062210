import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconZoom extends React.Component {
  render() {
    var props = $.extend({}, this.props, {
      title: 'Zoom in',
      className: 'zoom-in',
      path: "M17.33 16.15l4.42 4.42c.33.34.33.84 0 1.18l-.13.12h-.04a.71.71 0 0 1-.42.13.83.83 0 0 1-.6-.25l-4.41-4.42a6.3 6.3 0 1 1 1.18-1.18zm-9.6-3.83a4.6 4.6 0 0 0 9.18 0 4.6 4.6 0 0 0-9.18 0zm5.51-1.06h1.02a1 1 0 1 1 0 1.98h-1.02v1.02a1 1 0 1 1-1.98 0v-1.02h-1.02a1 1 0 0 1 0-1.98h1.02v-1.02a1 1 0 0 1 1.98 0v1.02z"
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}

export class IconZoomHover extends React.Component {
  render() {
    const childProps = $.extend({}, this.props, {fillColor: "#EFA036"});
    return <IconZoom {...childProps} />;
  }
}
