import React from 'react';
import { stateUrlEncode } from '@/data-outreach/_core/forms/util';
import uuid from 'uuid';

const serviceOptions = (props, mobile) => ({
  ...props.rv_services.attributes.available_services.values.reduce((p, c) => {
    if ((mobile && c.id.startsWith('mobile-')) || (!mobile && !c.id.startsWith('mobile-'))) {
      p[c.id] = { ...c, value: false };
    }
    return p;
  }, {})
});

const getServices = state => [...Object.keys(state).filter(k => state[k]['value'])];


export default (props) => ({
  initialState: {
    id: uuid(),

    // business
    name: '',

    // service areas
    sales: false,
    service_center: false,
    mobile_service: false,
    storage: false,
    parts_sales: false,
    rental: false,

    // service
    service_email: '',
    service_phone: '',
    service_website: '',
    service_address1: '',
    service_address2: '',
    service_postal_code: '',
    service_city: '',
    service_state: '',
    service_available_services: serviceOptions(props, false),

    // internal
    internal_first_name: '',
    internal_last_name: '',
    internal_email: '',
    internal_phone: '',
    internal_job_title: '',

    // mobile
    mobile_city: '',
    mobile_email: '',
    mobile_phone: '',
    mobile_radius: '',
    mobile_state: '',
    mobile_available_services: serviceOptions(props, true),

    // storage
    storage_phone: '',
    storage_email: '',

    // rental
    rental_phone: '',
    rental_email: '',

    // sales
    sales_phone: '',
    sales_email: '',

    // services
    services: props.rv_services.attributes.available_services.values.filter(
      service => !service['id'].startsWith('mobile-')
    ), // The mobile values will be assumed to be the regular services prepended with `mobile-`
    notes: ''
  },
  gsheet_hash: props.gsheet_hash,
  gsheet: {
    payloadFormatter: state => {
      return stateUrlEncode({
        ...state,
        mobile_available_services: getServices(state.mobile_available_services),
        service_available_services: getServices(state.service_available_services),
        spreadsheet: 'rv-data-outreach',
        sheet: 'v2'
      });
    }
  }
})
