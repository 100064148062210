
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import SideNav from './index';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SideNav enabled={false} listings={{
      show: true,
      path: '/'
    }} organization={{
      show: true,
      path: '/'
    }} listing={{
      show: false,
      path: '/'
    }} mdxType="SideNav" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;