import React from 'react';
import PropTypes from 'prop-types';
import IconMarker from '../../icons/IconMarker';

const iconMarker = (props) => {
  const color = props.color ? props.color : '#DE6A60';
  return (
    <IconMarker
      x={String(props.number).length > 1 ? '4.5' : '8.5'}
      y="17"
      fillColor={props.selected ? '#01425F' : color}
      text={props.icon ? props.name : props.number}
      className="marker"
      textClassName="marker-text"
      icon={props.icon}
    />
  )
}

const MarkerIcon = ({ number, name, selected, icon }) => (
    iconMarker({ number, name, selected, icon})
);

MarkerIcon.propTypes = {
  number: PropTypes.number,
  selected: PropTypes.bool
};

export default MarkerIcon
