import React from 'react';

type Props = {
  mirrorParent: boolean;
  parent_public_id?: string;
  public_id: string;
  selection: AssignmentMap;
  update: UpdateAssignmentMap;
  public_id_breadcrumbs?: string[];
};

function useMirrorParent({
  mirrorParent,
  parent_public_id,
  public_id,
  selection,
  public_id_breadcrumbs,
  update
}: Props){
  if (!mirrorParent || !parent_public_id) {
    return {
      fauxAssign: false,
      disabled: false,
      showTooltip: false
    };
  }

  // Display that the category is assigned because a parent is assigned
  const [fauxAssign, setFauxAssign] = React.useState(false);

  React.useEffect(
    () => {
      const t = public_id_breadcrumbs.filter((id) => selection[id]);

      // if there are more than one selection, an ancestor
      // is selected so the child should be faux assigned
      if (t.length > 1) {
        update(public_id, false);
      }

      if (t.length > 0) {
        if (!selection[public_id]) {
          setFauxAssign(true);
        }
      } else {
        setFauxAssign(false);
      }
    },
    [selection]
  );

  return {
    fauxAssign,
    disabled: fauxAssign,
    showTooltip: false
  };
}

export default useMirrorParent;
