import * as React from "react";
import {IconShowMedia, IconShowMediaHover} from "../../icons/IconShowMedia";
import IconHide from "../../icons/IconHide";
import Hover from "../Hover";
import * as ajax from "@/helpers/ajax";
import {useAsyncEffect} from "use-async-effect";

type Props = {
  flagged: boolean;
  url: string;
  mediaFlagged?: () => void;
  mediaUnflagged: () => void;
  ownerKey: string;
  ownerId: string;
  className?: string;
};

export default function Flag(props: Props) {
  const [flag, setFlag] = React.useState(props.flagged);

  React.useEffect(() => {
    if (flag !== props.flagged) {
      if (flag) {
        if (props.mediaFlagged) props.mediaFlagged();
      } else {
        props.mediaUnflagged();
      }
    }
  }, [flag]);

  useAsyncEffect(
    async () => {
      if (flag !== props.flagged) {
        if (flag) {
          await ajax.post(props.url + "/flag", {
            [props.ownerKey]: props.ownerId,
          });
        } else {
          await ajax.post(props.url + "/unflag");
        }
      }
    },
    null,
    [flag]
  );

  return (
    <div
      className={props.className || "image-badge hide-image-badge"}
      onClick={(e) => {
        e.stopPropagation();
        setFlag(!flag);
      }}
    >
      {flag ? (
        <span data-cy="show">Show</span>
      ) : (
        <span data-cy="hide">Hide</span>
      )}
    </div>
  );
}
