import * as React from "react";
import Drawer from "@/layout/drawer";
import Menu from "./menu";
import "./styles";
import "@library/fontawesome";

type Props = {
  canEnable: boolean;
  enabled: boolean;
  enablePath: string;
  internalUser: boolean;
  canonicalPlace: {
    parent: any;
    children: [];
  };
  discoverability: boolean;  
  nav: {
    canViewRoadTripDetails: boolean;
  };
  setActive: (tab: string) => void;
};

function NavMenu(props: Props) {
  return (
    <Drawer>
      <Menu
        parent={props.canonicalPlace.parent}
        children={props.canonicalPlace.children}
        nav={props.nav}
        internalUser={props.internalUser}
        enabled={props.enabled}
        enablePath={props.enablePath}
        canEnable={props.canEnable}
        setActive={props.setActive}
        discoverabilityEnabled={props.discoverability}
      ></Menu>
    </Drawer>
  );
}

export default NavMenu;
