import React from 'react';
import { useState } from 'react';
import ExtendedData from './extendedData';
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Relative(props) {
  
  const [viewMore, setViewMore] = useState(false);
  const toggleMore = () => {
    setViewMore(!viewMore);
  };
  const routeChange = (event: any) => {
    const id = event.target.getAttribute('data-id');
    const path = `/listings/${id}`;
    window.location.href = path;
  }

  const icon =
      faCaretUp && faCaretDown && (viewMore ? faCaretUp : faCaretDown);

  return (
    <>
      <div className="__row __current">
        <div className="btn item __canonical_place_id" data-id={props.id} onClick={routeChange}>Canonical Place ID: {props.id}</div>
        <div className="btn item __canonical_place_name" data-id={props.id} onClick={routeChange}>{props.name}</div>
        <div className="item __canonical_place_location">{
          props.locations && (
            props.locations.length > 0 &&
            props.locations.map(location =>
              <span key={location.id}>{`${location.address1}, ${location.city}, ${location.state}`}</span>
            )
          )
        }</div>
        <div className="btn item __canonical_place_more"
          onClick={toggleMore}>{
            <FontAwesomeIcon
              icon={icon}
              style={{
                ...{
                  width: "1em",
                  height: "1em",
                  marginLeft: "0.25em",
                  cursor: "pointer",
                  verticalAlign: "middle",
                },
              }}

            />
          }
        </div>
      </div>
      {viewMore && <ExtendedData {...props}></ExtendedData>}
    </>
  );
}