import React from 'react';

type Error = {
  error?: boolean;
  errorInfo?: any;
};

class ErrorBoundary extends React.Component<Error, Error> {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    // @ts-ignore
    if (this.state.errorInfo && process.env.NODE_ENV !== 'production') {
      // Error path
      return (
        <div className="container well">
          <h2 style={{ color: 'red' }}>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap', color: 'red' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    } else if (this.state.errorInfo && process.env.NODE_ENV === 'production') {
      return (
        <div className="container well">
          Sorry, something went wrong.
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
