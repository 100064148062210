import * as React from 'react';
import Sandbox from '../../component-explorer/Sandbox';
import Scenario from '../../component-explorer/Scenario';
import ViewList from './index';

export default function Examples(): any {
  return (
    <Sandbox name="View List">
      <Scenario description="Default usage" code="">
        <ViewList
          label="Links"
          link
          values={[
            'https://github.com/roadtrippers/quantu/issues/1127',
            'https://github.com/roadtrippers/quantu/issues/1126',
            'https://github.com/roadtrippers/quantu/issues/1125'
          ]}
        />
      </Scenario>
    </Sandbox>
  );
}
