import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as ajax from '@/helpers/ajax';

const PATH = '/listing_audits/';

export default Child => {
  return class extends Component {
    state = {
      error: false,
      audit: null
    };

    static displayName = Child.displayName;

    static propTypes = {
      audit: PropTypes.shape({
        id: PropTypes.number,
        user_id: PropTypes.number,
        canonical_place_id: PropTypes.number
      }),
      // required for create
      canonicalPlaceId: PropTypes.number.isRequired
    };

    componentDidMount() {
      this.setState({
        audit: this.props.audit
      });
    }

    onSuccess = audit => {
      this.setState({ error: false, audit });
    };

    onFailure = (method, e) => {
      this.setState({ error: true });
    };

    createAudit = userId => {
      const { canonicalPlaceId } = this.props;
      ajax
        .post(PATH, {
          canonical_place_id: canonicalPlaceId,
          user_id: userId
        })
        .then(response =>
          response.json().then(audit => {
            this.onSuccess(audit, canonicalPlaceId);
          })
        )
        .catch(e => this.onFailure('POST', e));
    };

    updateAudit = userId => {
      // assumes audit exists
      const { audit } = this.state;
      ajax
        .put(PATH + audit.id, { ...audit, user_id: userId })
        .then(response => response.json().then(audit => this.onSuccess(audit)))
        .catch(e => this.onFailure('PUT', e));
    };

    removeAudit = () => {
      if (!this.state.audit && !this.state.audit.id) {
        console.debug('Audit is undefined in withAuditApi hoc skipping removal');
        return;
      }
      ajax
        .destroy(PATH + this.state.audit.id)
        .then(response => response.json().then(() => this.onSuccess(null)))
        .catch(e => this.onFailure('DELETE', e));
    };

    render() {
      // NOTE - intentionally excluding this.props.audit from props passed to children
      const { audit, ...props } = this.props;
      return (
        <Child
          {...props}
          audit={{ ...this.state.audit }}
          createAudit={userId => this.createAudit(userId)}
          removeAudit={() => this.removeAudit()}
          updateAudit={userId => this.updateAudit(userId)}
        />
      );
    }
  };
};
