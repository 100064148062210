import React from 'react';
import { useWizardContext } from '@/library/wizard/useWizardContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { animated, useSprings } from 'react-spring';
import './styles/index';

function Summary(){
  const wz = useWizardContext();
  const sp = useSprings(
    wz.steps.length - 1,
    wz.steps.map((s, idx) => ({
      transform: 'scale(0.9)',
      from: { transform: 'scale(2)', transitionDelay: `.${idx + 2}s` }
    }))
  );

  return (
    <div className="editor__wizard__summary">
      <div className="editor__wizard__summary-message" />
      <div className="editor__wizard__summary-items">
        {wz.steps.filter((t) => t.title !== 'Summary').map((st, idx) => (
          <div
            className="editor__wizard__summary-item"
            key={st.title}
            onClick={() => wz.goToStep(idx)}
          >
            <animated.div style={sp[idx]}>
              <FontAwesomeIcon icon={faCheck} />
            </animated.div>
            {st}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Summary;
