import React, {ReactChildren, StyleHTMLAttributes} from "react";
import * as color from "@/helpers/color";

type Props = {
  str: string;
  style?: React.CSSProperties;
  children?: string | React.ReactChild | ReactChildren;
  className?: string;
};

function DynamicColor({str, children, ...props}: Props) {
  const bkgClr = color.stringToColor(str);
  const txtClr = color.lightOrDarkText(bkgClr);
  const styles = {backgroundColor: bkgClr, color: txtClr};

  const style = props.style
    ? {
        ...props.style,
        ...styles,
      }
    : styles;

  return (
    <div {...props} style={style}>
      {children}
    </div>
  );
}

export default DynamicColor;
