import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconUploadMedia extends React.Component {
  render() {
    var props = $.extend({}, this.props, {
      title: 'Upload',
      className: 'upload-media',
      path: "M15 13V7a1 1 0 0 0-2 0v6H7a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2h-6z"
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}

export class IconUploadMediaHover extends React.Component {
  render() {
    const childProps = $.extend({}, this.props, {fillColor: "#EFA036"});
    return <IconUploadMedia {...childProps} />;
  }
}
