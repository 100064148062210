import { useState } from "react";

export default (apiFunc) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const request = async (...args) => {
    setLoading(true);

    const [promise, abort] = await apiFunc(...args);
    promise
      .then((response) => setData(response))
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.error(error);
          setError(error || "Error retrieving data.");
        }
      })
      .finally(() => setLoading(false)
      );
      return promise;
  };

  return {
    data,
    error,
    loading,
    request
  };
};