import React from 'react';
import { useWizardContext } from '../useWizardContext';
import './styles/index';

function StepHeader(){
  const wz = useWizardContext();

  return (
    <div className="editor__wizard__step-header">
      {wz.step.title && <h4>{wz.step.title}</h4>}

      {wz.step.message && (
        <div>
          <div className="editor__wizard__step-header__message">
            {wz.step.message}
          </div>
        </div>
      )}
    </div>
  );
}

export default StepHeader;
