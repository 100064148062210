import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class IconTrashCan extends PureComponent {
  render() {
    return (
      <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1">
        <defs>
          <path
            d="M21.3043478,8.38674033 C21.6885459,8.38674033 22,8.68356812 22,9.04972376 C22,9.41587939 21.6885459,9.71270718 21.3043478,9.71270718 L19.8898551,9.71270718 L19.8898551,19.5690608 C19.8898551,20.9116314 18.7478568,22 17.3391304,22 L10.6608696,22 C9.25214325,22 8.11014493,20.9116314 8.11014493,19.5690608 L8.11014493,9.71270718 L6.69565217,9.71270718 C6.31145409,9.71270718 6,9.41587939 6,9.04972376 C6,8.68356812 6.31145409,8.38674033 6.69565217,8.38674033 L11.0550725,8.38674033 L11.0550725,7.54696133 C11.0550725,6.69259818 11.7817987,6 12.6782609,6 L15.3217391,6 C16.2182013,6 16.9449275,6.69259818 16.9449275,7.54696133 L16.9449275,8.38674033 L21.3043478,8.38674033 Z M12.4463768,7.54696133 L12.4463768,8.38674033 L15.5536232,8.38674033 L15.5536232,7.54696133 C15.5536232,7.42490945 15.4498052,7.32596685 15.3217391,7.32596685 L12.6782609,7.32596685 C12.5501948,7.32596685 12.4463768,7.42490945 12.4463768,7.54696133 Z M18.4753623,19.5911602 L18.4985507,9.71270718 L9.50144928,9.71270718 L9.50144928,19.5911602 C9.50144928,20.2014196 10.0205394,20.6961326 10.6608696,20.6961326 L17.315942,20.6961326 C17.9562722,20.6961326 18.4753623,20.2014196 18.4753623,19.5911602 Z M15.7391304,19.0828729 C15.3549323,19.0828729 15.0434783,18.7860451 15.0434783,18.4198895 L15.0434783,11.8121547 C15.0434783,11.4459991 15.3549323,11.1491713 15.7391304,11.1491713 C16.1233285,11.1491713 16.4347826,11.4459991 16.4347826,11.8121547 L16.4347826,18.4198895 C16.4347826,18.7860451 16.1233285,19.0828729 15.7391304,19.0828729 Z M12.2608696,19.0828729 C11.8766715,19.0828729 11.5652174,18.7860451 11.5652174,18.4198895 L11.5652174,11.8121547 C11.5652174,11.4459991 11.8766715,11.1491713 12.2608696,11.1491713 C12.6450677,11.1491713 12.9565217,11.4459991 12.9565217,11.8121547 L12.9565217,18.4198895 C12.9565217,18.7860451 12.6450677,19.0828729 12.2608696,19.0828729 Z"
            id="path-1"
          />
        </defs>
        <g id="Icons-/-Remove" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <use id="Icon" fill={this.props.fillColor} fillRule="nonzero" xlinkHref="#path-1" />
        </g>
      </svg>
    );
  }
}

IconTrashCan.defaultProps = {
  fillColor: '#C50800'
};

IconTrashCan.propTypes = {
  fillColor: PropTypes.string
};

export default IconTrashCan;
