import * as React from 'react';
import cn from 'classnames';

type Props = {
  selected?: boolean;
  setActive: () => void;
  handleSelection: () => void;
  id?: number | string;
  children?: any;
  active?: boolean;
  cy?: string;
  key?: string;
};

// @ts-ignore
function Suggestion(props: Props){
  const r: { current: HTMLDivElement } = React.createRef();

  React.useEffect(() => {
    if (props.active && r && r.current) {
      r.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  });

  return (
    <React.Fragment>
      {props.selected ? null : (
        <div
          data-cy={props.cy}
          ref={r}
          className={cn({
            'editor__multi-select__dropdown__list-item': true,
            'editor__multi-select__dropdown__list-item--active': props.active
          })}
          key={props.id}
          // @ts-ignore
          onMouseEnter={() => props.setActive()}
          onClick={() => {
            props.handleSelection();
          }}
        >
          {props.children}
        </div>
      )}
    </React.Fragment>
  );
}

export default Suggestion;
