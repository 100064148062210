import React from "react";
import {FieldsInputsMapper} from "@/data-outreach/_core/forms/components/field-mappers";
import BooleanRow from "@/library/boolean-row/edit";
import TextArea from "@/library/text-area";
import PlaceAutocomplete from '@/PlaceAutocomplete';
import DatePicker from './closure-date-picker';
import Link from "@library/link/view";
import CheckboxWithLabel from '@library/CheckboxWithLabel';
import {useContext} from "@/data-outreach/_core/forms/useContext";
import Reason from './Reason';
import useIndex from "@/hooks/useIndex";
import type {Category} from '../../../v1/data';
import SearchTree from '@library/tree-select/search-tree';
import Location from './Location';
import InputWithLabel from '@library/input-with-label';
import type {State} from "../../../types";

function PlaceSelection(props) {
  const url = (props.place.thumbnail && props.place.thumbnail.url) || '';
  return (
    <Link onClick={props.onClick} className="corrections__place-selection corrections__item-list__item">
      <div className="boone-image__thumbnail mr-2" style={{ backgroundImage: 'url(' + url + ')' }} />
      <div className="place-list-details">
        <b>{props.place.name}</b>
        <br />
        <small>{props.place.address}</small>
        { props.action && <div className="corrections__item-list__action">{props.action}</div> }
      </div>
    </Link>
  );
}

function CategoryTree(props) {
  const index = useIndex(props.categories_list, "public_id", {clearChildren: true});
  const _noop = () => {};
  const selected = props.selectedCategories.map((category) => category.public_id);

  const updateCategories = (categories) => {
    const selectedCategories = categories.map((category) => {
      return {
        public_id: category.public_id,
        public_id_breadcrumbs: category.public_id_breadcrumbs
      }
    });

    props.setSelectedCategories(selectedCategories);
  };

  return <SearchTree
          selection={selected}
          index={index}
          cancel={_noop}
          clear={_noop}
          save={_noop}
          onSelectionUpdated={updateCategories}
          mirrorParent={false}
          mirrorChild={true}
          showSelection={true}
          saveText=""
          showCancel={false}
          showSave={false}
          showRemoveAll={false}
          nothingSelectedText="This Place Has No Categories - Select Categories Above"
        />
}

type DuplicatePlace = {
  id: number,
  thumbnail?: string,
  name: string,
  address: string
}

export function GeneralInfo() {
  const ctx: {
    state?: State;
    setField: (value: Category[] | string | boolean, field: string) => void;
    updateField: (event, field: string) => void;
  } = useContext();

  const [name] = React.useState(ctx.state.name);
  const [categories, setCategories] = React.useState(ctx.state.categories);
  const [duplicateName, setDuplicateName] = React.useState(ctx.state.name);
  const [reason, setReason] = React.useState(ctx.state.reason);
  const [start_date, setStartDate] = React.useState(ctx.state.start_date);
  const [end_date, setEndDate] = React.useState(ctx.state.end_date);
  const [duplicatePlaces, setDuplicatePlaces] = React.useState([]);
  const [focused, setFocused] = React.useState(null);
  const [address1, setAddress1] = React.useState(ctx.state.address1);
  const [address2, setAddress2] = React.useState(ctx.state.address2);
  const [city, setCity] = React.useState(ctx.state.city);
  const [state, setState] = React.useState(ctx.state.state);
  const [postal_code, setPostalCode] = React.useState(ctx.state.postal_code);

  const START_DATE_ID = 'start_date';
  const END_DATE_ID = 'end_date';

  const setSelectedCategories = (selectedCategories) => {
    ctx.setField(selectedCategories, 'categories');
    setCategories(selectedCategories);
  }

  function updateAddress1(address) {
    ctx.setField(address, 'address1');
    setAddress1(address)
  }

  function updateAddress2(address) {
    ctx.setField(address, 'address2');
    setAddress2(address)
  }

  function updateCity(city) {
    ctx.setField(city, 'city');
    setCity(city)
  }

  function updateState(state) {
    ctx.setField(state, 'state');
    setCity(state)
  }

  function updatePostalCode(postal_code) {
    ctx.setField(postal_code, 'postal_code');
    setPostalCode(postal_code)
  }

  function updateDuplicatePlaces(updatedPlaces) {
    ctx.setField(updatedPlaces.map((p) => p.id), 'duplicate_ids');
    setDuplicatePlaces(updatedPlaces)
  }

  function addDuplicate(duplicatePlace) {
    const updatedPlaces = [...duplicatePlaces]
    updatedPlaces.push(duplicatePlace)

    updateDuplicatePlaces(updatedPlaces)
  }

  function removeDuplicate(duplicatePlace) {
    const updatedPlaces = [...duplicatePlaces]
    const indexToRemove = updatedPlaces.findIndex((place) => place.id == duplicatePlace.id);
    updatedPlaces.splice(indexToRemove, 1);

    updateDuplicatePlaces(updatedPlaces)
  }

  function renderDuplicates() {
    return duplicatePlaces.map((duplicatePlace) => {
        return (<PlaceSelection
          place={{...duplicatePlace}}
          action="Unselect"
          onClick={() => removeDuplicate(duplicatePlace)}
        />);
    })
  }

  function setSelectedStartDate(v) {
    ctx.setField(v, "start_date");
    setStartDate(v);
    setFocused(END_DATE_ID);
  };

  function setSelectedEndDate(v) {
    ctx.setField(v, "end_date");
    setEndDate(v)
    setFocused(null);
  };

  function setSelectedReason(v) {
    setReason(v)
    ctx.setField(v, "reason")
  }

  return (
    <div className="editor__data-outreach__corrections">
      <h2>Help us update {name}</h2>
      <p>
        Select the type of report you would like to make and provide details
        below. Place updates are reviewed by moderators.
      </p>
      <h3>What would you like to report?</h3>

      <div className="editor__correction">
        {[
          {field: "correction", copy: "details about the place are incorrect"},
          {
            field: "closed_permanently",
            copy: "this place is no longer in operation or open to the public",
          },
          {
            field: "closed_temporarily",
            copy: "this place is closed but will reopen at a later date",
          },
          {
            field: "does_not_exist",
            copy: "this place does not exist at this location",
          },
          {field: "duplicate", copy: "there are duplicates of this place"},
          {
            field: "category_correction",
            copy: "a category for this place is wrong or missing",
          },
        ].map((t) => (
          <BooleanRow
            key={t.field}
            label={
              <>
                <span>{t.field.split("_").join(" ")}</span>
                <span className="editor__explainer">{t.copy}</span>
              </>
            }
            value={ctx.state.issue_type === t.field}
            onChange={(e) => {
              ctx.state.issue_type === t.field
                ? ctx.setField("", "issue_type")
                : ctx.setField(t.field, "issue_type");
            }}
          />
        ))}
      </div>

      {ctx.state.issue_type === "category_correction" && (
        <>
          <h3>Select Categories For This Place</h3>
          <CategoryTree
            categories_list={ctx.state.categories_list}
            selectedCategories={categories}
            setSelectedCategories={setSelectedCategories}
          />
        </>
      )}

      {ctx.state.issue_type === "correction" && (
        <>
          <h3>Place Details</h3>
          <div className="editor__fields--long">
            <FieldsInputsMapper
              state={ctx.state}
              updateField={ctx.updateField}
              fields={[
                ["Name", "name", "name", "text", true],
                ["Phone", "phone", "tel", "phone", true],
                ["Email", "email", "email", "email", true],
                ["Website", "website", "url", "website", true],
              ]}
            />
          </div>
          <div className="editor__fields--short">
            <InputWithLabel
              label="Address1"
              labelClassName="editor__label"
              value={ctx.state.address1}
              onChange={(e) => updateAddress1(e.target.value)}
            />
            <InputWithLabel
              label="Address2"
              labelClassName="editor__label"
              value={ctx.state.address2}
              onChange={(e) => updateAddress2(e.target.value)}
            />
          </div>
          <div className="editor__fields--short">
            <InputWithLabel
              label="City"
              labelClassName="editor__label"
              value={ctx.state.city}
              onChange={(e) => updateCity(e.target.value)}
            />
            <InputWithLabel
              label="State"
              labelClassName="editor__label"
              value={ctx.state.state}
              onChange={(e) => updateState(e.target.value)}
            />
            <InputWithLabel
              label="Zip Code"
              labelClassName="editor__label"
              value={ctx.state.postal_code}
              onChange={(e) => updatePostalCode(e.target.value)}
            />
          </div>
          <div className="editor__fields--long">
            <Location
              city={ctx.state.city}
              address1={ctx.state.address1}
              address2={ctx.state.address2}
              state={ctx.state.state}
              postal_code={ctx.state.postal_code}
              latitude={ctx.state.latitude}
              longitude={ctx.state.longitude}
              setField={ctx.setField}
            />
            <FieldsInputsMapper
              state={ctx.state}
              updateField={ctx.updateField}
              fields={[
                ["", "latitude", "latitude", "hidden", true],
                ["", "longitude", "longitude", "hidden", true],
                ["", "location_set_via", "location_set_via", "hidden", true],
              ]}
            />
          </div>
        </>
      )}

      {ctx.state.issue_type === "closed_temporarily" && (
        <>
          <h3>Closure Details</h3>

          <h3>Start Date</h3>
          <div className="editor__fields--short">
            <DatePicker
              id="start_date"
              key={'closure-' + START_DATE_ID}
              date={ctx.state.start_date}
              focused={focused === START_DATE_ID}
              ownerControlled={true}
              handleChange={(p, v) => setSelectedStartDate(v)}
              onFocusChange={({ focused }) =>
                setFocused(focused ? START_DATE_ID : null)}
              placeholder={'Date'}
            />
          </div>
          <h3>End Date</h3>
          <div className="editor__fields--short">
            <DatePicker
              id="end_date"
              key={'closure-' + END_DATE_ID}
              minimum={ctx.state.start_date}
              date={ctx.state.end_date}
              focused={focused === END_DATE_ID}
              ownerControlled={true}
              handleChange={(p, v) => setSelectedEndDate(v)}
              onFocusChange={({ focused }) =>
                setFocused(focused ? END_DATE_ID : null)}
              placeholder={'Date'}
            />
          </div>
          <h3>Reason</h3>
          <div className="editor__fields--long editor__fields--dropdown">
            <Reason
              reason={ctx.state.reason}
              handleChange={(p, v) => setSelectedReason(v)}
            />
          </div>
        </>
      )}

      {ctx.state.issue_type === "duplicate" && (
        <>
          <h3>Find Duplicate Places</h3>

          <input
            data-cy="create-listing-search-input"
            type="text"
            className="editor__input"
            id="duplicateName"
            placeholder="Search for Duplicated Place"
            value={duplicateName}
            autoComplete="false"
            onChange={e => setDuplicateName(e.target.value)}
          />

          <PlaceAutocomplete
            endpoint='/corrections/autocomplete'
            query={duplicateName}
            onResults={() => null}
            excludeIds={duplicatePlaces.map((p) => p.id).concat(ctx.state.canonical_place_id)}
            SuggestionRow={(suggestedPlace) => {
              return (<PlaceSelection
                        place={{...suggestedPlace}}
                        action="Select Duplicate"
                        onClick={() => addDuplicate(suggestedPlace)}
                      />);
            }}
          />

          <h3>Selected Duplicates</h3>

          <div className='corrections__item-list'>
            { duplicatePlaces.length == 0 ? <span> None Selected - Search For Duplicates Above </span> : renderDuplicates() }
          </div>
        </>
      )}

      <h3>Anything else?</h3>
      <TextArea
        className="editor__comments"
        label={"Comments (optional)"}
        value={ctx.state.comment}
        onChange={(e) => {
          ctx.updateField(e, "comment");
        }}
      />
      <FieldsInputsMapper
        state={ctx.state}
        updateField={ctx.updateField}
        fields={[["Your Email (optional)", "user_email", "email", "email", true]]}
      />
      <div className="editor__fields--long">
        <CheckboxWithLabel
          labelClassName="editor__owner--label"
          inputClassName="editor__owner--checkbox"
          labelId="owner__checkbox"
          label={"I am the owner or manager of this place" }
          onChange={(e) => {
            const nextVal = e?.currentTarget?.checked;
            ctx.setField(Boolean(nextVal), "owner");
          }}
          checked={Boolean(ctx.state.owner)}
        />
      </div>
    </div>
  );
}

export default GeneralInfo;
