import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterForm from "@/library/filter-form";
import AuditAssignmentDropdown from './AuditAssignmentDropdown';
import AssignUserOption from '@library/dropdown/options/AssignUserOption';
import CheckboxWithLabel from '@library/CheckboxWithLabel';

class AuditQueueFilter extends Component {
  state = {
    prevUser: this.props.user ? parseInt(this.props.user) : this.props.user,
    user: this.props.user ? parseInt(this.props.user) : this.props.user,
    prevAnyone: this.props.anyone,
    anyone: this.props.anyone,
    open: false,
    active: false
  };

  handleChange(anyone = null, user = null) {
    /**
     * Filter states:
     *  - anyone ~ `/listings?audit=1`
     *  - a specific user ~ `/listings?audit_user=5`
     *  - unfiltered ~ `/listings`
     **/
    this.props.applyFilter({[this.props.anyoneParam]: anyone});
    this.props.applyFilter({[this.props.userParam]: user});
  }

  handleApply() {
    if (this.state.anyone && !this.state.user) {
      this.setState({ active: true, prevAnyone: true });
      this.handleChange(1);
    } else if (this.state.anyone && this.state.user) {
      this.setState({ active: true, prevUser: this.state.user, prevAnyone: false });
      this.handleChange(null, this.state.user);
    } else {
      this.setState({ active: false, prevUser: null, prevAnyone: false });
      this.handleChange();
    }
  }

  toggle() {
    this.setState(prevState => ({
      anyone: !prevState.anyone,
      open: !prevState.open
    }));
  }

  handleCancel() {
    this.setState({
      user: this.props.user,
      anyone: this.props.anyone
    });
  }

  render() {
    const user = this.props.users.find(u => u.id === this.state.user);
    const prevUser = this.props.users.find(u => u.id === this.state.prevUser);

    const BaseLabel = ({ children }) => (
      <div className="audit-button__contents">
        <div>For</div>
        {children}
      </div>
    );

    const FilterLabel =
      prevUser || this.state.prevAnyone ? (
        this.state.prevAnyone ? (
          <BaseLabel>
            <AssignUserOption name="Anyone" booneLogo={true} />
          </BaseLabel>
        ) : (
          <BaseLabel>
            <AssignUserOption hideLastName name={prevUser.name} url={prevUser.avatar_url} />
          </BaseLabel>
        )
      ) : (
        this.props.label
      );

    return (
      <FilterForm
        btnClass={this.props.btnClass}
        name={this.props.type}
        active={this.state.active}
        label={FilterLabel}
        onApply={() => this.handleApply()}
        onCancel={() => this.handleCancel()}
      >
        <div className="audit-filter audit-filter__container">
          <CheckboxWithLabel
            labelClassName="audit-filter audit-filter__input-label"
            labelId="audit-filter-checkbox"
            label={`${this.props.checkboxLabel}${this.state.anyone ? ' by ' : ''}`}
            onChange={() => this.toggle()}
            checked={Boolean(this.state.anyone)}
          />
          {this.state.anyone && (
            <div className="audit-filter audit-filter__dropdown-btn btn btn-white">
              <AuditAssignmentDropdown
                hasAudit={true}
                direction="down"
                right={false}
                menuClassName="boone-dropdown boone-dropdown--nested boone-dropdown__menu boone-dropdown__menu--below"
                showRemoveOption={false}
                onAnyoneClick={() => this.setState({ user: null })}
                onUserClick={id => this.setState({ user: id })}
                toggleIcon="label-arrow-down"
                users={this.props.users}
                assignedUserId={this.state.user}
                showOnHover={false}
                hideLastName={this.props.hideLastName}
                Label={() =>
                  user ? (
                    <AssignUserOption hideLastName={this.props.hideLastName} name={user.name} url={user.avatar_url} />
                  ) : (
                    <AssignUserOption name="Anyone" booneLogo={true} />
                  )
                }
              />
            </div>
          )}
        </div>
      </FilterForm>
    );
  }

  renderButtonLabel() {
    return this.state.appliedValue ? this.props.activeLabel : this.props.label;
  }
}

AuditQueueFilter.propTypes = {
  label: PropTypes.string.isRequired,
  activeLabel: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
  applyFilter: PropTypes.func.isRequired,
  anyone: PropTypes.string,
  anyoneParam: PropTypes.string,
  btnClass: PropTypes.string,
  type: PropTypes.string,
  hideLastName: PropTypes.bool,
  user: PropTypes.string,
  userParam: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar_url: PropTypes.string
    })
  )
};

export default AuditQueueFilter;
