import React from "react";

import Link from "@library/link/view";

import _ from "lodash";

import { findDOMNode } from "react-dom";

// Bootstrap
import { Badge } from "reactstrap";

// Material
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

import styled from "styled-components";

import { DragSource } from "react-dnd";
import ItemTypes from "./ItemTypes";

// -----------------------------------------------
// STYLED-COMPONENTS -----------------------------
// -----------------------------------------------
const StyledTableRow = styled.tr`
  cursor: pointer;

  .add-button {
    visibility: hidden;
  }

  &:hover {
    .add-button {
      visibility: visible;
    }
  }

  /* FIX: Not working */
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledMapIcon = styled(Avatar)`
  background-color: ${props => props.backgroundcolor} !important;
  color: #ffffff !important;
  height: 20px !important;
  width: 20px !important;
  font-size: 10px !important;
  text-align: center !important;
  font-weight: bold !important;
`;

// -----------------------------------------------
// DRAG-AND-DROP ---------------------------------
// -----------------------------------------------
const placeSource = {
  beginDrag(props) {
    return props.place;
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }

    // When dropped on a compatible target...
    // const item = monitor.getItem();
    // const dropResult = monitor.getDropResult();
  },
  canDrag(props) {
    return props.remappable;
  },
};

// eslint-disable-next-line no-unused-vars
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

// ==============================================-
// COMPONENT->PLACE ==============================
// ==============================================-
class Place extends React.Component {

  flyTo = e => {
    if (this.props.place.locations.length > 0) {
      const location = this.props.place.locations[0];
      const { longitude, latitude } = location;

      if (_.isNull(longitude) || _.isNull(latitude)) {
        alert("Cannot fly to location since it does not have coordinates.");
        return;
      }

      this.props.flyTo({
        center: [longitude.toFixed(4), latitude.toFixed(4)],
        zoom: 20
      });
    }
  };

  getCategories = (place) => {
    if (place.categories && place.categories.length > 0) {
      return place.categories.map((category) => {
        return category
      })
    }
  }

  render() {
    const {
      connectDragSource,
      // eslint-disable-next-line no-unused-vars
      isDragging,
      place,
      // eslint-disable-next-line no-unused-vars
      classes,
      target,
      breakPlaceIntoNewCanonical,
      canonical,
      matchType,
      itemType,
      isNearMatch,
      nearMatchApproved,
      remappable
    } = this.props;

    const tags = _.isUndefined(place.tags) ? [] : place.tags;

    const description = _.isNull(place.description) ? '' : place.description;

    return (
      <StyledTableRow
        ref={instance => connectDragSource(findDOMNode(instance))}
        onClick={this.flyTo}
      >
        <td>
          <StyledMapIcon backgroundcolor={canonical.map_number_color}>
            {place.map_icon_num}
          </StyledMapIcon>
        </td>
        <td>
          <Tooltip title={place.id}>
            <span>{itemType}</span>
          </Tooltip>
        </td>
        <td>
          <Tooltip title={place.id}>
            <span>{place.id}</span>
          </Tooltip>
        </td>
        {matchType === "group" && (<>
          <td><Link internalLink={"/listings/" + place.canonical_place_id}>{place.canonical_place_id}</Link></td>
          <td>{isNearMatch && ('Near Match')}</td>
          <td>{nearMatchApproved !== null && (nearMatchApproved ? 'Approved' : 'Not Approved')}</td>
        </>)}
        <td>
          <Tooltip title={place.source_name}>
            <span>{place.source_name}</span>
          </Tooltip>
        </td>
        <td>
          <Tooltip title={place.name}>
            <span>{place.name}</span>
          </Tooltip>
        </td>
        <td>{place.locations.length > 0 && place.locations[0].address1}</td>
        <td>
          <Tooltip title={this.getCategories(place)?.toString().replace('\n', '&#013;')}>
            <span>{
              this.getCategories(place)
            }</span>
          </Tooltip>
        </td>
        <td>
          {target &&
            !_.isObject(this.props.newCanonical) && (
              !this.props.remappable ?
                <>
                  <Tooltip
                    title="This place is not remappable"
                    className="lock"
                  >
                    <Icon
                      color="secondary"
                      className="lock"
                    >
                      lock
                    </Icon>
                  </Tooltip>
                </>
                :
                <Tooltip
                  title="Break Into New Canonical"
                  className="add-button"
                  onClick={e => breakPlaceIntoNewCanonical(e, place)}
                >
                  <Icon
                    color="secondary"
                    className="add-button"
                    onClick={e => breakPlaceIntoNewCanonical(e, place)}
                  >
                    playlist_add
                  </Icon>
                </Tooltip>
            )}
        </td>
      </StyledTableRow>
    );
  }
}

export default DragSource(ItemTypes.PLACE, placeSource, connect => ({
  connectDragSource: connect.dragSource(),
}))(Place);
