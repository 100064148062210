import React, { PureComponent } from 'react';

class BooneLoaderSpinner extends PureComponent {
  render() {
    return (
      <div className="boone-loader boone-loader__container above-scrim">
        <div className="boone-loader boone-loader__round-spinner" />
      </div>
    );
  }
}

export default BooneLoaderSpinner;
