import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { createTreeList } from './util';
import useSmartState from '@/hooks/useSmartState';

type Props = {
  selection?: string[];
  save: (public_ids: string[]) => void;
  clear?: () => void;
  cancel?: () => void;
  index: { byId: any; allNodes: any; ids: any; getNode: any; };
  mirrorParent?: boolean;
  mirrorChild?: boolean;
  showSelection: boolean;
};

const arrayToMap = arr => arr && arr.length > 0 ? arr.reduce((p, c) => { p[c] = true; return p }, {}) : {};

function useSearchTree(props: Props) {

  const [query, setQuery] = useSmartState('');
  const [cache, setCache] = useSmartState({ '@': createTreeList(props.index.allNodes()) });
  const [currentTree, setCurrentTree] = useSmartState('@');
  const [pendingMap, setPendingMap] = useSmartState(props.selection ? arrayToMap(props.selection) : {});
  const [pendingArray, setPendingArray] = useSmartState([]);

  React.useEffect(() => {
    const selection = Object.keys(pendingMap).filter(key => pendingMap[key]);
    const t = [...selection]
    setPendingArray(t.reverse())

  }, [pendingMap]);

  React.useEffect(() => {
    if (!cache['@']) setCache({ '@': createTreeList(props.index.allNodes()) })
  }, [props.index]);

  React.useEffect(() => {
    if (query) {

      const searchText = query.toLowerCase();

      if (cache[searchText]) {
        setCurrentTree(searchText); // grab cached search
      } else {
        search(searchText); // new search, results stored in cache
      }
    } else {
      if (currentTree !== '@') {
        setCurrentTree('@');
      }
    }
  }, [query]);

  // Constructs node or public id breadcrumbs array if it doesn't exist
  const getAncestors = (node, ids = false) => {

    if (node.root) return []
    if (node.public_id_breadcrumbs && ids) return node.public_id_breadcrumbs;
    if (node.public_id_breadcrumbs) return node.public_id_breadcrumbs.map(public_id => props.index.getNode(public_id));

    const t = []
    let n = node
    do {
      if (ids) {
        t.push(n.public_id)
      } else {
        t.push(n)
      }
      if (!n.parent_public_id) break;
      n = props.index.getNode(n.parent_public_id);
    } while (n);

    return t.reverse();
  }

  const search = (searchText) => {
    const matches = props.index.allNodes().filter(node => {
      const clean = node.name.toLowerCase();
      const idx = clean.indexOf(searchText);
      return idx !== -1 && (idx === 0 || clean[idx - 1] === ' ');
    });

    // match + parents until root
    const nodes = uniqBy(
      matches.reduce((all, node) => {
        all = [...all, ...getAncestors(node)];
        return all;
      }, []),
      'public_id'
    );

    setCache({ ...cache, [searchText]: createTreeList(nodes) });
    setCurrentTree(searchText);
  }

  const update = (id, value) => {
    setPendingMap({ ...pendingMap, [id]: value })
  }

  const createAncestorMap = () => {
    return props.index.ids.map(id => props.index.getNode(id)).reduce((p, c) => {
      p[c.public_id] = null;
      if (c['public_id_breadcrumbs']) {
        p[c.public_id] = c['public_id_breadcrumbs'];
      }
      return p;
    }, {})
  }

  return {
    getNode: (id) => props.index.getNode(id),
    update,
    setQuery,
    query,
    currentTree,
    cache,
    pendingArray,
    pendingMap,
    ancestorMap: createAncestorMap(),
    remove: (public_id) => setPendingMap({ ...pendingMap, [public_id]: false }),
    save: () => {
      props.save(pendingArray);
    },
    cancel: () => {
      if (props.cancel) props.cancel();
      setPendingMap(arrayToMap(props.selection))
    },
    clear: () => {
      if (props.clear) props.clear();
      setPendingMap({});
    }
  }
}

export default useSearchTree;
