import * as React from "react";
import { useState, useEffect } from 'react';

export default function Options(props) {

  const optionsList = () =>
    props.options.length > 0 &&

    props.options.map(option =>
      <input key={option.label} type="button" className="btn __button" defaultValue={option.label} onClick={() => { option.onClick() }} />
    );

  return (
    <div className="__options">
      {optionsList()}
    </div>
  );
}