import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
//import Tooltip from '@material-ui/core/Tooltip';

import styled from 'styled-components';

const StyledMatchActions = styled.div`
  margin-top: 8px;
  padding: 8px 0px 8px 0px;

  a {
    text-decoration: none;
  }
`;

const MatchActions = props => {
  return (
    <StyledMatchActions>
      <Button
        onClick={props.confirmAllMatches}
        color="primary"
        style={{
          float: 'right'
        }}
      >
        <Icon>done_all</Icon>
        Confirm All
      </Button>
    </StyledMatchActions>
  );
};

const ContinueActions = props => {
  return (
    !props.next_reviewing_match_set_id ?
      <button
        className='btn btn-white btn btn-primary'
        onClick={props.skipOrReturn}
        disabled={props.next_reviewing_match_set_id }
      >
        Return to Match Run
      </button>
      :
      <button
        className='btn btn-white btn btn-primary'
        onClick={props.skipOrReturn}
        disabled={!props.next_reviewing_match_set_id }
      >
        Skip Current
      </button>
  )
}

MatchActions.propTypes = {
  confirmAllMatches: PropTypes.func
};

ContinueActions.propTypes = {
  skipOrReturn: PropTypes.func,
  next_reviewing_match_set_id: PropTypes.number,

}

export { MatchActions, ContinueActions };
