import React from "react";
import cn from "classnames";
import "./input/styles.scss";

export type Props = {
  active?: boolean;
  className?: string;
  cy?: string;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  hidden?: boolean;
  id?: string;
  labelId?: string;
  max?: number;
  min?: number;
  name?: string;
  onBlur?: React.ChangeEventHandler;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  placeholder?: string;
  required?: boolean;
  tabIndex?: number;
  type?: string;
  value?: string | number;
};

export const Input = React.forwardRef(
  (
    {
      className,
      cy,
      forwardedRef,
      hidden,
      id,
      labelId,
      name,
      onBlur,
      onChange,
      onKeyDown,
      placeholder,
      required,
      tabIndex,
      type,
      value,
      ...props
    }: Props,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    return (
      <input
        id={id}
        name={name}
        ref={ref || forwardedRef}
        tabIndex={tabIndex}
        aria-labelledby={labelId}
        className={cn("editor__input", className)}
        type={type}
        value={value || ""}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        hidden={hidden}
        aria-hidden={hidden}
        required={required}
        aria-required={required}
        data-cy={cy || "input"}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

Input.defaultProps = {
  type: "text",
  value: "",
};

export default Input;
