import React from 'react';
import Modal from '../../Modal';
import IconHide from '../../icons/IconHide';

type Props = {
  modalId: string;
  onRejectAll: () => void;
};

function IgnoreAllSuggestionsModal(props: Props) {
  const cancel = () => {
    $('#' + props.modalId).modal('hide');
  };
  return (
    <Modal
      id={props.modalId}
      modalClass="editor__listing__suggestions__ignore-all-modal modal-dialog-centered suggestion-modal"
    >
      <div className="modal-header suggestion-modal-header">Ignore all pending suggestions?</div>
      <p className="modal-body">
        Are you positive you want to ignore all pending suggestions for this place? This can&apos;t be undone.
			</p>
      <div className="modal-footer">
        <a href="" title="Ignore suggestion" onClick={cancel}>
          Cancel
				</a>
        <button
          className="editor__listing__suggestions__ignore-button--primary"
          aria-label="Ignore all"
          onClick={props.onRejectAll}
        >
          <IconHide viewBoxX="-6" viewBoxY="-5" />
          Ignore all
				</button>
      </div>
    </Modal>
  );
}

export default IgnoreAllSuggestionsModal;
