import React from 'react';
import ViewField from '../../library/view-field';
import InputWithLabel from '../../library/input-with-label';
import { EditData, ViewData } from '../../library/data-section';
import TextArea from '@/library/text-area';

type Props = {
  idx: number;
  sourceId?: string | number;
  placeId?: number;
  subtitle: { text: string };
  description: { text: string };
  userEditingStrategy: 'update' | 'none';
  reset?: (id: string) => void;
  onChange?: (e: React.SyntheticEvent, key: string, id: string | number) => void;
};

const Editorial = (props: Props) => {
  const [editing, setEditing] = React.useState(false);
  const cancel = (id) => {
    if (props.reset) {
      props.reset(id);
    }
    setEditing(false);
  };

  const onSuccess = () => {
    setEditing(false);
  };

  const onChange = (e: React.SyntheticEvent, editorialType: string, placeId: string | number) => {
    if (props.onChange) {
      props.onChange(e, editorialType, placeId);
    }
  };

  return (
    <div data-cy="editorial" className="boone-listing__editorial__source" data-place-id={props.placeId}>
      <ViewData
        sectionTitle="Editorial"
        userEditingStrategy={props.userEditingStrategy}
        show={!editing}
        onClick={() => setEditing(true)}
      >
        <ViewField label="Subtitle" value={props.subtitle.text || ''} />
        <ViewField
          className="editor__textarea editor__textarea--view"
          label="Description"
          value={props.description.text || ''}
        />
      </ViewData>
      <EditData
        sectionTitle="Editorial"
        show={editing}
        params={{
          place_id: props.placeId,
          source_id: props.sourceId,
          listing: {
            description: props.description.text || null,
            subtitle: props.subtitle.text || null,
          },
        }}
        onSuccess={() => onSuccess()}
        cancel={() => cancel(props.placeId)}
        userEditingStrategy={props.userEditingStrategy}
      >
        <InputWithLabel
          inputName={'place_' + props.placeId + '_subtitle'}
          label="Subtitle"
          value={props.subtitle.text}
          onChange={(e) => onChange(e, 'subtitle', props.placeId)}
        />
        <TextArea value={props.description.text} onChange={(e) => onChange(e, 'description', props.placeId)} />
      </EditData>
    </div>
  );
};

export default Editorial;
