import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';

export const AvatarName = ({ name, children, booneLogo, ...props }) => (
  <div className={props.className || 'avatar-name'} onClick={props.onClick}>
    <Avatar className={props.avatarClassName} url={props.url} booneLogo={booneLogo} />
    <span className={props.nameClassName}>{name}</span>
    {children}
  </div>
);

AvatarName.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  nameClassName: PropTypes.string,
  avatarClassName: PropTypes.string,
  booneLogo: PropTypes.bool,
  children: PropTypes.any
};

export default AvatarName;
