import React from "react";
import FormV1 from "./form";
// import * as hooks from "@/library/launch-darkly/hooks";

function ConnectorV1() {
  // const [enabled] = hooks.useFlag("** orvp flag **", false);
  return (
    <React.Fragment>
      <FormV1 />
    </React.Fragment>
  );
}

export default ConnectorV1;
