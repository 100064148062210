import React from "react";
import "./styles";

function Summary() {
  return (
    <div className="editor__data-outreach__corrections__post-submit">
      <h2>Thank you!</h2>
      <br />
      <p>
        We really appreciate you taking the time to help us out! Your submission
        is under review by our team.
      </p>
    </div>
  );
}

export default Summary;
