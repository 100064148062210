import * as React from 'react';
import Users from './users';
import PageHeading from '../library/PageHeading';
import ContentHeading from '../layout/ContentHeading';
import { StoreProvider } from '../state';

export default function Organization(props){
  return (
    <StoreProvider user={props.user} org={props.org}>
      <div className="editor__main">
        <div className="editor__main__header">
          <PageHeading>
            <ContentHeading title={props.title} titleProps={props.titleProps} />
          </PageHeading>
        </div>
        <div className="editor__main__body">
          <Users {...props} />
        </div>
      </div>
    </StoreProvider>
  );
}
