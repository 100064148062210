import React from 'react';

type Props = {
  query?: string;
  childAssigned: boolean;
  hasChildren: boolean;
};

function useExpandedState({ hasChildren, query, childAssigned }: Props): any{
  const [isExpanded, setIsExpanded] = React.useState(
    hasChildren && Boolean(query)
  );

  React.useEffect(
    () => {
      if (hasChildren) {
        if (query && query !== '') {
          setIsExpanded(true);
        } else {
          setIsExpanded(childAssigned);
        }
      }
    },
    [query]
  );

  return [isExpanded, setIsExpanded];
}

export default useExpandedState;
