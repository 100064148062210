import React from 'react';
import '@library/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles';

type SortOption = {
  name: string;
  key: string;
  default?: string;
};

export function useSorting(initialSortOptions: SortOption[], initalSort: string) {
  const [activeSort, setActiveSort] = React.useState(initalSort || null);
  const [sortOptions, setSortOptions] = React.useState(initialSortOptions);

  const getSortLabel = (key: string) => {
    const sortOption = sortOptions.find((option) => option.key == key);
    const descSortKey = `-${key}`;

    if (activeSort == descSortKey) {
      return (
        <span onClick={() => setActiveSort(key)} className="editor__pagination__sort">
          {sortOption.name} <FontAwesomeIcon icon={'sort-down'} />
        </span>
      );
    } else if (activeSort == key) {
      return (
        <span onClick={() => setActiveSort(null)} className="editor__pagination__sort">
          {sortOption.name} <FontAwesomeIcon icon={'sort-up'} />
        </span>
      );
    } else {
      return (
        <span onClick={() => setActiveSort(descSortKey)} className="editor__pagination__sort">
          {sortOption.name} <FontAwesomeIcon icon={'sort'} style={{ opacity: 0.35 }} />
        </span>
      );
    }
  };

  return {
    activeSort,
    setActiveSort,
    getSortLabel,
    sortOptions,
    setSortOptions,
  };
}
