import classNames from 'classnames';
import React from 'react';

const truncate = function (text, maxLength) {
  if (text.length > maxLength) {
    return text.substr(0, maxLength - 3) + '...';
  } else {
    return text
  }
}


class AutocompleteRegion extends React.Component {

  // RENDER

  render() {
    return (
      <div className={classNames({
        'autocomplete-region': true,
        'highlighted': this.props.isHighlighted
      })}>

        <label className="autocomplete-item-name">
          <input
            className='mr-2'
            name={this.props.item.id}
            checked={this.props.isSelected}
            type="checkbox"
            value={this.props.item.id + ":" + this.props.item.name}
            onChange={this.onCheckboxChange}
          />
          {truncate(this.props.item.name, 100)}
        </label>
      </div>
    );
  }

  onCheckboxChange = () => {
    this.props.onItemSelectedChanged(this.props.item);
  }
}

export default AutocompleteRegion;
