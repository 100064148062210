import policy from '../policy';

describe('Policy', () => {
  describe('businessOwnerUser', () => {
    const permissions = policy(true, false, false);
    it('view media permissions', () => {
      expect(permissions.canViewMedia.fromOwner).toBeTruthy();
      expect(permissions.canViewMedia.fromOrg).toBeTruthy();
      expect(permissions.canViewMedia.fromOthers).toBeTruthy();
    });
    it('flag media permissions', () => {
      expect(permissions.canFlagMedia.fromOwner).toBeFalsy();
      expect(permissions.canFlagMedia.fromOrg).toBeFalsy();
      expect(permissions.canFlagMedia.fromOthers).toBeFalsy();
    });
    it('disable media permissions', () => {
      expect(permissions.canDisableMedia.fromOwner).toBeFalsy();
      expect(permissions.canDisableMedia.fromOrg).toBeTruthy();
      expect(permissions.canDisableMedia.fromOthers).toBeFalsy();
    });
    it('feature media permissions', () => {
      expect(permissions.canFeatureMedia.fromOwner).toBeFalsy();
      expect(permissions.canFeatureMedia.fromOrg).toBeTruthy();
      expect(permissions.canFeatureMedia.fromOthers).toBeTruthy();
    });
  });

  describe('superAdminUser', () => {
    const permissions = policy(false, false, true);
    it('view media permissions', () => {
      expect(permissions.canViewMedia.fromOwner).toBeTruthy();
      expect(permissions.canViewMedia.fromOrg).toBeTruthy();
      expect(permissions.canViewMedia.fromOthers).toBeTruthy();
    });
    it('flag media permissions', () => {
      expect(permissions.canFlagMedia.fromOwner).toBeTruthy();
      expect(permissions.canFlagMedia.fromOrg).toBeFalsy();
      expect(permissions.canFlagMedia.fromOthers).toBeTruthy();
    });
    it('disable media permissions', () => {
      expect(permissions.canDisableMedia.fromOwner).toBeFalsy();
      expect(permissions.canDisableMedia.fromOrg).toBeTruthy();
      expect(permissions.canDisableMedia.fromOthers).toBeFalsy();
    });
    it('feature media permissions', () => {
      expect(permissions.canFeatureMedia.fromOwner).toBeTruthy();
      expect(permissions.canFeatureMedia.fromOrg).toBeTruthy();
      expect(permissions.canFeatureMedia.fromOthers).toBeTruthy();
    });
  });

  describe('internalOrgUser', () => {
    const permissions = policy(false, true, false);
    it('view media permissions', () => {
      expect(permissions.canViewMedia.fromOwner).toBeTruthy();
      expect(permissions.canViewMedia.fromOrg).toBeTruthy();
      expect(permissions.canViewMedia.fromOthers).toBeTruthy();
    });
    it('flag media permissions', () => {
      expect(permissions.canFlagMedia.fromOwner).toBeFalsy();
      expect(permissions.canFlagMedia.fromOrg).toBeFalsy();
      expect(permissions.canFlagMedia.fromOthers).toBeFalsy();
    });
    it('disable media permissions', () => {
      expect(permissions.canDisableMedia.fromOwner).toBeFalsy();
      expect(permissions.canDisableMedia.fromOrg).toBeTruthy();
      expect(permissions.canDisableMedia.fromOthers).toBeFalsy();
    });
    it('feature media permissions', () => {
      expect(permissions.canFeatureMedia.fromOwner).toBeTruthy();
      expect(permissions.canFeatureMedia.fromOrg).toBeTruthy();
      expect(permissions.canFeatureMedia.fromOthers).toBeTruthy();
    });
  });

  describe('everyone', () => {
    const permissions = policy(false, false, false);
    it('view media permissions', () => {
      expect(permissions.canViewMedia.fromOwner).toBeTruthy();
      expect(permissions.canViewMedia.fromOrg).toBeTruthy();
      expect(permissions.canViewMedia.fromOthers).toBeTruthy();
    });
    it('flag media permissions', () => {
      expect(permissions.canFlagMedia.fromOwner).toBeFalsy();
      expect(permissions.canFlagMedia.fromOrg).toBeFalsy();
      expect(permissions.canFlagMedia.fromOthers).toBeFalsy();
    });
    it('disable media permissions', () => {
      expect(permissions.canDisableMedia.fromOwner).toBeFalsy();
      expect(permissions.canDisableMedia.fromOrg).toBeTruthy();
      expect(permissions.canDisableMedia.fromOthers).toBeFalsy();
    });
    it('feature media permissions', () => {
      expect(permissions.canFeatureMedia.fromOwner).toBeFalsy();
      expect(permissions.canFeatureMedia.fromOrg).toBeFalsy();
      expect(permissions.canFeatureMedia.fromOthers).toBeFalsy();
    });
  });
});
