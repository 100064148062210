import React from 'react';
import {IconAdd} from './icons/IconAdd'
import ListButton from "./ListButton";

export default class ListAddButton extends React.Component {
  render() {
    return (
      <ListButton {...this.props}>
        <IconAdd/>
      </ListButton>);
  }
}
