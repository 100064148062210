import React from 'react';

function useStateMapper(index, initialState){
  const { ids, byId } = index;
  const [state, setState] = React.useState(initialState);
  const [attributes, setAttributes] = React.useState([]);

  // Adds value and display state to def
  const setDefinitionState = ({ id, name, depends_on, ...def }) => {
    return {
      ...def,
      id,
      depends_on,
      label: name,
      value: state[id],
      show: depends_on ? Boolean(state[depends_on]) : true,
      onChange:
        (value) => {
          if (value !== null && value !== undefined && value.hasOwnProperty('target')) {
            // value is actually an event
            value = value.target.value
          }
          setState((prev) => ({ ...prev, [id]: value }));
        }
    };
  };

  // Updates def + defs of dependents and returns
  // array of only top level definitions
  const updateComponentDefinitions = () => {
    setAttributes(
      ids
        .map((id) => {
          const node = setDefinitionState(byId[id]);

          if (node['dependents'] && node['dependents'].length > 0) {
            node['dependents'] = node['dependents'].map((dependentId) =>
              setDefinitionState(byId[dependentId])
            );
          }
          return node;
          // Filter out dependent nodes so they are not shown twice
        })
        .filter((node) => !node['depends_on'])
    );
  };

  React.useEffect(
    () => {
      updateComponentDefinitions();
    },
    [state]
  );

  return [state, setState, attributes];
}

export default useStateMapper;
