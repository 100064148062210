import React from 'react';
import Info from '../info';
import AlertIcon from '@/icons/Alert';
import './styles';

type Props = {
  description?: string;
  id?: string;
  onClick?: (e: any) => void;
  show?: boolean;
  className?: string;
  cy?: string;
  text?: string;
  missingData?: boolean;
};

const Label = ({
  id,
  onClick,
  text,
  className = 'editor__label',
  description,
  ...props
}: Props) => {
  const show = props.show || true;
  return show ? (
    <label
      id={id}
      onClick={onClick}
      data-cy={props.cy || 'label'}
      className={className || 'editor__label'}
    >
      <span className="editor__label__text">{text}</span>
      {description ? (
        <Info message={description} id={id} />
      ) : (
        <div className="editor__info__icon" />
      )}
      {props.missingData ? (
        <AlertIcon />
      ) : (
        <div className="editor__data__missing" />
      )}
    </label>
  ) : null;
};

export default Label;
