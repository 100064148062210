import React from 'react';
import {IconRemove} from './icons/IconRemove'
import ListButton from "./ListButton";


export default class ListRemoveButton extends React.Component {
  render() {
    return (
      <ListButton {...this.props}>
        <IconRemove/>
      </ListButton>);
  }
}
