import * as React from 'react';
import Location from './Location';
import ContactInfo from './ContactInfo';
import InternalInfo from './InternalInfo';
import IdentityInfo from './IdentityInfo';
import './styles.scss';

type Props = {
  origPlace?: OriginalPlace;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  userEditingStrategy: UserEditingStrategy;
  chains?: Chain[];
  chainSuggestions: Chain[];
  canViewInternalInfo: boolean;
};

export default function BasicInfo(props: Props) {
  const { origPlace, onEdit, onSave, onCancel, chains, chainSuggestions, canViewInternalInfo, ...rest } = props;
  const { name, alt_names, former_names, ...place } = origPlace;

  return (
    <div className='editor__listing__info' data-cy="listing-basic-info" >
      <IdentityInfo
        chainSuggestions={chainSuggestions || []}
        chains={chains || []}
        name={name}
        altNames={alt_names}
        formerNames={former_names}
        userEditingStrategy={props.userEditingStrategy}
      />
       <Location {...rest} />
      <h4 className="mt-5">Contact & Social</h4>
      <ContactInfo {...rest} origPlace={{ ...place }} />
      {/* Header is included in Location to pluralize title when necessary */}

      {canViewInternalInfo &&
        <>
          <h4 className="mt-5">Internal</h4>
          <InternalInfo {...rest} />
        </>
      }
    </div>
  );
}
