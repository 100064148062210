import React from "react";
import "./styles/index";
import cn from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useSpring, animated} from "react-spring";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import Nav from "./nav";
import useDetectDevice from '@/hooks/useDetectDevice'
import useWindowSize from '@/hooks/useWindowSize';

function SideNav(props) {
  const windowMin = 992;
  const isMobile = useDetectDevice();
  const windowSize = useWindowSize();

  const [open, setOpen] = React.useState(isMobile || windowSize.width < windowMin ? false: true);
  // Drawer width
  const style = useSpring({ width: open ? isMobile || windowSize.width < windowMin ? "250px" : "300px" : "60px" });

  const ref = React.useRef();

  useOnClickOutside(ref, () => {
    if (isMobile || windowSize.width < windowMin) {
      props.enabled && setOpen(false);
    }
  });

  React.useEffect(() => {
    if (windowSize.width > windowMin) {
      setOpen(true)
    }
  }, [windowSize])
  return (
    <div
      ref={ref}
      onClick={(e) => {
        if (isMobile || windowSize.width < windowMin) {
          e.preventDefault();
          setOpen((prev) => !prev);
        }
      }}
      className={cn({
        "editor__side-nav": true,
        "editor__side-nav--open": open,
        "editor__side-nav--closed": !open,
      })}
    >
      <div
        className={cn({
          editor__scrim: true,
          // regardless of if the drawer is open or not leave scrim in place when
          // listing is not enabled
          "editor__scrim--open": props.enabled ? (isMobile || windowSize.width < windowMin) && open : true,
          "editor__scrim--closed": props.enabled ? (isMobile || windowSize.width < windowMin) && !open : false,
        })}
      />
      <animated.div style={style}>
        {(isMobile || windowSize.width < windowMin) && <button className="editor__side-nav__control">
          <FontAwesomeIcon icon={open ? faTimes : faBars} />
        </button>}

        <nav className="editor__side-nav__nav-el">
          {open && (
            <div className="editor__side-nav__logo">
              ROADPASS<span>PLACES</span>
            </div>
          )}
          <div className="editor__side-nav__overflow">
            <div className="editor__side-nav__sections">
              <div className="editor__side-nav__primary">
                <Nav
                  text={"Search Listings"}
                  icon={["fa", "search"]}
                  show={props.listings.show}
                  internalLink={props.listings.path}
                />
                {props.recentPlaces && <Nav
                  text={"Recent Listings"}
                  icon={["fa", "history"]}
                  className={"mb-2"}
                  onClick={(_e) => {
                    setOpen(true);
                  }}
                  defaultOpen={true}
                >
                  {props.recentPlaces && props.recentPlaces.map((place, i) => (
                    <Nav key={'recent-places' + i}
                      className={'editor__side-nav__recent-place'}
                      internalLink={place.href}
                      text={place.name}>
                    </Nav>
                  ))}
                </Nav>}
                <Nav
                  text={"Organization"}
                  icon={["fa", "users"]}
                  show={props.organization.show}
                  internalLink={props.organization.path}
                />
                {props.internalUser && (
                  <Nav admin text={"Admin"} icon={["fa", "bolt"]}>
                    <Nav
                      admin
                      text={"Keys"}
                      icon={["fa", "key"]}
                      show={props.keys.show}
                      internalLink={props.keys.path}
                    />
                    <Nav
                      admin
                      icon={["fa", "list"]}
                      text={"Imports"}
                      show={props.imports.show}
                      internalLink={props.imports.path}
                    />
                    <Nav
                      admin
                      text={"Match"}
                      icon={["fa", "layer-group"]}
                      show={props.match.show}
                      internalLink={props.match.path}
                    />
                    <Nav
                      admin
                      text={"Corrections"}
                      icon={["fa", "check"]}
                      show={props.corrections.show}
                      internalLink={props.corrections.path}
                    />
                  </Nav>
                )}
              </div>
              <div className="editor__side-nav__secondary">
                <Nav
                  defaultOpen
                  text={"Listing"}
                  icon={["fa", "map"]}
                  show={props.listing.show}
                  internalLink={props.listing.path}
                />
                {props.children}
              </div>
            </div>
          </div>
        </nav>
      </animated.div>
    </div>
  );
}

export default SideNav;
