/**
 * @deprecated 12/31/2019 use library/link/view
 */

import ExternalLink from '../ExternalLink';
import PropTypes from 'prop-types';
import React from 'react';
import ViewField from './view-field';

const ViewLink = ({ value, displayValue, type, label }) => {
  const link = Boolean(value) ? (
    // If value is set pass link into component
    <ExternalLink href={value} type={type}>
      {displayValue}
    </ExternalLink>
  ) : (
      // If not, pass in value and let ViewField handle what to display
      value
    );
  return <ViewField value={link} label={label} />;
};

ViewLink.propTypes = {
  displayValue: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string
};

export default ViewLink;
