import React from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import applyFilter from '@/helpers/applyFilter';
import './styles';

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value || '' };
    this.searchParam = props.name || 'name';

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.updateResults = debounce(this.updateResults, 300).bind(this);
  }

  handleChange(e) {
    this.setState({ value: e.target.value }, this.updateResults);
  }

  handleClear() {
    this.setState({ value: '' }, this.updateResults);
  }

  render() {
    const inputId = 'search-field';

    return (
      <div id="search-filter">
        <label htmlFor={inputId}>
          <svg height="34" viewBox="-5 -5 24 24" width="34">
            <path stroke='none' fill='black' d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </label>
        <input
          type="text"
          id={inputId}
          className="form-control search-field"
          name="search"
          autoFocus
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.handleChange}
        />
        <span
          className={classNames('clear-form', this.state.value.length == 0 ? 'hidden' : undefined)}
          onClick={this.handleClear}
        >
          <svg viewBox="0 0 24 24" height="35" width="35">
            <path
              stroke='none'
              fill='white'
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </span>
      </div>
    );
  }

  updateResults() {
    applyFilter({ [this.searchParam]: this.state.value}, this.props.replacedIds);
  }
}

export default SearchFilter;
