import React from 'react';

type Props = {
  ancestorMap: AncestorMap;
  children?: DataNode[];
  public_id: string;
  selection: AssignmentMap;
};

function useChildState({ children, ancestorMap, public_id, selection }: Props){
  const hasChildren = children && children.length > 0;

  // See if any node is selected downstream
  const checkChildren = (parent) => {
    // Get selection array
    const arr = Object.keys(selection).filter((k) => selection[k]);

    if (arr && arr.length < 1) return false;

    const f = arr.filter((public_id) => {
      const ancestors = ancestorMap[public_id];
      return ancestors && ancestors.includes(parent) && public_id !== parent;
    });

    return f && f.length > 0;
  };

  const [childAssigned, setChildAssigned] = React.useState(
    hasChildren ? checkChildren(public_id) : false
  );

  React.useEffect(
    () => {
      setChildAssigned(checkChildren(public_id));
    },
    [selection]
  );

  return [childAssigned, hasChildren];
}

export default useChildState;
