import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import React from 'react';

class Reason extends PureComponent {
  render() {
    return (
      <div>
        <select
          className="form-control"
          value={this.props.reason}
          onChange={e => this.props.handleChange('reason', e.target.value)}
        >
          <option value="holiday">Holiday</option>
          <option value="renovation">Renovation</option>
          <option value="seasonality">Seasonality</option>
          <option value="weather">Weather</option>
          <option value="other">Other</option>
        </select>
      </div>
    );
  }
}

Reason.propTypes = {
  handleChange: PropTypes.func.isRequired,
  reason: PropTypes.string
};

export default Reason;
