import * as React from 'react';
import InternalLinks from './InternalLinks';
import MapMarkers from './MapMarkers';
import SvgUpload from './SvgUpload';
import Editorial from './Editorial';
import './styles/index';

const RT = function(props){
  return (
    <div className="editor__listing" data-cy="rt-tab-content">
      <div className="boone-listing__alert">
        Only Roadtrippers can access this tab, but the data is available to
        everyone in the API.
      </div>
      <div className="editor__roadtrippers__map">
        <SvgUpload {...props} />
      </div>
      <div className="editor__roadtrippers__map">
        <MapMarkers
          userEditingStrategy={props.userEditingStrategy}
          mapMarkers={props.mapMarkers}
        />
      </div>
      <Editorial
        userEditingStrategy={props.userEditingStrategy}
        editorial={props.editorial}
        contributor={props.contributor}
      />
      <InternalLinks
        userEditingStrategy={props.userEditingStrategy}
        tripGuides={props.tripGuides}
        magazineArticles={props.magazineArticles}
      />
    </div>
  );
};

export default RT;
