import React from "react";
import Label from "../label";
import cn from "classnames";

type Value = string | boolean | null;

type Props = {
  defaultValue?: Value;
  getValue: (value: Value) => void;
  first: string;
  second: string;
  third?: string;
  label: string;
};

function TriSwitch(props: Props) {
  const [value, setValue] = React.useState<Value>(props.defaultValue);

  const UNSET = "UNSET";

  React.useEffect(() => {
    props.getValue(value === UNSET ? undefined : value);
  }, [value]);

  return (
    <div className="editor__tri-switch">
      <Label text={props.label} />
      <div
        className={cn({
          "editor__tri-switch__option": true,
          "editor__tri-switch__option--first": true,
          "editor__tri-switch__option--first--selected": value === true,
        })}
        onClick={() => setValue((s) => (s === true ? UNSET : true))}
      >
        {props.first}
      </div>
      <div
        className={cn({
          "editor__tri-switch__option": true,
          "editor__tri-switch__option--second": true,
          "editor__tri-switch__option--second--selected": value === false,
        })}
        onClick={() => setValue((s) => (s === false ? UNSET : false))}
      >
        {props.second}
      </div>
      {props.third && (
        <div
          className={cn({
            "editor__tri-switch__option": true,
            "editor__tri-switch__option--third": true,
            "editor__tri-switch__option--third--selected": value === null,
          })}
          onClick={() => setValue((s) => (s === null ? UNSET : null))}
        >
          {props.third}
        </div>
      )}
    </div>
  );
}

export default TriSwitch;
