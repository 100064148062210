import React from "react";
import InputWithLabel from "@library/input-with-label";
import CheckboxWithLabel from "@library/CheckboxWithLabel";
import type {SingleValueProps} from "./types";
import "./styles";

function SingleParam({
  show,
  setShow,
  label,
  value,
  onChange,
  cy,
}: SingleValueProps) {
  return (
    <div className="editor__filters__range__single">
      <CheckboxWithLabel
        cy={cy + "-checkbox"}
        checked={show}
        onChange={() => setShow((n) => !n)}
        label={label}
      />
      {show && (
        <InputWithLabel
          cy={cy + "-input"}
          type="number"
          value={value}
          label={""}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
}

export default SingleParam;
