import React from 'react';
import TimeFormat from '../helpers/TimeFormat';

export default class HourSelect extends React.Component {
  state = {
    value: ''
  };

  componentDidMount() {
    this.setState({
      value: this.props.value
    });
  }

  handleChange = value => {
    this.setState({ value }, () => this.props.onChange(value));
  };

  render() {
    const times = [
      { value: 'sunrise', text: 'Sunrise' },
      { value: 'sunset', text: 'Sunset' }
    ];
    for (let h = 0; h < 24; ++h) {
      let hour = h < 10 ? '0' + h : h;
      times.push({ value: hour + ':00', text: TimeFormat.format_time(h + ':00') });
      times.push({ value: hour + ':30', text: TimeFormat.format_time(h + ':30') });
    }
    return (
      <select data-cy="hour-select" value={this.state.value} onChange={e => this.handleChange(e.target.value)}>
        {times.map(t => (
          <option data-cy="hour-select-option" key={t.value} value={t.value}>
            {t.text}
          </option>
        ))}
      </select>
    );
  }
}
