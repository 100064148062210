import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

const IconExpand = (props) => (
  <SinglePathIcon
    width="28"
    height="28"
    fillColor={props.fillColor}
    path="M13.25,10.8043478 L13.25,5.9115942 L11.3,7.72028986 C11,7.99855072 10.525,7.99855072 10.225,7.72028986 C9.925,7.44202899 9.925,7.00144928 10.225,6.72318841 L13.475,3.70869565 C13.625,3.56956522 13.8,3.5 14,3.5 C14.2,3.5 14.4,3.56956522 14.525,3.70869565 L17.775,6.72318841 C17.925,6.86231884 18,7.04782609 18,7.21014493 C18,7.39565217 17.925,7.58115942 17.775,7.69710145 C17.475,7.97536232 17,7.97536232 16.7,7.69710145 L14.75,5.8884058 L14.75,10.8043478 C14.75,11.1985507 14.4,11.5 14,11.5 C13.575,11.5 13.25,11.1753623 13.25,10.8043478 Z M9,13.75 C9,13.325 9.39215686,13 9.86834734,13 L18.1596639,13 C18.6078431,13 19,13.325 19,13.75 C19,14.15 18.6358543,14.5 18.1596639,14.5 L9.84033613,14.5 C9.39215686,14.5 9,14.175 9,13.75 Z M10.225,20.7906977 C9.925,20.5116279 9.925,20.0697674 10.225,19.7906977 C10.525,19.5116279 11,19.5116279 11.3,19.7906977 L13.25,21.6046512 L13.25,16.6976744 C13.25,16.3023256 13.6,16 14,16 C14.425,16 14.75,16.3255814 14.75,16.6976744 L14.75,21.6046512 L16.7,19.7906977 C17,19.5116279 17.475,19.5116279 17.775,19.7906977 C17.925,19.9302326 18,20.1162791 18,20.2790698 C18,20.4651163 17.925,20.6511628 17.775,20.7674419 L14.525,23.7906977 C14.375,23.9302326 14.2,24 14,24 C13.8,24 13.6,23.9302326 13.475,23.7906977 L10.225,20.7906977 Z"
  />
);

export default IconExpand;
