import * as React from 'react';
import Sandbox from '../../../component-explorer/Sandbox';
import Scenario from '../../../component-explorer/Scenario';
import Loader from '.';

const examples = [
  { code: <Loader pastDelay={true} />, description: 'no parent' },
  {
    code:
      (
        <div
          style={{
            width: '300px',
            height: '300px',
            border: '1px dashed black'
          }}
        >
          <Loader pastDelay={true} />
        </div>
      ),
    description: 'Parent with fixed height 300x300 px'
  }
];

export default function Examples(): any{
  return (
    <Sandbox name="Nested">
      {examples.map((ex, idx) => (
        <Scenario key={`nested-loader-${idx}`} description={ex.description}>
          {ex.code}
        </Scenario>
      ))}
    </Sandbox>
  );
}
