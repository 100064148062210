import React from 'react';
import ContactSection from '@/data-outreach/_core/forms/components/contact';

function SalesContact({ show, state, updateField, ...props }){
  return show ? (
    <ContactSection
      title="RV Sales"
      label="RV Sales"
      state={state}
      updateField={updateField}
      fields={
        props.fields || [
          ['Phone', 'sales_phone', 'tel', 'phone'],
          ['Email', 'sales_email', 'email', 'email']
        ]
      }
    />
  ) : null;
}

export default SalesContact;
