import React from 'react';

export default function ExtendedData(props) {

  const locations = props.locations;

  return (
    <div className="relationship-view__section__extended-data-view">
      <div className="relationship-view__section__extended-data-view__fields">
        <div className="relationship-view__section__extended-data-view__label">Additional Information</div>
        <div className="relationship-view__section__extended-data-view__section">
          <div className="relationship-view__section__extended-data-view__property __canonical_place_id">Id: {props.id}</div>
          <div className="relationship-view__section__extended-data-view__property __canonical_place_name">Name: {props.name}</div>
          <div className="relationship-view__section__extended-data-view__property __canonical_place_locations">Locations: {
            locations &&
            locations.map((location, index) =>
              <span key={index}>{`${location.address1}, ${location.city}, ${location.state}`}</span>
            )
          }</div>
        </div>
        <div className="relationship-view__section__extended-data-view__label">Other Options</div>
        <div className="relationship-view__section__extended-data-view__section">
          {props.removeAction && (<button className="btn btn-danger relationship-view__section__extended-data-view__remove-button" onClick={() => props.removeAction()}> Remove Parent</button>)}
        </div>
      </div>
      <div className="relationship-view__section__extended-data-view__raw-json">
        <div className="relationship-view__section__extended-data-view__label">Raw Json</div>
        <pre>
          {JSON.stringify(props, null, 2)}
        </pre>
      </div>
    </div>
  );
}