import React from "react";
import Label from "../label";
import "./styles/index";

type Props = {
  value?: boolean | null;
  label: string;
  id?: string;
  description?: string;
  hideNull?: boolean;
};

function View(props: Props) {
  return (props.hideNull && props.value === null) ||
    props.value === undefined ? null : (
    <div data-cy="boolean-row" className="editor__boolean-row">
      <Label
        cy="boolean-row-label"
        missingData={!props.value && props.value !== false}
        text={props.label}
        description={props.description}
        id={props.id}
        className="editor__label"
      />

      {(props.value === false || props.value) && (
        <div className="editor__field__value">
          {props.value && "yes"}
          {props.value === false && "no"}
        </div>
      )}
    </div>
  );
}

export default View;
