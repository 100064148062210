import React, { useState } from 'react'
import { User } from '../../common/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface Props {
  onClick: Function;
  user: User;
  isSelected: boolean;
}

export const UserTab = ({ ...props }: Props) => {

  const handleOnClick = (event: any) => {
    event.preventDefault();
    props.onClick(props.user);
  }

  return (
    <div className={`user-listing ${props.isSelected ? '--selected' : ''}`} onClick={handleOnClick}>
      <div className="image">
        {props.user.avatar_url ? 
        <img src={props.user.avatar_url} />
        : 
        <FontAwesomeIcon icon={'user-circle'} />}
      </div>
      <div className="details">
        <div className="details-header">
          <div className="name">
            {props.user.name}
          </div>
        </div>
        <div className="details-body">
          <div className="content">

          </div>
        </div>
      </div>

    </div>
  )
}
