import React from 'react';
import { useTransition, animated } from 'react-spring'

function SlideIn({ items }) {
  // @ts-ignore
  const transitions = useTransition(items.filter(item => item['show'] === undefined || item['show']), item => item.id,
    {
      from: { transform: 'translate3d(-50%,0,0)', opacity: 0 },
      enter: { transform: 'translate3d(0,0,0)', opacity: 1 },
      leave: [{ transform: 'translate3d(-50%,100%,0)', opacity: 0 }],
      trail: 50
    }
  )
  {
    return (
      <>
        {transitions.map(({ item, key, props }) => {
          return item && (
              <animated.div key={key} style={props}>
                <>
                  {item.component}
                </>
              </animated.div>
            )
          })
        }
      </>
    )
  }
}

export default SlideIn;
