import * as React from "react";
import {Range as ReactRange, getTrackBackground} from "react-range";

type Props = {
  values: number[];
  step?: number;
  min: number;
  max: number;
  onChange: (values: number[]) => void;
  disabled: boolean;
  float: boolean;
  cy?: string;
};

function Range({values, step, min, max, onChange, disabled, float, cy}: Props) {
  const primary = "#00514c";
  const secondary = "#f5f5f2";
  const sliderOff = "#9c9fbf";

  return (
    <div
      data-cy={cy + (disabled ? "-disabled" : "")}
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <ReactRange
        values={disabled ? [min, max] : values}
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        allowOverlap={true}
        onChange={(values) => onChange(values)}
        renderTrack={({props, children}) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "40px",
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
              width: "100%",
              marginTop: "2em",
            }}
          >
            <div
              data-cy={cy + "-track"}
              ref={props.ref}
              style={{
                minHeight: "5px",
                width: "300px",
                borderRadius: "4px",
                background: disabled
                  ? "#ccc"
                  : getTrackBackground({
                      values,
                      colors: [sliderOff, primary, sliderOff],
                      min: min,
                      max: max,
                    }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({index, props, isDragged}) => {
          let value = values[index];
          const tl = disabled ? "-" : float ? value.toFixed(1) : value;

          return (
            <div
              {...props}
              data-cy={cy + "-thumb-" + index}
              style={{
                ...props.style,
                height: "32px",
                width: "32px",
                borderRadius: "9999px",
                border: disabled ? "none" : "1px solid " + primary,
                color: disabled ? "#fff" : isDragged ? secondary : primary,
                backgroundColor: disabled
                  ? "#ccc"
                  : isDragged
                  ? primary
                  : secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}
            >
              {tl}
            </div>
          );
        }}
      />
    </div>
  );
}

export default Range;
