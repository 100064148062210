import React from "react";

import Icon from "@material-ui/core/Icon";
import styled from "styled-components";

// Bootstrap
import { Button } from "reactstrap";

import IconCollapse from '../icons/IconCollapse';
import IconExpand from '../icons/IconExpand';

const StyledCanonicalActions = styled.div`
  float: right;
  position: relative;
  top: 8px;
  right: 8px;

  .btn {
    font-size: 12px;
  }
`;

const ButtonLookupByType = {
  target: {
    name: "Confirm Matches",
    color: "primary",
    outline: false,
    styleColor: "#707c3b"
  },
  new: {
    name: "Confirm Matches",
    color: "primary",
    outline: false,
    styleColor: "#707c3b"
  },
  children: {
    name: "Confirm non-matches",
    color: "link",
    outline: true,
    styleColor: "#8f3025"
  }
};

const CanonicalActions = props => {
  const { id, type, target, openAsTargetUrl } = props;
  const fillColor = (type === 'target' || type === 'new') ?  '#FFFFFF' : '#01425F';

  return (
    <StyledCanonicalActions>
      <Button className="btn" color="link" onClick={props.onToggleCollapse} style={{padding: 0}}>
        {target.isCollapsed ? <IconExpand fillColor={fillColor}/> : <IconCollapse fillColor={fillColor}/>}
      </Button>
      {target.isConfirmed !== true &&
        type !== "target" &&
        type !== "new" && (
          <a className="btn" href={openAsTargetUrl} target="_blank">
            <Icon>exit_to_app</Icon>
          </a>
      )}
    </StyledCanonicalActions>
  );
};

export default CanonicalActions;
