import React from "react";
import cn from "classnames";
import Label from "../label";
import Input from "../Input";

type Props = {
  className?: string;
  cy?: string;
  description?: string;
  error?: any;
  errorClassName?: string;
  errorMessage?: string;
  forwardedRef?: any;
  hidden?: boolean;
  inputClassName?: string;
  inputId?: string;
  inputName?: string;
  label?: string;
  labelClassName?: string;
  labelId?: string;
  max?: number;
  min?: number;
  onBlur?: any;
  onChange?: any;
  onClick?: any;
  onKeyDown?: any;
  placeholder?: string;
  required?: boolean;
  showLabel?: boolean;
  tabIndex?: number;
  type?: any;
  value?: any;
};

export const InputWithLabel = ({
  className,
  cy,
  description,
  error,
  errorClassName,
  errorMessage,
  forwardedRef,
  hidden,
  inputClassName,
  inputId,
  inputName,
  label,
  labelClassName,
  labelId,
  onBlur,
  onChange,
  onClick,
  onKeyDown,
  placeholder,
  required,
  tabIndex,
  type,
  value,
  showLabel,
  ...props
}: Props) => (
  <div
    className={cn({
      editor__field: true,
      "editor__field--edit": true,
      "editor__field--error": error,
      "boone-form__field--error": error,
      [className]: Boolean(className),
    })}
  >
    {showLabel ? (
      <Label
        id={labelId}
        text={label}
        className={labelClassName}
        description={description}
        onClick={onClick ? (e) => onClick(e) : null}
      />
    ) : null}
    <Input
      id={inputId}
      name={inputName}
      hidden={hidden}
      forwardedRef={forwardedRef}
      labelId={labelId}
      className={inputClassName}
      type={type}
      value={value}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      required={required}
      tabIndex={tabIndex}
      cy={cy}
      {...props}
    />
    {error && errorMessage && (
      <div
        className={
          errorClassName
            ? errorClassName + " editor__field__message--error"
            : "editor__field__message--error"
        }
      >
        {errorMessage}
      </div>
    )}
  </div>
);

InputWithLabel.defaultProps = {
  showLabel: true,
  type: "text",
  error: false,
};

export default React.forwardRef((props: Props, ref) => (
  <InputWithLabel {...props} forwardedRef={ref} />
));
