import React from 'react';
import moment from 'moment';
import Status from './Status';
import { isToday } from '../../../../helpers/TimeFormat';
import * as c from '../../constants';

type Props = {
  mayRequireAppointment?: boolean;
  temporaryClosures?: any[];
  hoursMayVary?: boolean;
  permanentlyClosed?: boolean;
  status: { warnings?: string[]; reason?: string; operating_normally?: boolean; changes_at?: string };
};

const View = function(props: Props) {
  const sort = (a, b) => {
    let aStart = moment(a.start_date);
    let bStart = moment(b.start_date);
    if (aStart.isBefore(bStart)) return -1;
    if (bStart.isBefore(aStart)) return 1;
    let aEnd = moment(a.end_date);
    let bEnd = moment(b.end_date);
    if (aEnd.isValid()) {
      if (bEnd.isValid()) {
        if (aEnd.isBefore(bEnd)) return -1;
        if (bEnd.isBefore(aEnd)) return 1;
      } else {
        return -1;
      }
    } else {
      if (bEnd.isValid()) {
        return 1;
      }
    }
    return 0;
  };

  const formatDate = date => {
    if (!date) {
      return '';
    }
    return isToday(date) ? 'today' : moment(date).format(c.VIEW_DATE_FORMAT);
  };

  const getViewStatus = (startDate, endDate, why) => {
    const startDateFmtd = formatDate(startDate);
    const start_date = startDateFmtd === 'today' ? startDateFmtd : `on ${startDateFmtd}`;
    const reason = why !== 'other' ? `for ${why}` : '';
    const end_date = endDate ? formatDate(endDate) : '';

    if (!endDate) {
      return `Temporarily closed indefinitely starting ${start_date} ${reason}`;
    } else if (startDateFmtd === end_date) {
      return `Temporarily closed ${start_date} ${reason}`;
    } else {
      return `Temporarily closed ${start_date} through ${end_date} ${reason}`;
    }
  };

  return (
    <>
      <Status status={props.status} />

      {props.temporaryClosures.sort(sort).map((tc, i) => (
        <div key={i + '_' + tc.start_date + '_' + tc.end_date} className="attribute-row">
          <div className="col-sm-11 offset-sm-1">{getViewStatus(tc.start_date, tc.end_date, tc.reason)}</div>
        </div>
      ))}

      {props.hoursMayVary && (
        <div className="attribute-row">
          <div className="col-sm-11 offset-sm-1">{c.HOURS_VARY_LABEL}</div>
        </div>
      )}

      {props.mayRequireAppointment && (
        <div className="attribute-row">
          <div className="col-sm-11 offset-sm-1">{c.APPOINTMENT_LABEL}</div>
        </div>
      )}
    </>
  );
};

export default View;
