import React from 'react';
import InputWithPills from '../InputWithPills';
import Label from '../label';
import without from 'lodash/without';
import uniqWith from 'lodash/uniqWith';

type Props = {
  suggestions: { id: ID; name: string; subtitle?: string }[];
  selected?: ID[];
  allowNewValues: boolean;
  persistChanges: (selected: any) => void;
  placeholder?: any;
  children?: any;
  cy?: string;
  labelId?: string;
  label?: string;
  description?: string;
  disabled?: boolean;
};

function MultiSelect(props: Props){
  //--- suggestions
  const [showSuggestions, setShowSuggestions] = React.useState(false);

  const [suggestions, setSuggestions] = React.useState(
    props.suggestions.reduce(
      (p, c) => {
        if (c && c['id']) {
          p[c.id] = c;
          p.ids.push(c.id);
        }
        return p;
      },
      { ids: [], refs: {} }
    )
  );

  //--- selected
  // selected in an array of ids
  const [selected, setSelected] = React.useState(props.selected || []);

  // When selected changes, persist changes
  React.useEffect(
    () => {
      props.persistChanges(selected === [] ? null : selected);
    },
    [selected]
  );

  const removeById = (id) => {
    setSelected([...selected.filter((sid) => sid !== id)]);
  };

  const addById = (id) => {
    setSelected([...selected, id]);
  };

  //--- input
  // input is used to filter suggestions
  const [input, setInput] = React.useState('');

  // input ref is used to focus input, it is necessary to keep focus
  // on the input when the dropdown is open
  const inputRef = React.useRef();

  const focusInput = () => {
    // @ts-ignore
    inputRef && inputRef.current && inputRef.current.focus();
  };

  //--- filtering
  const getFiltered = () => {
    if (input || input === '') {
      const userInput = input.toLowerCase();
      const t = without(
        suggestions.ids
          .map((id) => suggestions[id])
          .filter(({ name }) => name.toLowerCase().indexOf(userInput) > -1)
          .map((o) => o.id),
        ...selected
      );
      // @ts-ignore
      return uniqWith(t, 'id');
    } else {
      const t = without(suggestions.ids, ...selected);
      // @ts-ignore
      return uniqWith(t, 'id');
    }
  };

  // @ts-ignore
  const [filtered, setFiltered] = React.useState(getFiltered());

  // Search and selection, refresh filtered
  React.useEffect(
    () => {
      // @ts-ignore
      setFiltered(getFiltered());
    },
    [input, selected]
  );

  //--- active (filtered) suggestion
  // active, prev, and next are ids
  const [active, setActive] = React.useState(-1);
  const [prev, setPrev] = React.useState(-1);
  const [next, setNext] = React.useState(0);

  const checkIfActive = (id) => active !== -1 && id === active;

  // When showSuggestions is false, unset active id
  React.useEffect(
    () => {
      if (!showSuggestions) {
        setActive(-1);
      }
    },
    [showSuggestions]
  );

  // When active id is updated, update prev and next for ease of access
  React.useEffect(
    () => {
      if (active === -1) {
        setPrev(-1);
        setNext(0);
      } else {
        const index = filtered.indexOf(active);
        if (index <= 0) {
          setPrev(-1);
          setNext(filtered[index + 1]);
        } else {
          setPrev(filtered[index - 1]);
          setNext(filtered[index + 1]);
        }
      }
    },
    [active]
  );

  //--- key down event handlers
  const onDown = () => {
    if (showSuggestions) {
      // If the dropdown is open, increment active id
      setActive(next);
    } else {
      // If the dropdown is not open, open it and unset active id
      setShowSuggestions(true);
      setActive(-1);
    }
  };

  const onUp = () => {
    if (active === -1) {
      setShowSuggestions(false);
    } else {
      setActive(prev);
    }
  };

  const onDelete = (e) => {
    if (!input) {
      e.preventDefault();
      const id = selected[selected.length - 1];
      removeById(id);
    }
  };

  const onEnter = () => {
    if (props.allowNewValues) {
      if (input && active === -1) {
        // Not in selected array
        if (selected.indexOf(input) === -1) {
          // Not in suggestions map
          if (suggestions.ids.indexOf(input) === -1) {
            setSuggestions({
              ...suggestions,
              [input]: { id: input, name: input },
              ids: [...suggestions.ids, input]
            });
          }
          setSelected([...selected, input]);
          setInput('');
        }
      } else if (active && active !== -1) {
        addById(active);
        setActive(next);
      }
    } else if (selected.indexOf(active) === -1 && active !== -1) {
      addById(active);
      setActive(next);
    }
  };

  //--- outside click
  const boundaryRef = React.useRef();

  // If the user clicks outside of the outer most div, hide suggestions
  const handleClickOutside = (e) => {
    // @ts-ignore
    if (boundaryRef.current && !boundaryRef.current.contains(e.target)) {
      setShowSuggestions(false);
    }
  };

  React.useEffect(
    () => {
      document.addEventListener('mousedown', handleClickOutside);
    },
    [props.suggestions]
  );

  React.useEffect(
    () => {
      return () => document.addEventListener('mousedown', handleClickOutside);
    },
    [props.suggestions]
  );

  const pills =
    selected && selected.length > 0
      ? [
          ...selected.filter((a) => a).map((id) => ({
            id,
            text: (suggestions[id] && suggestions[id].name) || ''
          }))
        ]
      : [];

  return (
    <div
      ref={boundaryRef}
      className="editor__multi-select"
      data-cy={'multi-select'}
      onClick={() => focusInput()}
      onFocus={() => setShowSuggestions(true)}
    >
      <Label
        id={props.labelId}
        show={Boolean(props.label)}
        text={props.label}
        description={props.description}
      />
      <div className="editor__multi-select__input-dropdown">
        <InputWithPills
          cy="multi-select-input"
          ref={inputRef}
          active={active !== -1 || !props.disabled}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) =>
            onKeyDown(e, {
              onTab: () => setShowSuggestions(false),
              onEsc: () => setShowSuggestions(false),
              onEnter,
              onUp,
              onDown,
              onDelete
            })}
          removePill={(id) => removeById(id)}
          value={input}
          placeholder={props.placeholder}
          pills={pills}
        />
        {showSuggestions &&
        filtered && (
          <div
            data-cy="multi-select-suggestions"
            className="editor__multi-select__dropdown"
            onClick={() => focusInput()}
          >
            <div className="editor__multi-select__dropdown__list">
              {props.children({
                setActive,
                filtered: filtered.map((id) => suggestions[id]),
                checkIfActive,
                add: addById
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const ENTER_KEY_CODE = 13;
const UP_KEY_CODE = 38;
const DOWN_KEY_CODE = 40;
const DELETE_KEY_CODE = 8;
const ESC_KEY_CODE = 27;
const TAB_KEY_CODE = 9;

const onKeyDown = (e, fn) => {
  switch (e.keyCode) {
    case TAB_KEY_CODE:
      fn.onTab(e);
      break;
    case ESC_KEY_CODE:
      fn.onEsc(e);
      break;
    case ENTER_KEY_CODE:
      fn.onEnter(e);
      break;
    case UP_KEY_CODE:
      fn.onUp(e);
      break;
    case DOWN_KEY_CODE:
      fn.onDown(e);
      break;
    case DELETE_KEY_CODE:
      fn.onDelete(e);
      break;
  }
};

export default MultiSelect;
