import React from 'react';
import TextArea from '@/library/text-area';
import Subsection from '@/library/wizard/components/subsection';
import type { State } from '../typings';
import type { UpdateField } from '@/data-outreach/_core/forms/components/typings';

type Props = {
  state: State;
  updateField: UpdateField;
}

function Notes({ state, updateField }: Props){
  return (
    <Subsection>
      <TextArea
        label={''}
        value={state.notes}
        onChange={(e) => {
          updateField(e, 'notes');
        }}
      />
    </Subsection>
  );
}

export default Notes;
