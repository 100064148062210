import React, { useState, useEffect } from "react";
import LaunchDarklyProvider from "../library/launch-darkly/provider";
import { StoreProvider } from "../state";
import NavMenu from "./nav-menu";
import Tabs from "./tabs/index";
import Header from "./header";
import "./styles/index";
import { Props as iOption } from '@/v2Editor/src/layout/sidebar/NavOption';
import { Sidebar } from "@/v2Editor/src/layout/sidebar";
import Disable from "./nav-menu/disable";
import Enable from "./nav-menu/enable";

import '../v2Editor/src/styles.scss';

interface NavData {
  active: boolean
  internal: boolean
  path: string
  show: boolean
}

interface ListingLite {
  id: number,
  href: string,
  name: string
}

interface Props {
  nav: {
    canViewRoadTripDetails: boolean,
    corrections: NavData,
    imports: NavData,
    keys: NavData,
    listing: NavData,
    listings: NavData,
    match: NavData,
    organization: NavData,
    support: NavData,
    recentPlaces: Array<ListingLite>
  }
}

export function Component(props) {
  const [active, setActive] = useState(
    window.location.hash.slice(1, window.location.hash.length) || "dashboard"
  );
  const [subActive, setSubActive] = useState();
  const [collapsedState, setCollapsedState] = useState(false);
  const [enabled, setEnabled] = React.useState(props.enabled);

  const subnavOnClick = (id) => {
    if (active !== 'attributes') {
      setActive('attributes');
      (window as Window).location.hash = 'attributes';
    }
    const el = document.getElementById(id);
    if (el) {
      setSubActive(id);
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  const adminOptions: Array<iOption> = [
    { name: 'Match', icon: 'layer-group', url: props.nav.match.path },
    { name: 'Corrections', icon: 'check', url: props.nav.corrections.path },
  ];

  const attributes: Array<iOption> = [
    { name: 'Activities', icon: 'hiking', onClick: () => subnavOnClick('activities') },
    { name: 'Culture', icon: 'palette', onClick: () => subnavOnClick('in-culture') },
    { name: 'Universal', icon: 'globe-americas', onClick: () => subnavOnClick('universal-attributes') },
    { name: 'Restrooms', icon: 'restroom', onClick: () => subnavOnClick('restroom-attributes') },
    { name: 'Price', icon: 'dollar-sign', onClick: () => subnavOnClick('price-attributes') },
    { name: 'Parking', icon: 'car', onClick: () => subnavOnClick('parking-attributes') },
    { name: 'Overnight RV Parking', icon: 'car', onClick: () => subnavOnClick('overnight-rv-parking-attributes') },
    { name: 'Campground', icon: 'campground', onClick: () => subnavOnClick('campground-attributes') },
    { name: 'Pets', icon: 'dog', onClick: () => subnavOnClick('pet-attributes') },
    { name: 'Restaurant', icon: 'utensils', onClick: () => subnavOnClick('restaurant-attributes') },
    { name: 'Electric Vehicle Station', icon: 'plug', onClick: () => subnavOnClick('electric-vehicle-details') },
    { name: 'Hotel', icon: 'hotel', onClick: () => subnavOnClick('hotel-attributes') },
    { name: 'Booking', icon: 'book', onClick: () => subnavOnClick('booking-attributes') },
    { name: 'RV Service', icon: 'caravan', onClick: () => subnavOnClick('rv-service') },
  ];

  const navOptions: Array<iOption> = [
    { name: 'Search', icon: 'search', url: '/search' },
    { name: 'Admin', icon: 'bolt', options: adminOptions, adminOnly: true, collapsed: true, dropDownOnly: true },
    { name: 'Organization ', icon: 'users', url: props.nav.organization.path },
    { name: 'Dashboard', icon: 'home' },
    { name: 'General', icon: 'map-marker-alt' },
    { name: 'Attributes', icon: 'edit', options: attributes, collapsed: true },
    { name: 'Categories', icon: 'shapes' },
    { name: 'Hours', icon: 'calendar' },
    { name: 'Editorial', icon: 'newspaper' },
    { name: 'Media', icon: 'image' },
    { name: 'Suggestions', icon: 'comment-dots' },
    { name: 'Changes', icon: 'bell' },
    { name: 'Relationships', icon: 'network-wired' },
    { name: 'Support', icon: 'check', url: props.nav.support.path },
    { name: 'Discoverability', icon: 'percent', disabled: !props.discoverability },
    { name: 'RT', icon: 'landmark', disabled: !props.nav.canViewRoadTripDetails }
  ];

  useEffect(
    () => {
      (window as Window).scrollTo(0, 0);
    }, [active]
  );

  return (
    <StoreProvider
      user={props.user}
      org={props.org}
      canonicalPlace={props.canonicalPlace}
    >
      <>
        <div className="editor-container__content">
          <div className={
            `__left-panel${collapsedState ? '--collapsed' : ''}`
          }>
            <Sidebar
              title="Roadpass"
              titleB="Places"
              options={navOptions}
              userRole={props.userRole}
              setActive={setActive}
              recentPlaces={props.nav.recentPlaces}
              onCollapsedState={(state: any) => { setCollapsedState(!state) }}
            >
              <div className="editor__listing__nav">
                {enabled ? (
                    <Disable
                      onClick={() => setEnabled(false)}
                      enabled={enabled}
                      guarded={props.canonicalPlace.guarded}
                      canDisable={props.canEnable} />
                ) : (
                  <Enable
                    onClick={() => setEnabled(true)}
                    enabled={enabled}
                    canEnable={props.canEnable}
                    enablePath={props.enablePath}
                  />
                )}
              </div>
            </Sidebar>
          </div>
          <div className={`__right-panel`} id="rightPanel">
            <Header
              setActive={setActive}
              active={active}
              id={props.canonicalPlace.id}
              name={props.canonicalPlace.name}
              categories={props.canonicalPlace.categories}
              image={
                props.canonicalPlace.images &&
                props.canonicalPlace.images[0].image_url
              }
              guarded={props.canonicalPlace.guarded}
              {...props}
            />
            <div className="editor__listing __tab-content">
              <Tabs active={active} setActive={setActive} {...props} />
            </div>
          </div>
        </div>
      </>
    </StoreProvider>
  );
}

export default function Listing(props) {
  return (
    <LaunchDarklyProvider
      user={props.launchDarkly.user}
      clientID={props.launchDarkly.client}
    >
      <Component {...props} />
    </LaunchDarklyProvider>
  );
}
