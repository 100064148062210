const dataDefHOC = (def) => (data) => ({
  ...def,
  data,
});

export const regions = dataDefHOC({
  type: 'regions',
  name: 'regions',
  label: 'Regions',
});

export const chains = dataDefHOC({
  type: 'tree-select',
  featureFlag: 'chains-filter',
  filter: 'chains',
  key: 'chains',
  label: 'Chains',
  cy: 'chains-filter',
  showSelection: true,
});

export const sources = dataDefHOC({
  type: 'tree-select',
  filter: 'sources',
  name: 'sources',
  label: 'Sources',
  cy: 'sources-filter',
  showSelection: true,
});

export const phone = dataDefHOC({
  type: 'textfield',
  label: 'Phone',
  cy: 'phone-filter',
  showSelection: true,
});

export const postal_code = dataDefHOC({
  type: 'textfield',
  label: 'Postal Code',
  cy: 'postal-code-filter',
  showSelection: true,
});

export const verified = dataDefHOC({
  type: 'select',
  name: 'verified',
  label: 'Verified',
  options: [
    { label: 'All listings', value: '' },
    {
      label: 'Only verified listings',
      value: 1,
      shortLabel: 'Verified',
    },
    {
      label: 'Only unverified listings',
      value: 0,
      shortLabel: 'Unverified',
    },
  ],
});

export const categories = dataDefHOC({
  type: 'tree-select',
  featureFlag: 'categories-filter',
  filter: 'categories',
  key: 'categories',
  label: 'Categories',
  cy: 'categories-filter',
  showSelection: true,
});

export const needing_audit = dataDefHOC({
  type: 'audits',
  featureFlag: 'needing-audit',
  filter: 'needing_audit',
  key: 'needing_audit',
  name: 'audited',
  label: 'Audits',
  cy: 'needing-audit',
  showSelection: true,
  checkboxLabel: "Filter only places having audits",
});

export const audit_queue = (data) => {
  if (data.show) {
    return {
      type: 'audit-queue',
      anyoneParam: 'queued_for_audit',
      userParam: 'audit_user',
      btnClass: 'btn-admin btn-super-admin',
      label: 'Audit Queue',
      activeLabel: 'Need audit',
      checkboxLabel: 'Only listings needing review',
      anyone: data.anyone,
      user: data.user,
      users: data.users,
      hideLastName: true,
    };
  }
};

export const numImages = () => ({
  type: 'range',
  name: 'images_count',
  label: 'Image Count',
  minValue: 0,
  maxValue: 100,
  step: 1,
});

export const numReviews = () => ({
  type: 'range',
  name: 'reviews_count',
  label: 'Review Count',
  minValue: 0,
  maxValue: 100,
  step: 1,
});

export const ratings = () => ({
  type: 'range',
  name: 'rating',
  label: 'Rating',
  decimal: true,
  minValue: 0,
  maxValue: 5,
  step: 1,
});

export const fidelity = () => ({
  type: 'range',
  name: 'fidelity',
  label: 'Fidelity',
  minValue: 0,
  maxValue: 5,
  step: 1,
});

export const numSources = () => ({
  type: 'range',
  name: 'sources_count',
  label: 'Source Count',
  minValue: 0,
  maxValue: 20,
  step: 1,
});

export const numPlaces = () => ({
  type: 'range',
  name: 'places_count',
  label: 'Place Count',
  minValue: 0,
  maxValue: 20,
  step: 1,
});
