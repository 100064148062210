import React from 'react';

export const IconBooneAvatar = () => (
  <svg viewBox="0 0 700 700" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(100.00000, 590.000000) scale(0.0800000,-0.0800000)" stroke="none">
      <path
        stroke="#FFFFFF"
        fill="#FFFFFF"
        d="M1317 5931 c-112 -37 -164 -92 -174 -184 -11 -110 27 -163 187 -256
            108 -64 145 -107 155 -182 10 -69 10 -3573 0 -3629 -11 -65 -46 -114 -110
            -152 -198 -117 -227 -148 -233 -243 -7 -104 45 -176 154 -218 l59 -22 1435 0
            c789 0 1478 3 1531 7 584 46 1065 317 1307 737 23 40 42 75 42 78 0 2 11 28
            24 57 22 47 63 168 70 206 22 109 28 161 30 259 6 203 -28 375 -107 545 -93
            202 -214 344 -435 513 -38 29 -182 112 -245 141 -125 59 -331 127 -442 147
            l-40 7 105 52 c218 107 344 193 489 331 248 238 365 506 346 794 -3 58 -8 108
            -10 112 -3 4 -7 24 -11 45 -17 107 -110 309 -171 372 -7 7 -13 17 -13 21 0 4
            -32 39 -70 79 -209 211 -492 340 -832 378 -29 3 -64 8 -78 12 -14 3 -673 7
            -1465 9 -1384 2 -1442 2 -1498 -16z m2143 -446 c196 -13 310 -40 435 -100 177
            -85 273 -220 293 -413 36 -328 -124 -621 -466 -855 -150 -103 -341 -197 -547
            -270 -105 -37 -222 -74 -259 -81 -11 -3 -59 -14 -106 -25 -47 -12 -88 -21 -91
            -21 -3 0 -5 334 -4 743 1 776 2 782 45 867 25 48 56 75 120 103 51 22 69 28
            140 42 14 3 68 8 120 12 52 3 96 6 97 7 3 3 134 -2 223 -9z m342 -2099 c142
            -53 292 -150 398 -256 86 -87 98 -102 147 -177 103 -161 144 -312 138 -503 -4
            -132 -8 -158 -37 -255 -89 -298 -341 -513 -713 -609 -33 -8 -73 -17 -90 -20
            -16 -3 -59 -10 -95 -16 -99 -17 -421 -21 -516 -6 -136 22 -231 76 -275 159
            -43 81 -45 126 -45 817 l1 654 115 28 c63 15 124 29 135 32 81 19 576 138 640
            154 105 27 123 26 197 -2z"
      />
    </g>
  </svg>
);

export default IconBooneAvatar;
