import React from 'react';

// X symbol
function False({ className }: { className?: string }) {
  return (
    <svg data-cy="exclude-attribute" className={className || 'attribute-icon-exclude'}>
      <path d="M20.2 7a.8.8 0 0 0-.57.23L14 12.87 8.37 7.24a.8.8 0 1 0-1.13 1.13L12.87 14l-5.63 5.63a.8.8 0 1 0 1.13 1.13L14 15.13l5.63 5.64a.8.8 0 0 0 1.13 0 .8.8 0 0 0 0-1.14L15.13 14l5.63-5.63A.8.8 0 0 0 20.2 7z" />
    </svg>
  );
}

export default False;
