import React from 'react';
import { GalleryVideo, GalleryVideoUpload } from './Media';
import MediaGallery from './Gallery';
import deepClone from '../helpers/DeepClone';
import YouTubeModal from './YouTubeModal';
import update from 'immutability-helper';
import { useStoreContext } from '../state';

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = deepClone(props);
  }

  render() {
    return (
      <div>
        <YouTubeModal
          addVideo={this.addVideo}
          targetKey={this.props.targetKey}
          ownerKey={this.props.ownerKey}
          ownerId={this.props.ownerId}
          apiUrl={this.props.apiUrl}
          youtubeApiKey={this.props.youtubeApiKey}
        />
        <MediaGallery
          {...this.state}
          renderMedia={this.renderVideo}
          renderMediaUpload={this.renderUpload}
          onFeaturedChanged={this.onFeaturedChanged}
          onDeleted={this.onDeleted}
          onUnflagged={this.onUnflagged}
        />
      </div>
    );
  }

  renderVideo = (video, handleDelete, handleUnflag, handleToggleFeatured) => {
    return (
      <GalleryVideo
        mediaUrl={video.video_url}
        imageUrl={video.frame_image_url}
        featured={video.featured}
        flagged={video.flags && video.flags.length > 0}
        mediaId={video.id}
        onDelete={handleDelete}
        onUnflag={handleUnflag}
        onToggleFeatured={handleToggleFeatured}
        onClick={this.playVideo}
        canFeatureMedia={this.props.canFeatureMedia}
        isEditable={this.props.isEditable}
      />
    );
  };

  renderUpload = () => {
    return <GalleryVideoUpload onUpload={this.handleUpload} />;
  };

  handleUpload = () => {
    $('#add-youtube-video-modal').modal('show');
  };

  onFeaturedChanged = (id, featured) => {
    this.setState((prevState, props) => {
      for (var i = 0; i < prevState.media.length; ++i) {
        let media = prevState.media[i];
        if (media.id === parseInt(id)) {
          media.featured = featured;
          break;
        }
      }
      return { media: deepClone(prevState.media) };
    });
  };

  onDeleted = id => {
    this.setState((prevState, _props) => {
      if (this.props.canUnflagMedia) {
        const newState = update(prevState, {
          media: {
            $apply: function (media) {
              for (let i = 0; i < media.length; ++i) {
                let video = media[i];
                if (video.id === id) {
                  // Add placeholder flag
                  video.flags = [
                    {
                      flagged_item_id: id,
                      id: -1,
                      reason: 'flagged',
                      canonical_place_id: -1,
                      flagged_item_type: 'PlaceVideo'
                    }
                  ];
                }
              }
              return media;
            }
          }
        });
        return { newState };
      } else {
        var newMedia = deepClone(prevState.media).filter(media => media.id !== parseInt(id));
        return { media: newMedia };
      }
    });
    this.props.analytics.trackListing('Video Link', 'deleted');
  };

  onUnflagged = id => {
    this.setState((prevState, _props) => {
      const newState = update(prevState, {
        media: {
          $apply: function (media) {
            for (let i = 0; i < media.length; ++i) {
              let video = media[i];
              if (video.id === id) {
                // Remove flags
                video.flags = [];
              }
            }
            return media;
          }
        }
      });
      return { newState };
    });
  };

  playVideo = id => {
    let selectedMedia = null;
    for (var i = 0; i < this.state.media.length; ++i) {
      let media = this.state.media[i];

      if (media.id === parseInt(id)) {
        selectedMedia = media;
        break;
      }
    }

    var win = window.open(selectedMedia.video_url, '_blank');
    win.focus();
  };

  addVideo = newVideo => {
    this.setState((prevState, _props) => {
      prevState.media.push(newVideo);
      return { media: deepClone(prevState.media) };
    });
    this.props.analytics.trackListing('Video Link', 'added');
  };
}

export default function VideoGallery(props) {
  const { analytics } = useStoreContext();
  return <Gallery {...props} analytics={analytics} />;
}
