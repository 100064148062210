import React from 'react';
import { InputWithLabel as Input } from '@/library/input-with-label';
import Subsection from '@/library/wizard/components/subsection';

export function Component({ state, updateField }){
  return (
    <Subsection>
      <div className="editor__form-field editor__name">
        <h4>Business Name</h4>
        <Input
          label=""
          value={state.name}
          onChange={(event) => updateField(event, 'name')}
        />
      </div>
    </Subsection>
  );
}

export default Component;
