// development analytics logger
const logger = action => (...args) => console.debug('[analytics]', `[${action}]`, ...args);

const mockAnalytics = {
  page: logger('page'),
  track: logger('track'),
  identify: logger('identify'),
  trackSubmit: logger('trackSubmit'),
  trackClick: logger('trackClick'),
  trackLink: logger('trackLink'),
  trackForm: logger('trackForm'),
  pageview: logger('pageview'),
  reset: logger('reset'),
  group: logger('group'),
  ready: logger('ready'),
  alias: logger('alias'),
  debug: logger('debug'),
  once: logger('once'),
  off: logger('off'),
  on: logger('on')
};

const t = window.analytics || mockAnalytics;

// Enforces formatting, object name should be uppercase and action should be lowercase.
// ~ analytics._track(object, action, {})
export default {
  ...t,
  _track: (object, action, properties) => {
    const o = object
      .split(' ')
      .map(str => str.charAt(0).toUpperCase() + str.slice(1))
      .join(' ');

    t.track(`${o} ${action.toLowerCase()}`, { properties: { ...properties } });
  }
};
