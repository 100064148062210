import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

class Modal extends React.Component {
  componentDidMount() {
    if (this.props.onHide) {
      const node = ReactDOM.findDOMNode(this);
      $(node).on('shown.bs.modal', this.props.onShow);
      $(node).on('hidden.bs.modal', this.props.onHide);
    }
  }

  render() {
    return(
      <div
        className="modal fade"
        id={this.props.id}
        data-backdrop={this.props.backdrop}
        role="dialog"
        tabIndex="-1"
        aria-hidden="true"
        aria-labelledby={this.props.ariaLabelledby}
      >
        <div className={"modal-dialog " + this.props.modalClass} role="document">
          <div className="modal-content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  ariaLabelledby: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  backdrop: PropTypes.string
};

export default Modal;
