
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import Corrections from './index';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Corrections mdxType="Corrections" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;