import React, { useState, useEffect } from 'react'
import { User } from '../../common/types';
import { UserTab } from './UserTab';

interface Props {
  users: Array<User>;
  selectedUser: User;
  handleUserOnClick: Function
}

export const UsersList = ({ ...props }: Props) => {
  const [users, setUsers] = useState<Array<User>>();
  const [displayedUsers, setDisplayedUsers] = useState<Array<User>>();


  const filterUsers = (str: string) => {
    const results = users.filter(user => {
      const name = user.name.toLowerCase()
      const searchInput = str.toLowerCase()

      return (name.includes(str.toLowerCase()));
    });
    return results;
  }

  const handleOnChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;

    setDisplayedUsers(filterUsers(value));
  }

  useEffect(() => {
    props.users.length > 0 &&
      setUsers(props.users);
    setDisplayedUsers(props.users);

    return () => {
      setDisplayedUsers([]);
    }
  }, [props.selectedUser]);

  return (
    <>
      <div className="user-list-header">
        <div className="user-search">
          <input
            className="form-control"
            type="text"
            name="userSearch"
            placeholder="Search"
            onChange={handleOnChange}
          />
        </div>
        <div className="user-list-selected">
          {props.selectedUser &&
            <UserTab
              user={props.selectedUser}
              onClick={props.handleUserOnClick}
              isSelected={true}
            />
          }
        </div>
      </div>
      <div className="user-list-content">
        {displayedUsers && displayedUsers.length > 0 ? (
          displayedUsers.map((user: User, index: number) =>
            <UserTab
              key={`${user.name}-${index}`}
              user={user}
              onClick={props.handleUserOnClick}
              isSelected={
                props.selectedUser &&
                props.selectedUser.id === user.id
              }
            />
          )
        ) : (
          <>No users available.</>
        )}
      </div>
    </>
  )
}
