import React from "react";
import * as ajax from "@/helpers/ajax";
import Bar from "@library/loaders/bar";
import Button from "@/library/_button";
import parseQueryString from "@/helpers/parseQueryString";
import constructQueryString from "@/helpers/constructQueryString";

import "./styles";

type StringMap = {[key: string]: string};

type Props = {
  canonicalPlace?: {id: string};
  data?: any[];
  onDataChanged?: (data: any[]) => void;
  limit?: number;
  page?: number;
  url: string;
  Row: React.FunctionComponent;
  Head: React.FunctionComponent;
  dataFilters?: StringMap;
  dataSort?: string;
  onRowAction?: (action: string, options: StringMap) => void;
};

function LoadingBars({loading, limit}) {
  return (
    <>
      {loading
        ? new Array(limit - 1)
            .fill(null)
            .forEach((n, i) => <Bar key={"bar-" + i}></Bar>)
        : null}
    </>
  );
}
const defaults = {
  pagination: {
    hasNextPage: false,
    hasPreviousPage: false,
    pages: 0,
    total: 0,
  },
  page: 1,
  data: [],
};

function setQueryParams(params: { [key: string]: any}) {
  const queryParams = parseQueryString()?.params || {};
  const updatedParams = Object.assign(queryParams, params)

  Object.keys(updatedParams).forEach((k) => updatedParams[k] == null && delete updatedParams[k]);

  window.history.replaceState(
    "",
    "",
    `${document.location.pathname}?${constructQueryString(updatedParams)}`
  );
}

function usePagination() {
  const [page, setPage] = React.useState(
    parseInt(parseQueryString()?.params?.page) || 1
  );
  const [pages, setPages] = React.useState(0);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [hasPreviousPage, setHasPreviousPage] = React.useState(false);
  const [total, setTotal] = React.useState(0);

  function setPageParam(page) {
    setQueryParams({page: page});
  }

  function incPage() {
    setPage((page) => {
      let p = page + 1;
      setPageParam(p);
      return p;
    });
  }

  function decPage() {
    setPage((page) => {
      let p = page - 1;
      setPageParam(p);
      return p;
    });
  }

  function setPagination(t) {
    setPage(t.page);
    setPages(t.pages);
    setHasNextPage(t.hasNextPage);
    setHasPreviousPage(t.hasPreviousPage);
    setTotal(t.total);
  }

  React.useEffect(() => {
    const {params} = parseQueryString();

    if (!params["page"]) {
      setPageParam(page);
    } else if (parseInt(params["page"]) !== page) {
      setPage(parseInt(params["page"]));
    }
  }, [page]);

  React.useEffect(() => {
    if (pages && (page > pages || page < 1)) {
      setPageParam(1);
      setPage(1);
    }
  }, [pages]);

  return {
    page,
    pages,
    hasNextPage,
    hasPreviousPage,
    total,
    setPage,
    setPagination,
    decPage,
    incPage,
  };
}

function PaginatedTable(props: Props) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const {
    page,
    pages,
    hasNextPage,
    hasPreviousPage,
    setPagination,
    incPage,
    decPage,
  } = usePagination();

  const [data, setData] = React.useState(props.data || []);

  const apiParams = () => {
    var params = {
      page: page,
      sort: props.dataSort
    };

    if (props.limit) {
      params['limit'] = props.limit;
    }

    if (props.dataFilters) {
      Object.assign(params, props.dataFilters);
    }

    return params;
  }

  async function loadData() {
    try {
      const params = apiParams();
      const apiUrl = `${props.url}?${constructQueryString(params)}`;

      const [promise] = ajax._get(apiUrl);

      const json = await promise;

      if (json) {
        if (json.page === page) {
          setData([]);
          setData(json.data);
          setQueryParams(params);
          props.onDataChanged && props.onDataChanged(json.data);
          setPagination({
            page: json.page,
            hasNextPage: json.hasNextPage,
            hasPreviousPage: json.hasPreviousPage,
            pages: json.pages,
            total: json.total,
          });
          setLoading(false);
          window.scrollTo(0, 0);
          if (error) {
            setError(false);
          }
        }
      }
    } catch (e) {
      if (e) {
        setError(true);
        setLoading(false);
      }
    }
  }

  React.useEffect(() => {
    if (page) {
      loadData();
    }
  }, [page]);

  React.useEffect(() => {
    loadData();
  }, [props.dataSort, props.dataFilters]);

  return (
    <table data-cy="data" className="editor__paginated-table">
      <thead>
        <props.Head />
      </thead>
      <LoadingBars limit={props.limit} loading={loading} />
      <tbody>
        {data &&
          data.length > 0 &&
          data.map((datum, idx) => {
            return (
              <props.Row
                key={"c" + idx}
                {...datum}
                onRowAction={props.onRowAction}
                onChange={() => setPagination(defaults)}
              />
            );
          })}
      </tbody>
      <tfoot>
        {!loading && (
          <tr className="editor__pagination__controls">
            {pages && pages > 0 ? (
              <td>
                <div>{`page ${page} of ${pages}`}</div>{" "}
              </td>
            ) : null}
            {hasPreviousPage && (
              <td>
                <Button className="editor__button--white" onClick={decPage}>
                  back
                </Button>
              </td>
            )}
            {hasNextPage && (
              <td>
                <Button
                  className="editor__button--white"
                  onClick={() => {
                    setLoading(true);
                    incPage();
                  }}
                >
                  next
                </Button>
              </td>
              )}
          </tr>
        )}
      </tfoot>
    </table>
  );
}

export default PaginatedTable;
