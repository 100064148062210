import React, { useEffect } from 'react';
import * as ajax from '@/helpers/ajax';
import moment from 'moment';
import map from 'lodash/map';

import IconCheck from '../../../icons/IconCheck';
import IconHide from '../../../icons/IconHide';
import { PrimaryButton, WhiteButton } from '../../../library/Buttons';
import Modal from '../../../Modal';

import { cleanFieldName } from './helpers'

import '../styles/index';

import SuggestionDiff from './suggestion-diff'

import type SuggesterProps from './suggester-props'
import type SuggestionDetailProps from './suggestion-detail-props'

type Props = {
  className: string;
  created_at: string;
  details: SuggestionDetailProps[];
  onAccept: React.MouseEventHandler;
  onReject: React.MouseEventHandler;
  analytics: any;
  suggester: SuggesterProps;
  state: string;
  disabled: boolean;
};

const SuggestionDetails = (props: Props) => {
  useEffect( () => {
    props.analytics.trackListing('Suggestion', 'viewed');
  }, [])

  const createdAt = moment(props.created_at).fromNow();

  return (
    <React.Fragment>
      <div className="boone-listing__suggestion__content__details">
        {map(props.details, deets => (
          <SuggestionDiff key={deets.field} {...deets} />
        ))}
      </div>

      <div className="boone-listing__suggestion__content__actions">
        {props.state === 'pending' && (
          <React.Fragment>
            <WhiteButton
              ariaLabel="Ignore suggestion"
              onClick={props.onReject}
              disabled={props.disabled}
            >
              <IconHide viewBoxX="-6" viewBoxY="-5" />
              Ignore suggestion
            </WhiteButton>
            <PrimaryButton
              ariaLabel="Accept suggestion"
              onClick={props.onAccept}
              disabled={props.disabled}
            >
              <IconCheck fillColor="#fff" viewBoxX="-6" viewBoxY="-5" />
              Accept suggestion
            </PrimaryButton>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default SuggestionDetails;
