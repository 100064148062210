import React from 'react';
import * as ajax from '@/helpers/ajax';
import classNames from 'classnames';
import Modal from './Modal';
import PlaceAutocomplete from './PlaceAutocomplete';

type Props = {
  placeName?: string;
};

type State = {
  placeName?: string;
  suggestionCount: number;
  errorFields: string[];
  creatingNewListing: boolean;
};

export default class CreatePlaceView extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      placeName: this.props.placeName,
      suggestionCount: 0,
      errorFields: [],
      creatingNewListing: false
    };

    this.handleResults = this.handleResults.bind(this);
  }

  handleNameChanged = e => {
    this.setState({ placeName: e.target.value });
  };

  handleResults(suggestions) {
    this.setState({ suggestionCount: suggestions.length });
  }

  createPlace = e => {
    this.setState({creatingNewListing: true});
    ajax
      .post(document.location.pathname, {
        tomo_place: {
          name: this.state.placeName
        }
      })
      .then(response => {
        if (response.ok && response.redirected) {
          window.location.href = response.url;
        } else {
          response.json().then(val => {
            this.setState({errorFields: Object.keys(val), creatingNewListing: false});
          });
        }
      });
  };

  clearResults = e => {
    this.setState({placeName: '', suggestionCount: 0, errorFields: [], creatingNewListing: false});
  };

  renderNothing() {
    return <div />;
  }

  renderOr() {
    return <span className="suggestions-or mr-3">or</span>;
  }

  render() {
    return (
      <Modal id="create-listing-modal" onHide={this.clearResults}>
        <div id="new-tomo-place">
          <span className="header-text">Create a new place listing</span>
          <span className="btn close-modal-button" role="button" data-dismiss="modal" onClick={this.clearResults}>
            <i className="mdi mdi-close" />
          </span>

          <div className="form-body">
            <div className="form-group">
              <div
                className={classNames({
                  field_with_errors: this.state.errorFields.includes('name')
                })}
              >
                <label htmlFor="name">{`The place's name`}</label>
              </div>
              <div
                className={classNames({
                  field_with_errors: this.state.errorFields.includes('name')
                })}
              >
                <input
                  data-cy="create-listing-search-input"
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Empire State Building"
                  value={this.state.placeName}
                  onChange={e => this.handleNameChanged(e)}
                />
              </div>
            </div>
          </div>

          <PlaceAutocomplete
            query={this.state.placeName}
            prompt="Is one of these the listing you intend to create?"
            onResults={this.handleResults}
            SuggestionRow={(suggestionProps) => {
              const url = (suggestionProps.thumbnail && suggestionProps.thumbnail.url) || '';
              return (
                <a
                  key={suggestionProps.id}
                  className={classNames({
                    'd-block': true,
                    'has-photo': suggestionProps.thumbnail !== null
                  })}
                  href={'/listings/' + suggestionProps.id}
                  onClick={e => suggestionProps.handleClick(e, suggestionProps.id)}
                >
                  <div className="boone-image__thumbnail mr-2" style={{ backgroundImage: 'url(' + url + ')' }} />
                  <div className="place-list-details">
                    <b>{suggestionProps.name}</b>
                    <br />
                    <small>{suggestionProps.address}</small>
                  </div>
                </a>
              );
            }}
          />

          <div>
            <div className="modal-actions">
              {this.state.suggestionCount > 0 ? this.renderOr() : this.renderNothing()}
              <input
                data-cy="create-listing-submit"
                type="submit"
                className="editor__button editor__button__primary"
                onClick={this.createPlace}
                value="Create a new place"
                disabled={this.state.creatingNewListing}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
