import React from 'react';
import { useWizardContext } from '../useWizardContext';
import './styles/index';



function ProgressBar(){
  const wz = useWizardContext();

  const [totalSteps] =  React.useState(wz.steps.length)

  return (
    <>
      {
      <div>
        <div className="editor__progress-bar">
            {
              new Array(totalSteps)
                .fill('editor__progress-step')
                .map((cls, idx) => idx <= wz.index ? cls + '--done' : cls)
                .filter((cls, idx) => {
                  if (wz.steps[idx].showStep !== false) {
                    return cls;
                  }
                })
                .map((cls, idx) =>
                  <div key={cls + idx + wz.index} className={cls} />
                )}
        </div>
      </div>
    }</>
  );
}

export default ProgressBar;
