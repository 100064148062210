import React from "react";
import applyFilter, {removeFilter} from "@/helpers/applyFilter";
import LaunchDarklyProvider from "../library/launch-darkly/provider";
import FilterFactory from "./filter-factory";
import type {Props as FilterProps} from "./filter-factory";

type Props = {
  filters: FilterProps[];
  replacedIds?: string[];
  clearing?: boolean;
  launchDarkly?: {
    user: {key: string; anonymous: boolean};
    client: string;
  };
};

function FilterSet({launchDarkly, filters, replacedIds}: Props) {
  return (
    <LaunchDarklyProvider
      user={launchDarkly?.user}
      clientID={launchDarkly?.client}
    >
      <div id="filter-group">
        {filters.map((f,idx) => (
          <FilterFactory
            {...f}
            key={f.label+idx}
            removeFilter={(keys, rid = replacedIds) => {
              removeFilter(keys, rid);
            }}
            applyFilter={(newParams, rid = replacedIds) => {
              applyFilter(newParams, rid);
            }}
          />
        ))}
      </div>
    </LaunchDarklyProvider>
  );
}

export default FilterSet;
