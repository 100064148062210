import React from 'react';
import LocationMapView from './LocationMap';
import * as ajax from '@/helpers/ajax';
import InputWithLabel from '@/library/input-with-label';
import { RoundEditButton } from '@/library/Buttons';

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const latitudeError = this.validateLatitude(this.props.latitude);
    let latitudeClean = latitudeError.length === 0 ? this.props.latitude : 0;

    const longitudeError = this.validateLongitude(this.props.longitude);
    let longitudeClean = longitudeError.length === 0 ? this.props.longitude : 0;

    return {
      editing: this.props.editing || false,
      errors: {},
      index: this.props.index,
      id: this.props.id,
      address1: this.props.address1,
      address2: this.props.address2,
      city: this.props.city,
      state: this.props.state,
      postal_code: this.props.postal_code,
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      navigable: this.props.navigable
    };
  }

  validateLatitude = val => {
    let error = '';
    if (val == null || isNaN(val) || val === '') {
      error = 'Please enter latitude values in decimal degrees';
    } else if (val > 90 || val < -90) {
      error = 'Please enter a value between -90 and 90';
    }
    return error;
  };

  validateLongitude = val => {
    let error = '';
    if (val == null || isNaN(val) || val === '') {
      error = 'Please enter longitude values in decimal degrees';
    } else if (val > 180 || val < -180) {
      error = 'Please enter a value between -180 and 180';
    }
    return error;
  };

  handleCoordinateEdit = (id, value, validate) => {
    const error = validate(value);
    this.setState(prevState => ({
      [id]: value,
      errors: {
        ...prevState.errors,
        [id]: error
      }
    }));
  };

  setPositionLocation = (latitude, longitude) => {
    this.setState(prevState => ({
      location_set_via: 'my_location',
      latitude: latitude,
      longitude: longitude
    }));
  }

  onMapLocationChange = (_view, point) => {
    this.props.setField(point.latitude, 'latitude')
    this.props.setField(point.longitude, 'longitude')
    this.props.setField('address_geocode', 'location_set_via')
    this.handleCoordinateEdit('latitude', point.latitude, this.validateLatitude);
    this.handleCoordinateEdit('longitude', point.longitude, this.validateLongitude);
  };

  _geocodeAddress() {
    let params = { access_token: window._env_.MAPBOX_KEY  };
    let query = `${this.props.address1} ${this.props.address2} ${this.props.city}, ${this.props.state} ${this.props.postal_code}`;
    let queryStr = $.param(params);
    let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?${queryStr}`;

    ajax.get(url).then(response => {
      if (response.ok) {
        response.json().then(val => {
          if (val.features && val.features[0]) {
            let loc = val.features[0].center;
            this.handleCoordinateEdit('latitude', loc[1], this.validateLatitude);
            this.handleCoordinateEdit('longitude', loc[0], this.validateLongitude);
            this.setState({ geocodeError: null });
            this.props.setField(loc[1], 'latitude')
            this.props.setField(loc[0], 'longitude')
            this.props.setField('address_geocode', 'location_set_via')
            this.setPositionLocation(loc[1], loc[0]);
          } else {
            this.setState({ geocodeError: 'Cannot geocode address' });
          }
        });
      }
    });
  }

  render() {
    return (
      <>
        <RoundEditButton
          style={{position: 'relative'}}
          onClick={() => this.setState({ editing: true })}
        />
        <div className="map-container">
          <LocationMapView
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            zoom={[14]}
            onMapLocationChange={this.onMapLocationChange.bind(this)}
            setField={this.props.setField}
            editing={this.state.editing}
            setPositionLocation={this.setPositionLocation}
          />
        </div>
        <div style={ this.state.editing ? {} : { display: 'none' }}>
          <div className="boone-form container-fluid" field="location" className='geocode_address_inputs'>
            <div className="row pr-4 pt-4">
              <div className="col-sm-12 ">
                <div className="pull-right">
                  <a href="javascript:void(0);" onClick={() => this._geocodeAddress()}>
                    Geocode address
                  </a>
                </div>
              </div>
            </div>
            <div className="bg-light">
              <InputWithLabel
                label="Latitude"
                error={Boolean(this.state.errors.latitude)}
                errorMessage={this.state.errors.latitude}
                labelClassName="editor__label"
                value={this.state.latitude}
                onChange={e => this.handleCoordinateEdit('latitude', e.target.value, this.validateLatitude)}
              />
              <InputWithLabel
                label="Longitude"
                error={Boolean(this.state.errors.longitude)}
                errorMessage={this.state.errors.longitude}
                labelClassName="editor__label"
                value={this.state.longitude}
                onChange={e => this.handleCoordinateEdit('longitude', e.target.value, this.validateLongitude)}
              />
              {this.state.geocodeError && (
                <div className="row pb-3 pt-1 pr-3 px-md-1">
                  <div className="col-sm-3" />
                  <div className="col-sm-4 small error-message">{this.state.geocodeError}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Location;
