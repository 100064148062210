import filterMedia from '../filterMedia';

describe('Filter Media', () => {
  it('filters out media with "disabled" tag', () => {
    const media = [
      { id: 0, from_verified_owner: false, from_your_org: true, featured: false, flags: [{ reason: 'disabled' }] },
      { id: 1, from_verified_owner: false, from_your_org: true, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 2, from_verified_owner: false, from_your_org: true, featured: false }
    ];
    const results = filterMedia(media);
    expect(results.fromOrg[0]).toEqual(1);
    expect(results.fromOrg[1]).toEqual(2);
  });

  it('when from_verified_owner and from_your_org are both false, item id is added to fromOthers array', () => {
    const media = [
      { id: 0, from_verified_owner: false, from_your_org: true, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 1, from_verified_owner: false, from_your_org: false, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 2, from_verified_owner: false, from_your_org: true, featured: false }
    ];
    const results = filterMedia(media);
    expect(results.fromOthers[0]).toEqual(1);
  });

  it('when from_verified_owner is true and from_your_org is true, id is added to the fromOrg array', () => {
    const media = [
      { id: 0, from_verified_owner: false, from_your_org: false, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 1, from_verified_owner: true, from_your_org: true, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 2, from_verified_owner: false, from_your_org: false, featured: false }
    ];
    const results = filterMedia(media);
    expect(results.fromOrg[0]).toEqual(1);
    expect(results.fromOwner.length).toEqual(0);
  });

  it('when from_verified_owner is true and from_your_org is false, id is added to the fromOwner array', () => {
    const media = [
      { id: 0, from_verified_owner: false, from_your_org: false, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 1, from_verified_owner: true, from_your_org: false, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 2, from_verified_owner: false, from_your_org: false, featured: false }
    ];
    const results = filterMedia(media);
    expect(results.fromOrg.length).toEqual(0);
    expect(results.fromOwner.length).toEqual(1);
  });

  it('when from_verified_owner is false and from_your_org is true, id is added to the fromOrg array', () => {
    const media = [
      { id: 0, from_verified_owner: false, from_your_org: false, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 1, from_verified_owner: false, from_your_org: true, featured: false, flags: [{ reason: 'flagged' }] },
      { id: 2, from_verified_owner: false, from_your_org: false, featured: false }
    ];
    const results = filterMedia(media);
    expect(results.fromOrg.length).toEqual(1);
    expect(results.fromOwner.length).toEqual(0);
  });
});
