export default [
  {
    parent_public_id: 'cultural-interest-sites',
    assignable: true,
    id: 2166,
    public_id: 'abandoned-site',
    name: 'Abandoned Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'abandoned-site'
    ]
  },
  {
    parent_public_id: 'tours-experiences',
    assignable: true,
    id: 2121,
    public_id: 'activity',
    name: 'Activity',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'activity'
    ]
  },
  {
    parent_public_id: 'resort',
    assignable: true,
    id: 1896,
    public_id: 'adventure-resort',
    name: 'Adventure Resort',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'resort',
      'adventure-resort'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2779,
    public_id: 'advertising-agency',
    name: 'Advertising Agency',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'advertising-agency'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2577,
    public_id: 'aerial-fitness-center',
    name: 'Aerial Fitness Center',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'aerial-fitness-center'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2136,
    public_id: 'aerial-tour',
    name: 'Aerial Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'aerial-tour'
    ]
  },
  {
    parent_public_id: 'middle-eastern-restaurant',
    assignable: true,
    id: 2477,
    public_id: 'afghan-restaurant',
    name: 'Afghan Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'middle-eastern-restaurant',
      'afghan-restaurant'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2428,
    public_id: 'african-restaurant',
    name: 'African Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'african-restaurant'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2780,
    public_id: 'agricultural-service',
    name: 'Agricultural Service',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'agricultural-service'
    ]
  },
  {
    parent_public_id: 'tours-experiences',
    assignable: true,
    id: 2122,
    public_id: 'agritourism-experience',
    name: 'Agritourism Experience',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'agritourism-experience'
    ]
  },
  {
    parent_public_id: 'vacation-rental',
    assignable: true,
    id: 1912,
    public_id: 'airbnb-rental',
    name: 'Airbnb Rental',
    public_id_breadcrumbs: [
      'places-to-stay',
      'vacation-rental-places',
      'vacation-rental',
      'airbnb-rental'
    ]
  },
  {
    parent_public_id: 'airplane-facilities',
    assignable: true,
    id: 2688,
    public_id: 'aircraft-hangar',
    name: 'Aircraft Hangar',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'airplane-facilities',
      'aircraft-hangar'
    ]
  },
  {
    parent_public_id: 'transportation-facilities',
    assignable: true,
    id: 2685,
    public_id: 'airplane-facilities',
    name: 'Airplane Facilities',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'airplane-facilities'
    ]
  },
  {
    parent_public_id: 'airplane-facilities',
    assignable: true,
    id: 2689,
    public_id: 'airplane-parking',
    name: 'Airplane Parking',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'airplane-facilities',
      'airplane-parking'
    ]
  },
  {
    parent_public_id: 'airplane-facilities',
    assignable: true,
    id: 2686,
    public_id: 'airplane-runway',
    name: 'Airplane Runway',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'airplane-facilities',
      'airplane-runway'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2663,
    public_id: 'airport',
    name: 'Airport',
    public_id_breadcrumbs: ['general-services', 'transportation', 'airport']
  },
  {
    parent_public_id: 'transportation-facilities',
    assignable: true,
    id: 2690,
    public_id: 'airport-facilities',
    name: 'Airport Facilities',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'airport-facilities'
    ]
  },
  {
    parent_public_id: 'airport-facilities',
    assignable: true,
    id: 2691,
    public_id: 'airport-gate',
    name: 'Airport Gate',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'airport-facilities',
      'airport-gate'
    ]
  },
  {
    parent_public_id: 'airport-facilities',
    assignable: true,
    id: 2692,
    public_id: 'airport-lounge',
    name: 'Airport Lounge',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'airport-facilities',
      'airport-lounge'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2678,
    public_id: 'airport-terminal',
    name: 'Airport Terminal',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'airport-terminal'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2250,
    public_id: 'airsoft-center',
    name: 'Airsoft Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'airsoft-center'
    ]
  },
  {
    parent_public_id: 'airplane-facilities',
    assignable: true,
    id: 2687,
    public_id: 'airstrip',
    name: 'Airstrip',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'airplane-facilities',
      'airstrip'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2650,
    public_id: 'alternative-healer',
    name: 'Alternative Healer',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'alternative-healer'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2709,
    public_id: 'american-football-field',
    name: 'American Football Field',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'american-football-field'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2031,
    public_id: 'american-football-stadium',
    name: 'American Football Stadium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'american-football-stadium'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2430,
    public_id: 'american-restaurant',
    name: 'American Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'american-restaurant'
    ]
  },
  {
    parent_public_id: 'theater',
    assignable: true,
    id: 2006,
    public_id: 'amphitheater',
    name: 'Amphitheater',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'theater',
      'amphitheater'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1917,
    public_id: 'amusement-park',
    name: 'Amusement Park',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'amusement-park'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1943,
    public_id: 'amusement-park-ride',
    name: 'Amusement Park Ride',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'amusement-park-ride'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1918,
    public_id: 'animal-attraction',
    name: 'Animal Attraction',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction'
    ]
  },
  {
    parent_public_id: 'animal-attraction',
    assignable: true,
    id: 1919,
    public_id: 'animal-rescue-sanctuary',
    name: 'Animal Rescue Sanctuary',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction',
      'animal-rescue-sanctuary'
    ]
  },
  {
    parent_public_id: 'general-services',
    assignable: true,
    id: 2660,
    public_id: 'animal-services',
    name: 'Animal Services',
    public_id_breadcrumbs: ['general-services', 'animal-services']
  },
  {
    parent_public_id: 'animal-services',
    assignable: true,
    id: 2661,
    public_id: 'animal-shelter',
    name: 'Animal Shelter',
    public_id_breadcrumbs: [
      'general-services',
      'animal-services',
      'animal-shelter'
    ]
  },
  {
    parent_public_id: 'book-shop',
    assignable: true,
    id: 2050,
    public_id: 'antiquarian-book-shop',
    name: 'Antiquarian Book Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'book-shop',
      'antiquarian-book-shop'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2042,
    public_id: 'antique-store',
    name: 'Antique Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'antique-store'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2099,
    public_id: 'appliance-store',
    name: 'Appliance Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'appliance-store'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2375,
    public_id: 'apres-ski-bar',
    name: 'Apres Ski Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'apres-ski-bar'
    ]
  },
  {
    parent_public_id: 'animal-attraction',
    assignable: true,
    id: 1920,
    public_id: 'aquarium',
    name: 'Aquarium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction',
      'aquarium'
    ]
  },
  {
    parent_public_id: 'pet-store',
    assignable: true,
    id: 2658,
    public_id: 'aquarium-fish-shop',
    name: 'Aquarium & Fish Shop',
    public_id_breadcrumbs: [
      'general-services',
      'pet-services',
      'pet-store',
      'aquarium-fish-shop'
    ]
  },
  {
    parent_public_id: 'public-garden',
    assignable: true,
    id: 2286,
    public_id: 'arboretum',
    name: 'Arboretum',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'public-garden',
      'arboretum'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1946,
    public_id: 'arcade',
    name: 'Arcade',
    public_id_breadcrumbs: ['attractions-activities', 'fun-games', 'arcade']
  },
  {
    parent_public_id: 'cultural-interest-sites',
    assignable: true,
    id: 2165,
    public_id: 'archaeological-site',
    name: 'Archaeological Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'archaeological-site'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2251,
    public_id: 'archery-range',
    name: 'Archery Range',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'archery-range'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2781,
    public_id: 'architect',
    name: 'Architect',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'architect'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2176,
    public_id: 'architectural-site',
    name: 'Architectural Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'architectural-site'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2140,
    public_id: 'architectural-tour',
    name: 'Architectural Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'architectural-tour'
    ]
  },
  {
    parent_public_id: 'event-space',
    assignable: true,
    id: 1979,
    public_id: 'arena',
    name: 'Arena',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'event-space',
      'arena'
    ]
  },
  {
    parent_public_id: 'latin-american-restaurant',
    assignable: true,
    id: 2457,
    public_id: 'argentinian-restaurant',
    name: 'Argentinian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'latin-american-restaurant',
      'argentinian-restaurant'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2043,
    public_id: 'art-gallery',
    name: 'Art Gallery',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'art-gallery']
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2013,
    public_id: 'art-museum',
    name: 'Art Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'art-museum'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2081,
    public_id: 'arts-crafts-store',
    name: 'Arts & Crafts Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'arts-crafts-store'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2435,
    public_id: 'asian-fusion-restaurant',
    name: 'Asian Fusion Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'asian-fusion-restaurant'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2434,
    public_id: 'asian-restaurant',
    name: 'Asian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2710,
    public_id: 'athletics-track',
    name: 'Athletics Track',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'athletics-track'
    ]
  },
  {
    parent_public_id: 'essential-services',
    assignable: true,
    id: 2566,
    public_id: 'atm',
    name: 'ATM',
    public_id_breadcrumbs: ['general-services', 'essential-services', 'atm']
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1916,
    public_id: 'attraction',
    name: 'Attraction',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'attraction'
    ]
  },
  {
    parent_public_id: 'attractions-activities',
    id: 1915,
    public_id: 'attractions',
    name: 'Attractions',
    public_id_breadcrumbs: ['attractions-activities', 'attractions']
  },
  {
    id: 1914,
    public_id: 'attractions-activities',
    name: 'Attractions & Activities',
    public_id_breadcrumbs: ['attractions-activities']
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2596,
    public_id: 'atv-rental',
    name: 'ATV Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'atv-rental'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2433,
    public_id: 'australian-restaurant',
    name: 'Australian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'australian-restaurant'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2530,
    public_id: 'auto-customization-service',
    name: 'Auto Customization Service',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'auto-services',
      'auto-customization-service'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2531,
    public_id: 'auto-dealer',
    name: 'Auto Dealer',
    public_id_breadcrumbs: ['auto-rv-services', 'auto-services', 'auto-dealer']
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2532,
    public_id: 'auto-glass-service',
    name: 'Auto Glass Service',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'auto-services',
      'auto-glass-service'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1927,
    public_id: 'automotive-attraction',
    name: 'Automotive Attraction',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'automotive-attraction'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2014,
    public_id: 'automotive-museum',
    name: 'Automotive Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'automotive-museum'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2529,
    public_id: 'automotive-service-place',
    name: 'Automotive Service Place',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'auto-services',
      'automotive-service-place'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2533,
    public_id: 'auto-parts-accessories-store',
    name: 'Auto Parts & Accessories Store',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'auto-services',
      'auto-parts-accessories-store'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2036,
    public_id: 'auto-racing-track',
    name: 'Auto Racing Track',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'auto-racing-track'
    ]
  },
  {
    id: 2521,
    public_id: 'auto-rv-services',
    name: 'Auto & RV Services',
    public_id_breadcrumbs: ['auto-rv-services']
  },
  {
    parent_public_id: 'auto-rv-services',
    id: 2528,
    public_id: 'auto-services',
    name: 'Auto Services',
    public_id_breadcrumbs: ['auto-rv-services', 'auto-services']
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2015,
    public_id: 'aviation-museum',
    name: 'Aviation Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'aviation-museum'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1951,
    public_id: 'axe-throwing-place',
    name: 'Axe Throwing Place',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'axe-throwing-place'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2118,
    public_id: 'baby-store',
    name: 'Baby Store',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'baby-store']
  },
  {
    parent_public_id: 'hostel',
    assignable: true,
    id: 1885,
    public_id: 'backpackers',
    name: 'Backpackers',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'hostel',
      'backpackers'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2711,
    public_id: 'badminton-court',
    name: 'Badminton Court',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'badminton-court'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2508,
    public_id: 'bagel-shop',
    name: 'Bagel Shop',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'bagel-shop']
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2745,
    public_id: 'baggage-locker',
    name: 'Baggage Locker',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'baggage-locker'
    ]
  },
  {
    parent_public_id: 'outfitters',
    assignable: true,
    id: 2060,
    public_id: 'bait-tackle-shop',
    name: 'Bait & Tackle Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'outfitters',
      'bait-tackle-shop'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2509,
    public_id: 'bakery',
    name: 'Bakery',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'bakery']
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 1996,
    public_id: 'ballet',
    name: 'Ballet',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'ballet'
    ]
  },
  {
    parent_public_id: 'essential-services',
    assignable: true,
    id: 2562,
    public_id: 'bank',
    name: 'Bank',
    public_id_breadcrumbs: ['general-services', 'essential-services', 'bank']
  },
  {
    parent_public_id: 'bars-drinks',
    assignable: true,
    id: 2374,
    public_id: 'bar',
    name: 'Bar',
    public_id_breadcrumbs: ['bars-restaurants', 'bars-drinks', 'bar']
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2142,
    public_id: 'bar-crawl',
    name: 'Bar Crawl',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'bar-crawl'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2578,
    public_id: 'barre-studio',
    name: 'Barre Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'barre-studio'
    ]
  },
  {
    parent_public_id: 'bars-restaurants',
    id: 2373,
    public_id: 'bars-drinks',
    name: 'Bars & Drinks',
    public_id_breadcrumbs: ['bars-restaurants', 'bars-drinks']
  },
  {
    id: 2369,
    public_id: 'bars-restaurants',
    name: 'Bars & Restaurants',
    public_id_breadcrumbs: ['bars-restaurants']
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2712,
    public_id: 'baseball-field',
    name: 'Baseball Field',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'baseball-field'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2032,
    public_id: 'baseball-stadium',
    name: 'Baseball Stadium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'baseball-stadium'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2713,
    public_id: 'basketball-court',
    name: 'Basketball Court',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'basketball-court'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2033,
    public_id: 'basketball-stadium',
    name: 'Basketball Stadium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'basketball-stadium'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2652,
    public_id: 'bath-house',
    name: 'Bath House',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'bath-house'
    ]
  },
  {
    parent_public_id: 'military-site',
    assignable: true,
    id: 2189,
    public_id: 'battlefield',
    name: 'Battlefield',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'military-site',
      'battlefield'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2316,
    public_id: 'bay',
    name: 'Bay',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'bay'
    ]
  },
  {
    parent_public_id: 'outdoors-features',
    assignable: true,
    id: 2365,
    public_id: 'bbq-grill-station',
    name: 'BBQ/Grill Station',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoors-features',
      'bbq-grill-station'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2448,
    public_id: 'bbq-joint',
    name: 'BBQ Joint',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'bbq-joint'
    ]
  },
  {
    parent_public_id: 'places-to-stay',
    id: 1869,
    public_id: 'bbs',
    name: 'B&Bs',
    public_id_breadcrumbs: ['places-to-stay', 'bbs']
  },
  {
    parent_public_id: 'beaches-swim-spots',
    assignable: true,
    id: 2278,
    public_id: 'beach',
    name: 'Beach',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'beaches-swim-spots',
      'beach'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2376,
    public_id: 'beach-bar',
    name: 'Beach Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'beach-bar'
    ]
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    id: 2277,
    public_id: 'beaches-swim-spots',
    name: 'Beaches & Swim Spots',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'beaches-swim-spots']
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2597,
    public_id: 'beach-gear-rental',
    name: 'Beach Gear Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'beach-gear-rental'
    ]
  },
  {
    parent_public_id: 'resort',
    assignable: true,
    id: 1897,
    public_id: 'beach-resort',
    name: 'Beach Resort',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'resort',
      'beach-resort'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2044,
    public_id: 'beach-supplies-store',
    name: 'Beach Supplies Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'beach-supplies-store'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2646,
    public_id: 'beauty-salon',
    name: 'Beauty Salon',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'beauty-salon'
    ]
  },
  {
    parent_public_id: 'bbs',
    assignable: true,
    id: 1870,
    public_id: 'bed-and-breakfast',
    name: 'Bed and Breakfast',
    public_id_breadcrumbs: ['places-to-stay', 'bbs', 'bed-and-breakfast']
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2377,
    public_id: 'beer-garden',
    name: 'Beer Garden',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'beer-garden'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2614,
    public_id: 'beer-shop',
    name: 'Beer Shop',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'beer-shop'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2141,
    public_id: 'beer-tour',
    name: 'Beer Tour/Tasting',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'beer-tour'
    ]
  },
  {
    parent_public_id: 'european-restaurant',
    assignable: true,
    id: 2462,
    public_id: 'belgian-restaurant',
    name: 'Belgian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'european-restaurant',
      'belgian-restaurant'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2119,
    public_id: 'big-box-store',
    name: 'Big Box Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'big-box-store'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2598,
    public_id: 'bike-rental',
    name: 'Bike Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'bike-rental'
    ]
  },
  {
    parent_public_id: 'bike-shop',
    assignable: true,
    id: 2048,
    public_id: 'bike-repair-shop',
    name: 'Bike Repair Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'bike-shop',
      'bike-repair-shop'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2047,
    public_id: 'bike-shop',
    name: 'Bike Shop',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'bike-shop']
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2143,
    public_id: 'bike-tour',
    name: 'Bike Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'bike-tour'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1952,
    public_id: 'bingo-hall',
    name: 'Bingo Hall',
    public_id_breadcrumbs: ['attractions-activities', 'fun-games', 'bingo-hall']
  },
  {
    parent_public_id: 'animal-attraction',
    assignable: true,
    id: 1926,
    public_id: 'bird-hide',
    name: 'Bird Hide',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction',
      'bird-hide'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2449,
    public_id: 'bistro',
    name: 'Bistro',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'bistro'
    ]
  },
  {
    parent_public_id: 'nature-reserve',
    assignable: true,
    id: 2340,
    public_id: 'blm-public-land',
    name: 'BLM Public Land',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'nature-reserves',
      'nature-reserve',
      'blm-public-land'
    ]
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1986,
    public_id: 'blues-club',
    name: 'Blues Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'blues-club'
    ]
  },
  {
    parent_public_id: 'hostel',
    assignable: true,
    id: 1886,
    public_id: 'boarding-house',
    name: 'Boarding House',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'hostel',
      'boarding-house'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2248,
    public_id: 'boating-activity',
    name: 'Boating Activity',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'boating-activity'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2045,
    public_id: 'boating-dive-shop',
    name: 'Boating/Dive Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'boating-dive-shop'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2680,
    public_id: 'boat-ramp',
    name: 'Boat Ramp',
    public_id_breadcrumbs: ['general-services', 'transportation', 'boat-ramp']
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2599,
    public_id: 'boat-rental',
    name: 'Boat Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'boat-rental'
    ]
  },
  {
    parent_public_id: 'boating-dive-shop',
    assignable: true,
    id: 2046,
    public_id: 'boat-repair-place',
    name: 'Boat Repair Place',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'boating-dive-shop',
      'boat-repair-place'
    ]
  },
  {
    parent_public_id: 'unique-stay',
    assignable: true,
    id: 1908,
    public_id: 'boat-stay',
    name: 'Boat Stay',
    public_id_breadcrumbs: [
      'places-to-stay',
      'unique-places-to-stay',
      'unique-stay',
      'boat-stay'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2144,
    public_id: 'boat-tour-water-tour-',
    name: 'Boat Tour (water tour)',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'boat-tour-water-tour-'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2252,
    public_id: 'bobsled-run',
    name: 'Bobsled Run',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'bobsled-run'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2730,
    public_id: 'bocce-ball-court',
    name: 'Bocce Ball Court',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'bocce-ball-court'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2782,
    public_id: 'bookmaker',
    name: 'Bookmaker',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'bookmaker'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2049,
    public_id: 'book-shop',
    name: 'Book Shop',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'book-shop']
  },
  {
    parent_public_id: 'campground',
    assignable: true,
    id: 1875,
    public_id: 'boondocking-area',
    name: 'Boondocking Area/Freedom Camping Area',
    public_id_breadcrumbs: [
      'places-to-stay',
      'campgrounds',
      'campground',
      'boondocking-area'
    ],
    description:
      'Undeveloped area where RVs are allowed - no hookups. Also known as dispersed camping, examples BLM land, typically free but a permit may be required'
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2579,
    public_id: 'boot-camp',
    name: 'Boot Camp',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'boot-camp'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2204,
    public_id: 'border-crossing',
    name: 'Border Crossing',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'border-crossing'
    ]
  },
  {
    parent_public_id: 'public-garden',
    assignable: true,
    id: 2287,
    public_id: 'botanical-garden',
    name: 'Botanical Garden',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'public-garden',
      'botanical-garden'
    ]
  },
  {
    parent_public_id: 'hotel',
    assignable: true,
    id: 1889,
    public_id: 'boutique-hotel',
    name: 'Boutique Hotel',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'hotel',
      'boutique-hotel'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1953,
    public_id: 'bowling-alley',
    name: 'Bowling Alley',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'bowling-alley'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2731,
    public_id: 'bowling-lawn',
    name: 'Bowling Lawn',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'bowling-lawn'
    ]
  },
  {
    parent_public_id: 'martial-arts-studio',
    assignable: true,
    id: 2588,
    public_id: 'boxing-studio',
    name: 'Boxing Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'martial-arts-studio',
      'boxing-studio'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2450,
    public_id: 'brasserie',
    name: 'Brasserie',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'brasserie'
    ]
  },
  {
    parent_public_id: 'latin-american-restaurant',
    assignable: true,
    id: 2458,
    public_id: 'brazilian-restaurant',
    name: 'Brazilian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'latin-american-restaurant',
      'brazilian-restaurant'
    ]
  },
  {
    parent_public_id: 'martial-arts-studio',
    assignable: true,
    id: 2589,
    public_id: 'brazillian-jiu-jitsu-gym',
    name: 'Brazillian Jiu Jitsu Gym',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'martial-arts-studio',
      'brazillian-jiu-jitsu-gym'
    ]
  },
  {
    parent_public_id: 'breakfast-spots',
    assignable: true,
    id: 2371,
    public_id: 'breakfast-spot',
    name: 'Breakfast Spot',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'breakfast-spots',
      'breakfast-spot'
    ]
  },
  {
    parent_public_id: 'bars-restaurants',
    id: 2370,
    public_id: 'breakfast-spots',
    name: 'Breakfast Spots',
    public_id_breadcrumbs: ['bars-restaurants', 'breakfast-spots']
  },
  {
    parent_public_id: 'grape-grain-attractions',
    assignable: true,
    id: 2404,
    public_id: 'brewery',
    name: 'Brewery',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'grape-grain-attractions',
      'brewery'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2091,
    public_id: 'bridal-shop',
    name: 'Bridal Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'bridal-shop'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2202,
    public_id: 'bridge',
    name: 'Bridge',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'bridge'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2253,
    public_id: 'bridge-swing',
    name: 'Bridge Swing',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'bridge-swing'
    ]
  },
  {
    parent_public_id: 'music-entertainment',
    assignable: true,
    id: 2010,
    public_id: 'brothel',
    name: 'Brothel',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'brothel'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2512,
    public_id: 'bubble-tea-shop',
    name: 'Bubble Tea Shop',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'quick-bites',
      'bubble-tea-shop'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2694,
    public_id: 'buddhist-temple',
    name: 'Buddhist Temple',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'buddhist-temple'
    ]
  },
  {
    parent_public_id: 'hotel',
    assignable: true,
    id: 1888,
    public_id: 'budget-hotel',
    name: 'Budget Hotel',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'hotel',
      'budget-hotel'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2451,
    public_id: 'buffet',
    name: 'Buffet',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'buffet'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2254,
    public_id: 'bungee-jumping-place',
    name: 'Bungee Jumping Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'bungee-jumping-place'
    ]
  },
  {
    parent_public_id: 'fast-food-joint',
    assignable: true,
    id: 2502,
    public_id: 'burger-joint',
    name: 'Burger Joint',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'burgers-fast-food',
      'fast-food-joint',
      'burger-joint'
    ]
  },
  {
    parent_public_id: 'bars-restaurants',
    id: 2500,
    public_id: 'burgers-fast-food',
    name: 'Burgers & Fast Food',
    public_id_breadcrumbs: ['bars-restaurants', 'burgers-fast-food']
  },
  {
    parent_public_id: 'modern-european-restaurant',
    assignable: true,
    id: 2485,
    public_id: 'burrito-taco-joint',
    name: 'Burrito/Taco Joint',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'modern-european-restaurant',
      'burrito-taco-joint'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2778,
    public_id: 'business',
    name: 'Business',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2759,
    public_id: 'businesses-offices-commercial',
    name: 'Businesses, Offices and Commercial',
    public_id_breadcrumbs: ['general-services', 'businesses-offices-commercial']
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2664,
    public_id: 'bus-station',
    name: 'Bus Station',
    public_id_breadcrumbs: ['general-services', 'transportation', 'bus-station']
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2145,
    public_id: 'bus-tour',
    name: 'Bus Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'bus-tour'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2615,
    public_id: 'butcher',
    name: 'Butcher',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'butcher'
    ]
  },
  {
    parent_public_id: 'public-garden',
    assignable: true,
    id: 2288,
    public_id: 'butterfly-garden',
    name: 'Butterfly Garden',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'public-garden',
      'butterfly-garden'
    ]
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 1997,
    public_id: 'cabaret',
    name: 'Cabaret',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'cabaret'
    ]
  },
  {
    parent_public_id: 'vacation-rental',
    assignable: true,
    id: 1913,
    public_id: 'cabin-stay',
    name: 'Cabin Stay',
    public_id_breadcrumbs: [
      'places-to-stay',
      'vacation-rental-places',
      'vacation-rental',
      'cabin-stay'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2665,
    public_id: 'cable-car',
    name: 'Cable Car',
    public_id_breadcrumbs: ['general-services', 'transportation', 'cable-car']
  },
  {
    parent_public_id: 'coffee-tea',
    assignable: true,
    id: 2411,
    public_id: 'cafe',
    name: 'Cafe',
    public_id_breadcrumbs: ['bars-restaurants', 'coffee-tea', 'cafe']
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2510,
    public_id: 'cafeteria',
    name: 'Cafeteria',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'cafeteria']
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2453,
    public_id: 'cajun-creole-restaurant',
    name: 'Cajun/Creole Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'cajun-creole-restaurant'
    ]
  },
  {
    parent_public_id: 'campgrounds',
    assignable: true,
    id: 1874,
    public_id: 'campground',
    name: 'Campground',
    public_id_breadcrumbs: ['places-to-stay', 'campgrounds', 'campground']
  },
  {
    parent_public_id: 'places-to-stay',
    id: 1873,
    public_id: 'campgrounds',
    name: 'Campgrounds',
    public_id_breadcrumbs: ['places-to-stay', 'campgrounds']
  },
  {
    parent_public_id: 'campgrounds',
    assignable: true,
    id: 1882,
    public_id: 'camping-site',
    name: 'Camping Site',
    public_id_breadcrumbs: ['places-to-stay', 'campgrounds', 'camping-site']
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2452,
    public_id: 'canadian-restaurant',
    name: 'Canadian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'canadian-restaurant'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2320,
    public_id: 'canal',
    name: 'Canal',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'canal'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2321,
    public_id: 'canal-lock',
    name: 'Canal Lock',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'canal-lock'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2071,
    public_id: 'candy-shop',
    name: 'Candy Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'candy-shop'
    ]
  },
  {
    parent_public_id: 'coffee-tea',
    assignable: true,
    id: 2412,
    public_id: 'cannabis-coffee-shop',
    name: 'Cannabis Coffee Shop',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'coffee-tea',
      'cannabis-coffee-shop'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2074,
    public_id: 'cannabis-dispensary',
    name: 'Cannabis Dispensary',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'cannabis-dispensary'
    ]
  },
  {
    parent_public_id: 'boating-activity',
    assignable: true,
    id: 2249,
    public_id: 'canoe-kayak-center',
    name: 'Canoe/Kayak Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'boating-activity',
      'canoe-kayak-center'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2436,
    public_id: 'cantonese-restaurant',
    name: 'Cantonese Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'cantonese-restaurant'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2317,
    public_id: 'canyon',
    name: 'Canyon',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'canyon'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2255,
    public_id: 'canyoneering-course',
    name: 'Canyoneering Course',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'canyoneering-course'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2016,
    public_id: 'car-collection',
    name: 'Car Collection',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'car-collection'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2580,
    public_id: 'cardio-studio',
    name: 'Cardio Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'cardio-studio'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2454,
    public_id: 'caribbean-restaurant',
    name: 'Caribbean Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'caribbean-restaurant'
    ]
  },
  {
    parent_public_id: 'amusement-park-ride',
    assignable: true,
    id: 1944,
    public_id: 'carousel',
    name: 'Carousel',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'amusement-park-ride',
      'carousel'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2100,
    public_id: 'carpet-store',
    name: 'Carpet Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'carpet-store'
    ]
  },
  {
    parent_public_id: 'vehicle-rental-place',
    assignable: true,
    id: 2557,
    public_id: 'car-rental-place',
    name: 'Car Rental Place',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'car-rv-van-rental',
      'vehicle-rental-place',
      'car-rental-place'
    ]
  },
  {
    parent_public_id: 'auto-rv-services',
    id: 2555,
    public_id: 'car-rv-van-rental',
    name: 'Car, RV & Van Rental',
    public_id_breadcrumbs: ['auto-rv-services', 'car-rv-van-rental']
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2534,
    public_id: 'car-wash',
    name: 'Car Wash',
    public_id_breadcrumbs: ['auto-rv-services', 'auto-services', 'car-wash']
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1928,
    public_id: 'casino',
    name: 'Casino',
    public_id_breadcrumbs: ['attractions-activities', 'attractions', 'casino']
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2783,
    public_id: 'catering-service',
    name: 'Catering Service',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'catering-service'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2309,
    public_id: 'cave',
    name: 'Cave',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'cave'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2177,
    public_id: 'cemetery',
    name: 'Cemetery',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'cemetery'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2380,
    public_id: 'champagne-bar',
    name: 'Champagne Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'champagne-bar'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2771,
    public_id: 'charity-organization',
    name: 'Charity Organization',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'charity-organization'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2072,
    public_id: 'cheese-shop',
    name: 'Cheese Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'cheese-shop'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2455,
    public_id: 'chicken-joint',
    name: 'Chicken Joint',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'chicken-joint'
    ]
  },
  {
    parent_public_id: 'family-services',
    assignable: true,
    id: 2733,
    public_id: 'child-care-service',
    name: 'Child Care Service',
    public_id_breadcrumbs: [
      'general-services',
      'family-services',
      'child-care-service'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2017,
    public_id: 'childrens-museum',
    name: "Children's Museum",
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'childrens-museum'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2437,
    public_id: 'chinese-restaurant',
    name: 'Chinese Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'chinese-restaurant'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2073,
    public_id: 'chocolate-shop',
    name: 'Chocolate Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'chocolate-shop'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2695,
    public_id: 'church',
    name: 'Church',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'church'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2693,
    public_id: 'churches-temples-mosques',
    name: 'Churches, Temples & Mosques',
    public_id_breadcrumbs: ['general-services', 'churches-temples-mosques']
  },
  {
    parent_public_id: 'grape-grain-attractions',
    assignable: true,
    id: 2405,
    public_id: 'cidery',
    name: 'Cidery',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'grape-grain-attractions',
      'cidery'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2381,
    public_id: 'cigar-bar',
    name: 'Cigar Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'cigar-bar'
    ]
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 1998,
    public_id: 'circus',
    name: 'Circus',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'circus'
    ]
  },
  {
    parent_public_id: 'civic-spaces',
    assignable: true,
    id: 2232,
    public_id: 'city-hall',
    name: 'City Hall',
    public_id_breadcrumbs: ['sights-landmarks', 'civic-spaces', 'city-hall']
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2209,
    public_id: 'city-wall',
    name: 'City Wall',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'city-wall'
    ]
  },
  {
    parent_public_id: 'civic-spaces',
    assignable: true,
    id: 2231,
    public_id: 'civic-center',
    name: 'Civic Center',
    public_id_breadcrumbs: ['sights-landmarks', 'civic-spaces', 'civic-center']
  },
  {
    parent_public_id: 'sights-landmarks',
    id: 2230,
    public_id: 'civic-spaces',
    name: 'Civic Spaces',
    public_id_breadcrumbs: ['sights-landmarks', 'civic-spaces']
  },
  {
    parent_public_id: 'tours-experiences',
    assignable: true,
    id: 2123,
    public_id: 'class-workshop',
    name: 'Class/Workshop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2784,
    public_id: 'cleaning-service',
    name: 'Cleaning Service',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'cleaning-service'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2256,
    public_id: 'climbing-center',
    name: 'Climbing Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'climbing-center'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2257,
    public_id: 'climbing-spot',
    name: 'Climbing Spot',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'climbing-spot'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2108,
    public_id: 'clothing-store',
    name: 'Clothing Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'clothing-store'
    ]
  },
  {
    parent_public_id: 'emergency-services',
    assignable: true,
    id: 2571,
    public_id: 'coast-guard-station',
    name: 'Coast Guard Station',
    public_id_breadcrumbs: [
      'general-services',
      'emergency-services',
      'coast-guard-station'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2378,
    public_id: 'cocktail-bar',
    name: 'Cocktail Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'cocktail-bar'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2125,
    public_id: 'cocktail-class',
    name: 'Cocktail Class',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'cocktail-class'
    ]
  },
  {
    parent_public_id: 'coffee-tea',
    assignable: true,
    id: 2410,
    public_id: 'coffee-shop',
    name: 'Coffee Shop',
    public_id_breadcrumbs: ['bars-restaurants', 'coffee-tea', 'coffee-shop']
  },
  {
    parent_public_id: 'bars-restaurants',
    id: 2409,
    public_id: 'coffee-tea',
    name: 'Coffee & Tea',
    public_id_breadcrumbs: ['bars-restaurants', 'coffee-tea']
  },
  {
    parent_public_id: 'civic-spaces',
    assignable: true,
    id: 2238,
    public_id: 'college-building',
    name: 'College Building',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'civic-spaces',
      'college-building'
    ]
  },
  {
    parent_public_id: 'music-entertainment',
    assignable: true,
    id: 1977,
    public_id: 'comedy-club',
    name: 'Comedy Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'comedy-club'
    ]
  },
  {
    parent_public_id: 'book-shop',
    assignable: true,
    id: 2051,
    public_id: 'comic-book-shop',
    name: 'Comic Book Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'book-shop',
      'comic-book-shop'
    ]
  },
  {
    parent_public_id: 'educational-institution',
    assignable: true,
    id: 2237,
    public_id: 'community-college',
    name: 'Community College',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'civic-spaces',
      'educational-institution',
      'community-college'
    ]
  },
  {
    parent_public_id: 'parks-gardens',
    assignable: true,
    id: 2299,
    public_id: 'community-garden',
    name: 'Community Garden',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'community-garden'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2540,
    public_id: 'compressed-air-point',
    name: 'Compressed Air Point',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'auto-services',
      'compressed-air-point'
    ]
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1987,
    public_id: 'concert-venue',
    name: 'Concert Venue',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'concert-venue'
    ]
  },
  {
    parent_public_id: 'event-space',
    assignable: true,
    id: 1980,
    public_id: 'conference-center',
    name: 'Conference Center',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'event-space',
      'conference-center'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2696,
    public_id: 'confucian-temple',
    name: 'Confucian Temple',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'confucian-temple'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2801,
    public_id: 'construction-company',
    name: 'Construction Company',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'construction-company'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2616,
    public_id: 'convenience-store',
    name: 'Convenience Store',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'convenience-store'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2124,
    public_id: 'cooking-class',
    name: 'Cooking Class',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'cooking-class'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1929,
    public_id: 'corn-maze',
    name: 'Corn Maze',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'corn-maze'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2761,
    public_id: 'corporate-headquarters',
    name: 'Corporate Headquarters',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'corporate-headquarters'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2082,
    public_id: 'cosmetics-shop',
    name: 'Cosmetics Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'cosmetics-shop'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2083,
    public_id: 'costume-shop',
    name: 'Costume Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'costume-shop'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1939,
    public_id: 'country-club',
    name: 'Country Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'country-club'
    ]
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1988,
    public_id: 'country-dance-club',
    name: 'Country Dance Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'country-dance-club'
    ]
  },
  {
    parent_public_id: 'government-building',
    assignable: true,
    id: 2241,
    public_id: 'courthouse',
    name: 'Courthouse',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'civic-spaces',
      'government-building',
      'courthouse'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2195,
    public_id: 'covered-bridge',
    name: 'Covered Bridge',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'covered-bridge'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2760,
    public_id: 'co-working-space',
    name: 'Co-working Space',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'co-working-space'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2379,
    public_id: 'craft-beer-bar',
    name: 'Craft Beer Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'craft-beer-bar'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2322,
    public_id: 'crater',
    name: 'Crater',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'crater'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2772,
    public_id: 'crematorium',
    name: 'Crematorium',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'crematorium'
    ]
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2416,
    public_id: 'creperie',
    name: 'Creperie',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'creperie'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2714,
    public_id: 'cricket-field',
    name: 'Cricket Field',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'cricket-field'
    ]
  },
  {
    parent_public_id: 'ski-trail',
    assignable: true,
    id: 2358,
    public_id: 'cross-country-ski-trail',
    name: 'Cross Country Ski Trail',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'ski-trail',
      'cross-country-ski-trail'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2581,
    public_id: 'crossfit-box',
    name: 'CrossFit Box',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'crossfit-box'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2666,
    public_id: 'cruise-dock',
    name: 'Cruise Dock',
    public_id_breadcrumbs: ['general-services', 'transportation', 'cruise-dock']
  },
  {
    parent_public_id: 'latin-american-restaurant',
    assignable: true,
    id: 2459,
    public_id: 'cuban-restaurant',
    name: 'Cuban Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'latin-american-restaurant',
      'cuban-restaurant'
    ]
  },
  {
    parent_public_id: 'event-space',
    assignable: true,
    id: 1981,
    public_id: 'cultural-center',
    name: 'Cultural Center',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'event-space',
      'cultural-center'
    ]
  },
  {
    parent_public_id: 'sights-landmarks',
    id: 2164,
    public_id: 'cultural-interest-sites',
    name: 'Cultural Interest Sites',
    public_id_breadcrumbs: ['sights-landmarks', 'cultural-interest-sites']
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2146,
    public_id: 'cultural-tour',
    name: 'Cultural Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'cultural-tour'
    ]
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2417,
    public_id: 'cupcake-shop',
    name: 'Cupcake Shop',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'cupcake-shop'
    ]
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2629,
    public_id: 'currency-exchange',
    name: 'Currency Exchange',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'currency-exchange'
    ]
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2637,
    public_id: 'customs-office',
    name: 'Customs Office',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'customs-office'
    ]
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2348,
    public_id: 'cycle-trailhead',
    name: 'Cycle Trailhead',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'cycle-trailhead'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2323,
    public_id: 'dam',
    name: 'Dam',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'dam'
    ]
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1989,
    public_id: 'dance-club',
    name: 'Dance Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'dance-club'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2582,
    public_id: 'dance-studio',
    name: 'Dance Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'dance-studio'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2649,
    public_id: 'day-spa',
    name: 'Day Spa',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'day-spa'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2511,
    public_id: 'deli',
    name: 'Deli',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'deli']
  },
  {
    parent_public_id: 'medical-services',
    assignable: true,
    id: 2643,
    public_id: 'dentist-office',
    name: 'Dentist Office',
    public_id_breadcrumbs: [
      'general-services',
      'medical-services',
      'dentist-office'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2749,
    public_id: 'dermatologist',
    name: 'Dermatologist',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'dermatologist'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2785,
    public_id: 'design-service',
    name: 'Design Service',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'design-service'
    ]
  },
  {
    parent_public_id: 'sweets-treats',
    assignable: true,
    id: 2415,
    public_id: 'dessert-shop',
    name: 'Dessert Shop',
    public_id_breadcrumbs: ['bars-restaurants', 'sweets-treats', 'dessert-shop']
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2438,
    public_id: 'dim-sum-restaurant',
    name: 'Dim Sum Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'dim-sum-restaurant'
    ]
  },
  {
    parent_public_id: 'breakfast-spot',
    assignable: true,
    id: 2372,
    public_id: 'diner',
    name: 'Diner',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'breakfast-spots',
      'breakfast-spot',
      'diner'
    ]
  },
  {
    parent_public_id: 'theater',
    assignable: true,
    id: 2007,
    public_id: 'dinner-theater',
    name: 'Dinner Theater',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'theater',
      'dinner-theater'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2716,
    public_id: 'disc-golf-field',
    name: 'Disc Golf Field',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'disc-golf-field'
    ]
  },
  {
    parent_public_id: 'grape-grain-attractions',
    assignable: true,
    id: 2406,
    public_id: 'distillery',
    name: 'Distillery',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'grape-grain-attractions',
      'distillery'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2382,
    public_id: 'dive-bar',
    name: 'Dive Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'dive-bar'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2332,
    public_id: 'dock',
    name: 'Dock',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'dock'
    ]
  },
  {
    parent_public_id: 'medical-services',
    assignable: true,
    id: 2639,
    public_id: 'doctors-office',
    name: 'Doctors Office',
    public_id_breadcrumbs: [
      'general-services',
      'medical-services',
      'doctors-office'
    ]
  },
  {
    parent_public_id: 'parks-gardens',
    assignable: true,
    id: 2297,
    public_id: 'dog-park',
    name: 'Dog Park',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'dog-park'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2034,
    public_id: 'dog-racetrack',
    name: 'Dog Racetrack',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'dog-racetrack'
    ]
  },
  {
    parent_public_id: 'animal-attraction',
    assignable: true,
    id: 1922,
    public_id: 'dolphin-experience',
    name: 'Dolphin Experience',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction',
      'dolphin-experience'
    ]
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2418,
    public_id: 'donut-shop',
    name: 'Donut Shop',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'donut-shop'
    ]
  },
  {
    parent_public_id: 'ski-trail',
    assignable: true,
    id: 2359,
    public_id: 'downhill-ski-trail',
    name: 'Downhill Ski Trail',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'ski-trail',
      'downhill-ski-trail'
    ]
  },
  {
    parent_public_id: 'movie-theater-cinema',
    assignable: true,
    id: 1972,
    public_id: 'drive-in-movie-theater',
    name: 'Drive-in Movie Theater',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'movie-theater-cinema',
      'drive-in-movie-theater'
    ]
  },
  {
    parent_public_id: 'fast-food-joint',
    assignable: true,
    id: 2503,
    public_id: 'drive-in-restaurant',
    name: 'Drive-In Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'burgers-fast-food',
      'fast-food-joint',
      'drive-in-restaurant'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2715,
    public_id: 'driving-range',
    name: 'Driving Range',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'driving-range'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2134,
    public_id: 'driving-school',
    name: 'Driving School',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'driving-school'
    ]
  },
  {
    parent_public_id: 'laundry-services',
    assignable: true,
    id: 2627,
    public_id: 'dry-cleaner',
    name: 'Dry Cleaner',
    public_id_breadcrumbs: [
      'general-services',
      'laundry-services',
      'dry-cleaner'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2333,
    public_id: 'dry-stack',
    name: 'Dry Stack',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'dry-stack'
    ]
  },
  {
    parent_public_id: 'bbs',
    assignable: true,
    id: 1872,
    public_id: 'dude-ranch',
    name: 'Dude Ranch',
    public_id_breadcrumbs: ['places-to-stay', 'bbs', 'dude-ranch']
  },
  {
    parent_public_id: 'rv-services',
    assignable: true,
    id: 2523,
    public_id: 'dump-station',
    name: 'Dump Station',
    public_id_breadcrumbs: ['auto-rv-services', 'rv-services', 'dump-station']
  },
  {
    parent_public_id: 'resort',
    assignable: true,
    id: 1899,
    public_id: 'eco-resort',
    name: 'Eco Resort',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'resort',
      'eco-resort'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2147,
    public_id: 'eco-tour',
    name: 'Eco Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'eco-tour'
    ]
  },
  {
    parent_public_id: 'civic-spaces',
    assignable: true,
    id: 2234,
    public_id: 'educational-institution',
    name: 'Educational Institution',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'civic-spaces',
      'educational-institution'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2787,
    public_id: 'electrician',
    name: 'Electrician',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'electrician'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2113,
    public_id: 'electronics-shop',
    name: 'Electronics Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'electronics-shop'
    ]
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2630,
    public_id: 'embassy-consulate',
    name: 'Embassy/Consulate',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'embassy-consulate'
    ]
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2742,
    public_id: 'emergency-phone',
    name: 'Emergency Phone',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'emergency-phone'
    ]
  },
  {
    parent_public_id: 'emergency-services',
    assignable: true,
    id: 2569,
    public_id: 'emergency-service',
    name: 'Emergency Service',
    public_id_breadcrumbs: [
      'general-services',
      'emergency-services',
      'emergency-service'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2568,
    public_id: 'emergency-services',
    name: 'Emergency Services',
    public_id_breadcrumbs: ['general-services', 'emergency-services']
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2786,
    public_id: 'engineering-firm',
    name: 'Engineering Firm',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'engineering-firm'
    ]
  },
  {
    parent_public_id: 'pub',
    assignable: true,
    id: 2393,
    public_id: 'english-pub',
    name: 'English Pub',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'pub',
      'english-pub'
    ]
  },
  {
    parent_public_id: 'european-restaurant',
    assignable: true,
    id: 2463,
    public_id: 'english-restaurant',
    name: 'English Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'european-restaurant',
      'english-restaurant'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2205,
    public_id: 'entrance',
    name: 'Entrance',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'entrance'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2258,
    public_id: 'equestrian-center',
    name: 'Equestrian Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'equestrian-center'
    ]
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    id: 2367,
    public_id: 'equestrian-facilities',
    name: 'Equestrian Facilities',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'equestrian-facilities'
    ]
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2351,
    public_id: 'equestrian-trailhead',
    name: 'Equestrian Trailhead',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'equestrian-trailhead'
    ]
  },
  {
    parent_public_id: 'specialty-museum',
    assignable: true,
    id: 2019,
    public_id: 'erotic-museum',
    name: 'Erotic Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'specialty-museum',
      'erotic-museum'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1954,
    public_id: 'escape-room',
    name: 'Escape Room',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'escape-room'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2735,
    public_id: 'essential-amenities',
    name: 'Essential Amenities',
    public_id_breadcrumbs: ['general-services', 'essential-amenities']
  },
  {
    parent_public_id: 'general-services',
    id: 2561,
    public_id: 'essential-services',
    name: 'Essential Services',
    public_id_breadcrumbs: ['general-services', 'essential-services']
  },
  {
    parent_public_id: 'african-restaurant',
    assignable: true,
    id: 2429,
    public_id: 'ethiopian-restaurant',
    name: 'Ethiopian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'african-restaurant',
      'ethiopian-restaurant'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2461,
    public_id: 'european-restaurant',
    name: 'European Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'european-restaurant'
    ]
  },
  {
    parent_public_id: 'auto-rv-services',
    id: 2545,
    public_id: 'ev-charging',
    name: 'EV Charging',
    public_id_breadcrumbs: ['auto-rv-services', 'ev-charging']
  },
  {
    parent_public_id: 'ev-charging',
    assignable: true,
    id: 2546,
    public_id: 'ev-charging-station',
    name: 'EV Charging Station',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'ev-charging',
      'ev-charging-station'
    ]
  },
  {
    parent_public_id: 'music-entertainment',
    assignable: true,
    id: 1978,
    public_id: 'event-space',
    name: 'Event Space',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'event-space'
    ]
  },
  {
    parent_public_id: 'scenic-points',
    assignable: true,
    id: 2229,
    public_id: 'exhibit',
    name: 'Exhibit',
    public_id_breadcrumbs: ['sights-landmarks', 'scenic-points', 'exhibit']
  },
  {
    parent_public_id: 'event-space',
    assignable: true,
    id: 1982,
    public_id: 'expo-center',
    name: 'Expo Center',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'event-space',
      'expo-center'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2748,
    public_id: 'eye-doctor',
    name: 'Eye Doctor',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'eye-doctor'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2773,
    public_id: 'factory',
    name: 'Factory',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'factory'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2148,
    public_id: 'factory-tour',
    name: 'Factory Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'factory-tour'
    ]
  },
  {
    parent_public_id: 'event-space',
    assignable: true,
    id: 1983,
    public_id: 'fairground',
    name: 'Fairground',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'event-space',
      'fairground'
    ]
  },
  {
    parent_public_id: 'middle-eastern-restaurant',
    assignable: true,
    id: 2478,
    public_id: 'falafel-place',
    name: 'Falafel Place',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'middle-eastern-restaurant',
      'falafel-place'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2732,
    public_id: 'family-services',
    name: 'Family Services',
    public_id_breadcrumbs: ['general-services', 'family-services']
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2774,
    public_id: 'farm',
    name: 'Farm',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'farm'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1932,
    public_id: 'farm-attraction',
    name: 'Farm Attraction',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'farm-attraction'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2053,
    public_id: 'farmers-market',
    name: 'Farmers Market',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'farmers-market'
    ]
  },
  {
    parent_public_id: 'bbs',
    assignable: true,
    id: 1871,
    public_id: 'farm-stay',
    name: 'Farm Stay',
    public_id_breadcrumbs: ['places-to-stay', 'bbs', 'farm-stay']
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2469,
    public_id: 'farm-to-table-restaurant',
    name: 'Farm to Table Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'farm-to-table-restaurant'
    ]
  },
  {
    parent_public_id: 'burgers-fast-food',
    assignable: true,
    id: 2501,
    public_id: 'fast-food-joint',
    name: 'Fast Food Joint',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'burgers-fast-food',
      'fast-food-joint'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2583,
    public_id: 'fencing-club',
    name: 'Fencing Club',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'fencing-club'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1933,
    public_id: 'ferris-wheel',
    name: 'Ferris Wheel',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'ferris-wheel'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2667,
    public_id: 'ferry-terminal',
    name: 'Ferry Terminal',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'ferry-terminal'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2331,
    public_id: 'field',
    name: 'Field',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'field'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2717,
    public_id: 'field-hockey-field',
    name: 'Field Hockey Field',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'field-hockey-field'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2439,
    public_id: 'filipino-restaurant',
    name: 'Filipino Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'filipino-restaurant'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2767,
    public_id: 'film-studio',
    name: 'Film Studio',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'film-studio'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2788,
    public_id: 'financial-service',
    name: 'Financial Service',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'financial-service'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2470,
    public_id: 'fine-dining-restaurant',
    name: 'Fine Dining Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'fine-dining-restaurant'
    ]
  },
  {
    parent_public_id: 'emergency-services',
    assignable: true,
    id: 2570,
    public_id: 'fire-station',
    name: 'Fire Station',
    public_id_breadcrumbs: [
      'general-services',
      'emergency-services',
      'fire-station'
    ]
  },
  {
    parent_public_id: 'fast-food-joint',
    assignable: true,
    id: 2504,
    public_id: 'fish-chips-place',
    name: 'Fish & Chips Place',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'burgers-fast-food',
      'fast-food-joint',
      'fish-chips-place'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2149,
    public_id: 'fishing-charter',
    name: 'Fishing Charter',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'fishing-charter'
    ]
  },
  {
    parent_public_id: 'outfitters',
    assignable: true,
    id: 2061,
    public_id: 'fishing-hunting-store',
    name: 'Fishing & Hunting Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'outfitters',
      'fishing-hunting-store'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2617,
    public_id: 'fish-market',
    name: 'Fish Market',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'fish-market'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2575,
    public_id: 'fitness-facilities',
    name: 'Fitness Facilities',
    public_id_breadcrumbs: ['general-services', 'fitness-facilities']
  },
  {
    parent_public_id: 'fitness-facilities',
    assignable: true,
    id: 2576,
    public_id: 'fitness-facility',
    name: 'Fitness Facility',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2115,
    public_id: 'flea-market',
    name: 'Flea Market',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'flea-market']
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2132,
    public_id: 'flight-school',
    name: 'Flight School',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'flight-school'
    ]
  },
  {
    parent_public_id: 'public-garden',
    assignable: true,
    id: 2289,
    public_id: 'flower-garden',
    name: 'Flower Garden',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'public-garden',
      'flower-garden'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2084,
    public_id: 'flower-shop-florist',
    name: 'Flower Shop/Florist',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'flower-shop-florist'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2259,
    public_id: 'flyboarding-place',
    name: 'Flyboarding Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'flyboarding-place'
    ]
  },
  {
    parent_public_id: 'public-art',
    assignable: true,
    id: 2218,
    public_id: 'folk-art',
    name: 'Folk Art',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'public-art-displays',
      'public-art',
      'folk-art'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2513,
    public_id: 'food-court',
    name: 'Food Court',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'food-court']
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2054,
    public_id: 'foodie-shop',
    name: 'Foodie Shop',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'foodie-shop']
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2150,
    public_id: 'food-tour',
    name: 'Food Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'food-tour'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2514,
    public_id: 'food-truck',
    name: 'Food Truck',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'food-truck']
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2310,
    public_id: 'forest',
    name: 'Forest',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'forest'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2206,
    public_id: 'fountain',
    name: 'Fountain',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'fountain'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2789,
    public_id: 'framers',
    name: 'Framers',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'framers'
    ]
  },
  {
    parent_public_id: 'european-restaurant',
    assignable: true,
    id: 2464,
    public_id: 'french-restaurant',
    name: 'French Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'european-restaurant',
      'french-restaurant'
    ]
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2419,
    public_id: 'frozen-yogurt-shop',
    name: 'Frozen Yogurt Shop',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'frozen-yogurt-shop'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1955,
    public_id: 'fruit-slot-machines-place',
    name: 'Fruit/Slot Machines Place',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'fruit-slot-machines-place'
    ]
  },
  {
    parent_public_id: 'gas-fuel-stations',
    assignable: true,
    id: 2551,
    public_id: 'fuel-dock',
    name: 'Fuel Dock',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'gas-fuel-stations',
      'fuel-dock'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2777,
    public_id: 'funeral-home',
    name: 'Funeral Home',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'funeral-home'
    ]
  },
  {
    parent_public_id: 'attractions-activities',
    id: 1945,
    public_id: 'fun-games',
    name: 'Fun & Games',
    public_id_breadcrumbs: ['attractions-activities', 'fun-games']
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2085,
    public_id: 'garden-supplies-store',
    name: 'Garden Supplies Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'garden-supplies-store'
    ]
  },
  {
    parent_public_id: 'gas-fuel-stations',
    assignable: true,
    id: 2549,
    public_id: 'gas-station',
    name: 'Gas/Fuel Station',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'gas-fuel-stations',
      'gas-station'
    ]
  },
  {
    parent_public_id: 'auto-rv-services',
    id: 2548,
    public_id: 'gas-fuel-stations',
    name: 'Gas & Fuel Stations',
    public_id_breadcrumbs: ['auto-rv-services', 'gas-fuel-stations']
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2384,
    public_id: 'gastro-pub',
    name: 'Gastro Pub',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'gastro-pub'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2383,
    public_id: 'gay-bar',
    name: 'Gay Bar',
    public_id_breadcrumbs: ['bars-restaurants', 'bars-drinks', 'bar', 'gay-bar']
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2207,
    public_id: 'gazebo',
    name: 'Gazebo',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'gazebo'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2600,
    public_id: 'gear-rental',
    name: 'Gear Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'gear-rental'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2595,
    public_id: 'gear-rental-services',
    name: 'Gear Rental Services',
    public_id_breadcrumbs: ['general-services', 'gear-rental-services']
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2420,
    public_id: 'gelato',
    name: 'Gelato',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'gelato'
    ]
  },
  {
    id: 2560,
    public_id: 'general-services',
    name: 'General Services',
    public_id_breadcrumbs: ['general-services']
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2208,
    public_id: 'geocache',
    name: 'Geocache',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'geocache'
    ]
  },
  {
    parent_public_id: 'geographic-features',
    assignable: true,
    id: 2301,
    public_id: 'geographic-feature',
    name: 'Geographic Feature',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature'
    ]
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    id: 2300,
    public_id: 'geographic-features',
    name: 'Geographic Features',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'geographic-features']
  },
  {
    parent_public_id: 'cultural-interest-sites',
    assignable: true,
    id: 2168,
    public_id: 'geological-site',
    name: 'Geological Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'geological-site'
    ]
  },
  {
    parent_public_id: 'european-restaurant',
    assignable: true,
    id: 2465,
    public_id: 'german-restaurant',
    name: 'German Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'european-restaurant',
      'german-restaurant'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2324,
    public_id: 'geyser',
    name: 'Geyser',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'geyser'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2155,
    public_id: 'ghost-tour',
    name: 'Ghost Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'ghost-tour'
    ]
  },
  {
    parent_public_id: 'abandoned-site',
    assignable: true,
    id: 2167,
    public_id: 'ghost-town',
    name: 'Ghost Town',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'abandoned-site',
      'ghost-town'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2055,
    public_id: 'gift-shop',
    name: 'Gift Shop',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'gift-shop']
  },
  {
    parent_public_id: 'unique-places-to-stay',
    assignable: true,
    id: 1902,
    public_id: 'glamping-place',
    name: 'Glamping Place',
    public_id_breadcrumbs: [
      'places-to-stay',
      'unique-places-to-stay',
      'glamping-place'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1956,
    public_id: 'go-karting-place',
    name: 'Go Karting Place',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'go-karting-place'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2601,
    public_id: 'golf-cart-rental',
    name: 'Golf Cart Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'golf-cart-rental'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2718,
    public_id: 'golf-course',
    name: 'Golf Course',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'golf-course'
    ]
  },
  {
    parent_public_id: 'civic-spaces',
    assignable: true,
    id: 2239,
    public_id: 'government-building',
    name: 'Government Building',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'civic-spaces',
      'government-building'
    ]
  },
  {
    parent_public_id: 'bars-restaurants',
    id: 2403,
    public_id: 'grape-grain-attractions',
    name: 'Grape & Grain Attractions',
    public_id_breadcrumbs: ['bars-restaurants', 'grape-grain-attractions']
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2305,
    public_id: 'grasslands',
    name: 'Grasslands',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'grasslands'
    ]
  },
  {
    parent_public_id: 'mediterranean-restaurant',
    assignable: true,
    id: 2475,
    public_id: 'greek-restaurant',
    name: 'Greek Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'mediterranean-restaurant',
      'greek-restaurant'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2618,
    public_id: 'grocery-store',
    name: 'Grocery Store',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'grocery-store'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2156,
    public_id: 'guided-trail-tour',
    name: 'Guided Trail Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'guided-trail-tour'
    ]
  },
  {
    parent_public_id: 'outfitters',
    assignable: true,
    id: 2062,
    public_id: 'gun-shop',
    name: 'Gun Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'outfitters',
      'gun-shop'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2584,
    public_id: 'gym',
    name: 'Gym',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'gym'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2585,
    public_id: 'gymnastics-studio',
    name: 'Gymnastics Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'gymnastics-studio'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2648,
    public_id: 'hair-salon-barbershop',
    name: 'Hair Salon/Barbershop',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'hair-salon-barbershop'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2727,
    public_id: 'handball-court',
    name: 'Handball Court',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'handball-court'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2260,
    public_id: 'hang-gliding-place',
    name: 'Hang Gliding Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'hang-gliding-place'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2668,
    public_id: 'harbor',
    name: 'Harbor',
    public_id_breadcrumbs: ['general-services', 'transportation', 'harbor']
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2112,
    public_id: 'hardware-store',
    name: 'Hardware Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'hardware-store'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1930,
    public_id: 'haunted-attraction',
    name: 'Haunted Attraction',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'haunted-attraction'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2515,
    public_id: 'hawker-centre',
    name: 'Hawker Centre',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'hawker-centre']
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2075,
    public_id: 'head-shop',
    name: 'Head Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'head-shop'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2620,
    public_id: 'health-food-store',
    name: 'Health Food Store',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'health-food-store'
    ]
  },
  {
    parent_public_id: 'aerial-tour',
    assignable: true,
    id: 2137,
    public_id: 'helicopter-tour',
    name: 'Helicopter Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'aerial-tour',
      'helicopter-tour'
    ]
  },
  {
    parent_public_id: 'transportation-facilities',
    assignable: true,
    id: 2684,
    public_id: 'helipad',
    name: 'Helipad',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'helipad'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2178,
    public_id: 'heritage-trail',
    name: 'Heritage Trail',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'heritage-trail'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2213,
    public_id: 'highway-exit',
    name: 'Highway Exit',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'highway-exit'
    ]
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2352,
    public_id: 'hiking-trailhead',
    name: 'Hiking Trailhead',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'hiking-trailhead'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2697,
    public_id: 'hindu-temple',
    name: 'Hindu Temple',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'hindu-temple'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2181,
    public_id: 'historic-castle',
    name: 'Historic Castle',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'historic-castle'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2182,
    public_id: 'historic-cathedral',
    name: 'Historic Cathedral',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'historic-cathedral'
    ]
  },
  {
    parent_public_id: 'military-site',
    assignable: true,
    id: 2190,
    public_id: 'historic-fort',
    name: 'Historic Fort',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'military-site',
      'historic-fort'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2179,
    public_id: 'historic-home',
    name: 'Historic Home',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'historic-home'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2184,
    public_id: 'historic-palace',
    name: 'Historic Palace',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'historic-palace'
    ]
  },
  {
    parent_public_id: 'sights-landmarks',
    id: 2174,
    public_id: 'historic-places',
    name: 'Historic Places',
    public_id_breadcrumbs: ['sights-landmarks', 'historic-places']
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2185,
    public_id: 'historic-plantation',
    name: 'Historic Plantation',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'historic-plantation'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2180,
    public_id: 'historic-railroad',
    name: 'Historic Railroad',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'historic-railroad'
    ]
  },
  {
    parent_public_id: 'route',
    assignable: true,
    id: 2225,
    public_id: 'historic-route',
    name: 'Historic Route',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'scenic-points',
      'route',
      'historic-route'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2183,
    public_id: 'historic-ruin',
    name: 'Historic Ruin',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'historic-ruin'
    ]
  },
  {
    parent_public_id: 'historic-places',
    assignable: true,
    id: 2175,
    public_id: 'historic-site',
    name: 'Historic Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2194,
    public_id: 'historic-temple',
    name: 'Historic Temple',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'historic-temple'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2021,
    public_id: 'history-museum',
    name: 'History Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'history-museum'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2157,
    public_id: 'history-tour',
    name: 'History Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'history-tour'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2058,
    public_id: 'home-goods-shop',
    name: 'Home Goods Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'home-goods-shop'
    ]
  },
  {
    parent_public_id: 'emergency-services',
    assignable: true,
    id: 2574,
    public_id: 'homeless-shelter',
    name: 'Homeless Shelter',
    public_id_breadcrumbs: [
      'general-services',
      'emergency-services',
      'homeless-shelter'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2385,
    public_id: 'hookah-bar',
    name: 'Hookah Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'hookah-bar'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2035,
    public_id: 'horse-racetrack',
    name: 'Horse Racetrack',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'horse-racetrack'
    ]
  },
  {
    parent_public_id: 'medical-services',
    assignable: true,
    id: 2640,
    public_id: 'hospital',
    name: 'Hospital',
    public_id_breadcrumbs: ['general-services', 'medical-services', 'hospital']
  },
  {
    parent_public_id: 'hotels-hostels',
    assignable: true,
    id: 1884,
    public_id: 'hostel',
    name: 'Hostel',
    public_id_breadcrumbs: ['places-to-stay', 'hotels-hostels', 'hostel']
  },
  {
    parent_public_id: 'aerial-tour',
    assignable: true,
    id: 2138,
    public_id: 'hot-air-balloon-ride',
    name: 'Hot Air Balloon Ride',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'aerial-tour',
      'hot-air-balloon-ride'
    ]
  },
  {
    parent_public_id: 'fast-food-joint',
    assignable: true,
    id: 2505,
    public_id: 'hot-dog-joint',
    name: 'Hot Dog Joint',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'burgers-fast-food',
      'fast-food-joint',
      'hot-dog-joint'
    ]
  },
  {
    parent_public_id: 'hotels-hostels',
    assignable: true,
    id: 1887,
    public_id: 'hotel',
    name: 'Hotel',
    public_id_breadcrumbs: ['places-to-stay', 'hotels-hostels', 'hotel']
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2386,
    public_id: 'hotel-bar',
    name: 'Hotel Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'hotel-bar'
    ]
  },
  {
    parent_public_id: 'places-to-stay',
    id: 1883,
    public_id: 'hotels-hostels',
    name: 'Hotels & Hostels',
    public_id_breadcrumbs: ['places-to-stay', 'hotels-hostels']
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2302,
    public_id: 'hot-spring',
    name: 'Hot Spring',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'hot-spring'
    ]
  },
  {
    parent_public_id: 'european-restaurant',
    assignable: true,
    id: 2466,
    public_id: 'hungarian-restaurant',
    name: 'Hungarian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'european-restaurant',
      'hungarian-restaurant'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2387,
    public_id: 'ice-bar',
    name: 'Ice Bar',
    public_id_breadcrumbs: ['bars-restaurants', 'bars-drinks', 'bar', 'ice-bar']
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2421,
    public_id: 'ice-cream-shop',
    name: 'Ice Cream Shop',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'ice-cream-shop'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2728,
    public_id: 'ice-curling-rink',
    name: 'Ice Curling Rink',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'ice-curling-rink'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2719,
    public_id: 'ice-hockey-rink',
    name: 'Ice Hockey Rink',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'ice-hockey-rink'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1964,
    public_id: 'ice-skating-rink',
    name: 'Ice Skating Rink',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'ice-skating-rink'
    ]
  },
  {
    parent_public_id: 'movie-theater-cinema',
    assignable: true,
    id: 1974,
    public_id: 'imax-cinema',
    name: 'IMAX Cinema',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'movie-theater-cinema',
      'imax-cinema'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2471,
    public_id: 'indian-restaurant',
    name: 'Indian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'indian-restaurant'
    ]
  },
  {
    parent_public_id: 'movie-theater-cinema',
    assignable: true,
    id: 1973,
    public_id: 'indie-cinema',
    name: 'Indie Cinema',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'movie-theater-cinema',
      'indie-cinema'
    ]
  },
  {
    parent_public_id: 'cultural-interest-sites',
    assignable: true,
    id: 2169,
    public_id: 'indigenous-culture-site',
    name: 'Indigenous Culture Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'indigenous-culture-site'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2440,
    public_id: 'indonesian-restaurant',
    name: 'Indonesian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'indonesian-restaurant'
    ]
  },
  {
    parent_public_id: 'fitness-facility',
    assignable: true,
    id: 2586,
    public_id: 'indoor-climbing-gym',
    name: 'Indoor Climbing Gym',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'fitness-facility',
      'indoor-climbing-gym'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2775,
    public_id: 'industrial-area',
    name: 'Industrial Area',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'industrial-area'
    ]
  },
  {
    parent_public_id: 'hotel',
    assignable: true,
    id: 1890,
    public_id: 'inn',
    name: 'Inn',
    public_id_breadcrumbs: ['places-to-stay', 'hotels-hostels', 'hotel', 'inn']
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2790,
    public_id: 'insurance-office',
    name: 'Insurance Office',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'insurance-office'
    ]
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2741,
    public_id: 'internet-cafe',
    name: 'Internet Cafe',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'internet-cafe'
    ]
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2353,
    public_id: 'interpretive-trail',
    name: 'Interpretive Trail',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'interpretive-trail'
    ]
  },
  {
    parent_public_id: 'pub',
    assignable: true,
    id: 2394,
    public_id: 'irish-pub',
    name: 'Irish Pub',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'pub',
      'irish-pub'
    ]
  },
  {
    parent_public_id: 'european-restaurant',
    assignable: true,
    id: 2467,
    public_id: 'irish-restaurant',
    name: 'Irish Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'european-restaurant',
      'irish-restaurant'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2325,
    public_id: 'island',
    name: 'Island',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'island'
    ]
  },
  {
    parent_public_id: 'european-restaurant',
    assignable: true,
    id: 2468,
    public_id: 'italian-restaurant',
    name: 'Italian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'european-restaurant',
      'italian-restaurant'
    ]
  },
  {
    parent_public_id: 'government-building',
    assignable: true,
    id: 2240,
    public_id: 'jail-prison',
    name: 'Jail/Prison',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'civic-spaces',
      'government-building',
      'jail-prison'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2446,
    public_id: 'japanese-restaurant',
    name: 'Japanese Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'japanese-restaurant'
    ]
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1990,
    public_id: 'jazz-club',
    name: 'Jazz Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'jazz-club'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2602,
    public_id: 'jetski-rental',
    name: 'Jetski Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'jetski-rental'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2057,
    public_id: 'jewelry-store',
    name: 'Jewelry Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'jewelry-store'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2516,
    public_id: 'juice-bar',
    name: 'Juice Bar',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'juice-bar']
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2388,
    public_id: 'karaoke-bar',
    name: 'Karaoke Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'karaoke-bar'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1960,
    public_id: 'karaoke-joint',
    name: 'Karaoke Joint',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'karaoke-joint'
    ]
  },
  {
    parent_public_id: 'martial-arts-studio',
    assignable: true,
    id: 2590,
    public_id: 'karate-dojo',
    name: 'Karate Dojo',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'martial-arts-studio',
      'karate-dojo'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2603,
    public_id: 'kayak-canoe-rental',
    name: 'Kayak/Canoe Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'kayak-canoe-rental'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2261,
    public_id: 'kayaking-place',
    name: 'Kayaking Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'kayaking-place'
    ]
  },
  {
    parent_public_id: 'clothing-store',
    assignable: true,
    id: 2109,
    public_id: 'kids-clothing-store',
    name: 'Kids Clothing Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'clothing-store',
      'kids-clothing-store'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2698,
    public_id: 'kingdom-hall',
    name: 'Kingdom Hall',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'kingdom-hall'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2101,
    public_id: 'kitchen-shop',
    name: 'Kitchen Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'kitchen-shop'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2262,
    public_id: 'kiteboarding-place',
    name: 'Kiteboarding Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'kiteboarding-place'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2517,
    public_id: 'kombucha-place',
    name: 'Kombucha Place',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'kombucha-place']
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2443,
    public_id: 'korean-restaurant',
    name: 'Korean Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'korean-restaurant'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2797,
    public_id: 'laboratory',
    name: 'Laboratory',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'laboratory'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2326,
    public_id: 'lagoon',
    name: 'Lagoon',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'lagoon'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2303,
    public_id: 'lake',
    name: 'Lake',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'lake'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2762,
    public_id: 'lan-center',
    name: 'LAN Center',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'lan-center'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2328,
    public_id: 'landform',
    name: 'Landform',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'landform'
    ]
  },
  {
    parent_public_id: 'landmarks',
    assignable: true,
    id: 2199,
    public_id: 'landmark',
    name: 'Landmark',
    public_id_breadcrumbs: ['sights-landmarks', 'landmarks', 'landmark']
  },
  {
    parent_public_id: 'sights-landmarks',
    id: 2198,
    public_id: 'landmarks',
    name: 'Landmarks',
    public_id_breadcrumbs: ['sights-landmarks', 'landmarks']
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2791,
    public_id: 'landscaper',
    name: 'Landscaper',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'landscaper'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2133,
    public_id: 'language-school',
    name: 'Language School',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'language-school'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1961,
    public_id: 'laser-tag-place',
    name: 'Laser Tag Place',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'laser-tag-place'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2456,
    public_id: 'latin-american-restaurant',
    name: 'Latin American Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'latin-american-restaurant'
    ]
  },
  {
    parent_public_id: 'laundry-services',
    assignable: true,
    id: 2626,
    public_id: 'laundromat',
    name: 'Laundromat',
    public_id_breadcrumbs: [
      'general-services',
      'laundry-services',
      'laundromat'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2625,
    public_id: 'laundry-services',
    name: 'Laundry Services',
    public_id_breadcrumbs: ['general-services', 'laundry-services']
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2792,
    public_id: 'law-office',
    name: 'Law Office',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'law-office'
    ]
  },
  {
    parent_public_id: 'middle-eastern-restaurant',
    assignable: true,
    id: 2479,
    public_id: 'lebanese-restaurant',
    name: 'Lebanese Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'middle-eastern-restaurant',
      'lebanese-restaurant'
    ]
  },
  {
    parent_public_id: 'essential-services',
    assignable: true,
    id: 2563,
    public_id: 'library',
    name: 'Library',
    public_id_breadcrumbs: ['general-services', 'essential-services', 'library']
  },
  {
    parent_public_id: 'emergency-services',
    assignable: true,
    id: 2573,
    public_id: 'life-guard-station',
    name: 'Life Guard Station',
    public_id_breadcrumbs: [
      'general-services',
      'emergency-services',
      'life-guard-station'
    ]
  },
  {
    parent_public_id: 'outdoors-features',
    assignable: true,
    id: 2366,
    public_id: 'life-guard-tower',
    name: 'Life Guard Tower',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoors-features',
      'life-guard-tower'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2200,
    public_id: 'lighthouse',
    name: 'Lighthouse',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'lighthouse'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2671,
    public_id: 'light-rail-station',
    name: 'Light Rail Station',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'light-rail-station'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2621,
    public_id: 'liquor-store',
    name: 'Liquor Store',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'liquor-store'
    ]
  },
  {
    parent_public_id: 'cultural-interest-sites',
    assignable: true,
    id: 2170,
    public_id: 'literary-site',
    name: 'Literary Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'literary-site'
    ]
  },
  {
    parent_public_id: 'theater',
    assignable: true,
    id: 2008,
    public_id: 'local-theater',
    name: 'Local Theater',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'theater',
      'local-theater'
    ]
  },
  {
    parent_public_id: 'essential-services',
    assignable: true,
    id: 2565,
    public_id: 'locksmith',
    name: 'Locksmith',
    public_id_breadcrumbs: [
      'general-services',
      'essential-services',
      'locksmith'
    ]
  },
  {
    parent_public_id: 'hotel',
    assignable: true,
    id: 1891,
    public_id: 'lodge',
    name: 'Lodge',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'hotel',
      'lodge'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2389,
    public_id: 'lounge-bar',
    name: 'Lounge Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'lounge-bar'
    ]
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 1999,
    public_id: 'luau',
    name: 'Luau',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'luau'
    ]
  },
  {
    parent_public_id: 'hotel',
    assignable: true,
    id: 1892,
    public_id: 'luxury-hotel',
    name: 'Luxury Hotel',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'hotel',
      'luxury-hotel'
    ]
  },
  {
    parent_public_id: 'specialty-museum',
    assignable: true,
    id: 2020,
    public_id: 'macabre-museum',
    name: 'Macabre Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'specialty-museum',
      'macabre-museum'
    ]
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2422,
    public_id: 'macaron-shop',
    name: 'Macaron Shop',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'macaron-shop'
    ]
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 2003,
    public_id: 'magic-show',
    name: 'Magic Show',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'magic-show'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2441,
    public_id: 'malaysian-restaurant',
    name: 'Malaysian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'malaysian-restaurant'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2105,
    public_id: 'mall',
    name: 'Mall',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'mall']
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2669,
    public_id: 'marina',
    name: 'Marina',
    public_id_breadcrumbs: ['general-services', 'transportation', 'marina']
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2022,
    public_id: 'maritime-museum',
    name: 'Maritime Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'maritime-museum'
    ]
  },
  {
    parent_public_id: 'fitness-facilities',
    assignable: true,
    id: 2587,
    public_id: 'martial-arts-studio',
    name: 'Martial Arts Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'martial-arts-studio'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2647,
    public_id: 'massage-studio',
    name: 'Massage Studio',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'massage-studio'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2750,
    public_id: 'maternity-clinic',
    name: 'Maternity Clinic',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'maternity-clinic'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2098,
    public_id: 'mattress-store',
    name: 'Mattress Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'mattress-store'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1967,
    public_id: 'maze',
    name: 'Maze',
    public_id_breadcrumbs: ['attractions-activities', 'fun-games', 'maze']
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2747,
    public_id: 'medical-facility',
    name: 'Medical Facility',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2751,
    public_id: 'medical-lab',
    name: 'Medical Lab',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'medical-lab'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2638,
    public_id: 'medical-services',
    name: 'Medical Services',
    public_id_breadcrumbs: ['general-services', 'medical-services']
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2752,
    public_id: 'medical-spa',
    name: 'Medical Spa',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'medical-spa'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2699,
    public_id: 'meditation-center',
    name: 'Meditation Center',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'meditation-center'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2474,
    public_id: 'mediterranean-restaurant',
    name: 'Mediterranean Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'mediterranean-restaurant'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2187,
    public_id: 'memorial',
    name: 'Memorial',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'memorial'
    ]
  },
  {
    parent_public_id: 'clothing-store',
    assignable: true,
    id: 2110,
    public_id: 'mens-clothing-store',
    name: 'Mens Clothing Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'clothing-store',
      'mens-clothing-store'
    ]
  },
  {
    parent_public_id: 'latin-american-restaurant',
    assignable: true,
    id: 2460,
    public_id: 'mexican-restaurant',
    name: 'Mexican Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'latin-american-restaurant',
      'mexican-restaurant'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2476,
    public_id: 'middle-eastern-restaurant',
    name: 'Middle Eastern Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'middle-eastern-restaurant'
    ]
  },
  {
    parent_public_id: 'military-site',
    assignable: true,
    id: 2191,
    public_id: 'military-base',
    name: 'Military Base',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'military-site',
      'military-base'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2024,
    public_id: 'military-museum',
    name: 'Military Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'military-museum'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2188,
    public_id: 'military-site',
    name: 'Military Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'military-site'
    ]
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2423,
    public_id: 'milkshake-bar',
    name: 'Milkshake Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'milkshake-bar'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2193,
    public_id: 'mine',
    name: 'Mine',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'mine'
    ]
  },
  {
    parent_public_id: 'residential-living',
    assignable: true,
    id: 2243,
    public_id: 'mobile-home-park',
    name: 'Mobile Home Park',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'residential-living',
      'mobile-home-park'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2114,
    public_id: 'mobile-phone-shop',
    name: 'Mobile Phone Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'mobile-phone-shop'
    ]
  },
  {
    parent_public_id: 'rv-services',
    assignable: true,
    id: 2525,
    public_id: 'mobile-rv-repair-service',
    name: 'Mobile RV Repair Service',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'rv-services',
      'mobile-rv-repair-service'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2483,
    public_id: 'modern-american-restaurant',
    name: 'Modern American Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'modern-american-restaurant'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2484,
    public_id: 'modern-european-restaurant',
    name: 'Modern European Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'modern-european-restaurant'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2700,
    public_id: 'monastery',
    name: 'Monastery',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'monastery'
    ]
  },
  {
    parent_public_id: 'essential-services',
    assignable: true,
    id: 2567,
    public_id: 'money-transfer',
    name: 'Money Transfer Office',
    public_id_breadcrumbs: [
      'general-services',
      'essential-services',
      'money-transfer'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2186,
    public_id: 'monument',
    name: 'Monument',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'monument'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2605,
    public_id: 'moped-rental',
    name: 'Moped Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'moped-rental'
    ]
  },
  {
    parent_public_id: 'middle-eastern-restaurant',
    assignable: true,
    id: 2480,
    public_id: 'moroccan-restaurant',
    name: 'Moroccan Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'middle-eastern-restaurant',
      'moroccan-restaurant'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2701,
    public_id: 'mosque',
    name: 'Mosque',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'mosque'
    ]
  },
  {
    parent_public_id: 'hotel',
    assignable: true,
    id: 1893,
    public_id: 'motel',
    name: 'Motel',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'hotel',
      'motel'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2604,
    public_id: 'motorbike-rental',
    name: 'Motorbike Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'motorbike-rental'
    ]
  },
  {
    parent_public_id: 'auto-rv-services',
    id: 2541,
    public_id: 'motorbike-services',
    name: 'Motorbike Services',
    public_id_breadcrumbs: ['auto-rv-services', 'motorbike-services']
  },
  {
    parent_public_id: 'motorbike-services',
    assignable: true,
    id: 2544,
    public_id: 'motorcycle-dealer',
    name: 'Motorcycle Dealer',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'motorbike-services',
      'motorcycle-dealer'
    ]
  },
  {
    parent_public_id: 'motorbike-services',
    assignable: true,
    id: 2543,
    public_id: 'motorcycle-repair-service',
    name: 'Motorcycle Repair Service',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'motorbike-services',
      'motorcycle-repair-service'
    ]
  },
  {
    parent_public_id: 'motorbike-services',
    assignable: true,
    id: 2542,
    public_id: 'motorcycle-shop',
    name: 'Motorcycle Shop',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'motorbike-services',
      'motorcycle-shop'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2313,
    public_id: 'mountain',
    name: 'Mountain',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'mountain'
    ]
  },
  {
    parent_public_id: 'cycle-trailhead',
    assignable: true,
    id: 2349,
    public_id: 'mountain-biking-trailhead',
    name: 'Mountain Biking Trailhead',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'cycle-trailhead',
      'mountain-biking-trailhead'
    ]
  },
  {
    parent_public_id: 'unique-stay',
    assignable: true,
    id: 1909,
    public_id: 'mountain-hut',
    name: 'Mountain Hut',
    public_id_breadcrumbs: [
      'places-to-stay',
      'unique-places-to-stay',
      'unique-stay',
      'mountain-hut'
    ]
  },
  {
    parent_public_id: 'route',
    assignable: true,
    id: 2228,
    public_id: 'mountain-pass',
    name: 'Mountain Pass',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'scenic-points',
      'route',
      'mountain-pass'
    ]
  },
  {
    parent_public_id: 'cultural-interest-sites',
    assignable: true,
    id: 2172,
    public_id: 'movie-filming-location',
    name: 'Movie Filming Location',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'movie-filming-location'
    ]
  },
  {
    parent_public_id: 'music-entertainment',
    assignable: true,
    id: 1971,
    public_id: 'movie-theater-cinema',
    name: 'Movie Theater/Cinema',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'movie-theater-cinema'
    ]
  },
  {
    parent_public_id: 'movie-theater-cinema',
    assignable: true,
    id: 1975,
    public_id: 'multiplex',
    name: 'Multiplex',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'movie-theater-cinema',
      'multiplex'
    ]
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2354,
    public_id: 'multi-purpose-trailhead',
    name: 'Multi-Purpose Trailhead',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'multi-purpose-trailhead'
    ]
  },
  {
    parent_public_id: 'museums',
    assignable: true,
    id: 2012,
    public_id: 'museum',
    name: 'Museum',
    public_id_breadcrumbs: ['attractions-activities', 'museums', 'museum']
  },
  {
    parent_public_id: 'attractions-activities',
    id: 2011,
    public_id: 'museums',
    name: 'Museums',
    public_id_breadcrumbs: ['attractions-activities', 'museums']
  },
  {
    parent_public_id: 'maritime-museum',
    assignable: true,
    id: 2023,
    public_id: 'museum-ship',
    name: 'Museum Ship',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'maritime-museum',
      'museum-ship'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2087,
    public_id: 'musical-instrument-store',
    name: 'Musical Instrument Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'musical-instrument-store'
    ]
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 2004,
    public_id: 'musical-show',
    name: 'Musical Show',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'musical-show'
    ]
  },
  {
    parent_public_id: 'attractions-activities',
    id: 1970,
    public_id: 'music-entertainment',
    name: 'Music & Entertainment',
    public_id_breadcrumbs: ['attractions-activities', 'music-entertainment']
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2086,
    public_id: 'music-shop',
    name: 'Music Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'music-shop'
    ]
  },
  {
    parent_public_id: 'music-entertainment',
    assignable: true,
    id: 1985,
    public_id: 'music-venue',
    name: 'Music Venue',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2653,
    public_id: 'nail-salon',
    name: 'Nail Salon',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'nail-salon'
    ]
  },
  {
    parent_public_id: 'nature-reserve',
    assignable: true,
    id: 2342,
    public_id: 'national-forest',
    name: 'National Forest',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'nature-reserves',
      'nature-reserve',
      'national-forest'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2196,
    public_id: 'national-historic-site',
    name: 'National Historic Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'national-historic-site'
    ]
  },
  {
    parent_public_id: 'national-park-service-site',
    assignable: true,
    id: 2337,
    public_id: 'national-monument',
    name: 'National Monument',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'national-parks',
      'national-park-service-site',
      'national-monument'
    ]
  },
  {
    parent_public_id: 'national-park-service-site',
    assignable: true,
    id: 2336,
    public_id: 'national-park',
    name: 'National Park',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'national-parks',
      'national-park-service-site',
      'national-park'
    ]
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    id: 2334,
    public_id: 'national-parks',
    name: 'National Parks',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'national-parks']
  },
  {
    parent_public_id: 'national-parks',
    assignable: true,
    id: 2335,
    public_id: 'national-park-service-site',
    name: 'National Park Service Site',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'national-parks',
      'national-park-service-site'
    ]
  },
  {
    parent_public_id: 'nature-reserve',
    assignable: true,
    id: 2345,
    public_id: 'national-reserve',
    name: 'National Reserve',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'nature-reserves',
      'nature-reserve',
      'national-reserve'
    ]
  },
  {
    parent_public_id: 'nature-reserve',
    assignable: true,
    id: 2341,
    public_id: 'national-wildlife-refuge',
    name: 'National Wildlife Refuge',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'nature-reserves',
      'nature-reserve',
      'national-wildlife-refuge'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2025,
    public_id: 'natural-history-museum',
    name: 'Natural History Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'natural-history-museum'
    ]
  },
  {
    parent_public_id: 'parks-gardens',
    assignable: true,
    id: 2296,
    public_id: 'nature-center',
    name: 'Nature Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'nature-center'
    ]
  },
  {
    parent_public_id: 'nature-reserves',
    assignable: true,
    id: 2339,
    public_id: 'nature-reserve',
    name: 'Nature Reserve/Preserve',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'nature-reserves',
      'nature-reserve'
    ]
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    id: 2338,
    public_id: 'nature-reserves',
    name: 'Nature Reserves',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'nature-reserves']
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2355,
    public_id: 'nature-trail',
    name: 'Nature Trail',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'nature-trail'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2766,
    public_id: 'newspaper',
    name: 'Newspaper',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'newspaper'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2103,
    public_id: 'news-stand',
    name: 'News Stand',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'news-stand']
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2486,
    public_id: 'new-zealand-restaurant',
    name: 'New Zealand Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'new-zealand-restaurant'
    ]
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1994,
    public_id: 'nightclub',
    name: 'Nightclub',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'nightclub'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2442,
    public_id: 'noodle-house',
    name: 'Noodle House',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'noodle-house'
    ]
  },
  {
    parent_public_id: 'indian-restaurant',
    assignable: true,
    id: 2472,
    public_id: 'north-indian-restaurant',
    name: 'North Indian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'indian-restaurant',
      'north-indian-restaurant'
    ]
  },
  {
    parent_public_id: 'beach',
    assignable: true,
    id: 2279,
    public_id: 'nudist-beach',
    name: 'Nudist Beach',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'beaches-swim-spots',
      'beach',
      'nudist-beach'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2753,
    public_id: 'obgyn',
    name: 'OBGYN',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'obgyn'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1941,
    public_id: 'observatory',
    name: 'Observatory',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'observatory'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1934,
    public_id: 'offbeat-attraction',
    name: 'Offbeat Attraction',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'offbeat-attraction'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2770,
    public_id: 'office-building',
    name: 'Office Building',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'office-building'
    ]
  },
  {
    parent_public_id: 'recreation-area',
    assignable: true,
    id: 2294,
    public_id: 'off-road-park',
    name: 'Off-Road Park',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'recreation-area',
      'off-road-park'
    ]
  },
  {
    parent_public_id: 'route',
    assignable: true,
    id: 2226,
    public_id: 'off-road-trail',
    name: 'Off-Road Trail',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'scenic-points',
      'route',
      'off-road-trail'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2535,
    public_id: 'oil-change-service',
    name: 'Oil Change Service',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'auto-services',
      'oil-change-service'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2076,
    public_id: 'olive-oil-shop',
    name: 'Olive Oil Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'olive-oil-shop'
    ]
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 2000,
    public_id: 'opera',
    name: 'Opera',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'opera'
    ]
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1993,
    public_id: 'opera-house',
    name: 'Opera House',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'opera-house'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2754,
    public_id: 'optometrist',
    name: 'Optometrist',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'optometrist'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2622,
    public_id: 'organic-grocery',
    name: 'Organic Grocery',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'organic-grocery'
    ]
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    id: 2246,
    public_id: 'outdoor-activities',
    name: 'Outdoor Activities',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'outdoor-activities']
  },
  {
    parent_public_id: 'outdoor-activities',
    assignable: true,
    id: 2247,
    public_id: 'outdoor-activity',
    name: 'Outdoor Activity',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity'
    ]
  },
  {
    parent_public_id: 'movie-theater-cinema',
    assignable: true,
    id: 1976,
    public_id: 'outdoor-cinema',
    name: 'Outdoor Cinema',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'movie-theater-cinema',
      'outdoor-cinema'
    ]
  },
  {
    parent_public_id: 'event-space',
    assignable: true,
    id: 1984,
    public_id: 'outdoor-event-space',
    name: 'Outdoor Event Space',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'event-space',
      'outdoor-event-space'
    ]
  },
  {
    parent_public_id: 'public-art',
    assignable: true,
    id: 2219,
    public_id: 'outdoor-sculpture',
    name: 'Outdoor Sculpture',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'public-art-displays',
      'public-art',
      'outdoor-sculpture'
    ]
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    assignable: true,
    id: 2363,
    public_id: 'outdoors-features',
    name: 'Outdoors Features',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'outdoors-features']
  },
  {
    id: 2245,
    public_id: 'outdoors-recreation-places',
    name: 'Outdoors & Recreation Places',
    public_id_breadcrumbs: ['outdoors-recreation-places']
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2059,
    public_id: 'outfitters',
    name: 'Outfitters',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'outfitters']
  },
  {
    parent_public_id: 'mall',
    assignable: true,
    id: 2106,
    public_id: 'outlet-mall',
    name: 'Outlet Mall',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'mall',
      'outlet-mall'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2063,
    public_id: 'outlet-store',
    name: 'Outlet Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'outlet-store'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2391,
    public_id: 'oxygen-bar',
    name: 'Oxygen Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'oxygen-bar'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2390,
    public_id: 'oyster-bar',
    name: 'Oyster Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'oyster-bar'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2263,
    public_id: 'paddleboarding-place',
    name: 'Paddleboarding Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'paddleboarding-place'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2126,
    public_id: 'paddleboard-lesson',
    name: 'Paddleboard Lesson',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'paddleboard-lesson'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2606,
    public_id: 'paddleboard-rental',
    name: 'Paddleboard Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'paddleboard-rental'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1957,
    public_id: 'paintball-center',
    name: 'Paintball Center',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'paintball-center'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2265,
    public_id: 'paragliding-place',
    name: 'Paragliding Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'paragliding-place'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2264,
    public_id: 'parasailing-place',
    name: 'Parasailing Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'parasailing-place'
    ]
  },
  {
    parent_public_id: 'recreation-area',
    assignable: true,
    id: 2291,
    public_id: 'park',
    name: 'Park',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'recreation-area',
      'park'
    ]
  },
  {
    parent_public_id: 'parking-rest-areas',
    assignable: true,
    id: 2553,
    public_id: 'parking-lot',
    name: 'Parking Lot',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'parking-rest-areas',
      'parking-lot'
    ]
  },
  {
    parent_public_id: 'auto-rv-services',
    id: 2552,
    public_id: 'parking-rest-areas',
    name: 'Parking & Rest Areas',
    public_id_breadcrumbs: ['auto-rv-services', 'parking-rest-areas']
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    id: 2284,
    public_id: 'parks-gardens',
    name: 'Parks & Gardens',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'parks-gardens']
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2212,
    public_id: 'pavilion',
    name: 'Pavilion',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'pavilion'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2088,
    public_id: 'pawn-shop',
    name: 'Pawn Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'pawn-shop'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2755,
    public_id: 'pediatrician',
    name: 'Pediatrician',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'pediatrician'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2069,
    public_id: 'perfumery',
    name: 'Perfumery',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'perfumery'
    ]
  },
  {
    parent_public_id: 'middle-eastern-restaurant',
    assignable: true,
    id: 2481,
    public_id: 'persian-restaurant',
    name: 'Persian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'middle-eastern-restaurant',
      'persian-restaurant'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2645,
    public_id: 'personal-care-service',
    name: 'Personal Care Service',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'personal-care-service'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2644,
    public_id: 'personal-care-services',
    name: 'Personal Care Services',
    public_id_breadcrumbs: ['general-services', 'personal-care-services']
  },
  {
    parent_public_id: 'pet-services',
    assignable: true,
    id: 2655,
    public_id: 'pet-boarding',
    name: 'Pet Boarding',
    public_id_breadcrumbs: ['general-services', 'pet-services', 'pet-boarding']
  },
  {
    parent_public_id: 'pet-services',
    assignable: true,
    id: 2656,
    public_id: 'pet-grooming',
    name: 'Pet Grooming',
    public_id_breadcrumbs: ['general-services', 'pet-services', 'pet-grooming']
  },
  {
    parent_public_id: 'general-services',
    id: 2654,
    public_id: 'pet-services',
    name: 'Pet Services',
    public_id_breadcrumbs: ['general-services', 'pet-services']
  },
  {
    parent_public_id: 'pet-services',
    assignable: true,
    id: 2657,
    public_id: 'pet-store',
    name: 'Pet Store',
    public_id_breadcrumbs: ['general-services', 'pet-services', 'pet-store']
  },
  {
    parent_public_id: 'animal-attraction',
    assignable: true,
    id: 1921,
    public_id: 'petting-zoo',
    name: 'Petting Zoo',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction',
      'petting-zoo'
    ]
  },
  {
    parent_public_id: 'medical-services',
    assignable: true,
    id: 2641,
    public_id: 'pharmacy',
    name: 'Pharmacy',
    public_id_breadcrumbs: ['general-services', 'medical-services', 'pharmacy']
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2739,
    public_id: 'phone-booth',
    name: 'Phone Booth',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'phone-booth'
    ]
  },
  {
    parent_public_id: 'photo-ops',
    assignable: true,
    id: 2215,
    public_id: 'photo-opportunity',
    name: 'Photo Opportunity',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'photo-ops',
      'photo-opportunity'
    ]
  },
  {
    parent_public_id: 'sights-landmarks',
    id: 2214,
    public_id: 'photo-ops',
    name: 'Photo Ops',
    public_id_breadcrumbs: ['sights-landmarks', 'photo-ops']
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1991,
    public_id: 'piano-bar',
    name: 'Piano Bar',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'piano-bar'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2729,
    public_id: 'pickleball-court',
    name: 'Pickleball Court',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'pickleball-court'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2070,
    public_id: 'pick-your-own-farm',
    name: 'Pick Your Own Farm',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'pick-your-own-farm'
    ]
  },
  {
    parent_public_id: 'parks-gardens',
    assignable: true,
    id: 2298,
    public_id: 'picnic-area',
    name: 'Picnic Area',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'picnic-area'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2203,
    public_id: 'pier',
    name: 'Pier',
    public_id_breadcrumbs: ['sights-landmarks', 'landmarks', 'landmark', 'pier']
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2424,
    public_id: 'pie-shop',
    name: 'Pie Shop',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'pie-shop'
    ]
  },
  {
    parent_public_id: 'martial-arts-studio',
    assignable: true,
    id: 2592,
    public_id: 'pilates-studio',
    name: 'Pilates Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'martial-arts-studio',
      'pilates-studio'
    ]
  },
  {
    parent_public_id: 'arcade',
    assignable: true,
    id: 1947,
    public_id: 'pinball-arcade',
    name: 'Pinball Arcade',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'arcade',
      'pinball-arcade'
    ]
  },
  {
    parent_public_id: 'ski-trail',
    assignable: true,
    id: 2361,
    public_id: 'piste',
    name: 'Piste',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'ski-trail',
      'piste'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2487,
    public_id: 'pizza-place',
    name: 'Pizza Place',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'pizza-place'
    ]
  },
  {
    id: 1868,
    public_id: 'places-to-stay',
    name: 'Places to Stay',
    public_id_breadcrumbs: ['places-to-stay']
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2327,
    public_id: 'plain',
    name: 'Plain',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'plain'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2026,
    public_id: 'planetarium',
    name: 'Planetarium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'planetarium'
    ]
  },
  {
    parent_public_id: 'aerial-tour',
    assignable: true,
    id: 2139,
    public_id: 'plane-tour',
    name: 'Plane Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'aerial-tour',
      'plane-tour'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2756,
    public_id: 'plastic-surgery-clinic',
    name: 'Plastic Surgery Clinic',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'plastic-surgery-clinic'
    ]
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 2001,
    public_id: 'play',
    name: 'Play',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'play'
    ]
  },
  {
    parent_public_id: 'recreation-area',
    assignable: true,
    id: 2292,
    public_id: 'playground',
    name: 'Playground',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'recreation-area',
      'playground'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2793,
    public_id: 'plumber',
    name: 'Plumber',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'plumber'
    ]
  },
  {
    parent_public_id: 'emergency-services',
    assignable: true,
    id: 2572,
    public_id: 'police-station',
    name: 'Police Station',
    public_id_breadcrumbs: [
      'general-services',
      'emergency-services',
      'police-station'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2488,
    public_id: 'polish-restaurant',
    name: 'Polish Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'polish-restaurant'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1958,
    public_id: 'pool-hall',
    name: 'Pool Hall',
    public_id_breadcrumbs: ['attractions-activities', 'fun-games', 'pool-hall']
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2489,
    public_id: 'portuguese-restaurant',
    name: 'Portuguese Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'portuguese-restaurant'
    ]
  },
  {
    parent_public_id: 'essential-services',
    assignable: true,
    id: 2564,
    public_id: 'post-office',
    name: 'Post Office',
    public_id_breadcrumbs: [
      'general-services',
      'essential-services',
      'post-office'
    ]
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2738,
    public_id: 'post-office-box',
    name: 'Post Office Box',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'post-office-box'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2776,
    public_id: 'power-station',
    name: 'Power Station',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'power-station'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2307,
    public_id: 'prairie',
    name: 'Prairie',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'prairie'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2702,
    public_id: 'prayer-room',
    name: 'Prayer Room',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'prayer-room'
    ]
  },
  {
    parent_public_id: 'tent-campground',
    assignable: true,
    id: 1877,
    public_id: 'primitive-campground',
    name: 'Primitive Campground',
    public_id_breadcrumbs: [
      'places-to-stay',
      'campgrounds',
      'campground',
      'tent-campground',
      'primitive-campground'
    ],
    description:
      'Tent campground with little or no facilities, e.g. may have fire pit but no water/toilet'
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2094,
    public_id: 'print-shop',
    name: 'Print Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'print-shop'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1940,
    public_id: 'private-members-club',
    name: 'Private Members Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'private-members-club'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2623,
    public_id: 'produce-market',
    name: 'Produce Market',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'produce-market'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1959,
    public_id: 'psychic-mystic-reading-place',
    name: 'Psychic/Mystic Reading Place',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'psychic-mystic-reading-place'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2392,
    public_id: 'pub',
    name: 'Pub',
    public_id_breadcrumbs: ['bars-restaurants', 'bars-drinks', 'bar', 'pub']
  },
  {
    parent_public_id: 'public-art-displays',
    assignable: true,
    id: 2217,
    public_id: 'public-art',
    name: 'Public Art',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'public-art-displays',
      'public-art'
    ]
  },
  {
    parent_public_id: 'sights-landmarks',
    id: 2216,
    public_id: 'public-art-displays',
    name: 'Public Art Displays',
    public_id_breadcrumbs: ['sights-landmarks', 'public-art-displays']
  },
  {
    parent_public_id: 'parks-gardens',
    assignable: true,
    id: 2285,
    public_id: 'public-garden',
    name: 'Public Garden',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'public-garden'
    ]
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2736,
    public_id: 'public-restroom',
    name: 'Public Restroom',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'public-restroom'
    ]
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2737,
    public_id: 'public-shower',
    name: 'Public Shower',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'public-shower'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1931,
    public_id: 'pumpkin-patch',
    name: 'Pumpkin Patch',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'pumpkin-patch'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1968,
    public_id: 'putt-putt-mini-golf-course',
    name: 'Putt Putt/Mini Golf Course',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'putt-putt-mini-golf-course'
    ]
  },
  {
    parent_public_id: 'bars-restaurants',
    id: 2506,
    public_id: 'quick-bites',
    name: 'Quick Bites',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites']
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2064,
    public_id: 'quirky-shop',
    name: 'Quirky Shop',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'quirky-shop']
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2267,
    public_id: 'racing-experience',
    name: 'Racing Experience',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'racing-experience'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2765,
    public_id: 'radio-station',
    name: 'Radio Station',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'radio-station'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2266,
    public_id: 'rafting-place',
    name: 'Rafting Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'rafting-place'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1942,
    public_id: 'ranch',
    name: 'Ranch',
    public_id_breadcrumbs: ['attractions-activities', 'attractions', 'ranch']
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2635,
    public_id: 'ranger-station',
    name: 'Ranger Station',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'ranger-station'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2794,
    public_id: 'real-estate-agent',
    name: 'Real Estate Agent',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'real-estate-agent'
    ]
  },
  {
    parent_public_id: 'parks-gardens',
    assignable: true,
    id: 2290,
    public_id: 'recreation-area',
    name: 'Recreation Area',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'recreation-area'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2720,
    public_id: 'recreation-center',
    name: 'Recreation Center',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'recreation-center'
    ]
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2740,
    public_id: 'recycling-stop',
    name: 'Recycling Stop',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'recycling-stop'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2329,
    public_id: 'reef',
    name: 'Reef',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'reef'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2757,
    public_id: 'rehab-center',
    name: 'Rehab Center',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'rehab-center'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2706,
    public_id: 'religious-organization',
    name: 'Religious Organization',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'religious-organization'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2102,
    public_id: 'repair-shop',
    name: 'Repair Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'repair-shop'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2769,
    public_id: 'research-station',
    name: 'Research Station',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'research-station'
    ]
  },
  {
    parent_public_id: 'residential-living',
    assignable: true,
    id: 2244,
    public_id: 'residential-building',
    name: 'Residential Building',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'residential-living',
      'residential-building'
    ]
  },
  {
    parent_public_id: 'sights-landmarks',
    id: 2242,
    public_id: 'residential-living',
    name: 'Residential Living',
    public_id_breadcrumbs: ['sights-landmarks', 'residential-living']
  },
  {
    parent_public_id: 'hotels-hostels',
    assignable: true,
    id: 1895,
    public_id: 'resort',
    name: 'Resort',
    public_id_breadcrumbs: ['places-to-stay', 'hotels-hostels', 'resort']
  },
  {
    parent_public_id: 'parking-rest-areas',
    assignable: true,
    id: 2554,
    public_id: 'rest-area',
    name: 'Rest Area',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'parking-rest-areas',
      'rest-area'
    ]
  },
  {
    parent_public_id: 'restaurants',
    assignable: true,
    id: 2427,
    public_id: 'restaurant',
    name: 'Restaurant',
    public_id_breadcrumbs: ['bars-restaurants', 'restaurants', 'restaurant']
  },
  {
    parent_public_id: 'bars-restaurants',
    id: 2426,
    public_id: 'restaurants',
    name: 'Restaurants',
    public_id_breadcrumbs: ['bars-restaurants', 'restaurants']
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2675,
    public_id: 'rideshare-point',
    name: 'Rideshare Meeting Point',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'rideshare-point'
    ]
  },
  {
    parent_public_id: 'equestrian-facilities',
    assignable: true,
    id: 2368,
    public_id: 'riding-stable',
    name: 'Riding Stable',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'equestrian-facilities',
      'riding-stable'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2311,
    public_id: 'river',
    name: 'River',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'river'
    ]
  },
  {
    parent_public_id: 'cycle-trailhead',
    assignable: true,
    id: 2350,
    public_id: 'road-biking-trailhead',
    name: 'Road Biking Trailhead',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'cycle-trailhead',
      'road-biking-trailhead'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1935,
    public_id: 'roadside-attraction',
    name: 'Roadside Attraction',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'roadside-attraction'
    ]
  },
  {
    parent_public_id: 'music-venue',
    assignable: true,
    id: 1992,
    public_id: 'rock-club',
    name: 'Rock Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'music-venue',
      'rock-club'
    ]
  },
  {
    parent_public_id: 'show',
    assignable: true,
    id: 2002,
    public_id: 'rodeo',
    name: 'Rodeo',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show',
      'rodeo'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1965,
    public_id: 'roller-skating-rink',
    name: 'Roller Skating Rink',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'roller-skating-rink'
    ]
  },
  {
    parent_public_id: 'scenic-points',
    assignable: true,
    id: 2224,
    public_id: 'route',
    name: 'Route',
    public_id_breadcrumbs: ['sights-landmarks', 'scenic-points', 'route']
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2721,
    public_id: 'rugby-pitch',
    name: 'Rugby Pitch',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'rugby-pitch'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2037,
    public_id: 'rugby-stadium',
    name: 'Rugby Stadium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'rugby-stadium'
    ]
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2356,
    public_id: 'running-trailhead',
    name: 'Running Trailhead',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'running-trailhead'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2490,
    public_id: 'russian-restaurant',
    name: 'Russian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'russian-restaurant'
    ]
  },
  {
    parent_public_id: 'glamping-place',
    assignable: true,
    id: 1903,
    public_id: 'rv-glamping',
    name: 'RV Glamping Place',
    public_id_breadcrumbs: [
      'places-to-stay',
      'unique-places-to-stay',
      'glamping-place',
      'rv-glamping'
    ]
  },
  {
    parent_public_id: 'campground',
    assignable: true,
    id: 1878,
    public_id: 'rv-park',
    name: 'RV Park',
    public_id_breadcrumbs: [
      'places-to-stay',
      'campgrounds',
      'campground',
      'rv-park'
    ],
    description:
      'An RV Park can accomodate most RVs and typically offers hookups and some amenities such as WIFI or pool'
  },
  {
    parent_public_id: 'vehicle-rental-place',
    assignable: true,
    id: 2559,
    public_id: 'rv-rental-place',
    name: 'RV Rental Place',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'car-rv-van-rental',
      'vehicle-rental-place',
      'rv-rental-place'
    ]
  },
  {
    parent_public_id: 'campground',
    assignable: true,
    id: 1879,
    public_id: 'rv-resort',
    name: 'RV Resort',
    public_id_breadcrumbs: [
      'places-to-stay',
      'campgrounds',
      'campground',
      'rv-resort'
    ],
    description:
      'An RV Resort can accomodate the largest class of RVs, often has seasonal or full-time residents, and a larger selection of amenities, such as a clubhouse.'
  },
  {
    parent_public_id: 'rv-services',
    assignable: true,
    id: 2526,
    public_id: 'rv-sales-center',
    name: 'RV Sales Center',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'rv-services',
      'rv-sales-center'
    ]
  },
  {
    parent_public_id: 'rv-services',
    assignable: true,
    id: 2524,
    public_id: 'rv-service-center',
    name: 'RV Service Center',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'rv-services',
      'rv-service-center'
    ]
  },
  {
    parent_public_id: 'auto-rv-services',
    id: 2522,
    public_id: 'rv-services',
    name: 'RV Services',
    public_id_breadcrumbs: ['auto-rv-services', 'rv-services']
  },
  {
    parent_public_id: 'rv-services',
    assignable: true,
    id: 2527,
    public_id: 'rv-storage-facility',
    name: 'RV Storage Facility',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'rv-services',
      'rv-storage-facility'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2270,
    public_id: 'sailing-center',
    name: 'Sailing Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'sailing-center'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2127,
    public_id: 'sailing-school',
    name: 'Sailing School',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'sailing-school'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2395,
    public_id: 'sake-bar',
    name: 'Sake Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'sake-bar'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2491,
    public_id: 'salad-place',
    name: 'Salad Place',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'salad-place'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2536,
    public_id: 'salvage-yard',
    name: 'Salvage Yard',
    public_id_breadcrumbs: ['auto-rv-services', 'auto-services', 'salvage-yard']
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2312,
    public_id: 'sand-dunes',
    name: 'Sand Dunes',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'sand-dunes'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2518,
    public_id: 'sandwich-shop',
    name: 'Sandwich Shop',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'sandwich-shop']
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1962,
    public_id: 'scavenger-hunt',
    name: 'Scavenger Hunt',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'scavenger-hunt'
    ]
  },
  {
    parent_public_id: 'scenic-points',
    assignable: true,
    id: 2223,
    public_id: 'scenic-overlook',
    name: 'Scenic Overlook',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'scenic-points',
      'scenic-overlook'
    ]
  },
  {
    parent_public_id: 'sights-landmarks',
    id: 2222,
    public_id: 'scenic-points',
    name: 'Scenic Points',
    public_id_breadcrumbs: ['sights-landmarks', 'scenic-points']
  },
  {
    parent_public_id: 'route',
    assignable: true,
    id: 2227,
    public_id: 'scenic-route',
    name: 'Scenic Route',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'scenic-points',
      'route',
      'scenic-route'
    ]
  },
  {
    parent_public_id: 'educational-institution',
    assignable: true,
    id: 2236,
    public_id: 'school',
    name: 'School',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'civic-spaces',
      'educational-institution',
      'school'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2027,
    public_id: 'science-museum',
    name: 'Science Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'science-museum'
    ]
  },
  {
    parent_public_id: 'cultural-interest-sites',
    assignable: true,
    id: 2171,
    public_id: 'scientific-site',
    name: 'Scientific Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'scientific-site'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2607,
    public_id: 'scooter-rental',
    name: 'Scooter Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'scooter-rental'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2158,
    public_id: 'scooter-tour',
    name: 'Scooter Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'scooter-tour'
    ]
  },
  {
    parent_public_id: 'campground',
    assignable: true,
    id: 1880,
    public_id: 'scout-camp',
    name: 'Scout Camp',
    public_id_breadcrumbs: [
      'places-to-stay',
      'campgrounds',
      'campground',
      'scout-camp'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2271,
    public_id: 'scuba-center',
    name: 'Scuba Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'scuba-center'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2128,
    public_id: 'scuba-diving-class',
    name: 'Scuba Diving Class',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'scuba-diving-class'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2608,
    public_id: 'scuba-rental',
    name: 'Scuba Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'scuba-rental'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2611,
    public_id: 'scuba-snorkel-gear-rental',
    name: 'Scuba/Snorkel Gear Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'scuba-snorkel-gear-rental'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2492,
    public_id: 'seafood-restaurant',
    name: 'Seafood Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'seafood-restaurant'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2519,
    public_id: 'seafood-shack',
    name: 'Seafood Shack',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'seafood-shack']
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2159,
    public_id: 'segway-tour',
    name: 'Segway Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'segway-tour'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2129,
    public_id: 'self-defense-class',
    name: 'Self Defense Class',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'self-defense-class'
    ]
  },
  {
    parent_public_id: 'dessert-shop',
    assignable: true,
    id: 2425,
    public_id: 'shaved-ice-place',
    name: 'Shaved Ice Place',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'sweets-treats',
      'dessert-shop',
      'shaved-ice-place'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2211,
    public_id: 'shelter',
    name: 'Shelter',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'shelter'
    ]
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2210,
    public_id: 'ship',
    name: 'Ship',
    public_id_breadcrumbs: ['sights-landmarks', 'landmarks', 'landmark', 'ship']
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2116,
    public_id: 'shipping-store',
    name: 'Shipping Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'shipping-store'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2192,
    public_id: 'shipwreck',
    name: 'Shipwreck',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'shipwreck'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2272,
    public_id: 'shooting-range',
    name: 'Shooting Range',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'shooting-range'
    ]
  },
  {
    parent_public_id: 'attractions-activities',
    id: 2041,
    public_id: 'shopping',
    name: 'Shopping',
    public_id_breadcrumbs: ['attractions-activities', 'shopping']
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2104,
    public_id: 'shopping-area',
    name: 'Shopping Area',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'shopping-area'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2151,
    public_id: 'shopping-tour',
    name: 'Shopping Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'shopping-tour'
    ]
  },
  {
    parent_public_id: 'music-entertainment',
    assignable: true,
    id: 1995,
    public_id: 'show',
    name: 'Show',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'show'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2703,
    public_id: 'shrine',
    name: 'Shrine',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'shrine'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2152,
    public_id: 'sightseeing-tour',
    name: 'Sightseeing Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'sightseeing-tour'
    ]
  },
  {
    id: 2163,
    public_id: 'sights-landmarks',
    name: 'Sights & Landmarks',
    public_id_breadcrumbs: ['sights-landmarks']
  },
  {
    parent_public_id: 'recreation-area',
    assignable: true,
    id: 2295,
    public_id: 'skate-park',
    name: 'Skate Park',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'recreation-area',
      'skate-park'
    ]
  },
  {
    parent_public_id: 'sports-shop',
    assignable: true,
    id: 2066,
    public_id: 'skate-surf-shop',
    name: 'Skate/Surf Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'sports-shop',
      'skate-surf-shop'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1963,
    public_id: 'skating-rink',
    name: 'Skating Rink',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'skating-rink'
    ]
  },
  {
    parent_public_id: 'outdoors-features',
    assignable: true,
    id: 2364,
    public_id: 'ski-lift',
    name: 'Ski Lift',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoors-features',
      'ski-lift'
    ]
  },
  {
    parent_public_id: 'resort',
    assignable: true,
    id: 1898,
    public_id: 'ski-resort',
    name: 'Ski Resort',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'resort',
      'ski-resort'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1936,
    public_id: 'ski-snowboard-area',
    name: 'Ski/Snowboard Area',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'ski-snowboard-area'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2610,
    public_id: 'ski-snowboard-rental',
    name: 'Ski/Snowboard Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'ski-snowboard-rental'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2275,
    public_id: 'ski-snowboard-school',
    name: 'Ski/Snowboard School',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'ski-snowboard-school'
    ]
  },
  {
    parent_public_id: 'sports-shop',
    assignable: true,
    id: 2067,
    public_id: 'ski-snowboard-shop',
    name: 'Ski/Snowboard Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'sports-shop',
      'ski-snowboard-shop'
    ]
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2357,
    public_id: 'ski-trail',
    name: 'Ski Trail',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'ski-trail'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2268,
    public_id: 'skydiving-center',
    name: 'Skydiving Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'skydiving-center'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2507,
    public_id: 'snack-place',
    name: 'Snack Place',
    public_id_breadcrumbs: ['bars-restaurants', 'quick-bites', 'snack-place']
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2273,
    public_id: 'snorkeling-place',
    name: 'Snorkeling Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'snorkeling-place'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2609,
    public_id: 'snorkeling-rental',
    name: 'Snorkeling Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'snorkeling-rental'
    ]
  },
  {
    parent_public_id: 'ski-trail',
    assignable: true,
    id: 2360,
    public_id: 'snowboard-trail',
    name: 'Snowboard Trail',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'ski-trail',
      'snowboard-trail'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2274,
    public_id: 'snowmobiling-place',
    name: 'Snowmobiling Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'snowmobiling-place'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2722,
    public_id: 'soccer-field',
    name: 'Soccer Field',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'soccer-field'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2038,
    public_id: 'soccer-stadium',
    name: 'Soccer Stadium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'soccer-stadium'
    ]
  },
  {
    parent_public_id: 'family-services',
    assignable: true,
    id: 2734,
    public_id: 'social-services-office',
    name: 'Social Services Office',
    public_id_breadcrumbs: [
      'general-services',
      'family-services',
      'social-services-office'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2493,
    public_id: 'south-american-restaurant',
    name: 'South American Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'south-american-restaurant'
    ]
  },
  {
    parent_public_id: 'american-restaurant',
    assignable: true,
    id: 2431,
    public_id: 'southern-soulfood-restaurant',
    name: 'Southern/Soulfood Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'american-restaurant',
      'southern-soulfood-restaurant'
    ]
  },
  {
    parent_public_id: 'indian-restaurant',
    assignable: true,
    id: 2473,
    public_id: 'south-indian-restaurant',
    name: 'South Indian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'indian-restaurant',
      'south-indian-restaurant'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2056,
    public_id: 'souvenir-shop',
    name: 'Souvenir Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'souvenir-shop'
    ]
  },
  {
    parent_public_id: 'hotel',
    assignable: true,
    id: 1894,
    public_id: 'spa-hotel',
    name: 'Spa Hotel',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'hotel',
      'spa-hotel'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2494,
    public_id: 'spanish-restaurant',
    name: 'Spanish Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'spanish-restaurant'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2396,
    public_id: 'speakeasy',
    name: 'Speakeasy',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'speakeasy'
    ]
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2018,
    public_id: 'specialty-museum',
    name: 'Specialty Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'specialty-museum'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2068,
    public_id: 'specialty-shop',
    name: 'Specialty Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2077,
    public_id: 'spice-shop',
    name: 'Spice Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'spice-shop'
    ]
  },
  {
    parent_public_id: 'martial-arts-studio',
    assignable: true,
    id: 2593,
    public_id: 'spin-studio',
    name: 'Spin Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'martial-arts-studio',
      'spin-studio'
    ]
  },
  {
    parent_public_id: 'attractions-activities',
    id: 2029,
    public_id: 'sporting-attractions',
    name: 'Sporting Attractions',
    public_id_breadcrumbs: ['attractions-activities', 'sporting-attractions']
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2397,
    public_id: 'sports-bar',
    name: 'Sports Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'sports-bar'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2723,
    public_id: 'sports-club',
    name: 'Sports Club',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'sports-club'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2707,
    public_id: 'sports-facilities',
    name: 'Sports Facilities',
    public_id_breadcrumbs: ['general-services', 'sports-facilities']
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2708,
    public_id: 'sports-facility',
    name: 'Sports Facility',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'sports-facility'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2065,
    public_id: 'sports-shop',
    name: 'Sports Shop',
    public_id_breadcrumbs: ['attractions-activities', 'shopping', 'sports-shop']
  },
  {
    parent_public_id: 'sporting-attractions',
    assignable: true,
    id: 2030,
    public_id: 'sports-stadium',
    name: 'Sports Stadium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2724,
    public_id: 'squash-court',
    name: 'Squash Court',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'squash-court'
    ]
  },
  {
    parent_public_id: 'historic-site',
    assignable: true,
    id: 2197,
    public_id: 'state-historic-site',
    name: 'State Historic Site',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'historic-places',
      'historic-site',
      'state-historic-site'
    ]
  },
  {
    parent_public_id: 'recreation-area',
    assignable: true,
    id: 2293,
    public_id: 'state-park',
    name: 'State Park',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'parks-gardens',
      'recreation-area',
      'state-park'
    ]
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2631,
    public_id: 'state-welcome-center',
    name: 'State Welcome Center',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'state-welcome-center'
    ]
  },
  {
    parent_public_id: 'shopping',
    assignable: true,
    id: 2117,
    public_id: 'office-supplies-shop',
    name: 'Stationary/Office Supplies Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'office-supplies-shop'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2496,
    public_id: 'steak-house',
    name: 'Steak House',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'steak-house'
    ]
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2746,
    public_id: 'storage-facility',
    name: 'Storage Facility',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'storage-facility'
    ]
  },
  {
    parent_public_id: 'public-art',
    assignable: true,
    id: 2220,
    public_id: 'street-art',
    name: 'Street Art',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'public-art-displays',
      'public-art',
      'street-art'
    ]
  },
  {
    parent_public_id: 'quick-bites',
    assignable: true,
    id: 2520,
    public_id: 'street-food-place',
    name: 'Street Food Place',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'quick-bites',
      'street-food-place'
    ]
  },
  {
    parent_public_id: 'music-entertainment',
    assignable: true,
    id: 2009,
    public_id: 'strip-club',
    name: 'Strip Club',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'strip-club'
    ]
  },
  {
    parent_public_id: 'mall',
    assignable: true,
    id: 2107,
    public_id: 'strip-mall',
    name: 'Strip Mall',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'mall',
      'strip-mall'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2670,
    public_id: 'subway-station',
    name: 'Subway Station',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'subway-station'
    ]
  },
  {
    parent_public_id: 'campground',
    assignable: true,
    id: 1881,
    public_id: 'summer-camp',
    name: 'Summer Camp',
    public_id_breadcrumbs: [
      'places-to-stay',
      'campgrounds',
      'campground',
      'summer-camp'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2153,
    public_id: 'sunset-cruise',
    name: 'Sunset Cruise',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'sunset-cruise'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2619,
    public_id: 'supermarket',
    name: 'Supermarket',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'supermarket'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2613,
    public_id: 'supermarkets-groceries',
    name: 'Supermarkets & Groceries',
    public_id_breadcrumbs: ['general-services', 'supermarkets-groceries']
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2497,
    public_id: 'supper-club',
    name: 'Supper Club',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'supper-club'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2097,
    public_id: 'supplement-shop',
    name: 'Supplement Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'supplement-shop'
    ]
  },
  {
    parent_public_id: 'beach',
    assignable: true,
    id: 2280,
    public_id: 'surf-beach',
    name: 'Surf Beach',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'beaches-swim-spots',
      'beach',
      'surf-beach'
    ]
  },
  {
    parent_public_id: 'gear-rental-services',
    assignable: true,
    id: 2612,
    public_id: 'surfboard-rental',
    name: 'Surfboard Rental Service',
    public_id_breadcrumbs: [
      'general-services',
      'gear-rental-services',
      'surfboard-rental'
    ]
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2131,
    public_id: 'surf-school',
    name: 'Surf School',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'surf-school'
    ]
  },
  {
    parent_public_id: 'japanese-restaurant',
    assignable: true,
    id: 2447,
    public_id: 'sushi-place',
    name: 'Sushi Place',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'japanese-restaurant',
      'sushi-place'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2154,
    public_id: 'swamp-tour',
    name: 'Swamp Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'swamp-tour'
    ]
  },
  {
    parent_public_id: 'bars-restaurants',
    id: 2414,
    public_id: 'sweets-treats',
    name: 'Sweets & Treats',
    public_id_breadcrumbs: ['bars-restaurants', 'sweets-treats']
  },
  {
    parent_public_id: 'class-workshop',
    assignable: true,
    id: 2130,
    public_id: 'swim-class',
    name: 'Swim Class',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'class-workshop',
      'swim-class'
    ]
  },
  {
    parent_public_id: 'swim-spot',
    assignable: true,
    id: 2282,
    public_id: 'swimming-hole',
    name: 'Swimming Hole',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'beaches-swim-spots',
      'swim-spot',
      'swimming-hole'
    ]
  },
  {
    parent_public_id: 'swim-spot',
    assignable: true,
    id: 2283,
    public_id: 'swimming-pool',
    name: 'Swimming Pool',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'beaches-swim-spots',
      'swim-spot',
      'swimming-pool'
    ]
  },
  {
    parent_public_id: 'beaches-swim-spots',
    assignable: true,
    id: 2281,
    public_id: 'swim-spot',
    name: 'Swim Spot',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'beaches-swim-spots',
      'swim-spot'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2704,
    public_id: 'synagogue',
    name: 'Synagogue',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'synagogue'
    ]
  },
  {
    parent_public_id: 'personal-care-services',
    assignable: true,
    id: 2651,
    public_id: 'tanning-salon',
    name: 'Tanning Salon',
    public_id_breadcrumbs: [
      'general-services',
      'personal-care-services',
      'tanning-salon'
    ]
  },
  {
    parent_public_id: 'churches-temples-mosques',
    assignable: true,
    id: 2705,
    public_id: 'taoist-temple',
    name: 'Taoist Temple',
    public_id_breadcrumbs: [
      'general-services',
      'churches-temples-mosques',
      'taoist-temple'
    ]
  },
  {
    parent_public_id: 'spanish-restaurant',
    assignable: true,
    id: 2495,
    public_id: 'tapas-bar',
    name: 'Tapas Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'spanish-restaurant',
      'tapas-bar'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2398,
    public_id: 'tap-room',
    name: 'Tap Room',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'tap-room'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2078,
    public_id: 'tattoo-parlor',
    name: 'Tattoo Parlor',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'tattoo-parlor'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2089,
    public_id: 'taxidermist',
    name: 'Taxidermist',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'taxidermist'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2679,
    public_id: 'taxi-service',
    name: 'Taxi Service',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'taxi-service'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2672,
    public_id: 'taxi-stand',
    name: 'Taxi Stand',
    public_id_breadcrumbs: ['general-services', 'transportation', 'taxi-stand']
  },
  {
    parent_public_id: 'coffee-tea',
    assignable: true,
    id: 2413,
    public_id: 'tea-room',
    name: 'Tea Room',
    public_id_breadcrumbs: ['bars-restaurants', 'coffee-tea', 'tea-room']
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2795,
    public_id: 'tech-startup',
    name: 'Tech Startup',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'tech-startup'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2725,
    public_id: 'tennis-court',
    name: 'Tennis Court',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'tennis-court'
    ]
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2039,
    public_id: 'tennis-stadium',
    name: 'Tennis Stadium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'tennis-stadium'
    ]
  },
  {
    parent_public_id: 'campground',
    assignable: true,
    id: 1876,
    public_id: 'tent-campground',
    name: 'Tent Campground',
    public_id_breadcrumbs: [
      'places-to-stay',
      'campgrounds',
      'campground',
      'tent-campground'
    ],
    description: 'Developed campground that accommodates tents'
  },
  {
    parent_public_id: 'glamping-place',
    assignable: true,
    id: 1904,
    public_id: 'tent-glamping',
    name: 'Tent Glamping Place',
    public_id_breadcrumbs: [
      'places-to-stay',
      'unique-places-to-stay',
      'glamping-place',
      'tent-glamping'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2399,
    public_id: 'tequila-bar',
    name: 'Tequila Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'tequila-bar'
    ]
  },
  {
    parent_public_id: 'ev-charging-station',
    assignable: true,
    id: 2547,
    public_id: 'tesla-supercharger',
    name: 'Tesla Supercharger',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'ev-charging',
      'ev-charging-station',
      'tesla-supercharger'
    ]
  },
  {
    parent_public_id: 'american-restaurant',
    assignable: true,
    id: 2432,
    public_id: 'tex-mex-restaurant',
    name: 'Tex Mex Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'american-restaurant',
      'tex-mex-restaurant'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2444,
    public_id: 'thai-restaurant',
    name: 'Thai Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'thai-restaurant'
    ]
  },
  {
    parent_public_id: 'music-entertainment',
    assignable: true,
    id: 2005,
    public_id: 'theater',
    name: 'Theater',
    public_id_breadcrumbs: [
      'attractions-activities',
      'music-entertainment',
      'theater'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2498,
    public_id: 'theme-restaurant',
    name: 'Theme Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'theme-restaurant'
    ]
  },
  {
    parent_public_id: 'medical-facility',
    assignable: true,
    id: 2758,
    public_id: 'therapist',
    name: 'Therapist',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'medical-facility',
      'therapist'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2090,
    public_id: 'thrift-store',
    name: 'Thrift Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'thrift-store'
    ]
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2632,
    public_id: 'ticket-sales',
    name: 'Ticket Sales Kiosk',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'ticket-sales'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2400,
    public_id: 'tiki-bar',
    name: 'Tiki Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'tiki-bar'
    ]
  },
  {
    parent_public_id: 'resort',
    assignable: true,
    id: 1900,
    public_id: 'timeshare-resort',
    name: 'Timeshare Resort',
    public_id_breadcrumbs: [
      'places-to-stay',
      'hotels-hostels',
      'resort',
      'timeshare-resort'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2537,
    public_id: 'tire-exhaust',
    name: 'Tire & Exhaust',
    public_id_breadcrumbs: ['auto-rv-services', 'auto-services', 'tire-exhaust']
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2079,
    public_id: 'tobacconist',
    name: 'Tobacconist',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'tobacconist'
    ]
  },
  {
    parent_public_id: 'transportation-facilities',
    assignable: true,
    id: 2683,
    public_id: 'toll-booth',
    name: 'Toll Booth',
    public_id_breadcrumbs: [
      'general-services',
      'transportation-facilities',
      'toll-booth'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2677,
    public_id: 'toll-plaza',
    name: 'Toll Plaza',
    public_id_breadcrumbs: ['general-services', 'transportation', 'toll-plaza']
  },
  {
    parent_public_id: 'tours-experiences',
    assignable: true,
    id: 2135,
    public_id: 'tour',
    name: 'Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour'
    ]
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2633,
    public_id: 'tourist-information',
    name: 'Tourist Information Office',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'tourist-information'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2628,
    public_id: 'tourist-services',
    name: 'Tourist Services',
    public_id_breadcrumbs: ['general-services', 'tourist-services']
  },
  {
    parent_public_id: 'attractions-activities',
    id: 2120,
    public_id: 'tours-experiences',
    name: 'Tours & Experiences',
    public_id_breadcrumbs: ['attractions-activities', 'tours-experiences']
  },
  {
    parent_public_id: 'civic-spaces',
    assignable: true,
    id: 2233,
    public_id: 'town-hall',
    name: 'Town Hall',
    public_id_breadcrumbs: ['sights-landmarks', 'civic-spaces', 'town-hall']
  },
  {
    parent_public_id: 'landmark',
    assignable: true,
    id: 2201,
    public_id: 'town-square',
    name: 'Town Square/Plaza',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'landmarks',
      'landmark',
      'town-square'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2538,
    public_id: 'tow-service',
    name: 'Tow Service',
    public_id_breadcrumbs: ['auto-rv-services', 'auto-services', 'tow-service']
  },
  {
    parent_public_id: 'sports-stadium',
    assignable: true,
    id: 2040,
    public_id: 'track-stadium',
    name: 'Track Stadium',
    public_id_breadcrumbs: [
      'attractions-activities',
      'sporting-attractions',
      'sports-stadium',
      'track-stadium'
    ]
  },
  {
    parent_public_id: 'trails',
    assignable: true,
    id: 2347,
    public_id: 'trailhead',
    name: 'Trailhead',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'trails', 'trailhead']
  },
  {
    parent_public_id: 'outdoors-recreation-places',
    id: 2346,
    public_id: 'trails',
    name: 'Trails',
    public_id_breadcrumbs: ['outdoors-recreation-places', 'trails']
  },
  {
    parent_public_id: 'museum',
    assignable: true,
    id: 2028,
    public_id: 'train-museum',
    name: 'Train Museum',
    public_id_breadcrumbs: [
      'attractions-activities',
      'museums',
      'museum',
      'train-museum'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2681,
    public_id: 'train-platform',
    name: 'Train Platform',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'train-platform'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2673,
    public_id: 'train-station',
    name: 'Train Station',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'train-station'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1966,
    public_id: 'trampoline-park',
    name: 'Trampoline Park',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'trampoline-park'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2674,
    public_id: 'tram-service',
    name: 'Tram Service',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'tram-service'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2662,
    public_id: 'transportation',
    name: 'Transportation',
    public_id_breadcrumbs: ['general-services', 'transportation']
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2796,
    public_id: 'transportation-company',
    name: 'Transportation Company',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'transportation-company'
    ]
  },
  {
    parent_public_id: 'general-services',
    id: 2682,
    public_id: 'transportation-facilities',
    name: 'Transportation Facilities',
    public_id_breadcrumbs: ['general-services', 'transportation-facilities']
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2743,
    public_id: 'trashcan',
    name: 'Trashcan',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'trashcan'
    ]
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2636,
    public_id: 'travel-agency',
    name: 'Travel Agency',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'travel-agency'
    ]
  },
  {
    parent_public_id: 'transportation',
    assignable: true,
    id: 2676,
    public_id: 'travel-lounge',
    name: 'Travel Lounge',
    public_id_breadcrumbs: [
      'general-services',
      'transportation',
      'travel-lounge'
    ]
  },
  {
    parent_public_id: 'glamping-place',
    assignable: true,
    id: 1905,
    public_id: 'treehouse-stay',
    name: 'Treehouse Stay',
    public_id_breadcrumbs: [
      'places-to-stay',
      'unique-places-to-stay',
      'glamping-place',
      'treehouse-stay'
    ]
  },
  {
    parent_public_id: 'gas-station',
    assignable: true,
    id: 2550,
    public_id: 'truck-stop',
    name: 'Truck Stop',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'gas-fuel-stations',
      'gas-station',
      'truck-stop'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2269,
    public_id: 'tubing-place',
    name: 'Tubing Place',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'tubing-place'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2315,
    public_id: 'tunnel',
    name: 'Tunnel',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'tunnel'
    ]
  },
  {
    parent_public_id: 'middle-eastern-restaurant',
    assignable: true,
    id: 2482,
    public_id: 'turkish-restauarnt',
    name: 'Turkish Restauarnt',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'middle-eastern-restaurant',
      'turkish-restauarnt'
    ]
  },
  {
    parent_public_id: 'cultural-interest-sites',
    assignable: true,
    id: 2173,
    public_id: 'tv-filming-location',
    name: 'TV Filming Location',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'cultural-interest-sites',
      'tv-filming-location'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2764,
    public_id: 'tv-station',
    name: 'TV Station',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'tv-station'
    ]
  },
  {
    parent_public_id: 'nature-reserve',
    assignable: true,
    id: 2343,
    public_id: 'unesco-global-geopark',
    name: 'UNESCO Global Geopark',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'nature-reserves',
      'nature-reserve',
      'unesco-global-geopark'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1937,
    public_id: 'unesco-world-heritage-site',
    name: 'UNESCO World Heritage Site',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'unesco-world-heritage-site'
    ]
  },
  {
    parent_public_id: 'places-to-stay',
    id: 1901,
    public_id: 'unique-places-to-stay',
    name: 'Unique Places to Stay',
    public_id_breadcrumbs: ['places-to-stay', 'unique-places-to-stay']
  },
  {
    parent_public_id: 'unique-places-to-stay',
    assignable: true,
    id: 1907,
    public_id: 'unique-stay',
    name: 'Unique Stay',
    public_id_breadcrumbs: [
      'places-to-stay',
      'unique-places-to-stay',
      'unique-stay'
    ]
  },
  {
    parent_public_id: 'educational-institution',
    assignable: true,
    id: 2235,
    public_id: 'university',
    name: 'University',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'civic-spaces',
      'educational-institution',
      'university'
    ]
  },
  {
    parent_public_id: 'medical-services',
    assignable: true,
    id: 2642,
    public_id: 'urgent-care',
    name: 'Urgent Care',
    public_id_breadcrumbs: [
      'general-services',
      'medical-services',
      'urgent-care'
    ]
  },
  {
    parent_public_id: 'book-shop',
    assignable: true,
    id: 2052,
    public_id: 'used-book-shop',
    name: 'Used Book Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'book-shop',
      'used-book-shop'
    ]
  },
  {
    parent_public_id: 'vacation-rental-places',
    assignable: true,
    id: 1911,
    public_id: 'vacation-rental',
    name: 'Vacation Rental Place',
    public_id_breadcrumbs: [
      'places-to-stay',
      'vacation-rental-places',
      'vacation-rental'
    ]
  },
  {
    parent_public_id: 'places-to-stay',
    id: 1910,
    public_id: 'vacation-rental-places',
    name: 'Vacation Rental Places',
    public_id_breadcrumbs: ['places-to-stay', 'vacation-rental-places']
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2319,
    public_id: 'valley',
    name: 'Valley',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'valley'
    ]
  },
  {
    parent_public_id: 'vehicle-rental-place',
    assignable: true,
    id: 2558,
    public_id: 'van-rental-place',
    name: 'Van Rental Place',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'car-rv-van-rental',
      'vehicle-rental-place',
      'van-rental-place'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2093,
    public_id: 'vape-shop',
    name: 'Vape Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'vape-shop'
    ]
  },
  {
    parent_public_id: 'restaurant',
    assignable: true,
    id: 2499,
    public_id: 'vegetarian-restaurant',
    name: 'Vegetarian Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'vegetarian-restaurant'
    ]
  },
  {
    parent_public_id: 'auto-services',
    assignable: true,
    id: 2539,
    public_id: 'vehicle-licensing-agency',
    name: 'Vehicle Licensing Agency',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'auto-services',
      'vehicle-licensing-agency'
    ]
  },
  {
    parent_public_id: 'car-rv-van-rental',
    assignable: true,
    id: 2556,
    public_id: 'vehicle-rental-place',
    name: 'Vehicle Rental Place',
    public_id_breadcrumbs: [
      'auto-rv-services',
      'car-rv-van-rental',
      'vehicle-rental-place'
    ]
  },
  {
    parent_public_id: 'essential-amenities',
    assignable: true,
    id: 2744,
    public_id: 'vending-machine',
    name: 'Vending Machine',
    public_id_breadcrumbs: [
      'general-services',
      'essential-amenities',
      'vending-machine'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2763,
    public_id: 'veterans-organization',
    name: 'Veterans Organization',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'veterans-organization'
    ]
  },
  {
    parent_public_id: 'pet-services',
    assignable: true,
    id: 2659,
    public_id: 'veterinarian',
    name: 'Veterinarian',
    public_id_breadcrumbs: ['general-services', 'pet-services', 'veterinarian']
  },
  {
    parent_public_id: 'arcade',
    assignable: true,
    id: 1948,
    public_id: 'video-games-arcade',
    name: 'Video Games Arcade',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'arcade',
      'video-games-arcade'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2095,
    public_id: 'video-game-store',
    name: 'Video Game Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'video-game-store'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2092,
    public_id: 'video-store',
    name: 'Video Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'video-store'
    ]
  },
  {
    parent_public_id: 'asian-restaurant',
    assignable: true,
    id: 2445,
    public_id: 'vietnamese-restaurant',
    name: 'Vietnamese Restaurant',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'restaurants',
      'restaurant',
      'asian-restaurant',
      'vietnamese-restaurant'
    ]
  },
  {
    parent_public_id: 'grape-grain-attractions',
    assignable: true,
    id: 2407,
    public_id: 'vineyard',
    name: 'Vineyard',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'grape-grain-attractions',
      'vineyard'
    ]
  },
  {
    parent_public_id: 'arcade',
    assignable: true,
    id: 1949,
    public_id: 'vintage-penny-arcade',
    name: 'Vintage/Penny Arcade',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'arcade',
      'vintage-penny-arcade'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2080,
    public_id: 'vintage-shop',
    name: 'Vintage Shop',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'vintage-shop'
    ]
  },
  {
    parent_public_id: 'arcade',
    assignable: true,
    id: 1950,
    public_id: 'virtual-reality-arcade',
    name: 'Virtual Reality Arcade',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'arcade',
      'virtual-reality-arcade'
    ]
  },
  {
    parent_public_id: 'tourist-services',
    assignable: true,
    id: 2634,
    public_id: 'visitor-center',
    name: 'Visitor Center',
    public_id_breadcrumbs: [
      'general-services',
      'tourist-services',
      'visitor-center'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2318,
    public_id: 'volcano',
    name: 'Volcano',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'volcano'
    ]
  },
  {
    parent_public_id: 'sports-facilities',
    assignable: true,
    id: 2726,
    public_id: 'volleyball-court',
    name: 'Volleyball Court',
    public_id_breadcrumbs: [
      'general-services',
      'sports-facilities',
      'volleyball-court'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2160,
    public_id: 'walking-tour',
    name: 'Walking Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'walking-tour'
    ]
  },
  {
    parent_public_id: 'trailhead',
    assignable: true,
    id: 2362,
    public_id: 'walking-trail',
    name: 'Walking Trail',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'trails',
      'trailhead',
      'walking-trail'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2798,
    public_id: 'warehouse',
    name: 'Warehouse',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'warehouse'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2800,
    public_id: 'waste-facility',
    name: 'Waste Facility',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'waste-facility'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2304,
    public_id: 'waterfall',
    name: 'Waterfall',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'waterfall'
    ]
  },
  {
    parent_public_id: 'attractions',
    assignable: true,
    id: 1938,
    public_id: 'water-park',
    name: 'Water Park',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'water-park'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2314,
    public_id: 'water-point',
    name: 'Water Point',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'water-point'
    ]
  },
  {
    parent_public_id: 'fun-games',
    assignable: true,
    id: 1969,
    public_id: 'wedding-chapel',
    name: 'Wedding Chapel',
    public_id_breadcrumbs: [
      'attractions-activities',
      'fun-games',
      'wedding-chapel'
    ]
  },
  {
    parent_public_id: 'businesses-offices-commercial',
    assignable: true,
    id: 2768,
    public_id: 'wedding-venue',
    name: 'Wedding Venue',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'wedding-venue'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2306,
    public_id: 'wetlands',
    name: 'Wetlands',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'wetlands'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2161,
    public_id: 'whale-dolphin-watching',
    name: 'Whale/Dolphin Watching Tour',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'whale-dolphin-watching'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2308,
    public_id: 'wharf',
    name: 'Wharf',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'wharf'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2401,
    public_id: 'whisky-bar',
    name: 'Whisky Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'whisky-bar'
    ]
  },
  {
    parent_public_id: 'specialty-shop',
    assignable: true,
    id: 2096,
    public_id: 'wholesaler',
    name: 'Wholesaler',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'specialty-shop',
      'wholesaler'
    ]
  },
  {
    parent_public_id: 'animal-attraction',
    assignable: true,
    id: 1923,
    public_id: 'wildlife-park',
    name: 'Wildlife Park',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction',
      'wildlife-park'
    ]
  },
  {
    parent_public_id: 'nature-reserve',
    assignable: true,
    id: 2344,
    public_id: 'wildlife-reserve',
    name: 'Wildlife Reserve',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'nature-reserves',
      'nature-reserve',
      'wildlife-reserve'
    ]
  },
  {
    parent_public_id: 'bar',
    assignable: true,
    id: 2402,
    public_id: 'wine-bar',
    name: 'Wine Bar',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'bars-drinks',
      'bar',
      'wine-bar'
    ]
  },
  {
    parent_public_id: 'grape-grain-attractions',
    assignable: true,
    id: 2408,
    public_id: 'winery',
    name: 'Winery',
    public_id_breadcrumbs: [
      'bars-restaurants',
      'grape-grain-attractions',
      'winery'
    ]
  },
  {
    parent_public_id: 'supermarkets-groceries',
    assignable: true,
    id: 2624,
    public_id: 'wine-shop',
    name: 'Wine Shop',
    public_id_breadcrumbs: [
      'general-services',
      'supermarkets-groceries',
      'wine-shop'
    ]
  },
  {
    parent_public_id: 'tour',
    assignable: true,
    id: 2162,
    public_id: 'wine-tour',
    name: 'Wine Tour/Tasting',
    public_id_breadcrumbs: [
      'attractions-activities',
      'tours-experiences',
      'tour',
      'wine-tour'
    ]
  },
  {
    parent_public_id: 'clothing-store',
    assignable: true,
    id: 2111,
    public_id: 'womens-clothing-store',
    name: 'Womens Clothing Store',
    public_id_breadcrumbs: [
      'attractions-activities',
      'shopping',
      'clothing-store',
      'womens-clothing-store'
    ]
  },
  {
    parent_public_id: 'geographic-feature',
    assignable: true,
    id: 2330,
    public_id: 'woodland',
    name: 'Woodland',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'geographic-features',
      'geographic-feature',
      'woodland'
    ]
  },
  {
    parent_public_id: 'business',
    assignable: true,
    id: 2799,
    public_id: 'workshop',
    name: 'Workshop',
    public_id_breadcrumbs: [
      'general-services',
      'businesses-offices-commercial',
      'business',
      'workshop'
    ]
  },
  {
    parent_public_id: 'public-art',
    assignable: true,
    id: 2221,
    public_id: 'worlds-largest-something',
    name: 'Worlds Largest Something',
    public_id_breadcrumbs: [
      'sights-landmarks',
      'public-art-displays',
      'public-art',
      'worlds-largest-something'
    ]
  },
  {
    parent_public_id: 'martial-arts-studio',
    assignable: true,
    id: 2591,
    public_id: 'wrestling-gym',
    name: 'Wrestling Gym',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'martial-arts-studio',
      'wrestling-gym'
    ]
  },
  {
    parent_public_id: 'martial-arts-studio',
    assignable: true,
    id: 2594,
    public_id: 'yoga-studio',
    name: 'Yoga Studio',
    public_id_breadcrumbs: [
      'general-services',
      'fitness-facilities',
      'martial-arts-studio',
      'yoga-studio'
    ]
  },
  {
    parent_public_id: 'glamping-place',
    assignable: true,
    id: 1906,
    public_id: 'yurt-stay',
    name: 'Yurt Stay',
    public_id_breadcrumbs: [
      'places-to-stay',
      'unique-places-to-stay',
      'glamping-place',
      'yurt-stay'
    ]
  },
  {
    parent_public_id: 'outdoor-activity',
    assignable: true,
    id: 2276,
    public_id: 'ziplining-center',
    name: 'Ziplining Center',
    public_id_breadcrumbs: [
      'outdoors-recreation-places',
      'outdoor-activities',
      'outdoor-activity',
      'ziplining-center'
    ]
  },
  {
    parent_public_id: 'animal-attraction',
    assignable: true,
    id: 1924,
    public_id: 'zoo',
    name: 'Zoo',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction',
      'zoo'
    ]
  },
  {
    parent_public_id: 'animal-attraction',
    assignable: true,
    id: 1925,
    public_id: 'zoo-exhibit',
    name: 'Zoo Exhibit',
    public_id_breadcrumbs: [
      'attractions-activities',
      'attractions',
      'animal-attraction',
      'zoo-exhibit'
    ]
  }
];
