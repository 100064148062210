import * as React from "react";
import * as flags from "@library/launch-darkly/flags";
import Attributes from "../attributes/Container";
import Editorial from "../editorial/Container";
import GeneralInfo from "../info/";
import Hours from "../hours/Container";
import Media from "../media";
import RT from "../rt/Container";
import Changes from '../changes';
import Suggestions from "../suggestions/Container";
import Tab from "./tab";
import Categories from "../categories";
import Dashboard from "../dashboard";
import Discoverability from "../discoverability";
import Relationships from "../relationships";

function Tabs({active, ...props}) {
  return (
    <div className="editor__tabs">
      <Tab expected="dashboard" actual={active}>
        <Dashboard {...props} />
      </Tab>
      <Tab expected="general" actual={active}>
        <GeneralInfo
          {...props.basic}
          onSave={props.updateIndex}
          onEdit={props.updateIndex}
          onCancel={props.onGeneralCancel}
          userEditingStrategy={props.userEditingStrategy}
        />
      </Tab>
      <Tab expected="attributes" actual={active}>
        <Attributes
          {...props.attributes}
          userRole={props.userRole}
          categories={props.canonicalPlace.categories}
          userEditingStrategy={props.userEditingStrategy}
        />
      </Tab>
      <flags.DETAILS_TAB>
        <Tab expected="categories" actual={active}>
          <Categories {...props.details} 
          tags={props.attributes.tags}/>
        </Tab>
      </flags.DETAILS_TAB>
      <Tab expected="hours" actual={active}>
        <Hours
          {...props.hours}
          userEditingStrategy={props.userEditingStrategy}
        />
      </Tab>
      <Tab expected="media" actual={active}>
        <Media
          {...props.media}
          userEditingStrategy={props.userEditingStrategy}
        />
      </Tab>
      <Tab expected="suggestions" actual={active}>
        <Suggestions
          {...props.suggestions}
          userEditingStrategy={props.suggestions.userEditingStrategy}
        />
      </Tab>
      <Tab expected="editorial" actual={active}>
        <Editorial
          {...props.editorial}
          userEditingStrategy={props.userEditingStrategy}
        />
      </Tab>
      <Tab expected="changes" actual={active}>
        <Changes {...props.changes}/>
      </Tab>
      {props.discoverability && (
        <Tab expected="discoverability" actual={active}>
          <Discoverability {...props.details}/>
        </Tab>
      )}
      <Tab expected="relationships" actual={active}>
        <Relationships {...props}/>
      </Tab>
      <flags.EXTRAORDINARY_PLACES>
        <Tab expected="rt" actual={active}>
          <RT {...props.rt} userEditingStrategy={props.userEditingStrategy} />
        </Tab>
      </flags.EXTRAORDINARY_PLACES>
    </div>
  );
}

export default Tabs;
