import React from "react";

function Bar() {
  return (
    <div
      style={{
        minWidth: "100%",
        height: "60px",
        backgroundColor: "lightgrey",
        marginBottom: "1em",
        borderRadius: "2ps",
      }}
    ></div>
  );
}

export default Bar;
