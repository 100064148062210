import React from 'react';
import Group from './day';
import invertBy from 'lodash/invertBy';
import cn from 'classnames';
import './styles/index';

const DEFAULT_DAY_MAP = {
  sun_open_close: [],
  mon_open_close: [],
  tue_open_close: [],
  wed_open_close: [],
  thu_open_close: [],
  fri_open_close: [],
  sat_open_close: []
};

type HoursMap = {
  '<default>': {
    sun_open_close: string[];
    mon_open_close: string[];
    tue_open_close: string[];
    wed_open_close: string[];
    thu_open_close: string[];
    fri_open_close: string[];
    sat_open_close: string[];
  };
};

function Schedule(props){
  const [closed, setClosed] = React.useState(false);
  const [open, setOpen] = React.useState();
  const [close, setClose] = React.useState();

  const [items, setItems] = React.useState({});

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  const [schedule, setSchedule] = React.useState({
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: []
  });

  const getAssignment = (c) => {
    const abbr = c.slice(0, 3).toLowerCase();
    const key = abbr + '_open_close';
    return props['<default>'][key];
  };

  React.useEffect(() => {
    days.map((day) => {
      let time = getAssignment(day);

      setSchedule((s) => ({ ...s, [day]: time }));
    });
  }, []);

  React.useEffect(
    () => {
      setItems((i) => invertBy(schedule));
    },
    [schedule]
  );

  const updateTime = (oldTime, newTime) => {
    days.forEach((day) => {
      if (schedule[day] === oldTime) {
        setSchedule((s) => ({ ...s, [day]: newTime }));
      }
    });
  };

  const reschedule = (day, newTime) => {
    if (newTime === schedule[day].join(',')) {
      setSchedule((s) => ({ ...s, [day]: ['open'] }));
    } else {
      setSchedule((s) => ({ ...s, [day]: newTime.split(',') }));
    }
  };

  return (
    <div className="editor__schedule">
      <h3>Schedule</h3>
      <button onClick={() => setItems((i) => ({ ...i, '00:00-23:59': [] }))}>
        add item
      </button>
      {Object.keys(items).length > 0 ? (
        Object.keys(items)
          .filter((item) => item !== 'open')
          .map((item, idx) => {
            return (
              <div key={item + idx} className="editor__schedule__item">
                <Group
                  schedule={item.split(',')}
                  onChange={(n) => updateTime(item, n)}
                />
                <div className="editor__schedule__days">
                  {days.map((day) => {
                    return (
                      <div
                        key={item + day}
                        className={cn({
                          editor__schedule__day: true,
                          'editor__schedule__day--selected':
                            schedule[day].join(',') === item,
                          'editor__schedule__day--open':
                            schedule[day][0] === 'open'
                        })}
                        onClick={() => reschedule(day, item)}
                      >{`${day[0]}`}</div>
                    );
                  })}
                </div>
              </div>
            );
          })
      ) : (
        <div>No hours</div>
      )}
    </div>
  );
}

export default Schedule;
