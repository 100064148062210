/**
 * @deprecated 1/06/2020 ListRemoveButton, ListAddButton
 * @todo
 * - Replace deprecated components.
 * - Split up edit, view, and index into separate files.
 */
import React from 'react';
import PropTypes from 'prop-types';
import * as ajax from '@/helpers/ajax';
import SocialNetworkSelect from '../../SocialNetworkSelect';
import { IconDeleteSuggestion } from '../../icons/IconDelete';
import { IconAcceptSuggestion } from '../../icons/IconAcceptSuggestion';
import { SocialHandlePresenter } from '../../helpers/SocialHandlePresenter';
import filterChanges from '../../helpers/filterChanges';
import deepClone from '../../helpers/DeepClone';
import ListButton from '../../ListButton';
import ListAddButton from '../../ListAddButton';
import ListRemoveButton from '../../ListRemoveButton';
import ExternalLink from '../../ExternalLink';
import InputNoneUnknown from '../../library/input-none-unknown';
import Input from '../../library/Input';
import update from 'immutability-helper';
import Link from '../../library/link/view';
import dataExists from '../../helpers/dataExists';
import * as flags from '../../library/launch-darkly/flags';
import { EditData, ViewData } from '../../library/data-section';
import { useStoreContext } from '../../state';

class ContactInfoView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const state = {
      editPlace: { socialHandles: [], ...deepClone(this.props.origPlace) },
      editing: false,
      socialHandleSuggestions: deepClone(this.props.socialHandleSuggestions) || [],
      socialHandleCounter: 0
    };

    if (state.editPlace && state.editPlace.socialHandles && state.editPlace.socialHandles.length === 0) {
      state.editPlace.socialHandles = [{ key: ++state.socialHandleCounter, network: 'Facebook', url: null }];
    } else {
      for (let i = 0; i < state.editPlace.socialHandles.length; ++i) {
        // We received an object but it's property url is undefined so we set it to an empty string
        if (!state.editPlace.socialHandles[i].url) {
          state.editPlace.socialHandles[i].url = '';
        }
        state.editPlace.socialHandles[i].key = ++state.socialHandleCounter;
      }
    }

    return state;
  };

  analyticsContactInfo(url) {
    this.props.analytics.trackListing('Contact Link', 'opened', {
      link_url: url
    });
  }

  cancel = () => {
    let state = this.getInitialState();
    if (this.state.savedPlace) {
      state.savedPlace = this.state.savedPlace;
      state.editPlace = deepClone(this.state.savedPlace);
    }

    this.setState(state);
  };

  handlePlaceEdit = (id, value) => {
    this.setState(prevState => ({
      editPlace: {
        ...prevState.editPlace,
        [id]: value
      }
    }));
  };

  handleChange = e => {
    const editPlace = Object.assign({}, this.state.editPlace);
    editPlace[e.target.id] = e.target.value;
    this.setState({ editPlace });
  };

  cloneSocialHandle(socialHandle) {
    let index = this.state.editPlace.socialHandles.indexOf(socialHandle);
    let editPlace = deepClone(this.state.editPlace);
    let sh = editPlace.socialHandles[index];
    return { editPlace, sh };
  }

  handleSocialHandleChange = (item, url) => {
    let { editPlace, sh } = this.cloneSocialHandle(item);
    sh.url = url;
    sh.handle = '';
    this.setState({ editPlace });
  };

  handleNetworkChange = (item, network) => {
    let { editPlace, sh } = this.cloneSocialHandle(item);

    const presenter = new SocialHandlePresenter(sh);
    if (!presenter.urlMatchesNetwork(network)) {
      sh.url = '';
    }
    sh.network = network;

    this.setState({ editPlace });
  };

  updateData = (field, value) => {
    this.setState((prevState, props) => {
      let editPlace = deepClone(prevState.editPlace);
      editPlace[field] = value;

      return {
        editPlace,
        editing: true
      };
    });
  };

  // showDiff = (fieldDescription, field) => {
  //   this.setState(
  //     {
  //       diff: {
  //         id: 'contact-info-diff-modal',
  //         field: field,
  //         fieldDescription: fieldDescription,
  //         origValue: this.props.origPlace[field],
  //         diffValue: this.props.diffPlace[field],
  //         applyValue: this.updateData
  //       }
  //     }, () => ($('#contact-info-diff-modal').modal('show')));
  // }

  getSocialHandles() {
    return this.state.editPlace.socialHandles
      .map(item => {
        if (item.url !== null && item.url !== undefined) {
          return { network: item.network, url: item.url };
        } else {
          return null;
        }
      })
      .filter(item => {
        return item !== null;
      });
  }

  onSuccess() {
    this.setState({ editing: false, savedPlace: deepClone(this.state.editPlace) });
  }

  save = e => {
    this.setState({ saving: true });
    let socialHandles = this.state.editPlace.socialHandles
      .map(item => {
        if (item.url !== null && item.url !== undefined) {
          return { network: item.network, url: item.url };
        } else {
          return null;
        }
      })
      .filter(item => {
        return item !== null;
      });

    ajax
      .patch(document.location.pathname + this.props.url, {
        listing: {
          contact_info: filterChanges(this.props.origPlace, this.state.editPlace, [
            'booking_url',
            'email',
            'website',
            'phone',
            'tollfree'
          ]),
          social_handles: socialHandles
        }
      })
      .then(() => {
        this.setState({ editing: false, saving: false, savedPlace: deepClone(this.state.editPlace) });
      });
  };

  renderAddSocialHandleButton() {
    return <ListAddButton onClick={() => this.addNewSocialHandle()} />;
  }

  renderRemoveSocialHandleButton(item, index, className = '') {
    return <ListRemoveButton className={className} onClick={() => this.removeSocialHandle(item, index)} />;
  }

  addNewSocialHandle() {
    this.setState((prevState, props) => {
      const counter = prevState.socialHandleCounter + 1;
      let editPlace = deepClone(prevState.editPlace);
      editPlace.socialHandles.push({ key: counter, network: 'Facebook' });

      return {
        editPlace,
        socialHandleCounter: counter
      };
    });
  }

  removeSocialHandle(_item, index) {
    this.setState((prevState, props) => {
      return update(prevState, { editPlace: { socialHandles: { $splice: [[index, 1]] } } });
    });
  }

  handleSocialHandleFocusChanged = (item, focused) => {
    const editPlace = deepClone(this.state.editPlace);
    if (focused) {
      for (let i = 0; i < editPlace.socialHandles.length; ++i) {
        let sh = editPlace.socialHandles[i];
        if (sh.key === item.key) {
          sh.focused = focused;
        } else {
          sh.focused = false;
        }
      }
    }
    this.setState({ editPlace });
  };

  hideSuggestion = suggestion => {
    let index = this.state.socialHandleSuggestions[suggestion.network].indexOf(suggestion);
    let socialHandleSuggestions = deepClone(this.state.socialHandleSuggestions);
    socialHandleSuggestions[suggestion.network][index].hidden = true;
    this.setState({ socialHandleSuggestions });
  };

  doesSuggestionMatch = (socialHandle, suggestion) => {
    return new SocialHandlePresenter(socialHandle).url === new SocialHandlePresenter(suggestion).url;
  };

  renderSocialHandleEdit(item, index) {
    let url = item.url;
    if (!url) {
      if (item.handle) {
        url = new SocialHandlePresenter(item).url;
      } else {
        url = '';
      }
    }

    let suggestions = [];
    if (item.focused) {
      suggestions = this.state.socialHandleSuggestions[item.network] || [];
      suggestions = suggestions.filter(suggestion => {
        let matches = this.state.editPlace.socialHandles.some(socialHandle =>
          this.doesSuggestionMatch(socialHandle, suggestion)
        );
        return !suggestion.hidden && !matches;
      });
    }
    return (
      <React.Fragment key={item.key}>
        <div
          id="social-handles"
          className="editor__social-handles editor__social-handles--edit"
          key={'social-handle-' + item.key}
          onFocus={() => this.handleSocialHandleFocusChanged(item, true)}
        >
          <SocialNetworkSelect
            network={item.network}
            social_handle_id={item.key}
            onNetworkChanged={val => this.handleNetworkChange(item, val)}
          />
          <Input
            id={'social-handle-' + item.key}
            value={url}
            onChange={e => this.handleSocialHandleChange(item, e.target.value)}
          />
          {item === this.state.editPlace.socialHandles[this.state.editPlace.socialHandles.length - 1] ? (
            <div className="editor__group-editor__item__controls">
              {this.state.editPlace.socialHandles.length > 1
                ? this.renderRemoveSocialHandleButton(item, index, '')
                : null}
              {this.renderAddSocialHandleButton()}
            </div>
          ) : (
            <div className="editor__group-editor__item__controls">
              {this.renderRemoveSocialHandleButton(item, index)}
            </div>
          )}
        </div>

        {item.focused && suggestions.length > 0 ? (
          <div className="row pr-3" id="social-handle-suggestions">
            <div className="col-sm-3" />
            <div className="col-sm-9">
              <div className="well bg-light form-control social-suggestions-box">
                <div className="container">
                  <div className="row">Is one of these the right {item.network} link for this listing?</div>
                  {suggestions.map((sugg, index) => {
                    const suggestion = new SocialHandlePresenter(sugg);
                    return (
                      <div key={index + '_' + item.key} className="row mt-3">
                        <div className="col-sm-10 pl-0 mt-1">
                          <ExternalLink href={suggestion.url}>{suggestion.displayUrl}</ExternalLink>
                        </div>
                        <div className="col-sm-1 pl-0">
                          <ListButton
                            className="tab"
                            onClick={() => this.handleSocialHandleChange(item, suggestion.url)}
                          >
                            <IconAcceptSuggestion />
                          </ListButton>
                        </div>
                        <div className="col-sm-1 pl-0 mb-1">
                          <ListButton className="last-suggesstion-button" onClick={() => this.hideSuggestion(sugg)}>
                            <IconDeleteSuggestion />
                          </ListButton>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    // Leave index out of check
    const { index, ...editPlace } = this.state.editPlace;
    const { socialHandles, ...edits } = editPlace;
    // We have to perform a separate check to see if any data is set in the social handles array
    const anyFormDataSet = dataExists(edits) || (socialHandles.length > 0 && socialHandles[0].url !== null);
    const params = {
      listing: {
        contact_info: filterChanges(this.props.origPlace, this.state.editPlace, [
          'booking_url',
          'email',
          'website',
          'phone',
          'tollfree'
        ]),
        social_handles: this.getSocialHandles()
      }
    };
    return (
      <div>
        <EditData
          sectionTitle="Contact & Social"
          cy="listing-contact"
          show={this.state.editing}
          cancel={() => this.cancel()}
          params={params}
          userEditingStrategy={this.props.userEditingStrategy}
          onSuccess={() => this.onSuccess()}
        >
          <InputNoneUnknown
            attr="website"
            label="Website"
            labelClassName="editor__label"
            value={this.state.editPlace.website}
            onChange={value => this.handlePlaceEdit('website', value)}
          />
          <flags.BOOKING_URL>
            <InputNoneUnknown
              label="Booking URL"
              labelClassName="editor__label"
              value={this.state.editPlace.booking_url}
              onChange={value => this.handlePlaceEdit('booking_url', value)}
            />
          </flags.BOOKING_URL>
          <InputNoneUnknown
            attr="phone"
            label="Phone number"
            labelClassName="editor__label"
            value={this.state.editPlace.phone}
            onChange={value => this.handlePlaceEdit('phone', value)}
          />
          <InputNoneUnknown
            attr="tollfree"
            label="Toll-free number"
            labelClassName="editor__label"
            value={this.state.editPlace.tollfree}
            onChange={value => this.handlePlaceEdit('tollfree', value)}
          />
          <InputNoneUnknown
            attr="email"
            label="Email address"
            labelClassName="editor__label"
            value={this.state.editPlace.email}
            onChange={value => this.handlePlaceEdit('email', value)}
          />

          {this.state.editPlace.socialHandles.map((item, index) => this.renderSocialHandleEdit(item, index))}
        </EditData>
        <ViewData
          sectionTitle="Contact & Social"
          userEditingStrategy={this.props.userEditingStrategy}
          cy="listing-contact"
          show={!this.state.editing}
          onClick={() => this.setState({ editing: true })}
        >
          {anyFormDataSet ? (
            <div className="editor__social-handles editor__social-handles--view">
              <Link
                label="Website"
                externalLink={edits.website}
                onClick={() => this.analyticsContactInfo(edits.website)}
              >
                {edits.website}
              </Link>
              <flags.BOOKING_URL>
                <Link
                  label="Booking URL"
                  externalLink={edits.booking_url}
                  onClick={() => this.analyticsContactInfo(edits.booking_url)}
                >
                  {edits.booking_url}
                </Link>
              </flags.BOOKING_URL>
              <Link
                label="Phone number"
                internalLink={'tel:' + edits.phone}
                onClick={() => this.analyticsContactInfo(edits.phone)}
              >
                {edits.phone}
              </Link>
              <Link
                label="Toll-free number"
                internalLink={'tel:' + edits.tollfree}
                onClick={() => this.analyticsContactInfo(edits.tollfree)}
              >
                {edits.tollfree}
              </Link>
              <Link
                label="Email address"
                internalLink={'mailto:' + edits.email}
                onClick={() => this.analyticsContactInfo(edits.email)}
              >
                {edits.email}
              </Link>
              {socialHandles.map(item => {
                const socialHandle = new SocialHandlePresenter(item);
                return (
                  (item.url !== null || item.url !== undefined) && (
                    <Link
                      key={'social-handle-' + item.key}
                      label={socialHandle.network}
                      externalLink={socialHandle.url}
                      onClick={() => this.analyticsContactInfo(socialHandle.url)}
                    >
                      {socialHandle.displayUrl}
                    </Link>
                  )
                );
              })}
            </div>
          ) : (
            <React.Fragment>
              <p className="text-muted p-3 m-0">No contact info set for this listing</p>
              <div className="bg-light" style={{ height: '3rem' }} />
            </React.Fragment>
          )}
        </ViewData>
      </div>
    );
  }
}

ContactInfoView.propTypes = {
  origPlace: PropTypes.any,
  socialHandleSuggestions: PropTypes.array,
  analytics: PropTypes.any,
  url: PropTypes.string,
  userEditingStrategy: PropTypes.string
};

export default function ContactInfo(props) {
  const { analytics } = useStoreContext();
  return <ContactInfoView {...props} analytics={analytics} />;
}
