import React from "react";
import moment from "moment";
import GroupEditor from "@library/group-editor";
import TemporaryClosure from "./temporary-closure";
import * as Buttons from "@library/Buttons";
import * as c from "@/listing/hours/constants";

type Props = {
  temporaryClosures: {start_date: string; end_date: string; reason: string}[];
  setTemporaryClosures: (
    temporaryClosures: {
      start_date: string;
      end_date: string;
      reason: string;
    }[]
  ) => void;
};

const TemporaryClosures = (props: Props) => {
  const DEFAULT_CLOSURE = {
    start_date: moment().format(c.EDIT_DATE_FORMAT),
    end_date: moment().format(c.EDIT_DATE_FORMAT),
    reason: "other",
  };
  return (
    <div className="listing-status__temporary-closures">
      <div className="listing-status__temporary-closures__section-header">
        <b>Temporary Closures</b>
        {props.temporaryClosures.length === 0 && (
          <div>
            <Buttons.RoundButton
              btype="add"
              small
              className="m-1"
              onClick={() => props.setTemporaryClosures([{...DEFAULT_CLOSURE}])}
            />
          </div>
        )}
      </div>
      {props.temporaryClosures.length > 0 && (
        <GroupEditor
          allowNone={true}
          getChanges={(v) => props.setTemporaryClosures(v)}
          values={props.temporaryClosures}
          emptyValue={{...DEFAULT_CLOSURE}}
        >
          {({value, setValue}) => {
            return (
              <TemporaryClosure
                startDate={value.start_date}
                endDate={value.end_date}
                reason={value.reason || "other"}
                setStartDate={(d) => setValue({...value, start_date: d})}
                setEndDate={(d) => setValue({...value, end_date: d})}
                setReason={(r) => setValue({...value, reason: r})}
              />
            );
          }}
        </GroupEditor>
      )}
    </div>
  );
};

export default TemporaryClosures;
