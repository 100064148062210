import React from 'react';
import Input from '@/library/input-with-label';
import Select from '@/library/select';
import * as t from './useTime';

import '../styles/_time.scss';

function Time(props){
  const hour = t.useHour(props.hour);
  const minute = t.useMinute(props.minute);
  const [meridiem, setMeridiem] = React.useState(props.meridiem);

  return (
    <div className="editor__time">
      <Input
        label={props.label}
        value={hour.value}
        error={hour.error}
        onBlur={hour.onBlur}
        onChange={hour.onChange}
      />

      <Input
        label={':'}
        value={minute.value}
        error={minute.error}
        onBlur={minute.onBlur}
        onChange={minute.onChange}
      />

      <Select
        value={meridiem}
        onChange={(v) => setMeridiem(v)}
        options={['am', 'pm'].map((n) => ({
          id: n,
          name: n
        }))}
      />
    </div>
  );
}

export default Time;
