import * as React from "react";
import LoadableVisibility from "react-loadable-visibility/react-loadable";

const LoadableComponent = LoadableVisibility({
  loader: () => import("./main"),
  loading: () => null
});

function Loadable(props) {
  return <LoadableComponent {...props} />;
}

export default Loadable;
