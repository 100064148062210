import React from 'react';
import ContactSection from '@/data-outreach/_core/forms/components/contact';

function StorageContact({ show, state, updateField, ...props }){
  return show ? (
    <ContactSection
      label="RV Storage"
      state={state}
      updateField={updateField}
      fields={
        props.fields || [
          ['Phone', 'storage_phone', 'tel', 'phone'],
          ['Email', 'storage_email', 'email', 'phone']
        ]
      }
      title="RV Storage"
    />
  ) : null;
}

export default StorageContact;
