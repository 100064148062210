import React from 'react';
import Indicator from './Indicator';

interface Category {
    id: number;
    name: string;
    children: [];
    public_id: string;
    key: string;
    discoverability: number;
}

export default function Card(category: Category) {

    const [viewChildren, setViewChildren] = React.useState(false);
    const [selected, setSelected] = React.useState(false);

    const handleOnClick = (event) =>{
        event.preventDefault();

        if(category.children.length <= 0){
            return;
        }
        setViewChildren(!viewChildren);
        handleSelected()
    }

    const handleSelected = () =>{
        setSelected(!selected);
    }

    const handleToggle = () => {
        return category.children.length <= 0 ? category.name : !selected ? '+  ' + category.name : '-  ' + category.name;
    }

    return (
        <li className={`category__card ${selected ? 'selected' : ''}`}>
            <div className="btn __row" onClick={handleOnClick}>
                <div className="__name">
                    {handleToggle()}
                </div>
                <Indicator {...category}/>
            </div>
                <ul className="__children">
                    {category.children.length <= 0 ? (
                        ''
                        ):
                        !viewChildren ? (
                            ''
                        ) : category.children.map((child: Category) => {
                            return (
                                <Card {...child} key={child.public_id} />
                            )
                        }) }
                </ul>
        </li>
    );
}