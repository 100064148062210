import React, { PureComponent } from 'react';
import { SinglePathIcon } from './SinglePathIcon';

export class IconWarning extends PureComponent {
  render() {
    return (
      <SinglePathIcon
        {...this.props}
        path="M23.1286386 23H4.87142388c-.33199481 0-.58099092-.1642809-.74673833-.4100847-.1662474-.2458037-.1662474-.5741185 0-.8199223L13.2532929 5.36907621c.3319948-.49210161 1.1614819-.49210161 1.4942267 0L23.8758769 21.769993c.1654975.2458038.1654975.5741186 0 .8199223C23.7093795 22.8357191 23.4608834 23 23.1286386 23zM6.28240184 21.3594142H21.7176607L14.0000312 7.50077543 6.28240184 21.3594142zm7.71762936-4.0998586c-.4979922 0-.829987-.3283148-.829987-.8201694v-4.1003527c0-.4921016.3319948-.8199223.829987-.8199223.4977423 0 .8299871.3278207.8299871.8199223v4.1001057c-.00025.4921016-.3324948.8204164-.8299871.8204164zm0 2.8700987c-.6637396 0-1.2447305-.5741186-1.2447305-1.230007 0-.6556414.5812409-1.230007 1.2447305-1.230007.6634897 0 1.2447306.5741185 1.2447306 1.230007 0 .6556414-.5812409 1.230007-1.2447306 1.230007z"
      />
    );
  }
}

export default IconWarning;
