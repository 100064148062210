import React, { PureComponent } from 'react';
import CheckboxWithLabel from '../library/CheckboxWithLabel';
import PropTypes from 'prop-types';
import HoursSelect from './HoursSelect';
import ListAddButton from '../ListAddButton';
import ListRemoveButton from '../ListRemoveButton';
import cn from 'classnames';

export const OPEN_24_HRS = '24hrs';
export const DEFAULT_TIMESPAN = '00:00-00:00';
export const DAY_MAP = {
  sun_open_close: 'Sunday',
  mon_open_close: 'Monday',
  tue_open_close: 'Tuesday',
  wed_open_close: 'Wednesday',
  thu_open_close: 'Thursday',
  fri_open_close: 'Friday',
  sat_open_close: 'Saturday'
};

export default class DailyHoursView extends PureComponent {
  // This is the only place we call the props onChange method.
  setHours = hours => this.props.onChange(this.props.day, hours);

  handleOpenToday = open => {
    if (open) {
      this.setHours([DEFAULT_TIMESPAN]);
    } else {
      // Not open, return an empty array.
      this.setHours([]);
    }
  };

  update24Hours = open24hrs => {
    if (open24hrs) {
      // returns string rep of 24 hours in array
      this.setHours([OPEN_24_HRS]);
    } else {
      this.setHours([DEFAULT_TIMESPAN]);
    }
  };

  // Returns a copy of an array replacing the value at a specified index.
  replaceAtIndex = (arr, index, value) => [...arr.slice(0, index), value, ...arr.slice(index + 1, arr.length)];

  removeAtIndex = (arr, index) => [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];

  // If we are updating a timespan, we create a copy of the hours array and
  // replace timespan at the specified index.
  updateHours = (idx, timespan) => {
    const { hours } = this.props;
    const newHours = hours.length === 1 ? [timespan] : this.replaceAtIndex(hours, idx, timespan);
    this.setHours(newHours);
  };

  // Appends a default timespan to copy of props hours array.
  addTimespan = () => this.setHours([...this.props.hours, DEFAULT_TIMESPAN]);

  removeTimespan = idx => {
    if (this.props.hours.length > 1) {
      const newHours = this.removeAtIndex(this.props.hours, idx);
      this.setHours(newHours);
    }
  };

  render() {
    const { editing, hours, day } = this.props;
    const today = DAY_MAP[day];
    const open = hours.length > 0;
    const open24hrs = hours[0] === OPEN_24_HRS;
    return (
      <div
        data-cy="daily-hours"
        className={cn(
          'hours__daily__container',
          { 'hours_daily_container--view': !editing },
          { 'hours_daily_container--edit': editing },
          'row'
        )}
      >
        <OpenToday open={open} day={today} editing={editing} onChange={() => this.handleOpenToday(!open)} />
        <Open24Hours
          open24hrs={open24hrs}
          editing={editing}
          onChange={() => this.update24Hours(!open24hrs)}
          open={open}
        />
        <div
          className={cn('hours__daily__timespan-group', {
            'hours__daily__timespan-group--edit': editing
          })}
        >
          {open &&
            !open24hrs &&
            // construct timespans
            hours.map((str, idx) => {
              let [start, end] = str.split('-');
              return (
                <div
                  className={cn('hours__daily__timespan', {
                    'hours__daily__timespan--edit': editing
                  })}
                  key={idx + '-timespan-' + today}
                >
                  <HoursSelect
                    editing={editing}
                    openHour={start}
                    closeHour={end}
                    updateOpenHour={hour => this.updateHours(idx, hour + '-' + end)}
                    updateCloseHour={hour => this.updateHours(idx, start + '-' + hour)}
                  />
                  <div className="hours__daily__timespan--edit add-remove-buttons">
                    {// We only allow removal of a timespan if there is more than one.
                    editing && hours.length >= 2 && <ListRemoveButton onClick={() => this.removeTimespan(idx)} />}
                    {// Add button should only be visible for last timespan.
                    editing && idx === hours.length - 1 && <ListAddButton onClick={this.addTimespan} />}
                  </div>
                </div>
              );
            })}
        </div>
        {editing && today !== 'Saturday' && <div className="hours__daily__divider" />}
      </div>
    );
  }
}

DailyHoursView.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.string),
  editing: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  day: PropTypes.string.isRequired
};

// local child components
const DisplayMessage = ({ show, msg }) => (show ? <div className={''}>{msg}</div> : null);

DisplayMessage.propTypes = {
  show: PropTypes.bool,
  msg: PropTypes.string
};

const OpenToday = ({ editing, open, onChange, day }) => (
  <React.Fragment>
    <div
      data-cy="open-today"
      onClick={onChange}
      className={cn({
        hours__daily__checkbox: editing,
        'hours__daily__text hours__daily__text--justify-start': !editing,
        'hours__daily__text--edit': editing
      })}
    >
      <CheckboxWithLabel
        showCheckbox={editing}
        showLabel={true}
        checked={open}
        inputClassName="mt-1"
        onChange={onChange}
        label={day}
      />
    </div>
    <DisplayMessage show={!editing && !open} msg="Closed" />
  </React.Fragment>
);

OpenToday.propTypes = {
  editing: PropTypes.bool.isRequired,
  day: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func
};

const Open24Hours = ({ editing, open, open24hrs, onChange }) => {
  if (!open) {
    // If not open, doesn't show checkbox or display message.
    return null;
  }
  // If open and editing, show checkbox. If not editing and open
  // for 24 hours, show message.
  return (
    <React.Fragment>
      {editing && open ? (
        <div data-cy="open-twenty-four" className="hours__daily hours__daily__checkbox">
          <CheckboxWithLabel
            showCheckbox={true}
            showLabel={true}
            checked={open24hrs}
            onChange={onChange}
            inputClassName="mt-1"
            label="Open 24 hours"
          />
        </div>
      ) : null}
      <DisplayMessage show={!editing && open && open24hrs} msg="24 Hours" />
    </React.Fragment>
  );
};

Open24Hours.propTypes = {
  editing: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  open24hrs: PropTypes.bool.isRequired,
  onChange: PropTypes.func
};
