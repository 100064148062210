// Interfaces =========================
export interface Props {
  canonical_place_merge_count: number;
  id: number;
  import_id: number;
  num_canonicals: number;
  new_canonical_place_count: number;
  new_match_count: number;
  old_match_count: number;
  status: 'ready for review' | 'reviewing' | 'reviewed' | 'merging' | 'merged';
  type: string;
  message?: {
    error: boolean;
    details: string;
  };
}

// Constants ==========================
export const Defaults = {
  idUnavailable: '0000000',
  nameUnavailable: 'Not found',
  countUnavailable: '-',
  statusUnavailable: 'Unavailable',
}

export const Errors = {
  matchReviewUnavailable: 'Unable to review this match set at this time.',
  matchRunUnavailable: 'Unable to return this match run at this time.',
  matchReviewNotFound: 'No match run found associated with run id:',
}