export default function(addressObj) {
  const {address1, address2, city, state, postal_code} = addressObj;

  let address = [address1, address2, city, state].filter(a => a && a.length > 0).join(', ');

  if (postal_code && postal_code.length > 0) {
    address += (' ' + postal_code);
  }

  return address;
};
