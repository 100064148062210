import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconDelete extends React.Component {
  render() {
    var props = $.extend({}, this.props, {
      path: "M21.3043478 8.38674033c.3841981 0 .6956522.29682779.6956522.66298343 0 .36615563-.3114541.66298342-.6956522.66298342h-1.4144927v9.85635362C19.8898551 20.9116314 18.7478568 22 17.3391304 22h-6.6782608c-1.40872635 0-2.55072467-1.0883686-2.55072467-2.4309392V9.71270718H6.69565217C6.31145409 9.71270718 6 9.41587939 6 9.04972376c0-.36615564.31145409-.66298343.69565217-.66298343h4.35942033v-.839779C11.0550725 6.69259818 11.7817987 6 12.6782609 6h2.6434782c.8964622 0 1.6231884.69259818 1.6231884 1.54696133v.839779h4.3594203zm-8.857971-.839779v.839779h3.1072464v-.839779c0-.12205188-.103818-.22099448-.2318841-.22099448h-2.6434782c-.1280661 0-.2318841.0989426-.2318841.22099448zm6.0289855 12.04419887l.0231884-9.87845302H9.50144928v9.87845302c0 .6102594.51909012 1.1049724 1.15942032 1.1049724h6.6550724c.6403302 0 1.1594203-.494713 1.1594203-1.1049724zm-2.7362319-.5082873c-.3841981 0-.6956521-.2968278-.6956521-.6629834v-6.6077348c0-.3661556.311454-.6629834.6956521-.6629834s.6956522.2968278.6956522.6629834v6.6077348c0 .3661556-.3114541.6629834-.6956522.6629834zm-3.4782608 0c-.3841981 0-.6956522-.2968278-.6956522-.6629834v-6.6077348c0-.3661556.3114541-.6629834.6956522-.6629834.3841981 0 .6956521.2968278.6956521.6629834v6.6077348c0 .3661556-.311454.6629834-.6956521.6629834z"
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}

export class IconDeleteMedia extends React.Component {

  render() {
    var props = $.extend({}, this.props, {
      title: 'Delete',
      className: 'delete-media',
    })
    return (
      <IconDelete {...props}/>
    );
  }
}

export class IconDeleteMediaHover extends React.Component {
  render() {
    const childProps = $.extend({}, this.props, {fillColor: "#c50800"});
    return <IconDeleteMedia {...childProps} />;
  }
}

export class IconDeleteSuggestion extends React.Component {
  render() {
    const childProps = $.extend({}, this.props, {
      fillColor: '#000',
      width: '16',
      height: '16',
      viewBoxHeight: '24',
      viewBoxWidth: '24',
      viewBoxX: '0',
      viewBoxY: '0',
      className: "delete-suggestion"
    });
    return <IconDelete {...childProps} />;
  }
}
