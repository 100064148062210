import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Marker, Map } from '@commodityvectors/react-mapbox-gl';
import { BOTTOM_INSET, RIGHT_INSET } from './constants';
import * as util from './util';

//  NOTE: "show in map" portal anchor <div id={'show-in-map-anchor-' + this.props.id} />
//   <ShowInMap
//    onMouseEnter={this.props.addFocus}
//    onMouseLeave={this.props.removeFocus}
//    id={this.props.id}
//    onClick={() => this.onClick()}
//   />
export const ShowInMap = ({ id, onClick, ...props }) =>
  ReactDOM.createPortal(
    <div onClick={() => onClick()} {...props} />,
    document.getElementById('show-in-map-anchor-' + id)
  );

ShowInMap.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
};

export class CustomMarker extends Component {
  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render = () => {
    const show = util.visibleInMap(this.props.map, this.props.position, 0, BOTTOM_INSET, 0, RIGHT_INSET);
    return show ? (
      <div id={this.props.id} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
        <Marker {...this.props} map={this.props.map} onClick={() => this.onClick()}>
          {this.props.children}
        </Marker>
      </div>
    ) : null;
  };
}

CustomMarker.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  map: PropTypes.any.isRequired,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default Map.component(CustomMarker);
