// Our values are numbers so we have to make sure 0 isn't evaluated as false
export const truthy = (v: number): boolean => Boolean(v || v === 0);
// Don't allow float to be cast as int
export const enforceNum = (v: string, float: boolean): number => {
  const re = /^-?\d*\.?\d+$/;
  if (re.test(v)) {
    return (float ? parseFloat(v) : parseInt(v))
  } else {
    console.debug('enforceNum expects v to be a numeric string, instead it is: ', v)
  }
  return 0;
};
