import AccountFlyOut from '@/users/account-fly-out/component';
import React from 'react';
import { Account } from '../../common/types';
import './style.scss';

interface Props {
  account: Account;
}

export const Header = ({ ...props }: Props) => {
  return (
    <div className="header">
      <div className="header__start">

      </div>
      <div className="header__center">

      </div>
      <div className="header__end">
        <div className="__option">

        </div>
        <div className="__option">
          <AccountFlyOut {...props.account} />
        </div>
      </div>
    </div>
  )
}
