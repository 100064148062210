import React from 'react';

type Props = {
  code?: any;
  children?: any;
  description?: string;
  className?: string;
};

export default function Scenario({ description, children, className }: Props) {
  return (
    <>
      {children}
    </>
  );
}
