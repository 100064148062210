import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DatePicker from '@library/DatePicker';
import moment from 'moment';
import { WhiteButton } from '@/library/Buttons';
import * as c from '@/listing/hours/constants';

class ClosureDatePicker extends PureComponent {
  render() {
    return (
      <DatePicker
        id={this.props.id}
        className="form-control"
        focused={this.props.focused}
        onFocusChange={this.props.onFocusChange}
        minimum={this.props.minimum}
        date={this.props.date ? moment(this.props.date) : null}
        handleChange={date =>
          this.props.handleChange(this.props.id, date ? moment(date).format(c.EDIT_DATE_FORMAT) : null)
        }
        keepOpen={false}
        ownerControlled={true}
        calendarInfoPosition="after"
        placeholder={this.props.indefinitelyPreset ? 'Indefinitely' : 'Date'}
        renderCalendarInfo={
          this.props.indefinitelyPreset
            ? () => (
                <div className="listing-status__temporary-closures__single-closure__dates__indefinite-preset__container">
                  <hr />
                  <WhiteButton
                    cy="dp-indef"
                    className="listing-status__temporary-closures__single-closure__dates__indefinite-preset__button"
                    onClick={() => this.props.handleChange(this.props.id, null)}
                  >
                    Indefinitely
                  </WhiteButton>
                </div>
              )
            : null
        }
      />
    );
  }
}

ClosureDatePicker.defaultProps = {
  indefinitelyPreset: false
};

ClosureDatePicker.propTypes = {
  id: PropTypes.string,
  focused: PropTypes.bool,
  date: PropTypes.string,
  minimum: PropTypes.string,
  handleChange: PropTypes.func,
  onFocusChange: PropTypes.func,
  indefinitelyPreset: PropTypes.bool
};

export default ClosureDatePicker;
