import React from 'react';

type Props = {
  mirrorChild: boolean;
  public_id: string;
  parent_public_id?: string;
  hasChildren: boolean;
  childAssigned: boolean;
  selection: AssignmentMap;
  update: UpdateAssignmentMap;
  assignable?: boolean;
};

function useMirrorChild({
  mirrorChild,
  public_id,
  update,
  childAssigned,
  assignable,
  hasChildren,
  selection
}: Props){
  const [state, setState] = React.useState({
    showTooltip: false,
    fauxAssign: false,
    disabled: false
  });

  React.useEffect(
    () => {
      if (mirrorChild && hasChildren) {
        if (!selection[public_id]) {
          update(public_id, false);
        }
        setState({
          showTooltip: !assignable && !selection[public_id],
          fauxAssign: hasChildren && childAssigned,
          disabled: (!assignable && !selection[public_id]) || childAssigned
        });
      }
    },
    [childAssigned]
  );

  return state;
}

export default useMirrorChild;
