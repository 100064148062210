import Time from '../time';
import BooleanRow from '@/library/boolean-row/edit';

import React, { useState, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

import '../styles/index';

function TransitionManager({ options, idx }){
  const [index, set] = useState(idx);
  const next = useCallback(() => set((state) => (state + 1) % 3), []);
  const back = useCallback(() => set((state) => (state + 2) % 3), []);

  const transitions = useTransition(index, (p) => p, {
    from: { opacity: 1, transform: 'translate3d(0,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' }
  });
  return (
    <div className="editor__schedule__item__transition">
      {transitions.map(({ item, props, key }) => {
        const Option = options[item];
        return <Option key={key} style={props} />;
      })}
      {/* <FontAwesomeIcon icon={faChevronRight} onClick={next} /> */}
    </div>
  );
}

function Day(props){
  const [open, setOpen] = React.useState(
    props.schedule.length > 0 && props.schedule[0]
  );
  const [allHrs, setAllHrs] = React.useState(
    props.schedule.indexOf('00:00-00:00') !== -1 ||
      props.schedule.indexOf('24hrs') !== -1
  );

  const Closed = ({ style }) => (
    <animated.div style={{ ...style }}>Closed</animated.div>
  );

  const Open24 = ({ style }) => (
    <animated.div style={{ ...style }}>Open 24 Hours</animated.div>
  );

  const TimeSpan = ({ value }) => {
    const [start, end] = value.split('-');
    if (!start || !end) return null;
    const [startHour, startMinutes] = start.split(':');
    const [endHour, endMinutes] = end.split(':');

    const startPm = parseInt(startHour) > 12;
    const endPm = parseInt(endHour) > 12;
    return (
      <div className="editor__time-span">
        <Time
          meridiem={startPm ? 'pm' : 'am'}
          label={''}
          onChange={(value) => {
            return value + '-' + end;
          }}
          hour={startPm ? startHour - 12 : startHour}
          minute={startMinutes}
        />
        <Time
          meridiem={endPm ? 'pm' : 'am'}
          label={'-'}
          onChange={(value) => {
            return start + '-' + value;
          }}
          hour={endPm ? endHour - 12 : endHour}
          minute={endMinutes}
        />
      </div>
    );
  };
  const Hours = ({ style }) => (
    <animated.div style={{ ...style }}>
      {props.schedule.map(
        (ts) =>
          ts === 'n' ? (
            <TimeSpan value={'09:00-17:00'} />
          ) : (
            <TimeSpan key={ts} value={ts} />
          )
      )}
    </animated.div>
  );

  if (props.schedule[0] === 'n') {
    return <TransitionManager options={[Closed, Open24, Hours]} idx={2} />;
  }
  if (open && allHrs) {
    return <TransitionManager options={[Closed, Open24, Hours]} idx={1} />;
  } else if (open) {
    return <TransitionManager options={[Closed, Open24, Hours]} idx={2} />;
  }
  return <TransitionManager options={[Closed, Open24, Hours]} idx={0} />;
}

export default Day;
