import React from 'react';
import './styles';
import ContactSection from '@/data-outreach/_core/forms/components/contact';
import type { UpdateField, State } from '../../typings';

const fields = {
  phone: ['Phone', 'internal_phone', 'tel', 'phone'],
  email: ['Email', 'internal_email', 'email', 'email'],
  website: ['Website', 'internal_website', 'url', 'website'],
  first_name: ['First Name', 'internal_first_name', 'text', 'first-name'],
  last_name: ['Last Name', 'internal_last_name', 'text', 'last-name'],
  job_title: ['Job Title', 'internal_job_title', 'text', 'job-title']
};

type Props = {
  state: State;
  updateField: UpdateField;
}

export function Component({ state, updateField }: Props){
  return (
    <div className="editor__data-outreach__rv-service__contacts editor__data-outreach__rv-service__contacts--internal">
      <ContactSection
        title=""
        state={state}
        updateField={updateField}
        fields={[
          fields.first_name,
          fields.last_name,
          fields.job_title,
          fields.phone,
          fields.email
        ]}
      />
    </div>
  );
}

export default Component;
