import React, {memo, useState} from "react";
import {useSpring, animated as a} from "react-spring";
import {useMeasure, usePrevious} from "./helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
  expandIcon: any;
  collapseIcon: any;
  children?: any;
  name?: any;
  className?: string;
  style?: any;
  defaultOpen: boolean;
};

const Tree = memo(
  ({
    expandIcon,
    collapseIcon,
    children,
    name,
    className,
    style,
    defaultOpen = false,
  }: Props) => {
    const [isOpen, setOpen] = useState(defaultOpen);
    const previous = usePrevious(isOpen);
    // @ts-ignore
    const [bind, {height: viewHeight}] = useMeasure();
    // @ts-ignore
    const {height, opacity, transform} = useSpring({
      from: {height: 0, opacity: 0, transform: "translate3d(20px,0,0)"},
      to: {
        height: isOpen ? viewHeight : 0,
        opacity: isOpen ? 1 : 0,
        transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
      },
    });

    const icon =
      collapseIcon && expandIcon && (isOpen ? collapseIcon : expandIcon);

    return (
      <div className={className}>
        <div className="editor__tree-value">
          <div
            className="editor__tree-name"
            style={style}
            onClick={(e) => {
              e.stopPropagation();
              setOpen((prev) => !prev);
            }}
          >
            {name}
          </div>

          {icon && (
            <div className="editor__tree-icon">
              <FontAwesomeIcon
                icon={icon}
                style={{
                  ...{
                    width: "1em",
                    height: "1em",
                    marginLeft: "0.25em",
                    cursor: "pointer",
                    verticalAlign: "middle",
                  },
                  opacity: children ? 1 : 0.3,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen((prev) => !prev);
                }}
              />
            </div>
          )}
        </div>
        <a.div
          className="editor__tree-children"
          style={{
            position: "relative",
            opacity,
            height: isOpen && previous === isOpen ? "auto" : height,
            zIndex: isOpen ? "inherit" : -1,
          }}
        >
          <a.div style={{transform}} {...bind} children={children} />
        </a.div>
      </div>
    );
  }
);

export default Tree;
