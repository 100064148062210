import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconFeaturedImageBadge extends React.Component {
  render() {
    return (
      <div className="featured-image-badge">
        <span className="rounded diff-diff-label small">
          <IconFeaturedMedia height="14" width="14" viewBoxX="4" viewBoxY="-2" viewBoxWidth="24" viewBoxHeight="24"
                             fillColor="#FFF"/> Featured
        </span>
      </div>
    )
  }
}

export class IconFeaturedMedia extends React.Component {
  render() {
    var props = $.extend({}, this.props, {
      title: 'Featured',
      className: 'featured-media',
      path: "M5.23654661 12.0196432c-.40387814-.390038-.27773032-.7754036.28039687-.8564584l4.47999113-.6490476c.55812719-.0808516 1.22086479-.55600723 1.47295529-1.05574339l2.0630809-4.08199127c.249424-.50115818.6592506-.50197076.9105207-.0018283l2.0866695 4.08117868c.2541417.49872045.9187254.97265718 1.4768525 1.05330568l4.4756837.649657c.5581272.0812579.6846852.467233.2812173.8576773l-3.2749205 3.2107033c-.4010064.392882-.6506355 1.164629-.5546401 1.7151514l.769194 4.4116952c.0959954.5505224-.2350657.7896238-.7351444.5312237l-4.1097524-2.086297c-.5015145-.2555561-1.3203472-.2512901-1.8191951.0091415l-3.99611686 2.086297c-.49925821.2604316-.83072955.0227522-.73699044-.5281765l.74540029-4.5014852c.09168793-.5511318-.1638896-1.3208474-.5683831-1.7104791l-3.24681928-3.134524z"
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}

export class IconFeaturedMediaHover extends React.Component {
  render() {
    const childProps = $.extend({}, this.props, {fillColor: "#EFA036"});
    return <IconFeaturedMedia {...childProps} />;
  }
}

export class IconUnfeaturedMedia extends React.Component {

  render() {
    var props = $.extend({}, this.props, {
      title: 'Not featured',
      className: 'unfeatured-media',
      path: "M7.20421155 12.1817887l2.14634436 2.0721111c.70061959.6748776 1.09362539 1.8578598.93440019 2.8149531l-.50157891 3.0289929 2.67758651-1.3979172c.8574763-.4476593 2.1033213-.4539912 2.9635113-.0156636l2.7918592 1.417299-.5133996-2.944592c-.1667811-.9564699.2167108-2.1422734.9109706-2.8224673l2.1982969-2.1551662-2.9839549-.4331289c-.9591676-.1385981-1.972885-.8615673-2.4110484-1.7214092l-1.4246794-2.78648269-1.4065879 2.78306019c-.4361517.8646132-1.4490439 1.5906706-2.4097718 1.729844l-2.97194815.4305668zm14.69043605-1.063658a.41716456.41716456 0 0 0-.0038688.0037929l.0038688-.0037929zm-8.5681172-5.18239864a.41199359.41199359 0 0 0 .002642.00516731l-.002642-.00516731zM5.23654661 12.0196432c-.40387814-.390038-.27773032-.7754036.28039687-.8564584l4.47999113-.6490476c.55812719-.0808516 1.22086479-.55600723 1.47295529-1.05574339l2.0630809-4.08199127c.249424-.50115818.6592506-.50197076.9105207-.0018283l2.0866695 4.08117868c.2541417.49872045.9187254.97265718 1.4768525 1.05330568l4.4756837.649657c.5581272.0812579.6846852.467233.2812173.8576773l-3.2749205 3.2107033c-.4010064.392882-.6506355 1.164629-.5546401 1.7151514l.769194 4.4116952c.0959954.5505224-.2350657.7896238-.7351444.5312237l-4.1097524-2.086297c-.5015145-.2555561-1.3203472-.2512901-1.8191951.0091415l-3.99611686 2.086297c-.49925821.2604316-.83072955.0227522-.73699044-.5281765l.74540029-4.5014852c.09168793-.5511318-.1638896-1.3208474-.5683831-1.7104791l-3.24681928-3.134524z"
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}

export class IconUnfeaturedMediaHover extends React.Component {
  render() {
    const childProps = $.extend({}, this.props, {fillColor: "#EFA036"});
    return <IconUnfeaturedMedia {...childProps} />;
  }
}

