import parseQueryString from '@/helpers/parseQueryString';

interface QueryParams {
  code?: string;
  id_token?: string;
  nonce?: string;
  session_state?: string;
  state?: string;
  resent?: string;
}

function getUrlParamOrFragmentValue(url: string, name: string): string {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\#?&]' + name + '=([^&#]*)');
  const results = regex.exec(url);
  let val = '';
  if (results !== null) {
    val = decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  return val;
}

function buildResendQueryParams(): QueryParams {
  const thisUrl = window.location.href;
  const params = parseQueryString() as QueryParams;
  const desiredFragmentParams = ['code', 'id_token', 'nonce', 'session_state', 'state'];

  for (let i = 0; i < desiredFragmentParams.length; ++i) {
    const fragmentParamVal = getUrlParamOrFragmentValue(thisUrl, desiredFragmentParams[i]);
    if (fragmentParamVal) {
      params[desiredFragmentParams[i]] = fragmentParamVal;
    }
  }

  params['resent'] = 'y';

  return params;
}

function buildResendUrl(): string {
  const params = buildResendQueryParams();

  // Build the URL to resend.
  let resendUrl = location.protocol + '//' + location.host + location.pathname + '?';
  const queryStr = Object.keys(params)
    .map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
    })
    .join('&');
  resendUrl += queryStr;

  return resendUrl;
}

function resendCallback() {
  window.location.href = buildResendUrl();
}

export default resendCallback;
