import React from 'react';
import { SinglePathIcon } from './SinglePathIcon';

export class IconShowMedia extends React.Component {
  render() {
    const props = {
      ...this.props,
      path:
        'M20.34 12.43l1.8-1.73a.5.5 0 0 1 .73.04c.19.22.17.56-.04.75l-1.69 1.63a17.49 17.49 0 0 1 1.67 1.74c.25.33.25.8 0 1.13a17.26 17.26 0 0 1-2.53 2.49C18.3 20.05 16.17 21 14 21c-2.18 0-4.3-.95-6.27-2.52a17.26 17.26 0 0 1-2.54-2.49.93.93 0 0 1 0-1.13 17.48 17.48 0 0 1 1.72-1.8l-1.43-1.6a.55.55 0 0 1 .02-.75c.2-.2.53-.2.72.02l1.49 1.66a16.38 16.38 0 0 1 1.4-1l-1-2.2a.55.55 0 0 1 .24-.71.5.5 0 0 1 .68.25L10 10.9c.42-.22.85-.4 1.28-.55L10.79 8a.54.54 0 0 1 .38-.64.5.5 0 0 1 .61.4l.5 2.29c.4-.1.8-.16 1.21-.18V7.53c0-.3.23-.53.51-.53s.5.24.5.53v2.35c.42.02.82.08 1.23.18l.49-2.29a.5.5 0 0 1 .61-.4c.27.06.45.35.38.64l-.5 2.33c.43.15.86.33 1.28.55l.98-2.16a.5.5 0 0 1 .68-.25c.26.13.36.44.24.71l-1 2.2a14.9 14.9 0 0 1 1.45 1.04zm.57 2.9a15.54 15.54 0 0 0-1.66-1.53c-1.7-1.35-3.5-2.16-5.25-2.16-1.76 0-3.54.8-5.25 2.16A15.54 15.54 0 0 0 7 15.43a15.01 15.01 0 0 0 1.75 1.63c1.7 1.35 3.5 2.16 5.25 2.16s3.54-.8 5.25-2.16A15.55 15.55 0 0 0 21 15.43a15 15 0 0 0-.1-.1zm-4.8-1.48a2.85 2.85 0 0 1-.56 3.77c-1.11.9-2.71.71-3.61-.43a2.85 2.85 0 0 1 .26-3.8 2.54 2.54 0 0 1 3.64.12.7.7 0 0 0-.94.13.79.79 0 0 0-.02.99.7.7 0 0 0 .94.16c.3-.2.43-.6.29-.94z'
    };
    return <SinglePathIcon {...props} />;
  }
}

export class IconShowMediaHover extends React.Component {
  render() {
    const props = {
      ...this.props,
      fillColor: '#EFA036'
    };
    return <IconShowMedia {...props} />;
  }
}
