
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import Diff from './index';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div>
  <Diff changed_at={'2020-11-01T19:25:17Z'} field='test_hella_long_field_name' old_value="old" new_value='new' author="Boone, Kayla" mdxType="Diff" />
  <Diff changed_at={'2020-11-01T19:25:17Z'} field='accepts_credit_cards' old_value="old" new_value='new' author="Boone, Kayla" mdxType="Diff" />
  <Diff changed_at={'2020-10-01T18:25:17Z'} field='test_hella_long_field_name' old_value="old" new_value='new' author="Boone, Kayla" mdxType="Diff" />
    </div>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;