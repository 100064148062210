/**
 * @todo
 * - Use DataSection instead of it's sub components.
 * - Split up edit, view, and index into separate files.
 * - Move LocationView to it's own file.
 */
import React from 'react';
import PropTypes from 'prop-types';
import LocationMapView from './LocationMap';
import * as ajax from '@/helpers/ajax';
import deepClone from '../../helpers/DeepClone';
import ViewField from '../../library/view-field';
import InputNoneUnknown from '../../library/input-none-unknown';
import dataExists from '../../helpers/dataExists';
import InputWithLabel from '../../library/input-with-label';
import { ViewData, Edit } from '../../library/data-section';
import update from 'immutability-helper';
import { useStoreContext } from '../../state';
import BooleanRow from '../../library/boolean-row/edit';
import BooleanRowView from '../../library/boolean-row/view';
import { Button } from '@/library/Buttons';

/**
 * View/Edit a single location
 *
 * One of these per location. Handles its own state.
 */
class LocationView extends React.Component {
  constructor(props) {
    super(props);
    // set initial state, including default values if anything is null/missing (otherwise React complains about
    // controlled vs uncontrolled inputs)
    this.state = this.getInitialState();
  }

  getInitialState() {
    const latitudeError = this.validateLatitude(this.props.latitude);
    let latitudeClean = latitudeError.length === 0 ? this.props.latitude : 0;

    const longitudeError = this.validateLongitude(this.props.longitude);
    let longitudeClean = longitudeError.length === 0 ? this.props.longitude : 0;

    return {
      editing: this.props.editing || false,
      saving: false,
      errors: {},
      editLocation: {
        index: this.props.index,
        id: this.props.id,
        address1: this.props.address1,
        address2: this.props.address2,
        city: this.props.city,
        state: this.props.state,
        postal_code: this.props.postal_code,
        country: this.props.country,
        elevation: this.props.elevation,
        latitude: {
          raw: this.props.latitude,
          sanitized: latitudeClean,
        },
        longitude: {
          raw: this.props.longitude,
          sanitized: longitudeClean,
        },
        address_approximate: this.props.address_approximate,
        navigable: this.props.navigable,
      },
      isSatellite: this.props.view || false
    };
  }

  hasCoordinates() {
    return (
      this.state.editLocation.latitude.raw !== undefined &&
      this.state.editLocation.latitude.raw !== null &&
      this.state.editLocation.longitude.raw !== undefined &&
      this.state.editLocation.longitude.raw !== null
    );
  }

  confirmRemoval(index) {
    const confirmationId = '#delete-location-confirmation';
    $(confirmationId).modal('show');

    const deleteConfirmationButtonId = '#confirm-delete-location-button';
    const confirmDeleteButton = $(deleteConfirmationButtonId);
    confirmDeleteButton.off('click');

    confirmDeleteButton.on('click', () => {
      $(confirmationId).modal('hide');
      this.props.removeLocation(index);
    });
  }

  // update state when the user enters some text
  handleChange = (e) => {
    let editLocation = deepClone(this.state.editLocation);
    editLocation[e.target.id] = e.target.value;
    this.setState({ editLocation: editLocation });
  };

  validateLatitude = (val) => {
    let error = '';
    if (val == null || isNaN(val) || val === '') {
      error = 'Please enter latitude values in decimal degrees';
    } else if (val > 90 || val < -90) {
      error = 'Please enter a value between -90 and 90';
    }
    return error;
  };

  validateLongitude = (val) => {
    let error = '';
    if (val == null || isNaN(val) || val === '') {
      error = 'Please enter longitude values in decimal degrees';
    } else if (val > 180 || val < -180) {
      error = 'Please enter a value between -180 and 180';
    }
    return error;
  };

  handleCoordinateEdit = (id, value, validate) => {
    const error = validate(value);
    this.setState((prevState) => ({
      editLocation: {
        ...prevState.editLocation,
        [id]: {
          raw: value,
          sanitized: error.length === 0 ? value : prevState.editLocation[id].sanitized,
        },
      },
      errors: {
        ...prevState.errors,
        [id]: error,
      },
    }));
  };

  handleLocationEdit = (id, value) => {
    this.setState((prevState) => ({
      editLocation: {
        ...prevState.editLocation,
        [id]: value,
      },
    }));
  };

  cancel = () => {
    let state = this.getInitialState();
    state.editing = false;
    if (this.state.savedLocation) {
      state.savedLocation = this.state.savedLocation;
      state.editLocation = deepClone(this.state.savedLocation);
    }
    this.setState(state);
  };

  saveCompleted = () => {
    this.setState({ editing: false, saving: false, savedLocation: deepClone(this.state.editLocation) });
  };

  // make api call to update listing
  save = () => {
    this.setState({ saving: true });

    if (this.anylocationDataSet()) {
      const location = {
        id: this.state.editLocation.id,
        address1: this.state.editLocation.address1,
        address2: this.state.editLocation.address2,
        city: this.state.editLocation.city,
        state: this.state.editLocation.state,
        postal_code: this.state.editLocation.postal_code,
        country: this.state.editLocation.country,
        elevation: this.state.editLocation.elevation,
        address_approximate: this.state.editLocation.address_approximate,
        navigable: this.state.editLocation.navigable,
      };

      if (this.hasCoordinates()) {
        location.latitude = this.state.editLocation.latitude.sanitized;
        location.longitude = this.state.editLocation.longitude.sanitized;
      }

      this.props.saveLocation(this.props.index, location, this.saveCompleted);
    } else {
      this.saveCompleted();
    }
  };

  onMapLocationChange = (_view, point) => {
    this.handleCoordinateEdit('latitude', point.latitude, this.validateLatitude);
    this.handleCoordinateEdit('longitude', point.longitude, this.validateLongitude);
  };

  _geocodeAddress() {
    let params = { access_token: window._env_.MAPBOX_KEY };
    let query = `${this.state.editLocation.address1} ${this.state.editLocation.address2} ${this.state.editLocation.city}, ${this.state.editLocation.state} ${this.state.editLocation.postal_code}`;
    let queryStr = $.param(params);
    let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?${queryStr}`;

    ajax.get(url).then((response) => {
      if (response.ok) {
        response.json().then((val) => {
          if (val.features && val.features[0]) {
            let loc = val.features[0].center;
            this.handleCoordinateEdit('latitude', loc[1], this.validateLatitude);
            this.handleCoordinateEdit('longitude', loc[0], this.validateLongitude);
            this.setState({ geocodeError: null });
          } else {
            this.setState({ geocodeError: 'Cannot geocode address' });
          }
        });
      }
    });
  }

  anylocationDataSet() {
    const { index, latitude, longitude, ...edits } = this.state.editLocation;
    return dataExists(edits) || this.hasCoordinates();
  }

  toggleSatellite = () => {
    this.setState({ isSatellite: !this.state.isSatellite })
  }

  render() {
    const anyLocationDataSet = this.anylocationDataSet();
    const { index, ...edits } = this.state.editLocation;

    return (
      <>
        <Edit
          userEditingStrategy={this.props.userEditingStrategy}
          show={this.state.editing}
          cancel={(e) => this.cancel(e)}
          saving={this.state.saving}
          persistChanges={(e) => this.save(e)}
        >
          <>
            <div className="map-container">
              <LocationMapView
                latitude={edits.latitude.sanitized}
                longitude={edits.longitude.sanitized}
                zoom={[14]}
                isSatellite={this.state.isSatellite}
                editing={this.state.editing}
                onMapLocationChange={this.onMapLocationChange.bind(this)}
              />
            </div>
            <div className="boone-form container-fluid" field="location">
            <div className="map-options">
                  <Button
                    type="button"
                    className="editor__button--white"
                    onClick={this.toggleSatellite}>
                    {this.state.isSatellite ? 'Streets View' : 'Satellite View'}
                  </Button>
                  <Button
                    type="button"
                    className="editor__button--white" onClick={() => this._geocodeAddress()}>
                    Geocode address
                  </Button>
                  {this.props.canRemove && (
                    <Button
                      type="button"
                      className="editor__button--white"
                      onClick={() => this.confirmRemoval(index)}
                    >
                      Remove this location
                    </Button>
                  )}
                </div>
              <div className="row pr-4 pt-4">
              </div>
            </div>
            <div
              data-cy="listing-basic-info-location-edit-form"
              aria-roledescription="form"
              className="boone-form container-fluid"
            >
              <React.Fragment>
                <InputNoneUnknown
                  label="Address"
                  labelClassName="editor__label"
                  value={edits.address1}
                  onChange={(value) => this.handleLocationEdit('address1', value)}
                />

                <InputNoneUnknown
                  label="Suite, Unit, etc."
                  labelClassName="editor__label"
                  value={edits.address2}
                  onChange={(value) => this.handleLocationEdit('address2', value)}
                />

                <InputNoneUnknown
                  label="City"
                  labelClassName="editor__label"
                  value={edits.city}
                  onChange={(value) => this.handleLocationEdit('city', value)}
                />

                <InputNoneUnknown
                  label="State"
                  labelClassName="editor__label"
                  value={edits.state}
                  onChange={(value) => this.handleLocationEdit('state', value)}
                />

                <InputNoneUnknown
                  label="ZIP Code"
                  labelClassName="editor__label"
                  value={edits.postal_code}
                  onChange={(value) => this.handleLocationEdit('postal_code', value)}
                />

                <InputNoneUnknown
                  label="Country"
                  labelClassName="editor__label"
                  value={edits.country}
                  onChange={(value) => this.handleLocationEdit('country', value)}
                />

                <InputNoneUnknown
                  label="Elevation"
                  labelClassName="editor__label"
                  value={edits.elevation}
                  onChange={(value) => this.handleLocationEdit('elevation', value)}
                />

                <div className="bg-light">
                  <InputWithLabel
                    label="Latitude"
                    error={Boolean(this.state.errors.latitude)}
                    errorMessage={this.state.errors.latitude}
                    labelClassName="editor__label"
                    value={edits.latitude.raw}
                    onChange={(e) => this.handleCoordinateEdit('latitude', e.target.value, this.validateLatitude)}
                  />

                  <InputWithLabel
                    label="Longitude"
                    error={Boolean(this.state.errors.longitude)}
                    errorMessage={this.state.errors.longitude}
                    labelClassName="editor__label"
                    value={edits.longitude.raw}
                    onChange={(e) => this.handleCoordinateEdit('longitude', e.target.value, this.validateLongitude)}
                  />

                  {this.state.geocodeError && (
                    <div className="row pb-3 pt-1 pr-3 px-md-1">
                      <div className="col-sm-3" />
                      <div className="col-sm-4 small error-message">{this.state.geocodeError}</div>
                    </div>
                  )}
                </div>
                <input
                  type="hidden"
                  className="form-control"
                  id="index"
                  value={this.state.index}
                  onChange={this.handleChange}
                />
                <BooleanRow
                  value={edits.address_approximate}
                  label={'Address is approximate'}
                  onChange={(value) => this.handleLocationEdit('address_approximate', value)}
                />
                <BooleanRow
                  value={edits.navigable}
                  label={'Location is navigable'}
                  onChange={(value) => this.handleLocationEdit('navigable', value)}
                />
              </React.Fragment>
            </div>
          </>
        </Edit>
        <ViewData
          className="p-0"
          userEditingStrategy={this.props.userEditingStrategy}
          show={!this.state.editing}
          onClick={() => {
            this.setState({ editing: true });
            this.props.analytics.trackListing('Listing Section', 'opened to edit', { section: 'Location' });
          }}
        >
          <div className="location-section">
            {this.hasCoordinates() ? (
              <div className="map-container map-content-wrapper">
                <LocationMapView
                  latitude={edits.latitude.sanitized}
                  longitude={edits.longitude.sanitized}
                  isSatellite={this.state.isSatellite}
                  zoom={[12]}
                />
                <div className="map-options">
                  <Button
                    type="button"
                    className="editor__button--white"
                    onClick={this.toggleSatellite}>
                    {this.state.isSatellite ? 'Streets View' : 'Satellite View'}
                  </Button>
                </div>
              </div>
            ) : null}
            {anyLocationDataSet ? (
              <div className="location-field-container map-content-wrapper">
                <ViewField label="Address" value={edits.address1} />
                <ViewField label="Suite, Unit, etc." value={edits.address2} />
                <ViewField label="City" value={edits.city} />
                <ViewField label="State" value={edits.state} />
                <ViewField label="ZIP Code" value={edits.postal_code} />
                <ViewField label="Country" value={edits.country} />
                <ViewField label="Elevation" value={edits.elevation} />
                {this.hasCoordinates() && (
                  <>
                    <ViewField label="Latitude" value={edits.latitude.raw} />
                    <ViewField label="Longitude" value={edits.longitude.raw} />
                  </>
                )}

                <BooleanRowView value={edits.address_approximate} label={'Address is approximate: '} />
                <BooleanRowView value={edits.navigable} label={'Location is navigable: '} />
              </div>
            ) : (
              <div className="well px-0 position-relative border-0">
                <p className="text-muted p-3 m-0">No location set for this listing</p>
                <div className="bg-light" style={{ height: '7rem' }} />
              </div>
            )}
          </div>
        </ViewData>
      </>
    );
  }
}

LocationView.propTypes = {
  isEditable: PropTypes.bool,
  id: PropTypes.number,
  index: PropTypes.number,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postal_code: PropTypes.string,
  country: PropTypes.string,
  elevation: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  address_approximate: PropTypes.bool,
  navigable: PropTypes.bool,
  editing: PropTypes.bool,
  userEditingStrategy: PropTypes.string,
  canRemove: PropTypes.bool,
  saveLocation: PropTypes.func,
  removeLocation: PropTypes.func,
  analytics: PropTypes.any,
};

/**
 * Edit locations
 *
 * Technically, there may be multiple locations on a listing. So we need to handle that possibility.
 * There may also be no locations on a listing, so we need to handle that possibility too.
 */
class LocationsView extends React.Component {
  // set up component, and assign state to the initial properties we received
  constructor(props) {
    super(props);
    this.state = deepClone(this.props);
  }

  removeLocation = (index) => {
    let location = {
      id: this.state.locations[index].id,
      _destroy: true,
    };
    ajax.patch(document.location.pathname, { listing: { locations: [location] } }).then(() => {
      this.setState((prevState, props) => {
        return update(prevState, { locations: { $splice: [[index, 1]] } });
      });
    });
  };

  saveLocation = (index, location, callback) => {
    let locations = deepClone(this.state.locations) || [];
    locations[index] = location;
    ajax.patch(document.location.pathname, { listing: { locations: locations } }).then(() => {
      this.setState(locations);
      callback();
    });
  };

  renderHeader() {
    const headerText = this.state.locations.length > 1 ? 'Locations' : 'Location';
    return <h4 className="mt-5">{headerText}</h4>;
  }

  // render component
  render() {
    // if we have locations
    if (this.state.locations.length > 0) {
      return (
        // then for each location create a new LocationView element, passing in index
        <React.Fragment>
          {this.renderHeader()}
          {this.state.locations.map((location, index) => (
            <LocationView
              {...location}
              userEditingStrategy={this.props.userEditingStrategy}
              canRemove={this.props.userEditingStrategy != 'none' && this.state.locations.length > 1}
              isSatellite={this.state.isSatellite}
              index={index}
              key={location.id}
              removeLocation={this.removeLocation}
              saveLocation={this.saveLocation}
            />
          ))}
        </React.Fragment>
      );
    }
    // if we have no locations
    else {
      return (
        <>
          {this.renderHeader()}
          <LocationView
            userEditingStrategy={this.props.userEditingStrategy}
            editing={this.state.editing}
            canRemove={false}
            isSatellite={this.state.isSatellite}
            index={0}
            saveLocation={this.saveLocation}
          />
        </>
      );
    }
  }
}

LocationsView.propTypes = {
  userEditingStrategy: PropTypes.string,
};

export default function Location(props) {
  const { analytics } = useStoreContext();
  return <LocationsView {...props} analytics={analytics} />;
}
