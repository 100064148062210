import parseQueryString from "./parseQueryString";
import $ from "jquery";

export default function applyFilter(
  newParams: {[key: string]: string},
  replacedIds?: string[]
): {[key: string]: string} {
  (window as any).searchRequest?.abort();

  let {params, hash} = parseQueryString();

  // Ignore `page` parameter when changing filters

  params as {page?: string};
  delete params?.page;

  updateQueryStringValue(newParams, params);

  remount(replacedIds, params, hash);
  return params;
}

export function applyPage(newPage: number) {
  let {params, hash} = parseQueryString();
  updateQueryStringValue({page: newPage.toString()}, params);
  remount(null, params, hash);
  return params;
}

export function removeFilter(keys: string[], replacedIds?: string[]) {
  (window as any).searchRequest?.abort();

  let {params, hash} = parseQueryString();
  const copy = {...params};
  // Ignore `page` parameter when changing filters
  //@ts-ignore
  delete params?.page;

  // delete everything if empty array is passed in
  if (keys.length === 0) {
    Object.keys(params).map((key) => {
      delete params[key];
    });
  } else {
    keys.map((key) => {
      delete params[key];
    });
  }

  remount(replacedIds, params, hash);

  return copy;
}

function updateQueryStringValue (newParams: { [p: string]: string }, params) {
  Object.keys(newParams).map((key) => {
    let value = newParams[key];

    if (value && value !== "null" && value !== "undefined") {
      params[key] = value;
    } else {
      delete params[key];
    }
  });
}

function remount(replacedIds, params, hash) {
  if (replacedIds) {
    replacedIds.forEach((id) => $("#" + id).addClass("searching"));
  }

  // replace the URL
  const qs = $.param(params);
  const url = [window.location.pathname, qs].filter((i) => i).join("?") + hash;

  window.history.replaceState({turbolinks: true, url}, null, url);

  const isSearch = window.location.href.includes('/search');

  if (isSearch) {
    (window as any).updateSearch();
    return;
  } else if (!isSearch) {
    (window as any).searchRequest = $.get(window.location.pathname, params);

  (window as any).searchRequest.done((html, status, jqxhr) => {
    replacedIds.forEach((id) => {
      const selector = "#" + id;
      // @ts-ignore
      ReactRailsUJS.unmountComponents(selector);
      let $container = $(selector);
      let $newContent = $(html).find(selector).children();
      // @ts-ignore

      $container.html($newContent);
      // @ts-ignore

      ReactRailsUJS.mountComponents(selector);
    });
  });

  (window as any).searchRequest.always(() => {
    if (replacedIds) {
      replacedIds.forEach((id) => $("#" + id).removeClass("searching"));
    }
    (window as any).searchRequest = null;
  });
  }
}
