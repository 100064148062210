import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from './Icon';

export class SinglePathIcon extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <path className={this.props.pathClassName} fill={this.props.fillColor} d={this.props.path} />
        {this.props.children}
      </Icon>
    );
  }
}

SinglePathIcon.propTypes = {
  pathClassName: PropTypes.string,
  path: PropTypes.string.isRequired,
  fill: PropTypes.string
};

export default SinglePathIcon;
