import React, { useState, useEffect } from 'react';
import * as ajax from '@/helpers/ajax';
import './style';
import { Defaults, Props, Errors } from './Types';
import { Dashboard } from './Dashboard';
import { Loading } from '../v2Editor/src/components/loading';


// Component ============================
export const MatchRun = ({ ...props }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  props.message && props.message.error &&
    console.error(
      `[Match Set Error] -`,
      `[Match Set id]: ${props.id || null} `,
      `[Error Details]: ${props.message.details}`
    );

  // Handlers =============================
const onBeginReview = (id: number) => {
  return (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    window.location.href = `/match_runs/${id}/review`;
  }
};

const onSeeAll = (id: number) => {
  return (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    window.location.href = `/imports/${id}/matches`;
  }
};

const onMerge = (id: number) => {
  return (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    ajax
      .post(`/match_runs/${id}/merge`)
      .then(response => {
        if (response.ok) {
          setIsLoading(false);
           window.location.href = `/imports/${props.import_id}`;
        }
        console.error(response.status);
      });
  }
};

  const isMergedOrMerging = () => {
    return props &&
      (props.status === 'merged' || props.status === 'merging') || props.message?.error
  }

useEffect(()=>{
  {setIsLoading(false)}
}, []);

  return (
    <>
      {isLoading && <Loading />}
      {!props ?
      <div className="matchruns-container"> Loading... </div>
      :
      <div className="matchruns-container">
        <div
          className="header">
          <h1
            className="header__title">
            Match Run
          </h1>
          {props.message && <b className={"header__message--red"}>{
          props.message.error && Errors.matchRunUnavailable
          }</b>}
        </div>

        <div className="sub-header">
          <div className="sub-header__column">
            <span
              className="sub-header__label sub-header__label--id">
              {`ID:`}
            </span>
            <span
              className="sub-header__value sub-header__value--id">
              {props.id ?? Defaults.idUnavailable}
            </span>
          </div>
          <div className="sub-header__column">
            <span
              className="sub-header__label sub-header__label--name">
              {`Import:`}
            </span>
            <span
              className="sub-header__value sub-header__value--name">
              {props.import_id ? <a href={`/imports/${props.import_id}`}>{props.import_id}</a> : Defaults.nameUnavailable}
            </span>
          </div>
        </div>

        <Dashboard {...props} />

        <div className="footer">
          <button
            className="btn btn-white btn btn-primary footer__button"
            onClick={onBeginReview(props.id)}
            >
            Begin Review
          </button>
          <button
            className="btn btn-white btn btn-primary footer__button"
            onClick={onSeeAll(props.import_id)}>
            See All
          </button>
          <button
            className="btn btn-white btn btn-primary footer__button"
            onClick={onMerge(props.id)}
            disabled={isMergedOrMerging()}>
            Merge
          </button>
        </div>
      </div>}
    </>
  )
}

export default MatchRun;