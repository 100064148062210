// NOTE prop-types expects a function for custom type checks

export const requiredIf = (casualPropName, propValidationFn) => (props, propName, componentName) => {
  if (props[casualPropName] && !props[propName]) {
    return new Error(
      `Prop '${casualPropName}' is defined, '${propName}' must be defined in component '${componentName}'.`
    );
  }
  if (propValidationFn) {
    propValidationFn(props, propName, componentName);
  }
};

export const onlyIfNot = (casualPropName, propValidationFn) => (props, propName, componentName) => {
  if (props[casualPropName] && props[propName]) {
    return new Error(
      `Props '${casualPropName}' and '${propName}' cannot both be true in component '${componentName}'.`
    );
  }
  if (propValidationFn) {
    propValidationFn(props, propName, componentName);
  }
};

export const typeOrUndefined = type => (props, propName, componentName) => {
  const typeOf = typeof props[propName];
  if (props[propName] && typeOf !== type) {
    return new Error(
      `Prop ${propName} is of type '${typeOf}', it should be of type '${type}' in component '${componentName}'.`
    );
  }
};

export const boolOrUndefined = typeOrUndefined('boolean');
