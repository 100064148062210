
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import Schedule from './index';
import './styles/index';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className='alert'>
  This component is in progress
    </div>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;