import React from "react";
import Diff from "@/library/diff/container";
import Button from "@/library/_button";
import * as ajax from "@/helpers/ajax";
import Bar from "@library/loaders/bar";
import "./styles";

type Props = {
  canonicalPlace: {id: string};
};

function Changes(props: Props) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [current, setCurrent] = React.useState(1); // Current page
  const [changes, setChanges] = React.useState([]);
  const [meta, setMeta] = React.useState({
    hasNextPage: false,
    hasPreviousPage: false,
    pages: -1,
  });

  async function loadChanges() {
    try {
      const [promise] = ajax._get(
        "/listings/changes/" +
          props.canonicalPlace.id +
          ".json?limit=20&page=" +
          current
      );

      const json = await promise;

      if (json) {
        const {page, changes, ...res} = json;
        if (page === current) {
          setChanges(changes);
          setMeta({...res});
          setLoading(false);
          window.scrollTo(0, 0);
          if (error) {
            setError(false);
          }
        }
      }
    } catch (e) {
      if (e) {
        setError(true);
        setLoading(false);
      }
    }
  }

  React.useEffect(() => {
    setChanges([]);
    loadChanges();
  }, [current]);

  return (
    <div data-cy="changes" className="editor__changes">
      {loading && [
        // Way faster than creating them iteratively
        <br key="br" />,
        <Bar key="bar-1" />,
        <Bar key="bar-2" />,
        <Bar key="bar-3" />,
        <Bar key="bar-4" />,
        <Bar key="bar-5" />,
      ]}
      {meta.pages === 0 && <i>none</i>}

      {changes.length > 0 &&
        changes.map((change, idx) => {
          return <Diff {...change} key={idx + change.changed_at} />;
        })}

      {!loading && (
        <div className="editor__changes__pagination">
          {meta.pages && <div>{`page ${current} of ${meta.pages}`}</div>}

          {meta.hasPreviousPage && (
            <Button
              className="editor__button--white"
              onClick={() => setCurrent((p) => p - 1)}
            >
              back
            </Button>
          )}
          {meta.hasNextPage && (
            <Button
              className="editor__button--white"
              onClick={() => {
                setLoading(true);
                setCurrent((p) => p + 1);
              }}
            >
              next
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default Changes;
