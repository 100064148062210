import React from 'react';
import * as flags from '@/library/launch-darkly/flags';
import Disable from './disable';
import Enable from './enable';
import Menu from '@/layout/side-nav';
import Nav from '@/layout/side-nav/nav';
import './styles';
import '@library/fontawesome';

type Props = {
  canEnable: boolean;
  enabled: boolean;
  enablePath: string;
  internalUser: boolean;
  parent: any;
  children: [];
  discoverabilityEnabled: boolean;
  nav: {
    canViewRoadTripDetails: boolean;
  };
  setActive: (tab: string) => void;
};

function NavMenu(props: Props) {
  const [enabled, setEnabled] = React.useState(props.enabled);

  const onClick = (text) => () => {
    props.setActive(text);
    window.location.hash = text;
  };

  const subnavOnClick = (id) => () => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
  };

  return (
    <div className="editor__listing__nav">
      <Menu {...props.nav} internalUser={props.internalUser} enabled={enabled}>
        <Nav onClick={onClick('dashboard')} icon={['fa', 'home']} text="Dashboard" />
        <Nav onClick={onClick('general')} icon={['fa', 'map-marker-alt']} text="General Info" />
        <Nav text="Attributes" icon={['fa', 'edit']} onClick={onClick('attributes')}>
          <Nav onClick={subnavOnClick('activities')} icon={['fa', 'hiking']} text="Activities" />
          <Nav onClick={subnavOnClick('in-culture')} icon={['fa', 'palette']} text="Culture" />
          <Nav onClick={subnavOnClick('universal-attributes')} icon={['fa', 'globe-americas']} text="Universal" />
          <Nav onClick={subnavOnClick('restroom-attributes')} icon={['fa', 'restroom']} text="Restrooms" />
          <Nav onClick={subnavOnClick('price-attributes')} icon={['fa', 'dollar-sign']} text="Price" />
          <Nav onClick={subnavOnClick('parking-attributes')} icon={['fa', 'car']} text="Parking" />
          <Nav
            onClick={subnavOnClick('overnight-rv-parking-attributes')}
            icon={['fa', 'car']}
            text="Overnight RV Parking"
          />
          <Nav onClick={subnavOnClick('campground-attributes')} icon={['fa', 'campground']} text="Campground" />
          <Nav onClick={subnavOnClick('pet-attributes')} icon={['fa', 'dog']} text="Pets" />
          <Nav onClick={subnavOnClick('restaurant-attributes')} icon={['fa', 'utensils']} text="Restaurant" />
          <Nav
            onClick={subnavOnClick('electric-vehicle-details')}
            icon={['fa', 'plug']}
            text="Electric Vehicle Station"
          />
          <Nav onClick={subnavOnClick('hotel-attributes')} icon={['fa', 'hotel']} text="Hotel" />
          <Nav onClick={subnavOnClick('booking-attributes')} icon={['fa', 'book']} text="Booking" />
          <Nav onClick={subnavOnClick('rv-service')} icon={['fa', 'caravan']} text="RV Service" />
        </Nav>
        <flags.DETAILS_TAB>
          <Nav id="categories" admin icon={['fa', 'shapes']} onClick={onClick('categories')} text="Categories" />
        </flags.DETAILS_TAB>
        <Nav onClick={onClick('hours')} text="Hours" icon={['fa', 'calendar']} />
        <Nav onClick={onClick('editorial')} text="Editorial" icon={['fa', 'newspaper']} />
        <Nav onClick={onClick('media')} text="Media" icon={['fa', 'image']} />
        <Nav onClick={onClick('suggestions')} text="Suggestions" icon={['fa', 'comment-dots']} />
        <Nav icon={['fa', 'bell']} onClick={onClick('changes')} text="Changes" />
        {props.discoverabilityEnabled && (<Nav icon={['fa', 'percent']} onClick={onClick('discoverability')} text="Discoverability" />)}
        <Nav icon={['fa', 'network-wired']} onClick={onClick('relationships')} text="Relationships" />
        <flags.EXTRAORDINARY_PLACES>
          {props.nav.canViewRoadTripDetails ? (
            <Nav onClick={onClick('rt')} icon={['fa', 'landmark']} admin text="RT" />
          ) : null}
        </flags.EXTRAORDINARY_PLACES>
        <flags.ENABLE_PLACE>
          {enabled ? (
            <Disable onClick={() => setEnabled(false)} enabled={enabled} canDisable={props.canEnable} />
          ) : (
            <Enable
              onClick={() => setEnabled(true)}
              enabled={enabled}
              canEnable={props.canEnable}
              enablePath={props.enablePath}
            />
          )}
        </flags.ENABLE_PLACE>
      </Menu>
    </div>
  );
}

export default NavMenu;
