import React from "react";
import ConnectorV1 from "./connector";
import data from "./data";
import Providers from "@/data-outreach/_core/forms/providers";

function CorrectionsV1(props) {
  return (
    <Providers {...props} data={data(props)}>
      <ConnectorV1 />
    </Providers>
  );
}

export default CorrectionsV1;
