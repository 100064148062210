import React from "react";
import IconAdd from "../icons/IconAdd";
import IconTrashCan from "../icons/IconTrashCan";
import IconRemove from "../icons/IconRemove";
import cn from "classnames";
import IconX from "../icons/IconX";
import IconSuggestion from "../icons/IconSuggestion";

const CANCEL_LABEL = "Cancel";
const DISABLE_LABEL = "Disable";
const SAVE_LABEL = "Save";
const APPLY_LABEL = "Apply";

type Props = {
  ariaLabel?: string;
  children?: any;
  className?: string;
  cy?: string;
  disabled?: boolean;
  id?: string;
  type?: "button" | "submit" | "reset";
  onClick?: React.MouseEventHandler;
};

// base
export const Button = ({children, ariaLabel, cy, ...props}: Props) => (
  <button {...props} aria-label={ariaLabel} data-cy={cy}>
    {children}
  </button>
);

type WhiteButtonProps = Props & {
  small?: boolean;
  round?: boolean;
};

// variations
export const WhiteButton = ({
  round = false,
  small = false,
  className,
  cy,
  ...props
}: WhiteButtonProps) => (
  <Button
    cy={cy}
    className={cn({
      btn: true,
      "boone-button": true,
      "boone-button_round": round,
      "btn-white": true,
      "btn-circle": round,
      "btn-circle-small": round && small,
      [className]: Boolean(className),
    })}
    {...props}
  />
);

export const PrimaryButton = (props: Props) => (
  <Button className="editor__button editor__button__primary" {...props} />
);

export const SecondaryButton = (props: Props) => (
  <Button className="boone-button__secondary" {...props} />
);

export const DangerButton = (props: Props) => (
  <Button className="btn btn-danger" {...props} />
);

export const IconButton = (props: Props) => (
  <Button className="btn btn-icon" {...props} />
);

export const SaveButton = ({
  onSave,
  ...props
}: Props & {onSave: React.MouseEventHandler}) => (
  <PrimaryButton onClick={onSave} {...props}>
    {SAVE_LABEL}
  </PrimaryButton>
);

export const ApplyButton = (props: WhiteButtonProps) => (
  <WhiteButton {...props}>{APPLY_LABEL}</WhiteButton>
);

export const DisableButton = ({
  onDisable,
  ...props
}: WhiteButtonProps & {onDisable: React.MouseEventHandler}) => (
  <DangerButton onClick={onDisable} {...props}>
    {DISABLE_LABEL}
  </DangerButton>
);

export const CancelButton = ({
  onCancel,
  ...props
}: WhiteButtonProps & {onCancel: React.MouseEventHandler}) => (
  <WhiteButton className="mr-2" onClick={onCancel} {...props}>
    {CANCEL_LABEL}
  </WhiteButton>
);

// Red X no border
export const DeleteButton = ({
  onDelete,
  ...props
}: WhiteButtonProps & {onDelete: React.MouseEventHandler}) => (
  <IconButton ariaLabel="delete" onClick={onDelete} {...props}>
    <IconX width="28" height="28" className="exclude-icon" />
  </IconButton>
);

// round buttons
export const RoundEditButton = (props: WhiteButtonProps) => (
  <WhiteButton
    round
    ariaLabel="edit"
    cy="edit-button"
    className="btn btn-white btn-circle edit-icon"
    {...props}
  >
    <svg width="28" height="28">
      <path d="M22 8.9a2.9 2.9 0 0 0-4.94-2.05l-8.98 8.97a.67.67 0 0 0-.15.24L6.04 21.1a.67.67 0 0 0 .86.86l5.04-1.9a.67.67 0 0 0 .24-.15l8.97-8.97c.55-.54.85-1.28.85-2.05zM7.81 20.18l1-2.67 1.67 1.67-2.67 1zm3.88-1.68l-2.2-2.2 7.08-7.09 2.2 2.2-7.08 7.09zm8.52-8.52l-.49.5-2.2-2.21.49-.49a1.56 1.56 0 0 1 2.2 2.2z" />
    </svg>
  </WhiteButton>
);

export const RoundAddButton = ({
  className,
  small,
  ...props
}: WhiteButtonProps) => (
  <WhiteButton
    round
    ariaLabel="add"
    small={small}
    className={
      small
        ? `boone-button__round__add--small `
        : "boone-button__round__add " + className
    }
    {...props}
  >
    <IconAdd />
  </WhiteButton>
);

export const RoundRemoveButton = (props: WhiteButtonProps) => (
  <WhiteButton
    round
    ariaLabel="remove"
    className="boone-button__round__remove"
    {...props}
  >
    <IconRemove />
  </WhiteButton>
);

export const RoundActionButton = (props) => (
  <div
    aria-label="action"
    className="editor__button editor__button__action"
    {...props}
  />
);

export const RoundDiscardButton = (props: WhiteButtonProps) => (
  <WhiteButton round ariaLabel="discard" {...props}>
    <IconTrashCan />
  </WhiteButton>
);

export const RoundSuggestionButton = (props: WhiteButtonProps) => (
  <WhiteButton
    round
    ariaLabel="suggestion"
    cy="suggestion-button"
    className="btn btn-white btn-circle edit-icon"
    {...props}
  >
    <IconSuggestion />
  </WhiteButton>
);

export const RoundButton = ({
  btype,
  ...props
}: Props & WhiteButtonProps & {btype?: string}) => {
  switch (btype) {
    case "remove":
      return <RoundRemoveButton {...props} />;
    case "edit":
      return <RoundEditButton {...props} />;
    case "add":
      return <RoundAddButton {...props} />;
    case "discard":
      return <RoundDiscardButton {...props} />;
    case "action":
    default:
      return <RoundActionButton {...props} />;
  }
};
