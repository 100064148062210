import React from 'react';
import PropTypes from 'prop-types';

class EditField extends React.Component {
  render() {
    const { label, field, type, value, onChange, onBlur, placeholder, required, readOnly } = this.props;
    let editor = this.props.children;
    const className = this.props.className || 'row my-4 pr-3';
    const labelClass = this.props.labelClass || 'col-sm-3 text-sm-right col-form-label';

    if (!editor) {
      editor = (
        <input
          type={type || 'text'}
          className="editor__input"
          id={field}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
        />
      );
    }

    return (
      <div className={className}>
        <label htmlFor={field} className={labelClass}>
          {label}
        </label>
        {editor}
      </div>
    );
  }
}

EditField.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  field: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
};

export default EditField;
