import React from 'react';
import PropTypes from 'prop-types';
import parseHTML from 'html-react-parser';

const IconMarker = ({ text, fillColor, className, textClassName, x, y, icon }) => (
  !icon ? (
    <svg width="24" height="32" xmlns="http://www.w3.org/2000/svg" className={className}>
      <defs>
        <filter x="-33.3%" y="-200%" width="166.7%" height="500%" filterUnits="objectBoundingBox" id="a">
          <feGaussianBlur stdDeviation="2" in="SourceGraphic" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <ellipse fillOpacity=".33" fill="#171615" filter="url(#a)" cx="12" cy="30.5" rx="9" ry="1.5" />
        <path
          d="M12 29.5c3.1154334 0 11.5-11.7274466 11.5-17.5C23.5 5.64872538 18.3512746.5 12 .5 5.64872538.5.5 5.64872538.5 12c0 5.7725534 8.38456659 17.5 11.5 17.5z"
          stroke="#FFF"
          fill={fillColor}
        />
      </g>
      {text ? (
        <text className={textClassName} x={x} y={y} fill="white" textAnchor="top">
          {text}
        </text>
      ) : null}
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="100" className="svg-icon">
      <g>
        {parseHTML(icon)}
        {text ? (
          <foreignObject className="svg-icon-text" x={0} y={50} width="70" height="70" fill="white" textAnchor="top">
            {text}
          </foreignObject>
        ) : null}
      </g>
    </svg>
  )
);

IconMarker.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  x: PropTypes.string,
  y: PropTypes.string,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  fillColor: PropTypes.string
};

export default IconMarker;
