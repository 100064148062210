import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import CheckboxWithLabel from '../../library/CheckboxWithLabel';

export class ToggleMap extends PureComponent {
  componentDidMount = () => {
    if (!document.getElementById('toggle-map-anchor')) {
      const parent = document.getElementsByClassName('listing-filters')[0];
      const anchor = document.createElement('div');
      anchor.setAttribute('id', 'toggle-map-anchor');
      parent.appendChild(anchor);
    }
  };
  render = () => {
    const { toggleMap, mapOpen } = this.props;
    const anchor = document.getElementById('toggle-map-anchor');
    return anchor && anchor.children.length < 2
      ? ReactDOM.createPortal(
          <div className="toggle-map">
            <CheckboxWithLabel checked={mapOpen} label="Show Map" onChange={() => toggleMap()} />
          </div>,
          anchor
        )
      : null;
  };
}

ToggleMap.propTypes = {
  toggleMap: PropTypes.func.isRequired,
  mapOpen: PropTypes.bool.isRequired
};

export default ToggleMap;
