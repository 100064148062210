import React from "react";
import ConnectorV2 from "./connector";
import Providers from "@/data-outreach/_core/forms/providers";
import data from './data';

function RvServiceV2(props) {
  return (
    <Providers {...props} data={data(props)}>
      <ConnectorV2 />
    </Providers>
  );
}

export default RvServiceV2;
