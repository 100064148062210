import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconCalendar extends React.Component {
  render() {
    var props = $.extend({}, this.props, {
      width: '16',
      height: '16',
      viewBoxHeight: '24',
      viewBoxWidth: '24',
      viewBoxX: '0',
      viewBoxY: '2',
      className: 'icon-calendar',
      path: "M20.0066445 7.08571429h-1.7009967v-.51428572C18.3056478 6.25714286 18.0664452 6 17.7740864 6s-.5315615.25714286-.5315615.57142857v.51428572h-2.7109634v-.51428572C14.5315615 6.25714286 14.2923588 6 14 6c-.2923588 0-.5315615.25714286-.5315615.57142857v.51428572h-2.7109634v-.51428572C10.7574751 6.25714286 10.5182724 6 10.2259136 6c-.29235878 0-.53156144.25714286-.53156144.57142857v.51428572H7.99335548C6.90365449 7.08571429 6 8.05714286 6 9.22857143V19.8571429C6 21.0285714 6.90365449 22 7.99335548 22H20.0066445C21.0963455 22 22 21.0285714 22 19.8571429V9.22857143c0-1.17142857-.9036545-2.14285714-1.9933555-2.14285714zm.9302326 12.77142861c0 .5428571-.4252492 1-.9302326 1H7.99335548c-.50498339 0-.93023256-.4571429-.93023256-1v-7.0857143H20.9368771v7.0857143zm0-8.2285715H7.06312292V9.22857143c0-.54285714.42524917-1 .93023256-1h1.70099668v.51428571c0 .31428572.23920266.57142857.53156144.57142857.2923588 0 .5315615-.25714285.5315615-.57142857v-.51428571h2.7109634v.51428571c0 .31428572.2392027.57142857.5315615.57142857.2923588 0 .5315615-.25714285.5315615-.57142857v-.51428571h2.7109634v.51428571c0 .31428572.2392027.57142857.5315615.57142857.2923588 0 .5315614-.25714285.5315614-.57142857v-.51428571h1.7009967c.5049834 0 .9302326.45714286.9302326 1v2.39999997zM9 14h10v1H9v-1zm0 2h10v1H9v-1zm0 2h10v1H9v-1z"
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}
