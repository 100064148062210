import React, { PureComponent } from 'react';
import * as pt from '@/helpers/propTypes';
import BooneDropdown from '@/library/dropdown';
import BulkListingAuditLink from '@/audit/BulkListingAuditLink';
import SearchFilter from '@library/filters/search';
import applyFilter from '@/helpers/applyFilter';
import cn from 'classnames';
import { RoundButton } from '@/library/Buttons';

class ContentHeading extends PureComponent {
  render() {
    // When we use a title component, we wrap these components in a div to keep styles
    // consistent with those applied when we use a search component
    const TagInnerWrapper = this.props.title ? 'div' : React.Fragment;
    const propsInnerWrapper = this.props.title ? { className: 'content-heading content-heading__container' } : {};
    const classNameOuterEl = cn({ 'content-heading': true, 'input-group editor__search': this.props.search });

    return (
      <div className={classNameOuterEl}>
        <TagInnerWrapper {...propsInnerWrapper}>
          {this.props.search && <SearchFilter {...this.props.searchProps} applyFilter={(newParams, replacedIds) => applyFilter(newParams, replacedIds, searchProps.userId, searchProps.orgId)} />}
          {this.props.dropdown && <ContentHeadingDropDown {...this.props.dropdownProps} />}
          {this.props.title && (
            <h1 className={this.props.titleProps.className || 'text-truncate'}>{this.props.titleProps.text}</h1>
          )}
          {this.props.modal && (
            <span data-cy="listings-dropdown-button">
              <RoundButton data-toggle="modal" {...this.props.modalProps} />
            </span>
          )}
        </TagInnerWrapper>
      </div>
    );
  }
}

ContentHeading.defaultProps = {
  search: false,
  dropdown: false,
  modal: false,
  title: false,
};

ContentHeading.propTypes = {
  search: pt.onlyIfNot('title', pt.boolOrUndefined),
  dropdown: pt.onlyIfNot('modal', pt.boolOrUndefined),
  modal: pt.onlyIfNot('dropdown', pt.boolOrUndefined),
  title: pt.onlyIfNot('search', pt.boolOrUndefined),
  searchProps: pt.requiredIf('search'),
  dropdownProps: pt.requiredIf('dropdown'),
  modalProps: pt.requiredIf('modal'),
  titleProps: pt.requiredIf('title'),
};

export default ContentHeading;

const ContentHeadingDropDown = ({ options, ...props }) => (
  <BooneDropdown className='editor__search--dropdown' toggleIcon="action-button" {...props}>
    {options
      .filter((option) => option.item)
      .map((option, idx) => (
        <DropDownOption key={'content-heading-dropdown-item-' + idx} {...option} />
      ))}
    {props.canBulkAssignAudit && <BulkListingAuditLink isNestedDropdown={true} users={props.users} />}
  </BooneDropdown>
);

const DropDownOption = ({ wrapper, wrapperProps, itemProps }) => {
  const { tag, content, ...props } = itemProps;
  // create tags
  const TagOptionItem = tag || 'div';
  const TagOptionWrapper = wrapper ? wrapperProps.tag || 'div' : React.Fragment;

  return (
    <TagOptionWrapper {...wrapperProps}>
      <TagOptionItem {...props}> {content} </TagOptionItem>
    </TagOptionWrapper>
  );
};
