import React from 'react';

// ? question mark symbol
// aka - unknown
function Null({ className }: { className?: string }) {
  return (
    <svg className={className || 'attribute-icon-default'}>
      <path d="M12.29 18.29h2.04v-1.5c0-2.19 4.67-3.04 4.67-6.5C19 7.72 16.62 6 13.92 6c-1.9 0-3.74.87-4.92 2.11l1.34 1.4a5.1 5.1 0 0 1 3.44-1.64c1.7 0 3.04.99 3.04 2.48 0 2.72-4.53 3.48-4.53 6.43v1.51zM13.33 22c.8 0 1.43-.56 1.43-1.35 0-.72-.64-1.28-1.43-1.28-.82 0-1.47.56-1.47 1.28 0 .79.65 1.35 1.47 1.35z" />
    </svg>
  );
}

export default Null;
