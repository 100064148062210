import React from 'react';
import useIndex from '@/hooks/useIndex';

function useDefinitionsIndex(attributes){
  const callback = (index, node) => {
    const nodes = index.byId;
    // update parents who had dependents added before they were added to index
    nodes[node.id] = { ...nodes[node.id], ...node };

    // add to parent dependents
    if (node.depends_on) {
      const parent = nodes[node.depends_on];
      if (parent) {
        if (!parent['dependents']) {
          parent.dependents = [];
        }
        parent.dependents.push(node.id);
        nodes[parent.id] = parent;
      } else {
        // create node with only dependents, update rest when parent id is up
        nodes[node.depends_on] = { dependents: [node.id] };
      }
    }

    return { ...index, byId: nodes };
  };

  return useIndex(
    attributes.sort((a, b) => a.schema.type > b.schema.type),
    'id',
    { callback }
  );
}

export default useDefinitionsIndex;
