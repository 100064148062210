import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class PageHeading extends PureComponent {
  render() {
    return (
      <div className="row justify-content-center page-title">
        <div id="heading-container" className="col-12 col-lg-8">
          {this.props.children}
        </div>
      </div>
    );
  }
}

PageHeading.propTypes = {
  children: PropTypes.any
};
