import * as React from 'react';
import ListingSchedule from './ListingSchedule';
import Closures from './closures';
import './styles/index';

export default function ListingHours(props) {
  return (
    <div className="editor__listing editor__listing__hours">
      <Closures {...props} />
      <span data-cy="hours">
        <ListingSchedule {...props} />
      </span>
    </div>
  );
}
