import * as React from 'react';
import ImageGallery from './images/Gallery';
import VideoGallery from '../../media/VideoGallery';

export default function Media(props) {
  return (
    <>
      <ImageGallery {...props.images} userEditingStrategy={props.userEditingStrategy} />
      <h4 className="mt-5">YouTube Videos</h4>
      <VideoGallery {...props.videos} userEditingStrategy={props.userEditingStrategy} />
    </>
  );
}
