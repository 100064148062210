import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import ActionsDropdown from "./actions";
import type {Props as ActionsProps } from "./actions";
import LastUpdated from "./last-updated";
import DetailsLink from "@library/place-details/link";
import { Assignment } from "@/audit/Assignment";
import withAuditApi from "@/hoc/withAuditApi";
import * as util from "@library/place-details/util";
import * as auditUtil from "@/audit/util";
import * as helpers from "@/helpers";
import { useStoreContext } from "@/state";
import "./styles";
import ThumbnailLink from "@library/place-details/thumbnail-link";
import { Thumbnail } from "./thumbnail";

type Props = {
  name: string;
  branded_attribution: string;
  brandedAttributionUrl: string;
  owner_verified?: Boolean;
  number: number;
  locations?: {}[];
  last_updated: string;
  internalOrg: boolean;
  fidelity_level?: number;
  previous_ids: [];
  attribution?: {}[];
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  images?: {}[];
  inFocus?: number;
  ranking_score?: number;
  engagement?: {
    avg_rating: number;
    rating_count: number;
    visits: number;
  };
} & ActionsProps;

function ListingResult(props: Props) {
  const { analytics } = useStoreContext();
  const detailsViewPath = "/listings/" + props.canonicalPlaceId;
  const thumbnailUrl = util.findThumbnailUrl(props.images);
  const className = cn({
    "editor__place-summary": true,
    "in-focus": props.inFocus === props.canonicalPlaceId,
  });

  const { hasAudit, assignedUser } = auditUtil.assignment(
    props.audit,
    props.users
  );
  const numPlaces = props.attribution ? props.attribution.length : 0;

  const analyticsTrackSelectedFromList = () => {
    analytics.trackListing("Listing", "selected from list", {
      canonical_place_id: props.canonicalPlaceId,
      search_query: document.location.href,
    });
  };

  const analyticsTrackAssignedToAudit = (userId) => {
    analytics.trackListing("Listing", "assigned to audit", {
      assignee_user_id: userId,
      canonical_place_id: props.canonicalPlaceId,
    });
  };
  
  return (
    <div
      role="tr"
      onClick={() => analyticsTrackSelectedFromList()}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={className}
    >
      <div className="editor__place-summary__index-number">{props.number}</div>

      <div
        className="editor__place-summary__item"
        onClick={(e) => e.stopPropagation()}
      >
        <ActionsDropdown
          {...props}
          toggleIcon="label-arrow-right"
          canonicalPlaceId={props.canonicalPlaceId}
          removeAudit={props.removeAudit}
          updateAudit={props.updateAudit}
          createAudit={(user_id) => {
            props.createAudit(user_id);
            analyticsTrackAssignedToAudit(user_id);
          }}
          owner_verified={props.owner_verified || false}
          detailsViewPath={detailsViewPath}
        />
      </div>

      <div className="editor__place-summary__item">
        <div className="boone-listings__search-results__listing__detail">
          <DetailsLink
            verified={props.owner_verified || false}
            showMeta={true}
            numPlaces={numPlaces}
            name={props.name}
            locations={props.locations}
            internalOrg={props.internalOrg}
            fidelityLevel={props.fidelity_level}
            detailsViewPath={detailsViewPath}
            users={props.users}
            audit={props.audit}
          />

          {props.internalOrg && (
            <div className="editor__internal">
              <div className="editor__internal__item">
                <b>ID</b> {props.canonicalPlaceId}
              </div>
              <div className="editor__internal__item">
                <b>Fidelity</b> {props.fidelity_level}
              </div>
              <div className="editor__internal__item">
                <b>Places</b> {numPlaces}
              </div>
              <div className="editor__internal__item">
                <b>Travel Rank</b> {props.ranking_score}
              </div>
              <div className="editor__internal__item">
                {hasAudit ? (
                  <Assignment
                    name={helpers.getFirstName(assignedUser.name)}
                    url={assignedUser.avatar_url}
                    booneLogo={!props.audit.user_id}
                  />
                ) : (
                  <div className="audit-assignment" />
                )}
              </div>

              {props.engagement && (
                <React.Fragment>
                  {props.engagement.avg_rating && (
                    <div className="editor__internal__item">
                      <b>Avg Rating</b> {props.engagement.avg_rating}
                    </div>
                  )}
                  {props.engagement.rating_count && (
                    <div className="editor__internal__item">
                      <b>Ratings</b>{" "}
                      {props.engagement.rating_count.toLocaleString()}
                    </div>
                  )}
                  {props.engagement.visits && (
                    <div className="editor__internal__item">
                      <b>Visits</b> {props.engagement.visits.toLocaleString()}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="editor__place-summary__item">
        <LastUpdated
          detailsViewPath={detailsViewPath}
          dateTimeStr={props.last_updated}
        />
      </div>

      <Thumbnail {...props}>
        <ThumbnailLink
          detailsViewPath={detailsViewPath}
          thumbnailUrl={thumbnailUrl}
        />
      </Thumbnail>
    </div>
  );
}

ListingResult.propTypes = {
  name: PropTypes.string.isRequired,
  owner_verified: PropTypes.bool,
  locations: PropTypes.array,
  last_updated: PropTypes.string.isRequired,
  internalOrg: PropTypes.bool.isRequired,
  canonicalPlaceId: PropTypes.number.isRequired,
  fidelity_level: PropTypes.number,
  attribution: PropTypes.array,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default withAuditApi(ListingResult);
