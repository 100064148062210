import React, {ReactChildren, ReactElement} from "react";
import Button from "../_button";
import cn from "classnames";
import useOnClickOutside from '@/hooks/useOnClickOutside';
import {Dropdown, DropdownMenu, DropdownToggle, DropdownItem} from "reactstrap";
import "./styles";
type Props = {
  active?: boolean;
  children?: ReactChildren | ReactElement;
  label: string | Element;
  name: string;
  onSave: () => void;
  onCancel: () => void;
  hideButtons?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  cy?: string;
};

function DropdownForm(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef();
  useOnClickOutside(ref, props.onCancel);

  React.useEffect(() => {
    if (props.setIsOpen) props.setIsOpen(isOpen);
  }, [isOpen]);

  return (
    <Dropdown
      className={cn({
        "editor__dropdown-form": true,
        "above-scrim": isOpen,
      })}
      data-cy={props.cy}
      isOpen={isOpen}
      ref={ref}
      toggle={() => {
        setIsOpen((io) => !io);
      }}
    >
      <DropdownToggle
        data-cy={props.cy + "-toggle"}
        tag="button"
        className={
          "editor__dropdown-form__button " +
          (isOpen || props.active
            ? "editor__button editor__button--primary above-scrim"
            : "editor__button editor__button--white")
        }
      >
        {props.label}
      </DropdownToggle>
      <DropdownMenu className={"editor__dropdown-form__menu"}>
        <div data-cy={props.cy + "-children"}>{props.children}</div>
        {props.hideButtons ? null : (
          <div
            className="editor__dropdown-form__controls"
            data-cy={props.cy + "-controls"}
          >
            <Button
              cy={props.cy + "-controls-apply"}
              className="editor__button--primary"
              onClick={() => {
                props.onSave();
                setIsOpen(false);
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropdownForm;
