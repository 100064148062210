import IconX from '../../../icons/IconX';
import PropTypes from 'prop-types';
import React from 'react';

export const RemoveOption = ({ onClick, className = 'remove-option', iconClassName = 'remove-icon' }) => (
  <div className={className} onClick={onClick}>
    <IconX className={iconClassName} />
    Remove
  </div>
);

RemoveOption.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string
};

export default RemoveOption;
