import React from 'react';

export function useTime(init, options, leadingZero = false){
  const [error, setError] = React.useState(false);
  const [value, setValue] = React.useState(init + '');

  const validate = (n) => {
    return options.indexOf(n) !== -1 && n.length < 3;
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      setError(true);
    } else if (e.target.value) {
      if (validate(e.target.value)) {
        setError(false);
        const int = parseInt(value);
        const n = int < 10 ? '0' + int : value;
        // update display value to add leading zero
        if (leadingZero) setValue(n);
      } else {
        setError(true);
      }
    }
  };

  const onChange = (e) => {
    if (e.target.value.length < 3) {
      setValue(e.target.value);
      setError(false);
    }
  };

  return { error, value, onChange, onBlur };
}

export function useMinute(initial = '00'){
  const MINUTES = Array.from(Array(60).keys())
    .map((n) => n + '')
    .concat(Array.from(Array(10).keys()).map((n) => '0' + n));

  return useTime(initial, MINUTES, true);
}

export function useHour(initial = '12'){
  const HOURS = Array.from(Array(12).keys())
    .map((n) => n + 1 + '')
    .concat(Array.from(Array(10).keys()).map((n) => '0' + n));

  return useTime(initial, HOURS);
}
