import React from "react";
import FormV2 from "./form";
import useSteps from "./useSteps";
import {useContext} from "@/data-outreach/_core/forms/useContext";

function ConnectorV2() {
  const ctx = useContext();
  const steps = useSteps();
  return <FormV2 data={ctx} steps={steps} />;
}

export default ConnectorV2;
