import React, { ReactNode, useState } from 'react';
import { User, CurrentOrg, Account, FilterData, NavLinks, SearchProps } from './common/types';
import { Search } from './pages/Search';
import { Header } from './layout/header';
import { Sidebar } from './layout/sidebar';
import { Loading } from './components/loading';


import '@library/fontawesome';
import './styles';

interface props {
  children?: ReactNode;
  userId?: string;
  orgId?: string;
  search?: boolean;
  dropdown?: boolean
  searchProps?: SearchProps;
  user?: User;
  org?: CurrentOrg;
  account?: Account;
  launchDarkly: LaunchDarkly;
  isSuperAdmin: boolean;
  data: FilterData;
  navLinks: NavLinks;
}

export const V2Editor = ({ ...props }: props) => {
  const [collapsedState, setCollapsedState] = useState(false);
  const [navOptions, setNavOptions] = useState();
  const [isLoading, setIsLoading] = useState();

  return (
    <>
    {isLoading && <Loading />}
      <div className="editor-container">
        <Header account={props.account} />
        <div className="editor-container__content">
          <div className={
            `__left-panel${collapsedState ? '--collapsed' : ''}`
            }>
            <Sidebar
              title="Roadpass"
              titleB="Places"
              options={navOptions}
              userRole={props.account.role}
              recentPlaces={props.navLinks.recentPlaces}
              onCollapsedState={(state: any) => { setCollapsedState(!state) }}
            />
          </div>
          <div className={`__right-panel`}>
            <Search
              {...props} 
              setNavOptions={setNavOptions}
              onLoading={setIsLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default V2Editor;
