import React from 'react';
import { CanonicalPlace } from '../../common/types';
import { ListingDetails } from '../listingsList/ListingDetails';
import ThumbnailLink from "@library/place-details/thumbnail-link";
import * as util from "@library/place-details/util";

interface Props {
  listing: CanonicalPlace;
}

export const AuditListing = ({ ...props }: Props) => {

  //TODO: This is a dupe, maybe make a file in common to consolidate similar methods in refactor
  const constructAddress = (location) => {
    if (location) {
      return [location.address1, location.city, location.state, location.postal_code, location.country].filter(x => x).join(', ');
    } else {
      return '';
    }
  };

  return (
    <div className={`audit-listing`}>
      <div className="image">
        <ThumbnailLink
          detailsViewPath={`/listings/${props.listing.id}`}
          thumbnailUrl={util.findThumbnailUrl(props.listing.images)}
        />
      </div>
      <div className="details">
        <div className="details-header">
          <div className="name">
            {props.listing.name}
          </div>
        </div>
        <div className="details-body">
          <div className="content">
            <div className="address">
              <small>
                <label className="label">Address</label>
                {props.listing.locations && props.listing.locations?.length > 0 &&
                  constructAddress(props?.listing.locations[0])
                }
              </small>
            </div>
            <small>
              <ListingDetails
                {...props.listing} />
            </small>
          </div>
        </div>
      </div>

    </div>
  )
}
