import React from 'react';
import PropTypes from 'prop-types';
import mt from 'moment-timezone';

/**
 * date expected format example 10/2/18
 * time expected format example 8:10 PM EDT
 **/
export const parseDateTime = (isoStr, timezone = 'America/New_York') => {
  const [date, ...rest] = mt
    .utc(isoStr)
    .tz(timezone)
    .format('M/D/YY h:mm A z')
    .split(' ');
  return { date, time: rest.join(' ') };
};

export const ListingLastUpdated = ({ dateTimeStr, detailsViewPath }) => {
  const { date, time } = parseDateTime(dateTimeStr);
  return (
    <a href={detailsViewPath} className="d-block">
      <div className="last-updated-info">
        <span className="text-nowrap">{date}</span>
        <br />
        <small>
          <span className="text-nowrap">{time}</span>
        </small>
      </div>
    </a>
  );
};

ListingLastUpdated.propTypes = {
  /** expects utc iso string fmt */
  dateTimeStr: PropTypes.string.isRequired,
  detailsViewPath: PropTypes.string
};

export default ListingLastUpdated;
