import * as React from 'react';

type Props = {
  hoverContents: any;
  children: any;
};

const Hover = ({ hoverContents, children }: Props) => (
  <div className="hover">
    <div className="hover__no-hover">{children}</div>
    <div className="hover__hover">{hoverContents}</div>
  </div>
);

export default Hover;
