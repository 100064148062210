import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListingResultsTable from './table';
import ListingResultsMap from './map';
import parse from 'url-parse';
import { StoreProvider } from '@/state';
import './styles';

const TABLET_MIN_WIDTH = 746;

export class ListingsSearchResults extends Component {
  state = {
    inFocus: null, // listing in focus
    mapOpen: window.location.hash === '' && window.innerWidth > TABLET_MIN_WIDTH,
    // classes before we change them to accommodate
    // for the map's visibility
    classes: { heading: '', content: '', body: '' },
  };

  componentWillUpdate(nextProps, nextState) {
    if (window.innerWidth > TABLET_MIN_WIDTH) {
      const url = parse(window.location.href, true);
      if (nextState.mapOpen !== this.state.mapOpen) {
        if (nextState.mapOpen) {
          url.set('hash', '');
        } else {
          url.set('hash', 'hide_map');
        }
        window.location.href = url.toString();
      }
    }
  }

  getElById = (id) => document.getElementById(id);

  // elements exist outside of this component
  getElements = () => ({
    headingEl: this.getElById('heading-container'),
    contentEl: this.getElById('content-container'),
    bodyEl: document.body,
  });

  setOpenMapClasses = () => {
    const { headingEl, contentEl, bodyEl } = this.getElements();
    headingEl.className = 'col-12';
    contentEl.className = 'col-12';
    if (window.innerWidth > TABLET_MIN_WIDTH) {
      if (bodyEl.className) {
        const classes = bodyEl.className.split(' ');
        classes.pop();
        bodyEl.className = classes.join(' ') + ' editor--lg';
      }
    }
  };

  // when map is closed (not visible), we reset the
  // classes to their original value
  setClosedMapClasses = () => {
    const { headingEl, contentEl, bodyEl } = this.getElements();
    headingEl.className = this.state.classes.heading;
    contentEl.className = this.state.classes.content;
    bodyEl.className = this.state.classes.body;
  };

  // original classes are stored before they are updated
  // in response to openMap state
  storeClasses = () => {
    const { headingEl, contentEl } = this.getElements();
    const classes = {
      heading: headingEl.className,
      content: contentEl.className,
      body: document.body.className,
    };
    this.setState({ classes }, () => this.state.mapOpen && this.setOpenMapClasses());
  };

  componentDidMount() {
    sessionStorage.setItem('boone_listings_query', window.location.pathname + window.location.search);
    this.storeClasses();
    if (this.props.auditsTruncResults) {
      sessionStorage.setItem('boone_audits', JSON.stringify(this.props.auditsTruncResults));
    }
  }

  componentWillUnmount = () => {
    this.setClosedMapClasses();
  };

  setClassesForMapState = () => {
    if (this.state.mapOpen) {
      this.setOpenMapClasses();
    } else {
      this.setClosedMapClasses();
    }
  };

  toggleMap = () => {
    this.setState((prevState) => ({ mapOpen: !prevState.mapOpen }), this.setClassesForMapState);
  };

  // can be an id or null
  handleFocus = (id) => {
    this.setState({ inFocus: id });
  };

  render = () => {
    const dropdownMap = this.props.dropdown.reduce((prev, cur) => {
      prev[cur.id] = cur;
      return prev;
    }, {});
    return (
      <React.Fragment>
        <StoreProvider user={this.props.user} org={this.props.org}>
          <div className="editor__search-results">
            <ListingResultsTable
              {...this.props}
              dropdown={dropdownMap}
              offset={this.props.pagination.start}
              mapOpen={this.state.mapOpen}
              inFocus={this.state.inFocus}
              handleFocus={this.handleFocus}
            />
            {this.state.mapOpen && (
              <ListingResultsMap
                {...this.props}
                offset={this.props.pagination.start}
                mapOpen={this.state.mapOpen}
                inFocus={this.state.inFocus}
                handleFocus={this.handleFocus}
              />
            )}
          </div>
        </StoreProvider>
      </React.Fragment>
    );
  };
}

ListingsSearchResults.propTypes = {
  pagination: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    total: PropTypes.string,
  }),
  results: PropTypes.array,
  auditsTruncResults: PropTypes.array,
};

export default ListingsSearchResults;
