import * as moment from 'moment';
import 'moment-timezone';

const TIMEZONES = [
  {id: "US/Alaska", label: "Alaska", offset: -9, abbrs: ["AKST", "AKDT"]},
  {id: "US/Central", label: "Central", offset: -6, abbrs: ["CST", "CDT"]},
  {id: "US/Eastern", label: "Eastern", offset: -5, abbrs: ["EST", "EDT"]},
  {id: "US/Hawaii", label: "Hawaii", offset: -10, abbrs:["HST", "HAST", "HADT"]},
  {id: "US/Mountain", label: "Mountain", offset: -7,  abbrs:["MST", "MDT"]},
  {id: "US/Pacific", label: "Pacific", offset: -8,  abbrs:["PST", "PDT"]},
  {id: "US/Samoa", label: "Samoa", offset: -11,  abbrs:["SST", "SDT"]}
];

const getMatch = function (tzId) {
  const tz = moment.tz(tzId);
  if (tz && tz.zoneAbbr()) {
    const match = TIMEZONES.find(timezone => timezone.abbrs.indexOf(tz.zoneAbbr()) !== -1);
    return match;
  }
  return null;
}

const getMatchingTimezone = function (tzId) {
  const match = getMatch(tzId);
  if (match) {
    return match.id;
  }
  return null;
}

const getCurrentTimezone = function () {
  return getMatchingTimezone(moment.tz.guess());
}

const getOffset = function (tzId) {
  const tz = getMatch(tzId);
  if (tz) {
    return tz.offset;
  }
}

export {
  TIMEZONES,
  getCurrentTimezone,
  getMatchingTimezone,
  getOffset
}
