import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputWithLabel from '../../library/input-with-label';
import RoleSelect from './RoleSelect';
import { PrimaryButton } from '../../library/Buttons';

class InviteUserModalForm extends PureComponent {
  formRef = React.createRef();

  validate() {
    if (this.formRef.current) {
      const validity = this.formRef.current.checkValidity();
      this.formRef.current.reportValidity();
      return validity;
    }
    return true;
  }

  render() {
    return (
      <div className="editor__organization__invite-user-modal card" id="invite-user-modal">
        <h3 className="card-title pl-2">Invite a new user for {this.props.orgName} </h3>
        <div className="card-body">
          <form
            onSubmit={e => {
              e.preventDefault();
              if (this.validate()) {
                this.props.onSubmit();
              }
            }}
          >
            <InputWithLabel
              id="email"
              label={'Email Address'}
              value={this.props.email}
              onChange={e => this.props.updateFormData('email', e.target.value)}
              placeholder="julie@visitgotham.org"
              required
            />
            <InputWithLabel
              id="name"
              label="Name"
              value={this.props.name}
              onChange={e => this.props.updateFormData('name', e.target.value)}
              placeholder="Julie Madison"
              required
            />
            <div className="editor__field editor__field--edit">
              <div className="editor__label">Account Type</div>
              <div className="editor__field__value">
                <RoleSelect
                  className="editor__input"
                  options={this.props.roles}
                  showAlert={false}
                  selected={parseInt(this.props.role_id)}
                  selectRole={role_id => this.props.updateFormData('role_id', role_id)}
                />
              </div>
            </div>

            <div className="editor__organization__invite-user-modal__footer mt-5">
              <div className="text-left small">
                <div>
                  <span className="strong">Admins</span> can do all the things.
                </div>
                <div>
                  <span className="strong">Editors</span>
                  {` can read and update listings, but can't create or edit
                    users.`}
                </div>
                <div>
                  <span className="strong">Auditors</span>
                  {` can read listings and update assigned listings, but can't create or edit users.`}
                </div>
              </div>
              <div className="justify-content-end text-right">
                <PrimaryButton>Send invitation</PrimaryButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

InviteUserModalForm.propTypes = {
  orgName: PropTypes.string,
  roles: PropTypes.array,
  updateFormData: PropTypes.func,
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  name: PropTypes.string,
  role_id: PropTypes.number
};

export default InviteUserModalForm;
