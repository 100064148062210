import * as React from 'react';
import Spinner from '../Spinner';

export default function Loader({ pastDelay }: { pastDelay?: boolean }): any {
  return pastDelay ? (
    <div className="boone-loader__nested">
      <Spinner />
    </div>
  ) : null;
}
