import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface props {
  onSubmit: Function;
  onChange: Function;
  value: string;
}

export const SearchInput = ({...props}: props) => {
  return (
    <form onSubmit={(event) => props.onSubmit(event)}>
      <div>
        <div className="input-group search_input">
          <input name="name"
            value={props.value}
            className="form-control"
            placeholder="Search Listings"
            onChange={(event)=> props.onChange(event)}
          />
          <div className="input-group-append">
            <button className="btn btn-light search_input_submit">
              <FontAwesomeIcon icon={'search'} />
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
