import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import * as ajax from '@/helpers/ajax';
import Section from "@library/section";
import '../styles/index';

import { cleanFieldName } from './helpers'

import type SuggesterProps from './suggester-props'
import type SuggestionDetailProps from './suggestion-detail-props'

import SuggestionDetails from './suggestion-details'

type Props = {
  id: number;
  onUpdate: (number, string) => void;
  created_at: string;
  details: SuggestionDetailProps[];
  userEditingStrategy: UserEditingStrategy;
  disabled: boolean;
  suggester: SuggesterProps;
  analytics: any;
  state: 'rejected' | 'pending' | 'ignored' | 'accepted';
};

const SuggestionListItem = (props: Props) => {
  const [status, setStatus] = React.useState(props.state)
  useEffect( () => {
    props.analytics.trackListing('Suggestion', 'viewed');
  }, [])

  const fieldNames = () => {
    let label = (props.details[0] && cleanFieldName(props.details[0].field)) || 'n/a';

    if (props.details.length > 1) {
      const othersCount = props.details.length - 1;
      label += ` and ${othersCount} ${othersCount === 1 ? 'other' : 'others'}`;
    }

    return label;
  }

  const onClick = () => {}

  const createdAt = moment(props.created_at).fromNow();

  const sectionHeader = (
    <div className="boone-listing__suggestion__header" data-cy="suggestion-header">
      <div className="strong boone-listing__suggestion__header--detail">
        {fieldNames()}
      </div>
      <div className="strong boone-listing__suggestion__header--detail">
        from {props.suggester.organization.name}
      </div>
      <div className="strong boone-listing__suggestion__header--detail">
        { createdAt }
      </div>
      <div className="strong boone-listing__suggestion__header--detail">
        {props.state === 'rejected' ? 'ignored' : props.state}
      </div>
    </div>
  );

  return (
    <Section header={sectionHeader} collapsed={true}>
      <div className="boone-listing__suggestion__content" data-cy="suggestion-content">
        <SuggestionDetails
          className="editor__listing__suggestions__ignore-modal"
          created_at={props.created_at}
          analytics={props.analytics}
          onAccept={e => props.onUpdate(props.id, 'accepted')}
          onReject={e => props.onUpdate(props.id, 'rejected')}
          suggester={props.suggester}
          details={props.details}
          state={props.state}
          disabled={props.disabled}
        />
      </div>
    </Section>
  );
}

export default SuggestionListItem;
