
import isEqual from 'lodash/isEqual';

export const linkFormat = (schema) => {
  if (schema) {
    const fmt = schema.format || ( schema.items && schema.items.format )
    return fmt === 'url' || fmt === 'email';
  }
  return false;
}

export function formatLabel(label, depends_on) {

  let r = label.split(' ')
    .map((i, idx) => {
      if (idx !== 0) {
        i = i.toLowerCase();
      }
      if (i === 'concession/third') return 'third'
      if (i === 'rv') return 'RV';
      return i;
    })
    .filter((v) =>
      v !== 'Expected'
      && v !== 'Campground'
      && v !== 'restroom'
      && v !== 'available'
      && v !== 'Pets'
      && v !== 'Pet'
      && !(v === 'Reservations' && depends_on)
    )
    .join(' ');

  if (r === 'Good for traveler types') r = 'Traveler types';
  if (r === 'Emergency service appointments') r = 'Emergency roadside'
  if (r === 'Camping permit required') r = 'Permit required'

  if (r === 'Potable (drinking) water') return 'Potable water'
  return r && r[0].toUpperCase() + r.slice(1, r.length);
}

export const getOptions = (options) => options && options.length > 0 && options.map(option => {
  if (typeof option.id === 'number') {
    if (option.name) {
      return { ...option, id: option.name }
    }
  };
  return option;
});

export const getValues = (property, value, opts) =>
  value && Array.isArray(value) && value.length > 0 ? value.map(n =>
    opts.find(option =>
      (option['id'] === n || option['name'] === n || (n && option['id'] == n['public_id']) )))
    .map(option => option && option[property]) : [];

export const getValue = (property, value, opts) =>
  getValues(property, [value], opts)[0]

export function getObjectDiff(obj1, obj2) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}
