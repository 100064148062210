import React from 'react';
import {SinglePathIcon} from "./SinglePathIcon";

export class IconPlay extends React.Component {
  render() {
    var props = $.extend({}, this.props, {
      path: "M20.46 13.02l-9.93-5.87a.98.98 0 0 0-1.05 0c-.33.2-.48.59-.48.96v11.76c0 .38.2.75.48.96.16.08.36.17.56.17a.8.8 0 0 0 .47-.17l9.93-5.86c.36-.2.56-.59.56-.96 0-.38-.18-.74-.54-1z",
      title: 'Play',
      className: 'play-media'
    })
    return (
      <SinglePathIcon {...props}/>
    );
  }
}


export class IconPlayHover extends React.Component {
  render() {
    const childProps = $.extend({}, this.props, {fillColor: "#EFA036"});
    return <IconPlay {...childProps} />;
  }
}
