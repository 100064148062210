export const cleanFieldName = name => {
  let cleanName = name;
  const nameParts = name.split('.');

  if (nameParts[0].endsWith('_details')) {
    nameParts[0] = nameParts[0].replace('_details', '');
    cleanName = nameParts.join(' ');
  } else if (nameParts[nameParts.length - 1] === '_destroy') {
    cleanName = 'Remove ' + nameParts.slice(0, nameParts.length - 1).join(' ');
  } else {
    // Just use the leaf part of the attribute path as the field name
    cleanName = nameParts[nameParts.length - 1];
  }

  return cleanName;
};
