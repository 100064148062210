import * as React from 'react';
import TagAttributes from './TagAttributes';
import AttributesSection from '../../library/attributes-section';
import { SortedAttributeSections } from './SortAttributes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CancelButton,
  RoundEditButton,
  SaveButton,
  RoundSuggestionButton,
  SecondaryButton,
} from "../../library/Buttons";
import cn from "classnames";
import useApi from "../../hooks/useApi";

import './styles';
import { Sections } from './sections';

enum Roles {
  SuperAdmin = 1,
  Admin,
  Editor,
  Auditor
}

export default function Attributes(props) {
  const { saving, error, dismissError, response, ...api } = useApi();
  const [savedMessage, setSavedMessage] = React.useState(null);
  const [showSavedMessage, setShowSavedMessage] = React.useState(null);
  const [savedHasError, setSavedHasError] = React.useState(null);
  const [viewMode, setViewMode] = React.useState<'tabbed' | 'classic'>('classic');

  const sortedSections = SortedAttributeSections(props);

  const initialParams = (sections) => {
    const collection = {};
    sections.forEach((section) => {
      collection[section.rootAttributeKey] = section.data;
    })

    return collection;
  }

  const [params, setParams] = React.useState(initialParams(sortedSections));

  const onChange = (attributes) => {
    const newParams = Object.assign({}, params, attributes);
    setParams(newParams);
  }

  const setResponseStatus = (message, hasError) => {
    setSavedMessage(message);
    setSavedHasError(hasError);
    setShowSavedMessage(true);

    setTimeout(() => {
      setShowSavedMessage(false);
      setSavedHasError(null);
    }, 4000);
  }

  const onSuccess = () => { setResponseStatus("Saved Successfully!", false) }
  const onFailure = () => { setResponseStatus("Error Saving Place", true) }

  const persistChanges = (e) => {

    if (e) e.preventDefault();
    api[props.method || "patch"](
      { listing: params },
      onSuccess,
      onFailure
    );
  };

  const changeViewMode = (mode: 'tabbed' | 'classic') => {
    switch (mode) {
      case 'classic':
        setViewMode('tabbed');
        break;
      case 'tabbed':
        setViewMode('classic');
        break;

      default:
        setViewMode('classic');
        break;
    }
  }

  return (
    <React.Fragment>
      <div data-cy="attributes" className="editor__attributes-container">{console.log(props.userRole, Roles.Admin)}
        {(props.userRole === Roles.Admin || props.userRole === Roles.SuperAdmin) &&
          <div
            title="Demo tab view (Admin Only)"
            onClick={() => { changeViewMode(viewMode) }}>{
              <FontAwesomeIcon icon={['fas', 'grip-horizontal']} />
            }</div>}
        {viewMode && viewMode === 'tabbed' ?
          <>
            Demo Prototype Tab View (Admin Only)
            <Sections
              sections={sortedSections}
              handleFieldOnChange={onChange}
              userEditingStrategy={props.userEditingStrategy}
            />
          </>

          : sortedSections.map((section) => (
            <React.Fragment key={section.sectionTitle}>
              <div id={section.sectionTitle.split(' ').join('-').toLowerCase()} />
              <AttributesSection
                {...section}
                onChange={onChange}
                key={section.sectionTitle}
                userEditingStrategy={props.userEditingStrategy}
              />
            </React.Fragment>
          ))}

        <div className="editor__attributes-container__controls">
          <div className={cn({
            "editor__attributes-container__controls--notify": true,
            "editor__attributes-container__controls--error": savedHasError,
            "editor__attributes-container__controls--success": !savedHasError,
            "editor__attributes-container__controls--notify__visible": showSavedMessage
          })}><span>{savedMessage}</span></div>
          {props.userEditingStrategy === "update" ? (
            <>
              <SaveButton
                cy="save-button"
                disabled={saving}
                onSave={(e) => persistChanges(e)}
                id={props.saveButtonId}
              />
            </>
          ) : (
            <SecondaryButton
              cy="suggest-button"
              disabled={props.saving || props.disable}
              onClick={(e) => props.persistChanges(e)}
              id={props.suggestionsButtonId}
            >
              {"Suggest updates"}
            </SecondaryButton>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
