// "cat" => #22222
export function stringToColor(str) {
  let hash = 10;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 25) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}

export function lightOrDarkText(hexcolor){
  const r = parseInt(hexcolor.substr(1,2),16);
  const g = parseInt(hexcolor.substr(3,2),16);
  const b = parseInt(hexcolor.substr(4,2),16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  // Modify threshold to change sensitivity
  const threshold = 140;
  return (yiq <  threshold) ? 'white' : 'black';
}
