import React from 'react';
import * as ajax from '@/helpers/ajax';
import IconEdit from '../../icons/IconEdit';
import { useStoreContext } from '../../state';

const roles = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Editor',
  4: 'Auditor',
};

type Props = {
  avatarUrl?: string;
  signOutPath: string;
  name: string;
  organizations: any[];
  editPath: string;
  role: number;
  currentOrg?: { name: string; id: number; source_id: number };
  linkedToRoadpass: boolean;
};

function AccountFlyOut(props: Props) {
  const { analytics } = useStoreContext();

  const changeOrg = async (id) => {
    const res = await ajax.put('/organizations/switch/', { id });
    if (res.ok) {
      // We reload to make sure all the state gets updated, otherwise we need to make a number of
      // changes to make sure all relics of the old organization are cleaned up
      location.reload();
    }
  };

  return (
    <ul
      className="editor__site-nav__account-menu navbar-nav avatar-container"
      onClick={() => {
        analytics.track('User Flyout', 'opened');
      }}
    >
      <div className="dropdown">
        <div data-cy="user-avatar" className="editor__user__avatar avatar clickable" data-toggle="dropdown">
          {props.avatarUrl && <img src={props.avatarUrl} />}
        </div>
        <div className="dropdown-menu dropdown-menu-right user-menu">
          <div className="user-info">
            <div
              className="user-name"
              data-cy="user-name"
              onClick={() => {
                analytics.track('Edit Account', 'viewed', { canonical_place_id: null });
              }}
            >
              {props.name}
              <a className="editor__user-account__edit-icon" href={props.editPath}>
                <IconEdit />
              </a>
            </div>
            <div className="mb-2">{roles[props.role]}</div>
            <div className="user-attributes">
              <>
                <div>
                  <b>{props.currentOrg.name}</b>
                  <div className="mt-2">Source: {props.currentOrg.source_id}</div>
                  <br />
                  {props.organizations
                    .filter((org) => org.name !== props.currentOrg.name)
                    .map((org) => (
                      <React.Fragment key={org.name}>
                        <i>
                          <a href="" onClick={() => changeOrg(org.id)}>
                            switch to {org.name}
                          </a>
                        </i>
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              </>
              <>
                {!props.linkedToRoadpass && (
                  <div className="mt-2">
                    <i>
                      <a href={'/roadpass_login?return_to=' + encodeURI(window.location.href)}>Link to Roadpass</a>
                    </i>
                  </div>
                )}
              </>
            </div>
          </div>
          <a className="link" href={props.signOutPath}>
            Sign out
          </a>
        </div>
      </div>
    </ul>
  );
}

export default AccountFlyOut;
