import React from 'react';
import * as Buttons from '../../library/Buttons';
import * as ajax from '@/helpers/ajax';
import HTML5Backend from 'react-dnd-html5-backend';
import TargetBox from '../../library/TargetBox';
import createDOMPurify from 'dompurify';
import { DndProvider } from 'react-dnd';
import { DropTargetMonitor } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useAsyncEffect } from 'use-async-effect';
import './styles/index';

const { FILE } = NativeTypes;

type Props = {
  mapIllustration?: string;
};

const Container = (props: Props) => {
  const DOMPurify = createDOMPurify(window);

  const originalFile = DOMPurify.sanitize(props.mapIllustration);

  const [file, setFile] = React.useState(originalFile);
  const accepts = React.useMemo(() => [FILE], []);
  const inputFile = React.useRef(null);

  const reader = new FileReader();

  React.useEffect(() => {
    if (props.mapIllustration) {
      preview();
    }
  }, [props.mapIllustration]);

  useAsyncEffect(
    async () => {
      if (file != originalFile) {
        const r = await ajax.put(document.location.pathname, {
          listing: {
            road_trip_details: {
              map_illustration: file
            }
          }
        });
        const j = await r.json();
      }
    },
    null,
    [file]
  );

  const preview = (f = file) => {
    const span = document.createElement('span');
    span.innerHTML = f;
    document.getElementById('uploads').insertBefore(span, null);
  };

  const sanitizeAndPreview = f => {
    // We aren't showing the error to the user yet
    if (f) {
      if (!f.type.match('svg')) {
        console.debug('[Error] file must be an svg');
        return;
      }

      reader.onload = e => {
        // @ts-ignore
        const t = DOMPurify.sanitize(e.target.result);
        setFile(t);
        preview(t);
      };
      reader.readAsText(f);
    }
  };

  const handleFileDrop = (item: any, monitor: DropTargetMonitor) => {
    if (monitor) {
      sanitizeAndPreview(monitor.getItem().files[0]);
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  // @ts-ignore
  return (
    <DndProvider backend={HTML5Backend}>
      <TargetBox accepts={accepts} onDrop={handleFileDrop}>
        {({ isOver, canDrop }) => (
          <div className="editor__listing boone-rt__map-illustration">
            <h4 className="mt-5"> Map Illustration </h4>
            <div
              className="well container boone-rt__map-illustration__drop-area"
              style={{
                border: (canDrop && isOver && 'red solid 1px') || (canDrop && 'red dashed 1px') || ''
              }}
            >
              {(isOver || canDrop) && (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  Drop your SVG <br /> to upload
                </div>
              )}
              {file && !(isOver || canDrop) ? (
                <>
                  <div id="uploads" />
                  <a
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      setFile('');
                    }}
                  >
                    Remove
                  </a>
                </>
              ) : canDrop || isOver ? null : (
                <Buttons.RoundAddButton onClick={onButtonClick} />
              )}
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={evt => {
                  sanitizeAndPreview(evt.target.files[0]);
                }}
              />
            </div>
          </div>
        )}
      </TargetBox>
    </DndProvider>
  );
};

export default Container;
