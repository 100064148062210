import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../library/Avatar';

// use order css property to rearrange wording
export const Assignment = ({ name = 'Anyone', url, booneLogo, ...props }) => (
  <div className="audit-assignment" {...props}>
    <Avatar url={url} booneLogo={booneLogo} />
    <b className="assigned-to">Assigned to</b>
    <span className="name">{name}</span>
  </div>
);

Assignment.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  booneLogo: PropTypes.bool
};

export default Assignment;
