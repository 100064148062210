
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

// @ts-ignore
const allFiles = (ctx => {
  let keys = ctx.keys();
  let values = keys.map(ctx);
  let seen = [];
  return keys.reduce( (o, k, i) => {

    const m = k.split('/');
    const t = m.slice(1, m.length - 1).map(r => upperFirst(camelCase(r))).filter(n => n !== 'Components');

    if (seen.indexOf(t[0]) === -1) {
      seen = [...seen, t[0]];
      o[t[0]] = null;
    }
    o[t.join('')] = values[i].default;
    return o;
  }, {});
  // @ts-ignore
})(require.context('@', true, /examples.(tsx|mdx)$/));

export default allFiles;
