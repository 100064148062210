import * as React from 'react';

type Props = {
  fill?: string;
};

export default function IconSuggestion({ fill = '#de6a60' }: Props) {
  return (
    <svg
      className='icon-suggestion'
      fill={fill}
      width="28"
      height="28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M17.7538462 12.9230769h-6.923077c-.3823509 0-.6923077-.3099567-.6923077-.6923077 0-.3823509.3099568-.6923077.6923077-.6923077h6.923077c.3823509 0 .6923076.3099568.6923076.6923077 0 .382351-.3099567.6923077-.6923076.6923077zm0 2.7692308h-6.923077c-.3823509 0-.6923077-.3099567-.6923077-.6923077s.3099568-.6923077.6923077-.6923077h6.923077c.3823509 0 .6923076.3099567.6923076.6923077s-.3099567.6923077-.6923076.6923077zm3.066923 6.2307692c-.2236953-.0016951-.4431344-.0613253-.636923-.1730769l-2.0076924-1.2115385a.51923401.51923401 0 0 0-.1384615 0c-1.2204638.3625799-2.4759522.594648-3.7453846.6923077-4.96384616 0-9.00000001-3.4130769-9.00000001-7.6153846C5.29230769 9.41307692 9.32846154 6 14.2923077 6c4.9638461 0 9 3.41307692 9 7.6153846-.0062481 1.3140341-.3986721 2.5972366-1.1284615 3.69-.0912281.1352377-.1416904.2938338-.1453847.4569231l.1246154 2.5961538c.0709092.5186954-.1567916 1.0330313-.5884615 1.3292308-.2150482.1511391-.4710075.2332392-.7338462.2353846zm-2.6653846-2.7692307a1.87614918 1.87614918 0 0 1 .4015385.0415384.69230867.69230867 0 0 1 .2007692.0830769l2.0007692 1.1769231-.1246153-2.6861538c-.0030253-.4456119.1271575-.8819654.3738461-1.253077.5793692-.8570727.8924857-1.8662713.9-2.9007692 0-3.4615384-3.4130769-6.23076922-7.6153846-6.23076922-4.2023077 0-7.61538462 2.76923082-7.61538462 6.23076922 0 3.4615385 3.41307692 6.2307692 7.61538462 6.2307692a17.25230024 17.25230024 0 0 0 3.3923077-.5953846 1.85538226 1.85538226 0 0 1 .4707692-.096923z"
          id="a"
        />
      </defs>
      <use fillRule="nonzero" xlinkHref="#a" />
    </svg>
  );
}
