import React from 'react';
import './styles';
import * as ajax from '@/helpers/ajax';
import CheckboxWithLabel from '@library/CheckboxWithLabel';
import DropdownForm from '@library/dropdown-form';
import TextArea from "@library/text-area";
import {DateTime} from "luxon";
import {stringifyBools, stringifyNoteAndAuthor} from '@/helpers/audit'
import {Assignment} from "@/audit/Assignment";


type Props = {
  canonicalPlaceId: string;
  audit: {
    id: number;
    avatar_url: string;
    user_id: number;
    user_name: string;
  }
};

const AuditForm = (props: Props) => {
  const [validated, setValidated] = React.useState(false);
  const [resolved_duplicates, setResolvedDuplicates] = React.useState(false);
  const [general_update, setGeneralUpdate] = React.useState(false);
  const [contact_update, setContactUpdate] = React.useState(false);
  const [note, setNote] = React.useState('');
  const [audit, setAudit] = React.useState(null);
  const [remove_from_queue, setRemoveFromQueue] = React.useState(false);

  async function loadLatestAudit() {
    const [promise] = ajax._get(
      "/listings/audits/" + props.canonicalPlaceId + ".json/?limit=1&include_note_only_audits=1"
    );
    const json = await promise;

    if (json) {
      setAudit(json.data[0]);
    }
  }

  React.useEffect(() => {
    if (!audit) {
      loadLatestAudit();
    }
  }, []);

  const reset = function () {
    setValidated(false);
    setResolvedDuplicates(false);
    setGeneralUpdate(false);
    setContactUpdate(false);
    setNote('');
  };
  const onCreate = function () {
    ajax.post('/audits/', {
      canonical_place_id: props.canonicalPlaceId,
      validated: validated,
      resolved_duplicates: resolved_duplicates,
      general_update: general_update,
      contact_update: contact_update,
      note: note,
      remove_from_queue: remove_from_queue
    });
    reset();
  };

  const onCancel = function () {
    reset();
  };

  return (
    <DropdownForm active={false} label={'Audit'} name={'audit'} onCancel={onCancel} onSave={onCreate}>
      <div>
        <div className="d-flex">
          <div className="flex-column">
            <div className="audit-form__checkbox">
              <CheckboxWithLabel
                labelClassName="audit-form audit-form__input-label"
                labelId="audit-validated-checkbox"
                label="Validated"
                onChange={() => setValidated((n) => !n)}
                checked={Boolean(validated)}
              />
            </div>
            <div className="audit-form__checkbox">
              <CheckboxWithLabel
                labelClassName="audit-form audit-form__input-label"
                labelId="audit-duplicates-checkbox"
                label="Researched and resolved duplicates"
                onChange={() => setResolvedDuplicates((n) => !n)}
                checked={Boolean(resolved_duplicates)}
              />
            </div>
            <div className="audit-form__checkbox">
              <CheckboxWithLabel
                labelClassName="audit-form audit-form__input-label"
                labelId="audit-general-update-checkbox"
                label="Updated from general sources"
                onChange={() => setGeneralUpdate((n) => !n)}
                checked={Boolean(general_update)}
              />
            </div>
            <div className="audit-form__checkbox">
              <CheckboxWithLabel
                labelClassName="audit-form audit-form__input-label"
                labelId="audit-contact-update-checkbox"
                label="Spoke to management, updated"
                onChange={() => setContactUpdate((n) => !n)}
                checked={Boolean(contact_update)}
              />
            </div>
          </div>

          <div className="flex-column audit-form__note">
            <TextArea value={note}
                      placeholder={'Note'}
                      onChange={e => setNote(e.target.value)}
                      rows={6}
            />
            {props.audit.id &&
            <div className="audit-form__checkbox">
              <CheckboxWithLabel
                labelClassName="audit-form audit-form__input-label"
                labelId="audit-remove-from-queue"
                label="Remove from audit queue"
                onChange={() => setRemoveFromQueue((n) => !n)}
                checked={Boolean(remove_from_queue)}
              />
              <Assignment
                name={props.audit.user_name || 'Anyone'}
                url={props.audit.avatar_url}
                booneLogo={!props.audit.user_id}
              />
            </div>
            }
            <div className="text-right font-italic">
              All fields optional
            </div>
          </div>
        </div>

        <div>
          {audit ?
            <>
              <div className="audit-form__recent-audit-label">Most Recent Audit ({DateTime.fromISO(audit.created_at).toLocaleString()}):</div>
              <span>{stringifyBools(audit) + ': ' + stringifyNoteAndAuthor(audit)}</span>
            </>
            : "No previous audits"}
        </div>
      </div>
    </DropdownForm>
  );
};

export default AuditForm;
