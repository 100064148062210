import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Input from '@library/Input';
import Label from '@library/label';
import Switch from '@library/Switch';
import './styles/index';

const EMPTY = '';
const EMPTY_PLACEHOLDER = 'Empty';
const EMPTY_BUTTON_TEXT = 'empty';

const UNKNOWN = null;
const UNKNOWN_PLACEHOLDER = 'Unknown';
const UNKNOWN_BUTTON_TEXT = 'unknown';

class InputNoneUnknown extends PureComponent {
  state = {
    placeholder: this.props.value === '' ? EMPTY_PLACEHOLDER : UNKNOWN_PLACEHOLDER
  };

  componentDidMount() {
    if (this.props.value === undefined) {
      this.props.onChange(null);
    }
  }

  handleChange(value, source) {
    // Handle switch control click
    if (source === 'switch') {
      this.setState({ placeholder: value === EMPTY ? EMPTY_PLACEHOLDER : UNKNOWN_PLACEHOLDER });
      this.props.onChange(value);
    } else if (value === '') {
      this.setState({ placeholder: UNKNOWN_PLACEHOLDER }, () => {
        this.props.onChange(null);
      });
    } else {
      this.props.onChange(value);
    }
  }

  render() {
    var placeholder = this.state.placeholder;
    if (this.props.hideLabel) {
      placeholder = (this.state.placeholder == UNKNOWN_PLACEHOLDER) ? `${this.props.label} ${UNKNOWN_PLACEHOLDER}` : this.state.placeholder;
    }

    return (
      <div data-cy="input-none-unknown" className="editor__input-none-unknown">
        {(this.props.label && !this.props.hideLabel) && (
          <Label
            description={this.props.description}
            id={this.props.labelId}
            text={this.props.label}
            onClick={e => this.props.onClick(e)}
          />
        )}
        <div className="editor__input-none-unknown__switch">
          <Input
            cy="input-none-unknown-input"
            labelId={this.props.labelId}
            className={this.props.inputClassName}
            type={this.props.type}
            value={this.props.value || ''}
            onChange={e => this.handleChange(e.target.value)}
            placeholder={placeholder}
            disabled={this.props.disabled}
          />

          <Switch
            value={this.props.value}
            switchLabel={this.props.switchLabel}
            rightLabel={EMPTY_BUTTON_TEXT}
            rightValue={EMPTY}
            leftLabel={UNKNOWN_BUTTON_TEXT}
            leftValue={UNKNOWN}
            leftCy={UNKNOWN}
            rightCy={EMPTY}
            onChange={value => this.handleChange(value, 'switch')}
          />
        </div>
      </div>
    );
  }
}

InputNoneUnknown.defaultProps = {
  switchLabel: 'Set as'
};

InputNoneUnknown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  switchLabel: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  labelId: PropTypes.string,
  labelClassName: PropTypes.string,
  description: PropTypes.string
};

export default InputNoneUnknown;
