import React, { useState, useEffect } from 'react';
import * as util from "@library/place-details/util";
import ThumbnailLink from "@library/place-details/thumbnail-link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuditQueue, CanonicalPlace, User } from '../../common/types';
import YelpLogo from '@/listing/header/components/yelp-logo';
import TripAdvisorLogo from '@/listing/header/components/ta-logo';
import { RoadtrippersLogo } from '@/listing/header/components/rt-logo';
import { ListingDetails } from './ListingDetails';
import CampendiumLogo from "@/listing/header/components/campendium-logo";

interface Props {
  result: CanonicalPlace;
  onUpdateResults: Function;
  index: number;
  start?: number;
  focusRef?: number;
  scrollTo?: any;
  showAllDetails?: boolean;
  onHover?: Function;
  onLoading?: Function;
  auditQueue: AuditQueue;
  OnRemoveAudit: Function;
  auditShow: boolean;
  auditToggle: Function;
  selectedAuditUser: User;
  onSelectedAuditUser: Function;
  selectedAuditListing: CanonicalPlace;
  onSelectedAuditListing: Function;
  onAuditAction: Function;
}

export const Listing = ({ result, index, start, ...props }: Props) => {


  const handleOnHover = (event: any) => {
    const { id } = event.target;
    const focusId = parseInt(id);

    props.onHover(focusId);
  };

  const constructAddress = (location) => {
    if (location) {
      return [location.address1, location.city, location.state, location.postal_code, location.country].filter(x => x).join(', ');
    } else {
      return '';
    }
  };

  const handleAuditPrompt = (event?: any) => {
    props.onSelectedAuditListing(result);
    props.auditToggle();
  };

  const handleCallout = (props) => {
    const yelpIcon =
      <a href={props.branded_attribution_url ? props.branded_attribution_url : '#'}>
        <div className="editor__place-summary__branded-attribution__logo red callout-icon">
          <YelpLogo fill="white" width={45} height={15} />
        </div>
      </a>;

    const extraordinaryIcon =
      <div className="editor__place-summary__branded-attribution__logo green callout-icon">
        <RoadtrippersLogo fill="white" width={25} height={15} />
      </div>;

    const taAttribution = props.attribution && props.attribution.find((a) => a.source_name === 'tripadvisor');
    const tripAdvisorIcon =
      <a href={taAttribution ? taAttribution.source_item_url : '#'}>
        <div className="editor__place-summary__branded-attribution__logo blue callout-icon">
          <TripAdvisorLogo fill="white" width={35} height={15}/>
        </div>
      </a>;

    const campendiumAttribution = props.attribution && props.attribution.find((a) => a.source_name === 'campendium');
    const campendiumIcon =
      <a href={campendiumAttribution ? campendiumAttribution.source_item_url : '#'}>
        <div className="editor__place-summary__branded-attribution__logo blue callout-icon">
          <CampendiumLogo fill="white" width={25} height={25}/>
        </div>
      </a>;

    return props.branded_attribution ?
      props.branded_attribution == 'yelp' &&
      {icon: yelpIcon, type: 'yelp'}
      :
      props.road_trip_details && props.road_trip_details.map_illustration ?
        {icon: extraordinaryIcon, type: 'extraordinary'}
        :
        taAttribution ?
        {icon: tripAdvisorIcon, type: 'tripadvisor'}
        :
          campendiumAttribution &&
          {icon: campendiumIcon, type: 'campendium'}
  };

  // Handles toggling between 'Assign Audit' and the current auditor depending on if one is assigned
  const handleAssignAuditOrLable = (result: CanonicalPlace) => {
    const userById = (): User => {
      const selectedUser = props.auditQueue.users.filter(
        (user: User) => (
          result.listing_audit &&
          result.listing_audit.user_id === user.id))[0];

      const useAnyoneIfNoUser = selectedUser ? selectedUser : props.auditQueue.anyone;
    
      return useAnyoneIfNoUser;
    };

    return (
      result.listing_audit ?
        <>
          <span className="label">Audit assigned to: </span>
          <div className="listing__audit-content">
            <div className="__audit-icon">
              {userById().avatar_url ?
                <img src={userById().avatar_url} />
                :
                <FontAwesomeIcon icon={'user-circle'} />
              }
            </div>
            <div className="__audit-name">
              {userById().name}
            </div>
            <div className="__audit-options">
              <a href="#" className="__audit-remove" onClick={
                (event: any) => { 
                event.preventDefault();
                props.OnRemoveAudit(result);
                 }}>
                Remove
              </a>
              <span> | </span>
              <a href="#" className="__audit-update" onClick={
                (event: any) => {
                  event.preventDefault();
                  props.onAuditAction('update');
                  props.onSelectedAuditUser(() => userById());
                  handleAuditPrompt();
                }
              }>
                Update
              </a>
            </div>
          </div>
        </> :
        <a href="#"
          onClick={(event: any) => {
            event.preventDefault();
            props.onAuditAction('create');
            handleAuditPrompt();
          }}>
          Assign Audit
        </a>
    );

  };

  return (
    <div onMouseEnter={handleOnHover} ref={(ref) => (props.scrollTo.current[result.id] = ref)} className={`row list-row ${props.focusRef ? result.id === props.focusRef ? '--inFocus' : '' : ''}`} id={`${result.id}`}>
      <div className={`col-sm-3 image${handleCallout(result) ? ' --' + handleCallout(result)?.type : ''}`}>{
        result.images && result.images.length > 0 &&
        <ThumbnailLink
          detailsViewPath={`/listings/${result.id}`}
          thumbnailUrl={util.findThumbnailUrl(result.images)}
        />
      }</div>
      <div className="col-8 name">
        <div className="listing_header">
          <div className="start">
            <a href={`/listings/${result.id}`} className="name">
              {`${index + start}. `}
              {result?.name}
            </a>
          </div>
          <div className="end">
            {handleCallout(result) && handleCallout(result)?.icon}
          </div>
        </div>
        <div className="result_meta">
          <small className="address">
            {constructAddress(result?.locations[0])}
          </small>
          <small>
            {handleAssignAuditOrLable(result)}
            
          </small>
          {props.showAllDetails &&
            <small>
              <ListingDetails
                {...result}
              />
            </small>
          }
        </div>

      </div>


    </div>
  )
}
