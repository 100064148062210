import React from 'react';
import './styles/index';
import SelectionItem from '@library/tree-select/search-tree/selection-item';
import { useTransition, useSpring, animated } from 'react-spring';
import useMeasure from '@/hooks/useMeasure';

function AssignedCategories({ saved, pending, ancestorMap, getNode, remove }){
  const [bind, d] = useMeasure();

  const transitions = useTransition(pending, (item) => item, {
    from: { transform: 'translate3d(-40px,0,0)', opacity: 0 },
    enter:
      {
        transform: 'translate3d(0,0px,0)',
        opacity: 1
      },
    leave:
      {
        transform: 'translate3d(-40px,0,0)',
        opacity: 0
      },
    trail: 200
  });

  const sp = useSpring({
    transition: 'height 0.08s ease'
  });

  return (
    <animated.div style={sp}>
      <br />
      <h4>Assigned</h4>
      <animated.div className="editor__categories__assigned" {...bind}>
        {transitions.map(({ item, props, key }) => {
          const t = ancestorMap[item];
          const ancestors = (t ? t.slice(0, -1) : []).map((a) => getNode(a));
          const node = getNode(item);
          return (
            <animated.div
              style={props}
              key={key}
              className="editor__search-tree__selection-item__animator"
            >
              <SelectionItem
                ancestors={ancestors}
                name={node.name}
                public_id={item}
                remove={remove}
                pending={saved.indexOf(item) === -1}
              />
            </animated.div>
          );
        })}
      </animated.div>
    </animated.div>
  );
}

export default AssignedCategories;
