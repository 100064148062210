type Datum = {
  id: number;
};

type NormalizedData = {
  ids: number[];
  byId: { [id: number]: Datum };
};

export default function normalizeData(data: Datum[]) {
  if (!data || data.length === 0) {
    return { ids: [], byId: {} };
  }
  return data.reduce(
    (p, c): NormalizedData => {
      p.ids.push(c.id);
      p.byId[c.id] = c;
      return p;
    },
    { ids: [], byId: {} }
  );
}
