import React, { HTMLAttributes, ReactNode, useState } from 'react';
import './styles.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Props {
  children?: ReactNode;
  name?: string;
  icon?: IconProp;
  url?: string;
  onClick?: Function;
  options?: Array<Props>;
  collapsed?: boolean;
  setCollapsed?: Function;
  disabled?: boolean;
  parentCollapsed?: boolean;
  isSubOption?: boolean;
  adminOnly?: boolean;
  dropDownOnly?: boolean;
  setActive?: (tab: string) => void;
}

export const NavOption = ({ name = 'Default', collapsed = true, parentCollapsed = false, disabled = false, ...props }: Props) => {

  const [drawerCollapsed, setDrawerCollapsed] = useState(collapsed);

  const onClick = (event: any) => {
    event.preventDefault();

    if (props.url) {
      (window as Window).location.href = props.url;
      return;
    }

    if (!props.isSubOption && !props.dropDownOnly && !props.url) {
      props.setActive(name.toLowerCase());
      (window as Window).location.hash = name.toLowerCase();
    }

    if (props.onClick) {
      props.onClick(event);
    }
  }

  const onToggle = () => {
    setDrawerCollapsed &&
      setDrawerCollapsed(!drawerCollapsed);

  };

  const icon = () => {
    if (props.icon) {
      return props.icon;
    } else {
      return ['fas', 'circle'] as IconProp;
    }
  };

  return (
    !disabled ?
      <>
        <div className={`__option ${props.isSubOption ? '--sub' : ''} ${props.url ? '--link' : ''} ${props.adminOnly ? '--admin' : ''}`} >
          <a href={props.url ? props.url : ""}
            title={name}
            onClick={(event: any) => {
              event.preventDefault();
              props.options && props.options.length > 0 && onToggle();

              onClick(event);
            }}>
            <FontAwesomeIcon icon={icon()} />
            {!parentCollapsed && <span className={'pr-2'}>{name}</span>}
            {props.options &&
              props.options.length > 0 &&
              <div className='tree-chevron'>
                <FontAwesomeIcon icon={drawerCollapsed ? ['fas', 'angle-right'] : ['fas', 'angle-down']} />
              </div>
            }
          </a>
          {props.options &&
            props.options.length > 0 &&
            !drawerCollapsed &&
            <div className="__sub_options">
              {
                props.options.map((option: Props) =>
                  <NavOption
                    {...option}
                    key={option.name}
                    parentCollapsed={parentCollapsed}
                    isSubOption={true}
                    dropDownOnly={props.dropDownOnly}
                    setActive={props.setActive}
                  />
                )
              }
            </div>
          }
        </div>
      </>
      :
      <></>
  )
}
