import * as React from 'react';
import JSONPretty from 'react-json-pretty';
import './styles'

function PrettyJSON(props) {
  return (
    <JSONPretty themeClassName="editor__raw__json-pretty" json={props.json}></JSONPretty>
  );
}

export default PrettyJSON;
