import React from "react";
import FormV1 from "./form";
import {useContext} from "@/data-outreach/_core/forms/useContext";
import useSteps from "./useSteps";
import * as hooks from "@/library/launch-darkly/hooks";

type Context = {
  state?: {
    mobile_service?: boolean;
    service_center?: boolean;
  };
};

function ConnectorV1() {
  const [enabled] = hooks.useFlag("rv-service-outreach", false);

  const ctx: Context = useContext();
  const steps = useSteps();

  return (
    <React.Fragment>
      {enabled ? (
        <FormV1 data={ctx} steps={steps} />
      ) : (
        <p className="editor__well">:/ Page not found</p>
      )}
    </React.Fragment>
  );
}

export default ConnectorV1;
