import React from 'react';
import PropTypes from 'prop-types';
import openImagePicker from '../helpers/imageUpload';

class AvatarEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { avatarUrl: props.avatarUrl || '' };
  }

  render() {
    return (
      <div className="avatar-container-lg clickable" onClick={this.handleClick}>
        <input
          type="hidden"
          className="form-control"
          name="user[avatar_url]"
          id="user_avatar_url"
          value={this.state.avatarUrl}
        />

        <div className="avatar center-parent">
          {this.state.avatarUrl && <img src={this.state.avatarUrl} />}

          {this.state.avatarUrl && <div className="overlay" />}
        </div>
      </div>
    );
  }

  handleClick = () => {
    openImagePicker(this.props.filestackApiKey, this.handleFilestack, this.props.filestackStoragePath);
  };

  handleFilestack = response => {
    let url = response.filesUploaded[0]['url'];
    this.setState({ avatarUrl: url });
  };
}

AvatarEditor.propTypes = {
  avatarUrl: PropTypes.string,
  filestackStoragePath: PropTypes.string.isRequired,
  filestackApiKey: PropTypes.string.isRequired
};

export default AvatarEditor;
