const setMediaFeatured = function(apiUrl, id, featured, targetKey) {
  let dataObject = { [targetKey]: { featured: featured } };
  $.ajax({
    url: apiUrl + id,
    credentials: 'same-origin',
    method: 'PATCH',
    data: JSON.stringify(dataObject),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  });
};

const deleteMedia = function(apiUrl, id, ownerKey, ownerId) {
  let dataObject = { [ownerKey]: ownerId };
  $.ajax({
    url: apiUrl + id,
    credentials: 'same-origin',
    method: 'DELETE',
    data: JSON.stringify(dataObject),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  });
};

const unflagMedia = function(apiUrl, id) {
  $.ajax({
    url: apiUrl + id + '/unflag',
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  });
};

export default {
  setMediaFeatured,
  deleteMedia,
  unflagMedia
};
