import * as _ from 'lodash';

type Media = {
  id: number;
  from_verified_owner: boolean;
  from_your_org: boolean;
  flags?: {
    reason: string;
  }[];
  featured: boolean;
};

type FilteredMedia = {
  fromAll: number[];
  fromOthers: number[];
  fromOwner: number[];
  fromOrg: number[];
};

export default function filterMedia(media: Media[]): FilteredMedia {
  if (!media) return {
    fromAll: [],
    fromOwner: [],
    fromOrg: [],
    fromOthers: []
  }
  return media.reduce(
    (p, c): FilteredMedia => {
      // Filter out disabled images, return p
      if (!c || (c.flags && c.flags.find(flag => flag.reason === 'disabled'))) return p;

      if (p.fromAll.indexOf(c.id) === -1) {
        p.fromAll.push(c.id);

        if (c.from_your_org) p.fromOrg.push(c.id);

        if (c.from_verified_owner && !c.from_your_org) p.fromOwner.push(c.id);

        if (!c.from_your_org && !c.from_verified_owner) p.fromOthers.push(c.id);
      }

      return p;
    },
    {
      fromAll: [],
      fromOwner: [],
      fromOrg: [],
      fromOthers: []
    }
  );
}
