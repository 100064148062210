import React from "react";
import Button from "@/library/_button";
import { removeFilter } from "@/helpers/applyFilter";
import * as filters from "./definitions";
import FilterSet from "@library/filter-set";
import "./styles";

function SearchFilters(props: {
  launchDarkly?: LaunchDarkly;
  replacedIds: string[] | null;
  data: any;
  filters?: { [key: string]: any }[];
  isSuperAdmin: boolean;
}) {
  const [clearing, setClearing] = React.useState(false);

  const Filters = filterSetHOC(props.launchDarkly, props.replacedIds, clearing);
  return (
    <div className="editor__search__filters" id="search-filters">
      {props.isSuperAdmin &&
        <Filters
          filters={[
            filters.sources(props.data.sources),
            filters.numSources(),
            filters.numPlaces(),
          ]}
        />
      }

      <Filters
        filters={[
          filters.regions(props.data.regions),
          filters.chains(props.data.chains),
          filters.categories(props.data.categories),
        ]}
      />

      <Filters
        filters={[
          filters.phone(props.data.phone),
          filters.postal_code(props.data.postal_code)
        ]}
      />
      {props.isSuperAdmin &&
        <Filters
          filters={[filters.numImages(), filters.numReviews(), filters.ratings()]}
        />
      }
      <Filters
        filters={[
          props.isSuperAdmin && filters.fidelity(),
          filters.audit_queue(props.data.audit_queue),
          filters.needing_audit(props.data.needing_audit),
        ]}
      />

      <div className="editor__search__filters__set">
        <Button
          className="editor__button--primary"
          onClick={() => {
            removeFilter([], props.replacedIds);
            setClearing((c) => !c);
          }}
        >
          clear
        </Button>
      </div>
    </div>
  );
}

function filterSetHOC(launchDarkly, replacedIds, clearing) {
  return ({ filters }) => (
    <div className="editor__search__filters__set">
      <FilterSet
        launchDarkly={launchDarkly}
        replacedIds={replacedIds}
        filters={filters}
        clearing={clearing}
      />
    </div>
  );
}

export default SearchFilters;
