import React, { useState, useEffect } from 'react';
import { Props as iOption } from '../layout/sidebar/NavOption';
import { FilterData, LaunchDarkly, NavLinks } from '../common/types';
import { ListingsView, Results } from '../components/listingsView';
import { getQueryStringValue } from "@/helpers/parseQueryString";
import Pagination from "@/helpers/pagination";
import resultsApi from '../api/results';
import useApi from '../hooks/useApi';

export interface Props {
  setNavOptions?: Function;
  isSuperAdmin?: boolean;
  data: FilterData;
  launchDarkly: LaunchDarkly;
  navLinks: NavLinks;
  onLoading: Function;
}

export const Search = ({ setNavOptions, ...props }: Props) => {
  const [pagination, setPagination] = useState<Pagination>();
  const [sort, setSort] = useState<string>(null);
  const [bulkAssignAuditToggle, setBulkAssignAuditToggle] = useState(false);
  const [createListingShow, setCreateListingShow] = useState(false);
  const [pageSize, setPageSize] = useState(20);

  const getResultsApi = useApi(resultsApi.getResults);

  // Utility Functions

  // Populates sidebar with options relevant to page
  const getSidebarOptions = (updatedSearchResults: Results) => {

    // Nested options for search option
    const adminOptions: Array<iOption> = [
      { name: 'Match', icon: 'layer-group', url: props.navLinks.match.path },
      { name: 'Corrections', icon: 'check', url: props.navLinks.corrections.path },
    ];

    const searchOptions: Array<iOption> = [
      {
        name: 'Create New Listing', icon: 'plus', onClick: () => {
          setCreateListingShow(true);
        }
      },
      {
        name: 'Bulk Assign Audits', icon: 'file-contract', onClick: () => {
          updatedSearchResults && updatedSearchResults.data.length > 0 &&
            setBulkAssignAuditToggle(true)
        }
      }
    ];

    // Option definitions
    const options: Array<iOption> = [
      { name: 'Search ', icon: 'search', options: searchOptions, collapsed: true, dropDownOnly: true },
      { name: 'Admin', icon: 'bolt', options: adminOptions, adminOnly: true, collapsed: true, dropDownOnly: true },
      { name: 'Organization ', url: props.navLinks.organization.path, icon: 'users' },
      { name: 'Keys ', url: props.navLinks.keys.path, icon: 'key', adminOnly: true },
      { name: 'Imports ', url: props.navLinks.imports.path, icon: 'file-import' },
      { name: 'Support ', url: props.navLinks.support.path, icon: 'headset' }
    ];

    return options;
  }

  // Sets window global for calling search update across the app
  (window as any).updateSearch = () => {
    getResultsApi.request();
  };


  // Use Effects 

  // Gets initial request data on load
  useEffect(() => {
    getResultsApi.request();
  }, []);

  useEffect(
    () => {
      // Sets initial nav options when api data is available
      setNavOptions(getSidebarOptions(getResultsApi.data));

      // Resets and cleans up the nav bar for future mutating
      return () => setNavOptions({});
    }, [getResultsApi.data]
  );

  // Update pagination whenever the search results change
  useEffect(() => {
    const pageNumber = parseInt(getQueryStringValue('page', 1));
    const newPagination = new Pagination(pageNumber, pageSize, getResultsApi.data?.meta.total || 0);
    const sort = getQueryStringValue('sort', null);

    setPagination(newPagination);
    setSort(sort);
  }, [getResultsApi.data]);

  // Handles loading state
  useEffect(() => {
    props.onLoading(getResultsApi.loading);
  }, [getResultsApi.loading]);


  return (
    <>
      {getResultsApi.error && <p>{getResultsApi.error}</p>}
      {getResultsApi.data && pagination &&
          <ListingsView
            {...props}
            sort={sort}
            pagination={pagination}
            results={getResultsApi.data}
            onLoading={props.onLoading}
            bulkAssignAuditShow={bulkAssignAuditToggle}
            bulkAssignAuditToggle={setBulkAssignAuditToggle}
            createListingShow={createListingShow}
            createListingToggle={setCreateListingShow}
          />
      }
    </>
  )
};
