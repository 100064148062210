import React from 'react';

type State = {
  index: number;
  step: Step;
  steps: Steps;
  history: number[];
  start: boolean;
  end: boolean;
  goToStep: (i: number) => void;
  nextStep: () => void;
  previousStep: () => void;
};

function useWizardState(props: {
  steps: Steps;
  index?: number;
  step?: Step;
}): State{
  const [filteredStepIndices] = React.useState(
    props.steps.reduce((p, s, idx) => {
      if (s.showStep) {
        p.push(idx);
      }
      return p;
    }, [])
  );

  const [index, setIndex] = React.useState(
    props.index || filteredStepIndices[0]
  );

  const findNextStep = () => {
    let i = index;
    do {
      i = i + 1;
      if (props.steps[i].showStep) {
        setIndex(i);
        break;
      }
    } while (i < props.steps.length);
  };

  const findPrevStep = () => {
    let i = index;
    do {
      i = i - 1;
      if (props.steps[i].showStep) {
        setIndex(i);
        break;
      }
    } while (i > -1);
  };

  const [step, setStep] = React.useState(props.steps[index] || props.steps[0]);

  const [history, setHistory] = React.useState([props.index]);

  const lb = (s) => s !== 0;
  const ub = (s) => s !== filteredStepIndices.length;

  React.useEffect(
    () => {
      setStep(props.steps[index]);
      setHistory((h) => h.concat(index));
    },
    [index]
  );

  return {
    index,
    step,
    steps: props.steps,
    history,
    start: !lb(index),
    end: !ub(index),
    goToStep:
      (i) => {
        if (i === 0) {
          setIndex(0);
        } else if (lb(i) && ub(i)) setIndex(i);
      },
    nextStep:
      () => {
        findNextStep();
      },
    previousStep:
      () => {
        findPrevStep();
      }
  };
}

export default useWizardState;
