// export interface Pagination {
//   start: number;
//   end: number;
//   total: number;
//   hasNextPage: boolean;
//   hasPreviousPage: boolean;
//   // onPreviousClick,
//   // onNextClick,
// }

export default class Pagination {
  start: number;
  end: number;
  total: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  limit: number;
  page: number;
  pages: number;

  constructor(pageNumber: number, pageSize: number, total: number) {
    this.page = pageNumber;
    this.limit = pageSize;
    this.total = total;
    this.start = (this.page - 1) * this.limit + 1;
    this.end = Math.min(this.page * this.limit, total);

    this.pages = Math.ceil(this.total / this.limit);
    this.hasNextPage = this.page < this.pages;
    this.hasPreviousPage = this.page > 1;
  }

}


