import React from "react";
import Wizard from "@library/wizard";
import Link from "@library/link/view";
import "./styles";

function Form(props) {
  return (
    <div className="editor__data-outreach editor__data-outreach__orvp">
      <Wizard
        hideProgressBar={true}
        index={props.index}
        steps={props.steps}
        headerContent={<div className="editor__logo"></div>}
      >
        {props.children}
      </Wizard>
      <div className="editor__data-outreach__footer">
        <Link externalLink="https://www.togogroup.io/privacy-policy">
          Privacy Policy
        </Link>
        <Link externalLink="https://www.togogroup.io/terms-of-service">
          Terms of Service
        </Link>
      </div>
    </div>
  );
}

export default Form;
