import React from 'react';
import PropTypes from 'prop-types';
import SinglePathIcon from '../icons/SinglePathIcon';

export const IconArrow = ({
  className = 'boone-icon boone-icon__arrow-right',
  pathClassName = 'boone-icon boone-icon__arrow-right boone-icon__arrow-right__path',
  ...props
}) => (
  <SinglePathIcon
    viewBox="-30 0 30 30"
    {...props}
    className={className}
    pathClassName={pathClassName}
    path="M23 17.3424313C23 18.2706698 22.2721959 19 21.3458998 19c-.4631481 0-.8932142-.1989082-1.1909522-.4972706l-5.1607927-5.4036739-5.12771076 5.3705225c-.62855809.6630275-1.68718224.6961789-2.34882234.0331514-.66164009-.6298761-.69472209-1.69072-.066164-2.3537475l6.3517449-6.63027471c.6285581-.66302747 1.6871822-.69617884 2.3488223-.06630275.033082 0 .033082.03315138.066164.06630275l6.3517449 6.63027471C22.83459 16.513647 23 16.9114635 23 17.3424313z"
  />
);

IconArrow.propTypes = {
  className: PropTypes.string,
  pathClassName: PropTypes.string
};

export default IconArrow;
