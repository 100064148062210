import React from "react";
import Binary from "./components/binary";
import Ternary from "./components/ternary";
import "../styles/index";

function Edit(props: {
  cy?: string;
  key?: string;
  description?: string;
  id?: string;
  disabled?: boolean;
  value: boolean | null;
  label: any;
  binary?: boolean;
  onChange?: (value: boolean | null) => void;
}) {
  return props.binary ? <Binary {...props} /> : <Ternary {...props} />;
}

Edit.displayName = "BooleanRowEdit";

export default Edit;
