import PropTypes from 'prop-types';
import React from 'react';
import * as ajax from './helpers/ajax';
import classNames from 'classnames';

class PlaceAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { suggestions: [] };
    this.handleClick = this.handleClick.bind(this);
    this.endpoint = props.endpoint ? props.endpoint : '/autocomplete';

    this.autocomplete(this.props.query, this.props.sourceId);
  }

  autocomplete(inputVal, sourceId) {
    let params = {
      input: inputVal,
      limit: 100,
      type: 'canonical_places'
    };

    if (sourceId) {
      params.source_id = sourceId;
    }

    if (inputVal?.length > 0) {
      let queryStr = $.param(params);
      let url = `${this.endpoint}?${queryStr}`;

      ajax.get(url).then(response => {
        if (response.ok) {
          response.json().then(val => {
            this.updateSuggestions(val);
          });
        } else {
          this.updateSuggestions([]);
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query || prevProps.excludeIds?.length !== this.props.excludeIds?.length) {
      this.autocomplete(this.props.query, this.props.sourceId);
    }
  }

  handleClick(e, placeId) {
    if (this.props.onSelect) {
      const item = this.state.suggestions.find(p => p.id === placeId);
      this.props.onSelect(item);
      e.preventDefault();
    }
  }

  render() {
    return (
      <div className={this.state.suggestions.length > 0 ? 'suggestions-list' : undefined}>
        {this.props.prompt && this.state.suggestions.length > 0 && (
          <div className="suggestion-list-prompt">{this.props.prompt}</div>
        )}
        {this.state.suggestions.length > 0
          ? this.state.suggestions.map(suggestion => {
            return <this.props.SuggestionRow {...suggestion}/>
          })
          : this.props.placeholder}
      </div>
    );
  }

  updateSuggestions(suggestions) {
    const filteredSuggestions = suggestions.filter(
      sug => !this.props.excludeIds || !this.props.excludeIds.includes(sug.id)
    );

    this.setState({ suggestions: filteredSuggestions });
    this.props.onResults && this.props.onResults(filteredSuggestions);
  }
}

PlaceAutocomplete.propTypes = {
  excludeIds: PropTypes.arrayOf(PropTypes.number),
  onResults: PropTypes.func,
  onSelect: PropTypes.func,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  query: PropTypes.string.isRequired,
  sourceId: PropTypes.number,
  endpoint: PropTypes.string
};

export default PlaceAutocomplete;
