import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Heading = (
  { level = 1, small = false, large = false, primary, secondary, className, children } = {
    level,
    small,
    large,
    primary,
    secondary,
    className,
    children
  }
) => {
  const Tag = `h${level}`;
  return (
    <Tag
      className={cn({
        'boone-heading': true,
        ['boone-heading__' + Tag]: true,
        ['boone-heading__' + Tag + '--sm']: small,
        ['boone-heading__' + Tag + '--lg']: large,
        'boone-heading--primary': primary,
        'boone-heading--secondary': secondary,
        [className]: Boolean(className)
      })}
    >
      {children}
    </Tag>
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
};

export default Heading;
