import React from "react";
import {DropdownToggle} from "reactstrap";
import IconArrow from "../../icons/IconArrow";
import IconArrowDown from "../../icons/IconArrowDown";
import {RoundButton} from "../Buttons";
import {toggleIcons} from "./constants";

export type Props = {
  toggleIcon?: string;
  Label?: () => any;
};

export const Toggle = ({toggleIcon, Label}: Props) => {
  switch (toggleIcon) {
    case toggleIcons.labelNoIcon:
      return (
        <DropdownToggle
          tag="div"
          className="boone-dropdown__toggle__label-icon"
        >
          <Label />
        </DropdownToggle>
      );
    case toggleIcons.labelArrowRight:
      return (
        <DropdownToggle
          tag="div"
          className="boone-dropdown__toggle__label-icon"
        >
          <Label />
          <IconArrow />
        </DropdownToggle>
      );
    case toggleIcons.labelArrowDown:
      return (
        <DropdownToggle
          tag="div"
          className="boone-dropdown__toggle__label-icon"
        >
          <Label />
          <IconArrowDown />
        </DropdownToggle>
      );
    case toggleIcons.arrowRight:
      return (
        <DropdownToggle tag="div">
          <IconArrow />
        </DropdownToggle>
      );
    case toggleIcons.arrowDown:
      return (
        <DropdownToggle tag="div">
          <IconArrowDown />
        </DropdownToggle>
      );
    case toggleIcons.actionButton:
      return (
        <DropdownToggle tag="div">
          <RoundButton />
        </DropdownToggle>
      );
    case toggleIcons.dots:
    default:
      return (
        <DropdownToggle
          tag="div"
          className="editor__button editor__button__action"
        />
      );
  }
};

export default Toggle;
