import React from "react";
import Link from "@library/link/view";
import Tree from "@library/tree";
import cn from "classnames";
import ReactTooltip from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown} from "@fortawesome/free-solid-svg-icons";

export function NavContents(props: {
  icon?: any;
  internalLink?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  text?: string;
}) {
  return (
    <>
      <Link
        onClick={(e) => {
          e.stopPropagation();
          props.onClick && props.onClick(e);
        }}
        internalLink={props.internalLink}
        className="editor__nav"
      >
        {props.icon && (
          <span data-tip={props.text} className="editor__nav-icon">
            <FontAwesomeIcon icon={props.icon} />
          </span>
        )}
        <div className="editor__nav-text">{props.text}</div>
      </Link>
      <ReactTooltip
        delayHide={10000}
        effect="solid"
        place="right"
        offset={{bottom: -5, right: 5}}
      />
    </>
  );
}

function Nav(props) {
  const cls = props.children ? "editor__nav-dropdown" : "editor__nav-item";
  return props.show !== false ? (
    <Tree
      defaultOpen={props.defaultOpen}
      name={
        <NavContents
          onClick={props.onClick}
          text={props.text}
          icon={props.icon}
          internalLink={props.internalLink}
        />
      }
      expandIcon={props.children && faCaretDown}
      collapseIcon={props.children && faCaretUp}
      className={cn({
        [props.className]: props.className,
        [cls]: true,
        [`${cls}--active`]: props.active,
        [`${cls}--admin`]: props.admin,
      })}
    >
      {props.children}
    </Tree>
  ) : null;
}

export default Nav;
