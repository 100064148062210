import * as React from 'react';
import ReactDOM from 'react-dom';
import Spinner from '../Spinner';

export default function FullPageLoader(){
  // @ts-ignore
  return ReactDOM.createPortal(
    <div className="boone-loader boone-loader__full-page">
      <Spinner />
    </div>,
    document.getElementById('modal-anchor')
  );
}
