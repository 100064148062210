import React from "react";
import Button from "@/library/_button";
import Link from "@library/link/view";
import * as ajax from "@/helpers/ajax";
import {DateTime} from "luxon";
import "./styles";
import CorrectionDiff from "./correction_diff"
import JSONPretty from "@/listing/raw";
import '@library/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faHourglass } from '@fortawesome/free-solid-svg-icons'
import cn from "classnames";

function Correction(props) {
  // "pending" "accepted" "rejected"
  const [state, setState] = React.useState(props.state);
  const [showArgs, setShowArgs] = React.useState(false);
  const [do_not_apply, setDoNotApply] = React.useState(props.do_not_apply);

  function setManuallyAccepted() {
    setDoNotApply(true);
    setState("accepted");
  }

  async function action() {
    const [promise] = ajax._put(document.location.pathname + "/" + props.id, {
      correction: {
        id: props.id,
        state,
        do_not_apply: do_not_apply,
      },
    });

    const json = await promise;
    if (json.ok) {
      console.debug(json);
    }
  }

  React.useEffect(() => {
    if (state !== props.state) {
      action();
    }
  }, [state]);

  const now = DateTime.fromJSDate(new Date());
  const createdAt = DateTime.fromISO(props.created_at);
  const createdDaysAgo = Math.floor(now.diff(createdAt, 'days').days);

  let placeUpdatedAgo = null;
  if (props.canonical_place) {
    const placeUpdatedAt = DateTime.fromISO(props.canonical_place.updated_at);
    const placeUpdatedDiff = now.diff(placeUpdatedAt, ['days', 'hours']);

    if (placeUpdatedDiff.days == 0) {
      placeUpdatedAgo = `${Math.floor(placeUpdatedDiff.hours)} hours ago`
    } else {
      placeUpdatedAgo = `${Math.floor(placeUpdatedDiff.days)} days ago`
    }
  }

  const stateIcons = {
    pending: <FontAwesomeIcon icon={faHourglass} />,
    accepted: <FontAwesomeIcon icon={faThumbsUp} color='green'/>,
    rejected: <FontAwesomeIcon icon={faThumbsDown} color='red'/>
  }

  const correctionDiffFields = {
    name: "Name",
    address1: "Address 1",
    address2: "Address 2",
    city: "City",
    state: "State",
    postal_code: "Postal Code",
    email: "Email",
    phone: "Phone",
    website: "Website"
  };

  const categoryDiffFields = {
    categories: "Categories"
  };

  const disabledClass = props.canonical_place?.enabled ? '' : 'place__name--disabled';
  const nameLinkTitle = props.canonical_place?.enabled ? 'Enabled' : 'Disabled';

  return (
    <tr className="editor__correction">
      <td>{props.id}</td>
      <td>{props.issue_type}</td>
      <td className="editor__correction__place">
        {props.canonical_place ? (
          <Link
            internalLink={"/listings/" + props.canonical_place.id}
            useTab={true}
          >
            <a className={disabledClass} title={nameLinkTitle}>{props.canonical_place.name}</a>
          </Link>
        ) : (
          'n/a'
        )}
        <Button
          onClick={() => props.onRowAction('filter', {key: "canonical_place_id", option_id: props.canonical_place.id})}
        >
          <FontAwesomeIcon icon={'filter'} />
        </Button>
      </td>
      <td
        className={cn({
          "editor__state--accepted": state === "accepted",
          "editor__state--rejected": state === "rejected",
        })}
      >
        <a title={state}>{stateIcons[state]}</a>
      </td>
      <td>{props.ip_address}</td>
      <td><a title={props.created_at}>{createdDaysAgo} days ago</a></td>
      <td>{placeUpdatedAgo ? <a title={props.updated_at}>{placeUpdatedAgo}</a> : 'n/a'}</td>
      <td className="editor__correction__controls">
        <Button
          disabled={state === "accepted"}
          className="editor__correction__controls__accept"
          onClick={() => setState("accepted")}
        >
          Accept
        </Button>
        <Button
          disabled={state === "rejected"}
          className="editor__correction__controls__reject"
          onClick={() => setState("rejected")}
        >
          Reject
        </Button>
        <Button
          disabled={state === "accepted"}
          className="editor__correction__controls__accept"
          onClick={() => setManuallyAccepted()}
        >
          Manually Accept
        </Button>
      </td>
      {props.issue_type === 'correction' && (
        <td className="editor__correction__diff">
          <CorrectionDiff
            correction={props.args}
            current={props.canonical_place}
            diffFields={correctionDiffFields}
          />
        </td>
      )}
      {props.issue_type === 'category_correction' && (
        <td className="editor__correction__diff">
          <CorrectionDiff
            correction={props.args}
            current={props.canonical_place}
            diffFields={categoryDiffFields}
          />
        </td>
      )}
      {props.args && (
        <>
          <td className="editor__correction__args">
            {(props.issue_type == 'correction') && (
              <Link onClick={() => setShowArgs(!showArgs)}> {showArgs ? 'Hide' : 'Show' } Raw JSON </Link>
            )}
          </td>
          <td className="editor__correction__args">
            {(props.issue_type != 'correction' || showArgs) && (
              <JSONPretty json={props.args} />
            )}
          </td>
        </>
      )}
      {(props.comment || props.user_id || props.owner) && (
        <td className="editor__correction__comments">
          <p>{props.comment || ''}</p>
          <span>Submitted by {props.user_id || 'an unknown user'} { props.owner === true ? '(owner)' : ''}</span>
        </td>
      )}
    </tr>
  );
}

export default Correction;
