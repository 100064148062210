import React from 'react';
import cn from 'classnames';
import Label from '../label';
import './styles/index';

type Props = {
  type?: any;
  value?: any;
  onChange?: any;
  onClick?: any;
  label?: string;
  className?: string;
  labelClassName?: string;
  labelId?: string;
  error?: any;
  errorMessage?: string;
  cy?: string;
  onBlur?: any;
  description?: string;
  rows?: number;
  placeholder?: string;
  id?: string;
  name?: string;
};

const TextArea = ({
  value,
  onChange,
  onClick,
  label,
  className,
  labelClassName,
  labelId,
  error,
  errorMessage,
  cy,
  onBlur,
  rows,
  placeholder,
  id,
  ...props
}: Props) => (
  <div
    data-cy="text-area"
    className={cn({
      editor__textarea: true,
      'boone-form__field--error': error,
      [className]: Boolean(className)
    })}
  >
    <Label
      id={labelId}
      text={label}
      description={props.description}
      className={labelClassName}
      onClick={(e) => onClick(e)}
    />
    <textarea
      id={id}
      placeholder={placeholder}
      data-cy={'text-area-element'}
      rows={rows ? rows : 15}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
    {error &&
    errorMessage && (
      <div className="boone-form__field__input__with-message__message--error">
        {errorMessage}
      </div>
    )}
  </div>
);

TextArea.defaultProps = {
  error: false
};

export default TextArea;
