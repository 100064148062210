import * as React from "react";
import IconSuggestion from "../icons/IconSuggestion";
import IconEdit from "../icons/IconEdit";
import BooneDropdown from "../library/dropdown";
import {toggleIcons} from "../library/dropdown/constants";
import uniqBy from "lodash/uniqBy";
import formatSourceName from "../helpers/formatSourceName";

type Props = {
  authoritySourceName: string;
  authoritySourceId: number;
  attribution: any;
  userEditingStrategy: "update" | "suggest" | "none";
};

export default function PermissionsIndicator({
  authoritySourceName,
  authoritySourceId,
  attribution,
  userEditingStrategy,
}: Props) {
  const [sourceName, setOtherSourceName] = React.useState("");
  const [numOthers, setNumOthers] = React.useState(0);

  React.useEffect(() => {
    let t = uniqBy(attribution, "source_id");
    t = t.filter((v) => v && v["source_id"] !== authoritySourceId);
    if (t.length > 0) {
      setNumOthers(t.length - 1);
    }

    // Roadtrippers or Tripadviser are given precedence over other sources
    // const s = t.find((a) => a.source_id === 1 || a.source_id === 5);
    // if (s) {
    //   setOtherSourceName(formatSourceName(s.source_name));
    // } else if (t.length > 0) {
    //   setOtherSourceName(formatSourceName(t[0].source_name));
    // }
  }, []);

  const sources = (
    <span className="text-nowrap">
      <span className="text-capitalize">{sourceName}</span>
      <br />
      <span>{numOthers > 0 ? ` ${numOthers} others` : ""}</span>
    </span>
  );

  const canUpdateRowClasses =
    "d-flex flex-row flex-nowrap row justify-content-between small mb-2";
  const canEditRowClasses =
    "d-flex flex-row flex-nowrap row justify-content-between small";
  const popoutHeaderClasses = "d-flex align-items-center";
  const owner = formatSourceName(authoritySourceName);

  return (
    <div className="boone-listing__permissions-tag">
      {userEditingStrategy === "update" && (
        <div className="boone-listing__permissions-popout">
          <BooneDropdown
            right
            showOnHover={true}
            hideOnMouseLeave={true}
            divider={false}
            toggleIcon={toggleIcons.labelNoIcon}
            key={0}
            Label={() => (
              <span className="boone-listing__tag">
                <IconEdit />
                <span>Updating</span>
              </span>
            )}
          >
            [
            <div>
              <b className={popoutHeaderClasses}>
                <IconEdit />
                <span>Making updates</span>
              </b>
              <br />
              <p className="small">
                {`Because you're the owner of this listing. You can update
              it directly. We'll send you suggested updates to keep it
              fresh, but you control what people see.`}
              </p>
              <hr />
            </div>
            ,
            <div className="p-2">
              <span className={canUpdateRowClasses}>
                <span className="text-capitalize">{owner || "You"}</span>
                <b className="text-nowrap">Can update</b>
              </span>
              <span className={canEditRowClasses}>
                <p className="preserve-whitespace mr-1">{sources}</p>
                <span className="text-nowrap">Can suggest updates</span>
              </span>
            </div>
            ]
          </BooneDropdown>
        </div>
      )}
      {userEditingStrategy === "suggest" && (
        <div className="boone-listing__permissions-popout">
          <BooneDropdown
            right
            showOnHover={true}
            hideOnMouseLeave={true}
            divider={false}
            toggleIcon={toggleIcons.labelNoIcon}
            key={0}
            Label={() => (
              <span className="boone-listing__tag boone-listing__tag--secondary">
                <IconSuggestion fill="white" />
                <span>Suggestions only</span>
              </span>
            )}
          >
            [
            <div>
              <b className={popoutHeaderClasses}>
                <IconSuggestion />
                <span className="boone-secondary">Suggestions only</span>
              </b>
              <br />
              <p className="small">
                {`Only the owner of this listing can update it directly,
                  but you can send them suggested changes. You can always
                  edit your own editorial and media.`}
              </p>
              <hr />
            </div>
            ,
            <div className="p-2">
              <span className={canUpdateRowClasses}>
                <span className="text-capitalize">
                  {owner || "Listing owner"}
                </span>
                <span className="text-nowrap">Can update</span>
              </span>
              <span className={canEditRowClasses}>
                <p className="preserve-whitespace mr-1">{sources}</p>
                <b className="text-nowrap boone-secondary">
                  Can suggest updates
                </b>
              </span>
            </div>
            ]
          </BooneDropdown>
        </div>
      )}
    </div>
  );
}
