interface IKeys {
  [key: string]: string[]
}

// Each category can be added here, and within that category, sections will be displayed in order top to bottom.
// 'category': [
//       'section_slot_1',
//       'section_slot_2'
// ]

export const CategoriesToSections: IKeys = {

  'default': [
    'price',
    'details',
    'parking_details',
    'restroom_details',
    'culture_details'
  ],
  'attractions-activities': [
    'price_details'
  ],
  'bars-restaurants': [
    'restaurant_details',
    'details',
    'parking_details',
    'ev_station_details'
  ],
  'campgrounds': [
    'campground_details',
    'restroom_details',
    'parking_details'
  ],
  'hotels-hostels': [
    'hotel_details',
    'booking_details',
    'details',
    'parking_details',
    'ev_station_details'
  ],
  'landmarks': [],
  'outdoors-recreation-places': [
    'campground_details',
    'parking_details',
    'restroom_details'
  ],
  'parking-rest-areas': [
    'parking_details',
    'ev_station_details',
    'restroom_details'
  ],
  'rv-services': [
    'rv_services',
    'parking_details',
    'campground_details',
    'details',
    'ev_station_details'
  ],
  'sights-landmarks': [
    'parking_details'
  ],
  'tourist-services': [],
}

// Each attribute can be added here, and within the desired section to dictate the order that they appear.
// 'category': [
//       'section_slot_1',
//       'section_slot_2'
// ]

export const Attributes: IKeys = {
  'rv_services': [
    'accepting_new_clients',
    'available_services',
    'preferred',
    'other_available_services',
    'mobile_service_radius',
  ]
}