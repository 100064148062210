import React from "react";
import TreeSelectDropdown from "../tree-select/dropdown/index";
import parseQueryString from "../../helpers/parseQueryString";
import useIndex from "../../hooks/useIndex";
import useSmartState from "../../hooks/useSmartState";
import {useFlag} from "../launch-darkly/hooks";
import type { ApplyFilter } from './types';

type Props = {
  filter: string; // type of filter [categories, tags, etc]
  data: any;
  cy?: string;
  label: string;
  showSelection: boolean;
  featureFlag?: string;
  applyFilter: ApplyFilter
};

function TreeSelectFilter(props: Props) {
  if (!props.data) return null;
  const index = useIndex(props.data, "public_id", {clearChildren: true});
  const [selectedPublicIds, setSelectedPublicIds] = useSmartState([]);

  React.useEffect(() => {
    setSelectionFromUrl();
  }, []);

  const setSelectionFromUrl = () => {
    const {params} = parseQueryString();
    if (params && params[props.filter]) {
      const t = params[props.filter].split(",");
      setSelectedPublicIds(t);
    }
  };

  const filter = (public_ids: string[]): void => {
    setSelectedPublicIds(public_ids);
    const search = `${public_ids.join(",")}`;
    props.applyFilter({[props.filter]: search}, ["index-results-container"]);
  };

  const [enabled] = props.featureFlag ? useFlag(props.featureFlag) : [true];

  return enabled ? (
    <TreeSelectDropdown
      selection={selectedPublicIds}
      cancel={setSelectionFromUrl}
      index={index}
      save={filter}
      cy={props.cy}
      label={props.label}
      mirrorParent={true}
      mirrorChild={false}
      showSelection={props.showSelection}
    />
  ) : null;
}

export default TreeSelectFilter;
