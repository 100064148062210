import React from 'react';
import PropTypes from 'prop-types';
import ReactMapboxGl, { Layer, Feature, ZoomControl } from 'react-mapbox-gl';

const Map = ReactMapboxGl({
  accessToken:  window._env_.MAPBOX_KEY
});

/**
 * Show map view with edit capabilities
 *
 */
export default class LocationMapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      zoom: this.props.zoom || [11]
    };
  }

  getPoint() {
    let long = this.props.longitude;
    if (isNaN(long) || long < -180 || long > 180) {
      long = 0;
    }

    let lat = this.props.latitude;
    if (isNaN(lat) || lat < -90 || lat > 90) {
      lat = 0;
    }

    return [long, lat];
  }

  onDragEnd = pos => {
    if (this.props.editing) {
      let point = {
        longitude: pos.lngLat.lng,
        latitude: pos.lngLat.lat
      };
      this.props.onMapLocationChange(this, point);
    }
  };

  layerPaintProps() {
    return {
      'circle-stroke-width': 4,
      'circle-radius': 10,
      'circle-blur': 0.15,
      'circle-color': '#3770C6',
      'circle-stroke-color': 'white'
    };
  }

  // render component
  render() {
    return (
        <Map
          style={`mapbox://styles/mapbox/${this.props.isSatellite ? 'satellite' : 'streets' }-v9`}
          center={this.getPoint()}
          containerStyle={{ height: '100%', width: '100%' }}
          zoom={this.state.zoom}
        >
          <ZoomControl position="bottom-left" />
          <Layer type="circle" id="loc_circle_marker" paint={this.layerPaintProps()}>
            <Feature coordinates={this.getPoint()} draggable={this.props.editing} onDragEnd={this.onDragEnd} />
          </Layer>
        </Map>
    );
  }
}

LocationMapView.propTypes = {
  editing: PropTypes.bool,
  latitude: PropTypes.any.isRequired,
  longitude: PropTypes.any.isRequired,
  zoom: PropTypes.arrayOf(PropTypes.number),
  isSatellite: PropTypes.bool,
  onMapLocationChange: PropTypes.func
};
