import React from 'react';
import MultiSelectBase from './base';
import Suggestion from './suggestion';
import './styles';

type Props = {
  options: string[] | { id: ID; name: string, subtitle?: string }[];
  label: string;
  labelId?: string;
  persistChanges: any;
  selected?: ID[];
  allowNewValues?: boolean;
  cy?: string;
  placeholder?: string;
  description?: string;
  disabled?: boolean;
};

function MultiSelect(props: Props) {

  const strings = typeof props.options[0] === 'string';
  // @ts-ignore
  const suggestions = strings ? props.options.map(s => ({ id: s, name: s })) : props.options;

  return (
    <MultiSelectBase
      disabled={props.disabled}
      label={props.label}
      labelId={props.labelId}
      description={props.description}
      allowNewValues={props.allowNewValues}
      persistChanges={props.persistChanges}
      suggestions={suggestions}
      selected={props.selected}
      cy={props.cy}
    >
      {({ setActive, filtered, checkIfActive, add }) =>
        filtered.map(suggestion => {
          return (
            <Suggestion
              key={suggestion.id}
              setActive={() => setActive(suggestion.id)}
              active={checkIfActive(suggestion.id)}
              handleSelection={() => add(suggestion.id)}
              id={suggestion.id}
            >
              <>
                <div>{suggestion.name}</div>
                {suggestion.subtitle && <small>{suggestion.subtitle}</small>}
              </>
            </Suggestion>
          );
        })
      }
    </MultiSelectBase>
  );
}

export default MultiSelect;
