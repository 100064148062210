const getIsLeafNodeSelected = function (isNodeSelected, tree, isRecursiveSelect) {
  if (tree.children.length > 0 && isRecursiveSelect) {
    // Recursive case

    // If node is selected, replace with its child nodes in `isNodeSelected`
    if (isNodeSelected[tree.value.key]) {
     const selectedChildNodes = tree.children.reduce(function (isNodeSelected, child) {
        isNodeSelected[child.value.key] = child;
        return isNodeSelected;
      }, {});
      isNodeSelected = Object.assign({}, isNodeSelected, selectedChildNodes);
      delete isNodeSelected[tree.value.key];
    }

    // Recurse on all children
    return tree.children.reduce(getIsLeafNodeSelected, isNodeSelected);
  } else {
    // Base case
    return isNodeSelected;
  }
};

export default {
  getIsLeafNodeSelected
};
