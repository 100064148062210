import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

export class BooneModal extends Component {
  render = () => {
    const { children, toggle, ...props } = this.props;
    return (
      <Modal
        data-cy={props.cy}
        toggle={() => toggle()}
        className="boone-modal"
        isOpen={props.show}
        fade={true}
        size={props.size || 'lg'}
      >
        {children}
      </Modal>
    );
  };
}

BooneModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.any,
  cy: PropTypes.string,
  size: PropTypes.string
};

export default BooneModal;
