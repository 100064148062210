import * as React from 'react';
import Sandbox from '../../../component-explorer/Sandbox';
import Scenario from '../../../component-explorer/Scenario';
import Loader from './index';

export default function Examples(): any {
  return (
    <Sandbox name="Full Page Loader">
      <Scenario
        description="[!] remove `show=Full Page Loader` from the url to switch to a different component"
        code=""
      >
        <Loader />
      </Scenario>
    </Sandbox>
  );
}
