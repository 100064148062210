
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import Enable from './index';

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Disable = makeShortcode("Disable");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Disable {...{
      "enabled": true,
      "canDisable": true
    }} mdxType="Disable" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;