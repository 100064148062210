import React from "react";
import Field from "../view-field";
import cn from "classnames";
import useLink from "./useLink";
import "./styles";

type Props = {
  externalLink?: string;
  internalLink?: string;
  useTab?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: any;
  label?: any;
  hideNull?: boolean;
  className?: string;
  value?: string;
  description?: string;
  id?: string;
  cy?: string;
};

function Link(props: Props) {
  const linkProps = useLink({
    onClick: props.onClick,
    externalLink: props.externalLink,
    internalLink: props.internalLink,
    useTab: props.useTab,
  });

  const value = props.children || props.value;

  const link = value ? (
    <div {...linkProps} className={cn(["editor__link", "editor__link--view"])}>
      {value}
    </div>
  ) : null;

  return (
    <Field
      cy={props.cy}
      hideNull={props.hideNull}
      className={props.className}
      description={props.description}
      id={props.id}
      label={props.label}
      value={link}
    />
  );
}

export default Link;
