import React from 'react';
import BooleanRow from '@library/boolean-row/edit';
import Subsection from '@/library/wizard/components/subsection';
import type { State } from '../../typings';
import './styles';

type Props = {
  state: State;
  setState: any;
};

export function Component(props: Props){
  return (
    <div className="editor__data-outreach__rv-service__options">
      <Subsection>
        <Subsection>
          <div className="editor__service-option" />
          <div className="editor__service-areas">
            {props.state.service_center && (
              <div className="editor__boolean-row">
                <h4>RV Service Center</h4>
              </div>
            )}
            {props.state.mobile_service && (
              <div className="editor__boolean-row">
                <h4>RV Mobile Service</h4>
              </div>
            )}
          </div>
        </Subsection>
        {props.state.services.map(({ id, name }) => {
          return (
            <Subsection key={id+name}>
              <div className="editor__service-option">{name}</div>
              <div className="editor__service-areas">
                {props.state.service_center && (
                  <BooleanRow
                    binary
                    value={props.state.service_available_services[id]['value']}
                    label={''}
                    onChange={(v) =>
                      props.setState((s) => ({
                        ...s,
                        service_available_services:
                          {
                            ...s.service_available_services,
                            [id]: {...s.service_available_services[id], value: v}
                          }
                      }))}
                  />
                )}
                {props.state.mobile_service && (
                  <BooleanRow
                    binary
                    value={props.state.mobile_available_services['mobile-' + id]['value']}
                    label={''}
                    onChange={(v) =>
                      props.setState((s) => ({
                        ...s,
                        mobile_available_services:
                          {
                            ...s.mobile_available_services,
                            ['mobile-' + id]: {...s.mobile_available_services['mobile-' + id], value: v}
                          }
                      }))}
                  />
                )}
              </div>
            </Subsection>
          );
        })}
      </Subsection>
    </div>
  );
}

export default Component;
