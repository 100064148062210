import React from "react";
import "./styles";
import {DateTime} from "luxon";

function OrvpReport(props) {

  function formatDate(date) {
    return date ? DateTime.fromISO(date).toFormat('LLL dd, yyyy') : null;
  }

  function formatBoolean(bool) {
    if (bool == true)
      return 'Yes';
    else if(bool == false)
      return 'No';
    else
      return '';
  }

  return (
    <tr className="editor__orvp_report">
      <td className="clear_background">{props.id}</td>
      <td className="clear_background">{props.place.canonical_place_id}</td>
      <td className="clear_background">{props.place.name}</td>
      <td className="clear_background">{props.place.city}</td>
      <td className="clear_background">{formatBoolean(props.parking_allowed)}</td>
      <td className="clear_background">{formatBoolean(props.user_stay)}</td>
      <td className="clear_background">{formatBoolean(props.policy_enforced)}</td>
      <td className="clear_background">{formatBoolean(props.policy_confirmed)}</td>
      <td className="clear_background">{formatDate(props.created_at)}</td>
      <td className="editor__orvp_report__date_stayed"><span className="bold">Date Stayed:</span> {formatDate(props.user_stay_date)}</td>
      <td className="editor__orvp_report__restricted_by"><span className="bold">Restricted By:</span> {props.restricted_by}</td>
      <td className="editor__orvp_report__confirmation_method"><span className="bold">Confirmation Method:</span> {props.policy_confirmation_method}</td>
      <td className="editor__orvp_report__confirmed_by"><span className="bold">Confirmed By:</span> {props.policy_confirmed_by}</td>
      <td className="editor__orvp_report__confirmed_date"><span className="bold">Confirmed Date:</span> {formatDate(props.policy_confirmation_date)}</td>
      <td className="editor__orvp_report__place_correct"><span className="bold">Place Correct:</span> {formatBoolean(props.place_correct)}</td>
      <td className="editor__orvp_report__comment"><span className="bold">Comment:</span> {props.comment}</td>
    </tr>
  );
}

export default OrvpReport;
