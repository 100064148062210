import React from 'react';
import createTypeMap from './type-map';
import Composer from './composer';

function Attributes(props: {
  editing: boolean;
  featureFlag?: string;
  definitions: Attributes.Definitions;
}){
  // Adds component types to definitions and returns
  // an array with the types in position 0, and the
  // definitions mapped to type in position 1
  const [compTypes, typedDefs] = createTypeMap(props.definitions);

  return compTypes.map((type) => {
    return (
      <React.Fragment key={type + '-attributes'}>
        <Composer attributes={typedDefs[type]} editing={props.editing} />
      </React.Fragment>
    );
  });
}

export default Attributes;
