import React, { Component } from 'react';
import * as ajax from '@/helpers/ajax';
import User from './User';

type Props = {
  updateUserRole?: (id: number, role_id: number) => void;
  onUserDisabled?: (id: number) => void;
  resendInvite?: (id: number) => void;
  onUserInvitationResent?: (id: number) => void;
  currentUserId?: number;
  roles: { name: string; id: number }[];
  users?: {
    id: number;
    role_id: number;
    avatar_url: string;
    membership_id: number;
    name: string;
    invitation_pending: boolean;
  }[];
  userEditingStrategy: UserEditingStrategy;
  isSuperAdmin: boolean;
};

export default function UsersTable(props: Props) {
  const onRoleChange = async (id, membership_id, role_id) => {
    if (role_id) {
      const response = await ajax.put(`/memberships/${membership_id}.json`, { role_id });
      if (response.ok) {
        props.updateUserRole(id, role_id);
      } else {
        console.debug('[UsersTable] Update user role failed');
      }
    }
  };

  const removeUser = async (id) => {
    if (confirm('Are you sure you want to remove this user from ALL organizations?')) {
      const response = await ajax.destroy(`/users/${id}.json`);
      if (response.ok) {
        props.onUserDisabled(id);
      } else {
        console.debug('[UsersTable] Remove user failed');
      }
    }
  };

  const removeMembership = async (id, userId) => {
    const response = await ajax.destroy(`/memberships/${id}.json`);
    if (response.ok) {
      props.onUserDisabled(userId);
    } else {
      console.debug('[UsersTable] Remove membership role failed');
    }
  };

  const resendInvitation = async (email) => {
    if (confirm('Are you sure you want to resend the invitation to this user?')) {
      props.resendInvite(email);
    }
  };

  return (
    <table className="table users">
      <thead className="thead-light boone-organization__users_table">
        <tr>
          <th className="boone-organization__users_table__header-name">Name</th>
          <th className="boone-organization__users_table__header-role">Account Type</th>
          <th className="boone-organization__users_table__header-actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.users.map((user) => (
          <User
            key={'user-' + user.id}
            {...user}
            roles={props.roles}
            currentUserId={props.currentUserId}
            selectRole={(roleId) => onRoleChange(user.id, user.membership_id, roleId)}
            isSuperAdmin={props.isSuperAdmin}
            onRemove={removeUser}
            onResendInvite={resendInvitation}
            onRemoveMembership={removeMembership}
            userEditingStrategy={props.userEditingStrategy}
          />
        ))}
      </tbody>
    </table>
  );
}
