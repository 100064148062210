import React, {useEffect, useState, useCallback} from 'react';
import Modal from '@/library/Modal.js';
import PlaceAutocomplete from "@/PlaceAutocomplete";
import classNames from 'classnames';
import * as ajax from "@/helpers/ajax";
import debounce from 'lodash/debounce';


interface props {
  toggle: Function;
  show: boolean;
  onLoading: Function;
}

export const CreateListing = ({ ...props }: props) => {

  const [errorFields, setErrorFields] = useState<Array<string>>([]);
  const [placeName, setPlaceName] = useState<string>('');
  const [suggestionCount, setSuggestionCount] = useState<number>(0);
  const [creatingNewListing, setCreatingNewListing] = useState<boolean>(false);
  const [autocompleteQuery, setAutoCompleteQuery] = useState<string>('');
  const debouncedCallback = useCallback(debounce(placeName => setAutoCompleteQuery(placeName), 200), []);

  useEffect(() => {
    // Only update the autocomplete query after a short delay
      debouncedCallback(placeName);
    }, [placeName]
  );

  const clearResults = () => {
    setAutoCompleteQuery('');
    setErrorFields([]);
    setPlaceName('');
    setSuggestionCount(0);
    setCreatingNewListing(false);
  };

  const handleResults = (suggestions) => {
    setSuggestionCount(suggestions.length);
  };

  const createPlace = e => {
    props.onLoading(true);
    setCreatingNewListing(true);
    ajax
      .post('/listings', {
        tomo_place: {
          name: placeName
        }
      })
      .then(response => {
        if (response.ok && response.redirected) {
          window.location.href = response.url;
        } else {
          response.json().then(val => {
            setErrorFields(Object.keys(val));
            setCreatingNewListing(false);
          });
        }
      });
  };

  const handleToggle = () => {
    clearResults();
    props.toggle();
  };

  const renderNothing = () => {
    return <div />;
  };

  const renderOr = () => {
    return <span className="suggestions-or mr-3">or</span>;
  };

  return (
    <>
      <Modal
        show={props.show}
        toggle={handleToggle}
      >
        <div id="new-tomo-place">
          <span className="header-text">Create a new place listing</span>
          <span className="btn close-modal-button" role="button" data-dismiss="modal" onClick={clearResults}>
            <i className="mdi mdi-close" />
          </span>

          <div className="form-body">
            <div className="form-group">
              <div
                className={classNames({
                  field_with_errors: errorFields.includes('name')
                })}
              >
                <label htmlFor="name">{`The place's name`}</label>
              </div>
              <div
                className={classNames({
                  field_with_errors: errorFields.includes('name')
                })}
              >
                <input
                  data-cy="create-listing-search-input"
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Empire State Building"
                  value={placeName}
                  onChange={e => setPlaceName(e.target.value)}
                />
              </div>
            </div>
          </div>

          <PlaceAutocomplete
            query={autocompleteQuery}
            prompt="Is one of these the listing you intend to create?"
            onResults={handleResults}
            SuggestionRow={(suggestionProps) => {
              const url = (suggestionProps.thumbnail && suggestionProps.thumbnail.url) || '';
              return (
                <a
                  key={suggestionProps.id}
                  className={classNames({
                    'd-block': true,
                    'has-photo': suggestionProps.thumbnail !== null
                  })}
                  href={'/listings/' + suggestionProps.id}
                  onClick={e => suggestionProps.handleClick(e, suggestionProps.id)}
                >
                  <div className="boone-image__thumbnail mr-2" style={{ backgroundImage: 'url(' + url + ')' }} />
                  <div className="place-list-details">
                    <b>{suggestionProps.name}</b>
                    <br />
                    <small>{suggestionProps.address}</small>
                  </div>
                </a>
              );
            }}
          />

          <div>
            <div className="modal-actions">
              {suggestionCount > 0 ? renderOr() : renderNothing()}
              <input
                data-cy="create-listing-submit"
                type="submit"
                className="editor__button editor__button__primary"
                onClick={createPlace}
                value="Create a new place"
                disabled={creatingNewListing}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
