import React from 'react';
import { InputWithLabel } from '../input-with-label';
import './styles';

function Count(props) {
  return (
    <InputWithLabel
      {...props}
      cy='count'
      type='number'
      className='editor__count'
      value={props.value}
      placeholder={props.label}
      showLabel={false}
      onChange={(e) => props.onChange(e.target.value)}
    />
  )
}

export default Count;
