import React from 'react';
import 'react-dates/initialize';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { isToday } from '@/helpers/TimeFormat';
import * as pt from '@/helpers/propTypes';

export default class DatePicker extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      focusedInput: null,
      date: null
    };
  }

  isOutsideRange = date => {
    if (!date) {
      return false;
    }
    const beforeMinimum = this.props.minimum ? date.isBefore(moment(this.props.minimum).startOf('day')) : false;
    const afterMaximum =
      !beforeMinimum && this.props.maximum ? date.isAfter(moment(this.props.maximum).endOf('day')) : false;
    return beforeMinimum || afterMaximum;
  };

  render() {
    return (
      <div className="datepicker-wrapper">
        <SingleDatePicker
          numberOfMonths={1}
          date={this.props.ownerControlled ? this.props.date : this.state.date}
          showDefaultInputIcon={true}
          keepOpenOnDateSelect={this.props.keepOpen || true}
          onDateChange={this.props.ownerControlled ? this.props.handleChange : date => this.setState({ date })}
          onFocusChange={
            this.props.ownerControlled ? this.props.onFocusChange : focusedInput => this.setState({ focusedInput })
          }
          focused={this.props.ownerControlled ? this.props.focused : this.state.focused}
          isOutsideRange={this.isOutsideRange}
          isDayBlocked={this.isOutsideRange}
          hideKeyboardShortcutsPanel={true}
          isDayHighlighted={isToday}
          displayFormat={this.props.date && this.props.displayFormat ? this.props.displayFormat : undefined}
          renderCalendarInfo={this.props.renderCalendarInfo}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  ownerControlled: PropTypes.bool,
  date: PropTypes.instanceOf(moment),
  keepOn: PropTypes.bool,
  keepOpen: PropTypes.bool,
  focused: PropTypes.bool,
  onFocusChange: pt.requiredIf('ownerControlled'),
  displayFormat: PropTypes.string,
  handleChange: pt.requiredIf('ownerControlled'),
  renderCalendarInfo: PropTypes.func,
  placeholder: PropTypes.string,
  maximum: PropTypes.string,
  minimum: PropTypes.string
};
