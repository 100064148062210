import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@library/link/view'
import { ListingDetailsBase } from './base';

export class ListingDetailsLink extends Component {
  render() {
    const { name, locations, detailsViewPath, className } = this.props;

    return (
      <Link internalLink={detailsViewPath} className={`boone-listing__details ${className}`}>
        <ListingDetailsBase name={name} locations={locations} />
      </Link>
    );
  }
}

ListingDetailsLink.propTypes = {
  name: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  detailsViewPath: PropTypes.string,
  className: PropTypes.string
};

export default ListingDetailsLink;
