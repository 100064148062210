import { correctionPayload } from '@/data-outreach/_core/forms/util';
/**     ___________
 *     [__   Types |
 *    __/_| [] []  |
 *   [_,________,__|
 *     "        "
 **/

export type Category = {
  public_id: string;
  public_id_breadcrumbs: string[];
}

type Props = {
  name?: string;
  phone?: string;
  email?: string;
  website?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  categories?: Category[];
  categories_list: any;
  canonical_place_id: string;
  owner?: boolean;
  longitude: number;
  latitude: number;
  location_set_via: string;
};

type State = {
  comment?: string;
  user_email?: string;
  issue_type: string;
  owner?: boolean;
  duplicate_ids?: number[];
  reason?: string;
  start_date?: string;
  end_date?: string;
  do_not_apply?: boolean;
  longitude: number;
  latitude: number;
} & Props;

// If the correction type is 'correction' then the payload
// that we send the the backend is formatted differently
type Payload =
  | {
      correction: {
        canonical_place_id: string;
        comment: string;
        user_id: string;
        issue_type: string;
        owner: boolean;
        user_agent: string;
        duplicate_ids?: string;
        reason?: string;
        start_date?: string;
        end_date?: string;
        longitude: number;
        latitude: number;
        location_set_via?: string;
      } & Updates;
    }
  | Updates;

type Updates = {
  updates?: {
    name?: string;
    phone?: string;
    email?: string;
    website?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    reason?: string;
    start_date?: string;
    end_date?: string;
    categories: string[];
  };
};

export default (
  props: Props
): {
  atlas?: {
    url: string;
    method: string;
    payloadFormatter: (State) => Payload;
  };
  initialState: State;
  recaptcha: {
    action: string;
  };
} => ({
  initialState: {
    canonical_place_id: props.canonical_place_id,
    duplicate_ids: [],
    name: props.name,
    email: props.email,
    phone: props.phone,
    website: props.website,
    address1: props.address1,
    address2: props.address2,
    postal_code: props.postal_code,
    categories: props.categories,
    categories_list: props.categories_list,
    city: props.city,
    state: props.state,
    comment: '',
    user_email: '',
    issue_type: 'correction',
    owner: false,
    reason: '',
    start_date: '',
    end_date: '',
    longitude: props.longitude,
    latitude: props.latitude,
    location_set_via: 'my_location'
  },
  recaptcha: {
    action: 'correction',
  },
  atlas: {
    method: '_post',
    url: '/corrections',
    payloadFormatter: (st: State): Payload => {
      const meta = {
        canonical_place_id: st.canonical_place_id,
        comment: st.comment,
        user_id: st.user_email,
        issue_type: st.issue_type,
        duplicate_ids: st.duplicate_ids,
        user_agent: typeof window.navigator === 'undefined' ? '' : navigator.userAgent,
        categories: st.categories.map((c) => c.public_id),
        owner: st.owner,
        reason: st.reason,
        start_date: st.start_date,
        end_date: st.end_date,
        latitude: st.latitude,
        longitude: st.longitude,
        location_set_via: st.location_set_via
      };
      const data = {
        ...st,
      };
      const pl = correctionPayload(meta, data);
      return pl;
    },
  },
});
