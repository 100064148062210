import React from "react";
import Label from "@library/label";
import False from "./symbols/false";
import True from "./symbols/true";
import Checkbox from "./checkbox";
import "@library/boolean-row/styles/index";

function Ternary(props: {
  disabled?: boolean;
  value: boolean | null;
  label: any;
  binary?: boolean;
  description?: string;
  id?: string;
  onChange?: (value: boolean | null) => void;
}) {
  // states
  const falsy = props.value === false;
  const truthy = props.value === true;
  const neither = !falsy && !truthy;

  const valueRotation = () => {
    if (props.disabled) {
      return;
    }
    switch (props.value) {
      case false:
        props.onChange(null);
        break;
      case true:
        props.onChange(false);
        break;
      case null:
      default:
        props.onChange(true);
        break;
    }
  };

  return (
    <div
      onClick={valueRotation}
      data-cy="boolean-row-ternary"
      className={"editor__boolean-row editor__boolean-row--edit"}
    >
      <div
        className="editor__boolean-row__controls"
        data-cy="boolean-row-controls"
      >
        {falsy && (
          <Checkbox state="false" disabled={props.disabled}>
            <False />
          </Checkbox>
        )}

        {neither && <Checkbox state="null" disabled={props.disabled} />}

        {truthy && (
          <Checkbox state="true" disabled={props.disabled}>
            <True />
          </Checkbox>
        )}
      </div>
      <Label
        cy="boolean-row-label"
        description={props.description}
        id={props.id}
        text={props.label}
      />
    </div>
  );
}

Ternary.displayName = "BooleanRowTernary";

export default Ternary;
