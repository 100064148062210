import React from 'react';
import ErrorBoundary from '@library/ErrorBoundary';
import BooleanRowEdit from '@library/boolean-row/edit';
import BooleanRowView from '@library/boolean-row/view';
import CountComponent from '@library/count';
import DatePicker from '@library/DatePicker';
import TextAreaGroupEditor from '@library/textarea-group-editor/TextAreaGroupEditor';
import InputGroupEditor from '@library/group-editor/InputGroupEditor';
import InputNoneUnknown from '../../../input-none-unknown';
import MultiSelectEdit from '@library/multi-select';
import SelectComponent from '@library/select';
import TextAreaComponent from '@library/text-area';
import ViewField from '@library/view-field';
import ViewList from '@library/view-list';
import PriceView from '@library/price/view';
import PriceEdit from '@library/price/edit';
import LinkComponent from '@library/link/view';
import Label from "@library/label";

import moment from 'moment';
import * as c from '@/listing/hours/constants';
import { useFlag } from '@library/launch-darkly/hooks';

type Props<V, E> = {
  editing: boolean;
  featureFlag?: string;
  show?: boolean;
  view: V;
  edit: E;
};

export function factory(EditComponent, ViewComponent, displayName = 'Attribute'){
  function Component({
    editing = false,
    featureFlag,
    view,
    edit
  }: Props<typeof ViewComponent, typeof EditComponent>){
    const [enabled] = featureFlag ? useFlag(featureFlag, false) : [true];
    if (!enabled) {
      return null;
    }
    return (
      <ErrorBoundary>
        {editing ? <EditComponent {...edit} /> : <ViewComponent {...view} />}
      </ErrorBoundary>
    );
  }
  // @ts-ignore
  Component.displayName = displayName;
  return Component;
}

const DatePickerComponent = (props) => {
  const [focused, setFocused] = React.useState(null);

  const onFocusChange = ({ focused }) => {
    setFocused(focused);
  }

  const onChange = (date) => {
    props.onChange(date ? moment(date).format(c.EDIT_DATE_FORMAT) : null);
  }

  return (
    <>
      <Label
        description={props.description}
        id={props.id+'_label'}
        text={props.label}
      />
      <DatePicker
        id={props.id}
        className="form-control"
        focused={focused}
        onFocusChange={onFocusChange}
        date={props.value ? moment(props.value) : null}
        handleChange={onChange}
        keepOpen={false}
        ownerControlled={true}
        calendarInfoPosition="after"
        placeholder='Select Date'
      />
    </>
  );
}

const HideNullViewField = (props) => (<ViewField {...props} hideNull={true}/>);

export const MultiTextInput = factory(TextAreaGroupEditor, ViewList, 'MultiTextInput');
export const MultiInput = factory(InputGroupEditor, ViewList, 'MultiInput');
export const MultiSelect = factory(MultiSelectEdit, ViewList, 'MultiSelect');
export const Boolean = factory(
  (props) => <BooleanRowEdit {...props} binary={false} />,
  BooleanRowView,
  'Boolean'
);
export const Integer = factory(InputNoneUnknown, HideNullViewField, 'Integer');
export const Float = factory(InputNoneUnknown, HideNullViewField, 'Float');
export const Count = factory(CountComponent, HideNullViewField, 'Count');
export const Price = factory(PriceEdit, PriceView, 'Price');
export const Link = factory(InputNoneUnknown, LinkComponent, 'Link');
export const Input = factory(InputNoneUnknown, HideNullViewField, 'Input');
export const TextArea = factory(TextAreaComponent, HideNullViewField, 'TextArea');
export const Select = factory(
  (props) => <SelectComponent {...props} dropdown={{right: false}}/>,
  ViewField,
  'Select'
);
export const Date = factory(DatePickerComponent, ViewField, 'DatePicker');
