
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import SearchTreeDropdown from './dropdown';
import useIndex from '@/hooks/useIndex';
import data from './fixtures/categories-data';
export function Example() {
  const index = useIndex(data, "public_id", {
    clearChildren: true
  });
  return <div id='example-a'>
    <SearchTreeDropdown mirrorChild={false} selection={[]} clear={() => {}} cancel={() => {}} label="mirror parent" save={() => {}} cy="search-tree-mirror-child" index={index} mirrorParent={true} showSelection={true} mdxType="SearchTreeDropdown" />
    </div>;
}

const layoutProps = {
  Example
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Example mdxType="Example" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;