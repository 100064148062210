import React from "react";

import Link from "@library/link/view";

import _ from "lodash";

import { findDOMNode } from "react-dom";

// Bootstrap
import { Badge } from "reactstrap";

// Material
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

import styled from "styled-components";

import { DragSource } from "react-dnd";
import ItemTypes from "./ItemTypes";

// -----------------------------------------------
// STYLED-COMPONENTS -----------------------------
// -----------------------------------------------
const StyledTableRow = styled.tr`
  cursor: pointer;

  .add-button {
    visibility: hidden;
  }

  &:hover {
    .add-button {
      visibility: visible;
    }
  }

  /* FIX: Not working */
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledMapIcon = styled(Avatar)`
  background-color: ${props => props.backgroundColor} !important;
  color: #ffffff !important;
  height: 20px !important;
  width: 20px !important;
  font-size: 10px !important;
  text-align: center !important;
  font-weight: bold !important;
`;

// -----------------------------------------------
// DRAG-AND-DROP ---------------------------------
// -----------------------------------------------
const placeSource = {
  beginDrag(props) {
    return props.place;
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }

    // When dropped on a compatible target...
    // const item = monitor.getItem();
    // const dropResult = monitor.getDropResult();
  }
};

// eslint-disable-next-line no-unused-vars
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

// ==============================================-
// COMPONENT->PLACE ==============================
// ==============================================-
class Place extends React.Component {

  flyTo = e => {
    if (this.props.place.locations.length > 0) {
      const location = this.props.place.locations[0];
      const { longitude, latitude } = location;

      if (_.isNull(longitude) || _.isNull(latitude)) {
        alert("Cannot fly to location since it does not have coordinates.");
        return;
      }

      this.props.flyTo({
        center: [longitude.toFixed(4), latitude.toFixed(4)],
        zoom: 20
      });
    }
  };

  render() {
    const {
      connectDragSource,
      // eslint-disable-next-line no-unused-vars
      isDragging,
      place,
      // eslint-disable-next-line no-unused-vars
      classes,
      target,
      breakPlaceIntoNewCanonical,
      canonical,
      matchType,
      isNearMatch,
      nearMatchApproved
    } = this.props;

    const description = (_.isNull(place.description) ? '' : place.description).replace('\n', '&#013;');
    const categories = place.categories ? place.categories.join(', ') : '';

    return (
      <StyledTableRow
        ref={instance => connectDragSource(findDOMNode(instance))}
        onClick={this.flyTo}
      >
        <td>
          <StyledMapIcon backgroundColor={canonical.map_number_color}>
            {place.map_icon_num}
          </StyledMapIcon>
        </td>
        <td>
          <Tooltip title={place.id}>
            <span>{place.id}</span>
          </Tooltip>
        </td>
        <td>
          <Tooltip title={place.source_name}>
            <span>{place.source_name}</span>
          </Tooltip>
        </td>
        <td>
          <Tooltip title={place.name}>
            <span>{place.name}</span>
          </Tooltip>
        </td>
        <td>{place.locations.length > 0 && place.locations[0].address1}</td>
        <td>
          <Tooltip title={categories}><span>{categories}</span></Tooltip>
        </td>
        <td>
          {description.length > 0 &&
          <Tooltip title={description}>
            <Icon style={{color: canonical.map_number_color}}>
              description_outlined
            </Icon>
          </Tooltip>
          }
        </td>
        <td>
          {target &&
          !_.isObject(this.props.newCanonical) && (
            <Tooltip
              title="Break Into New Canonical"
              className="add-button"
              onClick={e => breakPlaceIntoNewCanonical(e, place)}
            >
              <Icon
                color="primary"
                className="add-button"
                onClick={e => breakPlaceIntoNewCanonical(e, place)}
              >
                playlist_add
              </Icon>
            </Tooltip>
          )}
        </td>
      </StyledTableRow>
    );
  }
}

export default DragSource(ItemTypes.PLACE, placeSource, connect => ({
  connectDragSource: connect.dragSource()
}))(Place);
