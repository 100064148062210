namespace Attributes {
  type DataType = 'string'
    | 'object'
    | 'array'
    | 'integer'
    | 'number'
    | 'boolean'
    | 'bool';

  type Properties = {
    [key: string]: DataType
  };

  type Format = 'long' | 'url' | 'email' | 'date' | 'price-rating';

  interface Schema {
    type: DataType;
    items?: Schema;
    properties?: Properties;
    format?: Format;
  }

  export type Value = string | number | boolean | null;

  export interface Option {
    id?: string;
    label?: string;
    name?: string;
    value?: Value | Value[];
  }

  export interface Definition {
    component?: string;
    dependents?: any[];
    depends_on?: boolean;
    description?: string;
    editing: boolean;
    featureFlag?: string;
    id: string;
    label: string;
    labelId: string;
    name: string;
    num: number;
    onChange: (value: Value | Value[]) => void;
    options?: Option[];
    schema: Schema;
    show: boolean;
    value?: Value | Value[];
    values?: Value[];
    hideNull?: boolean;
    active?: boolean;
    hideLabel?: boolean;
  }

  export type Definitions = Definition[]
}
