import React, { PureComponent } from 'react';
import { SinglePathIcon } from './SinglePathIcon';

export class IconReadOnly extends PureComponent {
  render() {
    return (
      <SinglePathIcon
        className="icon-read-only"
        path="M10.2 23C8.44 23 7 21.5104895 7 19.6433566v-5.2867132C7 12.5104895 8.44 11 10.2 11h6.9V8.35664336c0-.92307693-.72-1.67832168-1.6-1.67832168h-3c-.88 0-1.6.75524475-1.6 1.67832168V11c-1 0-1.41777738.1468531-1.6.1468531V8.35664336C9.3 6.51048951 10.74 5 12.5 5h3c1.76 0 3.2 1.51048951 3.2 3.35664336v2.79020974c1.32.3986014 2.3 1.6783217 2.3 3.2097903v5.2867132C21 21.4895105 19.56 23 17.8 23h-7.6zm-1.6-8.6433566v5.2867132c0 .923077.72 1.6783217 1.6 1.6783217h7.6c.88 0 1.6-.7552447 1.6-1.6783217v-5.2867132c0-.923077-.72-1.6783217-1.6-1.6783217h-7.6c-.88 0-1.6.7552447-1.6 1.6783217zm5.4 5.5594405c-.44 0-.8-.3776224-.8-.8391608v-1.2587413c0-.4615384.36-.8391608.8-.8391608.44 0 .8.3776224.8.8391608v1.2587413c0 .4615384-.36.8391608-.8.8391608z"
        {...this.props}
      />
    );
  }
}

export default IconReadOnly;
