import React from "react";
import Field from "@library/view-field";
import JSONPretty from "react-json-pretty";
import Button from "@library/_button";
import "./styles";

type Props = {
  status: string;
  id: string;
  organization?: {
    name?: string;
    id?: string;
  };
  source?: {
    id?: string;
    name?: string;
  };
  started_at?: string;
  updated_at?: string;
  make_suggestions?: boolean;
  suggestions_auto_acceptable?: boolean;
  directives?: string;
  notes?: string;
};

function ImportDetails(props: Props) {
  const [formatted, setFormatted] = React.useState(true);
  return (
    <div className="editor__imports__import__details">
      <h3>
        {`Import ${props.id}`}
        {formatted ? (
          <Button
            className="editor__button--white"
            onClick={() => setFormatted(false)}
          >
            JSON
          </Button>
        ) : (
          <Button
            className="editor__button--primary"
            onClick={() => setFormatted(true)}
          >
            Formatted
          </Button>
        )}
      </h3>
      {formatted ? (
        <div className="editor__imports__import__details__formatted">
          <div className="editor__imports__import__details__formatted__data">
            <Field label="Status" value={props.status} />
            <Field label="Organization Name" value={props.organization.name} />
            <Field label="Organization Id" value={props.organization.id} />
            <Field label="Source Id" value={props.source.id} />
            <Field label="Source Name" value={props.source.name} />
            <Field label="Created" value={props.started_at} />
            <Field label="Updated" value={props.updated_at} />
            <Field
              label="Make Suggestions"
              value={Boolean(props.make_suggestions) + ""}
            />
            <Field
              label="Suggestions Auto Acceptable"
              value={Boolean(props.suggestions_auto_acceptable) + ""}
            />
            <Field
              label="Directives"
              value={<JSONPretty json={props.directives} />}
            />
            <Field label="Notes" value={props.notes} />
          </div>
        </div>
      ) : (
        <div className="editor__imports__import__details__json">
          <JSONPretty json={props} />
        </div>
      )}
    </div>
  );
}

export default ImportDetails;
