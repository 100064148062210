import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as turf from '@turf/turf';
import * as util from '@library/place-details/util';
import BaseMap from '@library/map/BaseMap';
import BoundsControl from '@library/map/BoundsControl';
import CustomMarker from '@library/map/Marker';
import ListingDetailsLink from '@library/place-details/link';
import ListingThumbnailLink from '@library/place-details/thumbnail-link';
import MarkerIcon from '@library/map/MarkerIcon';
import Popup from '@library/map/Popup';
import { useStoreContext } from '@/state';
import './styles';

class ListingResultsMap extends Component {
  state = {
    viewport: {
      pitch: 0,
      bearing: 0
    },
    popup: null,
    // Array of ids used to draw markers on the map in order
    // of when they were last in focus. Listings most recently
    // in focus are at the end of the array.
    order: [],
    // Listing objects mapped to their ids for easy retrieval
    // during reordering.
    map: {}
  };

  mapRef = React.createRef();

  moveToFront = t => {
    if (t.length < 2) {
      return t;
    }
    const f = t.find(r => r === this.props.inFocus);
    if (f) {
      const arr = t.filter(r => r !== f);
      arr.push(f);
      return arr;
    }
  };

  updatePinsAndZoom = results => {
    if (results.length > 0) {
      const bounds = this.determineBounds(results);
      const { map, order } = this.constructMap(results);
      // bonds can either be an array of 2 numbers or an array of 2 arrays
      if (bounds) {
        if (typeof bounds[0] === 'number' || typeof bounds[1] === 'number') {
          // Only one location, we just center the map on it
          this.setCenter(bounds[0], bounds[1]);
          this.setState({ map, order });
        } else {
          // The rest of the time we use the calculated bounds
          this.setState({ bounds, map, order });
        }
      } else {
        // Center map on Americas if no bounds
        this.setCenter(-98, 38, 1);
      }
    }
  };

  componentDidMount = () => {
    // Close popup on outside click
    document.addEventListener('click', this.handleClickOutside);
    this.updatePinsAndZoom(this.props.results);
  };

  constructMap = l =>
    l.reduce(
      (prev, curr, idx) => {
        prev.map[curr.id] = curr;
        prev.map[curr.id].markerNumber = idx + this.props.offset;
        prev.order.push(curr.id);
        return prev;
      },
      { map: {}, order: [] }
    );

  determineBounds = results => {
    const coords = results
      .filter(
        ({ locations }) =>
          locations && locations.length > 0 && locations[0] && locations[0].longitude && locations[0].latitude
      )
      .map(({ locations }) => [locations[0].longitude, locations[0].latitude]);

    if (coords.length > 0) {
      if (coords.length === 1) {
        return coords[0];
      }
      const line = turf.lineString(coords);
      const bbox = turf.bbox(line);
      return [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]]
      ];
    }
    return null;
  };

  roadtripDetailsIcon = (road_trip_details) => {
    if(road_trip_details) {
      return road_trip_details.map_illustration ? road_trip_details.map_illustration : '';
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.inFocus && this.props.inFocus !== nextProps.inFocus) {
      this.setState(prevState => {
        // If the listing in focus changes, push the listing that is now in focus
        // to the end of the 'order' array in state.
        const order = this.moveToFront(prevState.order);
        // If a popup is open, move the popup to the marker for the listing that is in focus
        if (prevState.popup) {
          const result = nextProps.results.find(r => r.id === nextProps.inFocus);
          if (result) {
            return { order, popup: { ...result, ...result.locations[0] } };
          }
        }
        return { order };
      });
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside);
  };

  setCenter = (longitude, latitude, zoom = 8) => {
    this.setState(prevState => ({
      viewport: {
        ...prevState.viewport,
        center: [longitude, latitude],
        zoom
      }
    }));
  };

  openPopup = (result, location) => {
    this.setState({ popup: { ...result, ...location } });
    this.props.handleFocus(result.id);
  };

  closePopup = () => {
    this.props.handleFocus(null);
    this.setState({ popup: null });
  };

  handleClickOutside = () => {
    this.closePopup();
  };

  Observer = ({results, focusId}) => {
    useEffect(() => {
      this.updatePinsAndZoom(results);
    }, [results]);

    return null;
  }

  render = () => {
    const thumbnailUrl = this.state.popup ? util.findThumbnailUrl(this.state.popup.images) : '';
    const popupDetailsViewPath = this.state.popup ? '/listings/' + this.state.popup.id : '';
    const popupHref = window.location.protocol + '//' + window.location.host + popupDetailsViewPath;
    return (
      <div ref={this.mapRef} className="search-results search-results__map">
        <this.Observer results={this.props.results} focusId={this.props.inFocus} ></this.Observer>
        <div className="false-thead__th" />
        <div className="map-container">
          <BaseMap
            viewport={this.state.viewport}
            onClick={() => this.closePopup()}
            onViewPortChange={viewport => {
              this.setState({ viewport });
            }}
          >
            {this.state.bounds && <BoundsControl bounds={this.state.bounds} passMap={map => this.getMap(map)} />}
            {this.state.order.map(id => {
              const result = this.state.map[id];
              return (
                result.locations &&
                result.locations.map((location, idx) => (
                  <CustomMarker
                    key={result.id + '-' + idx}
                    onMouseEnter={() => this.openPopup(result, location)}
                    mapOpen={this.state.mapOpen}
                    id={result.id}
                    position={[location.longitude, location.latitude]}
                    selected={this.props.inFocus === result.id}
                  >
                    <a href={'/listings/' + result.id}>
                      <MarkerIcon name={result.name} number={result.markerNumber} selected={this.props.inFocus === result.id} icon={this.roadtripDetailsIcon(result.road_trip_details)}/>
                    </a>
                  </CustomMarker>
                ))
              );
            })}
            {<div id="popup-anchor" />}
            {this.state.popup && (
              <Popup longitude={this.state.popup.longitude} latitude={this.state.popup.latitude}>
                <div
                  className="popup has-photo"
                  onClick={() => {
                    window.location.href = popupHref;
                    analytics.trackListing('Listing', 'selected from map', {
                      canonical_place_id: this.state.popup.id,
                      search_query: popupHref
                    });
                  }}
                >
                  <ListingThumbnailLink popupDetailsViewPath={popupDetailsViewPath} thumbnailUrl={thumbnailUrl} />
                  <ListingDetailsLink
                    id={this.state.popup.id}
                    showMeta={false}
                    verified={false}
                    internalOrg={false}
                    popupDetailsViewPath={popupDetailsViewPath}
                    name={this.state.popup.name || ''}
                    categories={this.state.popup.categories}
                    locations={[this.state.popup.locations[0]]}
                  />
                </div>
              </Popup>
            )}
          </BaseMap>
        </div>
      </div>
    );
  };
}

ListingResultsMap.propTypes = {
  results: PropTypes.array.isRequired,
  offset: PropTypes.number,
  inFocus: PropTypes.number,
  handleFocus: PropTypes.func.isRequired
};

export default function Map(props) {
  const { analytics } = useStoreContext();
  return <ListingResultsMap {...props} analytics={analytics} />;
}
