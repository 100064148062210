import React from 'react';
import PropTypes from 'prop-types';

const ListingThumbnailLink = ({ thumbnailUrl, detailsViewPath }) => (
  <a href={detailsViewPath} className="boone-link boone-link__listing">
    <div className="boone-image__thumbnail" style={{ backgroundImage: `url(${thumbnailUrl})` }} />
  </a>
);

ListingThumbnailLink.propTypes = {
  thumbnailUrl: PropTypes.string,
  detailsViewPath: PropTypes.string
};

export default ListingThumbnailLink;
