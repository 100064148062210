
import FilterForm from "@/library/filter-form";
import React from 'react';
import ReactAutocomplete from 'react-autocomplete'
import AutocompleteRegion from './AutocompleteRegion';

const getItemsFromAutocompleteResponse = function (response) {
  const items = response;

  items.forEach(item => {
    if (item.address) {
      const state = item.address.split(',')[0];

      // Add the state to the end of `item.name`, unless the name already contains the state.
      if (!item.name.endsWith(', ' + state)) {
        item.name = item.name + ', ' + state;
      }
    }
  });

  return items;
};

class RegionFilter extends React.Component {

  onFilterChange() {
    this.setState(prevState => ({ appliedRegions: Object.values(prevState.selectedRegions) }), function() {
      const newValue = this.state.appliedRegions.map(r => r.id + ':' + r.name).join('|');
      this.props.applyFilter({ 'regions': newValue });
    });
  }

  onFilterCancel() {
    this.setState(prevState => {
      let selectedRegions = {};
      this.state.appliedRegions.forEach(r => selectedRegions[r.id] = r);
      return { selectedRegions: selectedRegions };
    });
  }

  constructor(props) {
    super(props);

    var selectedRegions = {};
    this.props.data.forEach(function (region) {
      selectedRegions[region.id] = region;
    });

    this.state = {
      isActive: false,
      items: [],
      value: '',
      appliedRegions: Object.values(selectedRegions),
      selectedRegions: selectedRegions
    };

    this.renderItem = this.renderItem.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterCancel = this.onFilterCancel.bind(this);
  }

  // LIFECYCLE

  componentDidMount() {
    this.updateResults({value: this.state.value});
    this.input.focus();
  }

  // HELPERS

  activate() {
    this.setState({
      isActive: true
    });
  }

  autocomplete({value}) {
    let params = {
      input: value,
      limit: 10,
      type: 'regions'
    };

    const queryString = $.param(params);
    const url = `/autocomplete?${queryString}`;
    const request = this.getJSON(url);

    const promise = request.promise.then(function (response) {
      return getItemsFromAutocompleteResponse(response);
    });
    return {
      promise: promise
    };
  }

  updateResults({value}) {
    if (value.length > 0) {
      let request;
      request = this.autocomplete({value});
      this.lastRequest = request;
      request.promise.then(function (items) {
        if (request === this.lastRequest) {
          if (items.length <= 0) {
            items = [{
              type: 'no_results'
            }];
          }
          this.addSelectedItems(items);

        }
      }.bind(this), function (xhr) {
        console.error('Error fetching search results: value, xhr: ', value, xhr);
      });

    } else {
      this.lastRequest = null;
      this.addSelectedItems([]);
    }

    this.setState({
      value: value
    });
  }

  addSelectedItems(items) {
    // Add the selected items back in
    for (var id in this.state.selectedRegions) {
      let found = false;
      items.forEach(function (item) {
        if (item['id'] == id) {
          found = true;
        }
      });
      if (!found) {
        items.unshift(this.state.selectedRegions[id]);
      }
    }
    this.setState({
      items: items
    });
  }

  // RENDER

  render() {
    return (

      <FilterForm
        active={this.state.appliedRegions.length > 0}
        label={this.renderButtonLabel()}
        name="region"
        cy="region"
        onApply={this.onFilterChange}
        onCancel={this.onFilterCancel}
      >
        <div className="regions-filter-container">
          <div
            className='autocomplete'
            onFocus={() => {
              // Autocomplete is active when any autocomplete element has focus.
              // This allows the autocomplete to show results when the user toggles
              // location biasing.
              if (!this.state.isActive) {
                this.activate();
              }
            }}
            ref={autocompleteEl => this.autocompleteEl = autocompleteEl}>

            <div className="search-icon">
              <i className="mdi mdi-search"></i>
            </div>

            <ReactAutocomplete
              getItemValue={(item) => {
                return this.state.value;
              }}
              inputProps={{
                placeholder: 'Filter regions',
                className: 'autocomplete-input'
              }}
              items={this.state.items}
              menuStyle={{
                background: 'white'
              }}
              onChange={(event, value) => {
                this.updateResults({
                  value
                });
              }}

              open={this.state.isActive && this.state.items.length > 0}
              ref={input => this.input = input}
              renderItem={this.renderItem}
              value={this.state.value}
              wrapperStyle={{
                display: 'block'
              }}
            />
            {this.renderClearButton()}
          </div>
        </div>
      </FilterForm>
    );
  }

  renderButtonLabel() {
    const appliedRegions = this.state.appliedRegions;
    let label = null;

    if (appliedRegions.length > 1) {
      label = this.props.label + ' • ' + appliedRegions.length;
    } else if (appliedRegions.length == 1) {
      label = appliedRegions[0].name;
    } else {
      label = this.props.label;
    }

    return label;
  }

  renderClearButton() {
    if (this.state.value.length > 0) {
      return (
        <div className="clear" role="button" onClick={() => {
          this.updateResults({
            value: ''
          });
          this.input.focus();
        }}>
          <i className="mdi mdi-clear"></i>
        </div>
      );
    }
  }

  renderItem(item, isHighlighted) {
    // NOTE: `ReactAutocomplete` expects item elements to support the `onClick`
    // NOTE: and `onMouseEnter` event props so it can handle highlighting and
    // NOTE: selecting items with the mouse. Component elements do not support
    // NOTE: those events by default, so wrap them in a `<div>` so that item
    // NOTE: components don't have to implement them.
    return (
      <div>
        {this.renderItemComponent(item, isHighlighted)}
      </div>
    );
  }

  renderItemComponent(item, isHighlighted) {
    if (item.type == 'no_results') {
      return this.renderNoResults();
    } else {
      return (
        <AutocompleteRegion
          key={item.id}
          item={item}
          isHighlighted={isHighlighted}
          isSelected={this.state.selectedRegions.hasOwnProperty(item.id)}
          onItemSelectedChanged={this.toggleSelectedRegion}
        />
      )
    }
  }

  renderNoResults() {
    return (
      <div className="no-results-item">
        <span className="autocomplete-item-name">
          No results found.
        </span>
      </div>
    );
  }

  toggleSelectedRegion = item => {
    var selectedRegions = Object.assign({}, this.state.selectedRegions);
    if (selectedRegions[item.id]) {
      delete selectedRegions[item.id];
    } else {
      selectedRegions[item.id] = item;
    }
    this.setState({selectedRegions: selectedRegions});
  }

  /**
   * @param {String} url
   * @return {Object} result
   * @return {Promise<Function(XMLHttpRequest.response), Function(XMLHttpRequest)>} result.promise
   * @return {XMLHttpRequest} result.xhr
   */
  getJSON(url) {
    const xhr = new XMLHttpRequest();
    const promise = new Promise(function (resolve, reject) {
      const handleError = function (event) {
        reject(event.currentTarget);
      }
      const handleLoad = function (event) {
        const xhr = event.currentTarget;
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(JSON.parse(xhr.response));
        } else {
          reject(xhr);
        }
      };
      xhr.addEventListener('load', handleLoad, false);
      xhr.addEventListener('error', handleError, false);
      xhr.open('GET', url);
      xhr.send();
    });
    return {
      promise: promise,
      xhr: xhr
    };
  }

}

export default RegionFilter;
