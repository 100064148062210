import * as React from 'react';

const useChangeLayoutClasses = function({
  headingClass,
  contentClass,
  bodyClass,
  resetClasses
}: {
  headingClass?: string;
  contentClass?: string;
  bodyClass?: string;
  resetClasses?: boolean;
  }): void {

  const getElements = () => {
    const getElById = id => document.getElementById(id);
    const headingEl = getElById('heading-container');
    const contentEl = getElById('content-container');
    const bodyEl = document.body;
    return { headingEl, bodyEl, contentEl };
  };

  const elements = getElements();

  const [prevHeadingClass] = React.useState(elements.headingEl.className);
  const [prevContentClass] = React.useState(elements.contentEl.className);
  const [prevBodyClass] = React.useState(elements.bodyEl.className);

  React.useEffect(
    () => {
      const reset = () => {
        const elements = getElements();
        elements.headingEl.className = prevHeadingClass;
        elements.contentEl.className = prevContentClass;
        elements.bodyEl.className = prevBodyClass;
      };

      if (resetClasses) {
        reset();
      } else {
        if (headingClass) {
          elements.headingEl.className = headingClass;
        }
        if (contentClass) {
          elements.contentEl.className = contentClass;
        }
        if (bodyClass) {
          elements.bodyEl.className = bodyClass;
        }
      }
      // Cleanup before dismount
      return () => {
        reset();
      };
    },
    [resetClasses]
  );
};

export default useChangeLayoutClasses;
