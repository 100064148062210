import * as moment from 'moment';
import 'moment-timezone';

const format_time = function(time_str) {
  var [hour, min] = time_str.split(':');
  var hour_as_i = parseInt(hour);
  var pm = hour_as_i >= 12;
  if (hour_as_i === 0) {
    hour_as_i = 12;
  } else if (hour_as_i > 12) {
    hour_as_i -= 12;
  }
  return hour_as_i + ':' + min + (pm ? 'PM' : 'AM');
};

const timeDescription = function(date, time, timezone, includeTimezone = true) {
  const utcDay = moment(date).format('YYYY-MM-DD');
  timezone = timezone || moment.tz.guess();
  const utcTime = moment.utc(time).format('HH:mm');
  let convertedTime = moment.tz(utcDay + ' ' + utcTime, timezone);
  const format = includeTimezone ? 'hh:mmA z' : 'hh:mmA';
  convertedTime = convertedTime.isValid() ? convertedTime.format(format) : '';

  return formatDate(date) + convertedTime;
};

const formatDate = function(date, opts) {
  const monthFormat = opts && opts.fullMonth ? 'MMMM' : 'MMM';
  return moment(date).format(monthFormat + ' D, YYYY ');
};

export default {
  format_time,
  timeDescription,
  formatDate
};

export const isToday = date => (date ? moment(date).isSame(moment(), 'day') : false);
