import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const Separator = ({ children, muted = true }) => (
  <div
    className={cn({
      'boone-separator': true,
      'text-muted': muted
    })}
  >
    {children}
  </div>
);

Separator.propTypes = {
  children: PropTypes.any,
  muted: PropTypes.bool
};

export default Separator;
