import React from "react";
import Section from "@library/section";
import useLink from "@library/link/useLink";
import ViewLink from "@library/link/view";
import ViewField from "@library/view-field";
import "./styles";

function Canonical(props) {
  const link = useLink({
    internalLink: document.location.pathname + "#general",
  });
  const loc =
    props.canonicalPlace.locations && props.canonicalPlace.locations[0];
  const contact = props.canonicalPlace.contact_info;
  const eng = props.canonicalPlace.engagement;
  return (
    <div data-cy="dashboard-canonical" className="editor__dashboard__canonical">
      <Section
        header={
          <h4 {...link}>
            <span>Canonical Place</span>
          </h4>
        }
      >
        <div
          data-cy="canonical-content"
          className="editor__dashboard__canonical__content"
        >
          <div className="editor__dashboard__canonical__public">
            <div className="editor__dashboard__canonical__source">
              <ViewField
                hideNull
                label={"Canonical Place Id"}
                cy={"canonical-id"}
                value={props.canonicalPlace.id}
              />
              <ViewField
                hideNull
                label={"Source"}
                value={props.canonicalPlace.authority_source_name}
              />
              <ViewField
                hideNull
                label={"Source Id"}
                value={props.canonicalPlace.authority_source_id}
              />
            </div>
            <ViewField
              hideNull
              cy={"canonical-location"}
              label="Location"
              value={
                loc &&
                (loc.address1 || "") +
                  " " +
                  (loc.city || "") +
                  " " +
                  (loc.state || "") +
                  " " +
                  (loc.postal_code || "")
              }
            />
            <div className="editor__dashboard__canonical__contact">
              <ViewField
                hideNull
                label="Phone"
                cy={"canonical-phone"}
                value={contact && contact.phone}
              />
              <ViewField
                hideNull
                label="Email"
                cy={"canonical-email"}
                value={contact && contact.email}
              />
              <ViewLink
                hideNull
                label="Website"
                cy={"canonical-website"}
                externalLink={contact && contact.website}
                value={contact && contact.website}
              />
            </div>
          </div>
          {props.canViewInternalData && (
            <div
              data-cy="internal-data"
              className="editor__dashboard__canonical__internal"
            >
              <ViewField
                hideNull
                label={"Previous Ids"}
                value={
                  props.canonicalPlace.previous_ids ? (
                    props.canonicalPlace.previous_ids.join(", ")
                  ) : (
                    <i>none</i>
                  )
                }
              />
              <ViewField
                hideNull
                label={"Fidelity"}
                value={props.canonicalPlace.fidelity_level}
              />

              <ViewField
                hideNull
                label={"Avg rating"}
                value={eng && eng.avg_rating}
              />
              <ViewField
                hideNull
                label={"Rating Count"}
                value={eng && eng.rating_count}
              />
              <ViewField label={"Visits"} value={eng && eng.visits} />
              <ViewField
                label={"Travel Rank"}
                value={props.canonicalPlace?.ranking_score}
              />
              {props.canonicalPlace?.chains &&
                props.canonicalPlace?.chains[0] && (
                  <ViewField
                    label={"Chain"}
                    value={props.canonicalPlace?.chains
                      .map((chain) => chain.name)
                      .join(" ")}
                  />
                )}
            </div>
          )}
        </div>
      </Section>
    </div>
  );
}

export default Canonical;
