import React from "react";
import { useContext } from "@/data-outreach/_core/forms/useContext";;

function useSteps() {

  const ctx = useContext();

  return [
    {
      showStep: true,
      hideBack: true,
      hideNext: false,
      nextButtonText: "Submit",
      save: ctx.save

    },
    {
      showStep: true,
      hideBack: true,
      hideNext: true,
    }
  ];
}

export default useSteps;
