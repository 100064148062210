import React from "react";
import Section from "@library/section";
import ViewField from "@library/view-field";
import "./styles";
import Link from "@library/link/view";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Places(props) {
  return (
    <div data-cy="dashboard-places" className="editor__dashboard__places">
      <Section header={<h4>{"Places (" + props.places.length + ")"}</h4>}>
        <div
          data-cy="places-content"
          className="editor__dashboard__places__content"
        >
          {props.places && props.places.length > 0 ? (
            props.places.map((place) => {
              const loc = place.locations && place.locations[0];
              const locStr =
                loc &&
                `${loc.address1 || ""} ${loc.city ? loc.city + "," : ""} ${
                  loc.state || ""
                } ${loc.postal_code || ""}`;
              return (
                <div
                  key={place.id}
                  className="editor__dashboard__places__place"
                >
                  <div>
                    <ViewField
                      className="editor__dashboard__places__place__id-source"
                      hideNull
                      value={place.id}
                    />
                    <ViewField
                      className="editor__dashboard__places__place__id-source"
                      hideNull
                      value={place.source_name}
                    />
                    <Link externalLink={place.source_item_url}>
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </Link>
                  </div>
                  <div className="editor__dashboard__places__place__data">
                    <ViewField hideNull label="name" value={place.name} />
                    <ViewField
                      hideNull
                      label="location"
                      value={locStr && locStr !== "   " && locStr}
                    />
                    {place.categories && (
                      <ViewField
                      hideNull
                      label="categories"
                      value={Object.values(place.categories).join(', ')}
                    />
                    )}
                    {place.contact_info && (
                      <ViewField
                        hideNull
                        label="phone"
                        value={place.contact_info.phone}
                      />
                    )}
                    {place.contact_info && (
                      <ViewField
                        hideNull
                        label="email"
                        value={place.contact_info.email}
                      />
                    )}
                    {place.contact_info && (
                      <ViewField
                        hideNull
                        label="website"
                        value={place.contact_info.website}
                      />
                    )} 
                  </div>
                </div>
              );
            })
          ) : (
            <i>none</i>
          )}
        </div>
      </Section>
    </div>
  );
}

export default Places;
