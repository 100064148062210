import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const BooneCardHeader = ({ children }) => <div className="boone-card__header">{children}</div>;
BooneCardHeader.propTypes = {
  children: PropTypes.any
};

export const BooneCardBody = ({ children }) => <div className="boone-card__body">{children}</div>;
BooneCardBody.propTypes = {
  children: PropTypes.any
};

export const BooneCardFooter = ({ children }) => <div className="boone-card__footer">{children}</div>;

BooneCardFooter.propTypes = {
  children: PropTypes.any
};

export default class BooneCard extends PureComponent {
  render() {
    return <div className={`boone-card ${this.props.className}`}>{this.props.children}</div>;
  }
}

export { BooneCard };

BooneCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};
