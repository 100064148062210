import * as React from "react";
import Feature from "../../../media/actions/Feature";
import Flag from "../../../media/actions/Flag";
import Disable from "../../../media/actions/Disable";
import View from "../../../media/actions/View";
import cn from "classnames";
import "./styles";
import Section from "@library/section";

type Props = {
  featured: boolean;
  flagged: boolean;
  targetKey: string;
  ownerKey: string;
  ownerId: string;
  url: string;
  mediaId: number;
  width?: number;
  height?: number;
  sourceName: string;
  sourceId: number;
  attributionName?: string;
  attributionUrl?: string;
  caption?: string;
  onClick: () => void;
  mediaDisabled: () => void;
  mediaFlagged: () => void;
  mediaUnflagged: () => void;
  featuredMediaChanged: (featured: boolean) => void;
  canFlagMedia: boolean;
  canDisableMedia: boolean;
  canFeatureMedia: boolean;
  canViewMedia: boolean;
  imageUrl: string;
  userEditingStrategy: "update" | "suggest" | "none";
};

const Media = function ({
  featured,
  imageUrl,
  flagged,
  targetKey,
  ownerKey,
  ownerId,
  url,
  mediaId,
  width,
  height,
  sourceName,
  sourceId,
  attributionName,
  attributionUrl,
  caption,
  onClick,
  featuredMediaChanged,
  mediaDisabled,
  mediaFlagged,
  mediaUnflagged,
  canFlagMedia,
  canDisableMedia,
  canFeatureMedia,
  canViewMedia,
}: Props) {
  const [failure, setFailure] = React.useState(false);
  return (
    <div
      className={cn({
        editor__media: true,
        "editor__media--featured": featured,
        "editor__media--flagged": flagged,
      })}
    >
      <div
        className={cn({
          editor__media__media: true,
        })}
        onClick={onClick}
      >
        <img
          alt=" "
          className={cn({
            editor__media__image: true,
            "editor__media__image--error": true,
          })}
          onError={(e) => {
            // If image fails to download just show gray box.
            if (e.currentTarget) {
              setFailure(true);
            }
          }}
          src={imageUrl}

        />
      </div>
      <div className="editor__media__meta">
        <div className="editor__media__data">
        <Section maxWidth={280} minHeight={130} collapsed={true} header={
          <div className='editor__media__data__details' >
            <div>
              <b>{`Status: `}</b>
              {`${flagged ? `hidden` : `visible`}`}
            </div>
              <div>
              <b>{`Priority: `}</b>
                {`${featured ? `featured` : `not featured`}`}
            </div>
              <div>
              <b>{`Dimensions:  `}</b>
                {width && height ? `${width} x ${height}` : `n/a`}
            </div>
              <div>
              <b>{`Source: `}</b>
                {`${sourceName} (${sourceId})`}
            </div>
          </div>}>
          <div>
            <b>{`Caption: `}</b>
            {`${caption|| 'none'}`}
          </div>
          <div>
            <b>{`Attribution: `}</b>
            {`${attributionName || 'none'}`}
          </div>
          <div>
            <b>{`Attribution URL: `}</b>
            {`${attributionUrl|| 'none'}`}
          </div>
        </Section>

        </div>
        <div className="editor__media__controls">
          <span>
            {canDisableMedia && (
              <Disable
                className="editor__button editor__button--warning"
                mediaDisabled={mediaDisabled}
                id={mediaId}
                ownerKey={ownerKey}
                ownerId={ownerId}
                url={url}
              />
            )}
          </span>
          <span>
            {canFeatureMedia && (
              <Feature
                className={cn({
                  editor__button: true,
                  "editor__button--white": featured && !flagged,
                  "editor__button--primary": !featured && !flagged,
                  "editor__button--disabled": flagged,
                })}
                flagged={flagged}
                featuredMediaChanged={featuredMediaChanged}
                featured={featured}
                targetKey={targetKey}
                url={url}
              />
            )}
            {canFlagMedia && (
              <Flag
                className={cn({
                  editor__button: true,
                  "editor__button--white": flagged,
                  "editor__button--disable": !flagged && !featured,
                  "editor__button--disabled": featured,
                })}
                flagged={flagged}
                url={url}
                mediaFlagged={!featured && mediaFlagged}
                mediaUnflagged={mediaUnflagged}
                ownerId={ownerId}
                ownerKey={ownerKey}
              />
            )}
          </span>
          {!canFeatureMedia && canViewMedia && <View />}
        </div>
      </div>
    </div>
  );
};

export default Media;
