import React, { Component } from 'react';
import * as ajax from '@/helpers/ajax';
import DailyHoursView from '../../hours/DailyHours';
import deepClone from '../../helpers/DeepClone';
import { EditData, ViewData } from '@library/data-section';

const DEFAULT_DAY_MAP = {
  sun_open_close: [],
  mon_open_close: [],
  tue_open_close: [],
  wed_open_close: [],
  thu_open_close: [],
  fri_open_close: [],
  sat_open_close: []
};

type HoursMap = {
  '<default>': {
    sun_open_close: string[];
    mon_open_close: string[];
    tue_open_close: string[];
    wed_open_close: string[];
    thu_open_close: string[];
    fri_open_close: string[];
    sat_open_close: string[];
  };
};

type Props = {
  userEditingStrategy: 'update' | 'suggest' | 'none';
  hours: HoursMap;
};

type State = {
  hours: HoursMap;
  savedHours?: HoursMap;
  editing: boolean;
  saving: boolean;
};

class ListingSchedule extends Component<Props, State> {
  state: State = {
    hours: deepClone(this.props.hours),
    editing: false,
    saving: false
  };

  getInitialState(): State {
    return {
      hours: deepClone(this.props.hours),
      editing: false,
      saving: false
    };
  }

  handleChange = (key, value) => {
    let hours = this.state.hours || deepClone(DEFAULT_DAY_MAP);
    hours[key] = value;
    this.setState({ hours });
  };

  getHours() {
    return { '<default>': this.state.hours };
  }

  save = () => {
    this.setState({ saving: true });
    ajax
      .patch(document.location.pathname, {
        listing:
          {
            hours: this.getHours()
          }
      })
      .then((res) => {
        this.setState({
          editing: false,
          saving: false,
          savedHours: deepClone(this.state.hours)
        });
      });
  };

  cancel = () => {
    let state = this.getInitialState();
    if (this.state.savedHours) {
      state.savedHours = this.state.savedHours;
      state.hours = deepClone(this.state.savedHours);
    }
    this.setState(state);
  };

  renderDailyHours() {
    let dayMap = this.state.hours || {};
    if (this.state.editing && Object.keys(dayMap).length === 0) {
      dayMap = DEFAULT_DAY_MAP;
    }

    return dayMap && Object.keys(dayMap).length > 0 ? (
      Object.keys(dayMap).map((k) => (
        <DailyHoursView
          key={k}
          day={k}
          onChange={this.handleChange}
          hours={dayMap[k]}
          editing={this.state.editing}
        />
      ))
    ) : (
      <p className="text-muted p-3 m-0">No hours set for this listing</p>
    );
  }

  onSuccess = () => {
    this.setState((prevState) => ({
      editing: false,
      savedHours: deepClone(prevState.hours)
    }));
  };

  render() {
    return (
      <React.Fragment>
        <h4 className="mt-5">Hours</h4>
        <EditData
          sectionTitle="Hours"
          params={{
            listing:
              {
                hours: this.getHours()
              }
          }}
          cy="hours-edit"
          userEditingStrategy={this.props.userEditingStrategy}
          show={this.state.editing}
          cancel={() => this.cancel()}
          onSuccess={() => this.onSuccess()}
        >
          <div className="hours">{this.renderDailyHours()}</div>
        </EditData>
        <ViewData
          cy="hours-view"
          sectionTitle="Hours"
          userEditingStrategy={this.props.userEditingStrategy}
          show={!this.state.editing}
          onClick={() => this.setState({ editing: true })}
        >
          <div className="hours">{this.renderDailyHours()}</div>
        </ViewData>
      </React.Fragment>
    );
  }
}

export default ListingSchedule;
