const attrMap = {
  "general_update": "Updated",
  "validated": "Validated",
  "resolved_duplicates": "Fixed duplicates",
  "contact_update": "Contacted",
  "note": "Note"
};

const shortAttrMap = {
  "general_update": "U",
  "validated": "V",
  "resolved_duplicates": "D",
  "contact_update": "C",
  "note": "N"
};

interface Audit {
  note: string;
  author: string;
}

export function stringifyBools(audit: Audit, short: boolean = false): string {
  let bools = Object.keys(audit).filter(k => audit[k] === true);
  bools = bools.length === 0 ? ['note'] : bools;
  let str = bools.map(k => short ? shortAttrMap[k] : attrMap[k]).sort().join(', ');
  str = short ? '[' + str + ']' : str;
  return str;
}

export function stringifyNoteAndAuthor(audit: Audit): string {
  return [audit.note, '(' + audit.author + ')'].filter(val => val).join(' ');
}
