import React from 'react';
import HourSelect from './HourSelect';
import TimeFormat from '../helpers/TimeFormat';
import PropTypes from 'prop-types';
import IconDash from '../icons/IconDash';

export const EditHour = ({ showLabel, hourSelect, hour, updateHour, label }) => {
  const hourfmt = /\d\d:\d\d/i;
  // capitalize first letter if non hour fmt (sunrise, sunset)
  const capFirst = string => string.charAt(0).toUpperCase() + string.slice(1);
  return (
    <React.Fragment>
      {showLabel ? <div className="text-sm-right text-muted mr-2">{label}</div> : null}
      {hourSelect ? (
        <div className="mr-2 ml-2">
          <HourSelect value={hour} onChange={updateHour} />
        </div>
      ) : (
        <div className="mr-4"> {hourfmt.test(hour) ? TimeFormat.format_time(hour) : capFirst(hour)} </div>
      )}
    </React.Fragment>
  );
};

const HoursSelect = ({ editing, openHour, closeHour, updateOpenHour, updateCloseHour }) => (
  <div className="hours__hours-select">
    <div className="hours__hours-select__hour-select">
      <EditHour
        label="from"
        hourSelect={editing}
        showLabel={!editing}
        hour={openHour}
        updateHour={value => updateOpenHour(value)}
      />
    </div>
    {editing ? <IconDash className="hours__hours-select__divider" width="15" height="15" /> : null}
    <div className="hours__hours-select__hour-select">
      <EditHour
        label="until"
        hourSelect={editing}
        showLabel={!editing}
        hour={closeHour}
        updateHour={value => updateCloseHour(value)}
      />
    </div>
  </div>
);

HoursSelect.propTypes = {
  editing: PropTypes.bool.isRequired,
  openHour: PropTypes.string.isRequired,
  closeHour: PropTypes.string.isRequired,
  updateCloseHour: PropTypes.func.isRequired,
  updateOpenHour: PropTypes.func.isRequired
};

export default HoursSelect;
