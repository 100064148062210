import React from 'react';
import PropTypes from 'prop-types';

const CheckboxWithLabel = ({
  checked,
  onChange,
  label,
  labelClassName,
  inputClassName,
  required,
  showCheckbox = true,
  showLabel = true,
  labelId,
  form,
  cy
}) => (
    <React.Fragment>
      {showCheckbox ? (
        <input
          data-cy={cy}
          form={form}
          required={required}
          aria-labelledby={labelId}
          className={'mr-2 ' + inputClassName}
          type="checkbox"
          checked={checked}
          onChange={e => onChange(e)}
        />
      ) : null}
      {showLabel ? (
        <div id={labelId} className={labelClassName} onClick={e => onChange(e)}>
          {label}
        </div>
      ) : null}
    </React.Fragment>
  );

CheckboxWithLabel.defaultProps = {
  showCheckbox: true,
  showLabel: true
};

CheckboxWithLabel.propTypes = {
  required: PropTypes.bool,
  form: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  showCheckbox: PropTypes.bool,
  showLabel: PropTypes.bool,
  labelId: PropTypes.string,
  cy: PropTypes.string
};

export default CheckboxWithLabel;
