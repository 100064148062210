import PropTypes from 'prop-types';
import React from 'react';
import { CancelButton, ApplyButton } from '@/library/Buttons';

class FilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.showFilter = this.showFilter.bind(this);
  }

  componentDidMount() {
    window.closeFilterForm();
  }

  handleApply() {
    window.closeFilterForm();
    this.props.onApply();
  }

  handleCancel() {
    window.closeFilterForm();
    this.props.onCancel();
  }

  render() {
    const buttonClasses = `btn btn-white ${this.props.btnClass || ''} ${this.props.active ? ' active' : ''}`;
    const buttonId = this.props.name ? this.props.name + '-button' : null;
    const formId = this.props.name ? this.props.name + '-filter' : null;
    const clearBtnLabel = this.props.clearBtnLabel || 'None';
    const clearBtnClass = 'btn ' + (this.props.clearBtnClass ? this.props.clearBtnClass : '');

    return (
      <span className="filter-item">
        <span className={buttonClasses} id={buttonId} onClick={this.showFilter} ref={el => (this.buttonNode = el)}>
          {this.props.label}
        </span>
        <div className="filter-form" id={formId} ref={el => (this.filterNode = el)}>
          {this.props.children}
          {this.props.hideButtons ? null : (
            <div className="form-buttons">
              <CancelButton onCancel={this.handleCancel} />
              <div>
                {this.props.onClear && (
                  <button type="button" className={clearBtnClass} onClick={this.props.onClear}>
                    {clearBtnLabel}
                  </button>
                )}
                <ApplyButton onClick={this.handleApply} />
              </div>
            </div>
          )}
        </div>
      </span>
    );
  }

  showFilter() {
    window.toggleFilter($(this.buttonNode), $(this.filterNode));
  }
}


export default FilterForm;
