import React from 'react';
import Location from '@/data-outreach/_core/forms/components/location';
import Name from '@/data-outreach/_core/forms/components/name';
import './styles';

export function Component({...props}) {
  const [errorMap, setErrorMap] = React.useState({
    mobile_radius: false,
  });
  return (
    <div className="editor__data-outreach__rv-service__listing">
      <Name updateField={props.updateField} state={props.state} />

      {props.state.mobile_service && (
        <Location
          validate={(value, field) => {
            if (field === "mobile_radius") {
              const error = value.length > 3 || parseInt(value) > 999;
              setErrorMap((m) => ({
                ...m,
                [field]: error,
              }));
            }
          }}
          errorMap={errorMap}
          updateField={props.updateField}
          state={props.state}
          title="RV Mobile Service Area"
          fields={[
            ["City", "mobile_city", "city"],
            ["State", "mobile_state", "state"],
            ["Radius (miles)", "mobile_radius", "radius"],
          ]}
        />
      )}

      <Location
        updateField={props.updateField}
        state={props.state}
        title={props.state.service_center ? "RV Service Center" : "Address"}
        fields={[
          ["Street Address", "service_address1", "address1"],
          ["Street Address Cont.", "service_address2", "address2"],
          ["City", "service_city", "city"],
          ["State", "service_state", "state"],
          ["Zip", "service_postal_code", "zip"],
        ]}
      />
    </div>
  );
}

export default Component;
