import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as ajax from '@/helpers/ajax';
import '../styles/index';
import { cleanFieldName } from './helpers'

type Props = {
  field: string;
  current: string;
  suggestion: string;
  union_on_apply: boolean;
};

const SuggestionDiff = (props: Props) => {

  const format = (value) => {
    const rawValue = JSON.parse(value);
    let label = value;

    if (rawValue === null) {
      label = <span className="text-muted">Unknown</span>;
    } else if (rawValue === '') {
      label = <span className="text-muted">Empty</span>;
    }

    return label;
  }

  return (
    <div className="container">
      <div className="row" style={{flexWrap: 'nowrap'}}>
        <div className="col-2 text-sm-right">
          <span className="rounded current-diff-label p-1 small">Current</span>
        </div>
        <div className="col-10">
          <span id="diff-orig-value preserve-whitespace">{format(props.current)}</span>
        </div>
      </div>

      <div className="row" style={{flexWrap: 'nowrap'}}>
        <div className="col-2 text-sm-right">
          <span className="rounded tomo-diff-label p-1 small">Suggestion {props.union_on_apply && '(unioned)'}</span>
        </div>
        <div className="col-10">
          <span id="diff-diff-value preserve-whitespace">{format(props.suggestion)}</span>
        </div>
      </div>
    </div>
  );
}

export default SuggestionDiff;
