import React, { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


export interface Account {
  avatarUrl?: string;
  currentOrg?: CurrentOrg;
  editPath: string;
  linkedToRoadpass: boolean
  name: string;
  organizations: [];
  role: number;
  signOutPath: string;
}

export interface AssignAuditData {
  users: Array<User>;
  listings?: Array<CanonicalPlace>;
}

export interface AuditData {
  audited?: boolean;
  notAudited?: boolean;
  startDateOffset?: string;
  endDateOffset?: string;
  auditTypes?: [];
  noteAdded?: string;
}

export interface AuditParams {
  id?: number;
  canonical_place_id: number;
  user_id: number;
}

export interface AuditQueue {
  show: boolean,
  anyone: User,
  user: string,
  users: Array<User>
}

export interface BulkAssignAuditData {
  user: User;
  query: string;
}

export interface CanonicalPlace {
  name?: string;
  locations?: Array<Location>;
  id?: number;
  last_updated?: string;
  uuid?: string;
  resultsIndex?: number;
  images?: Array<Image>;
  branded_attribution: string;
  branded_attribution_url: string;
  owner_verified?: Boolean;
  number: number;
  internalOrg: boolean;
  fidelity_level?: number;
  previous_ids: [];
  attribution?: {}[];
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  inFocus?: number;
  ranking_score?: number;
  listing_audit: {
    id: number;
    user_id: number;
  }
  road_trip_details?: {
    map_markers: [],
    map_illustration: string;
    editorial: string;
    contributor: string;
  }
  engagement?: {
    avg_rating: number;
    rating_count: number;
    visits: number;
  }
}

export interface CurrentOrg {
  api_client_id: string;
  api_client_secret: string;
  atlas_organization_id: string;
  can_invite_industry: boolean;
  created_at: string;
  enabled: boolean;
  id: number;
  invitation_pending: string;
  name: string;
  org_type: string;
  source_id: number;
  stripe_customer_id: string;
  updated_at: string;
}

export interface FilterData {
  categories: Array<any>;
  sources: Array<any>;
  chains: Array<any>,
  regions: Array<any>,
  needing_audit?: AuditData,
  audit_queue: AuditQueue
}

export interface FullResultsInfo {
  query: string;
  resultsTotal: number;
  pageTotal: number;
}

export interface Image {
  id?: number;
  image_url?: string;
  attribution_url?: string;
  fingerprint?: string;
  enabled: boolean;
  featured: boolean;
  sizes: Sizes;
  source_id: number;
  source_name: string;
  width: number;
}

export interface LaunchDarkly {
  user: {
    key: string
  },
  client: string
}

export interface Location {
  address1?: string;
  postal_code?: string;
  city?: string;
  state?: string;
  country?: string;
  map_zoom_level?: any;
  latitude?: number;
  longitude?: number;
  timezone?: string;
  navigable?: boolean;
}

export interface Meta {
  discoverable_min?: number;
  total: number;
}

export interface NavLink {
  name: string,
  path: string,
  show: boolean,
  internal: boolean,
  active: boolean
}

export interface NavLinks {
  corrections: NavLink;
  imports: NavLink;
  keys: NavLink;
  listing: NavLink;
  listings: NavLink;
  match: NavLink;
  organization: NavLink;
  support: NavLink;
  recentPlaces: Array<RecentPlace>
}

export interface RecentPlace {
  name: string;
  id: number;
  href: string;
}

export interface Size {
  width: number;
  height: number;
  url: string;
}

export interface Sizes {
  large: Size;
  medium: Size;
  small: Size;
  square: Size;
  thumbnail: Size;
}

export interface User {
  avatar_url?: string;
  created_at: string;
  email: string;
  enabled: boolean;
  id: number;
  name: string;
  onboarded_at?: string
  roadpass_id?: string
  updated_at: string
  assignedAudits?: Array<CanonicalPlace>
  role?: number;

}

export interface SearchProps {
  userId?: string;
  orgId?: string;
  value?: string;
}