import React, { useState, useEffect, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuditQueue, CanonicalPlace, Meta, User } from '../../common/types';
import { Listing } from './Listing';
import Pagination from '@/helpers/pagination';
import useApi from '../../hooks/useApi';
import auditsApi from '../../api/audits';
import { AssignAudit } from '../AssignAudit';


import './styles';
import { Sort } from './sort';
import { Results } from '../listingsView';

export interface Props {
  results?: Results;
  onUpdateResults: Function;
  handlePageChange: Function;
  handleStepPage: Function;
  pagination: Pagination;
  focusRef: number;
  scrollTo: any;
  onHover: Function;
  onLoading?: Function;
  sort: string;
  auditQueue: AuditQueue;
  selectedAuditUser: User;
  onSelectAuditUser: Function;
}

export const ListingsList = ({ ...props }: Props) => {
  const [auditOpen, setAuditOpen] = useState(false);
  const [showAllDetails, setShowAllDetails] = useState<boolean>();
  const [selectedListing, setSelectedListing] = useState<CanonicalPlace>();
  const [assignAuditAction, setAssignAuditAction] = useState<'create' | 'update'>();


  // AUDIT API INTERACTION ///
  const createAudit = useApi(auditsApi.postAuditsApi);
  const removeAudit = useApi(auditsApi.deleteAuditsApi);
  const reassignAudit = useApi(auditsApi.putAuditsApi);


  // Creating a new audit
  const handleOnCreateAudit = (result: CanonicalPlace) => {

    props.selectedAuditUser &&
      createAudit.request({
        canonical_place_id: result.id,
        user_id: props.selectedAuditUser.id,
      }).then((data) => {

        const updatedResults = props.results.data.map((oldResult) => {
          if (oldResult.id === result.id) {
            return { ...oldResult, listing_audit: { id: data.id, user_id: props.selectedAuditUser.id } };
          }
          return oldResult;
        });

        props.onUpdateResults({ ...props.results, data: updatedResults })
      })
    props.onSelectAuditUser(null);
    setAuditOpen(false);
  }

  // Remove an audit
  const handleOnRemoveAudit = (result: CanonicalPlace) => {
    result.listing_audit &&
      removeAudit.request(result.listing_audit.id).then(
        () => (delete result.listing_audit)
      )

  }
  // Update an audit / reassign
  const handleOnUpdateAudit = (result: CanonicalPlace) => {
    props.selectedAuditUser && result.listing_audit &&
      reassignAudit.request({
        id: result.listing_audit.id,
        canonical_place_id: result.id,
        user_id: props.selectedAuditUser.id,
      })
        .then((data) => {
          const updatedResults = props.results.data.map((oldResult) => {
            if (oldResult.id === result.id) {
              return {
                ...oldResult,
                listing_audit: {
                  id: data.id,
                  user_id: props.selectedAuditUser.id
                }
              };
            }
            return oldResult;
          });
          props.onUpdateResults(
            {
              ...props.results,
              data: updatedResults
            }
          )
        })
    props.onSelectAuditUser(null);
    setAuditOpen(false);
  }

  // Decides if reassigning an audit or updating
  const handleAuditAction = (result: CanonicalPlace) => assignAuditAction === 'update' ? handleOnUpdateAudit(result) : handleOnCreateAudit(result);

  useEffect(() => {
    !createAudit.error &&
      setAuditOpen(false);

  }, [createAudit.data]);


  return (
    <div className="container __results-list">
      <div className="__list-header">
        <div className="__content">
          <Sort sort={props.sort} />
        </div>
      </div>
      <div className="__list-body">
        <div id="row0" ref={(ref) => (props.scrollTo.current[0] = ref)}></div>
        {
          props.results && props.results.data.length > 0 &&
          props.results.data.map((result: CanonicalPlace, index: number) => {
            return (
              result ?
                <Listing
                  result={result}
                  onUpdateResults={props.onUpdateResults}
                  index={index}
                  start={props.pagination.start}
                  focusRef={props.focusRef}
                  scrollTo={props.scrollTo}
                  onHover={props.onHover}
                  onLoading={props.onLoading}
                  showAllDetails={showAllDetails}
                  key={`result-${result.id}`}
                  auditQueue={props.auditQueue}
                  auditShow={auditOpen}
                  auditToggle={() => {
                    props.onSelectAuditUser(null);
                    setAuditOpen(!auditOpen);
                  }}
                  OnRemoveAudit={handleOnRemoveAudit}
                  selectedAuditUser={props.selectedAuditUser}
                  onSelectedAuditUser={props.onSelectAuditUser}
                  selectedAuditListing={selectedListing}
                  onSelectedAuditListing={setSelectedListing}
                  onAuditAction={setAssignAuditAction}
                />
                :
                <>No data available.</>
            )
          }

          )
        }
      </div>
      {props.auditQueue &&
        <AssignAudit
          toggle={() => {
            props.onSelectAuditUser(null);
            setAuditOpen(!auditOpen);
          }}
          show={auditOpen}
          onUserClick={(user: User) => props.onSelectAuditUser(user)}
          onSave={() => {
            console.log(assignAuditAction);
            handleAuditAction(selectedListing)
          }}
          selectedUser={props.selectedAuditUser}
          assignAuditData={{
            users: [props.auditQueue.anyone, ...props.auditQueue.users],
            listings: [{ ...selectedListing }]
          }}
        />
      }
      <div className="list-footer">
        <div className="start">
          <small>Viewing {props.pagination.start.toLocaleString()} - {props.pagination.end.toLocaleString()} of {props.pagination.total.toLocaleString()}</small>
        </div>
        <div className="end">
          <div className="btn btn-light"
            title="Show All Details"
            onClick={() => { setShowAllDetails(!showAllDetails) }}><span className={`details-check${!showAllDetails ? ' --inactive' : ''}`}><FontAwesomeIcon icon={'square'} /></span> <FontAwesomeIcon icon={'list'} />
          </div>
          <button
            className="btn btn-light"
            disabled={!props.pagination.hasPreviousPage}
            onClick={() => props.handleStepPage('reverse')}>Prev</button>
          <button
            className="btn btn-light"
            disabled={!props.pagination.hasNextPage}
            onClick={() => props.handleStepPage('forward')}>Next</button>
        </div>
      </div>
    </div >
  )
}
