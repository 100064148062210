import React, { useState } from 'react';
import * as ajax from '@/helpers/ajax';
import Button from '@library/_button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles';

type Props = {
  onClick?: () => void;
  canDisable: boolean;
  enabled: boolean;
  guarded?: boolean;
};

function Disable(props: Props) {
  const [banner, setBanner] = useState(false);
  const [note, setNote] = useState(null);

  async function handleOnChange(event: any) {
    const value = event.target.value;
    setNote(value);
  }

  async function disable() {
    if (props.onClick) props.onClick();
    if (props.canDisable) {
      await ajax._delete(`${window.location.pathname}?note=${note}`);
    }
  }

  return (
    props.enabled &&
    props.canDisable && (
      <>
        {banner && (
          <>
            <div className="editor_overlay"></div>
            <div className="editor__disable__banner">
              <div>
                <p>Please specify a note for disabling this place.</p>
                <br />
                <input className="editor__input" placeholder="Note" onChange={handleOnChange}></input>
              </div>
              <br />
              <div className="editor__footer">
                <Button onClick={() => setBanner(false)} className="editor__cancel">
                  close
                </Button>
                <div className="editor__disable">
                  <Button onClick={disable} className="editor__error" disabled={!note}>
                    disable
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="editor__disable">
          <Button 
            onClick={() => setBanner(true)} 
            className="editor__error" 
            disabled={props.guarded}
            title={props.guarded ? 'Guarded' : 'Disable'}>
            {props.guarded && <FontAwesomeIcon icon={['fas', 'shield-alt']}/>} 
            disable
          </Button>
        </div>
      </>
    )
  );
}

export default Disable;
