import React from 'react';
import * as hooks from '../../library/launch-darkly/hooks';
import { useStoreContext } from '../../state';
import './styles/index';

import SuggestionsList from './components/suggestions-list'

function FilteredSuggestionsList(props) {
  const [showCategories] = hooks.useFlag('categories', false);
  const { analytics } = useStoreContext();
  return <SuggestionsList {...props} showCategories={showCategories} analytics={analytics} />;
}

export default FilteredSuggestionsList;
