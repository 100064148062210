import React from "react";
import BooneDropdown from '@/library/dropdown';

import "./styles";

type FilterOption = {
  name: string;
  key: string;
  default?: string;
  options: {id: string, name: string}[];
};

export function useFiltering(initialFilterOptions: FilterOption[], initalActiveFilters: { [key: string]: string }) {
  const defaultFilters = {};
  initialFilterOptions.forEach((option) => {
    defaultFilters[option.key] = initalActiveFilters[option.key] || option.default;
  });

  const [activeFilters, setActiveFilters] = React.useState(defaultFilters);
  const [filterOptions, setFilterOptions] = React.useState(initialFilterOptions);

  const getActiveFilterValue = (key: string) => {
    const filterOption = filterOptions.find((f) => f.key == key);
    const activeFiterValue = key in activeFilters ? activeFilters[key] : filterOption.default;

    return filterOption.options.find((f) => f.id == activeFiterValue)?.name;
  };

  const setActiveFilter = (key: string, value: string) => {
    const filters = { ...activeFilters };
    filters[key] = value;

    setActiveFilters(filters);
  };

  return {
    activeFilters,
    getActiveFilterValue,
    setActiveFilter,
    filterOptions,
    setFilterOptions,
  };
}

type Props = {
  filterOptions: FilterOption[];
  getActiveFilterValue: (optionKey: string) => void;
  setActiveFilter: (optionKey: string, optionId: string) => void
};

export function TableFilters(props: Props) {
  return (
    <div className="editor__pagination__filters">
      <label>Filter By</label>
      {props.filterOptions.map((filterOption) => {
        return (
          <div className="boone-dropdown__label-button" data-cy={filterOption.key} key={filterOption.key}>
            <BooneDropdown
              direction="down"
              right={false}
              menuClassName="boone-dropdown boone-dropdown--nested boone-dropdown__menu boone-dropdown__menu--below"
              toggleIcon="label-arrow-down"
              showOnHover={false}
              Label={() => {
                return (
                  <div className="boone-organization__user__role-select__dropdown__label">
                    <strong>{filterOption.name}:</strong> {props.getActiveFilterValue(filterOption.key)}
                  </div>
                );
              }}
            >
              {filterOption.options.map((option) => {
                const dataKey = filterOption.key + '-' + option.id;
                return (
                  <div
                    data-cy={dataKey}
                    key={dataKey}
                    onClick={() => {
                      props.setActiveFilter(filterOption.key, option.id);
                    }}
                  >
                    {option.name}
                  </div>
                );
              })}
            </BooneDropdown>
          </div>
        );
      })}
    </div>
  );
}

export default TableFilters;
