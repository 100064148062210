import React from "react";
import cn from "classnames";
import AccountFlyOut from "@/users/account-fly-out/component";
import {useStoreContext, StoreProvider} from "../../state";
import Link from "@library/link/view";
import "./styles";

type NavLink = {
  name: string;
  path: string;
  show: boolean;
  internal: boolean;
  active: boolean;
};

type ItemProps = {
  id?: string;
  name: string;
} & NavLink;

function NavItem(props: ItemProps) {
  return props.show ? (
    <Link
      id={props.id}
      internalLink={props.path}
      className={cn({
        "editor__site-nav__item": true,
        "editor__site-nav__item--internal": props.internal,
        "editor__site-nav__item--active": props.active,
      })}
    >
      {props.name}
    </Link>
  ) : null;
}

type Props = {
  internalOrg: boolean;
  navLinks: {
    listings: NavLink;
    listing: NavLink;
    events: NavLink;
    organization: NavLink;
    keys: NavLink;
    imports: NavLink;
    match: NavLink;
    support?: any;
    corrections: NavLink;
  };
  user: any;
  org: any;
};

function NavItems(props: Props) {
  const {analytics} = useStoreContext();
  return (
    <div className="editor__site-nav__tabs">
      <div className="editor__site-nav__items">
        <NavItem {...props.navLinks.listings} />
        <NavItem {...props.navLinks.listing} />
        <NavItem {...props.navLinks.organization} id="org-nav" />
        <NavItem
          {...props.navLinks.support}
          id="support-nav"
          onClick={() => analytics.track("Support", "viewed")}
        />
        <NavItem {...props.navLinks.keys} />
        <NavItem {...props.navLinks.imports} />
        <NavItem {...props.navLinks.match} />
        <NavItem {...props.navLinks.corrections} />
      </div>
    </div>
  );
}

export default class extends React.PureComponent<
  Props & {
    account: {
      id: number;
      avatarUrl?: string;
      name: string;
      editPath: string;
      organizations: any[];
      signOutPath: string;
      role: number;
      currentOrg: {name: string; id: number; source_id: number};
      source_id: number;
      linkedToRoadpass: boolean;
    };
  }
> {
  render() {
    const {account, ...props} = this.props;
    return (
      <StoreProvider user={props.user} org={props.org}>
        <nav className="editor__site-nav">
          <NavItems {...props} />
          <AccountFlyOut {...account} />
        </nav>
      </StoreProvider>
    );
  }
}
