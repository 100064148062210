import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const Pagination = ({
  start,
  end,
  total,
  hasNextPage,
  hasPaginatedResults,
  hasPreviousPage,
  pathToNextPage,
  pathToPreviousPage,
  onPreviousClick,
  onNextClick,
  className
}) =>
  hasPaginatedResults ? (
    <div className={className || 'boone-pagination'}>
      <div className="align-items-center index-pagination px-5 pb-3 row">
        <div className="col">
          <small>{`Viewing ${start} — ${end} of ${total}`}</small>
        </div>
        <div className="col-auto">
          <a
            href={pathToPreviousPage}
            onClick={onPreviousClick ? e => onPreviousClick(e) : null}
            className={`btn btn-white${hasPreviousPage ? '' : ' disabled'} mr-2`}
          >
            Previous
          </a>
          <a
            href={pathToNextPage}
            onClick={onNextClick ? e => onNextClick(e) : null}
            className={`btn btn-white${hasNextPage ? '' : ' disabled'}`}
          >
            Next
          </a>
        </div>
      </div>
    </div>
  ) : null;

Pagination.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasNextPage: PropTypes.bool,
  hasPaginatedResults: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  pathToNextPage: PropTypes.string,
  pathToPreviousPage: PropTypes.string,
  className: PropTypes.string,
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func
};

export default Pagination;
