import React from "react";
import Label from "../label";
import Dropdown from "../dropdown";
import "./styles/index";

function Select(props) {
  const [value, setValue] = React.useState(props.value);
  const opt =
    props.options &&
    props.options.find((opt) => {
      return (opt.id === props.value) || (props.value && opt.id == props.value.public_id);
    });

  const [name, setName] = React.useState((opt && opt["name"]) || "Unknown");

  React.useEffect(() => {
    if (props.value !== value && props.onChange) {
      props.onChange(value);
    }
  }, [value]);

  const includeUnknownOption = (options) => {
    const unknown = {name: 'Unknown', id: null};

    return [...options, unknown];
  }

  return (
    <div className="editor__select">
      <Label
        id={props.labelId}
        text={props.label}
        show={Boolean(props.label)}
        description={props.description}
      />
      <Dropdown
        {...props.dropdown}
        toggleIcon="label-no-icon"
        data-cy="select-element"
        Label={() => {
          return <Label className="editor__input" text={name} />;
        }}
      >
        {[
          ...includeUnknownOption(props.options).map((o) => (
            <div
              key={o.id}
              onClick={() => {
                setValue(o.id);
                setName(o.name);
              }}
            >
              {o.name}
            </div>
          )),
        ]}
      </Dropdown>
    </div>
  );
}

export default Select;
