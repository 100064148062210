import * as React from 'react';
import Sandbox from '../../component-explorer/Sandbox';
import Scenario from '../../component-explorer/Scenario';
import Suggestions from './Container';

export default function Examples(): any {
	return (
		<Sandbox name="Suggestions">
			<Scenario description="no suggestions" code="">
				<Suggestions />
			</Scenario>
			<Scenario description="ignore all suggestions" code="">
				<Suggestions
					{...{ sectionTitle: 'Pending Suggestions', listingId: 59419907, userEditingStrategy: 'update' }}
				/>
			</Scenario>
		</Sandbox>
	);
}
