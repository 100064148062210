import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as c from './constants';
import 'url-search-params-polyfill'; // polyfill for IE & Jest

export const TableHead = ({ id, sortKey, text, className, linkClassName }) => {
  return (
    <div role='th' className={className} id={id} scope="col">
      {sortKey ? <SortLink sortKey={sortKey} className={linkClassName} text={text} /> : text}
    </div>
  );
};

TableHead.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sortKey: PropTypes.string,
  linkClassName: PropTypes.string,
  className: PropTypes.string
};

export default TableHead;

export const SortLink = ({ sortKey, className, text }) => {
  const urlBase = window.location.href.split('?')[0];
  const urlParams = new URLSearchParams(window.location.search);
  const currentSort = getCurrentSort(urlParams, sortKey);
  const nextPath = getNextParams(urlParams, currentSort, sortKey);
  const nextUrl = constructUrl(urlBase, nextPath);

  return (
    <a
      href={nextUrl}
      className={cn({
        [className]: className,
        // set class based on current sort
        'sort-asc': currentSort === c.SORT_ASC,
        'sort-desc': currentSort === c.SORT_DESC,
        'text-nowrap': true
      })}
    >
      {text}
    </a>
  );
};

SortLink.propTypes = {
  sortKey: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string
};

export const getCurrentSort = (urlParams, sortKey) => {
  const urlSortValue = urlParams.get('sort');
  // sort is ascending
  if (urlSortValue === sortKey) {
    return c.SORT_ASC;
  }

  // sort is descending
  if (urlSortValue === '-' + sortKey) {
    return c.SORT_DESC;
  }

  // if sort is not set in the search params path, or sort exists
  // but for a different sort key, we return default
  return c.SORT_DEFAULT;
};

export const getNextParams = (urlParams, currentSort, sortKey) => {
  if (currentSort === c.SORT_DEFAULT) {
    if (urlParams.has('sort')) {
      urlParams.set('sort', sortKey);
    } else {
      urlParams.append('sort', sortKey);
    }
  } else if (currentSort === c.SORT_ASC) {
    urlParams.set('sort', '-' + sortKey);
  } else if (currentSort === c.SORT_DESC) {
    urlParams.delete('sort');
  }
  return urlParams.toString();
};

export const constructUrl = (urlBase, nextSearch) => {
  // append '?' before params if there are any
  return urlBase + (nextSearch.length > 0 ? '?' + nextSearch : '');
};
