import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Place from './place';
import Pagination from '@library/Pagination';
import TableHead from '@library/table/TableHead';
import cn from 'classnames';
import './styles'

export class PlacesTable extends Component {
  render = () => {
    const { pagination, results, tableColumnHeaders } = this.props;
    const hash = window.location.hash || '';
    return (
      <div className="editor__places-table">
          <div role="table" className={cn({
            'editor__table': true,
            "editor__scroll": results.length > 5 })
            }>
            <div role='thead' className="editor__thead">
              <div role="tr" className="editor__place-summary">
                <div></div>
                {tableColumnHeaders.map(({ text, sortKey, id }) => (
                  <TableHead className='editor__place-summary__item' id={id} key={id} sortKey={sortKey} text={text} />
                ))}
              </div>
            </div>
            <div role="tbody" className="editor__tbody">
              {results.map((listing, idx) => {
                return (
                  <Place
                    {...listing}
                    {...this.props.dropdown[listing.id]}
                    canonicalPlaceId={listing.id}
                    key={'listing-search-result-' + listing.id}
                    showMeta={true}
                    internalOrg={this.props.internalOrg}
                    number={idx + this.props.offset}
                    mapOpen={this.props.mapOpen}
                    inFocus={this.props.inFocus}
                    onMouseLeave={() => this.props.handleFocus(null)}
                    onMouseEnter={() => this.props.handleFocus(listing.id)}
                  />
                );
              })}
            </div>
        </div>
        <Pagination
          {...pagination}
          pathToNextPage={this.props.pagination.pathToNextPage + hash}
          pathToPreviousPage={this.props.pagination.pathToPreviousPage + hash}
          className={'editor__pagination'}
        />
      </div>
    );
  };
}

PlacesTable.propTypes = {
  tableColumnHeaders: PropTypes.array,
  results: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  offset: PropTypes.number.isRequired,
  mapOpen: PropTypes.bool,
  internalOrg: PropTypes.bool.isRequired,
  inFocus: PropTypes.number,
  dropdown: PropTypes.object.isRequired
};

export default PlacesTable;
