import React from 'react';
import ViewField from '../view-field';

function Price({ value,...props }) {
  const free = value === 0;

  const darkDollarSigns = free ? 'Free' : value ? Array(value + 1).join('$') : '' ;
  const mutedDollarSigns = (free || !value) ? '' : Array(5 - value).join('$');

  return (
    <ViewField
      {...props}
      cy='price'
      label={'Price'}
      value={
        value || free ? (
          <div className="editor__price">
            <span>{darkDollarSigns}</span>
            <span className="muted-dollar-signs text-muted">{mutedDollarSigns}</span>
          </div>
        ) : value
      }
    >
    </ViewField>
  );
}

export default Price;
