import React from 'react';
import PropTypes from 'prop-types';
import IconBooneAvatar from '../icons/IconBooneAvatar';

export const BooneLogoAvatar = ({ className = 'round-avatar' }) => (
  <div className={className}>
    <IconBooneAvatar />
  </div>
);

BooneLogoAvatar.propTypes = {
  className: PropTypes.string
};

export const Avatar = ({ url, className = 'round-avatar', placeholderClassName, booneLogo }) => {
  if (booneLogo) {
    return <BooneLogoAvatar className={placeholderClassName} />;
  } else {
    return <div className={className} style={{ backgroundImage: url ? `url(${url})` : '' }} />;
  }
};

Avatar.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  placeholderClassName: PropTypes.string,
  booneLogo: PropTypes.bool
};

export default Avatar;
