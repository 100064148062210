import React from 'react';
import resendCallBack from './callback';

function Redirect() {
  const f = ['code', 'id_token', 'nonce', 'session_state', 'state'];

  React.useEffect(() => {
    resendCallBack();
  }, []);

  return (
    <>
      <p>Signing in with Roadpass...</p>
    </>
  );
}

export default Redirect;
