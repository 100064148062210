import * as React from 'react';
import Sandbox from '../../component-explorer/Sandbox';
import Scenario from '../../component-explorer/Scenario';
import InputGroupEditor from './InputGroupEditor';
import { InputWithLabel } from '../input-with-label/index';

export default function Examples(): any {
  return (
    <Sandbox name="Group Editor">
      <Scenario description="InputGroupEditor" code="">
        <div className="container">
          <div className="row">
            <div className="col-2" style={{ border: '1px dashed grey' }} />
            <div className="col-8" style={{ border: '1px dashed black' }}>
              <InputWithLabel error={false} label="Input With Label" value="" onChange={() => { }} />
              <InputGroupEditor values={[]} getChanges={() => { }} label="Input Group Editor" allowNone={false} />
            </div>
          </div>
        </div>
      </Scenario>
    </Sandbox>
  );
}
