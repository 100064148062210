
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import FormV1 from './connector';
import useSteps from './useSteps';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Roadtrippers Corrections`}</h3>

    <h4>{`MVP`}</h4>
    <FormV1 location={{
      address1: '234 Way'
    }} name="Place" contact_info={{
      website: "https://yo.com"
    }} mdxType="FormV1" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;