import React from "react";
import {InputWithLabel as Input} from "@/library/input-with-label";
import BooleanRow from "@library/boolean-row/edit";
import Subsection from "@/library/wizard/components/subsection";

type Props<State> = {
  title?: string;
  fields: string[][];
  show?: boolean;
  label?: string;
  state: State;
  updateField: any;
};

function ContactSection<State>({
  title,
  fields,
  label,
  state,
  updateField,
}: Props<State>) {
  const [expand, setExpand] = React.useState(false);

  return (
    <Subsection>
      {label && (
        <BooleanRow
          binary
          label={<h4>{label}</h4>}
          value={expand}
          onChange={setExpand}
        />
      )}

      {title && !label && <h4>{title}</h4>}

      {((label && expand) || !label) &&
        fields.map(([label, field, type, cn]) => (
          <div key={field} className={"editor__form-field editor__" + cn}>
            <Input
              type={type}
              label={label}
              value={state[field]}
              onChange={(event) => updateField(event, field)}
            />
          </div>
        ))}
    </Subsection>
  );
}

export default ContactSection;
