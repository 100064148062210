import React, { PureComponent } from 'react';

export class Icon extends PureComponent {
  static defaultProps = {
    fillColor: '#000',
    width: '40',
    height: '40',
    viewBoxX: '0',
    viewBoxY: '0',
    className: 'single-path-icon',
    title: '',
    path: 'M150 0 L75 200 L225 200 Z',
    transform: null
  };

  render() {
    const { className, title, height, width, viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight, transform } = this.props;
    const viewBox =
      this.props.viewBox || [ viewBoxX, viewBoxY, viewBoxWidth || width, viewBoxHeight || height ].join(' ');
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        width={width}
        height={height}
        aria-labelledby="title"
        transform={transform}
      >
        <title id="title">{title}</title>
        {this.props.children}
      </svg>
    );
  }
}
