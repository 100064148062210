import React from 'react';

export default function Suggestion(props) {
  return (
    <div className="__row" onClick={props.onClick}>
      <div className="btn item __canonical_place_id" data-id={props.id}>Canonical Place ID: {props.id}</div>
      <div className="btn item __canonical_place_name" data-id={props.id}>{props.name}</div>
      <div className="item __canonical_place_location"><span>{`${props.address}`}</span></div>
    </div>
  )
}