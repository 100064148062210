import * as React from 'react';
import GroupEditor from './index';
import InputWithLabel from '../input-with-label';

type Props = {
  getChanges: (group?: string[]) => void;
  values: string[];
  label: string;
  // data-cy for the div that wraps the provided editor component
  cy?: string;
  allowNone: boolean;
  labelClassName?: string;
  id?: string;
  description?: string;
  onChange?: (group?: string[]) => void;
};

const InputGroupEditor = (props: Props) => (
  <GroupEditor
    allowNone={props.allowNone}
    cy={props.cy}
    emptyValue=""
    getChanges={v => {
      if (props.getChanges) props.getChanges(v);
      if (props.onChange) props.onChange(v)
    }}
    values={props.values}
    rmContentDiv
  >
    {({ value, setValue }) => (
      <InputWithLabel
        label={props.label}
        value={value}
        description={props.description}
        labelId={props.id}
        labelClassName={props.labelClassName}
        onChange={e => setValue(e.target.value)}
      />
    )}
  </GroupEditor>
);

export default InputGroupEditor;
