
import React from 'react';

function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  // Remember the latest function.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
 React.useEffect(() => {
   function tick() {
     if (savedCallback && savedCallback.current) {
       // @ts-ignore
       savedCallback.current();
     }
   }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
