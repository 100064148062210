import React from "react";
import Link from "@library/link/view";
import Label from "@library/label";
import cn from "classnames";

type Props = {
  values?: any[];
  link: boolean;
  label: string;
  className?: string;
  labelId?: string;
  description?: string;
  hideNull?: boolean;
};
export default function ViewList(props: Props) {
  // Filter out empty strings
  const values = props.values && props.values.filter((v) => v);

  return props.hideNull &&
    (props.values === undefined ||
      props.values === null ||
      props.values.length === 0) ? null : (
    <div data-cy="view-list" className={cn(["editor__list", props.className])}>
      <Label
        missingData={!values || values.length < 1}
        id={props.labelId}
        text={props.label}
        description={props.description}
      />
      {values && (
        <div className="editor__list__items">
          {values.map((m) => {
            return (
              <React.Fragment key={m}>
                {props.link ? (
                  <Link externalLink={m}>{m}</Link>
                ) : (
                  <div className="editor__list__item">{m}</div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
}

ViewList.defaultProps = {
  link: true,
};
