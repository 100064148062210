import React from 'react';
import { ConnectDropTarget, DropTargetMonitor } from 'react-dnd';
import { DropTarget, DropTargetConnector } from 'react-dnd';

type TargetBoxProps = {
  accepts: string[];
  onDrop: (props: TargetBoxProps, monitor: DropTargetMonitor) => void;
  isOver: boolean;
  canDrop: boolean;
  connectDropTarget: ConnectDropTarget;
  children?: any;
};

const TargetBox = ({ canDrop, isOver, connectDropTarget, children }: TargetBoxProps) => {
  return connectDropTarget(children({ isOver, canDrop }));
};

export default DropTarget(
  (props: TargetBoxProps) => props.accepts,
  {
    drop(props: TargetBoxProps, monitor: DropTargetMonitor) {
      if (props.onDrop) {
        props.onDrop(props, monitor);
      }
    }
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(TargetBox);
