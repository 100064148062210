import React from 'react';
import SearchTree from '../search-tree';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import './styles';
import '@library/tree-select/search-tree/styles/index';
import cn from 'classnames';

type Props = {
  selection?: string[];
  clear?: () => void;
  cancel?: () => void;
  label: string;
  save: (public_ids: string[]) => void;
  cy?: string;
  index: any;
  // When not selected, child will reflect parent state
  mirrorParent: boolean;
  mirrorChild?: boolean;
  // Shows table of selected items under controls
  showSelection: boolean;
};

function TreeSelectDropdown({ index, ...props }: Props){
  const [show, setShow] = React.useState(false);

  const cancel = () => {
    setShow(false);
  };

  const clear = () => {
    if (props.clear) props.clear();
  };

  const save = (public_ids: string[]): void => {
    setShow(false);
    props.save(public_ids);
  };

  return (
    <Dropdown
      className={cn({
        'editor__tree-select__dropdown': true,
        'above-scrim': show
      })}
      data-cy={props.cy}
      isOpen={show}
      toggle={() => setShow((prevState) => !prevState)}
    >
      <DropdownToggle
        tag="button"
        className={
          'editor__tree-select__dropdown__button ' +
          (show || props.selection.length > 0
            ? 'editor__button editor__button--primary above-scrim'
            : 'editor__button editor__button--white')
        }
      >
        {props.selection.length < 1 && props.label}
        {props.selection.length === 1 && index.getNode(props.selection[0]).name}
        {props.selection.length > 1 &&
          props.label + ' • ' + props.selection.length}
      </DropdownToggle>
      <DropdownMenu className="editor__tree-select__dropdown__menu above-scrim">
        <SearchTree
          index={index}
          selection={props.selection}
          cancel={cancel}
          clear={clear}
          save={save}
          mirrorChild={props.mirrorChild}
          mirrorParent={props.mirrorParent}
          showSelection={props.showSelection}
        />
      </DropdownMenu>
    </Dropdown>
  );
}

export default TreeSelectDropdown;
