import React from "react";
import Button from "@library/_button";
import YelpLogo from "./components/yelp-logo"
import { RoadtrippersLogo } from "./components/rt-logo";
import GoogleLogo from "./components/google-logo";
import ReactTooltip from "react-tooltip";
import useLink from "@library/link/useLink";
import ReactDOM from "react-dom";
import * as ajax from "@/helpers/ajax";
import "./styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AuditForm from "./components/audit-form";

type Props = {
  name: string;
  google: { path: string };
  categories: Category[];
  image: string;
  match: { path: string; permitted: boolean };
  rawJson: { path: string; permitted: boolean };
  id: string;
  setActive: (active: string) => void;
  guarded: boolean;
  canonicalPlace: { branded_attribution: string; branded_attribution_url: string};
  basic: {
    audit: {
      id: number;
      avatar_url: string;
      user_id: number;
      user_name: string;
    }
  };
};

export function Header(props: Props) {
  const yelpLink = useLink({ externalLink: props.canonicalPlace.branded_attribution_url ?? '#'});
  const googleLink = useLink({ externalLink: props.google.path });
  const matchLink = useLink({ externalLink: props.match.path });
  const rawJsonLink = useLink({ externalLink: props.rawJson.path });

  const rtLink = useLink({
    externalLink: `https://maps.roadtrippers.com/canonical_places/${props.id}`,
  });

  const [suggestions, setSuggestions] = React.useState(0);
  const [hasYelpAttribution, setHasYelpAttribution] = React.useState(false);

  const onClick = () => {
    const text = "suggestions";
    props.setActive(text);
    window.location.hash = text;
  };

  const suggestionsLink = useLink({
    onClick,
    internalLink: "",
  });

  async function loadSuggestions() {
    const [promise] = ajax._get(document.location.pathname + "/suggestions");
    const res = await promise;
    if (res) {
      const json = await res;
      setSuggestions(
        json
          .filter((s) => s.state === "pending" && s.details)
          .reduce((p) => {
            p = p + 1;
            return p;
          }, 0)
      );
    }
  }

  function checkBrandedAttribution(canonicalPlace, brand) {
    if (!canonicalPlace.branded_attribution) {
      setHasYelpAttribution(false);
      return false;
    }

    let brandedAttribution = canonicalPlace.branded_attribution;

    if (brandedAttribution.toLowerCase() == brand.toLowerCase()) {
      setHasYelpAttribution(true);
      return true;
    }
  }

  React.useEffect(() => {
    loadSuggestions();
    checkBrandedAttribution(props.canonicalPlace, 'yelp')
  }, []);

  const remainingCategoriesList = (startIndex) => {
    return props.categories.slice(startIndex).map((category) => (category.name)).join(', ')
  };

  return (
    <div
      data-cy="listing-header"
      className="editor__dashboard__header"
      id="editor-dashboard-header"
      style={{
        background: `linear-gradient(transparent 10%, rgba(0,0,0,1)), url("${props.image}")`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="editor__dashboard__header--right">
        <div className="editor__dashboard__header--right--top">
          <div className="editor__dashboard__header__name">
            <h2>
              {props.guarded && (
                <span
                  data-tip="Guarded"
                  data-for="guarded-icon"
                  className="editor__dashboard__header__icon"
                >
                  <ReactTooltip
                    data-cy="guarded-icon"
                    offset={{top: -4, left: 0}}
                    type="light"
                    id={"guarded-icon"}
                    effect="solid"
                  />
                  <FontAwesomeIcon icon={"shield-alt"} />
                </span>
              )}
              {props.name}
            </h2>
            {suggestions > 0 ? (
              <Button
                {...suggestionsLink}
                cy="suggestions-pending"
                className="editor__dashboard__pending"
              >
                {suggestions}
                <span>suggestions</span>
              </Button>
            ) : null}
          </div>

          <div className="editor__dashboard__header--right--bottom">
            <div
              data-tip="Categories"
              data-for="categories"
              className="editor__dashboard__header__categories"
            >
              {props.categories &&
                props.categories.map((c, i) =>
                  i < 3 ? (
                    <div
                      className="editor__dashboard__header__category"
                      key={c.public_id}
                    >
                      {c.name}
                    </div>
                  ) : i === 3 ? (
                        props.categories.length > 4 ? (
                          <div
                            className="editor__dashboard__header__category--truncate"
                            data-tip={remainingCategoriesList(3)}
                            data-for="categories-list"
                          >
                            <ReactTooltip
                              className="editor__dashboard__header__category--tooltip"
                              data-cy="rt-tooltip"
                              place="bottom"
                              type="info"
                              id={"categories-list"}
                            />
                            + {props.categories.length - 3} more
                          </div>
                        ) : (
                          <div
                            className="editor__dashboard__header__category"
                            key={c.public_id}
                          >
                            {c.name}
                          </div>
                        )
                  ) : null
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="editor__dashboard__header--left">
        <div className="editor__dashboard__header__external-links">
          {hasYelpAttribution && (
            <div
              data-tip="See it on Yelp"
              data-for="yelp-link"
              className="editor__dashboard__header__external-link"
            >
              <ReactTooltip
                data-cy="yelp-tooltip"
                offset={{top: -15, left: -10}}
                type="light"
                id={"yelp-link"}
                effect="solid"
              />
              <div {...yelpLink}>
                <YelpLogo fill="white" width={70} height={20} />
              </div>
            </div>
          )}
          <div
            data-tip="See it on Roadtrippers"
            data-for="rt-link"
            className="editor__dashboard__header__external-link"
          >
            <ReactTooltip
              data-cy="rt-tooltip"
              offset={{top: -15, left: -35}}
              type="light"
              id={"rt-link"}
              effect="solid"
            />
            <div {...rtLink}>
              <RoadtrippersLogo />
            </div>
          </div>
          <div
            data-tip="Google this place"
            data-for="g-link"
            className="editor__dashboard__header__external-link"
          >
            <ReactTooltip
              data-cy="g-tooltip"
              offset={{top: -20, left: -25}}
              type="light"
              id={"g-link"}
              effect="solid"
            />
            <div {...googleLink}>
              <GoogleLogo />
            </div>
          </div>
        </div>

        {props.rawJson.permitted && (
          <Button {...rawJsonLink} className="editor__button--white">
            json
          </Button>
        )}
        {props.match.permitted && (
          <Button {...matchLink} className="editor__button--white">
            match
          </Button>
        )}
        <AuditForm canonicalPlaceId={props.id} audit={props.basic.audit} />
      </div>
    </div>
  );
}

export default (props) => {
  return <Header {...props} />
  // const el = document.getElementById("editor-header");
  // if (!el) {
  //   console.error("mount element not found for header portal");
  //   return null;
  // }
  // return ReactDOM.createPortal(
  //   <Header {...props} />,
  //   document.getElementById("editor-header")
  // );
};
