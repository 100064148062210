import React from "react";
import Input from "@library/input-with-label";

export const listingPayload = (state) => ({
  listing: state,
});

export const correctionPayload = (
  meta,
  updates
) => ({
  correction: meta.issue_type === "correction" ? {...meta, updates} : meta,
});

export const stateUrlEncode = (payload) =>
  new URLSearchParams(payload).toString();

export function mapFieldsToInput(fields, state, updateField) {
  return fields.map(([label, field, cn]) => (
    <div key={field} className={"editor__form-field editor__" + cn}>
      <Input
        label={label}
        value={state[field]}
        onChange={(event) => updateField(event, field)}
      />
    </div>
  ));
}
