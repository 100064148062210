type Mods = {[mod: string]: string | boolean};

function bem(block: string) {
  return function (elOrMods?: string | Mods, mods?: Mods) {
    if (!elOrMods) return block;

    const elSet = typeof elOrMods === "string";
    let base = elSet ? block + "__" + elOrMods : block;

    let mod = elSet ? mods : elOrMods;
    if (mod) {
      return (
        base +
        Object.keys(mod).reduce(function (result, name) {
          if (mod[name]) {
            result +=
              " " +
              (typeof mod[name] === "boolean"
                ? base + "--" + name
                : base + "--" + name + "_" + mod[name]);
          }
          return result;
        }, "")
      );
    }

    return base;
  };
}

export default bem;
