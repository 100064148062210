import * as React from 'react';
import Sandbox from '../../component-explorer/Sandbox';
import Scenario from '../../component-explorer/Scenario';
import Select from './index';

export default function Examples(): any {
  return (
    <Sandbox name="Select">
      <Scenario description="Default usage" code="">
        <Select
          name="chain-type"
          label="Chain type"
          value="independent"
          options={[
            { label: 'Independent', value: 'independent' },
            { label: 'Local chain', value: 'local-chain' },
            { label: 'Chain', value: 'chain' }
          ]}
        />
      </Scenario>
    </Sandbox>
  );
}
