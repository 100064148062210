import { stateUrlEncode, listingPayload } from '@/data-outreach/_core/forms/util';

/**     ___________
 *     [__   Types |
 *    __/_| [] []  |
 *   [_,________,__|
 *     "        "
 **/
type Attribute = {
  id: string;
  data?: string[];
  options?: { id: string }[];
};

type Props = {
  gsheet_hash?: string;
  first_name?: string;
  last_name?: string;
  name: string;
  email?: string;
  phone?: string;
  canonical_place_id: string;
  contact_id?: string;
  categories?: { public_id: string }[];
  contact_info?: {
    email?: string;
    phone?: string;
    website?: string;
  };
  location: {
    address1?: string;
    address2?: string;
    postal_code?: string;
    city?: string;
    state?: string;
  };
  rv_services?: {
    attributes?: Attribute[];
    data?: {
      mobile_service_radius?: number;
      available_services?: string[];
    };
  };
};

type ServiceOptions = {
  [key: string]: { [id: string]: { value: boolean } };
};

type ServiceCenter = {
  service_email?: string;
  service_phone?: string;
  service_website?: string;
  service_address1?: string;
  service_address2?: string;
  service_postal_code?: string;
  service_city?: string;
  service_state?: string;
  service_available_services?: ServiceOptions;
};

type InternalContact = {
  internal_first_name?: string;
  internal_last_name?: string;
  internal_email?: string;
  internal_phone?: string;
  internal_job_title?: string;
};

type MobileService = {
  mobile_city?: string;
  mobile_email?: string;
  mobile_phone?: string;
  mobile_radius?: number;
  mobile_state?: string;
  mobile_available_services?: ServiceOptions;
};

type ServiceAreas = {
  sales?: boolean;
  service_center?: boolean;
  mobile_service?: boolean;
  storage?: boolean;
  parts_sales?: boolean;
  rental?: boolean;
};

type State = {
  canonical_place_id: string;
  contact_id?: string;
  name?: string;
  storage_phone?: string;
  storage_email?: string;
  rental_phone?: string;
  rental_email?: string;
  sales_phone?: string;
  sales_email?: string;
} & ServiceCenter &
  InternalContact &
  MobileService &
  ServiceAreas;

/**
 * Maps: state key <-> public_id
 **/

const stateKeys = ['sales', 'service_center', 'mobile_service', 'storage', 'parts_sales', 'rental'] as string[];

const statePubIdMap = {
  mobile_service: 'mobile-rv-repair-service',
  service_center: 'rv-service-center',
  rental: 'rv-rental-place',
  parts_sales: 'rv-parts-accessories-store',
  sales: 'rv-sales-center',
  storage: 'rv-storage-facility',
} as { [stateKey: string]: string };

const pubIdStateMap = Object.keys(statePubIdMap).reduce((p, c) => {
  p[statePubIdMap[c]] = c;
  return p;
}, {}) as { [pubIdKey: string]: string };

/**
 * Functions
 */
const getOptions = (props: Props): Attribute => {
  return props.rv_services.attributes.find((attr) => attr.id === 'available_services');
};

const serviceOptions = (props: Props, mobile: boolean): ServiceOptions => {
  const avs = getOptions(props);
  avs.data = props.rv_services.data['available_services'];

  return {
    ...avs.options.reduce((p, c) => {
      if ((mobile && c.id.startsWith('mobile-')) || (!mobile && !c.id.startsWith('mobile-'))) {
        p[c.id] = {
          ...c,
          value: avs.data ? !!avs.data.find((attr) => attr['public_id'] == c.id) : false,
        };
      }
      return p;
    }, {}),
  };
};

const getServices = (services: ServiceOptions): string[] => {
  return [...Object.keys(services).filter((k) => services[k]['value'])];
};

const setRVServiceState = (categories?: { public_id: string }[]): ServiceAreas => {
  const serviceAreas = {
    sales: false,
    service_center: false,
    mobile_service: false,
    storage: false,
    parts_sales: false,
    rental: false,
  };
  if (categories && categories.length > 0) {
    return categories
      .map((c) => c.public_id)
      .reduce((p, c) => {
        const sk = pubIdStateMap[c];
        p[sk] = true;
        return p;
      }, serviceAreas);
  }
  return serviceAreas;
};

export default (props) => ({
  initialState: {
    canonical_place_id: props.canonical_place_id,
    contact_id: props.contact_id,
    // business
    name: props.name,

    // service areas
    ...setRVServiceState(props.categories),

    // service
    service_email: props.contact_info.email,
    service_phone: props.contact_info.phone,
    service_website: props.contact_info.website,
    service_address1: props.location.address1,
    service_address2: props.location.address2,
    service_postal_code: props.location.postal_code,
    service_city: props.location.city,
    service_state: props.location.state,
    service_available_services: serviceOptions(props, false),

    // internal
    internal_first_name: props.first_name,
    internal_last_name: props.last_name,
    internal_email: props.email,
    internal_phone: props.phone,
    internal_job_title: '',

    // mobile
    mobile_city: '',
    mobile_email: '',
    mobile_phone: '',
    mobile_radius: props.rv_services.data.mobile_service_radius,
    mobile_state: '',
    mobile_available_services: serviceOptions(props, true),

    // storage
    storage_phone: '',
    storage_email: '',

    // rental
    rental_phone: '',
    rental_email: '',

    // sales
    sales_phone: '',
    sales_email: '',

    // services
    services: getOptions(props).options.filter((service) => !service['id'].startsWith('mobile-')), // The mobile values will be assumed to be the regular services prepended with `mobile-`
    notes: '',
  },
  gsheet_hash: props.gsheet_hash,
  atlas: {
    method: '_put',
    url: document.location.pathname,
    payloadFormatter: (state) => {
      return listingPayload({
        name: state.name,
        // convert service area booleans to an array of public ids to store categories
        categories: stateKeys.filter((k) => state[k]).map((k) => statePubIdMap[k]),
        locations: [
          {
            address1: state.service_address1,
            address2: state.service_address2,
            city: state.service_city,
            state: state.service_state,
            postal_code: state.service_postal_code,
          },
        ],
        contact_info: {
          email: state.service_email,
          phone: state.service_phone,
          website: state.service_website,
        },
        rv_services: {
          mobile_service_radius: state.mobile_radius,
          available_services: getServices(state.service_available_services).concat(
            getServices(state.mobile_available_services)
          ),
          other_available_services: state.notes.slice(0, 200),
        },
      });
    },
  },
  gsheet: {
    payloadFormatter: (state) => {
      return stateUrlEncode({
        ...state,
        mobile_available_services: getServices(state.mobile_available_services),
        service_available_services: getServices(state.service_available_services),
        spreadsheet: 'rv-data-outreach',
        sheet: 'v1',
      });
    },
  },
});
