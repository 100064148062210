import React from "react";
import Input from "./Input";
import type {Props as InputProps} from "./Input";
import cn from "classnames";

type Props = {
  active?: boolean;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  pills: {text: string; id: string | number}[];
  removePill: (id: string | number) => void;
} & InputProps;

const InputWithPills = React.forwardRef(
  (
    {
      active,
      className,
      cy,
      onBlur,
      onChange,
      onClick,
      onKeyDown,
      pills = [],
      placeholder,
      removePill,
      tabIndex,
      type,
      value,
    }: Props,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const inputProps = {
      onBlur,
      onChange,
      onKeyDown,
      placeholder,
      tabIndex,
      type,
      value,
      disabled: !active
    };

    const internalRemovePill = (id) => {
      if (active) {
        removePill(id);
      }
    }

    return (
      <div className={cn(["boone-form__field", className])}>
        <div
          className={cn({
            "boone-form__field__input": true,
            "boone-form__field__input--active": active,
            "boone-form__field__input__with-addon": true,
          })}
          onClick={onClick}
        >
          {pills.map(({id, text}) => (
            <div
              key={id + text + "-pill"}
              data-cy="pill"
              onClick={() => internalRemovePill(id)}
              className={
                cn({
                  "boone-form__pill": true,
                  "boone-form__pill--disabled": !active
                })
              }
            >
              <span className="boone-form__pill__text">{text}</span>
              <span key={text} className="boone-form__pill__x">
                x
              </span>
            </div>
          ))}
          <Input cy={cy} {...inputProps} forwardedRef={ref} />
        </div>
      </div>
    );
  }
);

InputWithPills.displayName = "InputWithPills";

export default InputWithPills;
