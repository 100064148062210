import React, { useState, useEffect } from 'react';
import * as ajax from '@/helpers/ajax';
import IconHide from '../../../icons/IconHide';
import { WhiteButton } from '../../../library/Buttons';
import IgnoreAllSuggestionsModal from '../IgnoreAllModal';
import * as hooks from '../../../library/launch-darkly/hooks';
import { useStoreContext } from '../../../state';
import map from 'lodash/map';
import each from 'lodash/each';
import some from 'lodash/some';

import '../styles/index';

import SuggestionListItem from './suggestion-list-item'
import SuggestionDetails from './suggestion-details'

type Props = {
  userEditingStrategy: UserEditingStrategy;
  listingId: number;
  sectionTitle: string;
  showCategories: boolean;
  analytics?: any;
};

type SuggestionItem = {
  id: number;
  state: string;
}

const SuggestionsList = (props: Props) => {
  const [suggestions, setSuggestions] = React.useState([]);
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  const filterSuggestions = suggestion => {
    if (suggestion.details) {
      return props.showCategories || !suggestion.details.find(detail => detail.field === 'categories');
    } else {
      return false;
    }
  };

  useEffect( () => {
    ajax
      .get(`/listings/${props.listingId}/suggestions`)
      .then(response => response.json())
      .then(data => setSuggestions(data.filter(filterSuggestions)));
  }, [])

  const showDetailsModal = id => {
    alert(id);
    return;
  };

  const showIgnoreAllModal = () => {
    $(`#ignore-all-suggestions`).modal('show');
  };

  const rejectAll = () => {
    // Do nothing if we are already updating a suggestion
    if (requestInProgress) {
      return null;
    }

    $(`#ignore-all-suggestions`).modal('hide');
    each(suggestions, sug => {
      if (sug.state === 'pending') {
        updateSuggestionImpl(sug.id, 'rejected');
      }
    });

    props.analytics.track('All Pending Suggestions', 'ignored');
  };

  const updateSuggestion = (id: number, newState: string) => {
    // Do nothing if we are already updating a suggestion
    if (requestInProgress) {
      return null;
    }

    updateSuggestionImpl(id, newState);
  };

  const updateSuggestionImpl = (id, newState) => {
    setRequestInProgress(true)

    ajax.put(`/listings/${props.listingId}/suggestions/${id}`, { state: newState }).then(response => {
      if (response.ok) {
        let suggestion = suggestions.find(sug => sug.id === id);
        suggestion.state = newState;
        setSuggestions(suggestions);
      }
      setRequestInProgress(false)
    });
  }

  if (suggestions.length === 0) {
    return (
      <React.Fragment>
        <h4 className="mt-5">{props.sectionTitle}</h4>
        <div className="well p-3">
          <div className="text-muted">None</div>
        </div>
      </React.Fragment>
    );
  }

  const suggestionsList = map(suggestions, sug => (
    <SuggestionListItem
      key={sug.id}
      userEditingStrategy={props.userEditingStrategy}
      disabled={requestInProgress || props.userEditingStrategy !== 'update'}
      onUpdate= {updateSuggestion}
      onClick={e => showDetailsModal(sug.id)}
      analytics={props.analytics}
      {...sug}
    />
  ));

  const showIgnoreAll = props.userEditingStrategy === 'update' && some(suggestions, sug => sug.state === 'pending')

  return (
    <div>
      <div className="editor__listing__suggestions__header">
        <h4 className="mt-5">{props.sectionTitle}</h4>
        { showIgnoreAll && (
          <>
            <WhiteButton
              className="editor__listing__suggestions__ignore-button"
              ariaLabel="Ignore suggestion"
              onClick={showIgnoreAllModal}
              disabled={requestInProgress}
            >
              <IconHide viewBoxX="-6" viewBoxY="-5" />
              Ignore All Pending
            </WhiteButton>
            <IgnoreAllSuggestionsModal modalId={'ignore-all-suggestions'} onRejectAll={rejectAll} />
          </>
        )}
      </div>
      <div className="boone-listing__suggestion-list">
        {suggestionsList}
      </div>
    </div>
  );
}

SuggestionsList.displayName = "SuggestionsList";
export default SuggestionsList;
