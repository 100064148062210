function formatSelectOptions(ids) {
  return ids.reduce((prev, curr) => {
    prev.push({ id: curr, name: curr.split('-').join(' ') });
    return prev;
  },[])
}

export const CONFIRMATION_METHODS = ["in-person", "by-phone", "did-not-confirm"];
export const CONF_METHODS_OPTIONS = formatSelectOptions(CONFIRMATION_METHODS);

export const CONFIRMATION_SOURCES = [
  "associate",
  "customer-service",
  "fuel-desk",
  "manager",
  "local-authority",
  "other",
];
export const CONF_SOURCES_OPTIONS = formatSelectOptions(CONFIRMATION_SOURCES)

export const RESTRICTIONS = [
  "store-policy",
  "local-ordinance",
  "size-of-parking-lot",
  "safety-issues",
  "other",
];
export const RESTRICTIONS_OPTIONS = formatSelectOptions(RESTRICTIONS);
