import * as React from 'react';
import Sandbox from '../../component-explorer/Sandbox';
import Scenario from '../../component-explorer/Scenario';
import { InputWithLabel } from './index';

export default function Examples(): any{
  return (
    <Sandbox name="Input With Label">
      <Scenario description="Default usage" code="">
        <div className="container">
          <div className="row">
            <div className="col-2" style={{ border: '1px dashed grey' }} />
            <div className="col-8" style={{ border: '1px dashed black' }}>
              <InputWithLabel
                error={false}
                label="Chain type"
                value="independent"
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
      </Scenario>
    </Sandbox>
  );
}
