import React from 'react';
import clone from '../../helpers/DeepClone';
import uniq from 'lodash/uniq';
import formatSourceName from '../../helpers/formatSourceName';
import Editorial from './Editorial';

const groupBySource = (arr) => {
  return arr.reduce(
    (p, c) => {
      p.sourceIds.push(c.source_id);
      p.sourceIds = uniq(p.sourceIds);
      if (!p.sourceNames[c.source_id]) p.sourceNames[c.source_id] = c.source_name;
      if (!p.bySourceId[c.source_id]) p.bySourceId[c.source_id] = [];
      p.bySourceId[c.source_id].push(c.place_id);
      p.bySourceId[c.source_id] = uniq(p.bySourceId[c.source_id]);
      if (!p.editorials[c.place_id])
        p.editorials[c.place_id] = { description: '', subtitle: '', [c.editorial_type]: c };
      if (!p.editorials[c.place_id][c.editorial_type]) p.editorials[c.place_id][c.editorial_type] = c;
      return p;
    },
    { bySourceId: {}, sourceIds: [], sourceNames: {}, editorials: {} }
  );
};

const groupByEditorialItem = (arr) => {
  return arr.reduce((p, c) => {
    const editorialItemId = `${c.editorial_item_type}-${c.editorial_item_id}`;
    if (!p[editorialItemId]) {
      p[editorialItemId] = { description: {}, subtitle: {}, ...c };
    }

    p[editorialItemId][c.editorial_type] = c;
    return p;
  }, {});
};

const setEditorialType = (arr, type) =>
  arr.map((v) => {
    v.editorial_type = type;
    return v;
  });

type EditorialItem = {
  text: string;
  editorial_item_id: number;
  editorial_item_name: string;
  editorial_item_type: string;
};

type Props = {
  descriptions: { text: string; source_id: number }[];
  subtitles: { text: string; source_id: number }[];
  read_only_descriptions: EditorialItem[];
  read_only_subtitles: EditorialItem[];
  authority_source_name: string;
  authority_source_id: number;
  current_org_source_id: number;
  owner: boolean;
  userEditingStrategy: 'update' | 'suggest' | 'none';
  internalUser: boolean;
};

export default function ListingEditorial(props: Props) {
  const [editorials, setEditorials] = React.useState({});
  const [edits, setEdits] = React.useState({});
  const [sourceIds, setSourceIds] = React.useState([]);
  const [bySourceId, setBySourceId] = React.useState({});
  const [sourceNames, setSourceNames] = React.useState({});

  const read_only_descriptions = setEditorialType(clone(props.read_only_descriptions), 'description');
  const read_only_subtitles = setEditorialType(clone(props.read_only_subtitles), 'subtitle');
  const readonlyEditorials = groupByEditorialItem([...read_only_descriptions, ...read_only_subtitles]);

  React.useEffect(() => {
    const descriptions = setEditorialType(clone(props.descriptions), 'description');
    const subtitles = setEditorialType(clone(props.subtitles), 'subtitle');
    const t = groupBySource([...descriptions, ...subtitles]);

    // Set placeholders or move editorials to the correct position
    // Current Org Source

    const currentOrgIsAuthority = props.authority_source_id === props.current_org_source_id;

    const authorityIdIndex = t.sourceIds.indexOf(props.authority_source_id);
    const currentOrgIdIndex = t.sourceIds.indexOf(props.current_org_source_id);

    const noEditorialFromAuthority = authorityIdIndex === -1;
    const noEditorialFromOrg = currentOrgIdIndex === -1;

    if (noEditorialFromAuthority && noEditorialFromOrg) {
      t.sourceNames[props.current_org_source_id] = 'You';
      t.sourceIds.unshift(props.current_org_source_id);
      t.bySourceId[props.current_org_source_id] = ['authority_owner'];
      t.editorials['authority_owner'] = { description: { text: '' }, subtitle: { text: '' } };
    } else {
      // Authority Source
      if (noEditorialFromAuthority) {
        t.sourceNames[props.authority_source_id] = props.authority_source_name;
        t.sourceIds.unshift(props.authority_source_id);
        t.bySourceId[props.authority_source_id] = ['authority'];
        t.editorials['authority'] = { description: { text: '' }, subtitle: { text: '' } };
      } else if (currentOrgIdIndex !== 0) {
        t.sourceIds.splice(authorityIdIndex, 1);
        t.sourceIds.unshift(props.authority_source_id);
      }

      if (noEditorialFromOrg) {
        t.sourceNames[props.current_org_source_id] = 'You';
        t.sourceIds.splice(1, 0, props.current_org_source_id);
        t.bySourceId[props.current_org_source_id] = ['owner'];
        t.editorials['owner'] = { description: { text: '' }, subtitle: { text: '' } };
      } else if (!currentOrgIsAuthority && currentOrgIdIndex !== 1) {
        t.sourceIds.splice(currentOrgIdIndex, 1);
        t.sourceIds.splice(1, 0, props.current_org_source_id);
      }
    }

    setEdits(t.editorials);
    setEditorials(t.editorials);
    setSourceIds(t.sourceIds);
    setBySourceId(t.bySourceId);
    setSourceNames(t.sourceNames);
  }, []);

  const onChange = (e, key, id) => {
    e.persist();

    setEdits({
      ...edits,
      [id]: {
        ...edits[id],
        [key]: { ...edits[id][key], text: e.target.value },
      },
    });
  };

  const reset = (id) => {
    setEdits({
      ...edits,
      [id]: { ...editorials[id] },
    });
  };

  return (
    <div className="boone-listing__editorial">
      {props.internalUser && (
        <div className="text-muted mb-5 d-flex flex-column">
          <span className="mb-2">{`Authority Source ID:  ${props.authority_source_id}`}</span>
          {`Your Source ID:  ${props.current_org_source_id}`}
        </div>
      )}

      <div className="boone-listing__alert">
        Boone provides a default subtitle and description, but makes all Editorial available via the API.
      </div>

      {sourceIds.map((id, idx) => {
        return (
          <div className="boone-listing__editorial__source" data-cy="listing-editorial" key={id} data-source-id={id}>
            {sourceNames[id] ? (
              <div className="d-flex mb-2">
                <div className="boone-listing__editorial__source__name">
                  From {formatSourceName(sourceNames[id])}
                  {idx === 0 ? <span className="boone-listing__tag">API Default</span> : null}
                  {props.internalUser && (
                    <span className="boone-listing__tag ml-2 bg-info border border-2 border-info">{`Source ID:  ${id}`}</span>
                  )}
                </div>
              </div>
            ) : (
              <div className="boone-listing__editorial__source__name">From You</div>
            )}
            <div className="">
              {bySourceId[id].map((placeId, idx) => {
                const { description, subtitle } = edits[placeId];
                return (
                  <Editorial
                    idx={idx}
                    sourceId={id}
                    placeId={placeId}
                    key={id + '-' + placeId}
                    userEditingStrategy={
                      props.userEditingStrategy !== 'none'
                        ? id === props.current_org_source_id ||
                          (props.current_org_source_id === 1000 && id === 1) ||
                          (props.current_org_source_id === 27 && id === 1)
                          ? 'update'
                          : 'none'
                        : 'none'
                    }
                    description={description}
                    subtitle={subtitle}
                    reset={reset}
                    onChange={(e, key, id) => onChange(e, key, id)}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
      {Object.keys(readonlyEditorials).map((itemId, idx) => {
        const {
          description,
          subtitle,
          editorial_item_name,
          editorial_item_type,
          editorial_item_id,
        } = readonlyEditorials[itemId];

        return (
          <div
            className="boone-listing__editorial__source"
            key={itemId}
            data-cy="listing-editorial-readonly"
            data-editorial-id={editorial_item_id}
          >
            <div className="d-flex mb-2">
              <div className="boone-listing__editorial__source__name">
                From {formatSourceName(editorial_item_name)}
                <span className="boone-listing__tag"> Read Only </span>
                <span className="boone-listing__tag bg-info">
                  {editorial_item_type}:{editorial_item_id}{' '}
                </span>
              </div>
            </div>
            <div className="">
              <Editorial
                idx={idx}
                key={itemId}
                userEditingStrategy="none"
                description={description}
                subtitle={subtitle}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
