import React from 'react';

export default class ListButton extends React.Component {
  render() {
    const props = $.extend({}, this.props, {
      className: ("btn btn-white btn-circle btn-circle-small " + this.props.className)
    });

    return (
      <div {...props}>
        {this.props.children}
      </div>);
  }
}
